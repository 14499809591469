import React from 'react';

import { FormSection as AkFormSection } from '@atlaskit/form';
import * as styles from 'modules/checkout/contacts/styles';
import { Props } from 'modules/checkout/contacts/CheckoutContactPage';
import Help from 'components/common/Help';
import t from 'modules/i18n/intl';

import { AddressForm } from 'modules/checkout/contacts/AddressForm';

export default ({
  billingOrg,
  countries,
  hasOnlyNewItems,
  isDifferentTechnical,
  showReconcilableWarning,
  lockDifferentTechnical,
  lockedCartCurrency,
  showCurrencyChangeWarning,
  showCompanyCountryCurrency,
}: Partial<Props>) => (
  <styles.Section>
    <AkFormSection
      title={
        <>
          {t('checkout.contact.contact-your-company')}
          <Help>{t('checkout.contact.purchasing-company-help')}</Help>
        </>
      }
    >
      <AddressForm
        {...billingOrg}
        name="billingOrganisationDetails"
        country={billingOrg?.country}
        countries={countries}
        enableTooltipOnCountrySelect={!!hasOnlyNewItems && !isDifferentTechnical}
        hasReconcilableWarning={!!showReconcilableWarning}
        showWarning={showCurrencyChangeWarning && !lockDifferentTechnical}
        cartCurrency={lockedCartCurrency}
        showCompanyCountryCurrency={!!showCompanyCountryCurrency}
        lockDifferentTechnical={!!lockDifferentTechnical}
        isDifferentTechnical={!!isDifferentTechnical}
      />
    </AkFormSection>
  </styles.Section>
);
