// @flow
import React from 'react';
import { FormContext, concatPath } from './Form';

export default (props: { name: string, children: any }) => (
  <FormContext.Consumer>
    {({ path, ...context }) => (
      <FormContext.Provider value={{ ...context, path: concatPath(path, props.name) }}>
        {props.children}
      </FormContext.Provider>
    )}
  </FormContext.Consumer>
);
