import React, { useEffect, useState } from 'react';
import Progress from 'redux-progress';
import t from 'modules/i18n/intl';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { STANDARD_EDITION } from 'util/constants';
import { HamsCart, HamsCartItem } from 'modules/cart/duck/model';
import { HeaderInfo } from 'components/common/Table/TableHeader';
import Table from 'components/common/Table/Table';
import { Cell, TotalPrice } from 'components/common/Table/Wrappers';
import { HamsPricingResponse } from 'model/hams/HamsPricingResponse';
import { hamsFetchPricing } from 'modules/catalog/pricing';
import { OrderableItem } from 'model/hams/Pricing';
import { AnnualBillingTableBody } from 'modules/siteconfigurator/table/AnnualBillingTableBody';
import AmountWithCurrency from 'components/common/AmountWithCurrency';
import { FooterRow } from 'components/common/Table/TableFooter';
import {
  getCartAppItems,
  getCartProductItems,
  getEditionTierMapping,
  getPricingOptions,
  getItemMaintenanceEndDate,
  getCloudParentItem,
} from '../utils';
import { OriginalItemDetails } from '../duck/model';
import { Dimensions } from 'components/visuals';
import { maintenanceDateElement } from 'components/common/MaintenancePeriod';
import { CartItemDescriptionDiv } from './AppInfoRow';
import { ExtraProductKeys } from '../../../data/products/keys';

type Props = {
  cart: HamsCart | null;
  originalCartItemDetails: OriginalItemDetails;
  onUpdateCartItem: (cartItem: HamsCartItem, sku: string, unitCount: number) => void;
  setAnalyticsTrigger: (trigger: string) => void;
  setIsPageLoading: (isPageLoading: Progress<boolean>) => void;
};

const tableHeaders: ReadonlyArray<HeaderInfo> = [
  {
    key: 'details',
    display: t('annualbilling.header.product-details'),
  },
  {
    key: 'edition',
    display: t('annualbilling.header.edition'),
  },
  {
    key: 'tier',
    display: t('annualbilling.header.tier'),
  },
  {
    key: 'list_price',
    display: t('annualbilling.header.list-price'),
  },
  {
    key: 'prorated_price',
    display: t('annualbilling.header.prorated-price'),
    tooltip: t('annualbilling.header.prorated-price-tooltip'),
    align: 'right',
  },
];

const PaddedVerticalCell = styled(Cell)`
  padding: ${Dimensions.units(4)}px 0 ${Dimensions.units(3)}px;
`;

const VerticalAlignedCell = styled(PaddedVerticalCell)`
  vertical-align: top;
`;

const StyledTotalPrice = styled(TotalPrice)`
  font-size: 20px;
  text-transform: none;
  margin-top: 0;
  color: #1b2b4b;
`;

const footerRows = (cart: HamsCart): ReadonlyArray<FooterRow> => {
  const untilDate = maintenanceDateElement(getItemMaintenanceEndDate(getCloudParentItem(cart)));
  const untilDateElement = untilDate && (
    <CartItemDescriptionDiv>
      <FormattedMessage id="annualbilling.maintenance-period.until" /> {untilDate}
    </CartItemDescriptionDiv>
  );

  return [
    {
      id: 'total_price',
      display: (
        <>
          <VerticalAlignedCell>
            <StyledTotalPrice>
              <FormattedMessage id="annualbilling.total-price" />
            </StyledTotalPrice>
          </VerticalAlignedCell>
          <PaddedVerticalCell data-cy="total-price" colSpan={4}>
            <AmountWithCurrency
              amount={cart.totalIncTax}
              currency={cart.currency}
              format="code"
              size="20px"
              dataRole="totalPrice"
            />
            {untilDateElement}
          </PaddedVerticalCell>
        </>
      ),
    },
  ];
};

export const AnnualBillingTable = ({
  cart,
  originalCartItemDetails,
  onUpdateCartItem,
  setAnalyticsTrigger,
}: Props) => {
  const [pricingOptions, setPricingOptions] = useState<HamsPricingResponse | null>(null);
  const [selectedEdition, setSelectedEdition] = useState<string>('');
  useEffect(() => {
    if (cart) {
      hamsFetchPricing(
        cart.items
          .map((cartitem) => cartitem.productDetails.productKey)
          .filter((productKey) => productKey !== ExtraProductKeys.ATLASSIAN_CLOUD),
        cart.currency,
      ).then((x: any) => setPricingOptions(x.data));
    }
  }, []);

  if (!cart) {
    return null;
  }

  const cartProductItems: HamsCartItem[] = getCartProductItems(cart);
  const cartAppItems: HamsCartItem[] = getCartAppItems(cart);

  const updateItem = (cartItem: HamsCartItem, editionTiers: OrderableItem[], unitCount: string) => {
    const sku = editionTiers.find((tier) => `${tier.unitCount}` === unitCount)?.sku || '';
    if (cartItem.accountId === -1 || sku === '') {
      return;
    }

    onUpdateCartItem(cartItem, sku, Number.parseInt(unitCount, 10));
  };

  const isCartItemOriginalEditionStandard = (cartItemId: number) =>
    originalCartItemDetails[cartItemId].edition === STANDARD_EDITION;
  const getOriginalTierCount = (cartItemId: number) =>
    originalCartItemDetails[cartItemId]?.tierCount;

  const appTierMapping: { [cartItemId: number]: OrderableItem[] } = cartAppItems.reduce(
    (mapping, cartItem) => ({
      ...mapping,
      [cartItem.id]: getPricingOptions(pricingOptions, cartItem),
    }),
    {},
  );

  const editionsAndTiersPerRow = cartProductItems.reduce((mapping, cartItem) => {
    const editionOptions: OrderableItem[] = getPricingOptions(pricingOptions, cartItem);
    const editionTierMapping = getEditionTierMapping(editionOptions);
    return {
      ...mapping,
      [cartItem.id]: editionTierMapping,
    };
  }, {});

  return (
    <Table
      body={
        <AnnualBillingTableBody
          currency={cart.currency}
          cartProductItems={cartProductItems}
          cartAppItems={cartAppItems}
          selectedEdition={selectedEdition}
          setSelectedEdition={setSelectedEdition}
          updateItem={updateItem}
          editionsAndTiersPerRow={editionsAndTiersPerRow}
          appTierMapping={appTierMapping}
          isCartItemOriginalEditionStandard={isCartItemOriginalEditionStandard}
          getOriginalTierCount={getOriginalTierCount}
          setAnalyticsTrigger={setAnalyticsTrigger}
        />
      }
      footers={footerRows(cart)}
      headers={tableHeaders}
    />
  );
};
