import Progress from 'redux-progress';

import { ORDER, ORDER_DETAILS, SOURCE } from 'modules/mac/duck/actions/cart';
import type { MACCartAction, MACCartState } from '../models/cart';

export const initialState: MACCartState = {
  order: Progress.none,
  source: Progress.none,
  purchaseOrderNumber: Progress.none,
};

export default (state: MACCartState = initialState, action: MACCartAction): MACCartState => {
  switch (action.type) {
    case ORDER:
      return {
        ...state,
        order: action.progress,
      };
    case ORDER_DETAILS:
      return {
        ...state,
        purchaseOrderNumber: action.progress,
      };
    case SOURCE:
      return {
        ...state,
        source: action.progress,
      };
    default:
      return state;
  }
};
