import Progress from 'redux-progress';
import { CLEAR_CART, CLOSE_DIALOG, OPEN_DIALOG } from '../actions';

const initialState = {
  submitProgress: Progress.none,
  dialogOpen: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_CART:
      return {
        ...state,
        submitProgress: action.progress,
      };
    case OPEN_DIALOG:
      return {
        ...state,
        dialogOpen: true,
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        dialogOpen: false,
      };
    default:
      return state;
  }
};
