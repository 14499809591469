import { progressResult } from 'util/rxOperators';
import Progress from 'redux-progress';
import { Observable, merge, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import * as selectors from 'modules/checkout/contacts/duck/selectors';
import { EnhancedOrganisation } from 'modules/checkout/contacts/duck/model';
import * as taxRates from 'modules/data/taxRates';
import * as countries from 'modules/data/countries';
import { Selector, State$ } from 'model/State';

const makeEpic = (getOrg: Selector<Progress<EnhancedOrganisation>>) => (
  action$: Observable<any>,
  state$: State$,
) => {
  const isoCountryCode$ = state$.pipe(
    progressResult(getOrg),
    map((org: EnhancedOrganisation) => org.isoCountryCode),
    distinctUntilChanged(),
    filter(Boolean),
  );

  const knownState$ = state$.pipe(
    progressResult(getOrg),
    map((org: EnhancedOrganisation) => {
      if (org.country) {
        return org.state || '';
      }
      return '';
    }),
    distinctUntilChanged(),
  );

  const fetchMissingDetails$ = isoCountryCode$.pipe(map(countries.actions.fetchMissingDetails));
  const fetchTaxRate$ = combineLatest(isoCountryCode$, knownState$).pipe(
    map(([isoCountryCode, orgState]) => taxRates.actions.fetchTaxRate(isoCountryCode, orgState)),
  );

  return merge(fetchMissingDetails$, fetchTaxRate$);
};

export default combineEpics(
  makeEpic(selectors.getBillingOrganisation),
  makeEpic(selectors.getTechnicalOrganisation),
);
