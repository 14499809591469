// @flow
import * as Sentry from '@sentry/browser';
import config from 'appconfig';

import { getCohortId } from 'modules/meta/cohort';

/*
  Default ignores and blacklist copied from Sentry.io official docs
  See https://docs.sentry.io/platforms/javascript/#decluttering-sentry
 */
const defaultIgnores = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // See https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/13
  'Non-Error exception captured',
  'Non-Error promise rejection captured',
];

const defaultBlacklist = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  // Mozilla extensions
  /^moz-extension:\/\//i,
  // Twitter
  /platform\.twitter\.com/i,
  /static\.ads-twitter\.com\/oct\.js/i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i,
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

/*
  End of defaults block, copied from Sentry.io docs
 */

export default () => {
  if (config.sentry) {
    Sentry.init({
      dsn: 'https://40c2a136956d4f8f9bd7d68856f1321c@o55978.ingest.sentry.io/5988846',
      release: process.env.COMMIT_HASH || 'unknown',
      ignoreErrors: [...defaultIgnores],
      blacklistUrls: [...defaultBlacklist],
      ...config.sentry,
    });

    Sentry.setTag('cohort', getCohortId());
  }
};
