import { REQUEST_TIMEOUT, ROUTE, PLACE_ORDER } from 'modules/checkout/review/duck/actions';

export default (state = false, action) => {
  switch (action.type) {
    case PLACE_ORDER:
      return state && !action.progress.complete;
    case ROUTE:
      return false;
    case REQUEST_TIMEOUT:
      return action.payload;
    default:
      return state;
  }
};
