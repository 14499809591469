import type { Dispatch, GetState } from 'model/State';

import { SET_SWITCH_USER_EMAIL, RESTORE_USER_EMAIL } from './types';
import { getCurrentContactEmail } from 'modules/auth/selectors';
import { MACSwitchedUser } from '../../api/storage';

const storedSwitchedUser = MACSwitchedUser();

export const setSwitchUserEmail = (email: string) => (dispatch: Dispatch) => {
  storedSwitchedUser.store(email);
  dispatch({
    type: SET_SWITCH_USER_EMAIL,
    payload: email,
  });
};

export const restoreSwitchUserEmail = () => (dispatch: Dispatch, getState: GetState) => {
  const email = getCurrentContactEmail(getState());
  storedSwitchedUser.store(email);
  dispatch({
    type: RESTORE_USER_EMAIL,
    payload: email,
  });
};
