/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import * as actions from 'modules/data/countries/actions';

export default produce((state, action) => {
  switch (action.type) {
    case actions.FETCH_DETAILS:
      state[action.isoCountryCode] = action.progress;
      break;
    default:
  }
}, {});
