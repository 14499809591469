import { createSelector } from 'reselect';
import { CartCountryValidation } from 'modules/checkout/contacts/duck/model';
import { Selector } from 'model/State';

export const cartCountryValidationSelector = (s) =>
  s.checkout.contacts.validateCartCurrencyCountryMatch;

export const cartCurrencyCountryValidation: Selector<CartCountryValidation> = createSelector(
  cartCountryValidationSelector,
  (validation) => validation.result,
);
