import React from 'react';
import styled from 'styled-components';
import { Dimensions, Font } from 'components/visuals';
import TermsTooltip from 'modules/configure/terms/TermsTooltip';
import { Props } from 'modules/addon/Parent';
import TermsSelect from 'modules/configure/terms/TermsSelect';
import t from 'modules/i18n/intl';

const Header = styled.h3`
  display: inline-block;
  font-size: 20px;
  font-family: ${Font.displayFamily};
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 24px;
  margin-bottom: ${Dimensions.units(1)}px;
`;

const Wrapper = styled.div`
  margin-top: ${Dimensions.units(2)}px;
  margin-bottom: ${Dimensions.units(2)}px;
  padding-left: 28px;
`;

const ParentTermSelect = (props: Props) => (
  <Wrapper>
    <div>
      <Header>
        {props.data.isSubscription ? t('addon.subscription-term') : t('addon.maintenance-period')}
      </Header>
      &nbsp;
      <TermsTooltip subscription={props.data.isSubscription} />
    </div>
    <TermsSelect
      options={props.parent.terms}
      selected={props.parent.term}
      onChange={(term) => props.events.onTermChange(props.parent.key, term)}
      currency={props.data.currency}
    />
  </Wrapper>
);

export default ParentTermSelect;
