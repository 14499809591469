import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { actions as checkoutCompleteActions } from 'modules/checkout/complete';
import { State, CartEpic } from 'model/State';
import {
  TrackAction,
  TrackSource,
  TrackActionSubject,
  TrackEventNames,
} from 'modules/analytics/model/TrackEvent';
import { createTrackEvent, utils } from 'modules/analytics';
import { Action } from 'redux';

const getPaymentTerm = (checkoutMode: string) => {
  switch (checkoutMode) {
    case 'cc':
    case 'bank':
      return 'NET0';
    case 'pot':
      return 'NET30';
    case 'poa':
      return 'onAccount';
    default:
      return null;
  }
};

const getProps = (s: State) => {
  const { query } = s.location;
  if (query?.mode === 'order') {
    return {
      orderNumber: query.ordernumber,
      reviewRequired: query.reviewRequired,
      paymentMethod: query.checkoutMode === 'cc' ? 'instant' : 'cash',
      paymentTerm: getPaymentTerm(query.checkoutMode as string),
    };
  }
  return {
    orderNumber: query?.ordernumber,
    reviewRequired: query?.reviewRequired,
  };
};

export const viewedCheckoutComplete: CartEpic<Action<typeof checkoutCompleteActions.ROUTE>> = (
  action$,
) =>
  action$.pipe(
    ofType(checkoutCompleteActions.ROUTE),
    map(() =>
      createTrackEvent(
        utils.getTrackEventData(
          TrackAction.Viewed,
          TrackActionSubject.CheckoutComplete,
          TrackSource.CheckoutCompleteScreen,
          TrackEventNames.ViewedCheckoutComplete,
        ),
        getProps,
      ),
    ),
  );

export default viewedCheckoutComplete;
