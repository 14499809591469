// @flow
import errorReporter from 'util/errorReporter';
import * as storage from 'modules/checkout/payment/duck/api/storage';
import { submit } from 'modules/checkout/payment/duck/actions/submit';
import type { Dispatch } from 'model/State';

export const FAILED = 'checkout/payment/paypal/FAILED';
export const failed = (e: any) => {
  errorReporter(e);
  return { type: FAILED, payload: e };
};

export const authorize = (token: any) => async (dispatch: Dispatch) => {
  storage.paypalDetails.store(token);
  await dispatch(submit());
};
