/* eslint-disable consistent-return */
import { createSelector } from 'reselect';
import transform from 'lodash/transform';
import omitBy from 'lodash/omitBy';
import Progress from 'redux-progress';
import FormValidationError from 'model/hams/FormValidationError';
import { State } from 'model/State';

const errorsToOmit = [
  'validate.order.cart.multicurrency.lockeditem.on.purchaser.mismatch',
  'validate.order.cart.multicurrency.lockeditem.on.techContact.mismatch',
  'validate.order.cart.multicurrency.purchaser.country.locked',
  'validate.order.cart.multicurrency.technicalContact.country.locked',
  'validate.order.cart.contact.technicalContactSameAsBilling',
];

export default createSelector(
  (s: State) => s.checkout.contacts.submitProgress.error,
  (error) => {
    if (error instanceof FormValidationError) {
      return transform(
        omitBy(error, (errorObj: any) => errorsToOmit.includes(errorObj.errorKey)),
        (result, errorObj: any) => {
          /* eslint-disable no-param-reassign */
          result[errorObj.field] = Progress.fail({
            text: errorObj.errorKey || errorObj.defaultMessage,
          });
        },
        {},
      );
    }
  },
);
