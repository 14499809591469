import Progress from 'redux-progress';

import {
  GET_CUSTOMER_BY_EMAIL,
  IS_MEMBER_OF_GROUP,
  IS_EMAIL,
  EXTERNAL,
} from 'modules/mac/duck/actions/contact';
import type { MACContactAction, MACContactState } from '../models';

export const initialState: MACContactState = {
  getCustomerByEmail: {},
  isMemberOfGroup: Progress.none,
  isEmail: Progress.none,
  external: Progress.none,
};

export default (
  state: MACContactState = initialState,
  action: MACContactAction,
): MACContactState => {
  switch (action.type) {
    case GET_CUSTOMER_BY_EMAIL:
      return {
        ...state,
        getCustomerByEmail: {
          ...state.getCustomerByEmail,
          [action.email]: action.progress,
        },
      };
    case IS_MEMBER_OF_GROUP:
      return {
        ...state,
        isMemberOfGroup: action.progress,
      };
    case IS_EMAIL:
      return {
        ...state,
        isEmail: action.progress,
      };
    case EXTERNAL:
      return {
        ...state,
        external: action.progress,
      };
    default:
      return state;
  }
};
