/* eslint-disable react/no-unused-prop-types */
// @flow
import * as React from 'react';
import compact from 'lodash/compact';
import join from 'lodash/join';
import { FormValidity } from './duck/model';

export type FieldsValidity = {
  [key: string]: boolean,
};

type Context = {
  // eslint-disable-next-line react/require-default-props
  path?: string,
  edit: (string, any) => void,
  onBlur: (string) => void,
  data: any,
  validity: FormValidity,
};

export const FormContext = React.createContext(
  ({
    path: '',
    edit: () => {},
    onBlur: () => {},
    data: {},
    validity: new FormValidity(),
  }: Context),
);

export type FormProps = Context & {
  name: string,
  onSubmit: () => void,
  children: any,
};

export const concatPath = (a?: string, b?: string) => join(compact([a, b]), '.');

export default ({ name, children, onSubmit, ...context }: FormProps) => {
  return (
    // Don't change to Atlaskit <Form>.
    // It doesn't seem to do anything with its onSubmit prop.
    // I.e. this page would trigger a real form submit.
    <form
      name={name}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      method="POST"
    >
      <FormContext.Provider
        value={{
          ...context,
        }}
      >
        {children}
      </FormContext.Provider>
    </form>
  );
};
