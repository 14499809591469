// @flow
import {
  CloudProductKeys,
  DataCenterProductKeys,
  ProductGroupKeys,
  ServerProductKeys,
} from 'data/products/keys';
import config from 'appconfig';
import t from 'modules/i18n/intl';
import ServerProduct from 'model/editions/ServerProduct';
import SoftwareProductGroup from 'model/SoftwareProductGroup';
import DataCenterProduct from 'model/editions/DataCenterProduct';
import CloudProduct from 'model/editions/CloudProduct';
import { deploymentOptions, saml, zeroDowntime } from 'model/features';

export const jiraSoftwareServer = new ServerProduct({
  key: ServerProductKeys.JIRA_SOFTWARE,
  groupKey: ProductGroupKeys.JIRA_SOFTWARE,
  tagLine: t('products.jira-software.tagline'),
  description: t('products.jira-software.description'),
  name: t('products.jira-software.name'),
  moreLink: `${config.wacUrl}/software/jira`,
  recommended: [
    ServerProductKeys.JIRA_PORTFOLIO,
    ServerProductKeys.JIRA_SERVICE_DESK,
    ServerProductKeys.CONFLUENCE,
    ServerProductKeys.BITBUCKET,
  ],
});

export const jiraSoftwareDatacenter = new DataCenterProduct(
  DataCenterProductKeys.JIRA_SOFTWARE,
  jiraSoftwareServer,
  [],
  [deploymentOptions, saml, zeroDowntime],
);

export const jiraSoftwareCloud = new CloudProduct(
  CloudProductKeys.JIRA_SOFTWARE,
  jiraSoftwareServer,
);

export const jiraSoftwareProductGroup = new SoftwareProductGroup(
  jiraSoftwareServer,
  jiraSoftwareDatacenter,
  jiraSoftwareCloud,
);
