export const CLEAR_FETCH_PURCHASER_EMAIL_VALIDATION =
  'checkout/contact/purchaserContact/emailValidation/CLEAR';
export const CLEAR_FETCH_TECHNICAL_EMAIL_VALIDATION =
  'checkout/contact/technicalContact/emailValidation/CLEAR';

export const clearPurchaserEmailValidation = () => ({
  type: CLEAR_FETCH_PURCHASER_EMAIL_VALIDATION,
});

export const clearTechnicalEmailValidation = () => ({
  type: CLEAR_FETCH_TECHNICAL_EMAIL_VALIDATION,
});
