import Progress from 'redux-progress';
import { ContactDetails, OrganisationDetails } from 'model/hams/contact';
import { ProductKey } from 'data/products/keys';
import { LicenseType } from 'model/hams/LicenseType';
import { QuoteMigrationStatuses } from 'util/QuoteMigrationStatuses';

export type HamsProductDetails = {
  productKey: ProductKey;
  productDescription: string;
  saleType: string;
  licenseType: LicenseType;
  unitCount: number;
  edition: string;
  editionDescription: string;
  unitLabel: string;
  marketplaceAddon: boolean;
  atlassianAddon: boolean;
  enterprise: boolean;
  isSubscription: boolean;
  isDataCenter: boolean;
  isTraining: boolean;
};

export type HamsDiscountDetails = {
  amount: number;
  reason: string;
  type: string;
};

export type SlaCreditApplication = {
  notes: string;
  amountIncTax: number;
  id: number;
};

export type HamsCartItem = {
  id: number;
  orderableItemId: number;
  description: string;
  nodeCount: number;
  maintenanceMonths: number;
  maintenanceStartDate: Date;
  maintenanceEndDate: Date;
  creditApplications: SlaCreditApplication[];
  listPrice: number;
  discountAmount: number;
  creditAmount: number;
  totalTax: number;
  totalIncTax: number;
  isCoTermedItem: boolean;
  remainingMaintenanceAmount: number;
  usdMaintenancePortion: number;
  autoRenewalEnabled: boolean;
  productDetails: HamsProductDetails;
  accountId?: number;
  accountName?: string;
  crossgradeAccountId?: number;
  parentCartItemId: number | null;
  slug: string | null;
  discountDetails?: HamsDiscountDetails[];
};

export type HamsCartEnrichedItem = HamsCartItem & {
  childItems: HamsCartEnrichedItem[];
};

export type MarketplacePromotion = {
  code: string;
  discountActive: boolean;
  reason: string;
  details: string;
};

export type AdjustmentDiscounts = {
  EXPERT?: number;
  LOYALTY_DISCOUNT?: number;
  MARKETPLACE_PROMOTION?: number;
  UPGRADE_CREDIT?: number;
};

export type HamsCart$Contacts = {
  billingContactDetails: ContactDetails;
  billingOrganisationDetails: OrganisationDetails;
  purchaserContactDetails: ContactDetails;
  technicalContactDetails: ContactDetails;
  technicalOrganisationDetails: OrganisationDetails;
  resellerOrder: boolean;
  expertOrder: boolean;
};
export type HamsCart = HamsCart$Contacts & {
  status: string;
  uuid: string;
  created: string;
  currency: string;
  purchaseOrderNumber: string;
  totalIncTax: number;
  totalTax: number;
  creditCardOnly: boolean;
  isPayOnTermsEligible: boolean;
  items: HamsCartItem[];
  inEditMode: boolean;
  ccpQuoteMigrationStatus: QuoteMigrationStatuses;
  ccpQuoteUuid: string | null;
  ccpQuoteId: string | null;
  ccpQuoteTxaId: string | null;
  /*
  TODO: Changing this to `isOutstandingPayment` would make more sense.
  TODO: Would be better if we supported `is3DSQuote` per-line-item
  That way, we'd have the added flexibility of mixing outstanding payments with
  normal line items.
  */
  is3DSQuote: boolean;
  promotionalCode: string;
  hasTaxExemptRequest: boolean;
  hasCoTermRequest: boolean;
  isCoTermRequestSatisfied: boolean;
  showEnterpriseSupportAgreement: boolean;
  showTrainingAgreement: boolean;
  expertOrder: boolean;
  marketplacePromotion?: MarketplacePromotion;
  updated?: string;
  quoteNumber: string | null;
  currentEndUserCurrency?: string;
  adjustmentAmounts?: AdjustmentDiscounts;
  savedAmountPercent: string;
  savedAmount: number;
  totalProratedPrice: number;
};

export type HamsEnrichedCart = {
  items: HamsCartEnrichedItem[];
} & HamsCart;

export type CartState = {
  payload: Progress<HamsCart | null>;
  itemsBeingDeleted: number[];
  itemDeletionQueue: number[];
  clearCart: {
    submitProgress: Progress<any>;
    dialogOpen: boolean;
  };
};

export type AddProductsBody = {
  newItems: {
    orderableItemId: string;
    maintenanceMonths: number;
    renewalMonths: number;
  }[];
};

export type CheckoutEventProducts = {
  productName: string;
  productPrice: number;
  productDiscount: number;
  productTier: number;
};

export const UnknownTax = 'UNKNOWN';
export type UnknownTaxType = typeof UnknownTax;

export type CheckoutEventData = {
  products: CheckoutEventProducts[];
  credits: number;
  loyatyDiscount: number;
  PartnerDiscount: number;
  total: number;
  subtotal: number;
  tax: number | UnknownTaxType;
  totalSaving: number;
  currency: string;
};

export enum CountryWithAdminFee {
  india = 'IN',
  turkey = 'TR',
}
