// @flow
import React from 'react';
import {
  AddonItem,
  AddonImg,
  AddonTitle,
  AddonSummary,
  LearnMore,
} from 'modules/marketplace/components/styled';
import type { MarketplaceAddon } from 'modules/catalog/addons';
import t from 'modules/i18n/intl';
import config from 'appconfig';

type AppEntryProps = $Diff<MarketplaceAddon, { key: any }> & {
  goAddon: () => void,
};

// eslint-disable-next-line no-underscore-dangle
export const getIcon = (addon: AppEntryProps) => addon._embedded.logo._links.image.href;
// eslint-disable-next-line no-underscore-dangle
export const getLink = (addon: AppEntryProps) =>
  `${config.marketplaceUrl}${addon._links.alternate.href}`;

const AddonEntry = (props: AppEntryProps) => (
  <AddonItem onClick={props.goAddon}>
    <AddonImg>
      <img src={getIcon(props)} alt={props.name} />
    </AddonImg>
    <div>
      <AddonTitle>{props.name}</AddonTitle>
      <AddonSummary>{props.summary}</AddonSummary>
      <div>
        <LearnMore href={getLink(props)} onClick={(e) => e.stopPropagation()} target="_blank">
          {t('marketplace.entry.learn-more.link')} »
        </LearnMore>
      </div>
    </div>
  </AddonItem>
);

export default AddonEntry;
