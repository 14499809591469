import React from 'react';
import Cell from 'modules/cart/summary/cells/Cell';
import CellSubLine from 'modules/cart/summary/cells/CellSubLine';
import ProductLogo from 'components/common/ProductLogo';
import { HamsCartItem } from 'modules/cart/duck/model';

type Props = {
  item: HamsCartItem;
};

const DATA_CENTER = 'Data Center';
const FORMERLY_PORFOLIO = '(formerly Portfolio)';

const getLogoAlt = (message) => {
  const alt = message.replace(DATA_CENTER, '');
  if (alt.includes(FORMERLY_PORFOLIO)) {
    return (
      <div>
        {alt.replace(FORMERLY_PORFOLIO, '')}{' '}
        <CellSubLine fontSize="14px">{FORMERLY_PORFOLIO}</CellSubLine>
      </div>
    );
  }
  return alt;
};

const LogoCell = (props: Props) => {
  return (
    <Cell {...props} width="340px">
      <ProductLogo
        product={props.item.productDetails.productKey}
        alt={getLogoAlt(props.item.productDetails.productDescription)}
        size="small"
      />
      {props.item.productDetails.isDataCenter && (
        <CellSubLine fontSize="14px">{DATA_CENTER}</CellSubLine>
      )}
      {props.item.accountId ? <CellSubLine>SEN-{props.item.accountId}</CellSubLine> : null}
      {props.item.slug ? <CellSubLine>{props.item.slug}</CellSubLine> : null}
    </Cell>
  );
};

export default LogoCell;
