import Progress from 'redux-progress';
import { FETCH_STATUS } from 'modules/checkout/review/duck/actions';

export default (state = Progress.none, action) => {
  switch (action.type) {
    case FETCH_STATUS:
      return action.progress;
    default:
      return state;
  }
};
