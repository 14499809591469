// @flow

import { jiraSoftwareCloud } from 'data/products/jira.software';
import { jiraServiceDeskCloud } from 'data/products/jira.servicedesk';
import { jiraCoreCloud } from 'data/products/jira.core';
import { jiraCaptureCloud } from 'data/products/jira.capture';
import statusPage from 'data/products/statuspage';
import Category from 'model/Category';

export default new Category(
  'track-and-support',
  jiraSoftwareCloud,
  jiraServiceDeskCloud,
  jiraCoreCloud,
  jiraCaptureCloud,
  statusPage,
);

export const trackAndSupportWithoutJWM = new Category(
  'track-and-support',
  jiraSoftwareCloud,
  jiraServiceDeskCloud,
  jiraCaptureCloud,
  statusPage,
);
