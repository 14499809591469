// @flow
import React from 'react';
import Progress from 'redux-progress';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import t from 'modules/i18n/intl';

import type { State, Dispatch } from 'model/State';
import type { UserDetails } from 'modules/auth/model/UserDetails';
import { getCurrentContactEmail } from 'modules/auth/selectors/contactDetails';
import Link from 'components/common/Link';
import { Font, Dimensions } from 'components/visuals';
import config from 'appconfig';
import macLoginURITransformer from 'util/macLoginURITransformer';
import { setSwitchUserEmail } from 'modules/mac/duck/actions/configUser';
import { FeatureFlags, checkFeatureGate } from 'util/statsig/index';

type StateProps = {
  userDetails: Progress<UserDetails | null>,
  currentContactEmail: string | null,
};

type DispatchProps = {
  setSwitchUserEmail: () => void,
};

type Props = StateProps & DispatchProps;

const Wrapper = styled.div`
  color: ${colors.N500};
  font-family: ${Font.family};
  font-size: 12px;
  height: ${Dimensions.units(2)}px;
  text-align: right;
`;

const LoggedInStatus = (props: {
  userDetails: UserDetails,
  macLink: string,
  setSwitchUserEmail: () => void,
}) => {
  const handleMACSwitchUserOnLogout = () => {
    props.setSwitchUserEmail();
  };
  return (
    <>
      <Wrapper>
        <Link to={props.macLink} className="pii">
          {props.userDetails.displayName || t('navigation.header.my-account')}
        </Link>
        <span>
          {' '}
          (
          <Link to={config.logoutUrl()} onClick={handleMACSwitchUserOnLogout}>
            {t('navigation.header.logout')}
          </Link>
          )
        </span>
      </Wrapper>
    </>
  );
};

const AnonymousStatus = (props: { macLink: string }) => (
  <Wrapper>
    <Link to={props.macLink}>{t('navigation.header.my-account')}</Link>
  </Wrapper>
);

export const VisualLoginStatus = (props: Props) => {
  const cartMACFunctionalityMigration = checkFeatureGate(
    FeatureFlags.CART_MAC_FUNCTIONALITY_MIGRATION,
  );
  const macLink = cartMACFunctionalityMigration
    ? `${config.root}/my`
    : macLoginURITransformer({
        url: config.macUrl,
        currentContactEmail: props.currentContactEmail,
      });

  return (
    props.userDetails.ifSuccess(
      (userDetails) =>
        userDetails && (
          <LoggedInStatus
            userDetails={userDetails}
            macLink={macLink}
            setSwitchUserEmail={props.setSwitchUserEmail}
          />
        ),
    ) || <AnonymousStatus macLink={macLink} />
  );
};

const mapStateToProps = (state: State): StateProps => ({
  userDetails: state.auth.userDetails,
  currentContactEmail: getCurrentContactEmail(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setSwitchUserEmail: () => dispatch(setSwitchUserEmail('')),
});

export default connect(mapStateToProps, mapDispatchToProps)(VisualLoginStatus);
