// @flow
import { redirect } from 'redux-first-router';

import type { Dispatch, GetState } from 'model/State';
import * as upgrade from 'modules/upgrade';
import { selectXsrfToken } from 'modules/auth/selectors';
import watchProgress from 'model/watchProgress';
import * as auth from 'modules/auth';
import { DataCenterProductKeys } from 'data/products/keys';
import loadAvailableOptions from 'modules/change/landing/duck/loadAvailableOptions';

export default async (dispatch: Dispatch, getState: GetState) => {
  const xsrfToken = await watchProgress(selectXsrfToken);
  if (!xsrfToken) {
    auth.actions.goToLogin();
    return;
  }

  const state = getState();

  if (
    state.location.query.productKey &&
    Object.values(DataCenterProductKeys).includes(state.location.query.productKey)
  ) {
    dispatch(redirect(upgrade.tiers.actions.route(state.location.query)));
  }

  await loadAvailableOptions(dispatch, state, upgrade.tiers.actions.route(state.location.query));
};
