// @flow
import { bambooDataCenter } from 'data/products/bamboo';
import { fisheyeServer } from 'data/products/fisheye';
import { crucibleServer } from 'data/products/crucible';
import Category from 'model/Category';

export const codeQuality = new Category(
  'code-quality',
  bambooDataCenter,
  fisheyeServer,
  crucibleServer,
);
