import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { Font, Dimensions } from 'components/visuals';
import Info from '@atlaskit/icon/glyph/info';
import Tooltip from '../Tooltip';

const Header = styled.th<{ width?: number }>`
  color: #707070;
  font-family: ${Font.displayFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: ${(props) => props.align || 'left'};
  vertical-align: baseline;
  letter-spacing: 1px;
  padding-left: 0;
  ${({ width }) => width && `width: ${width}%;`}
`;

const IconWrapper = styled.span`
  padding-left: ${Dimensions.units(0.5)}px;
`;

export type HeaderInfo = {
  key: string;
  display: React.ReactNode;
  tooltip?: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'char';
  width?: number;
};

type Props = {
  headers: ReadonlyArray<HeaderInfo>;
};

const TableHeader = (props: Props) => (
  <thead>
    <tr>
      {props.headers.map((header) => (
        <Header align={header.align} key={header.key} width={header.width}>
          {header.display}
          {header.tooltip && (
            <Tooltip
              direction="right-start"
              content={header.tooltip}
              distance={Dimensions.units(1)}
              contentPadding={Dimensions.units(2)}
              displayAsFlag
            >
              <IconWrapper>
                <Info label="Info icon" size="small" primaryColor={colors.N600} />
              </IconWrapper>
            </Tooltip>
          )}
        </Header>
      ))}
    </tr>
  </thead>
);

export default TableHeader;
