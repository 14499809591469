// @flow
import Progress from 'redux-progress';
import { createSelector } from 'reselect';

import { getPerProductConfiguration } from 'modules/configure/duck/selectors/productConfiguration';
import { selectMainProductKey } from 'modules/configure/duck/selectors/mainProduct';
import type { ContainsProductKey } from 'modules/configure/duck/selectors';
import type { Selector } from 'model/State';
import type { HamsProductPricing } from 'model/hams/Pricing';
import { selectProductPricing } from 'modules/catalog/pricing';
import { selectProductParents } from 'modules/catalog/parents';

export const selectAddonKeys: Selector<string[]> = (state) =>
  state.configure ? state.configure.addons.map((addon) => addon.productKey) : [];

export const selectValidAddonKeys: Selector<Progress<string[]>> = (state) => {
  const addonKeys = selectAddonKeys(state);
  const pricings: Progress<HamsProductPricing | null>[] = addonKeys.map((productKey) =>
    selectProductPricing(state, { productKey }),
  );

  return Progress.all(...pricings).map((results: any) =>
    results
      .map((pricing, index) => (pricing ? addonKeys[index] : null))
      .filter((key) => key !== null),
  );
};

const isSelected: Selector<boolean, ContainsProductKey> = createSelector(
  getPerProductConfiguration,
  (product) => product.selected,
);

export const selectSelectedAddonKeys: Selector<string[]> = (state) =>
  selectValidAddonKeys(state).ifSuccess((keys) =>
    keys.filter((productKey) => isSelected(state, { productKey })),
  ) || [];

export const isChildProduct: Selector<boolean, ContainsProductKey> = createSelector(
  selectMainProductKey,
  selectProductParents,
  (product, parents) => parents.ifSuccess((parentKeys) => parentKeys.includes(product)) || false,
);

export const isExpanded: Selector<boolean, ContainsProductKey> = (state, props) => {
  const config = getPerProductConfiguration(state, props);
  return !!config && config.expanded === true;
};

export const isProductSelected: Selector<boolean, ContainsProductKey> = (state, props) =>
  !!(
    state.configure &&
    (state.configure.product.productKey === props.productKey ||
      state.configure.addons.find(
        (addon) => addon.productKey === props.productKey && addon.selected,
      ))
  );
