import * as actions from 'modules/marketplace/search/duck/actions';
import { AllMarketplace } from 'modules/marketplace/data';

export default (state = AllMarketplace, action) => {
  switch (action.type) {
    case actions.SELECT_PRODUCT:
      return action.payload;
    default:
      return state;
  }
};
