import { foldIntoObject } from 'util/reducers';
import Progress from 'redux-progress';
import { DeploymentOptions, DeploymentOption } from 'model/hams/DeploymentOption';
import {
  fetchAddonsInfo,
  getCommerceProductKey,
  selectMarketplaceAddon,
  MarketplaceAddon,
} from 'modules/catalog/addons';

import { Dispatch, GetState, State } from 'model/State';
import { getProduct, getStaticProductGroup, SupportProductGroups } from 'data/products';
import { selectProductPricing } from 'modules/catalog/pricing';

/*
  getProductKeyForGroupSelection:  function was written for the case when we have same server keys but diff dc keys in the SupportProductGroups array.
  This kind of situation came from code restriction of the SupportProductGroup class and logic built on it and for the Priority Support new 
  product we had to add another product group with server key duplicate for avoiding related code unexpected behavior.
*/
const getProductKeyForGroupSelection = (state: State, productKey: string) => {
  const prevProductKey = state.location?.prev?.payload?.productKey;

  if (!prevProductKey) {
    return productKey;
  }

  const isServerDuplicates =
    SupportProductGroups.reduce(
      (acc, { server }) => (server?.key === productKey ? acc + 1 : acc),
      0,
    ) > 1;
  const isPrevKeyInSupportedProductDCGroups = SupportProductGroups.find(
    ({ dataCenter }) => dataCenter?.key === prevProductKey,
  );

  if (isServerDuplicates && isPrevKeyInSupportedProductDCGroups) {
    return prevProductKey;
  }

  return productKey;
};

export type Group = { [K in DeploymentOption]: string | null };
export const getDeploymentOption = (group: Group, productKey: string): DeploymentOption | null =>
  (Object.keys(group).find(
    (deploymentOption) => group[deploymentOption] === productKey,
  ) as DeploymentOption) || null;

export const selectProductGroup = (
  state: State,
  props: { productKey: string },
): Progress<Group> => {
  const productKeyForGroupSelection = getProductKeyForGroupSelection(state, props.productKey);
  const product = getProduct(productKeyForGroupSelection);
  const groupKey = product ? product.getGroupKey() : null;

  if (groupKey) {
    const group = getStaticProductGroup(groupKey);
    if (group) {
      return Progress.success(group);
    }
  }

  const mapResultByDeployment = (r) =>
    r.map((result, i) => {
      const deploymentType = DeploymentOptions[i];
      const productKey = (result && getCommerceProductKey(result.key, deploymentType)) || null;
      return {
        [deploymentType]: productKey,
      };
    });

  const deploymentOptionsProgressArray: Progress<MarketplaceAddon | null>[] = DeploymentOptions.map(
    (deploymentOption) => {
      const productKey = getCommerceProductKey(props.productKey, deploymentOption);
      const pricing = selectProductPricing(state, { productKey });
      return pricing.flatMap((price) =>
        price ? selectMarketplaceAddon(state, { productKey }) : Progress.success(null),
      );
    },
  );

  // $FlowFixMe Flow doesn't understand Progress.all
  const deploymentOptionsSingleProgress: Progress<(MarketplaceAddon | null)[]> = Progress.all(
    ...deploymentOptionsProgressArray,
  );

  return deploymentOptionsSingleProgress
    .map(mapResultByDeployment)
    .map((r) => r.reduce(foldIntoObject));
};

export const fetchProductGroup = (productKey: string) => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const group = selectProductGroup(getState(), { productKey });
  if (!group.success) {
    await dispatch(fetchAddonsInfo(DeploymentOptions.map((hosting) => ({ productKey, hosting }))));
  }
};
