import { SET_BILLING_PERIOD } from '../actions';

export default function selectedBillingPeriod(state = 1, action: any) {
  switch (action.type) {
    case SET_BILLING_PERIOD:
      return action.payload;
    default:
      return state;
  }
}
