import React from 'react';
import Modal, { ModalBody } from '@atlaskit/modal-dialog';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { AtlassianLogo } from '@atlaskit/logo';
import Button from '@atlaskit/button/standard-button';
import t from 'modules/i18n/intl';
import { Font } from 'components/visuals';
import { actions } from 'modules/auth';

type Props = {
  cancel: () => void;
  onCloseComplete: () => void;
};

const Body = styled.div`
  font-family: ${Font.family};
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 16px;
`;

const Heading = styled.h2`
  font-family: ${Font.displayFamily};
  margin-top: 12px;
`;

const LoginButton = styled(Button)`
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 0 30px;
`;

const CancelButton = styled(Button)`
  font-size: 12px;
  // Atlaskit uses !important, so we need it to overrule
  color: ${colors.N900} !important;
`;

const RequireLoginModal = ({ cancel, onCloseComplete }: Props) => (
  <Modal width={400} onCloseComplete={onCloseComplete}>
    <ModalBody>
      <Body>
        <AtlassianLogo textColor={colors.B400} iconColor={colors.B400} size="small" />
        <Heading>{t('checkout.contact.login.welcome-message')}</Heading>
        <p>{t('checkout.contact.login.autofill-form')}</p>
        <LoginButton appearance="primary" onClick={actions.goToLogin}>
          {t('checkout.contact.login.login')}
        </LoginButton>
        <p>
          <CancelButton
            appearance="link"
            onClick={(e) => {
              e.preventDefault();
              cancel();
            }}
          >
            {t('checkout.contact.login.use-different-email')}
          </CancelButton>
        </p>
      </Body>
    </ModalBody>
  </Modal>
);
export default RequireLoginModal;
