/* eslint-disable react/no-unused-prop-types,react/prop-types */
// An eslint bug prevents us from using it for prop-types in this file.

// @flow
import * as React from 'react';
import Select from '@atlaskit/select';
import styled from 'styled-components';
import { Dimensions, Font } from 'components/visuals';

type Props<T> = {
  items: T[],
  getOptionLabel: (T) => React.Node,
  getOptionExtraInfo: (T) => any,
  getOptionValue: (T) => string,
  onChange: (T) => void,
  selected: T | null,
  isDisabled?: boolean,
  fitToContainer?: boolean,
};

export const Wrapper = styled.span.attrs({
  'data-cy': 'select-wrapper',
})`
  display: inline-block;
  width: ${(props) => (props.fitToContainer ? '100%' : `${Dimensions.units(40)}px`)};
  font-family: ${Font.family};
`;

const LabelWrapper = styled.div`
  padding-top: 5px;
  width: ${Dimensions.units(35)}px;
`;

const Text = styled.span`
  overflow: hidden;
  display: inline-block;
  max-width: ${Dimensions.units(30)}px;
`;

const Extra = styled.span`
  font-weight: bold;
  position: absolute;
  box-sizing: border-box;
  right: ${Dimensions.units(1)}px;
`;

const Label = (props: { description: React.Node, info: string }) => (
  <LabelWrapper>
    <Text>{props.description}</Text>
    <Extra>{props.info}</Extra>
  </LabelWrapper>
);

const getOptionLabel = (props: { getOptionLabel: (any) => React.Node }) => (item) =>
  props.getOptionLabel(item);

const getOptionValue = (props: { getOptionValue: (any) => string }) => (item) =>
  props.getOptionValue(item);

const optionLabel = (props: {
  getOptionLabel: (any) => React.Node,
  getOptionExtraInfo: (any) => string,
}) => (item) => (
  <Label description={props.getOptionLabel(item)} info={props.getOptionExtraInfo(item)} />
);

const SelectWithExtraInfo = (props: Props<any>) => (
  <Wrapper onClick={(e) => e.stopPropagation()} fitToContainer={props.fitToContainer}>
    <Select
      isDisabled={props.isDisabled}
      options={props.items}
      getOptionLabel={getOptionLabel(props)}
      getOptionValue={getOptionValue(props)}
      formatOptionLabel={optionLabel(props)}
      value={props.selected}
      onChange={props.onChange}
    />
  </Wrapper>
);

SelectWithExtraInfo.defaultProps = {
  isDisabled: false,
  fitToContainer: false,
};

export default SelectWithExtraInfo;
