// @flow

import React from 'react';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { colors } from '@atlaskit/theme';
import styled from 'styled-components';
import { Dimensions } from 'components/visuals';
import config from 'appconfig';

const Wrapper = styled.span`
  position: absolute;
  right: 0;
  top: ${Dimensions.units(2)}px;
`;

export default () => (
  <Wrapper>
    <a href={`${config.wacUrl}/search`}>
      <SearchIcon primaryColor={colors.N80} />
    </a>
  </Wrapper>
);
