// @flow
import { thunkProgress } from 'redux-progress';
import hamsClient from 'modules/hams';
import { PLACE_ORDER } from 'modules/checkout/review/duck/actions/submit';
import type { Dispatch, GetState } from 'model/State';
import { selectHamsClientConfig } from 'modules/auth/selectors';
import selectCartPayload from 'modules/cart/duck/selectors/cart';
import type { CheckoutType } from 'modules/checkout/review/duck/actions/submit';

// eslint-disable-next-line max-len
export default (cartId: string, checkoutType: CheckoutType) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const state = getState();

  return dispatch(
    thunkProgress(
      PLACE_ORDER,
      hamsClient
        .post(
          `/1.0/public/order/cart/${cartId}/processFreeOrder`,
          { updatedTime: selectCartPayload(state).ifSuccess((cart) => cart.updated) },
          selectHamsClientConfig(getState()),
        )
        .then((r) => r),
      { checkoutType },
    ),
  );
};
