// @flow
import hamsClient from 'modules/hams';
import type { AutoRenewItem } from 'model/hams/AutoRenewEligibility';

export default async (cartId: string): Promise<AutoRenewItem[]> =>
  (
    await hamsClient.get(
      `/1.0/public/order/cart/${cartId}/eligibleCartItemsForAutoRenew?_=${Date.now()}`,
    )
  ).data.cartItemsEligibleForAutoRenewal;
