// @flow
import Progress from 'redux-progress';
import { createSelector } from 'reselect';

import type { HamsProductPricing } from 'model/hams/Pricing';
import type { Selector } from 'model/State';
import { selectProductPricing } from 'modules/catalog/pricing';

export const selectMainProductKey: Selector<string | null> = (state) =>
  state.configure ? state.configure.product.productKey : null;

export const selectMainProductPricing: Selector<Progress<HamsProductPricing | null>> = (state) =>
  state.configure ? selectProductPricing(state, state.configure.product) : Progress.none;

export const isSubscription: Selector<boolean> = createSelector(
  selectMainProductPricing,
  (pricing) => pricing.ifSuccess((result) => result.billingType === 'SUBSCRIPTION') || false,
);
