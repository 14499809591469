import { LicenseType } from './LicenseType';
import { OrderableItem } from './Pricing';

export default class ProductTier {
  static isUnlimited(unitCount: number) {
    return unitCount < 0 || unitCount === 987654321;
  }

  unitCount: number;
  unitLabel: string;
  productKey: string;
  pricingPlans: { [type: string]: OrderableItem };

  constructor(unitCount: number, unitLabel: string, productKey: string) {
    this.unitCount = unitCount;
    this.unitLabel = unitLabel;
    this.productKey = productKey;
    this.pricingPlans = {};
  }

  getOrderableItem(licenseType: LicenseType): OrderableItem {
    return this.pricingPlans[licenseType] || Object.values(this.pricingPlans)[0];
  }

  isUnlimited() {
    return ProductTier.isUnlimited(this.unitCount);
  }
}
