import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Selector } from 'model/State';
import { contactValidationSelector } from './getContactValidations';

export default createSelector(contactValidationSelector, (contactValidation) => {
  const validationResult = contactValidation.purchaserContactValidation.result;
  return (
    isEmpty(get(validationResult, 'irreconcilable')) &&
    !isEmpty(get(validationResult, 'reconcilable'))
  );
}) as Selector<boolean>;
