import * as React from 'react';
import Textfield, { TextFieldProps } from '@atlaskit/textfield';

type WithNoSubmitTextboxProps = {
  onKeyPress: (e: KeyboardEvent) => void;
};

export const withNoSubmitTextbox = <TOwnProps,>(
  WrappedComponent: React.ComponentType<TOwnProps>,
) => (props: TOwnProps): React.ReactElement<TOwnProps & WithNoSubmitTextboxProps> => (
  <WrappedComponent
    {...props}
    onKeyPress={(e: KeyboardEvent) => {
      if (e.key === 'Enter') e.preventDefault();
    }}
  />
);

export const WithNoSubmitFieldTextStateless = withNoSubmitTextbox(
  Textfield as React.ComponentType<TextFieldProps>,
);
