import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import t from 'modules/i18n/intl';
import OptionsSelect, { Option } from 'modules/cart/summary/OptionsSelect';
import TaxesIncluded from 'modules/cart/summary/TaxesIncluded';
import { Dimensions, Font } from 'components/visuals';
import AmountWithCurrency from 'components/common/AmountWithCurrency';
import { HamsCart } from 'modules/cart/duck/model';
import { PurchaseMode } from 'modules/settings/purchaseMode';

type Props = {
  cart: HamsCart;
  options: Option[];
  purchaseMode: PurchaseMode;
  hideTotalPrice?: boolean;
  inSiteConfiguratorMode?: boolean;
};

const Cell = styled.td`
  text-align: left;
  font-weight: 500;

  &:last-child {
    text-align: right;
  }
`;

const TotalPrice = styled.div`
  color: ${colors.N500};
  font-family: ${Font.displayFamily};
  font-size: 12px;
  letter-spacing: 1px;
  margin-top: ${Dimensions.units(1)}px;
  text-transform: uppercase;
`;

const TableFooter = ({
  cart,
  options,
  purchaseMode,
  hideTotalPrice,
  inSiteConfiguratorMode,
}: Props) => {
  const priceCellSpanSize = options?.length > 0 && !inSiteConfiguratorMode ? 3 : 5;
  return (
    <tfoot>
      <tr>
        <Cell colSpan={5}>{cart.totalTax > 0 ? <TaxesIncluded cart={cart} /> : null}</Cell>
      </tr>
      <tr>
        {!inSiteConfiguratorMode && options?.length > 0 && (
          <Cell colSpan={2}>
            <OptionsSelect options={options} purchaseMode={purchaseMode} />
          </Cell>
        )}
        {!hideTotalPrice && (
          <Cell data-cy="total-price" colSpan={priceCellSpanSize}>
            <TotalPrice>{t('common.total-price')}</TotalPrice>
            <AmountWithCurrency
              amount={cart.totalIncTax}
              currency={cart.currency}
              format="code"
              size="35px"
              dataRole="totalPrice"
            />
          </Cell>
        )}
      </tr>
    </tfoot>
  );
};

export default TableFooter;
