import Progress from 'redux-progress';
import { ROUTE, PLACE_ORDER } from 'modules/checkout/review/duck/actions';
import { CONVERT_TO_QUOTE } from 'modules/checkout/review/duck/actions/convertToQuote';

export default (state = Progress.none, action) => {
  switch (action.type) {
    case CONVERT_TO_QUOTE:
    case PLACE_ORDER:
      return action.progress;
    case ROUTE:
      return Progress.none;
    default:
      return state;
  }
};
