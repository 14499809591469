import React from 'react';
import { FormattedDate } from 'react-intl';
import { HamsCartItem } from 'modules/cart/duck/model';
import {
  getItemMaintenanceStartDate,
  getItemMaintenanceEndDate,
} from '../../modules/siteconfigurator/utils';

export const maintenanceDateElement = (maintenanceDate: Date | undefined): HTMLElement | null =>
  maintenanceDate ? (
    <FormattedDate value={maintenanceDate} year="numeric" month="short" day="2-digit" />
  ) : null;

export const formattedDatePeriod = (
  billingPeriodCartItem: HamsCartItem | undefined,
  WrapperElement: HTMLElement = 'div',
): HTMLElement => {
  const maintenanceStartDateElement = maintenanceDateElement(
    getItemMaintenanceStartDate(billingPeriodCartItem),
  );
  const maintenanceStartDateElementWithHyphen = maintenanceStartDateElement && (
    <span>{maintenanceStartDateElement} - </span>
  );
  const maintenanceEndDateElement = maintenanceDateElement(
    getItemMaintenanceEndDate(billingPeriodCartItem),
  );

  return (
    <WrapperElement>
      {maintenanceStartDateElementWithHyphen}
      {maintenanceEndDateElement}
    </WrapperElement>
  );
};
