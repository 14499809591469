// @flow
import { thunkProgress } from 'redux-progress';
import * as api from 'modules/checkout/payment/duck/api';
import type { Dispatch } from 'model/State';

export const CONFIGURE_TNS_FORM = 'checkout/payment/CONFIGURE_TNS_FORM';
export const TNS_FOCUS = 'checkout/payment/TNS_FOCUS';

const configureSession = async (fields) => {
  const storedSessionId = api.storage.sessionId.load();
  if (storedSessionId) {
    try {
      return await api.TnsSession.configure(storedSessionId, fields);
    } catch (e) {
      // Failed to use stored session. Retrying with a new session-id
      api.storage.sessionId.reset();
    }
  }

  const newSessionId = await api.createSessionId();
  api.storage.sessionId.store(newSessionId);
  return api.TnsSession.configure(newSessionId, fields);
};

export const configureTnsForm = (fields: {}) => (dispatch: Dispatch) =>
  dispatch(
    thunkProgress(
      CONFIGURE_TNS_FORM,
      configureSession(fields).then((session) => {
        session.fieldFocus$.subscribe((field) =>
          dispatch({
            type: TNS_FOCUS,
            field,
          }),
        );
        return session;
      }),
    ),
  );
