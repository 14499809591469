import hamsClient, { HamsError } from 'modules/hams';
import { AddProductsBody, HamsCart } from 'modules/cart/duck/model';

import { isHamsConcurrencyError } from 'util/apiHelper';

const add = (cartId: string, body: AddProductsBody): Promise<HamsCart> =>
  hamsClient
    .post(`/1.0/public/order/cart/${cartId}/addItemsToCart`, body, {
      validateStatus: (status) => (status >= 200 && status < 300) || status === 400,
    })
    .then((response) => {
      const hamsConcurrencyError = isHamsConcurrencyError(response);
      if (response.status === 400 && !hamsConcurrencyError) {
        throw new HamsError(response);
      } else if (response.status === 400 && hamsConcurrencyError) {
        return new HamsError(response);
      } else {
        return response.data;
      }
    });

export default add;
