import { combineEpics } from 'redux-observable';

import config from 'appconfig';

import createMiddleware from './createMiddleware';

import addToCart from './addToCart';
import checkoutContacts from './checkoutContacts';
import checkoutOrder from './checkoutOrder';
import fetchLanguage from './fetchLanguage';
import placeOrder from './placeOrder';
import submitContact from './submitContact';
import setCartDetails from './setCartDetails';
import upgradeAccount from './upgradeAccount';
import createMetalClient from './createMetalClient';
import macLicenses from './macLicenses';
import macConfigUser from './macConfigUser';
import macEditDetails from './macEditDetails';
import macLicenseEvaluation from './macLicenseEvaluation';
import macOrders from './macOrders';
import macPaymentDetails from './macPaymentDetails';
import macUpdateCreditCard from './macUpdateCreditCard';

export const { client, middleware } = (() => {
  if (config.metal !== null && config.metal !== undefined) {
    const newClient = createMetalClient({
      env: config.metal.environment,
      buildVersion: process.env.REACT_APP_BUILD_NUMBER,
    });
    const newMiddleware = createMiddleware(newClient);
    return { client: newClient, middleware: newMiddleware };
  }
  return {
    client: null,
    middleware: null,
  };
})();

export const epic = combineEpics(
  addToCart,
  checkoutContacts,
  checkoutOrder,
  fetchLanguage,
  placeOrder,
  submitContact,
  setCartDetails,
  upgradeAccount,
  macLicenses,
  macConfigUser,
  macEditDetails,
  macLicenseEvaluation,
  macOrders,
  macPaymentDetails,
  macUpdateCreditCard,
);
