import { CartThunk } from 'model/State';
import { fetchPricing } from 'modules/catalog/pricing';
import selectCartPayload from 'modules/cart/duck/selectors/cart';
import watchProgress from 'model/watchProgress';
import { HamsCart } from 'modules/cart/duck/model';

const routeThunk: CartThunk = async (dispatch, getState) => {
  const cart: HamsCart = await watchProgress(selectCartPayload);

  const storedPromotion = getState().promotion.storage;
  if (
    storedPromotion &&
    cart.marketplacePromotion &&
    cart.marketplacePromotion.code === storedPromotion.code
  ) {
    await dispatch(fetchPricing(...storedPromotion.products));
  }
};

export default routeThunk;
