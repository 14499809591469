import React from 'react';
import styled from 'styled-components';

import { DeploymentAndBillingCycleContext } from 'modules/migrate/comparator/Switchers';
import { DEPLOY_OPTION } from './utils';

type Props = {
  item: React.ReactNode;
  details: React.ReactNode;
  numberOfUsers?: React.ReactNode;
  plan?: React.ReactNode;
  pricing?: React.ReactNode;
  unavailableMessage?: React.ReactNode;
};

const serverColumnWidth = 25;
const cloudWideColumnWidth = 23;
const cloudSlimColumnWidth = 18;

export const ServerColumn = styled.div`
  width: ${serverColumnWidth}%;
`;

export const FooterFirstServerColumn = styled.div`
  width: ${serverColumnWidth * 2}%;
`;

export const FooterFirstCloudColumn = styled.div`
  width: ${cloudWideColumnWidth + 2 * cloudSlimColumnWidth}%;
`;

export const CloudWideColumn = styled.div`
  width: ${cloudWideColumnWidth}%;
`;

export const CloudSlimColumn = styled.div`
  width: ${cloudSlimColumnWidth}%;
`;

export const CloudUnavailableColumn = styled.div`
  width: ${100 - cloudWideColumnWidth - cloudSlimColumnWidth}%;
`;

export const TableRowWrapper = styled.div`
  display: flex;
`;

const TableRow: React.FC<Props> = ({
  item,
  details,
  numberOfUsers,
  plan,
  pricing,
  unavailableMessage,
}) => (
  <DeploymentAndBillingCycleContext.Consumer>
    {({ deployOption }) => (
      <TableRowWrapper data-cy="table-row">
        {deployOption === DEPLOY_OPTION.CLOUD ? (
          <>
            <CloudWideColumn>{item}</CloudWideColumn>
            <CloudSlimColumn>{details}</CloudSlimColumn>
            {unavailableMessage ? (
              <CloudUnavailableColumn>{unavailableMessage}</CloudUnavailableColumn>
            ) : (
              <>
                <CloudSlimColumn>{numberOfUsers}</CloudSlimColumn>
                <CloudSlimColumn>{plan}</CloudSlimColumn>
                <CloudWideColumn>{pricing}</CloudWideColumn>
              </>
            )}
          </>
        ) : (
          <>
            <ServerColumn>{item}</ServerColumn>
            <ServerColumn>{details}</ServerColumn>
            <ServerColumn>{numberOfUsers}</ServerColumn>
            <ServerColumn>{pricing}</ServerColumn>
          </>
        )}
      </TableRowWrapper>
    )}
  </DeploymentAndBillingCycleContext.Consumer>
);

export default TableRow;
