// @flow
import DataCenterProduct from 'model/editions/DataCenterProduct';
import {
  CloudProductKeys,
  DataCenterProductKeys,
  ProductGroupKeys,
  ServerProductKeys,
} from 'data/products/keys';
import config from 'appconfig';
import t from 'modules/i18n/intl';
import ServerProduct from 'model/editions/ServerProduct';
import SoftwareProductGroup from 'model/SoftwareProductGroup';
import CloudProduct from 'model/editions/CloudProduct';

export const confluenceTeamCalendarsServer = new ServerProduct({
  key: ServerProductKeys.CONFLUENCE_TEAM_CALENDARS,
  groupKey: ProductGroupKeys.CONFLUENCE_TEAM_CALENDARS,
  tagLine: t('products.com.atlassian.confluence.extra.team-calendars.tagline'),
  description: t('products.com.atlassian.confluence.extra.team-calendars.description'),
  name: t('products.com.atlassian.confluence.extra.team-calendars.name'),
  addon: true,
  moreLink: `${config.wacUrl}/software/confluence/team-calendars`,
});

export const confluenceTeamCalendarsDatacenter = new DataCenterProduct(
  DataCenterProductKeys.CONFLUENCE_TEAM_CALENDARS,
  confluenceTeamCalendarsServer,
);

export const confluenceTeamCalendarsCloud = new CloudProduct(
  CloudProductKeys.CONFLUENCE_TEAM_CALENDARS,
  confluenceTeamCalendarsServer,
);

export const confluenceTeamCalendarsProductGroup = new SoftwareProductGroup(
  confluenceTeamCalendarsServer,
  confluenceTeamCalendarsDatacenter,
  confluenceTeamCalendarsCloud,
);
