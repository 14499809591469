export const STRIPE = 'stripe';
export const FREE = 'free';
export const CART_CONVERT_TO_QUOTE = 'cart.convert.to.quote';
export const CREDIT_CARD = 'credit.card';
export const PAY_ON_ACCOUNT = 'pay.on.account';
export const PAY_ON_TERMS = 'pay.on.terms';
export const PAYPAL = 'paypal';

export type CheckoutType =
  | typeof STRIPE
  | typeof FREE
  | typeof CART_CONVERT_TO_QUOTE
  | typeof CREDIT_CARD
  | typeof PAY_ON_ACCOUNT
  | typeof PAY_ON_TERMS
  | typeof PAYPAL;
