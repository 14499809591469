// @flow
import AtlStorage from 'api/AtlStorage';
import * as steps from 'modules/checkout/steps';
import type { Dispatch, GetState, State } from 'model/State';
import * as confirmation from 'modules/checkout/complete/duck/actions/route';
import * as payment from 'modules/checkout/payment/duck/actions/route';
import * as quote from 'modules/checkout/quote/duck/actions';
import { routeToPaymentOrQuote } from 'modules/checkout/duck/actions';

export const PurchaseModes = {
  BUY: 'BUY',
  QUOTE: 'QUOTE',
};

export type PurchaseMode = $Values<typeof PurchaseModes>;

const storage = new AtlStorage('btf.purchase.mode', window.sessionStorage, 'purchase_mode');

const load = (): PurchaseMode => (storage.load() === 'QUOTE' ? 'QUOTE' : 'BUY');

export const SELECT_PURCHASE_MODE = 'settings/purchaseMode/SELECT';
export const RESET = 'settings/purchaseMode/RESET';

export const setPurchaseMode = (payload: PurchaseMode) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  storage.store(payload);
  dispatch({
    type: SELECT_PURCHASE_MODE,
    payload,
  });

  dispatch(steps.markComplete('payment', false));
  if ([payment.ROUTE, quote.ROUTE].includes(getState().location.type)) {
    dispatch(routeToPaymentOrQuote());
  }
};
export const reset = () => {
  storage.reset();
  return { type: RESET };
};

export const selectPurchaseMode = (state: State): PurchaseMode => {
  if (state.location.type === confirmation.ROUTE) {
    return state.location.query.mode === 'quote' ? 'QUOTE' : 'BUY';
  }
  return state.settings.purchaseMode;
};

export const togglePurchaseMode = () => (dispatch: Dispatch, getState: GetState) =>
  dispatch(setPurchaseMode(selectPurchaseMode(getState()) === 'BUY' ? 'QUOTE' : 'BUY'));

export const init = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  if (state.location.query.purchaseMode) {
    dispatch(setPurchaseMode(state.location.query.purchaseMode === 'quote' ? 'QUOTE' : 'BUY'));
  }
};

export default (state: PurchaseMode = load(), action: *): PurchaseMode => {
  switch (action.type) {
    case SELECT_PURCHASE_MODE:
      return action.payload;
    case RESET:
      return load();
    default:
      return state;
  }
};
