import { SET_ORIGINAL_CART_ITEM_DETAILS } from '../actions';

export default function originalCartItemDetails(state = {}, action: any) {
  switch (action.type) {
    case SET_ORIGINAL_CART_ITEM_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
