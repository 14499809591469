import React from 'react';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import uniqBy from 'lodash/uniqBy';
import intersection from 'lodash/intersection';
import styled from 'styled-components';

import { Props } from 'modules/addon/VisualAddonPage';
import config from 'appconfig';
import AtlStorage from 'api/AtlStorage';
import { Dimensions, Font } from 'components/visuals';
import t from 'modules/i18n/intl';

const shouldDisplayLicenseMatchingBanner = (props) =>
  uniqBy(props.data.chooseParentOptions, 'productKey').length > 1;

const promotionStorage = new AtlStorage('marketplace.promotion', window.sessionStorage);

const shouldDisplayPromotion = (props, promotion) =>
  intersection(props.data.productKeys, promotion.products).length > 0;

const BannerWrapper = styled.div`
  padding: ${Dimensions.units(2)}px 0;
  font-family: ${Font.family};
`;

const PageBanners = (props: Props) => {
  const promotion = promotionStorage.load();
  return (
    <>
      {promotion && shouldDisplayPromotion(props, promotion) && (
        <BannerWrapper>
          <SectionMessage appearance="success" title={promotion.name}>
            {t('addon.promotion.applied')}
          </SectionMessage>
        </BannerWrapper>
      )}
      {shouldDisplayLicenseMatchingBanner(props) && (
        <BannerWrapper>
          <SectionMessage
            appearance="information"
            title={t('addon.license-information.title')}
            actions={[
              {
                text: t('common.more-information'),
                href: `${config.wacUrl}/licensing/marketplace`,
                key: 'addon-licensing-faq-link',
              },
            ].map(({ text, ...restAction }) => (
              <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
            ))}
          >
            {t('addon.license-information.text')}
          </SectionMessage>
        </BannerWrapper>
      )}
    </>
  );
};

export default PageBanners;
