/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Progress from 'redux-progress';

import config from 'appconfig';
import { State } from 'model/State';
import { getCurrentContactDetails } from 'modules/auth/selectors';
import { isIntercomEnabledProduct } from 'modules/configure/duck/util';
import { CurrentContact } from 'modules/auth/model/CurrentContact';
import { reportLogOnly } from 'util/errorReporter';
import { ProductKey } from 'data/products/keys';

type IntercomConfig = {
  // eslint-disable-next-line camelcase
  app_id: string;
  email?: string;
  // eslint-disable-next-line camelcase
  user_name?: string;
};

type props = {
  productKey: ProductKey;
  currentContactsProgress: Progress<CurrentContact>;
};

function IntercomConfiguration(props: props) {
  const { currentContactsProgress } = props;
  const intercomEnabledProduct = isIntercomEnabledProduct(props.productKey);

  const getIntercomConfig = (currentContacts: CurrentContact): IntercomConfig => {
    let intercomConfig: IntercomConfig = { app_id: config.intercomAppID };
    const contactFirstName = currentContacts?.contactDetails?.firstName;
    const contactEmail = currentContacts?.contactDetails?.email;

    if (contactFirstName && contactEmail) {
      intercomConfig = {
        ...intercomConfig,
        ...{
          email: contactEmail,
          user_name: contactFirstName,
        },
      };
    }
    return intercomConfig;
  };

  useEffect(() => {
    currentContactsProgress.ifSuccess((currentContacts) => {
      const intercomConfig = getIntercomConfig(currentContacts);

      if (intercomEnabledProduct) {
        try {
          window.Intercom('boot', intercomConfig);
          window.Intercom('trackEvent', 'Page-opened');
        } catch (error) {
          reportLogOnly('ProductConfigurationPage', {
            intercomConfig,
            error,
            productKey: props.productKey,
          });
        }
      }
    });
  }, [props.currentContactsProgress]);
  return <></>;
}

const mapStateToProps = (state: State) => ({
  currentContactsProgress: getCurrentContactDetails(state),
});

export default connect(mapStateToProps)(IntercomConfiguration);
