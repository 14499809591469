/**
 * This should contain list of all feature flags and corresponding selector util functions if any
 * For adding a new feature flag please define a new constant here and then add selector util
 * function if needed.
 */
import { makeIsFeatureFlagOn } from 'modules/meta/launchdarkly/selectors';

export const CartStopServerUpgradeFF = 'cart-stop-server-upgrade';
export const TaaSValidationTaxIdFF = 'cart-taas-validation-for-tax-id';
export const DisableBambooCrowdServerAddonsFF = 'cart-disable-bamboo-crowd-addons';
export const AcademicPricingToggleFF = 'cart-remove-academic-pricing-toggle';
export const ServerAddonsEndgameFF = 'server-addons-endgame';

export const isCartStopServerUpgradeFFOn = makeIsFeatureFlagOn(CartStopServerUpgradeFF);
export const isTaaSValidationTaxIdFFOn = makeIsFeatureFlagOn(TaaSValidationTaxIdFF);
export const isDisableBambooCrowdServerOptionAddonsFFOn = makeIsFeatureFlagOn(
  DisableBambooCrowdServerAddonsFF,
);
export const isAcademicPricingToggleFFOn = makeIsFeatureFlagOn(AcademicPricingToggleFF);
export const isServerAddonsEndgameFFOn = makeIsFeatureFlagOn(ServerAddonsEndgameFF);
