/* eslint-disable import/prefer-default-export */
// @flow
import { createSelector } from 'reselect';
import type { Selector } from 'model/State';
import { selectAccounts } from 'modules/change/table/duck/selectors';
import { isAddonForServer, isDataCenterProduct } from 'model/hams/Pricing';

export const selectShouldDisplayDataCenterApprovedAppsMessage: Selector<boolean> = createSelector(
  selectAccounts,
  (accountsProgress) =>
    !!accountsProgress.ifSuccess(
      (accounts) =>
        accounts.some((account) => isDataCenterProduct(account.productType)) &&
        accounts.some((account) => isAddonForServer(account.productType)),
    ),
);
