import { AxiosRequestConfig } from 'axios';
import hamsClient from 'modules/hams';
import { CartCountryValidation } from 'modules/checkout/contacts/duck/model';

export default async (
  country: string,
  cartId: string,
  hamsClientConfig: AxiosRequestConfig,
): Promise<CartCountryValidation> =>
  (
    await hamsClient.get(
      `/1.0/public/order/cart/${cartId}/validate-country-on-cart/${country}`,
      hamsClientConfig,
    )
  ).data;
