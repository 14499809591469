import { AxiosRequestConfig } from 'axios';
import hamsClient from 'modules/hams';
import { paypalPayload } from 'modules/checkout/review/duck/model';
import { setInteractionError } from '@atlassian/ufo-set-interaction-error';

export default async (clientConfig: AxiosRequestConfig, cartUuid: string, payload: paypalPayload) =>
  (
    await hamsClient
      .post(`/1.0/public/order/cart/${cartUuid}/processOrderWithPayPal`, payload, clientConfig)
      .then((r) => {
        if (r.status === 400) {
          setInteractionError('cart-submit-paypal', {
            errorMessage: 'cart submit paypal error',
            name: 'cart submit paypal error',
          });
        }
        return r;
      })
  ).data;
