import { combineReducers, Reducer } from 'redux';
import settings from 'modules/settings';
import navigation from 'reducers/navigation';
import { reducer as auth } from 'modules/auth';

import account from 'modules/accounts';
import addon from 'modules/addon';
import cart from 'modules/cart';
import catalog from 'modules/catalog';
import change from 'modules/change';
import marketplace from 'modules/marketplace';
import { reducer as checkout } from 'modules/checkout';
import { reducer as data } from 'modules/data';
import addToCart from 'modules/addtocart';
import configure from 'modules/configure';
import promotion from 'modules/promotion';
import errorInfo from 'modules/error-info';
import i18n from 'modules/i18n';
import upgrade from 'modules/upgrade';
import meta from 'modules/meta';
import { siteConfigurator } from 'modules/siteconfigurator';
import mac from 'modules/mac';

export default (reducers: Reducer) =>
  combineReducers({
    ...reducers,
    errorInfo,
    account,
    addon,
    auth,
    addToCart,
    cart,
    catalog,
    change,
    checkout,
    configure,
    data,
    i18n,
    meta,
    navigation,
    promotion,
    marketplace,
    settings,
    siteConfigurator,
    upgrade,
    mac,
  });
