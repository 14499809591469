import * as React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import checkCircle from 'modules/change/landing/img/check-circle.svg';
import { Dimensions } from 'components/visuals';

type Props = {
  description: React.ReactNode;
};

const ListItem = styled.li`
  margin: ${Dimensions.units(2.5)}px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Description = styled.span`
  color: ${colors.N900};
  margin-left: ${Dimensions.units(3)}px;
`;

export default ({ description }: Props) => (
  <ListItem>
    <img src={checkCircle} alt="check circle" />
    <Description>{description}</Description>
  </ListItem>
);
