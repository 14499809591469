import { combineEpics } from 'redux-observable';

import { actions } from 'modules/checkout/review';

import { HamsOrderStatusCode } from '../checkout/review/duck/actions';
import { timeAction, counter, DEFAULT_RESULT_FILTER } from './util';

import {
  CART_CONVERT_TO_QUOTE,
  CREDIT_CARD,
  FREE,
  PAY_ON_ACCOUNT,
  PAYPAL,
} from 'modules/checkout/review/duck/actions/CheckoutTypes';

const aspectMapper = (action) => `cart.submit.${action.checkoutType || 'unknown'}`;

const isValidationError = (error) =>
  error.errorKey === 'cart.invalid' ||
  error.errorKey === 'export.restriction' ||
  error === HamsOrderStatusCode.CREDIT_CARD_DECLINED;

const histogramBucketMapper = (action) => {
  switch (action.checkoutType) {
    case CART_CONVERT_TO_QUOTE:
    case PAY_ON_ACCOUNT:
    case CREDIT_CARD:
    case PAYPAL:
    case FREE:
      return '10000';
    default:
      return '7000';
  }
};

const isSuccess = (action) => {
  if (action.progress.error) {
    if (isValidationError(action.progress.error)) {
      return true;
    }
  }
  return DEFAULT_RESULT_FILTER(action);
};

export default combineEpics(
  counter(actions.CHECKOUT, aspectMapper, { isSuccess }),
  timeAction(actions.CHECKOUT, aspectMapper, histogramBucketMapper, { isSuccess }),
);
