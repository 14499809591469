/* eslint-disable no-empty */
// @flow
import { getCohortInformation } from 'modules/meta/cohort';
import environment from 'appconfig/environment';
import type { State } from 'model/State';
import { selectPurchaseMode } from 'modules/settings/purchaseMode';
import {
  PropsSelector,
  getCartSaleChannel,
  getProductKey,
  getProductSEN,
} from 'modules/tracking/selectors/props';

const commonSelector: PropsSelector = (state: State) => ({
  buyingMode: selectPurchaseMode(state),
  env: environment,
  atlCohort: {
    ...getCohortInformation(),
  },
  cartSaleChannel: getCartSaleChannel(state),
  productKey: getProductKey(state),
  productSEN: getProductSEN(state),
});

export default commonSelector;
