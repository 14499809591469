// @flow
import { getActionPath } from 'util/ActionLink';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import type { Dispatch } from 'model/State';

type OwnProps = {
  to: string | Object | null,
  children: React.Node,
  disabled?: boolean,
  style?: any,
  outline?: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  onClick?: () => void,
  className?: string,
};

type DispatchProps = {
  onActivation: (any) => void,
};

type Props = OwnProps & DispatchProps;

const Link = styled.a`
  color: inherit !important;
  text-decoration: none;

  &:focus {
    outline: ${(props) => (props.outline ? 'auto' : 'none')};
  }
`;

const NavLink = (props: Props) => (
  <Link
    href={getActionPath(props.to)}
    onClick={props.onActivation}
    style={props.style}
    disabled={props.disabled}
    outline={props.outline}
    className={props.className}
  >
    {props.children}
  </Link>
);

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
  onActivation: (e) => {
    if (ownProps.disabled) {
      e.preventDefault();
      return;
    }

    if (ownProps.onClick) ownProps.onClick();

    if (typeof ownProps.to !== 'string') {
      e.preventDefault();
      dispatch(ownProps.to);
    }
  },
});

NavLink.defaultProps = {
  style: {},
  disabled: false,
  outline: true,
  onClick: () => {},
  className: '',
};

export default connect(null, mapDispatchToProps)(NavLink);
