// @flow
import uuidv1 from 'uuid/v1';

import type { Dispatch } from 'model/State';
import { reportErrorPayload } from '../../util/errorReporter';

// Currently not doing anything in prod.
// But we may consider having an error banner or error page in the future if deemed necessary.
export const REPORT = 'uncaughtError/REPORT';

export const report = (error: any) => {
  const uuid = error.uuid === undefined ? uuidv1() : error.uuid;
  const payload = {
    error,
    uuid,
  };

  reportErrorPayload(payload);

  return payload;
};

export const reportAction = (error: any) => {
  const payload = report(error);

  return {
    type: REPORT,
    payload,
  };
};

const reducer = (state: any = null, action: *) => {
  switch (action.type) {
    case REPORT:
      return action.payload;
    default:
      return state;
  }
};

export const reportErrorMiddleware = () => (next: Dispatch) => (action: Object) => {
  try {
    return next(action);
  } catch (e) {
    return next(reportAction(e));
  }
};

export default reducer;
