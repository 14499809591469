// @flow
import React from 'react';

import AddonEntry from 'modules/marketplace/components/AddonEntry';
import { AddonList } from 'modules/marketplace/components/styled';
import type { MarketplaceAddon } from 'modules/catalog/addons';
import { getCloudVersionKey } from 'modules/catalog/addons';

type AppEntriesProps = {
  entries: MarketplaceAddon[],
  goAddon: (productKey: string) => void,
};

const AddonEntries = ({ entries, goAddon }: AppEntriesProps) => (
  <AddonList>
    {entries &&
      entries.map((entry) => (
        // We chose the cloud version of the product by deafult. The addon config page
        // will fall back to server/data center version depending on the supported
        // platforms
        <AddonEntry {...entry} goAddon={() => goAddon(getCloudVersionKey(entry.key))} />
      ))}
  </AddonList>
);

export default AddonEntries;
