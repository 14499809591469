// @flow
import Progress from 'redux-progress';
import * as actions from 'modules/checkout/payment/duck/actions';

export default (state: Progress<any> = Progress.none, action: *) => {
  switch (action.type) {
    case actions.TNS_FOCUS:
    case actions.ROUTE:
      return Progress.none;
    case actions.SUBMIT:
      return action.progress;
    default:
      return state;
  }
};
