// @flow
import * as actions from 'modules/checkout/payment/duck/actions';

export default (state: boolean = false, action: *) => {
  switch (action.type) {
    case actions.ROUTE:
    case actions.SELECT_MODE:
      return false;
    case actions.AGREE_TO_PAY_ON_TERM:
      return action.payload;
    default:
      return state;
  }
};
