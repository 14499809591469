import { ExternalProductKeys } from 'data/products/keys';
import t from 'modules/i18n/intl';
import ExternalProduct from 'model/ExternalProduct';
import config from 'appconfig';

const guard = new ExternalProduct({
  key: ExternalProductKeys.GUARD,
  tagLine: t('products.guard.tagline'),
  description: t('products.guard.description'),
  name: t('products.guard.name'),
  externalUrl: `${config.adminHubUrl}/atlassian-access`,
});

export default guard;
