/* eslint-disable func-names,consistent-return,no-continue,no-restricted-syntax */
import validateEmailAddress from 'util/email';
import Progress from 'redux-progress';

import { createSelector } from 'reselect';
import trim from 'lodash/trim';
import get from 'lodash/get';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import type {
  EnhancedOrganisation,
  CartCountryValidation,
  TCCountryValidation,
} from 'modules/checkout/contacts/duck/model';
import {
  isCountryPurchaserContactMismatch,
  isCountryTechnicalContactMismatch,
} from 'modules/checkout/contacts/duck/selectors/getContactValidations';
import { cartCurrencyCountryValidation } from 'modules/checkout/contacts/duck/selectors/getCartCurrencyValidation';
import {
  getBillingOrganisation,
  getTechnicalOrganisation,
} from 'modules/checkout/contacts/duck/selectors/getEnhancedOrganisations';
import selectData from 'modules/checkout/contacts/duck/selectors/getValidity/selectData';
import {
  hasNewItemsInCart,
  hasAllRenewalItemsFree,
} from 'modules/cart/duck/selectors/findSaleTypeInCart';
import type { Selector } from 'model/State';
import t from 'modules/i18n/intl';
import type { Country } from 'modules/data/countries/model';
import { RESTRICTED_COUNTRIES_ISO_CODES, RESTRICTED_EMAIL_DOMAINS } from 'modules/utils';
import { extractTLDFrom } from '../../../../../siteconfigurator/utils';

const sameString = (a?: string, b?: string) => trim(a) === trim(b);

const checkCountryRestriction = (iso: string) => RESTRICTED_COUNTRIES_ISO_CODES.includes(iso);
const checkDomainRestriction = (domain: string) =>
  RESTRICTED_EMAIL_DOMAINS.includes(extractTLDFrom(domain));

const validateEmail = (email) => {
  if (!validateEmailAddress(email)) {
    return t('checkout.contact.valid-email-address');
  }
};

const validateUsExportRestriction = (country?: Country) => {
  if (country && country.usExportRestriction) {
    return t('checkout.contact.country-export-validation-message', {
      countryName: country.displayName,
    });
  }
};

const validateStateProvince = (stateProvince: string, country?: Country) => {
  if (
    country &&
    country.stateNames &&
    country.stateNames.length > 0 &&
    !country.stateNames.includes(stateProvince)
  ) {
    return t('checkout.contact.valid-province-abbv');
  }
};

const validateCountryCurrencyMisMatchForBillingContact = (
  countryCurrencyMisMatchValidation: boolean,
) => {
  if (countryCurrencyMisMatchValidation) {
    return (
      <FormattedMessage
        id="checkout.contact.country-currency-change"
        values={{
          customerSupport: <a href="https://support.atlassian.com">{t('customer.support')}</a>,
        }}
      />
    );
  }
};

const validateCountryCurrencyMisMatchForTechnicalContact = (
  countryCurrencyMisMatchValidation?: TCCountryValidation,
  cartCurrencyCountryMismatchValidation?: CartCountryValidation,
) => {
  if (
    !get(countryCurrencyMisMatchValidation, 'compatible', true) &&
    get(cartCurrencyCountryMismatchValidation, 'compatible', true)
  ) {
    return t('checkout.contact.country-currency-change-different-technical-contact');
  }
};

const validateCartCurrencyCountryMismatch = (cartValidation: CartCountryValidation) =>
  !get(cartValidation, 'compatible', true) && t('checkout.contact.country-cart-currency-error');

const isTechnicalContactInCompatible = (
  isDifferentTechnical,
  countryBCMismatch,
  countryTCMismatch,
) => (!isDifferentTechnical ? countryBCMismatch : !get(countryTCMismatch, 'compatible', true));

const warnCartCurrencyCountryMismatch = (
  isDifferentTechnical: boolean,
  countryBCMismatch: boolean,
  cartCurrencyCountryMismatchValidation?: CartCountryValidation,
  countryTCMismatch?: TCCountryValidation,
) => {
  if (
    isTechnicalContactInCompatible(isDifferentTechnical, countryBCMismatch, countryTCMismatch) &&
    get(cartCurrencyCountryMismatchValidation, 'compatible', false) &&
    get(cartCurrencyCountryMismatchValidation, 'lockedCartCurrency', '')
  ) {
    return !isDifferentTechnical
      ? validateCountryCurrencyMisMatchForBillingContact(true)
      : t('checkout.contact.country-cart-currency-warning');
  }
};

const validateDisabledRussianPurchase = (iso, hasNewItem, hasAllRenewalFree) => {
  if ((hasNewItem?.result || !hasAllRenewalFree?.result) && checkCountryRestriction(iso)) {
    return (
      <FormattedMessage
        id="checkout.contact.country-disabled-russia"
        values={{
          learnMore: (
            <a href="https://www.atlassian.com/blog/announcements/atlassian-stands-with-ukraine">
              {t('purchase.learn-more')}
            </a>
          ),
        }}
      />
    );
  }
};

const validateDisabledEmailRussianPurchase = (domain, hasNewItem, hasAllRenewalFree) => {
  if ((hasNewItem?.result || !hasAllRenewalFree?.result) && checkDomainRestriction(domain)) {
    return (
      <FormattedMessage
        id="checkout.contact.country-disabled-russia"
        values={{
          learnMore: (
            <a href="https://www.atlassian.com/blog/announcements/atlassian-stands-with-ukraine">
              {t('purchase.learn-more')}
            </a>
          ),
        }}
      />
    );
  }
};

const validate = (
  path: string,
  selects: Selector<any>[],
  validator: (arg: string, ...arg1: any[]) => any,
) =>
  createSelector(
    selectData((c) => get(c, path)),
    ...(selects as []),
    (value, ...more) => {
      if (value) {
        const error = validator(value, ...more);
        if (error) {
          return { [path]: Progress.fail({ text: error }) };
        }
      }
    },
  );

const getCountry = (getOrg: Selector<Progress<EnhancedOrganisation>>) =>
  createSelector(getOrg, (org) => org.map((o) => o.country).result);
const getBillingCountry = getCountry(getBillingOrganisation);
const getTechnicalCountry = getCountry(getTechnicalOrganisation);

const clientValidators = [
  validate('purchaserContactDetails.email', [], validateEmail),
  validate('billingContactDetails.email', [], validateEmail),
  validate('technicalContactDetails.email', [], validateEmail),

  validate('billingOrganisationDetails.isoCountryCode', [getBillingCountry], (iso, country) =>
    validateUsExportRestriction(country),
  ),

  validate('billingOrganisationDetails.state', [getBillingCountry], validateStateProvince),

  validate('technicalOrganisationDetails.isoCountryCode', [getTechnicalCountry], (iso, country) =>
    validateUsExportRestriction(country),
  ),

  validate('technicalOrganisationDetails.state', [getTechnicalCountry], validateStateProvince),

  validate(
    'billingOrganisationDetails.isoCountryCode',
    [isCountryPurchaserContactMismatch, selectData((d) => d.isDifferentTechnical)],
    (iso, countryCurrencyMisMatchValidation, isDifferentTechnical) =>
      !isDifferentTechnical &&
      validateCountryCurrencyMisMatchForBillingContact(countryCurrencyMisMatchValidation),
  ),

  validate(
    'technicalContactDetails.email',
    [
      selectData((d) => d.isDifferentTechnical),
      isCountryTechnicalContactMismatch,
      cartCurrencyCountryValidation,
    ],
    (email, isDifferentTechnical, countryCurrencyMisMatchValidation, cartCurrencyValidation) =>
      isDifferentTechnical &&
      validateCountryCurrencyMisMatchForTechnicalContact(
        countryCurrencyMisMatchValidation,
        cartCurrencyValidation,
      ),
  ),

  validate(
    'billingOrganisationDetails.isoCountryCode',
    [
      selectData((d) => d.isDifferentTechnical),
      cartCurrencyCountryValidation,
      isCountryPurchaserContactMismatch,
      isCountryTechnicalContactMismatch,
    ],
    (iso, isDifferentTechnical, cartCurrencyValidation, countryBCMismatch, countryTCMismatch) =>
      !isDifferentTechnical &&
      warnCartCurrencyCountryMismatch(
        isDifferentTechnical,
        countryBCMismatch,
        cartCurrencyValidation,
        countryTCMismatch,
      ),
  ),

  validate(
    'technicalContactDetails.email',
    [
      selectData((d) => d.isDifferentTechnical),
      cartCurrencyCountryValidation,
      isCountryPurchaserContactMismatch,
      isCountryTechnicalContactMismatch,
    ],
    (email, isDifferentTechnical, cartCurrencyValidation, countryBCMismatch, countryTCMismatch) =>
      isDifferentTechnical &&
      warnCartCurrencyCountryMismatch(
        isDifferentTechnical,
        countryBCMismatch,
        cartCurrencyValidation,
        countryTCMismatch,
      ),
  ),

  /* The Following 2 validations are for cart Currency Mismatch.
  Irrespective of who TC is if compatible is false we should show validation message
  for country saying it does not match the cart curerncy */

  validate(
    'billingOrganisationDetails.isoCountryCode',
    [cartCurrencyCountryValidation, selectData((d) => d.resellerOrder)],
    (iso, cartCurrencyValidation, isReseller) =>
      !isReseller && validateCartCurrencyCountryMismatch(cartCurrencyValidation),
  ),

  validate(
    'technicalOrganisationDetails.isoCountryCode',
    [cartCurrencyCountryValidation, selectData((d) => d.resellerOrder)],
    (iso, cartCurrencyValidation, isReseller) =>
      isReseller && validateCartCurrencyCountryMismatch(cartCurrencyValidation),
  ),

  validate(
    'technicalOrganisationDetails.organisationName',
    [
      selectData((d) => d.billingOrganisationDetails.organisationName),
      selectData((d) => d.resellerOrder),
    ],
    (techOrgName, billingOrg, reseller) => {
      if (reseller && sameString(techOrgName, billingOrg)) {
        return t('checkout.contact.technical.reseller.billing-address-validation');
      }
    },
  ),

  validate(
    'billingOrganisationDetails.isoCountryCode',
    [getBillingCountry, hasNewItemsInCart, hasAllRenewalItemsFree],
    (iso, country, hasNewItem, hasAllRenewalFree) =>
      validateDisabledRussianPurchase(iso, hasNewItem, hasAllRenewalFree),
  ),

  validate(
    'technicalOrganisationDetails.isoCountryCode',
    [getTechnicalCountry, hasNewItemsInCart, hasAllRenewalItemsFree],
    (iso, country, hasNewItem, hasAllRenewalFree) =>
      validateDisabledRussianPurchase(iso, hasNewItem, hasAllRenewalFree),
  ),

  validate(
    'purchaserContactDetails.email',
    [hasNewItemsInCart, hasAllRenewalItemsFree],
    (email, hasNewItem, hasAllRenewalFree) =>
      validateDisabledEmailRussianPurchase(email, hasNewItem, hasAllRenewalFree),
  ),
  validate(
    'billingContactDetails.email',
    [hasNewItemsInCart, hasAllRenewalItemsFree],
    (email, hasNewItem, hasAllRenewalFree) =>
      validateDisabledEmailRussianPurchase(email, hasNewItem, hasAllRenewalFree),
  ),

  validate(
    'technicalContactDetails.email',
    [hasNewItemsInCart, hasAllRenewalItemsFree],
    (email, hasNewItem, hasAllRenewalFree) =>
      validateDisabledEmailRussianPurchase(email, hasNewItem, hasAllRenewalFree),
  ),
];

export default createSelector(clientValidators, (...errors) => Object.assign({}, ...errors));
