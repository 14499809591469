// @flow
import type { Dispatch, GetState } from 'model/State';
import type { RoutesMap, thunk } from 'routes';
/**
 * Accepts a routes map, and generates an equivalent
 * with all the routes thunks wrapped - even those that aren't defined
 * @param geneateWrapper the wrapper generation function - will be called once for each thunk
 */

export type WrapperFunction = (thunk) => (dispatch: Dispatch, getState: GetState) => Promise<any>;

const defaultThunk = () => Promise.resolve(true);

const applyWrappingFunction = (
  routesMap: RoutesMap,
  wrapperFunction: WrapperFunction,
): RoutesMap => {
  const result = {};

  Object.keys(routesMap).forEach((key) => {
    if (typeof routesMap[key] === 'object') {
      result[key] = {
        ...routesMap[key],
        thunk: wrapperFunction(routesMap[key].thunk || defaultThunk),
      };
    } else if (typeof routesMap[key] === 'string') {
      result[key] = {
        path: routesMap[key],
        thunk: wrapperFunction(defaultThunk),
      };
    } else throw Error(`Unknown route type in routes map: ${key}`);
  });

  return result;
};

const wrapRouteThunks = (...wrappers: WrapperFunction[]) => (routesMap: RoutesMap) =>
  wrappers.reduce(applyWrappingFunction, routesMap);

export default wrapRouteThunks;
