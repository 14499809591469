import Progress, { thunkProgress } from 'redux-progress';
import { Dispatch, GetState } from 'model/State';
import { selectors } from '..';
import hamsClient from 'modules/hams';
import { selectHamsClientConfig } from '../selectors';

export const FETCH_CURRENT_CONTACT = 'FETCH_CURRENT_CONTACT';
export const fetchCurrentContact = () => async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  if (selectors.isXsrfPresent(state)) {
    await dispatch(
      thunkProgress(
        FETCH_CURRENT_CONTACT,
        hamsClient
          .get('/1.0/public/contact/currentUser', selectHamsClientConfig(state))
          .then((r) => r.data),
      ),
    );
  } else {
    dispatch({
      type: FETCH_CURRENT_CONTACT,
      progress: Progress.success(null),
    });
  }
};
