// @flow
import { CLOSE_MENU, TOGGLE_MENU } from 'actions/navigation/topDropdownMenu';

export default function topDropdownMenu(state: string | null = null, action: *) {
  switch (action.type) {
    case TOGGLE_MENU:
      return state === action.payload ? null : action.payload;
    case CLOSE_MENU:
      return null;
    default:
      return state;
  }
}
