/* eslint-disable import/prefer-default-export */
// @flow
import getAutoRenewItems from 'modules/checkout/payment/duck/selectors/getAutoRenewItems';
import { selectAutoRenewItems } from 'modules/checkout/payment/duck/actions/selectAutoRenewItems';
import type { Dispatch, GetState } from 'model/State';

export const selectAutoRenewAll = (selected: boolean) => (dispatch: Dispatch, getState: GetState) =>
  getAutoRenewItems(getState()).ifSuccess((items) =>
    dispatch(
      selectAutoRenewItems(
        selected,
        items.map((i) => i.id),
      ),
    ),
  );
