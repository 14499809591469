import * as React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import type { LogoProps } from '@atlaskit/logo';
import {
  JiraSoftwareLogo,
  JiraLogo,
  JiraWorkManagementLogo,
  JiraServiceManagementLogo,
  StatuspageLogo,
  BitbucketLogo,
  ConfluenceLogo,
  GuardLogo,
} from '@atlaskit/logo';

import { Font, Dimensions } from 'components/visuals';
import Product from 'model/Product';
import type { LogoProductKey, ProductGroupKey, ProductKey } from 'data/products/keys';
import {
  DataCenterProductKeys,
  ExternalProductKeys,
  mapProductKeyToLegacyProductKey,
  ProductGroupKeys,
  ServerProductKeys,
  CloudProductKeys,
} from 'data/products/keys';

import BambooSvg from 'components/common/img/logo-bamboo.svg';
import CrowdSvg from 'components/common/img/logo-crowd.svg';
import CrucibleSvg from 'components/common/img/logo-crucible.svg';
import FisheyeSvg from 'components/common/img/logo-fisheye.svg';
import UniversitySvg from 'components/common/img/logo-university.svg';
import MarketplaceSvg from 'components/common/img/logo-marketplace.svg';
import JsmSVG from 'components/common/img/logo-jira-service-management.svg';
import JwmSVG from 'components/common/img/logo-jira-work-management.svg';
import AccessSVG from 'components/common/img/logo-access.svg';
import AtlasSVG from 'components/common/img/logo-atlas.svg';
import JpdSVG from 'components/common/img/logo-jira-product-discovery.svg';
import { getSoftwareProduct } from 'data/products';

// Atlaskit accepts "xsmall" | "small" | "medium" | "large" | "xlarge",
// but we have no need to support all logo sizes in the cart
type Size = 'small' | 'medium' | 'large';

type Props = {
  product: Product | string;
  alt?: string | React.ReactNode;
  size?: Size;
  setIsTextLogo?: (boolean) => {};
};

type ProductLogoElement = (props: LogoProps) => React.JSX.Element;

type IconsByProduct<IconType> = Partial<Record<LogoProductKey | ProductGroupKey, IconType>>;

const AtlaskitIcons: IconsByProduct<ProductLogoElement> = {
  [ProductGroupKeys.JIRA]: JiraLogo,
  [ProductGroupKeys.JIRA_SOFTWARE]: JiraSoftwareLogo,
  [ProductGroupKeys.JIRA_SERVICE_DESK]: JiraServiceManagementLogo,
  [ProductGroupKeys.JIRA_CORE]: JiraWorkManagementLogo,
  [ProductGroupKeys.CONFLUENCE]: ConfluenceLogo,
  [ProductGroupKeys.BITBUCKET]: BitbucketLogo,
  [ExternalProductKeys.STATUSPAGE]: StatuspageLogo,
  [ExternalProductKeys.GUARD]: GuardLogo,
};

// Icons not provided by Atlaskit - we pack them as SVGs
const SvgIcons: IconsByProduct<SVGElement> = {
  [DataCenterProductKeys.BAMBOO]: BambooSvg,
  [DataCenterProductKeys.CROWD]: CrowdSvg,
  [ServerProductKeys.BAMBOO]: BambooSvg,
  [ServerProductKeys.CROWD]: CrowdSvg,
  [ServerProductKeys.CRUCIBLE]: CrucibleSvg,
  [ServerProductKeys.FISHEYE]: FisheyeSvg,
  [ExternalProductKeys.UNIVERSITY]: UniversitySvg,
  [ExternalProductKeys.MARKETPLACE]: MarketplaceSvg,
  [ExternalProductKeys.ACCESS]: AccessSVG,
  [ExternalProductKeys.ATLAS]: AtlasSVG,
  [ProductGroupKeys.JIRA_PRODUCT_DISCOVERY as ProductGroupKeys]: JpdSVG,
};

const SvgLogo = styled.img`
  height: ${Dimensions.units(3)}px;
  max-width: 260px;
`;

const JWMSvgLogo = styled.img`
  height: ${Dimensions.units(5)}px;
  max-width: 260px;
`;

type TextSize = 'small' | 'medium' | 'large';
type TextProps = {
  fontSize: string;
  lineHeight: string;
};
type TextSizes = Record<TextSize, TextProps>;

const px20 = `${Dimensions.units(2.5)}px`;
const px24 = `${Dimensions.units(3)}px`;
const px32 = `${Dimensions.units(4)}px`;

const textSizes: TextSizes = {
  small: {
    fontSize: px20,
    lineHeight: px20,
  },
  medium: {
    fontSize: px24,
    lineHeight: px24,
  },
  large: {
    fontSize: px32,
    lineHeight: px32,
  },
};

type TextProductLogoProps = { size?: TextSize };

export const TextProductLogo = styled.p<TextProductLogoProps>`
  color: ${colors.N800};
  line-height: ${(props) => textSizes[props.size || 'medium'].lineHeight};
  font-size: ${(props) => textSizes[props.size || 'medium'].fontSize};
  margin: 8px 0;
  font-family: ${Font.family};
`;

const isJSDProductGroup = (key: string) => {
  const product = getSoftwareProduct(key);
  return product && product.groupKey === ProductGroupKeys.JIRA_SERVICE_DESK;
};

const getImageLogo = (key: any, size) => {
  // lookup bundled svg icon
  if (key in SvgIcons) {
    const svg = SvgIcons[key];
    return <SvgLogo src={svg} alt={key} />;
  }

  // Spork Initiative: JSW to Jira rebranding
  if (key === CloudProductKeys.JIRA_SOFTWARE) {
    const AkLogo = AtlaskitIcons[ProductGroupKeys.JIRA];
    return AkLogo && <AkLogo iconColor={colors.B400} textColor={colors.N800} size={size} />;
  }

  // lookup per product key
  if (key in AtlaskitIcons) {
    const AkLogo = AtlaskitIcons[key];
    return <AkLogo iconColor={colors.B400} textColor={colors.N800} size={size} />;
  }

  // if software product, lookup per group key
  const product = getSoftwareProduct(key);
  if (product && product.groupKey in AtlaskitIcons) {
    const AkLogo = AtlaskitIcons[product.groupKey];
    return <AkLogo iconColor={colors.B400} textColor={colors.N800} size={size} />;
  }

  return null;
};

const ProductLogo = ({ product, size, setIsTextLogo, alt }: Props) => {
  const productKey = (typeof product === 'string' ? product : product?.key) as ProductKey;
  const key: any = mapProductKeyToLegacyProductKey(productKey);

  if (isJSDProductGroup(key)) return <SvgLogo src={JsmSVG} alt={key} />;

  if (key === CloudProductKeys.JIRA_CORE) return <JWMSvgLogo src={JwmSVG} alt={key} />;

  const imageLogo = getImageLogo(key, size);
  setIsTextLogo?.(!imageLogo);

  return (
    imageLogo || (
      <TextProductLogo size={size}>
        {product instanceof Product ? product.name : alt}
      </TextProductLogo>
    )
  );
};

ProductLogo.defaultProps = {
  size: 'medium',
  alt: '',
};

export default ProductLogo;
