/* eslint-disable react/jsx-no-target-blank */
// @flow
import React from 'react';
import { Checkbox } from '@atlaskit/checkbox';
import styled from 'styled-components';

import AtlassianEulaTerms from 'modules/checkout/review/eula/AtlassianEulaTerms';
import type { HamsCart } from 'modules/cart/duck/model';

type Props = {
  cart: HamsCart,
  eulaAccepted: boolean,
  onEulaAcceptedChange: () => void,
  isDisabled?: boolean,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const AtlassianEulaCheckbox = ({ cart, eulaAccepted, onEulaAcceptedChange, isDisabled }: Props) => (
  <Wrapper>
    <Checkbox
      label={<AtlassianEulaTerms cart={cart} />}
      value="true"
      name="eula"
      isChecked={!isDisabled && eulaAccepted}
      onChange={onEulaAcceptedChange}
      isDisabled={isDisabled}
    />
  </Wrapper>
);

export default AtlassianEulaCheckbox;
