import Progress from 'redux-progress';
import get from 'lodash/get';
import { saleType } from 'model/hams/SaleType';
import { Selector, State } from 'model/State';
import { HamsCartItem } from 'modules/cart/duck/model';
import { isCartAFreeOrder } from './getIsFreeOrder';

export const isRenewalItem = (item: HamsCartItem) =>
  get(item, 'productDetails.saleType') === saleType.RENEWAL;
export const isUpgradeItem = (item: HamsCartItem) =>
  get(item, 'productDetails.saleType') === saleType.UPGRADE;
export const isCrossGradeItem = (item: HamsCartItem) =>
  !!get(item, 'crossgradeAccountId') && get(item, 'productDetails.saleType') === saleType.NEW;
export const isNewItem = (item: HamsCartItem) =>
  get(item, 'productDetails.saleType') === saleType.NEW;

const hasOnlyNewItemsInCart: Selector<Progress<boolean | null>> = (state: State) =>
  state.cart.payload.map(
    (cart) =>
      cart &&
      !cart.items.some(isCrossGradeItem) &&
      !cart.items.some(isRenewalItem) &&
      !cart.items.some(isUpgradeItem),
  );

export const hasAllRenewalItemsFree: Selector<Progress<boolean | null>> = (state: State) =>
  state.cart.payload.map(
    (cart) => !!cart?.items.length && cart.items.every(isRenewalItem) && isCartAFreeOrder(cart),
  );

export const hasNewItemsInCart: Selector<Progress<boolean | null>> = (state: State) =>
  state.cart.payload.map((cart) => cart && cart.items.some(isNewItem));
export default hasOnlyNewItemsInCart;
