import React from 'react';

import { GridColumn } from '@atlaskit/page';

import { GridRow } from 'modules/migrate/Layout';

export const GridWrapper = ({ children }) => (
  <GridRow>
    <GridColumn medium={1} />
    <GridColumn medium={10}>{children}</GridColumn>
    <GridColumn medium={1} />
  </GridRow>
);
