// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SupportProductKeys } from 'data/products/keys';
import SupportProduct from 'model/SupportProduct';
import t from 'modules/i18n/intl';

const premierSupport = new SupportProduct({
  key: SupportProductKeys.PREMIER_SUPPORT,
  tagLine: t('products.premier-support.tagline'),
  description: t('products.premier-support.description'),
  name: t('products.premier-support.name'),
  longDescription: (
    <FormattedMessage
      id="products.premier-support.longDescription"
      values={{
        threeNamedContacts: (
          <b>{t('products.premier-support.longDescription.threeNamedContacts')}</b>
        ),
      }}
    />
  ),
  moreLink:
    'https://confluence.atlassian.com/support/premier-support-offering-details-593035672.html',
});

export default premierSupport;
