import { Selector } from 'model/State';
import { CheckoutModes, CheckoutModesType } from 'modules/checkout/payment/duck/model';
import { PurchaseModes } from 'modules/settings/purchaseMode';

const allowedPaymentMethods: Array<CheckoutModesType> = [
  CheckoutModes.STRIPE_CREDIT_CARD,
  CheckoutModes.CREDIT_CARD,
  CheckoutModes.PAYPAL,
];

export default (state): Selector<boolean> =>
  state.cart.payload.ifSuccess((cart) => {
    if (!cart) {
      return false;
    }

    const isSupportedPurchaseMode = state.settings.purchaseMode === PurchaseModes.BUY;
    const isSupportedPaymentMethod = allowedPaymentMethods.includes(
      state.checkout.payment.checkoutMode,
    );

    return (
      isSupportedPurchaseMode &&
      isSupportedPaymentMethod &&
      !cart.expertOrder &&
      !cart.resellerOrder
    );
  }) || false;
