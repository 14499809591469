/* eslint-disable import/prefer-default-export */
// @flow
import get from 'lodash/get';
import Progress from 'redux-progress';
import type { TaxRate } from 'modules/data/taxRates/model';
import type { Selector } from 'model/State';

export const getTaxRate: Selector<
  Progress<TaxRate>,
  { isoCountryCode: string, stateKey: string },
> = (state, props) =>
  get(state.data.taxRates, [props.isoCountryCode, props.stateKey]) || Progress.none;
