import React from 'react';
import { FormattedMessage } from 'react-intl';
import { JiraLogo } from '@atlaskit/logo';
import { colors } from '@atlaskit/theme';
import {
  Column,
  ColumnWrapper,
  Header,
  LinkWrapper,
  Logo,
  LogoWrapper,
  Subtext,
  Wrapper,
} from 'components/navigation/top/dropdowns/commons';
import config from 'appconfig';
import Link from 'components/common/Link';
import { Dimensions, Font } from 'components/visuals';
import JsmSVG from 'components/common/img/logo-jira-service-management.svg';
import JwmSVG from 'components/common/img/logo-jira-work-management.svg';

const WAC = config.wacUrl;

const height15px = { height: `${Dimensions.units(2) - 1}px` };
const height16px = { height: `${Dimensions.units(2)}px` };
const height17px = { height: `${Dimensions.units(2) + 1}px` };
const height19px = { height: `${Dimensions.units(2) + 3}px` };
const height20px = { height: `${Dimensions.units(2.5)}px` };
const height35px = { height: `${Dimensions.units(4) + 3}px` };

const ProductsDropdown: React.FC = () => (
  <Wrapper>
    <ColumnWrapper>
      <Column>
        <Header>
          <FormattedMessage id="products.jira-software.header.text" />
        </Header>
        <Link to={`${WAC}/software/jira`}>
          <LinkWrapper>
            <LogoWrapper>
              <Logo style={height20px}>
                <JiraLogo size="small" iconColor={colors.B400} textColor={colors.N800} />
              </Logo>
            </LogoWrapper>
            <div>
              <Subtext>
                <FormattedMessage id="products.jira-software.subtext" />
              </Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to={`${WAC}/software/jira/service-management`}>
          <LinkWrapper>
            <LogoWrapper>
              <Logo style={height20px}>
                <img alt="Jira Service Desk" src={JsmSVG} width="" height="20" />
              </Logo>
            </LogoWrapper>
            <div>
              <Subtext>
                <FormattedMessage id="products.jira-servicemanagement.subtext" />
              </Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to={`${WAC}/software/jira/work-management`}>
          <LinkWrapper>
            <LogoWrapper>
              <Logo style={height35px}>
                <img alt="Jira Work Management" src={JwmSVG} width="" height="35" />
              </Logo>
            </LogoWrapper>
            <div>
              <Subtext>
                <FormattedMessage id="products.jira-core.subtext" />
              </Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to={`${WAC}/software/statuspage`}>
          <LinkWrapper>
            <LogoWrapper>
              <Logo style={height19px}>
                <img
                  alt="Statuspage"
                  src="https://wac-cdn.atlassian.com/dam/jcr:d909aeb4-b62e-4f59-8cf3-dc611bed6564/Statuspage-blue.svg?cdnVersion=jj"
                  width=""
                  height="19"
                />
              </Logo>
            </LogoWrapper>
            <div>
              <Subtext>
                <FormattedMessage id="products.statuspage.subtext" />
              </Subtext>
            </div>
          </LinkWrapper>
        </Link>
      </Column>
      <Column>
        <Header>
          <FormattedMessage id="products.confluence.header.text" />
        </Header>
        <Link to={`${WAC}/software/confluence`}>
          <LinkWrapper>
            <LogoWrapper>
              <Logo style={height16px}>
                <img
                  alt="Confluence"
                  src="https://wac-cdn.atlassian.com/dam/jcr:1b7f4009-27d0-4882-8629-cecae97fc00f/Confluence-blue.svg?cdnVersion=jj"
                  width=""
                  height="16"
                />
              </Logo>
            </LogoWrapper>
            <div>
              <Subtext>
                <Subtext>
                  <FormattedMessage id="products.confluence.subtext" />
                </Subtext>
              </Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to={`${WAC}/software/trello`}>
          <LinkWrapper>
            <LogoWrapper>
              <Logo style={height16px}>
                <img
                  alt="Trello"
                  src="https://wac-cdn.atlassian.com/dam/jcr:983f8304-39f8-4863-aa47-9a8908dcf768/Trello-icon-blue.svg?cdnVersion=jj"
                  width=""
                  height="16"
                />
              </Logo>
            </LogoWrapper>
            <div>
              <Subtext>
                <Subtext>
                  <FormattedMessage id="products.trello.subtext" />
                </Subtext>
              </Subtext>
            </div>
          </LinkWrapper>
        </Link>
      </Column>
      <Column>
        <Header>
          <FormattedMessage id="products.bitbucket.header.text" />
        </Header>
        <Link to={`${WAC}/software/bitbucket`}>
          <LinkWrapper>
            <LogoWrapper>
              <Logo style={height15px}>
                <img
                  alt="Bitbucket"
                  src="https://wac-cdn.atlassian.com/dam/jcr:bd56917e-e361-4f03-b672-9f5ef5b06e80/Bitbucket-blue.svg?cdnVersion=jj"
                  width=""
                  height="15"
                />
              </Logo>
            </LogoWrapper>
            <div>
              <Subtext>
                <FormattedMessage id="products.bitbucket.subtext" />
              </Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to={`${WAC}/software/sourcetree`}>
          <LinkWrapper>
            <LogoWrapper>
              <Logo style={height17px}>
                <img
                  alt="Sourcetree"
                  src="https://wac-cdn.atlassian.com/dam/jcr:f32681c1-355d-4806-b29c-319b0c6ecb06/Sourcetree-blue.svg?cdnVersion=jj"
                  width=""
                  height="17"
                />
              </Logo>
            </LogoWrapper>
            <div>
              <Subtext>
                <FormattedMessage id="products.sourcetree.subtext" />
              </Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to={`${WAC}/software/bamboo`}>
          <LinkWrapper>
            <LogoWrapper>
              <Logo style={height17px}>
                <img
                  alt="Bamboo"
                  src="https://wac-cdn.atlassian.com/dam/jcr:3565304e-e789-486b-a722-be19f067f7c7/Bamboo-blue.svg?cdnVersion=jj"
                  width=""
                  height="17"
                />
              </Logo>
            </LogoWrapper>
            <div>
              <Subtext>
                <FormattedMessage id="products.bamboo.subtext" />
              </Subtext>
            </div>
          </LinkWrapper>
        </Link>
      </Column>
    </ColumnWrapper>
    <Link style={{ fontFamily: Font.family }} to={`${WAC}/software`}>
      <FormattedMessage id="products.view-all" />
    </Link>
  </Wrapper>
);

export default ProductsDropdown;
