import { SET_IN_SITE_CONFIGURATOR_MODE } from '../actions';

export default function siteConfiguratorMode(state = false, action: any) {
  switch (action.type) {
    case SET_IN_SITE_CONFIGURATOR_MODE:
      return action.payload;
    default:
      return state;
  }
}
