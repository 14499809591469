// @flow
import type { MapToProps } from 'util/react-redux/connectStateAndDispatch';
import type { PaypalProps } from 'modules/checkout/payment/duck/model';
import { CheckoutModes } from 'modules/checkout/payment/duck/model';
import getAvailablePaymentMethods from './getAvailablePaymentMethods';

export const isStripeCheckoutModeSelected = (state) =>
  state.checkout.payment.checkoutMode === CheckoutModes.STRIPE_CREDIT_CARD;

const getStripeCreditCardProps: MapToProps<PaypalProps> = (state) =>
  getAvailablePaymentMethods(state).map((availablePaymentMethods) => ({
    ...state.checkout.payment.stripeCreditCard,
    isSelected: isStripeCheckoutModeSelected(state),
    isAvailable: availablePaymentMethods.stripe,
    validity: true,
  }));
export default getStripeCreditCardProps;
