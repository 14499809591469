/* eslint-disable react/no-unused-prop-types */
// @flow
import React from 'react';
import styled from 'styled-components';
import Progress from 'redux-progress';

import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { Dimensions, Font } from 'components/visuals';
import t from 'modules/i18n/intl';
import {
  GenericErrorMessageVisual,
  ContactUsAction,
  ReloadAction,
  getStartNewCartAction,
} from 'components/common/GenericErrorMessage';
import type { HamsCart } from 'modules/cart/duck/model';

export type Props = {
  submitProgress: Progress<HamsCart>,
  onAddToCart: () => void,
};

const ErrorWrapper = styled.div`
  text-align: left;
  margin-bottom: ${Dimensions.units(2)}px;
  font-family: ${Font.displayFamily};
  font-weight: 400;
`;

const getGenericErrorMessage = (props: Props) => (
  <ErrorWrapper>
    <GenericErrorMessageVisual
      actions={[
        {
          text: t('common.try-again'),
          onClick: props.onAddToCart,
        },
        ReloadAction,
        ContactUsAction,
      ]}
      error={props.submitProgress.error}
    />
  </ErrorWrapper>
);

const HTTP_RESPONSE_CONFLICT = 409;
const HTTP_RESPONSE_BAD_REQUEST = 400;

const AddToCartErrorMessage = (props: Props) => {
  if (!props.submitProgress.failed) {
    return null;
  }

  if (
    !props.submitProgress.error ||
    !props.submitProgress.error.response ||
    !props.submitProgress.error.response.status
  ) {
    return getGenericErrorMessage(props);
  }

  switch (props.submitProgress.error.response.status) {
    case HTTP_RESPONSE_BAD_REQUEST:
      return (
        <ErrorWrapper>
          <SectionMessage
            appearance="error"
            title={t('review.unable-to-process-cart')}
            actions={[getStartNewCartAction(), ContactUsAction].map(({ text, ...restAction }) => (
              <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
            ))}
          >
            {t('review.cart-expired')}
          </SectionMessage>
        </ErrorWrapper>
      );
    case HTTP_RESPONSE_CONFLICT:
      return (
        <ErrorWrapper>
          <SectionMessage appearance="error" title={t('cart.error-max-cart-items')} />
        </ErrorWrapper>
      );
    default:
      return getGenericErrorMessage(props);
  }
};

export default AddToCartErrorMessage;
