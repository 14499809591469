import { Selector } from 'model/State';
import { DeploymentOptions } from 'model/hams/DeploymentOption';
import { getCommerceProductKey } from 'modules/catalog/addons';

export const selectProductKeys: Selector<string[]> = (state) =>
  decodeURIComponent(state.location.payload.productKeys).split(',') || [];

export const getAllProductKeys: Selector<string[]> = (state) => {
  const productKeys = selectProductKeys(state);
  return productKeys.length === 1
    ? DeploymentOptions.map((option) => getCommerceProductKey(productKeys[0], option))
    : productKeys;
};
