// @flow
import CloudProduct from 'model/editions/CloudProduct';
import {
  CloudProductKeys,
  DataCenterProductKeys,
  ProductGroupKeys,
  ServerProductKeys,
} from 'data/products/keys';
import config from 'appconfig';
import t from 'modules/i18n/intl';
import ServerProduct from 'model/editions/ServerProduct';
import SoftwareProductGroup from 'model/SoftwareProductGroup';
import DataCenterProduct from 'model/editions/DataCenterProduct';

export const jiraCaptureServer = new ServerProduct({
  key: ServerProductKeys.JIRA_CAPTURE,
  groupKey: ProductGroupKeys.JIRA_CAPTURE,
  tagLine: t('products.com.atlassian.bonfire.plugin.tagline'),
  description: t('products.com.atlassian.bonfire.plugin.description'),
  name: t('products.com.atlassian.bonfire.plugin.name'),
  addon: true,
  vendor: 'Zephyr',
  moreLink: `${config.wacUrl}/software/jira-capture`,
  parentKeys: [ServerProductKeys.JIRA_SOFTWARE],
});

export const jiraCaptureDataCenter = new DataCenterProduct(
  DataCenterProductKeys.JIRA_CAPTURE,
  jiraCaptureServer,
);

export const jiraCaptureCloud = new CloudProduct(CloudProductKeys.JIRA_CAPTURE, jiraCaptureServer);

export const jiraCaptureProductGroup = new SoftwareProductGroup(
  jiraCaptureServer,
  jiraCaptureDataCenter,
  jiraCaptureCloud,
);
