import { ServerProductKeys, ServerProductKey } from 'data/products/keys';

export const ServerProductPricingLinks: { [product in ServerProductKey]?: string } = {
  [ServerProductKeys.BITBUCKET]: 'https://www.atlassian.com/software/bitbucket/pricing',
  [ServerProductKeys.CONFLUENCE]: 'https://www.atlassian.com/software/confluence/pricing',
  [ServerProductKeys.JIRA_SOFTWARE]: 'https://www.atlassian.com/software/jira/pricing',
  [ServerProductKeys.JIRA_SERVICE_DESK]:
    'https://www.atlassian.com/software/jira/service-management/pricing',
  [ServerProductKeys.JIRA_CORE]: 'https://www.atlassian.com/software/jira/work-management/pricing',
};
