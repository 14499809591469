import { ServerProductKeys, ServerProductKey, PricingPlan } from 'data/products/keys';
import { ServerProductPricingLinks } from 'data/products/links';
import appconfig from 'appconfig';

export type CloudPriceSetterArgumentType = {
  monthly: number;
  annual: number;
};

type PricingPlans = {
  FREE: PricingPlan;
  STANDARD: PricingPlan;
  PREMIUM: PricingPlan;
};

export interface setCloudPricingVariablesType {
  (cloudPrices: CloudPriceSetterArgumentType, accountId: number): void;
}

export interface CloudUnavailableProductsDetails {
  messageKey: string;
}

export const cloudUnavailableProducts: Pick<
  Record<ServerProductKey, CloudUnavailableProductsDetails>,
  'bitbucket' | 'bamboo'
> = {
  [ServerProductKeys.BITBUCKET]: {
    messageKey: 'migrate.table.row.cloud-price-not-displayable',
  },
  [ServerProductKeys.BAMBOO]: {
    messageKey: 'migrate.table.row.cicd-as-bitbucket-pipeline',
  },
};

export const DEPLOY_OPTION = {
  CLOUD: 'cloud',
  SERVER: 'server',
};

export const BILL_CYCLE = {
  MONTHLY: 'monthly',
  ANNUAL: 'annual',
};

export const PRICING_PLANS: PricingPlans = {
  FREE: 'Free',
  STANDARD: 'Standard',
  PREMIUM: 'Premium',
};

export const getPricingLinkForServerProduct = (key: ServerProductKey) =>
  ServerProductPricingLinks[key];

export const getComparatorUrl = (): string =>
  `${appconfig.wacUrl}/migration/assess/calculate-pricing`;
