/* eslint-disable import/prefer-default-export */

import Progress from 'redux-progress';
import { parseZone } from 'moment';
import find from 'lodash/find';
import uniq from 'lodash/uniq';

import { AccountEntry, TableStateProps } from 'modules/change/table/duck/model';
import {
  entryComparator,
  isMixedCurrency,
  isSelectAllDisabled,
  isAccountCurrencyMatchingCartCurrency,
} from 'modules/change/table/duck/util';
import {
  selectAmount,
  selectOriginalAmount,
  selectLoyaltyDiscount,
} from 'modules/change/table/duck/selectors/amount';
import {
  selectAccounts,
  selectFoundAccounts,
  selectAllAccounts,
} from 'modules/change/table/duck/selectors/accounts';
import { State, Selector } from 'model/State';

import { selectPurchaseMode } from 'modules/settings/purchaseMode';
import * as auth from 'modules/auth';
import { selectChangeCurrency } from 'modules/change/table/duck/selectors/currency';
import { selectXsrfToken } from 'modules/auth/selectors';

const buildEntry = (selected, optionsMap, currency) => (account): AccountEntry => {
  const changeOptions = optionsMap[account.accountId] || Progress.none;

  const availableTerms = changeOptions.map(({ optionsList }) =>
    Array.isArray(optionsList)
      ? uniq(optionsList.map((opt) => opt.maintenanceMonths)).map((months, i) => ({
          terms: i + 1,
          months,
          amount: 0,
        }))
      : [],
  );

  const selectedTerm = availableTerms.map(
    (terms) => find(terms, { months: selected.months[account.accountId] }) || terms[0] || {},
  );

  const selectedOption = Progress.all(changeOptions, selectedTerm).map(
    ([options, term]) =>
      find(options.optionsList, {
        orderableItemId: selected.orderableItemIds[account.accountId],
        maintenanceMonths: term.months,
      }) ||
      find(options.optionsList, {
        maintenanceMonths: term.months,
      }) ||
      (options.optionsList && options.optionsList[0]),
  );

  const isCurrencyMatchingLocked =
    changeOptions.ifSuccess(
      ({ optionsList }) => !isAccountCurrencyMatchingCartCurrency(account, optionsList, currency),
    ) || false;

  const hasChangeOptions =
    changeOptions.ifSuccess(({ optionsList }) => optionsList?.length > 0) || false;

  const isDisabled = !changeOptions.success || !hasChangeOptions || isCurrencyMatchingLocked;

  return {
    accountId: account.accountId,
    accountName: account.accountName,
    availableTerms,
    expiration: parseZone(account.expireDate),
    isSelected:
      !isDisabled && (selected.allAccounts || selected.accounts[account.accountId] === true),
    isDisabled,
    isCurrencyMatchingLocked,
    licenseType: account.licenseType,
    options: changeOptions,
    productKey: account.productKey,
    productName: account.productDescription,
    productType: account.productType,
    selectedOption,
    selectedTerm,
    unitCount: account.unitCount,
    unitLabel: account.unitLabel,
    isStarter: account.isStarter,
    serverRenewalPrice: account.serverRenewalPrice,
    accountCurrency: account.accountCurrency,
  };
};

const hasSelected = (selected) => Object.values(selected).some((val) => val === true);
const hasFoundSelected = (foundEntries) => foundEntries.find((e) => e.isSelected) !== undefined;

const getAccountEntries = (state: State, accounts: AccountEntry[], currency: string | null) =>
  accounts
    .map(buildEntry(state.change.table.selected, state.change.table.options, currency))
    .sort(entryComparator);

export const selectPageProps: Selector<Progress<TableStateProps>> = (state) =>
  Progress.all(
    /*
note XSRF token is added to the asynchronous dependency list but not used later on:
we want to wait for it to resolve but we don't need to know its value
 */
    selectXsrfToken(state),
    selectAccounts(state),
    selectFoundAccounts(state),
    selectAllAccounts(state),
    selectChangeCurrency(state),
  ).map(([, accounts, foundAccounts, allAccounts, currency]) => {
    const entries = getAccountEntries(state, accounts, currency);
    const foundEntries = getAccountEntries(state, foundAccounts, currency);
    const allEntries = getAccountEntries(state, allAccounts, currency);

    return {
      currency,
      amount: selectAmount(state),
      entries,
      foundEntries,
      isExpert: auth.selectors.getUserChannel(state).ifSuccess((uc) => uc.isExpert) || false,
      purchaseMode: selectPurchaseMode(state),
      ...state.change.table,
      addItemsProgress: Progress.all(state.cart.payload, state.change.table.progress),
      isMixedCurrency: isMixedCurrency(allAccounts),
      isSelectAllDisabled: isSelectAllDisabled(allEntries),
      originalAmount: selectOriginalAmount(state),
      loyaltyDiscount: selectLoyaltyDiscount(state),
      hasSelected: hasSelected(state.change.table.selected.accounts),
      hasFoundSelected: hasFoundSelected(foundEntries),
    };
  });
