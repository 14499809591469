import { createSelector } from 'reselect';

import cartSelector from './cart';

const selectCartBillingOrganisationDetails = createSelector(
  cartSelector,
  (cart) => cart?.result?.billingOrganisationDetails || null,
);

export const selectBillingOrganisationCountry = createSelector(
  selectCartBillingOrganisationDetails,
  (details) => details?.isoCountryCode || null,
);

export default selectCartBillingOrganisationDetails;
