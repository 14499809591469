import React from 'react';

import { FormSection as AkFormSection } from '@atlaskit/form';
import * as styles from 'modules/checkout/contacts/styles';
import Slide from 'modules/checkout/contacts/Slide';
import { Props } from 'modules/checkout/contacts/CheckoutContactPage';
import t from 'modules/i18n/intl';
import Help from 'components/common/Help';

import { AddressForm } from 'modules/checkout/contacts/AddressForm';

export default ({
  data,
  technicalOrg,
  countries,
  lockedCartCurrency,
  showCurrencyChangeWarning,
  hasOnlyNewItems,
  isDifferentTechnical,
  showReconcilableWarning,
  lockDifferentTechnical,
  showCompanyCountryCurrency,
}: Partial<Props>) => (
  <Slide in={data.resellerOrder} maxHeight={585}>
    <styles.Section>
      <AkFormSection
        title={
          <>
            {t('checkout.contact.technical.end-user.company-info')}
            <Help>{t('checkout.contact.technical.end-user.enter-company-info')}</Help>
          </>
        }
      />
      <AddressForm
        {...technicalOrg}
        name="technicalOrganisationDetails"
        country={technicalOrg?.country}
        countries={countries}
        enableTooltipOnCountrySelect={!!hasOnlyNewItems && isDifferentTechnical}
        hasReconcilableWarning={!!showReconcilableWarning}
        showWarning={showCurrencyChangeWarning && lockDifferentTechnical}
        cartCurrency={lockedCartCurrency}
        showCompanyCountryCurrency={!!showCompanyCountryCurrency}
        lockDifferentTechnical={!!lockDifferentTechnical}
        isDifferentTechnical={!!isDifferentTechnical}
      />
    </styles.Section>
  </Slide>
);
