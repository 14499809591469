// @flow
import { redirect } from 'redux-first-router';

import {
  selectOrderableItems,
  selectSelectedAddonKeys,
  selectTerm,
  selectAddonKeys,
  selectMainProductKey,
} from 'modules/configure/duck/selectors';
import { goProductConfig } from 'modules/configure/duck/actions/route';
import type { Dispatch, GetState } from 'model/State';
import type { TermOption } from 'model/TermOption';
import ProductTier from 'model/hams/ProductTier';
import * as review from 'modules/checkout/review';
import { actions as cartActions, selectors as cartSelectors } from 'modules/cart';
import { UIAction, UIActionSubject } from 'modules/analytics/model/UIEvent';
import { AnalyticsClient } from 'modules/analytics/CartAnalyticsClient';
import { setInteractionError } from '@atlassian/ufo-set-interaction-error';

export const SELECT_TERM = 'configure/SELECT_MAINTENANCE';
export const SELECT_TIER = 'configure/SELECT_TIER';
export const ADD_ADDONS = 'configure/ADD_ADDONS';
export const SET_ADDONS = 'configure/SET_ADDONS';
export const EXPAND_ADDON = 'configure/TOGGLE_ADDON_EXPAND';

export const setAddons = (addons: string[]) => ({
  type: SET_ADDONS,
  payload: {
    addons,
  },
  meta: {
    query: {
      addons,
    },
  },
});

export const addAddons = (addons: string[], selectedAddons: string[]) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const keys: string[] = selectAddonKeys(getState());
  const missingAddons: string[] = addons.filter((addon) => !keys.includes(addon));
  if (missingAddons.length > 0) {
    dispatch({
      type: ADD_ADDONS,
      payload: { addons: missingAddons, selectedAddons },
    });
  }
};

export const addProductToCart = (analytics?: AnalyticsClient) => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const state = getState();
  const { months } = selectTerm(state);
  const newItems = selectOrderableItems(state).map((item) => ({
    orderableItemId: item.orderableItemId,
    maintenanceMonths: months,
    renewalMonths: months,
    productKey: item.productKey,
  }));

  const addProductsBody = {
    newItems,
  };

  const result = await dispatch(cartActions.add(addProductsBody));
  if (result.success) {
    analytics?.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'productConfigurationAddToCartQuote',
      attributes: {
        eventContainer: 'productConfigurationPage',
        eventComponent: UIActionSubject.Button,
        parentLabel: UIActionSubject.ProductConfiguration,
        label: 'Add to cart/quote',
        uuid: 'e4161a8a-6ab6-4c48-a989-8735df74dbdf',
      },
    });

    if (cartSelectors.inEditMode(state)) {
      dispatch(review.actions.route());
      return;
    }
    dispatch(cartActions.route());
  }

  if (result.error) {
    setInteractionError('items-add', {
      errorMessage: 'add items error',
      name: 'add items error',
    });
  }
};

export const setTerm = (term: TermOption) => ({
  type: SELECT_TERM,
  payload: { terms: term.terms },
});

export const toggleAddon = (addon: string) => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const mainProductKey = selectMainProductKey(state);

  if (mainProductKey) {
    const selectedAddonKeys = [...selectSelectedAddonKeys(state)];
    const pos = selectedAddonKeys.indexOf(addon);
    if (pos === -1) {
      selectedAddonKeys.push(addon);
    } else {
      selectedAddonKeys.splice(pos, 1);
    }

    dispatch(redirect(goProductConfig(mainProductKey, selectedAddonKeys)));
  }
};

export const toggleAddonExpanded = (productKey: string) => ({
  type: EXPAND_ADDON,
  payload: { productKey },
});

export const selectProductTier = (productKey: string, tier: ProductTier) => ({
  type: SELECT_TIER,
  payload: {
    productKey,
    tier,
  },
});
