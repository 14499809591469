import * as React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { Dimensions, Font } from 'components/visuals';

const NormalCell = styled.td`
  font-family: ${Font.family};
  color: ${colors.N700};
  font-size: 16px;
  padding: ${Dimensions.units(2)}px 0;
  ${(props: Props) => (props.width ? `width: ${props.width}` : '')};

  &:last-child,
  &:nth-last-child(2) {
    text-align: right;
  }
`;

const ChildCell = styled(NormalCell)`
  &:first-child {
    padding-left: ${Dimensions.units(2)}px;
  }
`;

type Props = {
  isChild?: boolean;
  children?: React.ReactNode;
  width?: string;
};

const Cell = ({ isChild, children, width }: Props) =>
  isChild ? <ChildCell>{children}</ChildCell> : <NormalCell width={width}>{children}</NormalCell>;

Cell.defaultProps = { isChild: false, children: null };

export default Cell;
