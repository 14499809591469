import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { State } from 'model/State';

import { colors } from '@atlaskit/theme';
import { Font, Dimensions } from 'components/visuals';
import ProductLogo from 'components/common/ProductLogo';
import {
  LicenseType,
  LICENSE_ACADEMIC,
  LICENSE_COMMUNITY,
  LICENSE_OPENSOURCE,
} from 'model/hams/LicenseType';

import { makeIsFeatureFlagOn } from 'modules/meta/launchdarkly/selectors';

const ExtraInfo = styled.div.attrs({
  className: 'pii',
})`
  font-size: 12px;
  color: ${colors.N200};
`;

const ProductLogoWrapper = styled.div`
  img {
    max-width: 210px;
  }
`;

const ExpiryWrapper = styled.div`
  font-family: ${Font.displayFamily};
  font-size: 14px;
  color: ${colors.N900};
  font-weight: 500;
  margin-top: ${Dimensions.units(2)}px;
  margin-bottom: ${Dimensions.units(1)}px;
`;

const LicenseWrapper = styled.p`
  background-color: ${colors.N40};
  font-family: ${Font.displayFamily};
  font-size: ${Dimensions.units(1.5)}px;
  font-weight: 500;
  text-align: center;
  color: ${colors.N500};
  margin-top: ${Dimensions.units(2)}px;
  text-transform: uppercase;
  width: fit-content;
  padding: 0 ${Dimensions.units(0.5)}px;
  border-radius: 3px;
`;

interface Props {
  accountId: number | string;
  accountName: string;
  productKey: string;
  productName: string;
  expiryDate?: string;
  licenseType?: LicenseType;
  isNonCommercialOn: boolean;
}

const AccountSummary = ({
  accountId,
  accountName,
  productKey,
  productName,
  expiryDate,
  licenseType,
  isNonCommercialOn,
}: Props) => (
  <>
    <ProductLogoWrapper>
      <ProductLogo product={productKey} alt={productName} size="small" />
    </ProductLogoWrapper>
    {expiryDate && (
      <ExpiryWrapper>
        <FormattedMessage
          id="migrate.table.row.expiry"
          values={{
            date: (
              <FormattedDate
                value={new Date(expiryDate)}
                day="numeric"
                month="short"
                year="numeric"
              />
            ),
          }}
        />
      </ExpiryWrapper>
    )}
    <ExtraInfo> {productName} </ExtraInfo>
    <ExtraInfo> {`SEN-${accountId}`} </ExtraInfo>
    <ExtraInfo> {accountName} </ExtraInfo>
    {isNonCommercialOn && licenseType && (
      <LicenseWrapper>
        {licenseType === LICENSE_OPENSOURCE && (
          <FormattedMessage id="migrate.table.row.licenseType.opensource" />
        )}
        {licenseType === LICENSE_ACADEMIC && (
          <FormattedMessage id="migrate.table.row.licenseType.academic" />
        )}
        {licenseType === LICENSE_COMMUNITY && (
          <FormattedMessage id="migrate.table.row.licenseType.community" />
        )}
      </LicenseWrapper>
    )}
  </>
);

const isNonCommercialFeatureFlagOn = makeIsFeatureFlagOn('cart-carebear-comparator-non-commercial');

export default connect((state: State) => ({
  isNonCommercialOn: isNonCommercialFeatureFlagOn(state).result,
}))(AccountSummary);
