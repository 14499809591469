export const ROUTE = 'addon/ROUTE';

export const route = (productKeys: string | string[], promoCode?: string) => ({
  type: ROUTE,
  payload: {
    productKeys: typeof productKeys === 'string' ? productKeys : productKeys.join(','),
  },
  query: {
    promoCode,
  },
});
