import { Selector } from 'model/State';

export default ((state) => {
  const { contacts } = state.checkout;
  return [
    contacts.submitProgress,
    contacts.validateCountryContactMatch.purchaserContactValidation,
    contacts.validateCountryContactMatch.technicalContactValidation,
  ].some((p) => p.inProgress);
}) as Selector<Boolean>;
