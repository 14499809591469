/* eslint-disable no-param-reassign,default-case,consistent-return */
import Progress from 'redux-progress';
import { produce } from 'immer';
import set from 'lodash/set';
import * as actions from 'modules/checkout/contacts/duck/actions';

export default (state = Progress.none, action) => {
  switch (action.type) {
    case actions.LOAD:
      return action.progress;
    case actions.EDIT:
      return state.map(produce((data) => set(data, action.path, action.payload)));
    case actions.SUBMIT:
      if (action.progress.success) {
        return Progress.none;
      }
  }
  return state;
};
