// @flow
import type { MapToProps } from 'util/react-redux/connectStateAndDispatch';
import Progress from 'redux-progress';
import type { CashProps } from 'modules/checkout/payment/duck/model';
import { CheckoutModes } from 'modules/checkout/payment/duck/model';
import * as actions from 'modules/checkout/payment/duck/actions';
import getAvailablePaymentMethods from './getAvailablePaymentMethods';

const getCashProps: MapToProps<CashProps> = (state, dispatch) =>
  Progress.all(state.cart.payload, getAvailablePaymentMethods(state)).map(
    ([cart, availablePaymentMethods]) => {
      if (cart === null) {
        return null;
      }

      const canPayOnAccount = availablePaymentMethods.onAccount;
      const canPayOnTerm = availablePaymentMethods.onTerms;
      const canQuote = availablePaymentMethods.quote;

      const multipleOptionsAvailable =
        [canQuote, canPayOnAccount, canPayOnTerm].filter((can) => can).length > 1;

      return {
        isAvailable:
          (availablePaymentMethods.quote ||
            availablePaymentMethods.onAccount ||
            availablePaymentMethods.onTerms) &&
          !cart.creditCardOnly,
        isSelected: [
          CheckoutModes.BANK_TRANSFER,
          CheckoutModes.PAY_ON_ACCOUNT,
          CheckoutModes.PAY_ON_TERMS,
        ].includes(state.checkout.payment.checkoutMode),
        canPayOnAccount,
        canPayOnTerm,
        canQuote,
        payOnTermAgreed: state.checkout.payment.payOnTermAgreed,
        agreeToPayOnTerm: (checked) => dispatch(actions.agreeToPayOnTerm(checked)),
        defaultCheckoutMode:
          (availablePaymentMethods.quote && CheckoutModes.BANK_TRANSFER) ||
          (availablePaymentMethods.onAccount && CheckoutModes.PAY_ON_ACCOUNT) ||
          CheckoutModes.PAY_ON_TERMS,
        multipleOptionsAvailable,
      };
    },
  );

export default getCashProps;
