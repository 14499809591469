import React, { useContext, useEffect } from 'react';
import SectionMessage from '@atlaskit/section-message';
import { getRightErrorMessage } from 'util/getI18nErrorMessage';

import { CartAnalyticsContext } from 'modules/analytics';
import { analyticsEvents, AnalyticsTrigger } from 'modules/checkout/contacts/analytics';

type Props = {
  errorMessage: string,
};

export default ({ errorMessage }: Props) => {
  const analytics = useContext(CartAnalyticsContext);

  useEffect(() => {
    analyticsEvents[AnalyticsTrigger.CreditCardPaymentFailed](analytics, errorMessage);
  }, []);

  return (
    <SectionMessage appearance="error" title="Payment Error" isDismissAllowed>
      {getRightErrorMessage(errorMessage)}
    </SectionMessage>
  );
};
