// @flow
import * as dates from 'util/dates';
import find from 'lodash/find';
import Progress from 'redux-progress';
import { createSelector } from 'reselect';
import selectCartPayload, { showTierLabel } from 'modules/cart/duck/selectors/cart';
import type { AutoRenewItemProps } from 'modules/checkout/payment/duck/model';
import getCanAutoRenew from 'modules/checkout/payment/duck/selectors/getCanAutoRenew';
import type { Selector } from 'model/State';
import type { HamsCartItem, HamsEnrichedCart } from 'modules/cart/duck/model';

const getRenewalDate = (item: HamsCartItem) => {
  if (item.maintenanceEndDate) {
    return new Date(item.maintenanceEndDate);
  }
  const numYears = item.maintenanceMonths / 12;
  const today = dates.now();
  today.setFullYear(today.getFullYear() + numYears);
  return today;
};

const showTierLabelForItem = (itemId: number, cart: HamsEnrichedCart): boolean => {
  const item: HamsCartEnrichedItem = cart.items.find((parent) => parent.id === itemId);
  if (item) {
    return showTierLabel(item);
  }

  return true;
};

const getAutoRenewItems: Selector<Progress<AutoRenewItemProps>> = createSelector(
  (state) => state.cart.payload,
  (state) => state.checkout.payment.eligibleAutoRenewal,
  getCanAutoRenew,
  (state) => state.checkout.payment.selectedAutoRenewItems,
  selectCartPayload,
  (c, ear, canAutoRenew, selectedAutoRenewItems, scp) =>
    Progress.all(c, ear, scp).map(([cart, eligibleAutoRenewal, cartPayloadWithChildItems]) =>
      canAutoRenew
        ? cart.items
            .filter((item) => find(eligibleAutoRenewal, { id: item.id }))
            .map((item) => ({
              id: item.id,
              accountId: item.accountId,
              accountName: item.accountName,
              selected: selectedAutoRenewItems.includes(item.id),
              productDetails: item.productDetails,
              renewDate: getRenewalDate(item),
              showTierLabel: showTierLabelForItem(item.id, cartPayloadWithChildItems),
            }))
        : [],
    ),
);

export default getAutoRenewItems;
