// @flow
import Progress from 'redux-progress';
import type { ChangeLandingState } from './model';
import {
  CHECK_CROSSGRADE_AVAILABLE,
  FETCH_ACCOUNT_MIGRATE_OPTIONS,
  CHECK_MIGRATE_AVAILABLE,
  UPGRADE_ROUTE,
} from './actions';
import { ROUTE as RENEW_ROUTE } from 'modules/renew/duck/landing/actions';

const initial: ChangeLandingState = {
  crossgradeAvailable: Progress.none,
  cloudUpgradeAvailable: Progress.none,
  accountMigrateOptions: Progress.none,
};

export default (state: ChangeLandingState = initial, action: any): ChangeLandingState => {
  switch (action.type) {
    case UPGRADE_ROUTE:
    case RENEW_ROUTE:
      return initial;
    case CHECK_CROSSGRADE_AVAILABLE:
      return {
        ...state,
        crossgradeAvailable: action.progress,
      };
    case FETCH_ACCOUNT_MIGRATE_OPTIONS:
      return {
        ...state,
        accountMigrateOptions: action.progress,
      };
    case CHECK_MIGRATE_AVAILABLE:
      return {
        ...state,
        cloudUpgradeAvailable: action.progress,
      };
    default:
      return state;
  }
};
