import React, { useMemo } from 'react';

import { getTotalPages, generatePages, getPageOffset, getCurrentPage } from 'util/pagination';
import AkPagination from '@atlaskit/pagination';

type Params = {
  limit?: number;
  offset?: number;
};

export type PaginationProps = {
  limit?: number;
  offset?: number;
  totalItems?: number;
  onPaginationChange: (params: Params) => void;
};

const Pagination = (props: PaginationProps) => {
  const { limit = 0, offset = 0, totalItems = 0, onPaginationChange } = props;

  const totalPages = getTotalPages(totalItems, limit);
  const currentPage = getCurrentPage(offset, limit);
  const pages = useMemo(() => generatePages(totalPages), [totalPages]);

  const handlePageChange = (_event, newPage) => {
    onPaginationChange({ offset: getPageOffset(newPage, limit) });
  };

  return (
    <AkPagination defaultSelectedIndex={currentPage} pages={pages} onChange={handlePageChange} />
  );
};

export default Pagination;
