import * as Sentry from '@sentry/browser';

import isError from 'lodash/isError';
import isString from 'lodash/isString';

import { catalog } from '@atlassiansox/metal-client';

const getError = (obj: any) => {
  if (isError(obj)) {
    return obj;
  }
  if (isString(obj)) {
    return new Error(obj);
  }
  return new Error(JSON.stringify(obj));
};

const report = (error) => {
  Sentry.captureException(error);
  // eslint-disable-next-line no-console
  console.error(error);
};

const reportOrThrow = (error) => {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    throw error;
  } else {
    report(error);
  }
};

export const reportErrorOnly = (obj: any): void => {
  const error = getError(obj);
  report(error);
};

export const reportLogOnly = (name: string, obj: any): void => {
  Sentry.captureMessage(`${name}: ${JSON.stringify(obj)}`);
};

const withErrorPayloadScope = (payload: any, callback) => {
  Sentry.withScope((scope) => {
    scope.setTag('uuid', payload.uuid);
    callback(scope);
  });
};

export const reportErrorPayload = (payload: any) => {
  withErrorPayloadScope(payload, () => {
    reportOrThrow(payload.error);
  });
};

interface MetalFailurePayload {
  currentPage: string;
  task: string;
  error: any;
}

/**
 * Reports the metal failure to sentry for better debugging
 * @param payload containing currentPage, task and error object
 */
export const reportMetalFailureToSentry = (payload: MetalFailurePayload) => {
  const METAL_NAME = catalog.userInteraction.TASK_FAILURE;

  const { currentPage, error, task } = payload;

  const message = `MetalFailure ${METAL_NAME}: For task: ${task} on page: ${currentPage}`;

  Sentry.withScope((scope) => {
    scope.setTag('metal-name', METAL_NAME);
    scope.setTag('page', currentPage);
    scope.setTag('task', task);

    if (error?.uuid) scope.setTag('uuid', error.uuid);

    // eslint-disable-next-line no-console
    console.error(error);

    Sentry.captureMessage(message);
  });
};

const reportError = (obj: any): void => {
  const error = getError(obj);
  reportErrorPayload({
    error,
    uuid: obj.uuid,
  });
};

export default reportError;
