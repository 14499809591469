import React, { useContext, useEffect } from 'react';

import SectionMessage from '@atlaskit/section-message';

import { AuthenticationContext } from 'modules/migrate/Authenticated';
import { AnalyticsContext } from 'modules/migrate/analytics/AnalyticsContext';
import { UIAction, UIActionSubject } from 'modules/analytics/model/UIEvent';
import { getComparatorUrl } from './comparator/utils';

const ImpersonationMessage = () => {
  const authenticationContext = useContext(AuthenticationContext);
  const analytics = useContext(AnalyticsContext);
  const comparatorUrl = getComparatorUrl();

  useEffect(() => {
    const label = 'advocatesView';

    analytics.sendUiEvent({
      action: UIAction.Rendered,
      actionSubject: UIActionSubject.Modal,
      actionSubjectId: label,
      attributes: {
        eventContainer: 'comparatorPath',
        eventComponent: 'banner',
        uuid: '7577b186-6e32-4bbe-8223-abddbb11cb5f',
        pageVersionComparator: 'customerAdvocate',
        capture: true,
        label,
      },
    });
  }, []);

  if (authenticationContext.isImpersonation()) {
    return (
      <div style={{ margin: '24px 0' }}>
        <SectionMessage title="You are impersonating a customer">
          <p>
            You are seeing the Cloud Price Comparator tool as the customer would see it. If you want
            to share the link to this page with the customer please use this URL:
          </p>
          <p>
            <a href={comparatorUrl}>{comparatorUrl}</a>
          </p>
        </SectionMessage>
      </div>
    );
  }

  return null;
};

export default ImpersonationMessage;
