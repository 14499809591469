// @flow

import type { Dispatch, GetState } from 'model/State';
import { selectXsrfToken } from 'modules/auth/selectors';
import watchProgress from 'model/watchProgress';
import * as auth from 'modules/auth';

import * as renew from 'modules/renew/duck/server/actions';
import loadAvailableOptions from 'modules/change/landing/duck/loadAvailableOptions';

export default async (dispatch: Dispatch, getState: GetState) => {
  const xsrfToken = await watchProgress(selectXsrfToken);
  if (!xsrfToken) {
    auth.actions.goToLogin();
    return;
  }

  const state = getState();
  await loadAvailableOptions(dispatch, state, renew.route(state.location.query));
};
