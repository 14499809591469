// @flow
import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import type { PaymentPageProps } from 'modules/checkout/payment/duck/model';
import t from 'modules/i18n/intl';
import AmountWithCurrency from 'components/common/AmountWithCurrency';
import Help from 'components/common/Help';
import { Font } from 'components/visuals';

const TotalLabel = styled.h4`
  font-family: ${Font.displayFamily};
  color: ${colors.N500};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 30px;
`;

const TotalSubtext = styled.span`
  color: ${colors.N90};
  font-size: 19px;
  padding-left: 10px;
`;

export default (props: PaymentPageProps) => (
  <>
    <TotalLabel>
      {t('payment.total-amount')} ({props.currency})
    </TotalLabel>
    <AmountWithCurrency
      size="xx-large"
      amount={props.totalIncTax}
      currency={props.currency}
      format="code"
    />
    <TotalSubtext>
      ({t('payment.includes-tax')})
      {props.taxedUsOrder && <Help>{t('payment.sales-tax-added')}</Help>}
    </TotalSubtext>
  </>
);
