import { UIAction, UIActionSubject } from 'modules/analytics/model/UIEvent';

export const nonBillingUserEvents = {
  onSENAddAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.Button,
    parentLabel: 'addProducts',
    actionSubjectId: 'builderAddSEN',
    label: 'add',
    uuid: 'aa92e4f2-7b63-48c0-aa0d-b16277f68d82',
    eventComponent: 'button',
  },
  onSENAddMoreAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.Button,
    parentLabel: 'addProducts',
    actionSubjectId: 'builderAddMoreSEN',
    label: 'addMore',
    uuid: '0d57889c-01f1-45a8-81ce-4bcce17235d8',
    eventComponent: 'button',
  },
  onCannotFindAnalytics: {
    action: UIAction.Rendered,
    actionSubject: UIActionSubject.Banner,
    actionSubjectId: 'builderCannotFindAnalytics',
    parentLabel: 'errorMessage',
    label: 'weCannotFindProduct',
    uuid: 'f1fad2ba-405d-483d-8ff1-9696125f4efa',
    eventComponent: 'banner',
  },
  onClearProductsAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.LinkButton,
    actionSubjectId: 'builderClearProducts',
    parentLabel: 'clearProducts',
    label: 'clearproducts',
    uuid: '6ad086df-f310-4422-9017-67e9ba16b64d',
    eventComponent: 'button',
  },
  onGetStartedAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.Link,
    actionSubjectId: 'comparatorGetStarted',
    parentLabel: 'migrationQuideLink',
    label: 'getStarted',
    uuid: '43393784-03c4-4a45-8f71-ff94b6830ed8',
    eventComponent: 'linkButton',
  },
  onViewTheFAQAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.Link,
    actionSubjectId: 'comparatorViewTheFAQ',
    parentLabel: 'stillHaveQuestions',
    label: 'viewOurFaqs',
    uuid: 'faaf0e85-5a0d-4d66-b845-eda8dc7be508',
    eventComponent: 'linkButton',
  },
  onContactUsAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.Link,
    actionSubjectId: 'comparatorContactUs',
    parentLabel: 'needToTalkToSomeone',
    label: 'contactUs',
    uuid: 'c9ebf669-30e6-4e9e-9582-c29d2c7601b7',
    eventComponent: 'linkButton',
  },
  onTakeTheQuizAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.LinkButton,
    actionSubjectId: 'comparatorTakeTheQuiz',
    parentLabel: 'journeyToCloudQuiz',
    label: 'takeTheQuiz',
    uuid: '04e53f6c-ac2a-40ed-8417-fd3df35f1467',
    eventComponent: 'button',
  },
  onPlaceholderHowDoIFindMySENAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.Link,
    actionSubjectId: 'comparatorPlaceholderHowDoIFindSEN',
    parentLabel: 'howTo',
    label: 'HowToFindMySen',
    uuid: '49a874ea-8a23-4ee9-98c4-92ef7a477bfa',
    eventComponent: 'linkButton',
  },
  onPlaceholderGetStartedAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.LinkButton,
    actionSubjectId: 'comparatorPlaceholderGetStarted',
    parentLabel: 'addProducts',
    label: 'addProductToCompareServerAndCloudPricing',
    uuid: '9532cb5c-b650-4b9c-be43-59b6c9c490d2',
    eventComponent: 'button',
  },
  onPlaceholderWasRenderedAnalytics: {
    action: UIAction.Rendered,
    actionSubject: UIActionSubject.Banner,
    actionSubjectId: 'comparatorPlaceholderWasRendered',
    parentLabel: 'comparatorVersion',
    label: 'nonBillingContact',
    uuid: '85e0e49b-93e8-4376-ba9a-5a3a2513d8d6',
    eventComponent: 'recommendation',
  },
};

export const sharedEvents = {
  onServerSoftwareLicensingCostWasRenderedAnalytics: {
    action: UIAction.Rendered,
    actionSubject: UIActionSubject.TableHeaderCellContent,
    actionSubjectId: 'builderServerSoftwareLicensingCost',
    uuid: 'ac930259-c2da-4ec7-97c2-21cb9f1c38eb',
    eventComponent: 'serverSoftwareLicensingCost',
  },
  onCloudSoftwareLicensingCostWasRenderedAnalytics: {
    action: UIAction.Rendered,
    actionSubject: UIActionSubject.TableHeaderCellContent,
    actionSubjectId: 'builderCloudSoftwareLicensingCost',
    uuid: '8eb77118-7324-4fc0-b053-8846b411689f',
    eventComponent: 'cloudSoftwareLicensingCost',
  },
  onServerTCOCostWasRenderedAnalytics: {
    action: UIAction.Rendered,
    actionSubject: UIActionSubject.TableHeaderCellContent,
    actionSubjectId: 'builderServerTCOCost',
    uuid: '842e7117-3835-4036-bc58-e32bf874cf67',
    eventComponent: 'serverTcoCost',
  },
  onBillingCycleWasRenderedAnalytics: {
    action: UIAction.Rendered,
    actionSubject: UIActionSubject.Switcher,
    actionSubjectId: 'builderSwitcher',
    parentLabel: 'totalCartValue',
    uuid: 'b835f87d-c3af-4649-a4b7-df49cdaf1b56',
    eventComponent: 'billingCycle',
  },
};

export const comparatorEvents = {
  onRecommendationsRenderAnalytics: {
    action: UIAction.Rendered,
    actionSubject: UIActionSubject.Recommendation,
    actionSubjectId: 'recommendationsSimplifiedRender',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.Recommendation,
    parentLabel: 'recommendationsSimplified',
    label: 'recommendations',
    uuid: '97c775f6-b5c1-44b6-9fed-8f87a64f94e9',
  },
  onToggleBillCycleAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.Button,
    actionSubjectId: 'recommendationsSimplifiedToggleBillCycle',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.Button,
    parentLabel: 'recommendationsSimplified',
    label: 'toggleBillCycle',
    uuid: '92989aa4-c1cf-4c92-9068-06b62e55e803',
  },
  onToggleDeploymentAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.Button,
    actionSubjectId: 'recommendationsSimplifiedToggleDeployment',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.Button,
    parentLabel: 'recommendationsSimplified',
    label: 'toggleDeployment',
    uuid: '9365a070-003c-4daa-b467-cae29ab1bca4',
  },
  onSelectPricingPlanAnalytics: {
    action: UIAction.Selected,
    actionSubject: UIActionSubject.Select,
    actionSubjectId: 'recommendationsSimplifiedSelectPricingPlan',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.Select,
    parentLabel: 'recommendationsSimplified',
    label: 'selectPricingPlan',
    uuid: '82b17b14-857c-481a-b803-9d5c5aec9e42',
  },
  onInputNumberOfUsersAnalytics: {
    action: UIAction.Input,
    actionSubject: UIActionSubject.Input,
    actionSubjectId: 'recommendationsSimplifiedInputNumberOfUsers',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.Input,
    parentLabel: 'recommendationsSimplified',
    label: 'inputNumberOfUsers',
    uuid: 'b0583094-2667-4a00-b1d3-124909022c03',
  },
  onSearchBySenAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.Button,
    actionSubjectId: 'recommendationsSimplifiedSearchBySen',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.Button,
    parentLabel: 'recommendationsSimplified',
    label: 'searchBySen',
    uuid: '38820be8-1cbe-49f2-afa7-31b097a0ecc2',
  },
  onComparePlansAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.LinkButton,
    actionSubjectId: 'recommendationsSimplifiedComparePlans',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.LinkButton,
    parentLabel: 'recommendationsSimplified',
    label: 'comparePlans',
    uuid: 'f2cce6f9-962b-4fbb-92e1-1ccffb52f883',
  },
  onCMTLearnMoreAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.LinkButton,
    actionSubjectId: 'recommendationsSimplifiedCMTLearnMore',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.LinkButton,
    parentLabel: 'recommendationsSimplified',
    label: 'learnMore',
    uuid: 'a355a5aa-33df-4fa1-9363-881a61195200',
  },
};

export const comparatorCTAEvents = {
  onCTARenderedAnalytics: {
    action: UIAction.Rendered,
    actionSubject: UIActionSubject.CTA,
    actionSubjectId: 'recommendationsSimplifiedCTA',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.CTA,
    parentLabel: 'recommendationsSimplified',
    label: 'CTA',
    uuid: '22a7b467-7960-4b05-b6bb-5d03d6dd8980',
  },
  onCTAContactUsAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.LinkButton,
    actionSubjectId: 'recommendationsSimplifiedCTAContactUs',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.LinkButton,
    parentLabel: 'recommendationsSimplified',
    label: 'contactUs',
    uuid: 'da9e267e-8769-4ab3-ba67-e497b668c34d',
  },
  onCTAMigrationCenterAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.LinkButton,
    actionSubjectId: 'recommendationsSimplifiedCTAMigrationCenter',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.LinkButton,
    parentLabel: 'recommendationsSimplified',
    label: 'migrationCenter',
    uuid: 'd6502623-6a69-4d04-8bd8-8c757236ba69',
  },
  onCTATryCloudAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.LinkButton,
    actionSubjectId: 'recommendationsSimplifiedCTATryCloud',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.LinkButton,
    parentLabel: 'recommendationsSimplified',
    label: 'tryCloud',
    uuid: 'c2509c1a-5ec9-4f22-9b69-b029e4581e96',
  },
  onCTAViewResourcesAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.LinkButton,
    actionSubjectId: 'recommendationsSimplifiedCTAViewResources',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.LinkButton,
    parentLabel: 'recommendationsSimplified',
    label: 'viewResources',
    uuid: '74e2b049-0e4b-49d1-9c46-7bd5e751f33c',
  },
  onCTAViewDataCenterPricingAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.LinkButton,
    actionSubjectId: 'recommendationsSimplifiedCTAViewDataCenterPricing',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.LinkButton,
    parentLabel: 'recommendationsSimplified',
    label: 'viewDataCenterPricing',
    uuid: '6ceac484-96ae-46cc-9834-759a4e922387',
  },
  onCTAGetStartedAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.LinkButton,
    actionSubjectId: 'recommendationsSimplifiedCTAGetStarted',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.LinkButton,
    parentLabel: 'recommendationsSimplified',
    label: 'getStarted',
    uuid: '653b62ca-f890-4e9e-ae86-e2651744ed33',
  },
  onCTAGetStartedInCloudAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.LinkButton,
    actionSubjectId: 'recommendationsSimplifiedCTAGetStartedInCloud',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.LinkButton,
    parentLabel: 'recommendationsSimplified',
    label: 'getStartedInCloud',
    uuid: '9f501c09-158b-4027-81e3-5e8395f520bd',
  },
  onCTALearnMoreAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.LinkButton,
    actionSubjectId: 'recommendationsSimplifiedCTALearnMore',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.LinkButton,
    parentLabel: 'recommendationsSimplified',
    label: 'learnMore',
    uuid: '305cea2a-12eb-4a85-8026-0c50055fe874',
  },
  onCTAViewPricingResourcesAnalytics: {
    action: UIAction.Clicked,
    actionSubject: UIActionSubject.LinkButton,
    actionSubjectId: 'recommendationsSimplifiedCTAViewPricingResources',
    eventContainer: 'comparatorPath',
    eventComponent: UIActionSubject.LinkButton,
    parentLabel: 'recommendationsSimplified',
    label: 'viewPricingResources',
    uuid: 'fefd9aa4-a7c9-4732-8931-7b27b3d5f9f5',
  },
};
