import Progress from 'redux-progress';

import {
  ACCOUNT_DETAILS,
  CUSTOMER_ACCOUNT_OVERVIEWS,
  CUSTOMER_ACCOUNT_OVERVIEWS_ASYNC,
  IS_EVALUATION_ALLOWED,
  IS_RENEWABLE_ACCOUNT,
  IS_UPGRADABLE_ACCOUNT,
} from 'modules/mac/duck/actions/accounts';
import { AccountAction, AccountData } from '../models/accounts';

export const initialState = {
  getAccountById: Progress.none,
  getCustomerAccountOverviewsByPartnerSearchDTO: Progress.none,
  isUpgradableAccount: Progress.none,
  isRenewableAccount: Progress.none,
  isEvaluationAllowed: Progress.none,
  getAsyncCustomerAccountOverviewsByPartnerSearchDTO: Progress.none,
};

export default (state: AccountData = initialState, action: AccountAction): AccountData => {
  switch (action.type) {
    case ACCOUNT_DETAILS:
      return {
        ...state,
        getAccountById: action.progress,
      };
    case CUSTOMER_ACCOUNT_OVERVIEWS:
      return {
        ...state,
        getCustomerAccountOverviewsByPartnerSearchDTO: action.progress,
      };
    case IS_UPGRADABLE_ACCOUNT:
      return {
        ...state,
        isUpgradableAccount: action.progress,
      };
    case IS_RENEWABLE_ACCOUNT:
      return {
        ...state,
        isRenewableAccount: action.progress,
      };
    case IS_EVALUATION_ALLOWED:
      return {
        ...state,
        isEvaluationAllowed: action.progress,
      };
    case CUSTOMER_ACCOUNT_OVERVIEWS_ASYNC:
      return {
        ...state,
        getAsyncCustomerAccountOverviewsByPartnerSearchDTO: action.progress,
      };
    default:
      return state;
  }
};
