import reportError from 'util/errorReporter';
import cookies from 'js-cookie';
import { LDUser } from 'launchdarkly-js-client-sdk';

import { getCohortId } from 'modules/meta/cohort';

export const getKey = () => {
  try {
    return JSON.parse(decodeURIComponent(cookies.get('ajs_anonymous_id') || '"unknown"'));
  } catch (e) {
    reportError(e);
  }
  return 'unknown';
};

const buildEvalContext = (): LDUser => ({
  key: getKey(),
  anonymous: true,
  custom: {
    cohort: getCohortId(),
  },
});

export default buildEvalContext;
