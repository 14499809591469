// @flow

import React from 'react';
import styled from 'styled-components';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { Dimensions, Font } from 'components/visuals';

type Props = {
  enabled: boolean,
};

const MaintenanceWrapper = styled.div`
  font-family: ${Font.family};
  margin-top: ${Dimensions.units(2)}px;
`;

const startDate = new Date(1568599200000);
const endDate = new Date(1568602800000);
const statusPage = 'https://status.atlassian.com/incidents/vt7d9280ckr4';

const MaintenanceBanner = (props: Props) => (
  <MaintenanceWrapper>
    {!props.enabled ? null : (
      <SectionMessage
        title={<FormattedMessage id="maintenance.title" />}
        appearance="warning"
        actions={[
          {
            href: statusPage,
            text: 'Learn More',
          },
        ].map(({ text, ...restAction }) => (
          <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
        ))}
      >
        <p>
          <FormattedMessage
            id="maintenance.summary"
            values={{
              startDate: (
                <FormattedDate value={startDate} year={undefined} month="short" day="2-digit" />
              ),
              startTime: <FormattedTime value={startDate} />,
              endTime: <FormattedTime value={endDate} />,
            }}
          />
        </p>
        <p>
          <FormattedMessage id="maintenance.body" />
        </p>
      </SectionMessage>
    )}
  </MaintenanceWrapper>
);

export default MaintenanceBanner;
