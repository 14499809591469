/* eslint-disable import/first */
import '@atlaskit/css-reset'; // eslint-disable-line

// Polyfills
import './polyfills';

// React
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// App
import './fonts/fonts.css';
import './reset.css';
import './intercom';
import App from './App';
import store from './store';
import migration from './migration';
import sentry from './sentry';
import { initUfo } from 'modules/telemetry/initUFO';

sentry();
initUfo({
  product: 'cart',
  region: 'unknown',
  doNotAbortActivePressInteractionOnTransition: [
    'checkout-contacts-submit',
    'upgrade-submit-changelist',
    'cart-submit-stripe',
    'cart-submit-convert-to-quote',
    'cart-submit-pay-on-terms',
    'cart-submit-pay-on-account',
    'cart-submit-paypal',
    'cart-submit-free',
    'items-add',
  ],
  kind: {
    hover: 0,
    page_load: 1,
    press: 1,
    transition: 1,
    typing: 0,
  },
  rates: {
    unknown: 0,
  },
});

const root = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  root,
);

migration();
