import { thunk } from 'routes';
import { Dispatch, GetState } from 'model/State';
import { cancelAllNotificationFlags } from './actions';

const routeWrapper = (originalThunk: thunk) => (dispatch: Dispatch, getState: GetState) =>
  Promise.resolve(originalThunk(dispatch, getState)).finally(
    dispatch(cancelAllNotificationFlags()),
  );

export default routeWrapper;
