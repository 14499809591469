import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Font, Dimensions } from 'components/visuals';

import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import { colors } from '@atlaskit/theme';

import AmountWithCurrency from 'components/common/AmountWithCurrency';
import Tooltip from 'components/common/Tooltip';

const SavingsMessageFontSize = 12;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  border-radius: 4px;

  border: 1px solid ${colors.N40};
  padding: 0 ${Dimensions.units(3)}px;
  margin-top: ${Dimensions.units(2)}px;

  color: ${colors.N800};

  .darkText {
    color: ${colors.N900};
  }

  .totalPayAmount {
    align-items: center;
    padding-bottom: 0;
  }

  .savingsText {
    color: ${colors.G300};
    padding-top: 0px;

    font-size: ${SavingsMessageFontSize}px;
    font-weight: normal;
  }
`;

const BodyWrapper = styled.div`
  margin-top: ${Dimensions.units(1)}px;
  display: flex;
  flex-direction: column;
`;

const FooterWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;

  padding: ${Dimensions.units(2)}px 0px;
`;

const RowGroupWrapper = styled.div`
  padding: ${Dimensions.units(1)}px 0px;
  border-bottom: 0.75px solid ${colors.N30};
`;

const RowWrapper = styled.div`
  display: flex;
  padding: 8px 0px;
`;

const CellWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
`;

const ValueCellWrapper = styled(CellWrapper)`
  justify-content: flex-end;
`;

const SavedAmountWrapper = styled.span`
  font-size: ${SavingsMessageFontSize}px;
  font-family: ${Font.displayFamily};
`;

export interface PriceSummaryProps {
  currency: string;
  rowGroups: RowGroup[];
  footerRows: Row[];
}

export interface RowGroup {
  rows: Row[];
  id?: string;
}

export interface Row {
  field: React.ReactNode;
  value: React.ReactNode;
  classes?: string;
  currency?: string;
  isFooter?: boolean;
  id?: string;
}

const SummaryRow: React.FC<Row> = ({ field, value, classes, currency, isFooter = false }) => (
  <RowWrapper className={classes}>
    {field}
    {React.cloneElement(value as React.ReactElement<any>, { currency, isFooter })}
  </RowWrapper>
);

export const PriceSummary: React.FC<PriceSummaryProps> = ({ currency, rowGroups, footerRows }) => (
  <Wrapper data-cy="price-summary">
    <BodyWrapper>
      {rowGroups.map((rowGroup, index) => (
        <RowGroupWrapper data-cy={rowGroup.id} key={rowGroup.id ? rowGroup.id : index}>
          {rowGroup.rows.map((rowObj, rowIndex) => (
            <SummaryRow key={rowObj.id ? rowObj.id : rowIndex} {...rowObj} currency={currency} />
          ))}
        </RowGroupWrapper>
      ))}
    </BodyWrapper>
    {footerRows && (
      <FooterWrapper>
        {footerRows.map((rowObj, index) => (
          <SummaryRow
            key={rowObj.id ? rowObj.id : index}
            {...rowObj}
            currency={currency}
            isFooter
          />
        ))}
      </FooterWrapper>
    )}
  </Wrapper>
);

export interface SummaryField {
  text: React.ReactNode;
  classes?: string;
}

export const FieldCell: React.FC<SummaryField> = ({ text, classes }) => (
  <CellWrapper className={classes}>
    <span>{text}</span>
  </CellWrapper>
);

export interface SummaryFieldValue {
  text: number | React.ReactNode;
  prefix?: string;
  classes?: string;
  showCurrency?: boolean;
  currency?: string;
  isFooter?: boolean;
  testId?: string;
}

export const FieldValueCell: React.FC<SummaryFieldValue> = ({
  text,
  classes,
  currency,
  showCurrency = false,
  isFooter = false,
  prefix = '',
  testId,
}) => (
  <ValueCellWrapper data-cy={testId} className={classes}>
    <span>{prefix}</span>
    &nbsp;
    {showCurrency ? (
      <AmountWithCurrency
        currency={currency}
        amount={text}
        format="code"
        size={isFooter ? '32px' : '14px'}
        useSpace
      />
    ) : (
      <span>{text}</span>
    )}
  </ValueCellWrapper>
);

export interface SavedAmountProps {
  percentage: string;
  currency: string;
  amount: number;
  isPartner?: boolean;
}

export const SavedAmountMessage: React.FC<SavedAmountProps> = ({
  percentage,
  currency,
  amount,
  isPartner = false,
}) => (
  <ValueCellWrapper data-cy="saved-amount">
    {isPartner ? (
      <SavedAmountWrapper>
        <FormattedMessage
          id="common-off-list-price"
          values={{
            percentage,
          }}
        />
      </SavedAmountWrapper>
    ) : (
      <SavedAmountWrapper>
        <FormattedMessage id="common.you-save" />
        {` ${percentage} `}
      </SavedAmountWrapper>
    )}
    <span>(</span>
    <AmountWithCurrency
      currency={currency}
      amount={amount}
      format="code"
      size={`${SavingsMessageFontSize}px`}
      weight="normal"
      useSpace
    />
    <span>)</span>
  </ValueCellWrapper>
);

export type WithTooltipProps = React.PropsWithChildren<{
  content: React.ReactNode;
}>;

export const WithTooltip: React.FC<WithTooltipProps> = ({ content, children }) => (
  <CellWrapper>
    {children}
    &nbsp;
    <Tooltip direction="right" content={content}>
      <QuestionCircleIcon size="small" primaryColor={colors.N80} label="" />
    </Tooltip>
  </CellWrapper>
);
