/* eslint-disable */
import 'core-js';

import 'whatwg-fetch';
import 'raf/polyfill';

import fromEntries from 'object.fromentries';
if (!Object.fromEntries) {
  fromEntries.shim();
}

import 'intl';
