import * as React from 'react';
import { connect } from 'react-redux';
import { State, Dispatch } from 'model/State';
import NotificationFlag from 'components/common/NotificationFlag';
import { removeNotificationFlag } from 'modules/meta/notificationFlag/actions';
import t from 'modules/i18n/intl';

type Props = {
  children: any;
  notificationFlag: any[];
  onNotificationDismissed: (any) => {};
};

const NotificationWrapper = (props: Props) => (
  <>
    {props.children}
    <NotificationFlag
      isOpen={props.notificationFlag}
      onDismissed={() =>
        props.onNotificationDismissed(
          props.notificationFlag.length > 0 && props.notificationFlag[0].timeoutId,
        )
      }
      title={props.notificationFlag.length > 0 && t(props.notificationFlag[0].message)}
    />
  </>
);

const mapStateToProps = (state: State) => ({
  notificationFlag: state.meta.notificationFlag,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onNotificationDismissed: (timeoutId) => dispatch(removeNotificationFlag(timeoutId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationWrapper);
