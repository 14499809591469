/* eslint-disable class-methods-use-this */
import SoftwareProduct from 'model/SoftwareProduct';
import { DataCenterProductKey, ProductKey } from 'data/products/keys';
import { ProductFeature } from 'model/Product';
import { annualTerm, clustering, disasterRecovery } from 'model/features';
import ServerProduct from 'model/editions/ServerProduct';

export default class DataCenterProduct extends SoftwareProduct {
  constructor(
    key: DataCenterProductKey,
    serverEdition: ServerProduct,
    parentKeys: ProductKey[] = [],
    features: ProductFeature[] = [],
  ) {
    super({
      key,
      groupKey: serverEdition.groupKey,
      tagLine: serverEdition.tagLine,
      description: serverEdition.description,
      name: serverEdition.name,
      addon: serverEdition.addon,
      moreLink: serverEdition.moreLink && `${serverEdition.moreLink}/enterprise/data-center`,
      parentKeys,
      features: [...features, clustering, annualTerm, disasterRecovery],
    });
  }

  get isDataCenter(): boolean {
    return true;
  }
}
