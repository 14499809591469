/* eslint-disable import/prefer-default-export,no-unused-vars */
import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map, pairwise, filter } from 'rxjs/operators';

export const TYPE_TELEMETRY_TIMER = 'telemetry/TIMER';
export const TYPE_TELEMETRY_COUNTER = 'telemetry/COUNTER';
export const TYPE_TELEMETRY_COUNTER_FAILURE = 'telemetry/COUNTER_FAILURE';

export const telemetryTimer = (aspect: string, time: number, histogramBuckets?: string) => ({
  type: TYPE_TELEMETRY_TIMER,
  aspect,
  time,
  histogramBuckets,
});

export const telemetryCounter = (aspect: string) => ({
  type: TYPE_TELEMETRY_COUNTER,
  aspect,
});

export const telemetryCounterFailure = (aspect: string, error?: any) => ({
  type: TYPE_TELEMETRY_COUNTER_FAILURE,
  aspect,
  error,
});

export type ActionType = {};

export type MapperType<T> = string | ((payload: any) => T);

export type HistogramBucketType = MapperType<string>;
export type AspectType = string | MapperType<string>;

const getAspect = (aspectDef: AspectType, action: any) => {
  if (typeof aspectDef === 'string') {
    return aspectDef;
  }
  return aspectDef(action);
};

export const getHistogramBucket = (
  histogramBucketDef: HistogramBucketType | undefined,
  action: any,
) => {
  if (histogramBucketDef === undefined) {
    return undefined;
  }

  if (typeof histogramBucketDef === 'string') {
    return histogramBucketDef;
  }

  if (typeof histogramBucketDef === 'function') {
    return histogramBucketDef(action);
  }

  return undefined;
};

export const DEFAULT_RESULT_FILTER = (action) => action.progress.success;

export const timeAction = (
  actionType: string,
  aspectDef: AspectType,
  histogramBucketsDef?: HistogramBucketType,
  { isSuccess = DEFAULT_RESULT_FILTER } = {},
) => (action$: Observable<any>) =>
  action$.pipe(
    ofType(actionType),
    map((action) => ({ ...action, time: new Date().getTime() })),
    pairwise(),
    filter(([, end]) => isSuccess(end)),
    map(([start, end]) =>
      telemetryTimer(
        getAspect(aspectDef, end),
        end.time - start.time,
        getHistogramBucket(histogramBucketsDef, end),
      ),
    ),
  );

const isForbiddenError = (action) => action?.progress?.error?.errorKey === 'forbidden.error';

export const counter = (
  actionType: string,
  aspectDef: AspectType,
  { isSuccess = DEFAULT_RESULT_FILTER } = {},
) => (action$: Observable<any>) =>
  action$.pipe(
    ofType(actionType),
    filter((action) => action.progress.isCompleted),
    map((action) => {
      if (isForbiddenError(action)) {
        return () => {};
      }
      if (isSuccess(action)) {
        return telemetryCounter(`${getAspect(aspectDef, action)}`);
      }
      return telemetryCounterFailure(`${getAspect(aspectDef, action)}`, action.progress.error);
    }),
  );
