/* eslint-disable class-methods-use-this */
import * as React from 'react';
import { ProductGroupKey, SupportProductKey } from 'data/products/keys';
import Product, { Props as ProductProps } from 'model/Product';
import { ProductType, SUPPORT } from 'model/ProductType';

export type Props = ProductProps & {
  key: SupportProductKey;
  longDescription: React.ReactNode;
  groupKey?: ProductGroupKey | null;
  enableProductSelect?: boolean;
};

export default class SupportProduct extends Product {
  longDescription: React.ReactNode;
  groupKey: ProductGroupKey | null;
  enableProductSelect: boolean;

  constructor(props: Props) {
    super(props);
    this.longDescription = props.longDescription;
    this.groupKey = props.groupKey || null;
    this.enableProductSelect = props.enableProductSelect || false;
  }

  get type(): ProductType {
    return SUPPORT;
  }

  getGroupKey(): ProductGroupKey | null {
    return this.groupKey;
  }

  isProductSelectEnabled(): boolean {
    return this.enableProductSelect;
  }
}
/* eslint-enable class-methods-use-this */
