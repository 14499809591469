import { AxiosRequestConfig } from 'axios';

import { State } from 'model/State';
import hamsClient from 'modules/hams';
import { selectHamsClientConfig } from 'modules/auth/selectors';

import { baseAccountApiUrl } from '../..';

export const getFromHamsClient = async (
  remainingPath: string,
  state: State,
  params?: AxiosRequestConfig['params'],
) => {
  try {
    const config = selectHamsClientConfig(state);

    return (
      await hamsClient.get(`${baseAccountApiUrl}${remainingPath}`, {
        ...config,
        params,
      })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
};
