// @flow
import React from 'react';
import { connect } from 'react-redux';
import Progress from 'redux-progress';

import { StepHeader, StepWrapper } from 'modules/configure/common/Steps';
import { selectors } from 'modules/configure';
import AddonLine from 'modules/configure/addons/AddonLine';
import type { State } from 'model/State';
import t from 'modules/i18n/intl';

type Props = {
  addonKeys: Progress<string[]>,
};

const AddonStep = (props: Props) =>
  props.addonKeys.ifSuccess((addonKeys) =>
    addonKeys.length > 0 ? (
      <StepWrapper>
        <StepHeader>{t('purchase.choose-addons')}</StepHeader>
        <div>
          {addonKeys.map((productKey) => (
            <AddonLine key={productKey} productKey={productKey} />
          ))}
        </div>
      </StepWrapper>
    ) : null,
  );

const mapStateToProps = (state: State) => ({
  addonKeys: selectors.selectValidAddonKeys(state),
});

export default connect(mapStateToProps)(AddonStep);
