// @flow
import * as React from 'react';
import styled from 'styled-components';

import { Dimensions } from 'components/visuals';

type Props = {
  children: React.Node,
  dataTestId?: String,
};

const Wrapper = styled.div`
  width: ${Dimensions.pageWidth}px;
  margin: 0 auto;
  position: relative;
  flex: 1 0 auto;
  padding-bottom: ${Dimensions.units(6)}px;
`;

export default (props: Props) => <Wrapper data-testId={props.dataTestId}>{props.children}</Wrapper>;
