// @flow
import React from 'react';
import { Paragraph, SubHeader } from 'modules/checkout/styled';
import type { PaymentPageProps } from 'modules/checkout/payment/duck/model';
import TotalPrice from 'modules/checkout/payment/components/TotalPrice';
import t from 'modules/i18n/intl';

export default ({ paypal, ...props }: PaymentPageProps) =>
  paypal.isSelected && (
    <div>
      <SubHeader>{t('payment.paypal.content.title')}</SubHeader>
      <Paragraph>
        {paypal.hasAutoRenewItems
          ? t('payment.paypal.content.description')
          : t('payment.paypal.content.non-auto-renew-description')}
      </Paragraph>
      <TotalPrice {...props} />
    </div>
  );
