import { connect } from 'react-redux';
import { MACLicensesPage } from './LicensesPage';

import { State } from 'model/State';

// this component can be used to have a particular license expanded
// and scrolled into view port using URL: `/purchase/my/licenses/{senNumber}`
export const MACLicensesPreselectedPage = connect((state: State) => ({
  senNumber: state.location.payload.senNumber,
}))(MACLicensesPage);
