import { createSelector } from 'reselect';
import get from 'lodash/get';
import { Selector } from 'model/State';
import { cartCountryValidationSelector } from './getCartCurrencyValidation';
import {
  isCountryPurchaserContactMismatch,
  isCountryTechnicalContactMismatch,
} from './getContactValidations';

export default createSelector(
  cartCountryValidationSelector,
  isCountryPurchaserContactMismatch,
  isCountryTechnicalContactMismatch,
  (cartCurrencyValidation, isPurchaserContactInValid, isTechnicalContactInvalid) =>
    get(cartCurrencyValidation.result, 'compatible', true) &&
    !isPurchaserContactInValid &&
    get(isTechnicalContactInvalid, 'compatible', true),
) as Selector<boolean>;
