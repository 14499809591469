import React, { useContext, useState, useEffect } from 'react';
import { Field, FieldText } from 'modules/form';
import { Intl } from 'modules/i18n/intl';
import { FormContext } from 'modules/form/Form';
import { getTaaSErrorMessage } from 'modules/checkout/contacts/AddressForm/getTaaSErrorMessage';
import { StateProvinceSelect } from 'modules/checkout/contacts/StateProvinceSelect';

import type { EnhancedOrganisation } from 'modules/checkout/contacts/duck/model';

import { analyticsEvents, AnalyticsTrigger } from 'modules/checkout/contacts/analytics';
import { CartAnalyticsContext } from 'modules/analytics';

import fieldsMeta from './fieldsMeta';
import { FieldNames } from './types';

interface FormFieldProps {
  name: FieldNames;
  state?: EnhancedOrganisation['state'];
  country?: EnhancedOrganisation['country'];
}

const isRequired = ({ name, country }: Omit<FormFieldProps, 'state'>): boolean => {
  const optionalFields: Array<FieldNames> = ['address2'];

  if (name === 'state') {
    return !!country?.requiresStates;
  }
  if (name === 'postcode') {
    return !!country?.requiresPostcode;
  }
  if (optionalFields.includes(name)) {
    return false;
  }
  return true;
};

const FormField: React.FC<FormFieldProps> = ({ name, state, country }: FormFieldProps) => {
  const [invalidMessageKey, setInvalidMessageKey] = useState<null | string>(null);
  const { path, validity, data } = useContext(FormContext);
  const analytics = useContext(CartAnalyticsContext);

  const { label, characterLimit, lengthBreachKey, errors } = fieldsMeta[name];

  const errorCode = validity.get(path).error?.text;

  const isFieldRequired = isRequired({ name, country });

  const handleValidation = (fieldValue) => {
    if (typeof fieldValue === 'string') {
      const isLengthBreach =
        characterLimit && lengthBreachKey && fieldValue.length > characterLimit;

      const isRequiredBreach = isFieldRequired && fieldValue.length === 0;

      if (isRequiredBreach) {
        setInvalidMessageKey('billing.address.validation.required');
      } else if (isLengthBreach) {
        setInvalidMessageKey(getTaaSErrorMessage(lengthBreachKey || ''));
      } else {
        setInvalidMessageKey(null);
      }
    }
  };

  useEffect(() => {
    if (errorCode && errors.includes(errorCode)) {
      setInvalidMessageKey(getTaaSErrorMessage(errorCode));
      analyticsEvents[AnalyticsTrigger.ContactDetailsValidationFieldFailed](analytics, errorCode);
    }
  }, [errorCode]);

  useEffect(() => {
    const fieldValue = data[path][name];

    if (fieldValue) {
      handleValidation(data[path][name]);
    }
  }, [data, path, name]);

  return (
    <Intl>
      {(translate) => (
        <Field
          name={name}
          label={translate(label)}
          invalidMessage={invalidMessageKey ? translate(invalidMessageKey) : null}
          isRequired={isFieldRequired}
        >
          {name === 'state' ? (
            <StateProvinceSelect country={country} currentStateCode={state} />
          ) : (
            <FieldText type="text" onValidation={handleValidation} />
          )}
        </Field>
      )}
    </Intl>
  );
};

export const CompanyName = () => <FormField name="organisationName" />;

export const AddressLine1 = () => <FormField name="address1" />;

export const AddressLine2 = () => <FormField name="address2" />;

export const City = () => <FormField name="city" />;

export const State = (props: Omit<FormFieldProps, 'name'>) => <FormField name="state" {...props} />;

export const PostCode = (props: Pick<FormFieldProps, 'country'>) => (
  <FormField name="postcode" {...props} />
);
