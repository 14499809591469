// @flow
import { combineEpics } from 'redux-observable';

import * as cart from 'modules/cart/duck/actions';

import { timeAction, counter } from './util';

export default combineEpics(
  timeAction(cart.SET_DETAILS, 'cart.details.set'),
  counter(cart.SET_DETAILS, 'cart.details.set'),
);
