/* eslint-disable no-unused-vars */
// @flow
import * as React from 'react';
import type { FieldContext } from './Field';

type ExtractProps<P, T: React.ComponentType<P>> = P;
export type BindProps<T: React.ComponentType<*>> = $Shape<ExtractProps<*, T>> & {
  field?: FieldContext,
  akRef?: React.Ref<*>,
};
type Props = {
  field: FieldContext,
  children: any,
};
export default ({ field, children, ...props }: Props) =>
  children instanceof Function
    ? children(field)
    : React.Children.map(children, (child) => React.cloneElement(child, { ...props, field }));
