import { colors } from '@atlaskit/theme';
import { Dimensions } from 'components/visuals';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

const StyledMainMACHeader = styled.h1`
  margin: ${Dimensions.unit}px 0 ${Dimensions.unit * 3}px;
  color: ${colors.N800};
`;

const MACPageHeader = () => (
  <StyledMainMACHeader>
    <FormattedMessage id="mac.main.header" />
  </StyledMainMACHeader>
);

export default MACPageHeader;
