import { selectLatestFrom } from 'util/rxOperators';
import { Observable, merge } from 'rxjs';
import { ofType } from 'redux-observable';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { actions } from 'modules/checkout/contacts/duck';
import * as selectors from 'modules/checkout/contacts/duck/selectors';
import { State$ } from 'model/State';

export default (action$: Observable<any>, state$: State$) => {
  const onErrorAfterSubmit$ = action$.pipe(
    ofType(actions.SUBMIT),
    filter((a) => a.progress.failed),
  );

  const onResellerWarningAfterBlur$ = action$.pipe(
    ofType(actions.BLUR),
    selectLatestFrom(state$),
    map(selectors.getShowsResellerWarning),
    distinctUntilChanged(),
    filter(Boolean),
  );

  return merge(onErrorAfterSubmit$, onResellerWarningAfterBlur$).pipe(
    map(actions.scrollToMessageBanner),
  );
};
