import { Dispatch, GetState } from 'model/State';
import { emitScreenEvent } from 'modules/analytics/actions';
import * as properties from 'modules/tracking/selectors/props';

const getName = (type: string, getState: GetState) => {
  const route = getState().location.routesMap[type];
  return (route && route.name) || type;
};

export default (type: string) => (dispatch: Dispatch, getState: GetState) => {
  dispatch(
    emitScreenEvent({
      name: getName(type, getState),
      attributes: properties.common(getState()),
    }),
  );
};
