import { ExternalProductKey } from 'data/products/keys';
import Product, { Props as ProductProps } from 'model/Product';
import { ProductType } from 'model/ProductType';

export type Props = ProductProps & {
  key: ExternalProductKey;
  type: ProductType;
};

export default class ExternalProduct extends Product {
  _type: string;

  constructor(props: Props) {
    super(props);
    this._type = props.type;
  }

  get type(): ProductType {
    return this._type;
  }
}
