import { AxiosRequestConfig } from 'axios';
import hamsClient from 'modules/hams';
import { cartId } from 'modules/cart/duck/api/ids';
import { HamsCart } from 'modules/cart/duck/model';
import Progress from 'redux-progress';

export default async function createCart(
  axiosConfig: AxiosRequestConfig,
): Promise<Progress<HamsCart>> {
  const config: AxiosRequestConfig = {
    ...axiosConfig,
    headers: {
      ...axiosConfig.headers,
      'source-application': 'sc',
    },
  };
  const r = await hamsClient.post('/1.0/public/order/cart/new', undefined, config);
  cartId.store(r.data.uuid);
  return r.data;
}
