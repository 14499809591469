// @flow
import { combineEpics } from 'redux-observable';

import * as cart from 'modules/cart';

import { timeAction, counter } from './util';

export default combineEpics(
  timeAction(cart.actions.ADD_PRODUCT_TO_CART, 'items.add'),
  counter(cart.actions.ADD_PRODUCT_TO_CART, 'items.add'),
);
