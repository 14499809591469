// @flow
import * as React from 'react';
import Progress from 'redux-progress';
import Spinner from '@atlaskit/spinner';

type Props<T> = {
  progress: Progress<T>,
  errorMessage?: any,
  content: (T) => any,
};

export default <T>(props: Props<T>) =>
  props.progress.fold({
    loading: () => (
      <div style={{ textAlign: 'center' }}>
        <Spinner interactionName="loaded-spinner" size="large" />
      </div>
    ),
    failed: () => props.errorMessage || null,
    success: props.content,
  });
