import Progress from 'redux-progress';
import { CompleteState } from './model';
import { CLOSE_SURVEY, DISMISS_SURVEY, FETCH_LICENSES, OPEN_SURVEY } from './actions';

const initialState: CompleteState = {
  licenses: Progress.none,
  showSurvey: false,
  showFlag: true,
};

const reducer = (state: CompleteState = initialState, action: any): CompleteState => {
  switch (action.type) {
    case FETCH_LICENSES:
      return { ...state, licenses: action.progress };
    case OPEN_SURVEY:
      return { ...state, showSurvey: true, showFlag: false };
    case CLOSE_SURVEY:
      return { ...state, showSurvey: false };
    case DISMISS_SURVEY:
      return { ...state, showFlag: false };
    default:
      return state;
  }
};

export default reducer;
