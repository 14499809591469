// @flow
import config from 'appconfig';

export default async function checkLoginExists(email: string) {
  const r = await fetch(`${config.hamsUrl}/1.0/public/aid/exists/${encodeURIComponent(email)}`, {
    method: 'POST',
  });

  if (!r.ok) {
    throw Error();
  }
  const result = await r.json();
  return result.success;
}
