import t from 'modules/i18n/intl';

export const clustering = {
  weight: 0,
  description: t('product.features.clustering'),
};

export const singleServer = {
  weight: 5,
  description: t('product.features.single-server'),
};

export const annualTerm = {
  weight: 10,
  description: t('product.features.annual-term'),
};

export const perpetual = {
  weight: 15,
  description: t('product.features.perpetual'),
};

export const deploymentOptions = {
  weight: 20,
  description: t('product.features.deployment-options'),
};

export const optimizedAWS = {
  weight: 25,
  description: t('product.features.optimized-aws'),
};

export const saml = {
  weight: 40,
  description: t('product.features.saml'),
};

export const disasterRecovery = {
  weight: 50,
  description: t('product.features.disaster-recovery'),
};

export const zeroDowntime = {
  weight: 60,
  description: t('product.features.zero-downtime'),
};

export const gitLFS = {
  weight: 70,
  description: t('product.features.git-lfs'),
};

export const mirroring = {
  weight: 80,
  description: t('product.features.mirroring'),
};

export const defaultServerFeatures = [perpetual, singleServer];

export const defaultDatacenterFeatures = [
  annualTerm,
  clustering,
  deploymentOptions,
  disasterRecovery,
];
