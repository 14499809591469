// @flow
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import ProductTier from 'model/hams/ProductTier';
import styled from 'styled-components';

type Props = {
  unitCount: number,
  unitLabel: string,
  // eslint-disable-next-line react/no-unused-prop-types
  formatUnitCount?: (number) => any,
};

type ExtraLabelProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  additionalTierLabel: React.ReactNode,
};

const isNone = (unitLabel: string) => unitLabel === 'NONE';
const getUnitCount = (props) =>
  (props.formatUnitCount && props.formatUnitCount(props.unitCount)) || props.unitCount;

const TierLabel = (props: Props) => {
  if (isNone(props.unitLabel)) {
    return <FormattedMessage id="common.n-a" />;
  }
  const count = props.unitCount;
  const message = props.unitLabel
    ? `tier.label.${props.unitLabel.toLowerCase()}`
    : 'tier.label.user';
  return ProductTier.isUnlimited(props.unitCount) ? (
    <FormattedMessage id={`${message}.unlimited`} />
  ) : (
    <FormattedMessage
      id={message}
      defaultMessage={`${count} ${props.unitLabel ? props.unitLabel.toLowerCase() : ''}`}
      values={{ count, formattedCount: getUnitCount(props) }}
    />
  );
};

const StyledExtraTierLabelDiv = styled.div`
  font-family: CharlieText;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 8px;
`;

const StyledExtraTierCellDiv = styled.div`
  font-size: 16px;
  line-height: 16px;
`;

export const ExtraTierLabel = (props: ExtraLabelProps) =>
  props.additionalTierLabel ? (
    <StyledExtraTierLabelDiv>{props.additionalTierLabel}</StyledExtraTierLabelDiv>
  ) : null;

export const ExtraTierLabelCell = (props: ExtraLabelProps) =>
  props.additionalTierLabel ? (
    <StyledExtraTierCellDiv>{props.additionalTierLabel}</StyledExtraTierCellDiv>
  ) : null;

TierLabel.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  formatUnitCount: (unitCount) => unitCount,
};

export default TierLabel;
