import sortBy from 'lodash/sortBy';
import Progress from 'redux-progress';
import * as actions from 'modules/data/countries/actions';

export default (state = Progress.none, action) => {
  switch (action.type) {
    case actions.FETCH_ALL:
      return action.progress.map((countries) => sortBy(countries, (c) => c.displayName));
    default:
      return state;
  }
};
