// @flow
import React from 'react';
import { connect } from 'react-redux';
import Progress from 'redux-progress';

import Button from '@atlaskit/button/standard-button';

import AddonEntries from 'modules/marketplace/components/AddonEntries';
import {
  ProductSelectionEntry,
  ProductSelectionList,
  Section,
} from 'modules/marketplace/components/styled';
import type { MarketplaceProductKey } from 'modules/marketplace/data';
import { actions, selectors } from 'modules/marketplace/recommended';
import ProgressLoader from 'components/common/ProgressLoader';
import type { Dispatch, State } from 'model/State';
import type { MarketplaceAddon } from 'modules/catalog/addons';
import { RecommendedAddonsProductOptions } from 'modules/marketplace/data';
import { route as goAddon } from 'modules/addon/duck/actions/route';
import t from 'modules/i18n/intl';

type StateProps = {
  entries: Progress<MarketplaceAddon[]>,
  selectedProduct: string,
};

type DispatchProps = {
  filter: (productKey: MarketplaceProductKey) => void,
  goAddon: (productKey: string) => void,
};

type RecommendedAppsProps = StateProps & DispatchProps;

export const RecommendedAddonsStateless = (props: RecommendedAppsProps) => {
  const onClick = (productKey: MarketplaceProductKey) => (e) => {
    e.preventDefault();
    props.filter(productKey);
  };

  return (
    <ProgressLoader progress={props.entries} errorMessage={t('marketplace.recommended.load.fail')}>
      {(result) => (
        <Section>
          <h3>{t('marketplace.recommended.title')}</h3>
          <ProductSelectionList>
            {RecommendedAddonsProductOptions.map(({ key, label }) => (
              <ProductSelectionEntry key={key} selected={props.selectedProduct === key}>
                <Button appearance="link" onClick={onClick(key)}>
                  {label}
                </Button>
              </ProductSelectionEntry>
            ))}
          </ProductSelectionList>
          <AddonEntries entries={result} goAddon={props.goAddon} />
        </Section>
      )}
    </ProgressLoader>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  entries: selectors.entries(state),
  selectedProduct: selectors.selectedProduct(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  filter: (productKey: MarketplaceProductKey) => dispatch(actions.filter(productKey)),
  goAddon: (productKey: string) => dispatch(goAddon(productKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedAddonsStateless);
