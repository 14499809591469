// @flow
export const ROUTE = 'configure/ROUTE';
export const goProductConfig = (productKey: string, addons: string[] = []) => ({
  type: ROUTE,
  payload: {
    productKey,
  },
  meta: {
    query: {
      addons,
    },
  },
});
