// @flow
import React from 'react';
import styled from 'styled-components';
import { SubHeader, Subtle } from 'modules/checkout/styled';
import type { AdditionalDetailsProps } from 'modules/checkout/payment/duck/model';
import PoNumberInput from 'modules/checkout/PoNumberInput';
import { Dimensions } from 'components/visuals';
import t from 'modules/i18n/intl';

const PoNumberWidth = styled(PoNumberInput)`
  width: ${Dimensions.units(93)}px;
`;

export default (props: AdditionalDetailsProps) => (
  <>
    <SubHeader>
      {t('payment.additional-details.title')} <Subtle>({t('payment.optional')})</Subtle>
    </SubHeader>
    <PoNumberWidth value={props.poNumber} onChange={(poNumber) => props.edit({ poNumber })} />
  </>
);
