// @flow
import Crypto from 'crypto-js';
import memoize from 'lodash/memoize';

import { createSelector } from 'reselect';
import type { State } from 'model/State';

export const hashCartId = memoize((uuid) => Crypto.SHA256(uuid).toString());

export const getCartSaleChannel = (s: State) =>
  s.cart.payload.result && s.cart.payload.result.resellerOrder ? 'reseller' : 'direct';

export default createSelector(
  (s: State) => s.cart.payload.result,
  (s: State) => s.settings.purchaseMode,
  (cart, purchaseMode) =>
    cart && {
      cart: {
        cartID: hashCartId(cart.uuid),
        totalExcTax: cart.totalIncTax - cart.totalTax,
        totalIncTax: cart.totalIncTax,
        dateCreated: cart.created,
        cartCurrency: cart.currency,
        cartMode: purchaseMode,
        cartSaleChannel: cart.resellerOrder ? 'reseller' : 'direct',
      },
    },
);
