export enum OperationalAction {}

export enum OperationalSubject {}

export type OperationalFields = Readonly<{
  action: OperationalAction;
  actionSubject: OperationalSubject;
  actionSubjectId?: string;
  attributes?: Record<string, any>;
  tags?: string[];
}>;

export type OperationalContext = Readonly<{
  source: string;
  objectType?: string;
  objectId?: string;
  containerType?: string;
  containerId?: string;
}>;

export interface CartOperationalEvent extends OperationalFields, OperationalContext {}
