import { thunkProgress } from 'redux-progress';
import hamsSetAutoRenewItems from 'modules/cart/duck/api/setAutoRenewItems';
import { CartThunk } from 'model/State';
import { selectors } from 'modules/auth';

export type AutoRenewItem = {
  itemId: number;
  enabled: boolean;
};

export const SET_AUTO_RENEW_ITEMS = 'cart/SET_AUTO_RENEW_ITEMS';
export const setAutoRenewItems = (items: AutoRenewItem[], cartId: string): CartThunk => (
  dispatch,
  getState,
) =>
  dispatch(
    thunkProgress(
      SET_AUTO_RENEW_ITEMS,
      hamsSetAutoRenewItems(cartId, items, selectors.selectHamsClientConfig(getState())),
    ),
  );
