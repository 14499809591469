// @flow
import { redirect } from 'redux-first-router';

import { goProductConfig } from 'modules/configure/duck/actions';
import { getSoftwareProduct } from 'data/products';
import type { Dispatch, GetState } from 'model/State';
import { api as cartApi } from 'modules/cart';
import { route as goAddon } from 'modules/addon/duck/actions';
import { mapProductKeyToLegacyProductKey } from 'data/products/keys';
import selectCartPayload from 'modules/cart/duck/selectors/cart';
import watchProgress from 'model/watchProgress';

const generateCartProperties = (productKey, query) => {
  const props = {};
  if (query.callback) {
    props.callback = decodeURIComponent(query.callback);
  }
  if (query.serverId) {
    props.serverId = decodeURIComponent(query.serverId);
    props.productToAssociateWithServerId = productKey;
  }
  return Object.keys(props).length > 0 ? props : null;
};

export const ROUTE = 'software/buy/ROUTE';

export const load = async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const cart = await watchProgress(selectCartPayload);
  const productKey = mapProductKeyToLegacyProductKey(state.location.payload.productKey);
  const staticProduct = getSoftwareProduct(productKey);
  const addon = !staticProduct || staticProduct.addon;

  const cartProperties = generateCartProperties(productKey, state.location.query);
  if (cartProperties) {
    cartApi.storage.cartProperties(cart.uuid).store(cartProperties);
  }

  if (!addon) {
    return dispatch(redirect(goProductConfig(productKey)));
  }
  return dispatch(redirect(goAddon(productKey)));
};
