// @flow
import environment from 'appconfig/environment';

import bizboxConfig from 'appconfig/envs/bizbox';
import developmentConfig from 'appconfig/envs/development';
import e2eConfig from 'appconfig/envs/e2e';
import localConfig from 'appconfig/envs/local';
import productionConfig from 'appconfig/envs/production';
import stagingConfig from 'appconfig/envs/staging';
import { BIZBOX, DEVELOPMENT, E2E, LOCAL, STAGING, PRODUCTION } from 'model/EnvironmentConfig';
import type { Environment, EnvironmentConfig } from 'model/EnvironmentConfig';

function getConfigurationForEnvironment(env: Environment): EnvironmentConfig {
  switch (env) {
    case LOCAL:
      return localConfig();
    case BIZBOX:
      return bizboxConfig();
    case DEVELOPMENT:
      return developmentConfig();
    case E2E:
      return e2eConfig();
    case STAGING:
      return stagingConfig();
    default:
    case PRODUCTION:
      return productionConfig();
  }
}

export default getConfigurationForEnvironment(environment);
