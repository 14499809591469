import React from 'react';
import Progress from 'redux-progress';
import styled from 'styled-components';

import SectionMessage from '@atlaskit/section-message';

import { route as goAddon } from 'modules/addon/duck/actions/route';
import t from 'modules/i18n/intl';
import { HamsCart } from 'modules/cart/duck/model';
import { StoredPromotion } from 'modules/promotion/duck/model';
import { HamsProductPricing } from 'model/hams/Pricing';
import ProgressLoader from 'components/common/ProgressLoader';
import NavLink from 'components/common/NavLink';
import { Dimensions, Font } from 'components/visuals';

type Props = {
  cart: HamsCart;
  promotion: StoredPromotion | null;
  products: Progress<HamsProductPricing[]>;
};

const Wrapper = styled.div`
  margin-bottom: ${Dimensions.units(2)}px;
  font-family: ${Font.family};
`;

const PromotionMessage = (props: Props) => {
  if (props.cart.marketplacePromotion && props.cart.marketplacePromotion.discountActive) {
    return (
      <Wrapper>
        <SectionMessage
          title={t('cart.promotion', { promotionName: props.promotion && props.promotion.name })}
        >
          {t('cart.discount-promotion')}
        </SectionMessage>
      </Wrapper>
    );
  }

  if (props.cart.items.length > 0 && props.cart.marketplacePromotion && props.promotion) {
    return (
      <Wrapper>
        <SectionMessage
          title={t('cart.unfulfilled-promotion', { promotionName: props.promotion.name })}
        >
          <p>{t('cart.mandatory-purchase-required-for-discount')}</p>
          <ProgressLoader
            progress={props.products}
            errorMessage={t('cart.error-while-loading-product-list')}
          >
            {(products) => (
              <ul>
                {products.map((product) => (
                  <li key={product.productKey}>
                    <NavLink to={goAddon(product.productKey)}>{product.productDescription}</NavLink>
                  </li>
                ))}
              </ul>
            )}
          </ProgressLoader>
        </SectionMessage>
      </Wrapper>
    );
  }

  return null;
};

export default PromotionMessage;
