/* eslint-disable import/prefer-default-export */
// @flow
import * as storage from 'modules/checkout/payment/duck/api/storage';
import { CheckoutModes } from 'modules/checkout/payment/duck/model/PaymentState';
import type { Dispatch, GetState } from 'model/State';
import getIsFreeOrder from 'modules/cart/duck/selectors/getIsFreeOrder';

export const updateTnsSessionFromForm = () => async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const { payment } = state.checkout;

  if (!getIsFreeOrder(state) && payment.checkoutMode === CheckoutModes.CREDIT_CARD) {
    const tnsSession = payment.creditCard.configureProgress.unwrap();
    const paymentDetails = await tnsSession.updateFromForm();
    storage.paymentDetails.store({
      ...paymentDetails,
      gatewayCardName: payment.creditCard.cardName,
    });
  }
};
