import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { GridColumn } from '@atlaskit/page';
import { colors } from '@atlaskit/theme';
import { AuthenticationContext } from 'modules/migrate/Authenticated';
import { connect, ConnectedProps } from 'react-redux';
import { catalog } from '@atlassiansox/metal-client';
import Progress from 'redux-progress';
import styled from 'styled-components';

import { client as metalClient } from 'modules/telemetry';
import { getUserChannel } from 'modules/auth/selectors';
import Header from 'components/navigation/top/Header';
import { Dimensions } from 'components/visuals';

import { EntitlementSummary, fetchRecommendations } from 'modules/migrate/recommendations/api';
import { GridWrapper } from '../search/Visuals';
import PageLoading from 'components/common/PageLoading';

import BottomMenu from 'components/navigation/bottom/BottomMenu';
import AccountsTable from 'modules/migrate/comparator/AccountsTable';
import { getFeatureFlags } from 'modules/meta/launchdarkly/selectors';
import { mapProductKeyToLegacyProductKey } from 'data/products/keys';
import { GridRow } from 'modules/migrate/Layout';
import ImpersonationMessage from 'modules/migrate/ImpersonationMessage';
import DynamicCTA from './modules/cta/DynamicCTA';
import ExploreCloudBanner from './modules/ExploreCloudBanner';

import { AnalyticsContext } from 'modules/migrate/analytics/AnalyticsContext';
import { comparatorEvents } from 'modules/migrate/analytics/analyticsEvents';
import { generateAnalytics } from 'modules/migrate/analytics/utils';
import UFOSegment from '@atlassian/ufo-segment';
import traceUFOPress from '@atlassian/ufo-trace-press';

const Footer = styled.section`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  min-height: ${Dimensions.units(8)}px;
  margin-top: ${Dimensions.units(6)}px;
  background-color: ${colors.N10};
`;

const VerticalAlignedBanner = styled.div`
  margin: ${Dimensions.units(2)}px auto;
  width: ${Dimensions.units(110)}px;
`;

const mapState = (state: any) => ({
  userChannel: getUserChannel(state),
  ffProgress: getFeatureFlags(state),
});

const connector = connect(mapState);

const Recommendations = ({ userChannel, ffProgress }: ConnectedProps<typeof connector>) => {
  const [response, setResponse] = useState<EntitlementSummary | null>(null);
  const [responseProgress, setResponseProgress] = useState<Progress<boolean>>(Progress.none);
  const [error, setError] = useState(false);

  const authenticationContext = useContext(AuthenticationContext);
  const analytics = useContext(AnalyticsContext);
  const { onRecommendationsRenderAnalytics } = generateAnalytics(analytics, comparatorEvents);

  const pageLoadingProgress = Progress.all(ffProgress, responseProgress, userChannel);
  const isPartner = !!userChannel.result?.isPartner;
  const isImpersonation = authenticationContext.isImpersonation();

  const loadData = useCallback(() => {
    (async () => {
      try {
        const start = new Date().getTime();
        const result = await fetchRecommendations({ authenticationContext });
        const mapper = (account) => ({
          ...account,
          productKey: mapProductKeyToLegacyProductKey(account.productKey),
        });
        // temporally fix of different products in 'entitlementSummary' and 'list' calls
        // problem: wrong CTA can be shown
        // not supported (obsolete) products are not filtered
        const filteredResult = {
          productFeatureUsageList: result.productFeatureUsageList.filter(
            ({ productKey }) =>
              !(
                productKey.includes('-support') ||
                productKey === 'technical-account-management' ||
                productKey === 'enterprise-success-package'
              ),
          ),
        };

        setResponse({
          ...filteredResult,
          productFeatureUsageList: filteredResult.productFeatureUsageList.map(mapper),
        });
        onRecommendationsRenderAnalytics({
          isPartner: isPartner.toString(),
          isCAs: isImpersonation.toString(),
          nonBillingOrTechnical: !isPartner && !filteredResult.productFeatureUsageList.length,
          capture: true,
        });
        setResponseProgress(Progress.success(true));

        // eslint-disable-next-line no-unused-expressions
        metalClient?.metric.submit({
          name: catalog.userInteraction.TASK_SUCCESS,
          value: 1,
          page: 'MIGRATE',
          task: 'loadEntitlementSummary',
        });
        traceUFOPress('loadEntitlementSummary-ufo');

        // eslint-disable-next-line no-unused-expressions
        metalClient?.metric.submit({
          name: catalog.userInteraction.TASK_DURATION,
          value: new Date().getTime() - start,
          page: 'MIGRATE',
          task: 'loadEntitlementSummary',
        });
      } catch (e) {
        // eslint-disable-next-line no-unused-expressions
        metalClient?.metric.submit({
          name: catalog.userInteraction.TASK_FAILURE,
          value: 1,
          page: 'MIGRATE',
          task: 'loadEntitlementSummary',
        });
        setError(true);
        setResponseProgress(Progress.success(e));
      }
    })();
  }, []);

  const summaryProductsNumberInitial = isPartner
    ? 0
    : response?.productFeatureUsageList.reduce((acc, { amount }) => acc + amount, 0) || 0;

  useEffect(() => {
    analytics.setAttribute('isPartner', isPartner.toString());
    loadData();
  }, []);

  if (response === null && error === null) {
    return null;
  }

  return (
    <UFOSegment name="recommendations-load-data">
      <PageLoading progress={pageLoadingProgress}>
        {() => (
          <>
            <GridRow>
              <GridColumn medium={1} />
              <GridColumn medium={10}>
                <ImpersonationMessage />
                <Header />
              </GridColumn>
              <GridColumn medium={1} />
            </GridRow>
            <AccountsTable
              summaryProductsNumberInitial={summaryProductsNumberInitial}
              isPartner={isPartner}
            />
            <ExploreCloudBanner
              isPartner={isPartner}
              productFeatureUsageList={response?.productFeatureUsageList || []}
            />
            <DynamicCTA
              isPartner={isPartner}
              productFeatureUsageList={response?.productFeatureUsageList || []}
            />
            <Footer>
              <GridWrapper>
                <VerticalAlignedBanner>
                  <FormattedMessage id="migrate.comparator.page.footer-banner" />
                </VerticalAlignedBanner>
              </GridWrapper>
            </Footer>
            <BottomMenu />
          </>
        )}
      </PageLoading>
    </UFOSegment>
  );
};

export default connector(Recommendations);
