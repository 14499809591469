import { SET_BIENNIAL_CART, SET_UPDATED_BIENNIAL_CART } from '../actions';
import { FETCH_SECONDARY_CART } from 'modules/cart/duck/actions/fetchOrCreate';

export default function biennialBillingCart(state = null, action: any) {
  switch (action.type) {
    case SET_BIENNIAL_CART:
    case FETCH_SECONDARY_CART:
      return action.progress;
    case SET_UPDATED_BIENNIAL_CART: {
      if (action.progress.result) {
        return action.progress;
      }
      return state;
    }
    default:
      return state;
  }
}
