import { progressResult, selectLatestFrom } from 'util/rxOperators';
import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map, filter, mapTo } from 'rxjs/operators';

import * as actions from 'modules/cart/duck/actions';
import selectCartPayload from 'modules/cart/duck/selectors/cart';
import { State$ } from 'model/State';

export default (action$: Observable<any>, state$: State$) =>
  action$.pipe(
    ofType(actions.FETCH_CART),
    selectLatestFrom(state$),
    progressResult(selectCartPayload),
    map((c: any) => c.currentEndUserCurrency),
    filter(Boolean),
    mapTo(actions.updateCurrency()),
  );
