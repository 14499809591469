import { Dispatch, GetState } from 'model/State';
import { emitUIEvent, UIEvent } from 'modules/analytics';
import { PropsSelector } from 'modules/tracking/selectors/props';
import * as selectors from 'modules/tracking/selectors';

export interface UIEventData {
  uiFields: UIEvent.UIFields;
  uiContext: UIEvent.UIContext;
  [s: string]: any;
}

export const createUIEvent = (
  paramUIData: UIEventData,
  ...extraPropSelectors: (PropsSelector | Record<string, any>)[]
) => (dispatch: Dispatch, getState: GetState) => {
  const uiFields: UIEvent.UIFields = {
    ...paramUIData.uiFields,
    attributes: Object.assign(
      {},
      paramUIData.uiFields.attributes,
      selectors.props.common(getState()),
      ...extraPropSelectors.map((selectorOrObject) =>
        typeof selectorOrObject === 'function' ? selectorOrObject(getState()) : selectorOrObject,
      ),
      { eventType: 'ui' },
    ),
  };

  const uiContext: UIEvent.UIContext = {
    ...paramUIData.uiContext,
  };

  dispatch(
    emitUIEvent({
      ...uiFields,
      ...uiContext,
    }),
  );
};
