import React from 'react';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import config from 'appconfig';

import t from 'modules/i18n/intl';

const ConfigureErrorMessage = () => (
  <SectionMessage
    appearance="error"
    title={t('payment.unable-to-load-credit-card-form')}
    actions={[
      {
        key: 'reload',
        onClick: () => window.location.reload(true),
        text: t('common.reload'),
      },
      {
        href: `${config.wacUrl}/company/contact/purchasing-licensing`,
        key: 'contact-us',
        text: t('common.contact-us'),
      },
    ].map(({ text, ...restAction }) => (
      <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
    ))}
  >
    {t('payment.retry')}
  </SectionMessage>
);

export default ConfigureErrorMessage;
