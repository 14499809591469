import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Action, redirect } from 'redux-first-router';
import RedirectToProductList from './components/redirectToProductList';
import MACPageHeader from './components/macPageHeader';
import { restoreSwitchUserEmail, setSwitchUserEmail } from './duck/actions/configUser/actions';
import validateEmailAddress from 'util/email';
import Button from '@atlaskit/button/standard-button';
import { LoadingButton } from '@atlaskit/button';
import { ErrorMessage, Label } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { styled } from 'styled-components';
import { Dimensions } from 'components/visuals';
import { FormattedMessage } from 'react-intl';
import SectionMessage from '@atlaskit/section-message';
import {
  selectGetCustomerByEmail,
  selectIsMemberOfGroup,
  selectSwitchedUser,
} from './duck/selectors';
import { getCurrentContactEmail } from 'modules/auth/selectors';
import { fetchGetCustomerByEmail } from './duck/actions/contact';
import Loader from 'components/common/Loader';
import { route } from './duck/actions/landing';

const SwitchUserForm = styled.form`
  margin: ${Dimensions.units(3)}px 0;
  width: ${Dimensions.units(60)}px;
`;

const StyledSubmitButton = styled(LoadingButton)`
  margin-top: ${Dimensions.unit}px;
`;

const ConfigUserPage = () => {
  const dispatch = useDispatch();
  const [inputEmail, setInputEmail] = useState('');
  const [debouncedInputEmail, setDebouncedInputEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isNotExistingEmail, setIsNotExistingEmail] = useState(false);
  const [isRevertEmailButtonDisabled, setIsRevertEmailButtonDisabled] = useState(true);
  const [isAccessCallFetching, setIsAccessCallFetching] = useState(true);
  const currentUserEmail = useSelector(getCurrentContactEmail);
  const switchedUserEmail = useSelector(selectSwitchedUser);
  const customersByEmail = useSelector(selectGetCustomerByEmail);
  const isMemberOfGroup = useSelector(selectIsMemberOfGroup);

  useEffect(() => {
    if (isMemberOfGroup === false) {
      setIsAccessCallFetching(false);
      dispatch(redirect(route() as Action));
    }
    if (isMemberOfGroup === true) {
      setIsAccessCallFetching(false);
    }
  }, [isMemberOfGroup]);

  useEffect(() => {
    const debounceInputTimeoutId = setTimeout(() => {
      if (inputEmail) {
        setIsEmailValid(!!validateEmailAddress(inputEmail));
      } else {
        setIsEmailValid(false);
      }
    }, 200);
    return () => clearTimeout(debounceInputTimeoutId);
  }, [inputEmail]);

  useEffect(() => {
    if (isEmailValid) {
      setDebouncedInputEmail(inputEmail);
    }
  }, [isEmailValid]);

  const handleEmailFieldUpdate = (e) => {
    setInputEmail(e.target.value);

    setIsNotExistingEmail(false);
  };

  useEffect(() => {
    if (currentUserEmail === switchedUserEmail) {
      setIsRevertEmailButtonDisabled(true);
    } else {
      setIsRevertEmailButtonDisabled(false);
    }
  }, [switchedUserEmail, currentUserEmail]);

  useEffect(() => {
    if (customersByEmail[debouncedInputEmail]) {
      const { result } = customersByEmail[debouncedInputEmail];
      if (result) {
        dispatch(setSwitchUserEmail(debouncedInputEmail));
      } else if (!customersByEmail[debouncedInputEmail]?.inProgress) {
        setIsNotExistingEmail(true);
      }
    }
  }, [customersByEmail[debouncedInputEmail]]);

  const handleEmailSwitch = useCallback(() => {
    dispatch(fetchGetCustomerByEmail(debouncedInputEmail));
  }, [debouncedInputEmail]);

  const handleEmailRestore = () => {
    dispatch(restoreSwitchUserEmail());
  };

  return (
    <>
      <RedirectToProductList />
      <MACPageHeader />
      {isAccessCallFetching ? (
        <Loader />
      ) : (
        <>
          <SectionMessage>
            <FormattedMessage id="mac.switch-user.info-banner" />
          </SectionMessage>
          <SwitchUserForm
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleEmailSwitch();
            }}
          >
            <Label htmlFor="userEmail">
              <FormattedMessage id="mac.switch-user.input-label" />
            </Label>
            <Textfield
              name="email"
              id="userEmail"
              onSubmit={handleEmailSwitch}
              onChange={handleEmailFieldUpdate}
              data-cy="mac-update-user-input"
              isInvalid={isNotExistingEmail}
            />
            {isNotExistingEmail && (
              <ErrorMessage>
                <FormattedMessage id="mac.switch-user.validation.not-existing-email" />
              </ErrorMessage>
            )}
            <StyledSubmitButton
              type="submit"
              appearance="primary"
              isDisabled={!isEmailValid}
              data-cy="mac-update-user-button"
              isLoading={customersByEmail[debouncedInputEmail]?.inProgress}
            >
              <FormattedMessage id="mac.switch-user.button.update" />
            </StyledSubmitButton>
          </SwitchUserForm>
          <Button
            type="button"
            onClick={handleEmailRestore}
            isDisabled={isRevertEmailButtonDisabled}
            data-cy="mac-revert-user-button"
          >
            <FormattedMessage id="mac.switch-user.button.revert" />
          </Button>
        </>
      )}
    </>
  );
};

export const MACConfigUserPage = connect(null, null)(ConfigUserPage);
