// @flow
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { StepList } from 'modules/configure/common/Steps';
import Header from 'modules/configure/common/Header';
import TermsStep from 'modules/configure/terms/TermsStep';
import PriceLine from 'modules/configure/common/PriceLine';
import DeploymentStep from 'modules/configure/deployment/DeploymentStep';
import supportIllustration from 'components/common/img/support-illustration.png';
import { Dimensions, Font } from 'components/visuals';
import { ProductGroupKeys } from 'data/products/keys';

import { getSupportProduct } from 'data/products';
import type { State } from 'model/State';
import SupportProduct from 'model/SupportProduct';

import t from 'modules/i18n/intl';
import ProductsToSupportStep from './products/ProductsToSupportStep';

type OwnProps = {
  productKey: string,
};

type Props = {
  product: SupportProduct,
  isLearnMoreBlockVisible: Boolean,
};

const TextBlock = styled.p`
  font-family: ${Font.family};
  font-weight: 400;
  font-size: 16px;
  max-width: ${Dimensions.units(60)}px;
  line-height: 24px;
`;

const FlexWrapper = styled.div`
  display: flex;
`;

const ColumnWrapper = styled.div`
  flex: 1;
`;

const SupportImage = () => <img src={supportIllustration} alt="Support" />;

const ProductDescription = (props: Props) => <TextBlock>{props.product.longDescription}</TextBlock>;

const LearnMoreBlock = (props: Props) => (
  <TextBlock>
    <strong>
      <FormattedMessage
        id="purchase.learn-more-in-documentation-page"
        values={{
          documentationLink: (
            <a href={props.product.moreLink}>{t('purchase.documentation-page')}</a>
          ),
        }}
      />
    </strong>
  </TextBlock>
);

const DeploymentBlock = (props: Props) => (props.product.getGroupKey() ? <DeploymentStep /> : null);

const ProductSelectionBlock = (props: Props) =>
  props.product.isProductSelectEnabled() ? (
    <ProductsToSupportStep productKey={props.product.key} />
  ) : null;

const TwoColumnView = (props: Props) => (
  <StepList>
    <Header />
    <FlexWrapper>
      <ColumnWrapper>
        <ProductDescription product={props.product} />
        <LearnMoreBlock product={props.product} />
        <DeploymentBlock product={props.product} />
        <TermsStep isPeriod />
      </ColumnWrapper>
      <ColumnWrapper>
        <SupportImage />
      </ColumnWrapper>
    </FlexWrapper>
    <PriceLine />
  </StepList>
);

const OneColumnView = (props: Props) => (
  <StepList>
    <Header />
    <FlexWrapper>
      <ColumnWrapper>
        <ProductDescription product={props.product} />
        {props.isLearnMoreBlockVisible && <LearnMoreBlock product={props.product} />}
        <DeploymentBlock product={props.product} />
      </ColumnWrapper>
      <ColumnWrapper>
        <SupportImage />
      </ColumnWrapper>
    </FlexWrapper>
    <ProductSelectionBlock product={props.product} />
    <TermsStep isPeriod />
    <PriceLine />
  </StepList>
);

const SupportProductConfiguration = (props: Props) => {
  const groupKey = props?.product?.getGroupKey() || '';
  const isLearnMoreBlockVisible = ![
    ProductGroupKeys.PRIORITY_SUPPORT_BITBUCKET,
    ProductGroupKeys.PRIORITY_SUPPORT_BAMBOO,
  ].includes(groupKey);

  return props.product.isProductSelectEnabled() ? (
    <OneColumnView product={props.product} isLearnMoreBlockVisible={isLearnMoreBlockVisible} />
  ) : (
    <TwoColumnView product={props.product} isLearnMoreBlockVisible={isLearnMoreBlockVisible} />
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps) => ({
  product: getSupportProduct(ownProps.productKey, state),
});

export default connect(mapStateToProps)(SupportProductConfiguration);
