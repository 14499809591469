/**
 * The default location reducer produces a query field which can be null, or an object.
 * It's bug prone to keep checking for location query, like this:
 *     state.location.query && state.location.query.myParam==='true' ? ...
 *
 * To minimise verbosity and risk, we default location.query to an empty object.
 */
export default (locationReducer) => (state, action) => {
  const result = locationReducer(state, action);

  if (result.query) {
    return result;
  }

  return {
    ...result,
    query: result.query || {},
  };
};
