// @flow
import reportError, { reportLogOnly } from 'util/errorReporter';
import type { Dispatch, GetState } from 'model/State';
import * as confirmation from 'modules/checkout/complete';
import { selectPurchaseMode } from 'modules/settings/purchaseMode';
import * as payment from 'modules/checkout/payment';
import type { HamsCart } from 'modules/cart/duck/model';
import { actions as cartActions, api as cartApi } from 'modules/cart';
import { inEditMode } from 'modules/cart/duck/selectors';
import type { ConfirmationData } from 'modules/checkout/complete/duck/model';
import isAuthenticated from 'modules/auth/selectors/isAuthenticated';
import { selectInSiteConfiguratorMode } from 'modules/siteconfigurator/duck/selectors';
import { OrderStatusResponse } from 'modules/checkout/review/duck/model';
import { sendAnnualBillingMessageToBux } from 'modules/siteconfigurator/utils';

import { emitTrackEvent } from 'modules/analytics';
import { analyticsEvents, AnalyticsTrigger } from 'modules/siteconfigurator/analytics';

const getCheckoutMode = (purchaseMode, checkoutMode, cart: HamsCart) => {
  if (purchaseMode === 'QUOTE' || cart.totalIncTax === 0) {
    return undefined;
  }

  const values: { [payment.model.CheckoutMode]: confirmation.model.CheckoutModeString } = {
    [payment.model.CheckoutModes.STRIPE_CREDIT_CARD]: 'cc',
    [payment.model.CheckoutModes.CREDIT_CARD]: 'cc',
    [payment.model.CheckoutModes.BANK_TRANSFER]: 'bank',
    [payment.model.CheckoutModes.PAY_ON_ACCOUNT]: 'poa',
    [payment.model.CheckoutModes.PAY_ON_TERMS]: 'pot',
    [payment.model.CheckoutModes.PAYPAL]: 'pp',
  };

  return values[checkoutMode];
};

const isReviewRequred = (status: OrderStatusResponse) => status.status === 'REVIEW_REQUIRED';

export default () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();

  const cart = state.cart.payload.unwrap();
  const status = state.checkout.review.orderStatus.unwrap();
  const purchaseMode = selectPurchaseMode(state);

  if (!cart) {
    // Send message to bux if we are currently in annual billing flow
    if (selectInSiteConfiguratorMode(state)) {
      reportLogOnly('SiteConfiguratorCartSubmitFailed', { purchaseMode });
      dispatch(
        emitTrackEvent(
          analyticsEvents[AnalyticsTrigger.ConfigConfigSubmitFailed]({
            purchaseMode,
          }),
        ),
      );
      sendAnnualBillingMessageToBux({
        notify: true,
        isError: true,
        purchaseMode,
      });
    }

    reportError('No cart present in checkout.review.next action');
    return;
  }

  const cartProperties = cartApi.storage.cartProperties(cart.uuid).load();

  const params: ConfirmationData = {
    ordernumber: (status.orderNumber: any),
    mode: purchaseMode.toLowerCase(),
    total: cart.totalIncTax,
    reviewRequired: isReviewRequred(status),
    singleAddon:
      cart.items.length === 1 &&
      (!!cart.items[0].productDetails.marketplaceAddon ||
        !!cart.items[0].productDetails.atlassianAddon),
    lasso: isAuthenticated(state),
    numberLicensedItems: cart.items.filter((item) => !item.productDetails.isTraining).length,
    hasTraining: cart.items.filter((item) => item.productDetails.isTraining).length > 0,
  };

  const checkoutMode = getCheckoutMode(purchaseMode, state.checkout.payment.checkoutMode, cart);
  if (checkoutMode) {
    params.checkoutMode = checkoutMode;
  }

  if (inEditMode(state)) {
    params.editedCartId = cart.uuid;
  }

  if (cartProperties) {
    params.callback = cartProperties.callback;
    params.cartItemIdToAssociateWithServerId = cartProperties.cartItemIdToAssociateWithServerId;
    params.serverId = cartProperties.serverId;
  }

  dispatch(cartActions.resetCart());
  cartApi.storage.clearedCartId.store(cart.uuid);
  // Send message to bux if we are currently in annual billing flow
  if (selectInSiteConfiguratorMode(state)) {
    reportLogOnly('SiteConfiguratorCartSubmitSuccess', {
      checkoutMode,
      purchaseMode,
      reviewRequired: isReviewRequred(status),
      cart,
    });
    dispatch(
      emitTrackEvent(
        analyticsEvents[AnalyticsTrigger.CartConfigSubmitSuccess]({
          checkoutMode,
          purchaseMode,
          reviewRequired: isReviewRequred(status),
          cart,
        }),
      ),
    );
    sendAnnualBillingMessageToBux({
      notify: true,
      checkoutMode,
      purchaseMode,
      reviewRequired: isReviewRequred(status),
      cart,
    });
    return;
  }
  dispatch(confirmation.actions.route(params));
};
