// @flow
import React from 'react';
import styled from 'styled-components';
import ContactDetailsComponent from 'modules/checkout/review/details/ContactDetails';
import PaymentMethodDetailsComponent from 'modules/checkout/review/details/PaymentMethodDetailsComponent';
import type { PurchaseMode } from 'modules/settings/purchaseMode';
import type { HamsCart } from 'modules/cart/duck/model';
import { isCartAFreeOrder } from 'modules/cart/duck/selectors/getIsFreeOrder';
import { Dimensions, Font } from 'components/visuals';

type Props = {
  purchaseMode: PurchaseMode,
  cart: HamsCart,
  onContactDetailsEdit: () => void,
  onPaymentDetailsEdit: () => void,
  onRemovePayment: () => void,
  inSiteConfiguratorMode: boolean,
  canEditContactInSiteConfigurator: boolean,
};

const Wrapper = styled.div`
  font-family: ${Font.family};
  font-weight: 400;
  font-size: 16px;
  display: flex;
  padding-top: ${Dimensions.units(8)}px;

  > * {
    flex-grow: 1;
  }
`;

const shouldDisplayContactDetails = ({ cart }: Props): boolean => cart.status !== 'QUOTED';

const shouldDisplayPaymentDetails = ({ cart, purchaseMode }: Props): boolean =>
  !!(
    (purchaseMode === 'BUY' && !isCartAFreeOrder(cart)) ||
    cart.purchaseOrderNumber ||
    cart.promotionalCode ||
    cart.hasTaxExemptRequest ||
    cart.hasCoTermRequest ||
    cart.inEditMode
  );

const DetailsSection = (props: Props) => (
  <Wrapper className="pii">
    {shouldDisplayContactDetails(props) && <ContactDetailsComponent {...props} />}
    {shouldDisplayPaymentDetails(props) && <PaymentMethodDetailsComponent {...props} />}
  </Wrapper>
);

export default DetailsSection;
