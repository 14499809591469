import { sum } from 'util/reducers';
import { createSelector } from 'reselect';
import Progress from 'redux-progress';

import selectOrderableItems from 'modules/addon/duck/selectors/orderableItems';
import selectTermOption from 'modules/addon/duck/selectors/term';
import selectIsSubscription from 'modules/addon/duck/selectors/isSubscription';
import { Selector } from 'model/State';

const selectAmount: Selector<number> = createSelector(
  selectOrderableItems,
  selectTermOption,
  selectIsSubscription,
  (orderableItemsProgress, termOptionProgress, isSubscriptionProgress) =>
    Progress.all(orderableItemsProgress, termOptionProgress, isSubscriptionProgress).ifSuccess(
      ([items, term, isSubscription]) =>
        term &&
        (isSubscription ? term.amount : items.map((item) => item.amount).reduce(sum, term.amount)),
    ) || 0,
);

export default selectAmount;
