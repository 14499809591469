import { progressResult } from 'util/rxOperators';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged, filter, skipUntil } from 'rxjs/operators';
import * as actions from 'modules/checkout/contacts/duck/actions';
import {
  getSelectedTechnicalOrganisationCurrency,
  isResellerOrder,
} from 'modules/checkout/contacts/duck/selectors/getBillingContacts';
import { EDIT } from 'modules/checkout/contacts/duck/actions/edit';

const isEditAction$ = (action$) =>
  action$.pipe(
    map((a: any) => a.type === EDIT),
    filter(Boolean),
  );

export default (action$: Observable<any>, state$: any) =>
  state$.pipe(
    filter((state) => isResellerOrder(state).result as boolean),
    progressResult(getSelectedTechnicalOrganisationCurrency),
    distinctUntilChanged(),
    filter(Boolean),
    skipUntil(isEditAction$(action$)),
    map((newCurrency: any) => actions.validatePreEmptiveResellerShowNotification(newCurrency)),
  );
