// @flow

import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { Dimensions, Font } from 'components/visuals';
import Link from 'components/common/Link';
import LanguageSwitch from 'components/navigation/bottom/LanguageSwitch';
import { getCurrentContactEmail } from 'modules/auth/selectors/contactDetails';
import macLoginURITransformer from 'util/macLoginURITransformer';
import { selectLanguage } from 'modules/i18n/duck/actions';
import t from 'modules/i18n/intl';
import { FeatureFlags, checkFeatureGate } from 'util/statsig/index';
import config from 'appconfig';

const FooterSection = styled.section`
  background-color: ${colors.N20};
  flex-shrink: 0;
`;

const Footer = styled.footer`
  padding: ${Dimensions.units(3)}px 0 ${Dimensions.units(4)}px;
  max-width: ${Dimensions.pageWidth}px;
  margin: 0 auto;
`;

const TopLine = styled.ul`
  display: block;
  padding: 0;
  font-family: ${Font.family};
  font-size: 16px;
  margin-bottom: ${Dimensions.units(1)}px;
  color: ${colors.N800};
`;

const BottomLine = styled(TopLine)`
  font-size: 12px;
`;

const Item = styled.li`
  list-style: none;
  display: inline-block;
  margin-right: ${Dimensions.units(2)}px;
  color: ${colors.N400};
  &:hover {
    color: ${colors.N700};
  }
`;

type StateProps = {
  language: string,
  currentContactEmail: string | null,
  select: (string) => () => void,
};

const BottomMenu = (props: StateProps) => {
  const cartMACFunctionalityMigration = checkFeatureGate(
    FeatureFlags.CART_MAC_FUNCTIONALITY_MIGRATION,
  );
  const macProductsLink = cartMACFunctionalityMigration
    ? `${config.root}/my`
    : macLoginURITransformer({
        url: 'https://my.atlassian.com/products/index',
        currentContactEmail: props.currentContactEmail,
      });

  return (
    <FooterSection>
      <Footer>
        <TopLine>
          <Item>
            <Link to={macProductsLink}>{t('navigation.footer.my-account')}</Link>
          </Item>
          <Item>
            <Link to="https://www.atlassian.com/software">{t('navigation.footer.products')}</Link>
          </Item>
          <Item>
            <Link to="https://marketplace.atlassian.com">{t('navigation.footer.marketplace')}</Link>
          </Item>
          <Item>
            <Link to="https://www.atlassian.com/company/events">
              {t('navigation.footer.community')}
            </Link>
          </Item>
          <Item>|</Item>
          <Item>
            <Link to="https://www.atlassian.com/company">{t('navigation.footer.company')}</Link>
          </Item>
          <Item>
            <Link to="https://www.atlassian.com/company/careers">
              {t('navigation.footer.careers')}
            </Link>
          </Item>
          <Item>
            <Link to="https://www.atlassian.com/company/contact">{t('common.contact-us')}</Link>
          </Item>
        </TopLine>

        <BottomLine>
          <Item>
            <LanguageSwitch language={props.language} select={props.select} />
          </Item>
          <Item>
            <Link to="https://www.atlassian.com/legal/privacy-policy">
              {t('navigation.footer.privacy-policy')}
            </Link>
          </Item>
          <Item>
            <Link to="https://www.atlassian.com/legal/customer-agreement">
              {t('navigation.footer.terms-of-use')}
            </Link>
          </Item>
          <Item>
            <Link to="https://www.atlassian.com/security">{t('navigation.footer.security')}</Link>
          </Item>
          <Item>
            {t('navigation.footer.copyright-with-year', { year: new Date().getFullYear() })}
          </Item>
        </BottomLine>
      </Footer>
    </FooterSection>
  );
};

const mapStateToProps = (state) => ({
  language: state.i18n.language,
  currentContactEmail: getCurrentContactEmail(state),
});

const mapDispatchToProps = (dispatch) => ({
  select: (language) => () => dispatch(selectLanguage(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BottomMenu);
