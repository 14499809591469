import React from 'react';
import Button from '@atlaskit/button';
import styled from 'styled-components';
import t from 'modules/i18n/intl';
import config from 'appconfig';
import { useDispatch, useSelector } from 'react-redux';
import { setSwitchUserEmail } from 'modules/mac/duck/actions/configUser';
import { selectIsMemberOfGroup, selectSwitchedUser } from 'modules/mac/duck/selectors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  font-size: smaller;
`;

export const TopMenuMAC = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector(selectSwitchedUser);
  const hasAccessToSwitchUserFunctionality = useSelector(selectIsMemberOfGroup);

  return (
    <Wrapper data-cy="mac-header-line">
      {userEmail && (
        <Button appearance="link" href={config.emailMAC(userEmail)}>
          {userEmail}
        </Button>
      )}
      <Button appearance="link" href={config.billingDetailsMAC}>
        {t('mac.menu.billing-details')}
      </Button>
      {hasAccessToSwitchUserFunctionality ? (
        <Button appearance="link" href={config.switchUserMAC}>
          {t('mac.menu.switch-user')}
        </Button>
      ) : null}
      <Button appearance="link" href={config.emailPreferencesMAC}>
        {t('mac.menu.email-preferences')}
      </Button>
      <Button appearance="link" href="https://support.atlassian.com">
        {t('mac.menu.get-help')}
      </Button>
      <Button appearance="link" href="https://marketplace.atlassian.com">
        {t('mac.menu.marketplace')}
      </Button>
      <Button appearance="link" href={config.switchAccountMAC}>
        {t('mac.menu.switch-account')}
      </Button>
      <Button
        appearance="link"
        href={config.logOutMAC}
        onClick={() => dispatch(setSwitchUserEmail(''))}
      >
        {t('mac.menu.log-out')}
      </Button>
    </Wrapper>
  );
};
