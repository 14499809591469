import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import config from 'appconfig';
import { GridWrapper } from '../Visuals';
import { GridColumn } from '@atlaskit/page';
import { colors } from '@atlaskit/theme';
import { Dimensions, Font } from 'components/visuals';
import { ProductFeatureUsageList } from '../api';
import CMT from '../../img/free-CMT-banner.png';

import { AnalyticsContext } from 'modules/migrate/analytics/AnalyticsContext';
import { comparatorEvents } from 'modules/migrate/analytics/analyticsEvents';
import { generateAnalytics } from 'modules/migrate/analytics/utils';

const BannerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${Dimensions.units(10)}px 0 ${Dimensions.units(4)}px;
  padding: ${Dimensions.units(5)}px;
  border-left: ${Dimensions.units(1.25)}px solid ${colors.T100};
  border-radius: ${Dimensions.units(0.5)}px;
  box-shadow: 1px 3px 6px 2px rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  font-family: ${Font.displayFamily};
  font-size: 24px;
  font-weight: 500;
  min-height: ${Dimensions.units(4)}px;
  margin-bottom: ${Dimensions.units(2)}px;
  letter-spacing: 0.3px;
  color: ${colors.N900};
`;

const TextWrapper = styled.div`
  font-family: ${Font.family};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: ${Dimensions.units(4)}px;
  color: ${colors.N900};
`;

const MessageWrapper = styled.div`
  width: 52%;
`;

const ImageWrapper = styled.img`
  width: ${Dimensions.units(36)}px;
  height: ${Dimensions.units(21)}px;
  margin-right: ${Dimensions.units(3.5)}px;
`;

type Props = {
  isPartner: boolean;
  productFeatureUsageList: ProductFeatureUsageList[];
};

const ExploreCloudBanner = ({ isPartner, productFeatureUsageList = [] }: Props) => {
  const analytics = useContext(AnalyticsContext);
  const { onCMTLearnMoreAnalytics } = generateAnalytics(analytics, comparatorEvents);

  return !isPartner &&
    (productFeatureUsageList
      .filter(({ unitType }) => unitType === 'USER')
      .find(({ unitCount }) => unitCount > 10) ||
      productFeatureUsageList
        .filter(({ unitType }) => unitType !== 'USER')
        .find(({ unitCount }) => unitCount > 3)) ? (
    <GridWrapper>
      <GridColumn medium={1} />
      <GridColumn medium={10}>
        <BannerWrapper>
          <MessageWrapper>
            <Title>
              <FormattedMessage id="migrate.comparator.page.cloud-trial-banner.title" />
            </Title>
            <TextWrapper>
              <FormattedMessage id="migrate.comparator.page.cloud-trial-banner.text" />
            </TextWrapper>
            <a
              href={`${config.wacUrl}/migration/cloud/free-license`}
              onClick={() => onCMTLearnMoreAnalytics()}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="migrate.comparator.page.cloud-trial-banner.link" />
            </a>
          </MessageWrapper>
          <ImageWrapper src={CMT} alt="Cloud Migration Trial banner" />
        </BannerWrapper>
      </GridColumn>
      <GridColumn medium={1} />
    </GridWrapper>
  ) : null;
};

export default ExploreCloudBanner;
