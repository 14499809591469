// @flow
import type { TermOption } from 'model/TermOption';
import { CloudProductKey, IntercomEnabledProducts } from 'data/products/keys';

export const buildTermOption = (
  terms: number,
  amountPerTerm: number,
  monthsPerTerm: number,
  subscription: boolean,
): TermOption => ({
  terms,
  amount: amountPerTerm * (terms - (subscription ? 0 : 1)),
  months: monthsPerTerm * terms,
});

export const buildTermOptions = (
  amount: number,
  months: number,
  isSubscription: boolean,
  termsAmount: number,
): TermOption[] => {
  const terms = [];
  for (let i = 1; i <= termsAmount; i += 1) {
    terms.push(buildTermOption(i, amount, months, isSubscription));
  }
  return terms;
};

/**
 * Currently, Intercom is enabled for few cloud products.
 * @param productKey the cloud product key for choosen product.
 * @returns the eligibility of product to show intercom chat tool.
 */

export const isIntercomEnabledProduct = (productKey: CloudProductKey): boolean =>
  IntercomEnabledProducts.includes(productKey);
