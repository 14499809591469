/* eslint-disable no-param-reassign */
// @flow
import Progress from 'redux-progress';
import produce from 'immer';
import type { GeneralProductConfiguration } from 'modules/configure/duck/model';
import { actions } from 'modules/configure/duck';
import { actions as cartActions } from 'modules/cart';

const runAction = (state: GeneralProductConfiguration, action: any) => {
  switch (action.type) {
    case actions.SELECT_TERM:
      state.terms = action.payload.terms;
      break;

    case actions.SELECT_TIER:
      if (action.payload.productKey === state.product.productKey) {
        state.product.tier = action.payload.tier;
      } else {
        state.addons
          .filter((addon) => action.payload.productKey === addon.productKey)
          .forEach((addon) => {
            addon.tier = action.payload.tier;
          });
      }
      break;
    case actions.EXPAND_ADDON:
      state.addons
        .filter((addon) => addon.productKey === action.payload.productKey)
        .forEach((addon) => {
          addon.expanded = addon.expanded !== true;
        });
      break;
    case cartActions.ADD_PRODUCT_TO_CART:
      state.submitProgress = action.progress;
      break;
    default:
      break;
  }
};

const extractAddons = (action: any) => {
  if (action.meta && action.meta.query && action.meta.query.addons) {
    if (typeof action.meta.query.addons === 'string') {
      return action.meta.query.addons.split(',');
    }
    return action.meta.query.addons;
  }
  return [];
};

export default (
  state: GeneralProductConfiguration | null = null,
  action: any,
): GeneralProductConfiguration | null => {
  switch (action.type) {
    case actions.ROUTE:
      if (!state || state.product.productKey !== action.payload.productKey) {
        return {
          academic: false,
          addons: [],
          product: {
            productKey: action.payload.productKey,
            selected: true,
            tier: null,
          },
          terms: 1,
          submitProgress: Progress.none,
        };
      }
      return produce(state, (draft) => {
        const selected = extractAddons(action);
        draft.addons.forEach((addon) => {
          addon.selected = selected.includes(addon.productKey);
        });
      });
    case actions.ADD_ADDONS:
      return state
        ? produce(state, (draft) => {
            draft.addons.push(
              ...action.payload.addons.map((productKey) => ({
                productKey,
                selected: action.payload.selectedAddons.includes(productKey),
                tier: null,
              })),
            );
          })
        : state;
    default:
      if (action.meta && action.meta.location) {
        return null;
      }

      return state
        ? produce(state, (draft) => {
            runAction(draft, action);
          })
        : null;
  }
};
