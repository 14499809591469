import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Dimensions } from 'components/visuals';

import Select, { OptionType } from '@atlaskit/select';

import { PRICING_PLANS } from 'modules/migrate/comparator/utils';

import { AnalyticsContext } from 'modules/migrate/analytics/AnalyticsContext';
import { comparatorEvents } from 'modules/migrate/analytics/analyticsEvents';
import { generateAnalytics } from 'modules/migrate/analytics/utils';

const Label = styled.div`
  line-height: 28px;
`;

const ComparePlansLinkRow = styled.div`
  margin-top: ${Dimensions.units(1)}px;
`;

const SelectWrapper = styled.div`
  max-width: ${Dimensions.units(14)}px;
`;

type Props = {
  plansList: string[];
  unitCount: number;
  setNumberOfUsers: (arg0: number) => void;
  recommendedPlan: string;
  setRecommendedPlan: (arg0: string) => void;
  pricingLink: string;
  productName: string;
  productKey: string;
  numberOfUsers: number;
};

const RecommendedPlan = ({
  plansList,
  unitCount,
  setNumberOfUsers,
  recommendedPlan,
  setRecommendedPlan,
  pricingLink,
  productName,
  productKey,
  numberOfUsers,
}: Props) => {
  const analytics = useContext(AnalyticsContext);
  const { onComparePlansAnalytics } = generateAnalytics(analytics, comparatorEvents);

  const onSelectChange = (option: OptionType) => {
    const chosenPlan = option.value;
    if (chosenPlan === PRICING_PLANS.FREE) {
      setNumberOfUsers(unitCount);
    }
    setRecommendedPlan(chosenPlan);
  };

  const options: OptionType[] = useMemo(
    () =>
      (plansList || []).map((plan) => ({
        label: plan,
        value: plan,
      })),
    [plansList],
  );

  return (
    <>
      {options.length > 1 ? (
        <SelectWrapper>
          <Select
            options={options}
            onChange={onSelectChange}
            value={options.find((option) => option.value === recommendedPlan)}
          />
        </SelectWrapper>
      ) : (
        <Label>{plansList[0]}</Label>
      )}
      {pricingLink && (
        <ComparePlansLinkRow>
          <a
            href={pricingLink}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() =>
              onComparePlansAnalytics({
                productName,
                productKey,
                numberOfUsers,
                recommendedPlan,
              })
            }
          >
            <FormattedMessage id="migrate.table.row.plan.compare-plans" />
          </a>
        </ComparePlansLinkRow>
      )}
    </>
  );
};

export default RecommendedPlan;
