import React from 'react';

import TableHeader, { HeaderInfo } from 'components/common/Table/TableHeader';
import TableFooter, { FooterRow } from 'components/common/Table/TableFooter';

type Props = {
  headers: ReadonlyArray<HeaderInfo>;
  footers: ReadonlyArray<FooterRow>;
  body: React.ReactNode;
  dataAttr?: string;
};
const Table = ({ headers, body, footers, dataAttr }: Props) => (
  <table data-cy-table={dataAttr}>
    <TableHeader headers={headers} />
    {body}
    <TableFooter footerRows={footers} />
  </table>
);

export default Table;
