import Progress from 'redux-progress';
import { createSelector } from 'reselect';

import { selectProductKeys } from 'modules/addon/duck/selectors/productKeys';
import { getDeploymentOption, selectProductGroup, Group } from 'modules/catalog/groups';
import { Selector } from 'model/State';
import { DeploymentOption } from 'model/hams/DeploymentOption';

export const selectDeploymentGroup: Selector<Progress<Group | null>> = (state) => {
  const productKeys = selectProductKeys(state);
  return productKeys.length === 1
    ? selectProductGroup(state, { productKey: productKeys[0] })
    : Progress.success(null);
};

export const selectDeploymentOption: Selector<
  Progress<DeploymentOption | null>
> = createSelector(selectDeploymentGroup, selectProductKeys, (groupProgress, productKeys) =>
  groupProgress.map((group) =>
    productKeys.length !== 1 ? null : getDeploymentOption(group as Group, productKeys[0]),
  ),
);
