// @flow
import React from 'react';

import { Subheader, Section } from 'modules/checkout/review/details/Commons';
import t from 'modules/i18n/intl';

const PayOnAccountInstructions = () => <p>{t('review.pay-on-account.instructions')}</p>;

const PaymentOnAccountDetails = () => (
  <Section>
    <Subheader>Pay on account</Subheader>
    <PayOnAccountInstructions />
  </Section>
);

export default PaymentOnAccountDetails;
