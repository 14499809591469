import { thunkProgress } from 'redux-progress';
import { AxiosRequestConfig } from 'axios';
import hamsClient from 'modules/hams';
import { selectHamsClientConfig } from 'modules/auth/selectors';

const doFetchCheckoutMethodsAvailableFromHams = (
  clientConfig: AxiosRequestConfig,
  cartId: string,
) =>
  hamsClient
    .get(`/1.0/public/order/cart/${cartId}/checkoutMethods`, clientConfig)
    .then((r) => r.data.availability);

export const FETCH_CHECKOUT_METHODS_AVAILABLE = 'checkout/payment/checkoutMethods/FETCH';

export const fetchCheckoutMethodsAvailable = (cartId: string) => (dispatch, getState) =>
  dispatch(
    thunkProgress(
      FETCH_CHECKOUT_METHODS_AVAILABLE,
      doFetchCheckoutMethodsAvailableFromHams(selectHamsClientConfig(getState()), cartId),
    ),
  );
