import { cartId, editCartId } from 'modules/cart/duck/api/ids';
import * as steps from 'modules/checkout/steps';
import * as payment from 'modules/checkout/payment/duck/api';
import * as purchaseMode from 'modules/settings/purchaseMode';
import * as academic from 'modules/settings/academic';
import { CartThunk } from 'model/State';

export const RESET_CART = 'cart/RESET';

export const resetCart = (): CartThunk => (dispatch) => {
  if (editCartId.load()) {
    editCartId.reset();
  } else {
    cartId.reset();
  }

  payment.storage.paymentDetails.reset();
  payment.storage.checkoutMode.reset();
  payment.storage.sessionId.reset();
  dispatch(steps.reset());
  dispatch(purchaseMode.reset());
  dispatch(academic.reset());

  dispatch({ type: RESET_CART });
};
