import { map, distinctUntilChanged, filter } from 'rxjs/operators';
import * as contacts from 'modules/checkout/contacts/duck';
import { CartEpic } from 'model/State';
import { createTrackEvent, utils } from 'modules/analytics';
import {
  TrackAction,
  TrackActionSubject,
  TrackSource,
  TrackEventNames,
} from 'modules/analytics/model/TrackEvent';

export const requiredToLogin: CartEpic = (action$, state$) =>
  state$.pipe(
    map(contacts.selectors.getValidity),
    map((v) => v.get('purchaserContactDetails.email').error.requireLogin),
    distinctUntilChanged(),
    filter(Boolean),
    // map(() => actions.track('requiredToLogin')),
    map(() =>
      createTrackEvent(
        utils.getTrackEventData(
          TrackAction.Required,
          TrackActionSubject.Login,
          TrackSource.ContactDetailsScreen,
          TrackEventNames.RequiredToLogin,
        ),
      ),
    ),
  );

export default requiredToLogin;
