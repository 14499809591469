import { createAction } from 'redux-action';

export const OPEN_SURVEY = 'checkout/complete/survey/OPEN';
export const CLOSE_SURVEY = 'checkout/complete/survey/CLOSE';
export const DISMISS_SURVEY = 'checkout/complete/survey/DISMISS';

export const openSurvey = createAction(OPEN_SURVEY);
export const closeSurvey = createAction(CLOSE_SURVEY);
export const dismissSurvey = createAction(DISMISS_SURVEY);

export * from './route';
export * from './load';
export * from './fetchLicenses';
