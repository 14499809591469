import Progress from 'redux-progress';
import { CartThunk } from 'model/State';
import { HamsCart } from 'modules/cart/duck/model';
import { SWITCH_TO_DIFFERENT_CART } from 'modules/cart/duck/actions/fetchOrCreate';
import {
  selectAnnualBillingCart,
  selectBiennialBillingCart,
  selectChosenBillingPeriod,
} from '../selectors';
import { OriginalItemDetails } from '../model';
import FormValidationError from 'model/hams/FormValidationError';

export const SET_IN_SITE_CONFIGURATOR_MODE = 'siteconfigurator/SET_IN_SITE_CONFIGURATOR_MODE';
export const SET_BILLING_PERIOD = 'siteconfigurator/SET_BILLING_PERIOD';
export const SET_ANNUAL_CART = 'siteconfigurator/SET_ANNUAL_CART';
export const SET_BIENNIAL_CART = 'siteconfigurator/SET_BIENNIAL_CART';
export const SET_ORIGINAL_CART_ITEM_DETAILS = 'siteconfigurator/SET_ORIGINAL_CART_ITEM_DETAILS';
export const SET_ANALYTICS_TRIGGER = 'siteconfigurator/SET_ANALYTICS_TRIGGER';
export const SET_CART_FORM_VALIDATION_ERROR = 'siteconfigurator/SET_CART_FORM_VALIDATION_ERROR';

export const setAnnualCart = (cart?: HamsCart | null): CartThunk<Progress<HamsCart> | void> => (
  dispatch,
) => {
  dispatch({
    type: SET_ANNUAL_CART,
    payload: cart,
  });
};

export const setBiennialCart = (cart?: HamsCart | null): CartThunk<Progress<HamsCart> | void> => (
  dispatch,
) => {
  dispatch({
    type: SET_BIENNIAL_CART,
    payload: cart,
  });
};

export const setOriginalCartItemDetails = (
  originalCartItemDetails: OriginalItemDetails,
): CartThunk<Progress<HamsCart> | void> => (dispatch) => {
  dispatch({
    type: SET_ORIGINAL_CART_ITEM_DETAILS,
    payload: originalCartItemDetails,
  });
};

export const loadPayloadInCart = (): CartThunk<Progress<HamsCart> | void> => (
  dispatch,
  getState,
) => {
  const state = getState();

  switch (selectChosenBillingPeriod(state)) {
    case 1: {
      // switch cart payload to annualCart
      dispatch({
        type: SWITCH_TO_DIFFERENT_CART,
        payload: selectAnnualBillingCart(state),
      });
      break;
    }
    case 2: {
      // switch cart payload to biennialCart
      dispatch({
        type: SWITCH_TO_DIFFERENT_CART,
        payload: selectBiennialBillingCart(state),
      });
      break;
    }
    default:
      break;
  }
};

export const changeBillingPeriod = (billingPeriod: number): CartThunk => (dispatch) => {
  dispatch({
    type: SET_BILLING_PERIOD,
    payload: billingPeriod,
  });

  dispatch(loadPayloadInCart());
};

export const setAnalyticsTrigger = (trigger: string): CartThunk => (dispatch) => {
  dispatch({
    type: SET_ANALYTICS_TRIGGER,
    payload: trigger,
  });
};

export const setInSiteConfiguratorMode = (mode: boolean): CartThunk => (dispatch) => {
  dispatch({
    type: SET_IN_SITE_CONFIGURATOR_MODE,
    payload: mode,
  });
};

export const setCartFormValidationState = (
  mode: Progress<FormValidationError | null>,
): CartThunk => (dispatch) => {
  dispatch({
    type: SET_CART_FORM_VALIDATION_ERROR,
    payload: mode,
  });
};
