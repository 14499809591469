import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { State } from 'model/State';

import { colors } from '@atlaskit/theme';
import { Font, Dimensions } from 'components/visuals';
import {
  LicenseType,
  LICENSE_ACADEMIC,
  LICENSE_COMMUNITY,
  LICENSE_OPENSOURCE,
} from 'model/hams/LicenseType';

import { makeIsFeatureFlagOn } from 'modules/meta/launchdarkly/selectors';

const DetailsWrapper = styled.div`
  font-size: 12px;
  line-height: 16px;
`;

const LicenseWrapper = styled.p`
  background-color: ${colors.N40};
  font-family: ${Font.displayFamily};
  font-size: ${Dimensions.units(1.5)}px;
  font-weight: 500;
  text-align: center;
  color: ${colors.N500};
  margin-top: ${Dimensions.units(2)}px;
  text-transform: uppercase;
  width: fit-content;
  padding: 0 ${Dimensions.units(0.5)}px;
  border-radius: 3px;
`;

interface Props {
  accountId: number | string;
  accountName: string;
  expiryDate?: string;
  licenseType?: LicenseType;
  isNonCommercialOn: boolean;
  ownerEmail?: string;
}

const AccountDetails = ({
  accountId,
  accountName,
  expiryDate,
  licenseType,
  isNonCommercialOn,
  ownerEmail,
}: Props) => (
  <DetailsWrapper>
    {expiryDate && (
      <FormattedMessage
        id="migrate.table.row.expiry"
        values={{
          date: (
            <FormattedDate
              value={new Date(expiryDate)}
              day="numeric"
              month="short"
              year="numeric"
            />
          ),
        }}
      />
    )}
    <div> {`SEN-${accountId}`} </div>
    <div> {accountName} </div>
    {ownerEmail && <div> {ownerEmail} </div>}
    {isNonCommercialOn && licenseType && (
      <LicenseWrapper>
        {licenseType === LICENSE_OPENSOURCE && (
          <FormattedMessage id="migrate.table.row.licenseType.opensource" />
        )}
        {licenseType === LICENSE_ACADEMIC && (
          <FormattedMessage id="migrate.table.row.licenseType.academic" />
        )}
        {licenseType === LICENSE_COMMUNITY && (
          <FormattedMessage id="migrate.table.row.licenseType.community" />
        )}
      </LicenseWrapper>
    )}
  </DetailsWrapper>
);

const isNonCommercialFeatureFlagOn = makeIsFeatureFlagOn('cart-carebear-comparator-non-commercial');

export default connect((state: State) => ({
  isNonCommercialOn: isNonCommercialFeatureFlagOn(state).result,
}))(AccountDetails);
