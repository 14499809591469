// @flow
import Progress from 'redux-progress';
import { createSelector } from 'reselect';
import type { CheckoutContactsProps } from 'modules/checkout/contacts/duck/model/CheckoutContactsProps';
import {
  getBillingOrganisation,
  getTechnicalOrganisation,
} from 'modules/checkout/contacts/duck/selectors/getEnhancedOrganisations';
import getShowsResellerWarning from 'modules/checkout/contacts/duck/selectors/getShowsResellerWarning';
import getLockPurchaserEmail from 'modules/checkout/contacts/duck/selectors/getLockPurchaserEmail';
import getShowSubmitLoading from 'modules/checkout/contacts/duck/selectors/getShowSubmitLoading';
import getShowReconcilableWarning from 'modules/checkout/contacts/duck/selectors/getShowReconcilableWarning';
import getLockedCartCurrency from 'modules/checkout/contacts/duck/selectors/getLockedCartCurrency';
import getShowCurrencyChangeWarning from 'modules/checkout/contacts/duck/selectors/getShowCurrencyChangeWarning';
import getValidity from 'modules/checkout/contacts/duck/selectors/getValidity';
import type { State, Selector } from 'model/State';
import * as auth from 'modules/auth';
import hasOnlyNewItemsInCart, {
  hasNewItemsInCart,
  hasAllRenewalItemsFree,
} from 'modules/cart/duck/selectors/findSaleTypeInCart';

const selector: Selector<Progress<CheckoutContactsProps>> = createSelector(
  (s: State) => s.checkout.contacts,
  getBillingOrganisation,
  getTechnicalOrganisation,
  auth.selectors.getUserChannel,
  (s: State) => s.data.countries.all,
  getLockPurchaserEmail,
  getShowsResellerWarning,
  getValidity,
  getShowSubmitLoading,
  hasOnlyNewItemsInCart,
  hasNewItemsInCart,
  hasAllRenewalItemsFree,
  getShowReconcilableWarning,
  getLockedCartCurrency,
  getShowCurrencyChangeWarning,
  (s: State) => s.checkout.contacts.showCountryCurrency.showCompanyCountryCurrency,
  (
    contacts,
    _billingOrg,
    _technicalOrg,
    _userChannel,
    _countries,
    lockPurchaser,
    showsResellerWarning,
    validity,
    isSubmitLoading,
    _hasOnlyNewItems,
    _hasNewItemsInCart,
    _hasAllRenewalItemsFree,
    showReconcilableWarning,
    lockedCartCurrency,
    showCurrencyChangeWarning,
    showCompanyCountryCurrency,
  ): Progress<CheckoutContactsProps> =>
    Progress.all(
      contacts.data,
      _billingOrg,
      _technicalOrg,
      _userChannel,
      _countries,
      _hasOnlyNewItems,
      _hasNewItemsInCart,
      _hasAllRenewalItemsFree,
    ).map(
      ([
        data,
        billingOrg,
        technicalOrg,
        { isExpert },
        countries,
        hasOnlyNewItems,
        hasNewItems,
        hasAllFreeRenewItems,
      ]) =>
        ({
          data,
          submitProgress: contacts.submitProgress,
          billingOrg,
          technicalOrg,
          lockPurchaser,
          isExpert,
          countries,
          lockDifferentTechnical: data.resellerOrder,
          showsResellerWarning,
          validity,
          isSubmitLoading,
          hasOnlyNewItems,
          hasNewItems,
          hasAllFreeRenewItems,
          isDifferentTechnical: data.isDifferentTechnical,
          showReconcilableWarning,
          lockedCartCurrency,
          showCurrencyChangeWarning,
          showCompanyCountryCurrency,
        }: CheckoutContactsProps),
    ),
);
export default selector;
