import Progress from 'redux-progress';
import { createSelector } from 'reselect';

import { ChooseModeState, ParentData } from 'modules/addon/duck/model';
import selectChooseParentOptions from 'modules/addon/duck/selectors/chooseParentOptions';
import { Selector } from 'model/State';
import { HamsCart } from 'modules/cart/duck/model';

const getChosenParent = (options: ChooseModeState, cart: HamsCart, parents: ParentData[]) => {
  if (options.accountId) {
    return (
      parents.find(
        (parent) => parent.license && (parent.license.accountId as any) === options.accountId,
      ) || null
    );
  }

  if (options.cartItemId) {
    return parents.find((parent) => parent.cartItemId === options.cartItemId) || null;
  }

  return parents.length > 0 ? parents[0] : null;
};

const selectChosenParentOption: Selector<Progress<ParentData | null>> = createSelector(
  (state) => state.addon.choose,
  (state) => state.cart.payload,
  selectChooseParentOptions,
  (chooseOptions, cartProgress, parentOptionsProgress) =>
    Progress.all(cartProgress, parentOptionsProgress).map(([cart, parentOptions]) =>
      getChosenParent(chooseOptions, cart, parentOptions),
    ),
);

export default selectChosenParentOption;
