import { map } from 'rxjs/operators';
import { UI_EPIC_ACTION, UIEventEpicAction } from 'modules/analytics';
import { CartEpic } from 'model/State';
import { ofType } from 'redux-observable';
import { createUIEvent } from 'modules/analytics/actions/ui';

export const uiEvents: CartEpic<UIEventEpicAction> = (action$) =>
  action$.pipe(
    ofType(UI_EPIC_ACTION),
    map((action) => createUIEvent(action.payload.data, ...action.payload.selectorsOrObjects)),
  );

export default uiEvents;
