import * as actions from 'modules/checkout/payment/duck/actions';

const initialState = {
  failed: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.paypal.FAILED:
      return {
        failed: true,
      };
    default:
      return state;
  }
};
