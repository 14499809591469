// @flow
import React, { useContext, forwardRef } from 'react';
import styled from 'styled-components';
import { LoadingButton } from '@atlaskit/button';
import { Dimensions } from 'components/visuals';
import type { HamsCart } from 'modules/cart/duck/model';
import type { PurchaseMode } from 'modules/settings/purchaseMode';
import t from 'modules/i18n/intl';

import { CartAnalyticsContext } from 'modules/analytics';
import {
  analyticsEvents as siteConfiguratorAnalyticsEvents,
  AnalyticsTrigger as SiteConfiguratorAnalyticsTrigger,
} from 'modules/siteconfigurator/analytics';
import {
  analyticsEvents as reviewAnalyticsEvents,
  AnalyticsTrigger as ReviewAnalyticsTrigger,
} from 'modules/checkout/review/analytics';
import * as payment from 'modules/checkout/payment';
import UFOLoadHold from '@atlassian/ufo-load-hold';

type Props = {
  cart: HamsCart,
  eulaAccepted: boolean,
  onSubmit?: ((e) => void) | null,
  processing: boolean,
  purchaseMode: PurchaseMode,
  inSiteConfiguratorMode: boolean,
  isDisabled?: boolean,
};

const Button = styled(LoadingButton)`
  margin-top: ${Dimensions.units(2)}px;
  padding: 0 60px;
  font-size: 16px;
  line-height: 32px;
`;

const getSubmitAttributes = ({ status, items, updated }) => ({
  isQuoted: status === 'QUOTED',
  itemsQuantity: items.length,
  purchasingDurationDays: updated
    ? Math.round((new Date() - new Date(updated)) / 3600000 / 24)
    : -1,
});

const ButtonText = ({
  totalIncTax,
  purchaseMode,
  inSiteConfiguratorMode,
}: {
  totalIncTax: number,
  purchaseMode: PurchaseMode,
  inSiteConfiguratorMode: boolean,
}) => {
  if (inSiteConfiguratorMode) {
    switch (true) {
      case totalIncTax === 0:
        return t('common.submit');
      case purchaseMode === 'QUOTE':
        return t('review.save-quote');
      case purchaseMode === 'BUY':
        return t('review.confirm-and-pay');
      default:
        return t('review.confirm-and-pay');
    }
  } else {
    return totalIncTax === 0 || purchaseMode === 'BUY'
      ? t('common.submit')
      : t('review.save-quote');
  }
};

const SubmitButton = forwardRef(({ onSubmit = null, ...props }: Props, ref) => {
  const analytics = useContext(CartAnalyticsContext);
  let checkoutMode = '';

  if (props.cart.totalIncTax === 0) {
    checkoutMode = 'FREE';
  } else {
    checkoutMode = payment.api.storage.checkoutMode.load();
  }

  let UFOEvent = '';
  switch (checkoutMode) {
    case payment.model.CheckoutModes.STRIPE_CREDIT_CARD:
    case payment.model.CheckoutModes.CREDIT_CARD:
      UFOEvent = 'cart-submit-stripe';
      break;
    case payment.model.CheckoutModes.BANK_TRANSFER:
      UFOEvent = 'cart-submit-convert-to-quote';
      break;
    case payment.model.CheckoutModes.PAY_ON_TERMS:
      UFOEvent = 'cart-submit-pay-on-terms';
      break;
    case payment.model.CheckoutModes.PAY_ON_ACCOUNT:
      UFOEvent = 'cart-submit-pay-on-account';
      break;
    case payment.model.CheckoutModes.PAYPAL:
      UFOEvent = 'cart-submit-paypal';
      break;
    case payment.model.CheckoutModes.FREE:
      UFOEvent = 'cart-submit-free';
      break;
    default:
      UFOEvent = 'unknown-payment-method';
      break;
  }
  const instrumentedOnSubmit = (e) => {
    if (props.inSiteConfiguratorMode) {
      if (props.purchaseMode === 'QUOTE') {
        siteConfiguratorAnalyticsEvents[SiteConfiguratorAnalyticsTrigger.SaveQuoteSubmit](
          analytics,
        );
      } else {
        siteConfiguratorAnalyticsEvents[SiteConfiguratorAnalyticsTrigger.ConfirmAndPay](analytics);
      }
    } else if (props.purchaseMode === 'QUOTE') {
      reviewAnalyticsEvents[ReviewAnalyticsTrigger.SaveQuoteSubmit](analytics);
    } else {
      reviewAnalyticsEvents[ReviewAnalyticsTrigger.ConfirmAndPay](
        analytics,
        getSubmitAttributes(props.cart),
      );
    }
    onSubmit?.(e);
  };
  const isDisabled =
    !props.eulaAccepted ||
    props.cart.items.length === 0 ||
    props.onSubmit === null ||
    props.isDisabled;
  const isLoading = props.processing || props.onSubmit === null;

  return (
    <>
      <UFOLoadHold name="submitting-checkout" hold={isLoading} />
      <Button
        isDisabled={isDisabled}
        appearance="primary"
        onClick={instrumentedOnSubmit}
        isLoading={isLoading}
        type="submit"
        innerRef={ref}
        purchaseMode={props.purchaseMode}
        interactionName={UFOEvent}
      >
        <ButtonText
          totalIncTax={props.cart.totalIncTax}
          purchaseMode={props.purchaseMode}
          inSiteConfiguratorMode={props.inSiteConfiguratorMode}
        />
      </Button>
    </>
  );
});

export default SubmitButton;
