import { FeatureGateEnvironment, LocalOverrides } from '@atlaskit/feature-gate-js-client';

import config from 'appconfig';
import environment from 'appconfig/environment';
import { BIZBOX, E2E, LOCAL, PRODUCTION, STAGING } from 'model/EnvironmentConfig';

const getStatsigEnv = () => {
  try {
    switch (environment) {
      case PRODUCTION:
        return FeatureGateEnvironment.Production;
      case STAGING:
      case BIZBOX:
      case E2E:
        return FeatureGateEnvironment.Staging;
      case LOCAL:
      default:
        return FeatureGateEnvironment.Development;
    }
  } catch (e) {
    throw new Error('Error while specifying the environment for statsig');
  }
};

declare global {
  interface Window {
    FEATURE_GATES_OVERRIDES?: LocalOverrides;
  }
}

export const statsigConfig = {
  apiKey: config.statsigClientSDK,
  targetApp: 'cart_web',
  environment: getStatsigEnv(),
};
