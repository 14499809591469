// @flow
import { thunkProgress } from 'redux-progress';
import hamsClient from 'modules/hams';
import type { Dispatch, GetState } from 'model/State';
import { selectHamsClientConfig } from 'modules/auth/selectors';

export const CONVERT_TO_QUOTE = 'checkout/review/CONVERT_TO_QUOTE';

export default (cartId: string) => (dispatch: Dispatch, getState: GetState) =>
  dispatch(
    thunkProgress(
      CONVERT_TO_QUOTE,
      hamsClient
        .post(`/1.0/public/order/quote/${cartId}`, undefined, selectHamsClientConfig(getState()))
        .then((r) => r.data),
    ),
  );
