// @flow
import * as React from 'react';
import { Field as AkField, ErrorMessage } from '@atlaskit/form';
import get from 'lodash/get';
import { FormContext, concatPath } from './Form';
import { FieldValidity } from './duck/model';
import BindFieldInput from './BindFieldInput';

export type FieldContext = {
  value: any,
  validity: FieldValidity,
  edit: (value: *) => void,
  onBlur: () => void,
};
export type Renderer = ((FieldContext) => ?React.Element<*>) | React.Element<*>;

type FieldProps = $Diff<$PropertyType<AkField, 'props'>, { children: any }> & {
  children: Renderer | Renderer[],
  errorMessage?: (Object) => string | void,
  invalidMessage?: string,
};

export default ({
  name,
  children,
  errorMessage = (e) => e.text,
  invalidMessage,
  ...props
}: FieldProps) => (
  <FormContext.Consumer>
    {(context) => {
      const path = concatPath(context.path, name);
      const value = get(context.data, path) || '';
      const validity = context.validity.get(path);
      const invalidErrorMessage =
        invalidMessage ?? (validity.isValid ? null : errorMessage(validity.error));
      const field = {
        value,
        validity,
        edit: (newValue) => context.edit(path, newValue),
        onBlur: () => context.onBlur(path),
        invalidErrorMessage,
      };

      return (
        <AkField
          {...props}
          name={path}
          testId={path}
          isInvalid={!!invalidErrorMessage}
          validateOnBlur={false}
        >
          {({ fieldProps }) => (
            <>
              <BindFieldInput {...fieldProps} field={field}>
                {children}
              </BindFieldInput>
              {!!invalidErrorMessage && <ErrorMessage>{invalidErrorMessage}</ErrorMessage>}
            </>
          )}
        </AkField>
      );
    }}
  </FormContext.Consumer>
);
