/* eslint-disable import/prefer-default-export */

import { redirect, Action } from 'redux-first-router';
import { fetchLicenses } from 'modules/checkout/complete/duck/actions/fetchLicenses';
import { CartThunk } from 'model/State';
import { goToProductList } from 'actions';
import { actions, selectors, api } from 'modules/cart';

export const load: CartThunk<Action | Promise<any>> = (dispatch, getState) => {
  const { query } = getState().location;
  if (!query?.mode && !query?.fromMAC) {
    return dispatch(redirect(goToProductList()));
  }

  let cartId: null | undefined | string = query?.uuid;
  if (!cartId) {
    cartId = selectors.id(getState());
    if (cartId) {
      api.storage.clearedCartId.store(cartId);
      dispatch(actions.resetCart());
    } else {
      cartId = api.storage.clearedCartId.load();
    }
  }

  if (cartId && query?.cartItemIdToAssociateWithServerId && query.serverId) {
    return dispatch(fetchLicenses(cartId, query.cartItemIdToAssociateWithServerId, query.serverId));
  }

  return Promise.resolve();
};
