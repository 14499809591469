import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Button from '@atlaskit/button/standard-button';
import { colors } from '@atlaskit/theme';
import { FormattedMessage } from 'react-intl';
import { uid } from 'react-uid';
import { getCurrentContactEmail } from 'modules/auth/selectors/contactDetails';
import flaggedForReview from 'modules/checkout/complete/img/flagged-for-review.svg';
import Celebration from 'modules/checkout/complete/img/celebration.png';
import IncomingMessage from 'modules/checkout/complete/img/incoming-message.svg';
import Invitation from 'modules/checkout/complete/img/invitation.png';
import ShoppingQuote from 'modules/checkout/complete/img/shopping-quote.png';
import SurveyFlag from 'modules/checkout/complete/SurveyFlag';
import SurveyModal from 'modules/checkout/complete/SurveyModal';
import { ConfirmationData } from 'modules/checkout/complete/duck/model';
import { selectConfirmationData } from 'modules/checkout/complete/duck/selectors';
import CallbackForm from 'modules/checkout/complete/CallbackForm';
import t, { Intl } from 'modules/i18n/intl';
import { actions, model } from 'modules/checkout/complete';
import { Dispatch, State } from 'model/State';
import config from 'appconfig';
import { Font } from 'components/visuals';
import macLoginURITransformer from 'util/macLoginURITransformer';

type StateProps = {
  data: ConfirmationData;
  licenses: model.License[] | null;
  showSurvey: boolean;
  showFlag: boolean;
  currentContactEmail: string | null;
};

type DispatchProps = {
  onSurveyOpen: () => void;
  onSurveyClose: () => void;
  onSurveyDismiss: () => void;
};

type Props = StateProps & DispatchProps;

const Centering = styled.div`
  text-align: center;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
`;

const Container = styled.div`
  max-width: 805px;
  margin: auto;
  padding-bottom: 30px;
`;

const Heading = styled.div`
  color: ${colors.N700};
  font-family: ${Font.displayFamily};
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  margin-top: 20px;
  margin-bottom: 24px;
`;

const SubHeading = styled.div`
  color: ${colors.N700};
  font-family: ${Font.displayFamily};
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
  letter-spacing: 0.3px;
`;

const ActionItem = styled.p`
  padding: 12px 0;
  width: 800px;
  margin: auto;
`;

const images = {
  reviewRequired: (
    <Intl>
      {(translate) => (
        <img
          src={flaggedForReview}
          height={214}
          alt={translate('confirmation.need-to-review-request')}
        />
      )}
    </Intl>
  ),
  quote: {
    existingUser: (
      <Intl>
        {(translate) => (
          <img
            src={ShoppingQuote}
            height={214}
            alt={translate('confirmation.your-quote-processed')}
          />
        )}
      </Intl>
    ),
    newUser: (
      <Intl>
        {(translate) => (
          <img src={Invitation} height={130} alt={translate('confirmation.check-your-inbox')} />
        )}
      </Intl>
    ),
  },
  order: {
    existingUser: (
      <Intl>
        {(translate) => (
          <img
            src={Celebration}
            height={163}
            alt={translate('confirmation.your-order-processed')}
          />
        )}
      </Intl>
    ),
    newUser: (
      <Intl>
        {(translate) => (
          <img
            src={IncomingMessage}
            height={214}
            alt={translate('confirmation.check-your-inbox')}
          />
        )}
      </Intl>
    ),
  },
};

export const PurchaserConfirmation = ({
  data,
  licenses,
  showSurvey,
  showFlag,
  currentContactEmail,
  onSurveyOpen,
  onSurveyClose,
  onSurveyDismiss,
}: Props) => {
  let image: React.ReactNode = null;
  let statusMessage = '';
  const subStatuses: React.ReactNode[] = [];
  const actionItems: React.ReactNode[] = [];

  const productsIndexString = '/products/index';
  const macLink = (path: string) =>
    macLoginURITransformer({
      url: `${config.macUrl}${path}`,
      currentContactEmail,
    });

  if (data.reviewRequired) {
    image = images.reviewRequired;
    subStatuses.push(t('confirmation.review-your-order'));
    if (data.checkoutMode === 'pot') {
      statusMessage = t('confirmation.will-be-touch-soon');
      subStatuses.push(t('confirmation.net-30-license-provisioned'));
    } else {
      statusMessage = t('confirmation.thank-you-for-choosing-atlassian');
    }
    subStatuses.push(t('confirmation.your-order-number', { orderNumber: data.ordernumber }));
  } else if (data.mode === 'quote') {
    subStatuses.push(t('confirmation.your-order-number', { orderNumber: data.ordernumber }));
    if (data.lasso) {
      image = images.quote.existingUser;
      statusMessage = t('confirmation.your-quote-is-ready');
      const editedCartIdParam = data.editedCartId ? `?editedUuid=${data.editedCartId}` : '';
      actionItems.push(
        <Button
          href={macLink(`/billing/quotes${editedCartIdParam}`)}
          appearance="primary"
          type="button"
        >
          {t('confirmation.view-your-quote')}
        </Button>,
      );
      actionItems.push(
        <a href={`${config.wacUrl}/licensing/purchase-licensing/howtopay`}>
          {t('confirmation.check-the-payment-option')}
        </a>,
      );
    } else {
      image = images.quote.newUser;
      statusMessage = t('confirmation.check-inbox-to-download-quote');
    }
  } else if (data.mode === 'order' || data.fromMAC) {
    if (data.lasso || data.fromMAC) {
      // existing user
      image = images.order.existingUser;
      subStatuses.push(t('confirmation.your-order-number', { orderNumber: data.ordernumber }));
      if (data.callback && licenses) {
        actionItems.push(
          <CallbackForm callback={data.callback} purchasedProductLicenses={licenses} />,
        );
      } else if (data.claimCloudId.length === 1) {
        const href = `${config.atlAdminUrl}/claim?uuid=${data.claimCloudId[0]}`;
        actionItems.push(
          <Button href={href} appearance="primary" type="button">
            {t('confirmation.claim-your-site')}
          </Button>,
        );
      } else if (data.claimCloudId.length > 1) {
        actionItems.push(
          <Button href={macLink(productsIndexString)} appearance="primary" type="button">
            {t('confirmation.get-started')}
          </Button>,
        );
      } else {
        let href = macLink(productsIndexString);
        if (data.fromMAC || data.singleAddon) {
          href = macLink(`/addon/success?orderId=${data.ordernumber.replace(/\D/g, '')}`);
        }
        actionItems.push(
          <Button href={href} appearance="primary" type="button">
            {t('confirmation.get-your-licenses')}
          </Button>,
        );
      }
      if (data.checkoutMode === 'bank') {
        statusMessage = t('confirmation.thanks-for-your-order');
        subStatuses.push(t('confirmation.check-inbox-for-next-steps'));
        actionItems.push(
          <Button href={macLink('/billing/quotes')} appearance="link" type="button">
            {t('confirmation.view-your-order')}
          </Button>,
        );
      } else {
        statusMessage = t('confirmation.thanks-for-your-purchase');
        actionItems.push(
          <a href={macLink('/billing/paidorders')}>{t('confirmation.view-your-invoice')}</a>,
        );
      }
    } else {
      image = images.order.newUser;
      statusMessage = t('confirmation.check-your-inbox-to-activate-account');
      subStatuses.push(
        <FormattedMessage
          id="confirmation.login-to-atlassian"
          values={{ myAtlassian: <a href={macLink('')}>{t('confirmation.my-atlassian')}</a> }}
        />,
      );
      subStatuses.push(t('confirmation.your-order-number', { orderNumber: data.ordernumber }));
    }
    if (data.hasTraining) {
      subStatuses.push(t('confirmation.email-sent-to-technical-person'));
    }
  }

  return (
    <Centering>
      <SurveyModal isOpen={showSurvey} onClose={onSurveyClose} />
      <SurveyFlag isOpen={showFlag} onOpen={onSurveyOpen} onDismissed={onSurveyDismiss} />
      {image}
      <Container>
        <Heading>{statusMessage}</Heading>
        {subStatuses.map((subStatus) => (
          <SubHeading key={uid(subStatus)}>{subStatus}</SubHeading>
        ))}
      </Container>
      {actionItems.map((actionItem) => (
        <ActionItem key={uid(actionItem)}>{actionItem}</ActionItem>
      ))}
    </Centering>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  data: selectConfirmationData(state),
  licenses: state.checkout.complete.licenses.ifSuccess((r) => r.purchasedProductLicenses),
  showSurvey: state.checkout.complete.showSurvey,
  showFlag: state.checkout.complete.showFlag,
  currentContactEmail: getCurrentContactEmail(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSurveyOpen: () => dispatch(actions.openSurvey({})),
  onSurveyClose: () => dispatch(actions.closeSurvey({})),
  onSurveyDismiss: () => dispatch(actions.dismissSurvey({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaserConfirmation);
