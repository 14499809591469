// @flow
import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import type { State } from 'model/State';
import { selectActiveLanguage, selectI18NMessages } from 'modules/i18n/duck/selectors';

type Props = {
  children: *,
  language: string,
  messages: { [string]: string },
};

const I18nProvider = (props: Props) => (
  <IntlProvider key={props.language} locale={props.language} messages={props.messages}>
    {props.children}
  </IntlProvider>
);

const mapStateToProps = (state: State) => ({
  language: selectActiveLanguage(state),
  messages: selectI18NMessages(state),
});

export default connect(mapStateToProps)(I18nProvider);
