/**
 * The code to insert the snippet is as provided by Clicktale
 */
/* eslint-disable */
import config from 'appconfig';
import { isAnalyticsDisabled } from 'util/analytics';

const maskingSelectors = [
  "input[type='text']",
  "input[type='email']",
  "input[type='tel']",
  'textarea',
  '.pii',
  '.country__single-value',
  'span[class^="SelectWithExtraInfo__Text"]',
  'tbody[class^="TableBody__ParentItemWrapper"] tr',
  'span[class^="AmountWithCurrency__Wrapper"]',
  'div[class^="ConfirmationPage__SubHeading"]',
];
window.ClickTalePIISelector = maskingSelectors.join(', ');

const hackClicktaleStyledComponents = () => {
  //
  // Styled components hack for Clicktale
  //
  // we need to duplicate all CSS generated by styled components using insertRule(), since
  // Clicktale won't capture those, and add them as DOM manipulations in the page.
  // This will likely get fixed by Clicktale at some point.
  // Hack originally adapted from https://engineering.brighthr.com/blog-posts/hotjar-styled-components-fix/
  // SC Discussion on https://github.com/styled-components/styled-components/issues/1603

  const syncStylesEl = document.createElement('style');
  syncStylesEl.type = 'text/css';
  document.head.insertBefore(syncStylesEl, document.head.children[0]);

  let syncClicktaleStylesTimeout;
  const debouncedSyncClicktaleStyles = function () {
    const later = function () {
      const styleNodes = [].slice.call(document.querySelectorAll('head [data-styled-components]'));
      if (!styleNodes.length) {
        return;
      }
      syncStylesEl.textContent = styleNodes
        .map(({ sheet }) =>
          [].slice
            .call(sheet.cssRules)
            .map((rule) => rule.cssText)
            .join(' '),
        )
        .join(' ');
    };
    clearTimeout(syncClicktaleStylesTimeout);
    syncClicktaleStylesTimeout = setTimeout(later, 1000);
  };

  const originalInsertRule = CSSStyleSheet.prototype.insertRule;
  CSSStyleSheet.prototype.insertRule = function (style, index) {
    originalInsertRule.call(this, style, index);
    if (typeof window.ClickTaleIsRecording === 'function' && window.ClickTaleIsRecording()) {
      debouncedSyncClicktaleStyles();
    }
  };
};

if (config.clicktaleEnabled && !isAnalyticsDisabled()) {
  if (!window.clickTaleTagInjected) {
    hackClicktaleStyledComponents();

    // Clicktale snippet
    !(function (d, t, u) {
      window.clickTaleTagInjected = true;
      function injectTag() {
        var ns = d.createElementNS;
        var a = ns ? ns.call(d, 'http://www.w3.org/1999/xhtml', t) : d.createElement(t),
          s = d.getElementsByTagName(t)[0];
        a.async = true;
        a.crossOrigin = 'anonymous';
        a.type = 'text/javascript';
        a.src = u;
        s.parentNode.insertBefore(a, s);
      }
      if (d.readyState != 'loading') {
        injectTag();
      } else {
        d.addEventListener('DOMContentLoaded', function () {
          setTimeout(injectTag, 0);
        });
      }
    })(
      document,
      'script',
      'https://cdnssl.clicktale.net/www33/ptc/ef81b94c-8498-4f12-b358-eb76a000a247.js',
    );
  }
}
