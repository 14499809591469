import flow from 'lodash/fp/flow';
import pickBy from 'lodash/fp/pickBy';
import mapValues from 'lodash/fp/mapValues';
import { ProductGroupKey } from 'data/products/keys';
import { Group } from 'modules/catalog/groups';
import ServerProduct from 'model/editions/ServerProduct';
import DataCenterProduct from 'model/editions/DataCenterProduct';
import CloudProduct from 'model/editions/CloudProduct';
import Product from './Product';
import { CLOUD, DATACENTER, SERVER } from 'model/hams/DeploymentOption';

export default class SoftwareProductGroup {
  key: ProductGroupKey;
  server: ServerProduct;
  dataCenter: DataCenterProduct | null;
  cloud: CloudProduct | null;

  constructor(
    server: ServerProduct,
    dataCenter: DataCenterProduct | null = null,
    cloud: CloudProduct | null = null,
  ) {
    this.key = server.groupKey;
    this.server = server;
    this.dataCenter = dataCenter;
    this.cloud = cloud;

    [server, dataCenter, cloud].forEach((p) => {
      if (p && p.groupKey !== this.key) {
        throw Error(`Mismatch group key (${this.key}) for product ${p.key}`);
      }
    });
  }

  getDeploymentOptions(): Group {
    return flow(
      pickBy(Boolean),
      mapValues((p: Product) => p.key),
    )({
      [SERVER]: this.server,
      [DATACENTER]: this.dataCenter,
      [CLOUD]: this.cloud,
    });
  }
}
