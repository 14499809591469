export default () => (next) => (action) => {
  const start = new Date();
  try {
    return next(action);
  } finally {
    if (process.env.NODE_ENV === 'development' && action.type) {
      // eslint-disable-next-line
      console.log(
        `Action: ${action.type} took ${new Date().getTime() - start.getTime()} milliseconds`,
      );
    }
  }
};
