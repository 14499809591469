import { UIAction, UIActionSubject } from 'modules/analytics/model/UIEvent';
import { getEdition } from './utils';

import {
  TrackAction,
  TrackActionSubject,
  TrackSource,
  TrackEventNames,
} from 'modules/analytics/model/TrackEvent';

export enum AnalyticsTrigger {
  ConfigurePage = 'configurePage',
  BillingCycle = 'billingCycle',
  Edition = 'edition',
  Tier = 'tier',
  Continue = 'continue',
  SaveQuote = 'saveQuote',
  SaveQuoteContinue = 'saveQuoteContinue',
  SaveQuoteSubmit = 'saveQuoteSubmit',
  LoyaltyDiscount = 'loyaltyDiscount',
  ContactUs = 'contactUs',
  ContactDetailsContinue = 'contactDetailsContinue',
  PayPal = 'payPal',
  CreditCard = 'creditCard',
  BankTransferCheck = 'bankTransferCheck',
  ConfirmAndPay = 'confirmAndPay',
  CartConfigContactDetailsError = 'cartConfigContactDetailsError',
  CartConfigContactDetailsAdd = 'cartConfigContactDetailsAdd',
  CartConfigSubmitSuccess = 'cartConfigSubmitSuccess',
  ConfigConfigSubmitFailed = 'configConfigSubmitFailed',
  CartConfigPartnerLoginError = 'cartConfigPartnerLoginError',
  CartConfigPartnerLogin = 'cartConfigPartnerLogin',
}

export const analyticsEvents = {
  [AnalyticsTrigger.ConfigurePage]: (analytics, { cart, selectedBillingPeriod }) => {
    analytics.sendUiEvent({
      action: UIAction.Rendered,
      actionSubject: UIActionSubject.CartConfig,
      actionSubjectId: 'trialToAnnualPathCartConfigRender',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: 'cartConfig',
        parentLabel: UIActionSubject.CartConfig,
        label: 'Render cart configure page',
        uuid: '7c13a9c4-aa82-4c13-b08c-836a33b873d8',
        itemAmount: cart?.items?.length || 0,
        totalCartValue: cart?.totalIncTax,
        cartId: cart?.uuid,
        isPartner: !!(cart?.expertOrder || cart?.resellerOrder),
        billingCycle: selectedBillingPeriod,
      },
    });
  },
  [AnalyticsTrigger.BillingCycle]: (analytics, { cart, selectedBillingPeriod }) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.CartConfig,
      actionSubjectId: 'trialToAnnualPathCartConfigSelectCycle',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: 'radiobutton',
        parentLabel: UIActionSubject.CartConfig,
        label: 'Select a billing cycle',
        uuid: 'd09fde8a-f080-4fcf-985c-4874aecaf6bb',
        itemAmount: cart?.items?.length || 0,
        totalCartValue: cart?.totalIncTax,
        cartId: cart?.uuid,
        isPartner: !!(cart?.expertOrder || cart?.resellerOrder),
        billingCycle: selectedBillingPeriod,
      },
    });
  },
  [AnalyticsTrigger.Edition]: (analytics, { cart, cartItem, selectedBillingPeriod }) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.CartConfig,
      actionSubjectId: 'trialToAnnualPathCartConfigSelectEdition',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: 'editionOption',
        parentLabel: UIActionSubject.CartConfig,
        label: 'Select an upgrade edition',
        uuid: 'd5e59c35-0a85-4389-a856-94f1531ebf21',
        productName: cartItem?.productDetails?.productDescription || '',
        editionChosen: cartItem && getEdition(cartItem),
        userTiers: cartItem?.productDetails?.unitCount || '',
        productPrice: cartItem?.totalIncTax || '',
        totalCartValue: cart?.totalIncTax,
        cartId: cart?.uuid,
        isPartner: !!(cart?.expertOrder || cart?.resellerOrder),
        billingCycle: selectedBillingPeriod,
      },
    });
  },
  [AnalyticsTrigger.Tier]: (analytics, { cart, cartItem, selectedBillingPeriod }) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.CartConfig,
      actionSubjectId: 'trialToAnnualPathCartConfigSelectTier',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: 'tierOption',
        parentLabel: UIActionSubject.CartConfig,
        label: 'Select a tier',
        uuid: '9495cf06-132e-4a63-96a8-51891cb21fda',
        productName: cartItem?.productDetails?.productDescription || '',
        editionChosen: cartItem && getEdition(cartItem),
        userTiers: cartItem?.productDetails?.unitCount || '',
        productPrice: cartItem?.totalIncTax || '',
        totalCartValue: cart?.totalIncTax,
        cartId: cart?.uuid,
        isPartner: !!(cart?.expertOrder || cart?.resellerOrder),
        billingCycle: selectedBillingPeriod,
      },
    });
  },
  [AnalyticsTrigger.Continue]: (analytics, { cart }) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'trialToAnnualPathCartConfigContinue',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: UIActionSubject.Button,
        parentLabel: UIActionSubject.CartConfig,
        label: 'Continue',
        uuid: '21ee839d-831e-4901-adf7-1a0786d15383',
        itemAmount: cart?.items?.length || 0,
        totalCartValue: cart?.totalIncTax,
        cartId: cart?.uuid,
        isPartner: !!(cart?.expertOrder || cart?.resellerOrder),
        billingCycleStartCheckout: 'continue',
      },
    });
  },
  [AnalyticsTrigger.SaveQuote]: (analytics, { cart }) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.LinkButton,
      actionSubjectId: 'trialToAnnualPathCartSaveQuote',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: UIActionSubject.LinkButton,
        parentLabel: UIActionSubject.CartConfig,
        label: 'Or save this as a quote',
        uuid: 'a4e03183-d5c0-41a2-a12b-001d4bf67d3a',
        itemAmount: cart?.items?.length || 0,
        totalCartValue: cart?.totalIncTax,
        cartId: cart?.uuid,
        isPartner: !!(cart?.expertOrder || cart?.resellerOrder),
        billingCycleStartCheckout: 'generateQuote',
      },
    });
  },
  [AnalyticsTrigger.SaveQuoteContinue]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'trialToAnnualPathCartSaveQuoteContinue',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: UIActionSubject.Button,
        parentLabel: UIActionSubject.CartConfig,
        label: 'Save a quote continue',
        uuid: 'fb086d34-8e0e-44a0-98ef-ba86dc5ffadd',
        billingCycleStartCheckout: 'generateQuote',
      },
    });
  },
  [AnalyticsTrigger.SaveQuoteSubmit]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'trialToAnnualPathCartSaveQuoteSubmit',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: UIActionSubject.Button,
        parentLabel: UIActionSubject.CartConfig,
        label: 'Save a quote submit',
        uuid: '4e59063b-414e-44e7-8709-991f7fa27d27',
        billingCycleStartCheckout: 'generateQuote',
      },
    });
  },
  [AnalyticsTrigger.LoyaltyDiscount]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.LinkButton,
      actionSubjectId: 'trialToAnnualPathCartLoyaltyDiscount',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: UIActionSubject.LinkButton,
        parentLabel: UIActionSubject.CartConfig,
        label: 'loyaltyDiscount',
        uuid: '0050254c-2ebc-489f-a956-a9bd953219fb',
      },
    });
  },
  [AnalyticsTrigger.ContactUs]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.LinkButton,
      actionSubjectId: 'trialToAnnualPathCartLoyaltyDiscountContactUs',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: UIActionSubject.LinkButton,
        parentLabel: UIActionSubject.CartConfig,
        label: 'loyaltyDiscountContactUs',
        uuid: '91397261-4d66-43f5-bb9c-360bf80a0a19',
      },
    });
  },
  [AnalyticsTrigger.ContactDetailsContinue]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'trialToAnnualPathCartBillingContactDetails',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: UIActionSubject.Button,
        parentLabel: 'billingContactDetails',
        label: 'continue',
        uuid: '7370ef72-5eed-4072-aad4-c838f5c2f140',
      },
    });
  },
  [AnalyticsTrigger.PayPal]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'trialToAnnualPathCartPaymentPayPal',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: UIActionSubject.Button,
        parentLabel: 'paymentOptions',
        label: 'payPal',
        uuid: '1766e1d5-7117-4cb3-82f3-3550abae5d3d',
      },
    });
  },
  [AnalyticsTrigger.CreditCard]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'trialToAnnualPathCartPaymentCreditCard',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: UIActionSubject.Button,
        parentLabel: 'paymentOptions',
        label: 'creditCard',
        uuid: '4191a1f1-c8f8-4ccc-a014-7a3361287f87',
      },
    });
  },
  [AnalyticsTrigger.BankTransferCheck]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'trialToAnnualPathCartPaymentBankTransferCheck',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: UIActionSubject.Button,
        parentLabel: 'paymentOptions',
        label: 'bankTransferCheck',
        uuid: 'bca6aad4-b5ff-4eb4-92e3-8970c9340e12',
      },
    });
  },
  [AnalyticsTrigger.ConfirmAndPay]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'trialToAnnualPathCartPaymentConfirmAndPay',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: UIActionSubject.Button,
        parentLabel: 'payment',
        label: 'confirmAndPay',
        uuid: 'c195734e-0639-4673-b243-ca353a8c4923',
      },
    });
  },
  [AnalyticsTrigger.CartConfigContactDetailsError]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Rendered,
      actionSubject: UIActionSubject.CartConfig,
      actionSubjectId: 'trialToAnnualPathCartConfigContactDetailsError',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: 'cartConfig',
        parentLabel: UIActionSubject.CartConfig,
        label: 'Render cart configure contact details error',
        uuid: 'fbe4a72e-9233-4952-b0a6-57ca7b626ec6',
      },
    });
  },
  [AnalyticsTrigger.CartConfigContactDetailsAdd]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.LinkButton,
      actionSubjectId: 'trialToAnnualPathCartConfigContactDetailsAdd',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: 'cartConfig',
        parentLabel: UIActionSubject.CartConfig,
        label: 'Adding contact information',
        uuid: 'cc295893-be44-43f8-9c65-b222a4cc5206',
      },
    });
  },
  [AnalyticsTrigger.CartConfigSubmitSuccess]: ({
    checkoutMode,
    purchaseMode,
    reviewRequired,
    cart,
  }) => ({
    action: TrackAction.Submitted,
    actionSubject: TrackActionSubject.TrialToAnnualPathCartConfigSubmitSuccess,
    source: TrackSource.CartConfig,
    eventName: TrackEventNames.CartConfigSubmit,
    attributes: {
      eventContainer: 'trialToAnnualPath',
      eventComponent: 'cartConfig',
      parentLabel: 'cartConfig',
      label: 'Submit success',
      uuid: 'd23bc787-3093-463e-b005-35912283fba1',
      checkoutMode,
      purchaseMode,
      reviewRequired,
      totalCartValue: cart?.totalIncTax,
      cartId: cart?.uuid,
      isPartner: !!(cart?.expertOrder || cart?.resellerOrder),
      itemAmount: cart?.items?.length || 0,
    },
  }),
  [AnalyticsTrigger.ConfigConfigSubmitFailed]: ({ purchaseMode }) => ({
    action: TrackAction.Failed,
    actionSubject: TrackActionSubject.TrialToAnnualPathCartConfigSubmitFailed,
    source: TrackSource.CartConfig,
    eventName: TrackEventNames.CartConfigSubmit,
    attributes: {
      eventContainer: 'trialToAnnualPath',
      eventComponent: 'cartConfig',
      parentLabel: 'cartConfig',
      label: 'Submit failed',
      uuid: 'a808686f-17ab-46aa-8544-d977ddc24e89',
      purchaseMode,
    },
  }),
  [AnalyticsTrigger.CartConfigPartnerLoginError]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Rendered,
      actionSubject: UIActionSubject.CartConfig,
      actionSubjectId: 'trialToAnnualPathCartConfigPartnerLoginError',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: 'cartConfig',
        parentLabel: UIActionSubject.CartConfig,
        label: 'Render cart configure partner login error',
        uuid: '9761c4eb-e11f-498d-82e0-3697df942e7c',
      },
    });
  },
  [AnalyticsTrigger.CartConfigPartnerLogin]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.LinkButton,
      actionSubjectId: 'trialToAnnualPathCartConfigPartnerLogin',
      attributes: {
        eventContainer: 'trialToAnnualPath',
        eventComponent: 'cartConfig',
        parentLabel: UIActionSubject.CartConfig,
        label: 'Login',
        uuid: '182404e3-399f-493b-bfc9-1aa7a3ce4174',
      },
    });
  },
};
