/* eslint-disable import/prefer-default-export */
import { ExternalProductKeys } from 'data/products/keys';
import t from 'modules/i18n/intl';
import ExternalProduct from 'model/ExternalProduct';

const university = new ExternalProduct({
  key: ExternalProductKeys.UNIVERSITY,
  tagLine: t('products.university.tagline'),
  description: t('products.university.description'),
  name: t('products.university.name'),
  externalUrl: 'https://training.atlassian.com/jira-catalog',
});

export default university;
