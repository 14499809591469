import Progress from 'redux-progress';
import { StoredPromotion } from 'modules/promotion/duck/model';
import * as actions from 'modules/promotion/duck/actions';

export default (state: Progress<StoredPromotion> = Progress.none, action: any) => {
  switch (action.type) {
    case actions.LOAD_PROMOTION:
      return action.progress;
    default:
      return state;
  }
};
