// @flow
import React from 'react';
import { connect } from 'react-redux';
import { StepHeader, StepWrapper } from 'modules/configure/common/Steps';
import { actions, selectors } from 'modules/configure';
import TermsSelect from 'modules/configure/terms/TermsSelect';
import TermsTooltip from 'modules/configure/terms/TermsTooltip';
import t from 'modules/i18n/intl';

import type { Dispatch, State } from 'model/State';
import type { TermOption } from 'model/TermOption';
import selectCurrency from 'modules/cart/duck/selectors/currency';

type OwnProps = {
  isPeriod?: boolean,
};

type StateProps = {
  termOptions: TermOption[],
  term: TermOption | null,
  subscription: boolean,
  currency: string,
};

type DispatchProps = {
  onTermSelect: (TermOption) => void,
};

type Props = OwnProps & StateProps & DispatchProps;

const termsName = (period: boolean, subscription: boolean) => {
  if (period) {
    return t('purchase.choose-your-subscription-period');
  }
  if (subscription) {
    return t('purchase.choose-your-subscription-term');
  }
  return t('purchase.choose-your-maintenance-period');
};

const TermsStep = (props: Props) => (
  <StepWrapper>
    <StepHeader>
      {termsName(props.isPeriod === true, props.subscription)}&nbsp;
      {props.isPeriod ? null : <TermsTooltip subscription={props.subscription} />}
    </StepHeader>
    <TermsSelect
      options={props.termOptions}
      selected={props.term}
      onChange={props.onTermSelect}
      currency={props.currency}
    />
  </StepWrapper>
);

const mapStateToProps = (state: State): StateProps => ({
  term: selectors.selectTerm(state),
  subscription: selectors.isSubscription(state),
  termOptions: selectors.selectTermOptions(state),
  currency: selectCurrency(state).result,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onTermSelect: (term) => dispatch(actions.setTerm(term)),
});

TermsStep.defaultProps = {
  isPeriod: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsStep);
