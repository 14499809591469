import { progressResult, selectLatestFrom } from 'util/rxOperators';
import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { pairwise, map, filter, skipUntil } from 'rxjs/operators';
import { SUBMIT } from 'modules/checkout/contacts/duck/actions/submit';
import * as actions from 'modules/checkout/contacts/duck/actions';
import selectCurrency from 'modules/cart/duck/selectors/currency';
import { ROUTE } from 'modules/checkout/contacts/duck/actions/route';
import { State$ } from 'model/State';

const isSubmitAction$ = (action$) =>
  action$.pipe(
    ofType(SUBMIT),
    filter((action: any) => action.progress.success),
  );

export default (action$: Observable<any>, state$: State$) =>
  action$.pipe(
    ofType(SUBMIT),
    selectLatestFrom(state$),
    progressResult(selectCurrency),
    pairwise(),
    skipUntil(isSubmitAction$(action$)),
    filter(() => (state$ as any).value.location.type !== ROUTE), // TODO CIW5-18 investigate why we had to type state$ as any to access .value (defs bump?)
    map((currencyPair) => actions.validateReactiveShowNotification(...currencyPair)),
  );
