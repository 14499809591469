import { ExternalProductKeys } from 'data/products/keys';
import t from 'modules/i18n/intl';
import ExternalProduct from 'model/ExternalProduct';
import config from 'appconfig';

const access = new ExternalProduct({
  key: ExternalProductKeys.ACCESS,
  tagLine: t('products.access.tagline'),
  description: t('products.access.description'),
  name: t('products.access.name'),
  externalUrl: `${config.adminHubUrl}/atlassian-access`,
});

export default access;
