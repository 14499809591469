import React from 'react';
import styled from 'styled-components';
import Select from '@atlaskit/select';
import { colors } from '@atlaskit/theme';

import NavLink from 'components/common/NavLink';
import t from 'modules/i18n/intl';
import { Dimensions, Font } from 'components/visuals';
import { PurchaseMode } from 'modules/settings/purchaseMode';

export type Option = {
  label: React.ReactNode;
  to: string | Object;
};

type Props = {
  options?: Option[];
  purchaseMode: PurchaseMode;
};

const SelectNoPadding = styled(Select)`
  div[class$='option'],
  div[role='option'] {
    padding: 0;
  }
`;

const Link = styled(NavLink)`
  font-family: ${Font.family};
  font-size: 16px;
  font-weight: 400;
  display: block;
  padding: 6px 12px;
  color: ${colors.N500};

  :hover {
    text-decoration: none;
  }
`;

const message = (purchaseMode) =>
  purchaseMode === 'BUY' ? t('cart.add-to-cart') : t('cart.add-to-quote');

const optionLabel = (option) => <Link to={option.to}>{option.label}</Link>;

const OptionsSelect = ({ options, purchaseMode }: Props) => (
  <SelectNoPadding
    options={options}
    getOptionLabel={optionLabel}
    placeholder={message(purchaseMode)}
    value={null}
  />
);

const OptionsWrapper = styled.div.attrs({
  'data-cy': 'options-wrapper',
})`
  margin-top: ${Dimensions.units(1)}px;
  max-width: 300px;
`;

const OptionsSelectSwitch = (props: Props) =>
  !props.options || props.options.length === 0 ? null : (
    <OptionsWrapper>
      <OptionsSelect {...props} />
    </OptionsWrapper>
  );

export default OptionsSelectSwitch;
