import Category from 'model/Category';
import { crowdDataCenter } from 'data/products/crowd';
import access from 'data/products/access';
import guard from 'data/products/guard';

export default new Category('securityAndIdentity', crowdDataCenter, access);

export const securityAndIdentityWithGuard = new Category(
  'securityAndIdentity',
  crowdDataCenter,
  guard,
);
