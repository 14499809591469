import React from 'react';
import { FormSection as AkFormSection } from '@atlaskit/form';
import ContactInputs from 'modules/checkout/contacts/ContactInputs';
import * as styles from 'modules/checkout/contacts/styles';
import { Props } from 'modules/checkout/contacts/CheckoutContactPage';
import { Checkbox, Field } from 'modules/form';
import t from 'modules/i18n/intl';

export default ({ isExpert, lockPurchaser, inSiteConfiguratorMode }: Partial<Props>) => (
  <styles.Section>
    <AkFormSection title={t('checkout.contact.your-contact-details')}>
      <Field
        isDisabled={inSiteConfiguratorMode && !isExpert}
        name="resellerOrder" // Forcing element into a string props. A hack because if we pass a string label,
        //   for some reason Atlaskit <Field> and <Checkbox> will both render it,
        //    i.e. it'll appear twice.
        // But it's fine with an element (Field doesn't render it). Hence a dummy span here.
      >
        <Checkbox
          name="reseller"
          label={
            isExpert ? (
              <span>{t('checkout.contact.message.partner')}</span>
            ) : (
              <span>{t('checkout.contact.message.reseller')}</span>
            )
          }
          value="reseller"
        />
      </Field>
      <ContactInputs
        name="purchaserContactDetails"
        lockEmail={lockPurchaser || inSiteConfiguratorMode}
      />
    </AkFormSection>
  </styles.Section>
);
