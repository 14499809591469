import padresClientFactory from 'util/getPadresClient';
import Progress, { thunkProgress } from 'redux-progress';

import { Dispatch, GetState, State } from 'model/State';
import { ApiKeysDictionary } from 'data/products/keys';

export type ParentsMap = {
  [product: string]: Progress<string[]>;
};

export const PARENTS_FETCH = 'catalog/parents/FETCH';

export const selectProductParents = (
  state: State,
  props: { productKey: string },
): Progress<string[]> => state.catalog.parents[props.productKey] || Progress.none;

export const selectProductsWithParentInfo = (state: State): string[] =>
  Object.keys(state.catalog.parents).filter(
    (key) =>
      state.catalog.parents[key].success === true || state.catalog.parents[key].inProgress === true,
  );

export const fetchParents = (...keys: string[]) => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const storeKeys = selectProductsWithParentInfo(state);

  const keysToBeFetched = keys.filter((key) => !storeKeys.includes(key));
  if (keysToBeFetched.length > 0) {
    const toApiKey = (key) => ApiKeysDictionary[key] || key;
    const hamsPromise = padresClientFactory().get('/relationships', {
      params: { product: keysToBeFetched.map(toApiKey) },
    });

    const extractProduct = (productKey: string) => (result) => {
      const rels = (result.data.relationships || []).find(
        (relationship) => relationship.product === toApiKey(productKey),
      );
      return rels ? rels.parents || [] : [];
    };

    return Promise.all(
      keysToBeFetched.map((productKey) =>
        dispatch(
          thunkProgress(PARENTS_FETCH, hamsPromise.then(extractProduct(productKey)), {
            payload: { productKey },
          }),
        ),
      ),
    );
  }

  return Promise.resolve();
};

const reducer = (state: ParentsMap = {}, action: any) => {
  switch (action.type) {
    case PARENTS_FETCH:
      return {
        ...state,
        [action.payload.productKey]: action.progress,
      };
    default:
      return state;
  }
};

export default reducer;
