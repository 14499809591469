import { redirect } from 'redux-first-router';
import { route } from 'modules/addon/duck/actions';
import { selectProductKeys, getAllProductKeys } from 'modules/addon/duck/selectors/productKeys';
import { fetchProductGroup, selectProductGroup, Group } from 'modules/catalog/groups';
import { CartThunk } from 'model/State';
import watchProgress from 'model/watchProgress';

import { fetchAccountOverview } from 'modules/accounts/actions';
import { fetchPricing } from 'modules/catalog/pricing';
import { fetchParents } from 'modules/catalog/parents';
import { OrderedDeploymentOptions } from 'model/hams/DeploymentOption';

export const load: CartThunk = async (dispatch, getState) => {
  const state = getState();
  const pricingKeys = getAllProductKeys(state);
  const addonKeys = selectProductKeys(state);

  await Promise.all([
    dispatch(fetchPricing(...pricingKeys)),
    dispatch(fetchParents(...addonKeys)),
    dispatch(fetchAccountOverview()),
    watchProgress((s) => s.cart.payload),
    addonKeys.length === 1 && dispatch(fetchProductGroup(addonKeys[0])),
  ]);

  if (addonKeys.length === 1) {
    const productGroup: Group = selectProductGroup(getState(), {
      productKey: addonKeys[0],
    }).unwrap();
    const orderedProductKeys: (string | null)[] = OrderedDeploymentOptions.map(
      (option) => productGroup[option],
    );
    const isDeploymentOptionAvailable = orderedProductKeys.includes(addonKeys[0]);
    if (!isDeploymentOptionAvailable) {
      const availableKey = orderedProductKeys.find(Boolean);
      if (availableKey) {
        dispatch(redirect(route(availableKey)));
      }
    }
  }
};
