// @flow
import Progress from 'redux-progress';
import * as actions from 'modules/marketplace/search/duck/actions';
import type { MarketplaceAddon } from 'modules/catalog/addons';

export default (state: Progress<MarketplaceAddon[]> = Progress.none, action: *) => {
  switch (action.type) {
    case actions.FETCH_SEARCH:
      return action.progress;
    case actions.FETCH_MORE_SEARCH:
      return state.map((e) => e.concat(action.progress.result || []));
    default:
      return state;
  }
};
