// @flow
import merge from 'lodash/merge';
import { envTypes } from '@atlassiansox/metal-client';
import type { EnvironmentConfig } from 'model/EnvironmentConfig';
import staging from 'appconfig/envs/staging';

export default (): EnvironmentConfig =>
  merge(staging(), {
    clicktaleEnabled: false,
    atlAdminUrl: 'http://localhost:3001',
    hamsUrl: '/hams',
    launchdarklyId: '5760d1160b163a072400013c',
    logoutUrl: () => '#logout',
    i18nUrl: 'https://cart-static.ap-southeast-2.dev.public.atl-paas.net/i18n',
    sentry: {
      environment: 'local',
    },
    metal: {
      environment: envTypes.LOCAL,
    },
    root: 'http://localhost:3000/purchase',
    adminHubUrl: 'https://admin.stg.atlassian.com',
    statsigClientSDK: 'aed6e833-9555-48d3-8812-a54fbb2db5dc',
    emailMAC: (userEmail) =>
      `https://id.stg.internal.atlassian.com/login?application=mac&continue=https://id.stg.internal.atlassian.com/manage-profile&prompt=none&login_hint=${userEmail}`,
    billingDetailsMAC: 'http://localhost:3000/purchase/my/edit-details',
    switchUserMAC: 'http://localhost:3000/purchase/my/config',
    emailPreferencesMAC: 'https://my.stg.internal.atlassian.com/email', // TODO: this will be update with this ticket: https://hello.jira.atlassian.cloud/browse/CHT-2683
    switchAccountMAC:
      'https://id.stg.internal.atlassian.com/login?application=mac&continue=https://wac.stg.internal.atlassian.com/purchase/my&prompt=select_account',
    logOutMAC:
      'https://id.stg.internal.atlassian.com/logout?application=mac&continue=https://wac.stg.internal.atlassian.com/purchase/my',
    calculatePricingURL:
      'https://wac.stg.internal.atlassian.com/migration/assess/calculate-pricing',
  });
