// @flow
import React from 'react';
import SuccessIcon from '@atlaskit/icon/glyph/editor/success';
import { colors } from '@atlaskit/theme';
import Button from '@atlaskit/button/standard-button';
import styled from 'styled-components';
import { Section } from 'modules/checkout/review/details/Commons';
import PaypalLogoUrl from 'components/common/img/logo-paypal.svg';
import { Dimensions, Font } from 'components/visuals';
import * as payment from 'modules/checkout/payment';

const padding = `${Dimensions.units(2)}px`;
const Wrapper = styled(Section)`
  border: 1px solid ${colors.N50};
  width: ${Dimensions.units(50)}px;
  border-radius: 5px;
  padding: ${padding};
  background: url(${PaypalLogoUrl}) no-repeat right ${padding} top ${padding};
  background-size: 130px;
`;

export const Header = styled.h4`
  font-family: ${Font.displayFamily};
  font-weight: 500;
  color: ${colors.N700};
  margin-bottom: ${Dimensions.units(1)}px;
`;

// Atlaskit's link button is misaligned by default. Realigning it.
const RealignedButton = styled(Button)`
  margin-left: -12px;
  margin-top: ${Dimensions.units(2)}px;
`;

type Props = {
  onRemovePayment: () => void,
};
const PaymentPaypalDetails = (props: Props) => {
  const paypal = payment.api.storage.paypalDetails.load();
  return (
    paypal && (
      <Wrapper>
        <Header>
          Paypal account <SuccessIcon size="medium" primaryColor={colors.G400} />
        </Header>
        <div>{paypal.details.email}</div>
        <RealignedButton
          appearance="link"
          onClick={(e) => {
            e.preventDefault();
            props.onRemovePayment();
          }}
        >
          Remove this account
        </RealignedButton>
      </Wrapper>
    )
  );
};

export default PaymentPaypalDetails;
