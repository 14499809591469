// @flow
import type { LicenseType } from 'model/hams/LicenseType';

type Props = {
  licenseType: LicenseType,
};

const labels = {
  COMMERCIAL: 'Commercial License',
  ACADEMIC: 'Academic License',
  COMMUNITY: 'Community License',
  OPEN_SOURCE: 'Open Source License',
  STARTER: 'Starter License',
  EVALUATION: 'Evaluation License',
};

export default (props: Props) => labels[props.licenseType] || '';
