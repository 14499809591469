import React from 'react';
import Category from 'components/products/Category';
import { getProductCategory } from 'data/categories';
import { FeatureFlags, checkFeatureGate } from 'util/statsig/index';

export default () => {
  const isAccessRebrandingFFOn = checkFeatureGate(
    FeatureFlags.CART_RENAME_ATLASSIAN_ACCESS_TO_GUARD,
  );

  const cartProductCategory = getProductCategory(isAccessRebrandingFFOn);

  return cartProductCategory.map((category) => <Category key={category.name} {...category} />);
};
