import Button from '@atlaskit/button/standard-button';
import ModalDialog, {
  ModalTransition,
  ModalBody,
  ModalTitle,
  ModalHeader,
  ModalFooter,
} from '@atlaskit/modal-dialog';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import { Font } from 'components/visuals';
import t from 'modules/i18n/intl';
import config from 'appconfig';
import { AccountEntry } from 'modules/change/table/duck/model';
import { connect } from 'react-redux';
import { Dispatch } from 'model/State';
import { hideJiraCoreModal } from 'modules/upgrade/duck/datacenter/actions/jiraCoreModal';

interface BaseDialogProps {
  showModal: boolean;
  close: () => void;
}

interface UpgradeModalProps {
  showModal: boolean;
  account: AccountEntry;
  dispatch: Dispatch;
}

const jiraSoftwareDataCenter = 'Jira Software Data Center';

const dialogHeading = () => (
  <span>
    <FormattedMessage id="upgrade.jira.core.modal.title" />
  </span>
);

const DialogMessage = () => (
  <FormattedMessage
    id="upgrade.jira.core.modal.text.body"
    values={{ product: <b>{jiraSoftwareDataCenter}</b> }}
  />
);

const actions = (props) => [
  {
    onClick: props.close,
    text: t('eol.modal.dialog.button'),
    className: props.className,
  },
];

const Dialog = (props: BaseDialogProps) => (
  <ModalDialog autoFocus={false} onClose={props.close} shouldCloseOnEscapePress width="medium">
    <ModalHeader>
      <ModalTitle>{dialogHeading()}</ModalTitle>
    </ModalHeader>

    <ModalBody>
      <p>
        <DialogMessage />
      </p>
      <p>
        <a href={`${config.wacUrl}/software/jira`}>{t('upgrade.jira.core.modal.message.link')}</a>
      </p>
    </ModalBody>
    <ModalFooter>
      {actions(props).map((footerProps, index) => (
        <Button
          {...props}
          onClick={footerProps.onClick}
          appearance={index === 0 ? 'primary' : 'subtle'}
        >
          {footerProps.text}
        </Button>
      ))}
    </ModalFooter>
  </ModalDialog>
);

const StyledDialog = styled(Dialog)`
  font-family: ${Font.family};
`;

const UpgradeJiraCoreModalDialog = (props: UpgradeModalProps) => (
  <ModalTransition>
    {props.showModal && (
      <StyledDialog close={() => props.dispatch(hideJiraCoreModal())} {...props} />
    )}
  </ModalTransition>
);

export default connect((state: any) => ({
  showModal: state.upgrade.datacenter.showUpgradeJiraCoreModal,
}))(UpgradeJiraCoreModalDialog);
