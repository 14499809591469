/* eslint-disable */
// @flow
import React from 'react';
import styled from 'styled-components';
import { Dimensions } from 'components/visuals';
import { FormattedMessage } from 'react-intl';
import t from 'modules/i18n/intl';

import { colors } from '@atlaskit/theme';

import config from 'appconfig';

const NoOptionsHeader = styled.div`
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: ${Dimensions.units(1)}px;
`;

const NoOptionsBody = styled.div`
  font-size: 12px;
  color: ${colors.N200};
`;

export const CannotBeRenewed = () => (
  <React.Fragment>
    <NoOptionsHeader>{t('renew.no-options.title')}</NoOptionsHeader>
    <NoOptionsBody>
      <FormattedMessage
        id="renew.no-options.body"
        values={{
          contactUs: (
            <a href={`${config.wacUrl}/company/contact`} target="_blank">
              <FormattedMessage id="common.contact-us" />
            </a>
          ),
        }}
      />
    </NoOptionsBody>
  </React.Fragment>
);
