/* eslint-disable import/prefer-default-export */

import Progress from 'redux-progress';
import { createSelector } from 'reselect';
import uniqBy from 'lodash/uniqBy';

import { Account } from 'modules/accounts/model';
import { Selector, State } from 'model/State';

export const selectAccounts: Selector<Progress<Account[]>> = createSelector(
  (state: State) => state.change.table.list,
  (state: State) => state.change.search.foundAccounts,
  (list, searchResults) => {
    const accounts = [...searchResults];
    list.ifSuccess((overview) => accounts.push(...overview.accounts));

    // If either the search or the normal request found any accounts we want to display those
    if (accounts.length > 0) {
      return Progress.success(uniqBy(accounts, 'accountId'));
    }

    // If there are no accounts to display we'll go ahead with the normal fetch result
    return list.map((overview) => overview.accounts);
  },
);

export const selectFoundAccounts: Selector<Progress<Account[]>> = createSelector(
  (state: State) => state.change.search.foundAccounts,
  (searchResults) => {
    const accounts = [...searchResults];

    // If either the search or the normal request found any accounts we want to display those
    if (accounts.length > 0) {
      return Progress.success(uniqBy(accounts, 'accountId'));
    }

    // If there are no accounts to display we'll go ahead with the normal fetch result
    return Progress.success([]);
  },
);

export const selectAllAccounts: Selector<Progress<Account[]>> = createSelector(
  (state: State) => state.change.table.list,
  (state: State) => state.change.table.paginated,
  (state: State) => state.change.search.foundAccounts,
  (list, paginated, searchResults) => {
    const accounts = [...searchResults];

    Object.values(paginated || []).forEach((overview) => accounts.push(...overview.accounts));

    // If either the search or the normal request found any accounts we want to display those
    if (accounts.length > 0) {
      return Progress.success(uniqBy(accounts, 'accountId'));
    }

    // If there are no accounts to display we'll go ahead with the normal fetch result
    return list.map((overview) => overview.accounts);
  },
);
