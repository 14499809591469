export enum TrackAction {
  Viewed = 'viewed',
  Changed = 'changed',
  Added = 'added',
  Identified = 'identified',
  Mismatched = 'mismatched',
  Invalidated = 'invalidated',
  Required = 'required',
  Fetched = 'fetched',
  Submitted = 'submitted',
  Failed = 'failed',
  Recieved = 'recieved',
}

export enum TrackActionSubject {
  Currency = 'currency',
  ProductList = 'productList',
  CheckoutComplete = 'checkoutComplete',
  BillingContact = 'billingContact',
  TechnicalContact = 'technicalContact',
  Reseller = 'reseller',
  Partner = 'partner',
  Country = 'country',
  PurchaserCountry = 'purchaserCountry',
  Login = 'login',
  TechnicalContactCountry = 'technicalContactCountry',
  ContactDetails = 'contactDetails',
  TrialToAnnualPathCartConfigSubmitSuccess = 'trialToAnnualPathCartConfigSubmitSuccess',
  TrialToAnnualPathCartConfigSubmitFailed = 'trialToAnnualPathCartConfigSubmitFailed',
  CountryChangedContactForm = 'countryChangedContactForm',
  CardPayment = 'cardPayment',
}

export enum TrackSource {
  ProductListScreen = 'productListScreen',
  CheckoutCompleteScreen = 'checkoutCompleteScreen',
  ContactDetailsScreen = 'contactDetailsScreen',
  Identity = 'identity',
  CartConfig = 'cartConfig',
  ReviewPageVisual = 'reviewPageVisual',
}

export enum TrackEventNames {
  ViewedProductList = 'viewedProductList',
  ViewedCheckoutComplete = 'viewedCheckoutComplete',
  RequiredToLogin = 'requiredToLogin',
  ChangeCurrency = 'changeCurrency',
  AddedBillingContact = 'addedBillingContact',
  AddedTechnicalContact = 'addedTechnicalContact',
  IdentifiedAsReseller = 'identifiedAsReseller',
  PartnerPlacingOrderForEndCustomer = 'partnerPlacingOrderForEndCustomer',
  CartCountryMismatchError = 'cartCountryMismatchError',
  CountryPurchaserValidationError = 'countryPurchaserValidationError',
  CountryDifferentTechnicalValidationError = 'countryDifferentTechnicalValidationError',
  ContactDetailsFetched = 'contactDetailsFetched',
  CartConfigSubmit = 'cartConfigSubmit',
  ContactDetailsUpdated = 'ContactDetailsUpdated',
  InitiateConfirmCardPayment = 'initiateConfirmCardPayment',
}

export type TrackFields = Readonly<{
  action: TrackAction;
  actionSubject: TrackActionSubject;
  actionSubjectId?: string;
  attributes: {
    eventName: TrackEventNames;
    [s: string]: any;
  };
  tags?: string[];
}>;

export type TrackContext = Readonly<{
  source: TrackSource;
  objectType?: string;
  objectId?: string;
  containerType?: string;
  containerId?: string;
}>;

export interface CartTrackEvent extends TrackFields, TrackContext {}
