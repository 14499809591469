/* eslint-disable consistent-return */
// @flow
import { redirect } from 'redux-first-router';
import type { Dispatch, GetState } from 'model/State';
import watchProgress from 'model/watchProgress';
import * as payment from 'modules/checkout/payment/duck/actions/route';
import * as contacts from 'modules/checkout/contacts';
import * as additionalDetails from 'modules/checkout/additionalDetails';
import { goToProductList } from 'actions';
import { selectPurchaseMode, setPurchaseMode } from 'modules/settings/purchaseMode';
import { fetchCanCoTermAction } from 'modules/checkout/quote/duck/actions/fetchCanCoTerm';

export const ROUTE = 'checkout/quote/ROUTE';
export const route = () => ({ type: ROUTE });

const loadCartDetails = () => async (dispatch: Dispatch) => {
  const cart = await watchProgress((state) => state.cart.payload);
  if (!cart || cart.items.length === 0) {
    return dispatch(redirect(goToProductList()));
  }

  if (cart.totalIncTax === 0 || (cart.status === 'QUOTED' && !cart.inEditMode)) {
    return dispatch(setPurchaseMode('BUY'));
  }

  return dispatch(
    additionalDetails.edit({
      poNumber: (cart && cart.purchaseOrderNumber) || '',
      hasCoTermRequest: cart.hasCoTermRequest,
      hasTaxExemptRequest: cart.hasTaxExemptRequest,
    }),
  );
};

export const routeThunk = () => async (dispatch: Dispatch, getState: GetState) => {
  if (selectPurchaseMode(getState()) === 'BUY') {
    return dispatch(redirect(payment.route(getState().location.query)));
  }

  const { steps } = getState().checkout;
  if (steps.contact && !steps.contact.complete) {
    return dispatch(redirect(contacts.actions.route()));
  }

  await dispatch(fetchCanCoTermAction());

  return dispatch(loadCartDetails());
};
