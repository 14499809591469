import hamsClient from 'modules/hams';
import { HamsCart } from 'modules/cart/duck/model';
import { setInteractionError } from '@atlassian/ufo-set-interaction-error';

const removeItems = (cartId: string, cartItemId: number[]): Promise<HamsCart> =>
  hamsClient
    .post(`/1.0/public/order/cart/${cartId}/removeItems`, undefined, {
      params: { cartItemId },
    })
    .then((response) => response.data)
    .catch(() => {
      setInteractionError('items-delete', {
        errorMessage: 'items-delete error',
        name: 'items-delete error',
      });
      throw new Error('Error while deleting item');
    });

export default removeItems;
