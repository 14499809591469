import React, { useState } from 'react';
import Progress from 'redux-progress';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';

import { HamsCart } from 'modules/cart/duck/model';
import { Dispatch, State } from 'model/State';
import { selectCart } from 'modules/cart/duck/selectors';

import { Dimensions } from 'components/visuals';
import PageLoading from 'components/common/PageLoading';
import {
  PurchaseMode,
  togglePurchaseMode,
  selectPurchaseMode,
} from 'modules/settings/purchaseMode';
import * as checkout from 'modules/checkout';
import * as steps from 'modules/checkout/steps';
import { goToProductList } from 'actions';
import AnnualBillingPeriod from './components/AnnualBillingPeriod';
import AnnualBillingConfigurator from './AnnualBillingConfig';
import { changeBillingPeriod, setAnalyticsTrigger, updateCart } from './duck/actions';
import {
  selectChosenBillingPeriod,
  selectOriginalCartItemDetails,
  selectInSiteConfiguratorMode,
  selectAnalyticsTrigger,
  selectAnnualBillingCart,
  selectBiennialBillingCart,
  selectCartFormValidationError,
  hasLoginFormValidationError,
} from './duck/selectors';
import { OriginalItemDetails } from './duck/model';
import { UpdateCartItemParams } from './utils';
import { CartAnalyticsWrapper } from 'modules/analytics';
import {
  CartContactDetailsError,
  CartPartnerLoginErrorMsg,
} from './components/CartValidationErrors';

import FormValidationError from 'model/hams/FormValidationError';

type StateProps = {
  hasLoginFormValidationError: boolean;
  cartProgress: Progress<HamsCart | null>;
  purchaseMode: PurchaseMode;
  selectedBillingPeriod: number;
  selectedAnalyticsTrigger: string;
  originalCartItemDetails: OriginalItemDetails;
  inSiteConfiguratorMode: boolean;
  selectedAnnualBillingCart: Progress<HamsCart | null>;
  selectedBiennialBillingCart: Progress<HamsCart | null>;
  cartFormValidationError: Progress<FormValidationError | null>;
};

type DispatchProps = {
  onStartShopping: () => void;
  onSubmit: () => void;
  onTogglePurchaseMode: () => void;
  onChangeBillingPeriod: (billingPeriod: number) => void;
  setAnalyticsTrigger: (trigger: string) => void;
  onUpdateCartItem: ({
    accountId,
    sku,
    unitCount,
    setIsPageLoading,
    setAnnualBillingCartError,
    setBiennialBillingCartError,
  }: UpdateCartItemParams) => void;
};
type Props = StateProps & DispatchProps;

export const Header = styled.h3`
  font-size: ${Dimensions.units(3)}px;
  font-weight: 500;
  line-height: ${Dimensions.units(4)}px;
  letter-spacing: 0.3px;
  margin-bottom: ${Dimensions.units(2)}px;
  flex-grow: 1;
  border-bottom: 1px solid ${colors.N800};
`;

const SiteConfigurator = (props: Props) => {
  const [isPageLoading, setIsPageLoading] = useState<Progress<boolean>>(Progress.success(false));
  const [annualBillingCartError, setAnnualBillingCartError] = useState<boolean>(false);
  const [biennialBillingCartError, setBiennialBillingCartError] = useState<boolean>(false);

  return (
    <CartAnalyticsWrapper>
      <PageLoading
        progress={Progress.all(props.cartProgress, props.cartFormValidationError, isPageLoading)}
      >
        {([cart, cartFormValidationError]) => {
          const ErrorMessage = () =>
            props.hasLoginFormValidationError ? (
              <CartPartnerLoginErrorMsg />
            ) : (
              <CartContactDetailsError cart={cart} />
            );

          return cartFormValidationError ? (
            <ErrorMessage />
          ) : (
            <>
              <AnnualBillingPeriod
                {...props}
                annualBillingCartError={annualBillingCartError}
                biennialBillingCartError={biennialBillingCartError}
              />
              <AnnualBillingConfigurator
                {...props}
                cart={cart}
                setIsPageLoading={setIsPageLoading}
                setAnnualBillingCartError={setAnnualBillingCartError}
                setBiennialBillingCartError={setBiennialBillingCartError}
              />
            </>
          );
        }}
      </PageLoading>
    </CartAnalyticsWrapper>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  cartProgress: selectCart(state),
  purchaseMode: selectPurchaseMode(state),
  selectedBillingPeriod: selectChosenBillingPeriod(state),
  originalCartItemDetails: selectOriginalCartItemDetails(state),
  inSiteConfiguratorMode: selectInSiteConfiguratorMode(state),
  selectedAnalyticsTrigger: selectAnalyticsTrigger(state),
  selectedAnnualBillingCart: selectAnnualBillingCart(state),
  selectedBiennialBillingCart: selectBiennialBillingCart(state),
  cartFormValidationError: selectCartFormValidationError(state),
  hasLoginFormValidationError: hasLoginFormValidationError(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSubmit: () => {
    dispatch(steps.resetSteps());
    dispatch(checkout.actions.goNext());
  },
  onTogglePurchaseMode: () => dispatch(togglePurchaseMode()),
  onStartShopping: () => dispatch(goToProductList()),
  onChangeBillingPeriod: (billingPeriod: number) => dispatch(changeBillingPeriod(billingPeriod)),
  setAnalyticsTrigger: (trigger: string) => dispatch(setAnalyticsTrigger(trigger)),
  onUpdateCartItem: ({
    accountId,
    sku,
    unitCount,
    setIsPageLoading,
    setAnnualBillingCartError,
    setBiennialBillingCartError,
  }: UpdateCartItemParams) => {
    dispatch(
      updateCart({
        accountId,
        sku,
        unitCount,
        setIsPageLoading,
        setAnnualBillingCartError,
        setBiennialBillingCartError,
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteConfigurator);
