// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import Progress from 'redux-progress';
import { StepHeader, StepWrapper } from 'modules/configure/common/Steps';
import ChooseDeployment from 'modules/configure/deployment/ChooseDeployment';
import t from 'modules/i18n/intl';
import type { Group } from 'modules/catalog/groups';
import type { Dispatch, State } from 'model/State';
import Loaded from 'components/common/Loaded';

import { actions, selectors } from '..';

type OwnProps = {
  alwaysShowCloud?: boolean,
};

type StateProps = {
  options: Progress<Group>,
  selected: string | null,
  note: React.Node | null,
};

type DispatchProps = {
  onChange: (string) => void,
};

export type Props = OwnProps & StateProps & DispatchProps;

const DeploymentStep = (props: Props) => (
  <StepWrapper>
    <StepHeader>{t('purchase.choose-deployment-step')}</StepHeader>
    <Loaded
      progress={props.options}
      content={(options) => (
        <ChooseDeployment {...props} options={options} action={actions.goProductConfig} />
      )}
    />
  </StepWrapper>
);

DeploymentStep.defaultProps = {
  alwaysShowCloud: false,
};

const mapStateToProps = (state: State): StateProps => ({
  options: selectors.selectDeploymentOptions(state),
  selected: selectors.selectMainProductKey(state),
  note: selectors.selectDeploymentNote(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onChange: (key) => dispatch(actions.goProductConfig(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentStep);
