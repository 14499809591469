// @flow
import merge from 'lodash/merge';
import { envTypes } from '@atlassiansox/metal-client';
import type { EnvironmentConfig } from 'model/EnvironmentConfig';
import staging from 'appconfig/envs/staging';

const devEnvHostname = window.location.hostname.replace(/^cart-/, '');

export default (): EnvironmentConfig =>
  merge(staging(), {
    clicktaleEnabled: false,
    hamsUrl: `https://hamlet-${devEnvHostname}`,
    launchdarklyId: '5760d1160b163a072400013c',
    macUrl: `https://my-${devEnvHostname}`,
    sentry: {
      environment: `branch-${devEnvHostname}`,
    },
    i18nUrl: 'https://cart-static.ap-southeast-2.dev.public.atl-paas.net/i18n',
    metal: {
      environment: envTypes.DEV,
    },
    root: 'http://localhost:3000/purchase',
  });
