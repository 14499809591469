import { setInteractionError } from '@atlassian/ufo-set-interaction-error';

export const RESTRICTED_EMAIL_DOMAINS = ['ru', 'by', 'рф', 'рус', 'бел'];
export const RESTRICTED_COUNTRIES_ISO_CODES = ['RU', 'BY'];

export const setInteractionErrorUpgradeSubmitChangelist = () => {
  setInteractionError('upgrade-submit-changelist', {
    errorMessage: 'upgrade DC add items error',
    name: 'upgrade DC add items error',
  });
};
