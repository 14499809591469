import { addItems } from './addItems';

export const ROUTE: 'addtocart/ROUTE' = 'addtocart/ROUTE';

export type RouteAction = {
  readonly type: typeof ROUTE;
};

export function route(): RouteAction {
  return {
    type: ROUTE,
  };
}

export const routeThunk = () => addItems();
