import React, { useMemo } from 'react';

import MenuItemDropdown, { MenuItemLink } from './MenuItem';
import MenuList from './MenuList';

import config from 'appconfig';
import { connect } from 'react-redux';
import { State } from 'model/State';
import { LocationState } from 'redux-first-router';
import { RoutesMap } from 'routes';

const topMenuItemsSC = [
  {
    Component: MenuItemDropdown,
    menuKey: 'products',
    text: 'navigation.header.products',
  },
  {
    Component: MenuItemDropdown,
    menuKey: 'teams',
    text: 'navigation.header.teams',
  },
  {
    Component: MenuItemDropdown,
    menuKey: 'support',
    text: 'navigation.header.support',
  },
  {
    Component: MenuItemLink,
    href: `${config.wacUrl}/try`,
    text: 'navigation.header.try',
  },
  {
    Component: MenuItemLink,
    href: `${config.root}`,
    text: 'navigation.header.buy',
  },
];

const topMenuItemsMAC = [
  {
    Component: MenuItemLink,
    href: `${config.root}/my`,
    text: 'navigation.header.licenses',
  },
  {
    Component: MenuItemLink,
    href: `${config.wacUrl}/download`,
    text: 'navigation.header.downloads',
  },
  {
    Component: MenuItemLink,
    href: `${config.root}/my/orders`,
    text: 'navigation.header.orders',
  },
  {
    Component: MenuItemLink,
    href: `${config.root}`,
    text: 'navigation.header.buy',
  },
];

type TopMenuItemType = {
  Component: React.FC<any>;
  href?: string;
  text: string;
};

type PropTypes = {
  cartMACFunctionalityMigration: boolean;
  isMACPage: boolean;
  location: LocationState<RoutesMap>;
};

const TopMenu: React.FC<PropTypes> = React.memo(
  ({ isMACPage, cartMACFunctionalityMigration, location }) => {
    const topMenuItems: TopMenuItemType[] = useMemo(
      () => (cartMACFunctionalityMigration && isMACPage ? topMenuItemsMAC : topMenuItemsSC),
      [cartMACFunctionalityMigration, isMACPage],
    );

    const isActive = (pathname: string | undefined) => {
      if (!pathname) return false;

      const url = new URL(pathname);
      const route = url.pathname.replace('/purchase', '');

      return route === location.pathname;
    };

    return (
      <MenuList isMACPage={isMACPage}>
        {topMenuItems.map((item) => {
          const { Component, ...props } = item;

          return (
            <Component key={props.text} active={isMACPage && isActive(props.href)} {...props} />
          );
        })}
      </MenuList>
    );
  },
);

export default connect(
  (state: State) => ({
    location: state.location,
  }),
  null,
)(TopMenu);
