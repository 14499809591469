// @flow
import connectStateAndDispatch from 'util/react-redux/connectStateAndDispatch';
import React, { useContext } from 'react';
import styled from 'styled-components';
import PageLoading from 'components/common/PageLoading';
import PriceQuoteMessage from 'modules/checkout/quote/PriceQuoteMessage';
import AdditionalDetails from 'modules/checkout/quote/AdditionalDetails';
import QuoteFooter from 'modules/checkout/quote/QuoteFooter';
import { selectors } from 'modules/checkout/quote';
import type { QuotePageProps } from 'modules/checkout/quote';
import { Font, Dimensions } from 'components/visuals';
import type { Dispatch, State } from 'model/State';
import GenericErrorMessage from 'components/common/GenericErrorMessage';

import { selectInSiteConfiguratorMode } from 'modules/siteconfigurator/duck/selectors';

import { CartAnalyticsWrapper, CartAnalyticsContext } from 'modules/analytics';
import { analyticsEvents, AnalyticsTrigger } from 'modules/siteconfigurator/analytics';

const Form = styled.form`
  font-family: ${Font.family};
  width: ${Dimensions.pageWidth};
  margin: 36px auto 48px;
`;

const Header = styled.h2`
  font-family: ${Font.displayFamily};
`;

const Link = styled.a`
  cursor: pointer;
`;

export const VisualQuotePage = (props: QuotePageProps) => {
  const analytics = useContext(CartAnalyticsContext);

  return (
    props.submission.fold({
      failed: (e) => <GenericErrorMessage error={e} />,
    }) || (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (props.inSiteConfiguratorMode) {
            analyticsEvents[AnalyticsTrigger.SaveQuoteContinue](analytics);
          }
          props.onSubmit();
        }}
      >
        <Header>Save a quote</Header>
        <p>
          You can save your cart as a price quote to pay later. &nbsp; If you want to pay now you
          can &nbsp;
          <Link onClick={props.onGoToPayment}>choose a payment option</Link>
          &nbsp; and complete your order.
        </p>
        <PriceQuoteMessage />
        <AdditionalDetails {...props} />
        <QuoteFooter {...props} />
      </Form>
    )
  );
};

const mapToProps = (state: State, dispatch: Dispatch) => ({
  progress: selectors.getPageProps(state, dispatch),
  inSiteConfiguratorMode: selectInSiteConfiguratorMode(state),
});

export default connectStateAndDispatch(mapToProps)(({ progress, ...props }) => (
  <PageLoading progress={progress}>
    {(result) =>
      result && (
        <CartAnalyticsWrapper>
          <VisualQuotePage {...result} {...props} />
        </CartAnalyticsWrapper>
      )
    }
  </PageLoading>
));
