import React from 'react';
import styled from 'styled-components';

import { Field } from '@atlaskit/form';
import Warning from '@atlaskit/icon/glyph/warning';
import Textfield from '@atlaskit/textfield';
import LoadingButton from '@atlaskit/button/loading-button';
import Tooltip from 'components/common/Tooltip';

import t, { Intl } from 'modules/i18n/intl';
import { Dimensions } from 'components/visuals';
import { colors } from '@atlaskit/theme';

const Wrapper = styled.div`
  padding-bottom: ${Dimensions.units(2)}px;
`;

const ComponentWrapper = styled.div`
  display: inline-block;
  margin-right: ${Dimensions.units(3)}px;
`;

const FieldWrapper = styled(ComponentWrapper)`
  width: ${Dimensions.units(26)}px;
`;

const StyledField = styled(Field)`
  margin-top: ${Dimensions.units(2.5)}px;
`;

const IconWrapper = styled.div`
  display: inline-block;
  margin-right: ${Dimensions.units(1)}px;
`;

const ButtonWrapper = styled(ComponentWrapper)`
  margin-right: 0;

  button {
    margin-left: ${Dimensions.units(1)}px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

type Labels = {
  sen: string;
  email: string;
};

export type Props = {
  sen: string | null;
  email: string | null;
  onSenChange: (value: string) => void;
  onEmailChange: (value: string) => void;
  isSenValid: boolean;
  isEmailValid: boolean;
  onSearchSubmit: () => void;
  onClearSearch?: () => void;
  canClearSearch?: boolean;
  searchInProgress: boolean;
  submitTitle?: React.ReactNode;
  labels?: Labels;
  isRequired?: boolean;
};

const WarningIcon = ({ tooltipLabel }) => (
  <IconWrapper>
    <Tooltip direction="right" content={tooltipLabel} displayAsFlag>
      <Warning label="Warning icon" primaryColor={colors.Y300} />
    </Tooltip>
  </IconWrapper>
);

const SearchBySenForm = ({
  sen,
  email,
  onSenChange,
  onEmailChange,
  isSenValid,
  isEmailValid,
  onSearchSubmit,
  onClearSearch,
  canClearSearch,
  searchInProgress,
  submitTitle,
  labels,
  isRequired,
}: Props) => {
  const isButtonDisabled = () => !sen || !email || !isSenValid || !isEmailValid;

  return (
    <Wrapper>
      <FieldWrapper>
        <Intl>
          {() => (
            <StyledField label={labels?.sen} name="sen" isRequired={isRequired}>
              {({ fieldProps }: any) => (
                <>
                  <Textfield
                    id="sen"
                    {...fieldProps}
                    placeholder="SEN-XXXXXX"
                    value={sen || ''}
                    onChange={(e) => onSenChange(e.currentTarget.value)}
                    onKeyPress={(e) => e.key === 'Enter' && onSearchSubmit()}
                    isInvalid={!isSenValid}
                    isRequired={isRequired}
                    elemAfterInput={
                      !isSenValid ? (
                        <WarningIcon tooltipLabel={t('addon.search.invalid.sen')} />
                      ) : null
                    }
                  />
                </>
              )}
            </StyledField>
          )}
        </Intl>
      </FieldWrapper>
      <FieldWrapper>
        <Intl>
          {(translate) => (
            <StyledField label={labels?.email} name="email" isRequired={isRequired}>
              {({ fieldProps }: any) => (
                <>
                  <Textfield
                    {...fieldProps}
                    placeholder={translate('addon.search.email')}
                    type="email"
                    value={email || ''}
                    onChange={(e) => onEmailChange(e.currentTarget.value)}
                    onKeyPress={(e) => e.key === 'Enter' && onSearchSubmit()}
                    isInvalid={!isEmailValid}
                    isRequired={isRequired}
                    elemAfterInput={
                      !isEmailValid ? (
                        <WarningIcon tooltipLabel={t('addon.search.invalid.email')} />
                      ) : null
                    }
                  />
                </>
              )}
            </StyledField>
          )}
        </Intl>
      </FieldWrapper>
      <ButtonWrapper>
        <LoadingButton
          appearance="default"
          isDisabled={isButtonDisabled()}
          onClick={onSearchSubmit}
          isLoading={searchInProgress}
          testId="search-sen-submit"
        >
          {submitTitle || t('common.submit')}
        </LoadingButton>
        {canClearSearch && (
          <LoadingButton appearance="link" onClick={onClearSearch}>
            {t('change.search.clear.button')}
          </LoadingButton>
        )}
      </ButtonWrapper>
    </Wrapper>
  );
};

export default SearchBySenForm;
