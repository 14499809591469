import React from 'react';
import styled from 'styled-components';

import TableRow from 'modules/change/table/TableRow';
import { TableProps } from 'modules/change/table/duck/model';
import { Font } from 'components/visuals';

const StyledTableBody = styled.tbody`
  font-family: ${Font.family};
`;

const TableBody = (props: TableProps) => (
  <StyledTableBody>
    {props.entries.map((entry) => (
      <TableRow key={entry.accountId} entry={entry} {...props} />
    ))}
  </StyledTableBody>
);

export default TableBody;
