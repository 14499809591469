// @flow
import type { MapToProps } from 'util/react-redux/connectStateAndDispatch';
import Progress from 'redux-progress';

import { CheckoutModes, type PaypalProps } from 'modules/checkout/payment/duck/model';
import * as api from 'modules/checkout/payment/duck/api';
import * as actions from 'modules/checkout/payment/duck/actions';
import canAutoRenew from 'modules/checkout/payment/duck/selectors/getCanAutoRenew';
import getAutoRenewItems from 'modules/checkout/payment/duck/selectors/getAutoRenewItems';
import { selectCurrency } from 'modules/cart/duck/selectors';
import getAvailablePaymentMethods from './getAvailablePaymentMethods';

const getPaypalProps: MapToProps<PaypalProps> = (state, dispatch) =>
  Progress.all(getAvailablePaymentMethods(state), selectCurrency(state)).map(
    ([availablePaymentMethods, currency]) => ({
      ...state.checkout.payment.paypal,
      isAvailable: availablePaymentMethods.paypal,
      isSelected: state.checkout.payment.checkoutMode === CheckoutModes.PAYPAL,
      flow: state.checkout.payment.selectedAutoRenewItems.length > 0 ? 'vault' : 'checkout',
      createInstance: () => api.createPaypalCheckoutInstance(currency),
      onFail: (e) => dispatch(actions.paypal.failed(e)),
      authorize: (token) => dispatch(actions.paypal.authorize(token)),
      hasAutoRenewItems:
        getAutoRenewItems(state).ifSuccess((items) => items.length > 0) && canAutoRenew(state),
    }),
  );

export default getPaypalProps;
