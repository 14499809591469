/* eslint-disable function-paren-newline */
// @flow
import { CheckoutModes } from 'modules/checkout/payment/duck/model';
import validateCreditCard from 'modules/checkout/payment/duck/selectors/validateCreditCard';
import type { Selector } from 'model/State';
import { isCartAFreeOrder } from 'modules/cart/duck/selectors/getIsFreeOrder';

const getCanSubmit: Selector<boolean> = (state) =>
  !!state.cart.payload.ifSuccess((cart) => {
    if (cart && isCartAFreeOrder(cart)) {
      return true;
    }
    const { payment } = state.checkout;
    switch (payment.checkoutMode) {
      case CheckoutModes.STRIPE_CREDIT_CARD:
        return true;
      case CheckoutModes.CREDIT_CARD:
        return Boolean(
          validateCreditCard(state).isAllValid &&
            payment.creditCard.cardName &&
            payment.creditCard.expiryMonth &&
            payment.creditCard.expiryYear,
        );
      case CheckoutModes.PAY_ON_TERMS:
        return payment.payOnTermAgreed;
      default:
        return true;
    }
  });

export default getCanSubmit;
