import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import ChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import { LoadingButton } from '@atlaskit/button';
import UFOLoadHold from '@atlassian/ufo-load-hold';

import { Font } from 'components/visuals';

type Props = {
  'data-account-id': string;
  onClick: () => void;
  isLoading: boolean;
  isRenewUpgrade: boolean;
};

const Button = styled(LoadingButton)`
  font-family: ${Font.family};
  font-size: 16px;
  font-weight: 500;
  text-align: left;
`;

const ShowPricingButton = (props: Props) => (
  <>
    {props.isRenewUpgrade ? (
      <>
        <UFOLoadHold name="loading-options-button" hold={props.isLoading} />
        <Button
          interactionName="upgrade-changeoptions"
          {...props}
          iconAfter={<ChevronDownIcon label="" />}
          shouldFitContainer
          isLoading={props.isLoading}
        >
          <FormattedMessage id="change.show-pricing" />
        </Button>
      </>
    ) : (
      <Button
        {...props}
        iconAfter={<ChevronDownIcon label="" />}
        shouldFitContainer
        isLoading={props.isLoading}
      >
        <FormattedMessage id="change.show-pricing" />
      </Button>
    )}
  </>
);

export default ShowPricingButton;
