/* eslint-disable flowtype/no-types-missing-file-annotation */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@atlaskit/button/standard-button';
import ModalDialog, {
  ModalTransition,
  ModalBody,
  ModalTitle,
  ModalHeader,
  ModalFooter,
} from '@atlaskit/modal-dialog';
import styled from 'styled-components';
import { Font } from 'components/visuals';

interface ModalWindowProps {
  showDialog: boolean;
}

interface DialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  inProgress: boolean;
  className?: string;
}

type IProps = ModalWindowProps & DialogProps;

const Dialog = (props: DialogProps) => (
  <ModalDialog
    autoFocus
    onClose={props.onCancel}
    shouldCloseOnOverlayClick
    shouldCloseOnEscapePress
    width="small"
  >
    <ModalHeader>
      <ModalTitle appearance="warning">
        <span className={props.className}>
          <FormattedMessage id="cart.clear.cta" />
        </span>
      </ModalTitle>
    </ModalHeader>

    <ModalBody>
      <span className={props.className}>
        <FormattedMessage id="cart.clear.dialog.body" />
      </span>
    </ModalBody>
    <ModalFooter>
      <Button appearance="default" autoFocus onClick={props.onCancel}>
        Cancel
      </Button>
      <Button appearance="warning" onClick={props.onConfirm}>
        Confirm
      </Button>
    </ModalFooter>
  </ModalDialog>
);

const StyledDialog = styled(Dialog)`
  font-family: ${Font.family};
`;

const ClearCartModal = (props: IProps) => (
  <ModalTransition>
    {props.showDialog && (
      <StyledDialog
        onCancel={props.onCancel}
        inProgress={props.inProgress}
        onConfirm={props.onConfirm}
        className={props.className}
      />
    )}
  </ModalTransition>
);

export default ClearCartModal;
