import { State, Selector } from 'model/State';
import { isContactStepComplete } from 'modules/checkout/steps';
import { CheckoutEventData, UnknownTax, HamsCart } from 'modules/cart/duck/model';
import selectCurrency from 'modules/cart/duck/selectors/currency';

const getCheckoutEventData: Selector<CheckoutEventData> = (state: State) => {
  const cart = state.cart.payload.result as HamsCart;
  const isTaxUnknown = !isContactStepComplete(state).result && cart.totalTax === 0;

  const products = cart.items.map((item) => ({
    productName: item.productDetails.productDescription,
    productTier: item.productDetails.unitCount,
    productPrice: item.listPrice,
    productDiscount: item.discountAmount,
  }));

  return {
    products,
    credits: cart.adjustmentAmounts?.UPGRADE_CREDIT || 0,
    loyatyDiscount: cart.adjustmentAmounts?.LOYALTY_DISCOUNT || 0,
    PartnerDiscount: cart.adjustmentAmounts?.EXPERT || 0,
    total: cart.totalIncTax,
    subtotal: cart.totalProratedPrice,
    tax: isTaxUnknown ? UnknownTax : cart.totalTax,
    totalSaving: cart.savedAmount,
    currency: selectCurrency(state).result as string,
  };
};

export default getCheckoutEventData;
