import * as React from 'react';

import Blanket from '@atlaskit/blanket';
import Spinner from '@atlaskit/spinner';

// If user switches payment-method when TNS configure is taking place,
// the TNS iframe will result in weird behavior (i.e. 0px height).
// So we're preventing that with a blanket.
const LoadingBlanket = () => (
  <>
    <Blanket isTinted />
    <Spinner interactionName="loading-blanket-spinner" size="medium" />
  </>
);

export default LoadingBlanket;
