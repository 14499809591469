import * as React from 'react';
import { CheckoutModes } from 'modules/checkout/payment/duck/model/PaymentState';

import StripeTabContent from './StripeTabContent';
import TnsTabContent from './TnsTabContent';

const TabContentSwitcher = (props) => {
  switch (props.checkoutMode) {
    case CheckoutModes.STRIPE_CREDIT_CARD:
      return <StripeTabContent {...props} />;
    case CheckoutModes.CREDIT_CARD:
      return <TnsTabContent {...props} />;
    default:
      return null;
  }
};

export default TabContentSwitcher;
