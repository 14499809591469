import { combineReducers } from 'redux';
import data from './data';
import purchaserLoginValidation from './purchaserLoginValidation';
import submitProgress from './submitProgress';
import validateCountryContactMatch from './validateContactCountry';
import validateCartCurrencyCountryMatch from './validateCartCountry';
import showCountryCurrency from './showCountryCurrency';

export default combineReducers({
  data,
  purchaserLoginValidation,
  submitProgress,
  validateCountryContactMatch,
  validateCartCurrencyCountryMatch,
  showCountryCurrency,
});
