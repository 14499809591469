import { State } from 'model/State';
import { fetchWithParamValidation } from 'util/fetchWithParamValidation';
import { getFromHamsClient } from 'util/mac/api/order/get';
import { postToHamsClient } from 'util/mac/api/order/post';
import { getFromHamsClient as getFromRefund } from 'util/mac/api/refund/get';
import { postToHamsClient as postToOrdersContact } from 'util/mac/api/ordersContact/post';
import { DownloadablePDF, downloadFile } from 'util/mac/downloadFile';
import { selectSwitchedUser } from '../selectors';

export const ordersCountByEmail = (state: State) => {
  const email = selectSwitchedUser(state);

  return fetchWithParamValidation(
    ({ emailParam }) => getFromHamsClient(`count/${emailParam}`, state),
    {
      emailParam: email,
    },
  );
};

export const quotes = (maxResultsParam: number, offsetParam: number, state: State) => {
  const email = selectSwitchedUser(state);

  return fetchWithParamValidation(
    ({ maxResults, offset }) =>
      postToOrdersContact(
        'quotes',
        state,
        {
          maxResults,
          offset,
        },
        {
          email,
        },
      ),
    { maxResults: maxResultsParam, offset: offsetParam },
  );
};

export const searchForContact = (
  maxResultsParam: number,
  offsetParam: number,
  orderNumberMask: number,
  technicalContactCompanyDomainName: string,
  integerPartOfTotalAmount: number,
  state: State,
) => {
  const email = selectSwitchedUser(state);

  return fetchWithParamValidation(
    ({ maxResults, offset }) =>
      postToHamsClient(
        'searchForContact',
        state,
        {
          maxResults,
          offset,
        },
        {
          email,
          orderNumberMask,
          technicalContactCompanyDomainName,
          integerPartOfTotalAmount,
        },
      ),
    { maxResults: maxResultsParam, offset: offsetParam },
  );
};

export const latestRefund = (orderId: string, state: State) =>
  fetchWithParamValidation(({ id }) => getFromRefund(`latest/${id}`, state), { id: orderId });

export const refundPDF = async (refundId: string, state: State) =>
  fetchWithParamValidation(
    async ({ id }) => {
      const response: DownloadablePDF = await getFromRefund(`external/${id}/pdf`, state);

      downloadFile(response);
    },
    {
      id: refundId,
    },
  );

export const invoicePdf = async (orderId: string, state: State) =>
  fetchWithParamValidation(
    async ({ id }) => {
      const response: DownloadablePDF = await getFromRefund(`invoice/${id}/pdf`, state);

      downloadFile(response);
    },
    {
      id: orderId,
    },
  );
