import React from 'react';
import { Field } from 'modules/form';
import { Intl } from 'modules/i18n/intl';
import CountrySelect from 'modules/checkout/contacts/CountrySelect';

import { CountryProps } from './types';

export const Country: React.FC<CountryProps> = (props: CountryProps) => {
  const { enableTooltipOnCountrySelect, ...restProps } = props;

  return (
    <Intl>
      {(translate) => (
        <Field name="isoCountryCode" label={translate('checkout.contact.country')} isRequired>
          <CountrySelect enableCurrencyTooltip={enableTooltipOnCountrySelect} {...restProps} />
        </Field>
      )}
    </Intl>
  );
};
