import { ContactDetails, OrganisationDetails } from 'model/hams/contact';

const organisationTypes = {
  CUSTOMER: 'CUSTOMER',
  EXPERT: 'EXPERT',
  RESELLER: 'RESELLER',
};
export const { CUSTOMER, EXPERT, RESELLER } = organisationTypes;
export type OrganisationType = $Values<typeof organisationTypes> | null;
export type ExtendedOrganisationDetails = OrganisationDetails & {
  organisationType: OrganisationType;
  payOnAccount: boolean;
};
export type CurrentContact = {
  contactDetails: ContactDetails;
  organisationDetails: ExtendedOrganisationDetails;
};
