import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';

import { TopMenuMAC } from 'components/navigation/top/TopMenuMAC';
import { Dimensions } from 'components/visuals';
import TopMenu from 'components/navigation/top/TopMenu';
import SearchBox from 'components/navigation/top/SearchBox';
import TopLogo from 'components/navigation/top/TopLogo';
import LoginStatus from 'components/navigation/top/LoginStatus';
import ActiveDropdown from 'components/navigation/top/dropdowns/ActiveDropdown';
import { FeatureFlags, checkFeatureGate } from 'util/statsig/index';

const HeaderSection = styled.section`
  width: 100%;
  background: ${colors.N0};
`;

const HeaderLine = styled.header`
  padding-top: ${Dimensions.units(1)}px;
  margin: 0 auto;
  color: ${colors.N500};
  position: relative;
`;

type Props = {
  isMACPage: boolean;
};

const Header: React.FC<Props> = ({ isMACPage }: Props) => {
  const cartMACFunctionalityMigration = checkFeatureGate(
    FeatureFlags.CART_MAC_FUNCTIONALITY_MIGRATION,
  );

  return (
    <HeaderSection>
      <HeaderLine>
        {cartMACFunctionalityMigration && isMACPage ? <TopMenuMAC /> : <LoginStatus />}
      </HeaderLine>

      <HeaderLine>
        <TopLogo />
        <TopMenu
          isMACPage={isMACPage}
          cartMACFunctionalityMigration={cartMACFunctionalityMigration}
        />
        <SearchBox />
      </HeaderLine>

      <HeaderLine>
        <ActiveDropdown />
      </HeaderLine>
    </HeaderSection>
  );
};

export default Header;
