import { CartThunk } from 'model/State';
import { TCCartCountryValidationType } from 'modules/checkout/contacts/duck/model';
import { validateCartCountryMatch } from './validateCartCountry';
import { checkCountryTechnicalContactMatch } from './validateContactCountry';

export const TC_CART_COUNTRY_VALIDATIONS = 'checkout/contact/CHECK_TECHNICAL_CONTACT_VALIDATIONS';

export const tcCartCountryValidations = (payload: [any, TCCartCountryValidationType | null]) => ({
  type: TC_CART_COUNTRY_VALIDATIONS,
  payload,
});

export const checkValidationsForTC = (
  isoCountryCode: string,
  emailAddress: string,
): CartThunk<Promise<any>> => (dispatch) =>
  Promise.all([
    dispatch(validateCartCountryMatch(isoCountryCode)),
    dispatch(checkCountryTechnicalContactMatch(isoCountryCode, emailAddress)),
  ]).then((d) => dispatch(tcCartCountryValidations(d)));
