import React, { useCallback, useEffect, useMemo, useState } from 'react';
import appconfig from 'appconfig';
import queryString from 'query-string';

export interface IAuthenticationContext {
  isAuthenticated: () => boolean;
  isImpersonation: () => boolean;
  getRequestHeaders: () => { [name: string]: string };
}

interface AuthenticationState {
  xsrfToken?: string;
}

export const AuthenticationContext = React.createContext<IAuthenticationContext>({
  isAuthenticated: () => false,
  isImpersonation: () => false,
  getRequestHeaders: () => ({}),
});

export const Authenticated = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState<AuthenticationState>({});

  const getToken = useCallback(() => {
    (async () => {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await fetch(`${appconfig.hamsUrl}/1.0/public/auth/xsrf/plain`, {
          mode: 'cors',
          credentials: 'include',
        });
        if (response.status !== 200) {
          // anonymous user
          const loginParams = queryString.stringify({ continue: window.location.href });
          const loginUrl = `${appconfig.idLoginUrl}?${loginParams}`;
          window.location.assign(loginUrl);
          return;
        }

        // authenticated user
        const xsrfToken = await response.text();
        setState((prev) => ({ ...prev, xsrfToken }));
      } catch (e) {
        throw e;
      }
    })();
  }, []);

  useEffect(getToken, []);
  const context: IAuthenticationContext = useMemo(() => {
    // Impersionation parameter
    // eslint-disable-next-line prefer-destructuring
    const contactId = queryString.parse(window.location.search).contactId;

    return {
      isAuthenticated: () => !!state.xsrfToken,
      isImpersonation: () => !!contactId,
      getRequestHeaders: () => {
        const headers: { [name: string]: string } = {};
        if (state.xsrfToken) {
          headers['Atl-Xsrf-Token'] = state.xsrfToken;
        }

        if (contactId) {
          headers['HAMS-Impersonated-Contact-Id'] = contactId;
        }

        return headers;
      },
    };
  }, [state]);

  return (
    <AuthenticationContext.Provider value={context}>
      {context.isAuthenticated() && children}
    </AuthenticationContext.Provider>
  );
};
