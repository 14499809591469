import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import * as selectors from 'modules/tracking/selectors';
import { PRODUCT_LIST, ProductListAction } from 'actions';
import { createTrackEvent, utils } from 'modules/analytics';
import {
  TrackAction,
  TrackActionSubject,
  TrackSource,
  TrackEventNames,
} from 'modules/analytics/model/TrackEvent';
import { CartEpic } from 'model/State';
import { getMessage } from 'modules/migrate/analytics/messages';

export const viewedProductList: CartEpic<ProductListAction> = (action$) =>
  action$.pipe(
    ofType(PRODUCT_LIST),
    map(() =>
      // actions.track('viewedProductList', selectors.props.allProducts))
      createTrackEvent(
        utils.getTrackEventData(
          TrackAction.Viewed,
          TrackActionSubject.ProductList,
          TrackSource.ProductListScreen,
          TrackEventNames.ViewedProductList,
        ),
        (state) => {
          const data = selectors.props.allProducts(state);
          return {
            product: data.product.map(({ category, position, sku, type, vendor }) => ({
              name: getMessage(`products.${sku}.name`),
              category,
              position,
              sku,
              type,
              vendor,
            })),
          };
        },
      ),
    ),
  );

export default viewedProductList;
