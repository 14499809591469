/* eslint-disable import/no-named-as-default */
// @flow
import { actionToPath } from 'redux-first-router';
import queryString from 'query-string';
import routesMap, { basename } from 'routes';
import type { Dispatch } from 'model/State';

export const getActionPath = (to: string | Object) => {
  if (to === null || typeof to === 'string') {
    return to;
  }
  return `${basename}${actionToPath(to, routesMap, queryString)}`;
};

export type ActionLink = {
  href: string,
  navigate: () => void,
};

export const getActionLink = (dispatch: Dispatch, action: Object): ActionLink => ({
  href: getActionPath(action),
  navigate: () => dispatch(action),
});
