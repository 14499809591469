import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';
import { Dimensions, Font } from 'components/visuals';
import Button from '@atlaskit/button';
import SectionMessage from '@atlaskit/section-message';
import t from 'modules/i18n/intl';
import Textfield from '@atlaskit/textfield';
import { getCurrentContactEmail } from 'modules/auth/selectors';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';

const StyledTableData = styled.td<{ paddingLeft?: boolean }>`
  padding: ${({ paddingLeft }) =>
    paddingLeft
      ? `${Dimensions.units(2.5)}px !important`
      : `${Dimensions.units(2.5)}px ${Dimensions.units(2.5)}px ${Dimensions.units(
          2.5,
        )}px 0 !important`};
`;

const StyledTextLabel = styled.p`
  color: ${colors.N100};
  font-family: ${Font.displayFamily};
  width: ${Dimensions.units(5)}%;
  text-align: end;
`;

const StyledTextValueWrapper = styled.div`
  width: ${Dimensions.units(7.5)}%;
  text-align: start;
`;

const StyledTDSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Dimensions.units(1)}px;
`;

const StyledButtonsWrapper = styled.div`
  width: ${Dimensions.units(7)}%;
`;

const StyledPipeSymbol = styled.span`
  color: ${colors.N100};
  padding-right: ${Dimensions.units(0.5)}px;
`;

const StyledLink = styled.a`
  color: ${colors.B400};
  padding: ${Dimensions.units(0.5)}px;
  cursor: pointer;
`;

const StyledFlex = styled.div<{ width?: string; flexDirection?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.flexDirection ? 'column' : 'row')};
  gap: ${Dimensions.units(2)}px;
  padding: ${Dimensions.units(0.5)}px;
  align-items: baseline;
  width: ${(props) => (props.width ? props.width : '')};
`;

const FieldWrapper = styled.div`
  margin-right: ${Dimensions.units(2)}px;
`;

const AddContactWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: end;
  margin-bottom: ${Dimensions.units(2)}px;
`;

const StyledContactsHeading = styled.div`
  color: ${colors.N100};
  font-family: ${Font.displayFamily};
  text-align: start;
`;

const ContactsWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: ${Dimensions.units(11)}%;
`;

const StyledSenInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledDivWithBorder = styled.div`
  margin-top: ${Dimensions.units(3)}px;
  border-bottom: 1px solid ${colors.N100};
`;

const StyledSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledSpan = styled.span<{ margin?: string; padding?: string }>`
  margin: ${(props) => (props.margin ? props.margin : '')};
  padding: ${(props) => (props.padding ? props.padding : '')};
`;

export enum ContactTypes {
  TECHNICAL = 'TECHNICAL',
  BILLING = 'BILLING',
}

const getContacts = (accountDetails, contactType, contactsArray) => {
  if (accountDetails) {
    accountDetails.contactRelationships.forEach((contact: any) => {
      if (contact.relationshipType === contactType)
        contactsArray.push({
          name: contact.contact.contactDetails.displayName,
          email: contact.contact.contactDetails.email,
        });
    });
  }
};

const DetailsRow = ({ label, value, dataCy = '' }) => {
  return (
    <StyledFlex {...(dataCy ? { 'data-cy': dataCy } : {})} width={`${Dimensions.units(12.5)}%`}>
      <StyledTextLabel>{label}</StyledTextLabel>
      <StyledTextValueWrapper>
        <StyledSpan padding={`0 ${Dimensions.units(2)}px`}>{value}</StyledSpan>
      </StyledTextValueWrapper>
    </StyledFlex>
  );
};

const DetailsRowContent = ({
  accountDetails,
  canUserSeeAccountDetails,
  endDate,
  billingContacts,
  technicalContacts,
  productKey,
}) => {
  const shouldShowAccountURL =
    productKey !== 'atlassian.organisation' &&
    accountDetails &&
    (accountDetails.accountUrl || accountDetails.isUnclaimedCloudSite);

  const accountURLValue =
    accountDetails && accountDetails.accountUrl === '' && accountDetails.isUnclaimedCloudSite ? (
      <FormattedMessage id="mac.licenses.site.not.claimed" />
    ) : (
      accountDetails.accountUrl
    );

  return (
    <>
      {canUserSeeAccountDetails ? (
        <>
          <StyledTableData paddingLeft colSpan={3}>
            <StyledTDSection>
              <StyledSenInfoWrapper>
                <DetailsRow
                  label={t('mac.lecenses.name')}
                  value={accountDetails && accountDetails.name}
                />
                <DetailsRow
                  label={t('change.search.label.sen')}
                  value={accountDetails && accountDetails.id ? `SEN-${accountDetails.id}` : ''}
                  dataCy="mac-sen-details"
                />
                {shouldShowAccountURL && (
                  <DetailsRow label={t('mac.licenses.url')} value={accountURLValue} />
                )}
                <StyledFlex width={`${Dimensions.units(12.5)}%`}>
                  <StyledTextLabel data-cy="mac-addres-details">
                    {t('mac.licenses.address.details')}
                  </StyledTextLabel>
                  <Button appearance="link" href="/">
                    {t('mac.licenses.update.address.details')}
                  </Button>
                </StyledFlex>
              </StyledSenInfoWrapper>
              {endDate && (
                <SectionMessage appearance="information">
                  <FormattedMessage
                    id="mac.licenses.update.endDate.message"
                    values={{
                      endDate: (
                        <FormattedDate
                          value={new Date(endDate)}
                          day="2-digit"
                          month="long"
                          year="numeric"
                        />
                      ),
                    }}
                  />
                </SectionMessage>
              )}
              <h3>{t('review.contact-details')}</h3>
              <SectionMessage appearance="information">
                {t('mac.licenses.technical.address.on.invoice')}
              </SectionMessage>
              <StyledDivWithBorder>
                <StyledContactsHeading>
                  {t('mac.licenses.technical.contacts')}
                </StyledContactsHeading>
              </StyledDivWithBorder>
              <ContactsWrapper>
                <StyledFlex flexDirection>
                  {technicalContacts &&
                    technicalContacts.map((contact: { name: string; email: string }) => (
                      <span key={`technical ${contact.name}`}>{contact.name}</span>
                    ))}
                </StyledFlex>
                <StyledFlex flexDirection>
                  {technicalContacts &&
                    technicalContacts.map((contact: { name: string; email: string }) => (
                      <span key={`technical ${contact.email}`}>{contact.email}</span>
                    ))}
                  <AddContactWrapper>
                    <FieldWrapper>
                      <Textfield name="technical-contact" type="text" />
                    </FieldWrapper>
                    <Button appearance="primary">{t('migrate.non-billing-user.search.add')}</Button>
                  </AddContactWrapper>
                </StyledFlex>
              </ContactsWrapper>
              <StyledDivWithBorder>
                <StyledContactsHeading>{t('mac.licenses.billing.contacts')}</StyledContactsHeading>
              </StyledDivWithBorder>
              <ContactsWrapper>
                <StyledFlex flexDirection>
                  {billingContacts &&
                    billingContacts.map((contact: { name: string; email: string }) => (
                      <span key={`billing ${contact.name}`}>{contact.name}</span>
                    ))}
                </StyledFlex>
                <StyledFlex flexDirection>
                  {billingContacts &&
                    billingContacts.map((contact: { name: string; email: string }) => (
                      <span key={`billing ${contact.email}`}>{contact.email}</span>
                    ))}
                  <AddContactWrapper>
                    <FieldWrapper>
                      <Textfield name="technical-contact" type="text" />
                    </FieldWrapper>
                    <Button appearance="primary">{t('migrate.non-billing-user.search.add')}</Button>
                  </AddContactWrapper>
                </StyledFlex>
              </ContactsWrapper>
            </StyledTDSection>
          </StyledTableData>
          <StyledTableData colSpan={2}>
            <StyledTDSection>
              <StyledFlex>
                <StyledTextLabel>{t('mac.licenses.organization')}</StyledTextLabel>
                <StyledTextValueWrapper>
                  <StyledSpan margin={`0 0 0 ${Dimensions.units(0.5)}px`}>
                    {accountDetails && accountDetails.name}
                  </StyledSpan>
                </StyledTextValueWrapper>
              </StyledFlex>
              <StyledFlex>
                <StyledTextLabel>{t('mac.licenses.license.key')}</StyledTextLabel>
                <StyledLink>{t('mac.licenses.view.license')}</StyledLink>
              </StyledFlex>
              <StyledFlex>
                <StyledTextLabel>{t('mac.licenses.actions')}</StyledTextLabel>
                <StyledButtonsWrapper>
                  <StyledLink>{t('navigation.header.menu.upgrade')}</StyledLink>
                  <StyledPipeSymbol>|</StyledPipeSymbol>
                  <StyledLink>
                    <FormattedMessage
                      id="mac.licenses.download"
                      values={{
                        description:
                          accountDetails && accountDetails.merchandise.details.description,
                      }}
                    />
                  </StyledLink>
                  <StyledPipeSymbol>|</StyledPipeSymbol>
                  <StyledLink>{t('mac.licenses.download.source')}</StyledLink>
                  <StyledPipeSymbol>|</StyledPipeSymbol>
                  <StyledLink>{t('mac.licenses.view.developer.license')}</StyledLink>
                  <StyledPipeSymbol>|</StyledPipeSymbol>
                  <StyledLink>{t('mac.licenses.archive')}</StyledLink>
                </StyledButtonsWrapper>
              </StyledFlex>
              <StyledTextLabel>{t('mac.licenses.history')}</StyledTextLabel>
            </StyledTDSection>
          </StyledTableData>
        </>
      ) : (
        // TODO: Show the proper error message once we have it: https://hello.jira.atlassian.cloud/browse/CHT-2819
        <p>You don&apos;t have permissions to see the details</p>
      )}
    </>
  );
};

export const LicensesExpandedRow = ({ productKey, accountDetails }) => {
  const currentContactEmail = useSelector(getCurrentContactEmail);

  const [canUserSeeAccountDetails, setCanUserSeeAccountDetails] = useState(false);
  const [loading, isLoading] = useState(false);

  const technicalContacts = [];
  const billingContacts = [];

  let endDate;

  getContacts(accountDetails, ContactTypes.TECHNICAL, technicalContacts);
  getContacts(accountDetails, ContactTypes.BILLING, billingContacts);

  // This check would probably be moved to the backend
  useEffect(() => {
    isLoading(true);
    if (accountDetails && technicalContacts && billingContacts) {
      [...technicalContacts, ...billingContacts].forEach((contact: { email: string }) => {
        if (contact.email && contact.email === currentContactEmail) {
          setCanUserSeeAccountDetails(true);
        } else {
          setCanUserSeeAccountDetails(false);
        }
        isLoading(false);
      });
    }
  }, [currentContactEmail, technicalContacts, billingContacts, loading]);

  if (accountDetails && accountDetails.latestLicense) {
    endDate = new Date(accountDetails.latestLicense.expires);
  }

  return (
    <>
      {loading ? (
        <StyledTableData colSpan={5}>
          <StyledSpinnerWrapper>
            <Spinner />
          </StyledSpinnerWrapper>
        </StyledTableData>
      ) : (
        accountDetails && (
          <DetailsRowContent
            accountDetails={accountDetails}
            canUserSeeAccountDetails={canUserSeeAccountDetails}
            endDate={endDate}
            technicalContacts={technicalContacts}
            billingContacts={billingContacts}
            productKey={productKey}
          />
        )
      )}
    </>
  );
};
