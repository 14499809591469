import mapErrorAndRetry from 'util/rxOperators/mapErrorAndRetry';
import { combineEpics } from 'redux-observable';
import * as errors from 'modules/error-info';
import * as contacts from 'modules/checkout/contacts';
import * as cart from 'modules/cart';
import trackingEpics from 'modules/tracking';
import * as watchProgress from 'model/watchProgress';
import * as telemetry from 'modules/telemetry';
import * as upgrade from 'modules/upgrade/duck/datacenter/epic';

const rootEpic = combineEpics(
  contacts.epic,
  trackingEpics,
  cart.epic,
  watchProgress.epic,
  telemetry.epic,
  upgrade.epic,
);

export default (action$, state$) => rootEpic(action$, state$).pipe(mapErrorAndRetry(errors.report));
