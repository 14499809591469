// @flow
import {
  CloudProductKeys,
  DataCenterProductKeys,
  ProductGroupKeys,
  ServerProductKeys,
} from 'data/products/keys';
import config from 'appconfig';
import t from 'modules/i18n/intl';
import ServerProduct from 'model/editions/ServerProduct';
import DataCenterProduct from 'model/editions/DataCenterProduct';
import SoftwareProductGroup from 'model/SoftwareProductGroup';
import CloudProduct from 'model/editions/CloudProduct';
import { deploymentOptions, saml } from 'model/features';

export const confluenceServer = new ServerProduct({
  key: ServerProductKeys.CONFLUENCE,
  groupKey: ProductGroupKeys.CONFLUENCE,
  tagLine: t('products.confluence.tagline'),
  description: t('products.confluence.description'),
  name: t('products.confluence.name'),
  moreLink: `${config.wacUrl}/software/confluence`,
  recommended: [
    ServerProductKeys.CONFLUENCE_QUESTIONS,
    ServerProductKeys.CONFLUENCE_TEAM_CALENDARS,
    ServerProductKeys.JIRA_SOFTWARE,
  ],
});

export const confluenceDataCenter = new DataCenterProduct(
  DataCenterProductKeys.CONFLUENCE,
  confluenceServer,
  [],
  [deploymentOptions, saml],
);

export const confluenceCloud = new CloudProduct(CloudProductKeys.CONFLUENCE, confluenceServer);

export const confluenceProductGroup = new SoftwareProductGroup(
  confluenceServer,
  confluenceDataCenter,
  confluenceCloud,
);
