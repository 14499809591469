import appconfig from 'appconfig';

import { OrderableItem, PriceStrategy } from 'model/hams/Pricing';
import { IAuthenticationContext } from 'modules/migrate/Authenticated';
import { LicenseType } from 'model/hams/LicenseType';
import { ProductKey } from 'data/products/keys';

//  Refers to the number of accounts being fetched at a time
const PAGE_SIZE = 25;

interface HamsMigrationEvaluation {
  eligibleForExtendedCloudTrial: boolean;
  provisioned: boolean;
  migrationSourceUuid: string | null;
  bundleKey: string;
  cloudProductDescription: string;
}

export interface HamsAccountMigrationInfo {
  accountName: string;
  accountId: number;

  // after TS types will be fixed this property will be actual ProductKey
  // right now it's just a string because of the broken types
  productKey: ProductKey;
  productName: string;

  migrationEvaluation: HamsMigrationEvaluation;

  freeAvailable: boolean;
  freeTierLimit: number;
  freeUnitLabel: string;

  serverRenewalPrice: number | null;
  futureServerRenewalPrice: number | null;
  unitCount: number;
  unitLabel: string;

  available: boolean;
  cloudProductKey: string;
  cloudProductDescription: string;

  annualTiers: OrderableItem[];
  monthlyPriceStrategy: PriceStrategy;
  premiumMonthlyPriceStrategy?: PriceStrategy;

  currency: string;
  expiryDate: string;
  licenseType: LicenseType;

  pricingLink: string;
}

export interface HamsResponse {
  items: HamsAccountMigrationInfo[];
  offset: number;
  limit: number;
  totalItems: number;
}

export interface ApiResponse extends HamsResponse {
  nextPage: boolean;
}

export const fetchAccounts = async ({
  authenticationContext,
  page,
}: {
  authenticationContext: IAuthenticationContext;
  page: number;
}): Promise<ApiResponse> => {
  const response = await fetch(
    `${appconfig.hamsUrl}/1.0/public/account/migrate/list?offset=${
      page * PAGE_SIZE
    }&limit=${PAGE_SIZE}`,
    {
      headers: authenticationContext.getRequestHeaders(),
      mode: 'cors',
      credentials: 'include',
    },
  );
  if (response.status !== 200) {
    const error = new Error();
    try {
      Object.assign(error, await response.json());
    } catch (e) {
      error.message = e.message;
    }
    throw error;
  }
  const result = (await response.json()) as HamsResponse;
  return {
    ...result,
    nextPage: page * PAGE_SIZE + PAGE_SIZE < result.totalItems,
  };
};
