import config from 'appconfig';
import { ExternalProductKeys } from 'data/products/keys';
import ExternalProduct from 'model/ExternalProduct';
import { APP } from 'model/ProductType';
import t from 'modules/i18n/intl';

export const jiraProductDiscovery = new ExternalProduct({
  key: ExternalProductKeys.JIRA_PRODUCT_DISCOVERY,
  type: APP,
  tagLine: t('products.jira-product-discovery.tagline'),
  description: t('products.jira-product-discovery.description'),
  name: t('products.jira-product-discovery.name'),
  externalUrl: `${config.wacUrl}/try/cloud/signup?bundle=jira-product-discovery`,
});

export default jiraProductDiscovery;
