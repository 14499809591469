import appconfig from 'appconfig';
import { IAuthenticationContext } from 'modules/migrate/Authenticated';

export const fetchEmail = async ({
  authenticationContext,
}: {
  authenticationContext: IAuthenticationContext;
}) => {
  const response = await fetch(`${appconfig.hamsUrl}/1.0/public/auth/user/details`, {
    mode: 'cors',
    credentials: 'include',
    headers: authenticationContext.getRequestHeaders(),
  });
  if (response.status !== 200) {
    throw new Error();
  }
  const result = await response.json();
  return result.username;
};
