// @flow
import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';

import ProductLogo from 'components/common/ProductLogo';
import { Font, Dimensions } from 'components/visuals';
import NavLink from 'components/common/NavLink';
import Product from 'model/Product';

const Link = styled(NavLink)`
  &:hover {
    text-decoration: none;
  }
`;

type Props = {
  product: Product,
};

const OuterWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid ${colors.N60};
  border-bottom-width: 0;

  &:hover {
    background-color: ${colors.N20};
  }

  &:last-child {
    border-bottom-width: 1px;
  }
`;

const Wrapper = styled.div`
  padding: 18px ${Dimensions.units(3)}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  width: 300px;
`;

const DescriptionWrapper = styled.div`
  width: 600px;
`;

const TagLine = styled.p`
  color: ${colors.N200};
  margin: 0;
  font-size: 12px;
  font-family: ${Font.family};
`;

const Description = styled.p`
  font-size: 16px;
  font-family: ${Font.family};
  margin: 0;
`;

const linkStyle = { display: 'inline-block' };

export default ({ product }: Props) => (
  <OuterWrapper>
    <Link to={product.getLink()} style={linkStyle}>
      <Wrapper>
        <LogoWrapper>
          <ProductLogo product={product} size="medium" />
          <TagLine>{product.tagLine}</TagLine>
        </LogoWrapper>
        <DescriptionWrapper>
          <Description>{product.description}</Description>
        </DescriptionWrapper>
      </Wrapper>
    </Link>
  </OuterWrapper>
);
