/* eslint-disable no-param-reassign,consistent-return */

import produce from 'immer';
import { SHOW_COMPANY_COUNTRY_CURRENCY } from 'modules/checkout/contacts/duck/actions/showCountryCurrency';

type ShowCountryCurrencyState = {
  showCompanyCountryCurrency: boolean;
};

const initialState: ShowCountryCurrencyState = {
  showCompanyCountryCurrency: false,
};

export default produce((draft, action): ShowCountryCurrencyState | void => {
  switch (action.type) {
    case SHOW_COMPANY_COUNTRY_CURRENCY:
      draft.showCompanyCountryCurrency = action.payload;
      break;
    default:
      break;
  }
}, initialState);
