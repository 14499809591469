/* eslint-disable no-underscore-dangle */
// @flow
import Progress from 'redux-progress';
import uniqBy from 'lodash/uniqBy';

import type { MarketplaceEula } from 'modules/checkout/review/duck/model';
import { ROUTE } from 'modules/checkout/review/duck/actions';
import type { Selector } from 'model/State';
import { selectMarketplaceAddon } from 'modules/catalog/addons';

export const selectMarketplaceAddonEulas: Selector<Progress<MarketplaceEula[]>> = (state) =>
  state.cart.payload.flatMap((cart) => {
    if (!cart) {
      return Progress.none;
    }

    const getLink = (item) =>
      selectMarketplaceAddon(state, { productKey: item.productDetails.productKey }).map((addon) =>
        addon && addon._embedded.version.vendorLinks && addon._embedded.version.vendorLinks.eula
          ? addon._embedded.version.vendorLinks.eula
          : null,
      );

    const eulas: Progress<MarketplaceEula>[] = uniqBy(
      cart.items,
      (item) => item.productDetails.productKey,
    )
      .filter((item) => item.productDetails.marketplaceAddon)
      .map((item) =>
        getLink(item).map((link) => ({
          name: item.productDetails.productDescription,
          link,
        })),
      );

    return (Progress.all(...eulas): any);
  });

export const selectUnavailableMarketplaceAddonInCart: Selector<Progress<String[]>> = (state) =>
  selectMarketplaceAddonEulas(state).map((eulas) =>
    eulas.filter((eula) => eula.link === null).map((eula) => eula.name),
  );

export const isReviewPage: Selector<boolean> = (state) => state.location.type === ROUTE;
