// @flow
import React, { useContext } from 'react';
import { CheckoutModes } from 'modules/checkout/payment/duck/model';
import PaymentMethodButton from 'modules/checkout/payment/components/PaymentMethodButton';
import type {
  CreditCardProps,
  StripeCreditCardProps,
  CheckoutMode,
} from 'modules/checkout/payment/duck/model';
import t from 'modules/i18n/intl';

import { CartAnalyticsContext } from 'modules/analytics';
import { analyticsEvents, AnalyticsTrigger } from 'modules/siteconfigurator/analytics';
import { CREDIT_CARD_TEST_ID } from './TabButton.testids';

type Props = {
  creditCard: CreditCardProps,
  stripeCreditCard: StripeCreditCardProps,
  selectMode: (mode: CheckoutMode) => void,
  inSiteConfiguratorMode: boolean,
};

type CreditCardPaymentMethodButtonProps = {
  isSelected: boolean,
  checkoutMode: CheckoutMode,
  selectMode: (mode: CheckoutMode) => void,
  inSiteConfiguratorMode: boolean,
};
const CreditCardPaymentMethodButton = ({
  isSelected,
  checkoutMode,
  selectMode,
  inSiteConfiguratorMode,
}: CreditCardPaymentMethodButtonProps) => {
  const analytics = useContext(CartAnalyticsContext);
  const onSelect = () => {
    if (inSiteConfiguratorMode) {
      analyticsEvents[AnalyticsTrigger.CreditCard](analytics);
    }
    selectMode(checkoutMode);
  };

  return (
    <PaymentMethodButton
      title={t('payment.credit-card.button.title')}
      isSelected={isSelected}
      select={onSelect}
      testId={CREDIT_CARD_TEST_ID}
    >
      {t('payment.credit-card.button.description')}
    </PaymentMethodButton>
  );
};

const CreditCardTabButton = (props: Props) => {
  if (props.stripeCreditCard.isAvailable) {
    return (
      <CreditCardPaymentMethodButton
        isSelected={props.stripeCreditCard.isSelected}
        checkoutMode={CheckoutModes.STRIPE_CREDIT_CARD}
        selectMode={props.selectMode}
        inSiteConfiguratorMode={props.inSiteConfiguratorMode}
      />
    );
  }
  if (props.creditCard.isAvailable) {
    return (
      <CreditCardPaymentMethodButton
        isSelected={props.creditCard.isSelected}
        checkoutMode={CheckoutModes.CREDIT_CARD}
        selectMode={props.selectMode}
        inSiteConfiguratorMode={props.inSiteConfiguratorMode}
      />
    );
  }

  return null;
};

export default CreditCardTabButton;
