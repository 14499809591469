// @flow
import React from 'react';

import Product from 'model/Product';
import ProductEntry from 'components/products/ProductEntry';

type Props = {
  products: Product[],
};

export default (props: Props) => (
  <div>
    {props.products.map((product) => (
      <ProductEntry key={product.key} product={product} />
    ))}
  </div>
);
