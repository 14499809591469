/* eslint-disable import/prefer-default-export */
// @flow
import { thunkProgress } from 'redux-progress';
import { HamsError } from 'modules/hams';

import * as api from 'modules/renew/duck/server/api';
import type { Dispatch, GetState } from 'model/State';
import { selectHamsClientConfig } from 'modules/auth/selectors';
import { actions as cartActions } from 'modules/cart';
import * as change from 'modules/change';
import selectCartPayload from 'modules/cart/duck/selectors/cart';
import watchProgress from 'model/watchProgress';
import { PurchaseModes, selectPurchaseMode } from 'modules/settings/purchaseMode';
import { inEditMode } from 'modules/cart/duck/selectors';
import * as review from 'modules/checkout/review/duck/actions/route';

export const addAccountRenewItemsToCart = () => async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const purchaseMode = selectPurchaseMode(state);

  const cart = await watchProgress(selectCartPayload);
  const selectedOptions = await change.table.selectors
    .selectChosenOptions(state)
    .ifSuccess((chosenOptions) =>
      dispatch(
        thunkProgress(
          change.table.actions.SUBMIT_CHANGE_LIST,
          api.addRenewItemsToCart(cart.uuid, chosenOptions, selectHamsClientConfig(state)),
        ),
      ),
    );

  const isHamsError = selectedOptions.result instanceof HamsError;
  if (selectedOptions && !isHamsError) {
    selectedOptions.unwrap();
    if (purchaseMode === PurchaseModes.QUOTE && inEditMode(state)) {
      dispatch(review.route());
    } else {
      dispatch(cartActions.route());
    }
  }
};
