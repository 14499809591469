/* eslint-disable react/jsx-no-target-blank */
// @flow
import React from 'react';
import styled from 'styled-components';

import { colors } from '@atlaskit/theme';
import type { RowProps } from 'modules/change/table/duck/model';
import SelectWithExtraInfo from 'components/common/SelectWithExtraInfo';
import AmountWithCurrency from 'components/common/AmountWithCurrency';

const Wrapper = styled.div`
  color: ${colors.N200};

  a {
    font-weight: 500;
  }
`;

const getOptionExtraInfo = (option) => <AmountWithCurrency {...option} />;

const getOptionValue = (option) => option.maintenanceMonths;

const ChangeOptions = (props: RowProps) => (
  <Wrapper>
    <SelectWithExtraInfo
      items={props.entry.options.result.optionsList}
      getOptionLabel={props.getOptionLabel}
      getOptionExtraInfo={getOptionExtraInfo}
      getOptionValue={getOptionValue}
      onChange={(option) => {
        props.onOptionSelect(props.entry, option);
      }}
      selected={props.entry.selectedOption.result}
      fitToContainer
    />
  </Wrapper>
);

export default ChangeOptions;
