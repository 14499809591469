import React, { useContext, useEffect } from 'react';
import t from 'modules/i18n/intl';
import config from 'appconfig';

import styled from 'styled-components';
import { Dimensions } from 'components/visuals';
import { FormContext } from 'modules/form/Form';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';

import { analyticsEvents, AnalyticsTrigger } from 'modules/checkout/contacts/analytics';
import { CartAnalyticsContext } from 'modules/analytics';

const GENERAL_ERRORS = [
  'ERR_BAD_REQUEST',
  'ERR_TAAS_ADDRESSES_NOT_DELIVERABLE_000',
  'ERR_TAAS_ADDRESSES_INCOMPLETE_006',
  'ERR_TAAS_ADDRESSES_NOT_GEOCODED_00',
  'ERR_TAAS_ADDRESSES_AVALARA_400',
  'ERR_TAAS_ADDRESSES_NA',
];

const Wrapper = styled.div`
  margin-top: ${Dimensions.units(2)}px;
`;

export const GeneralErrors = () => {
  const { path, validity } = useContext(FormContext);
  const analytics = useContext(CartAnalyticsContext);

  const errorCode = validity.get(path).error?.text;

  const isGeneralError = GENERAL_ERRORS.includes(errorCode);

  useEffect(() => {
    if (isGeneralError) {
      analyticsEvents[AnalyticsTrigger.ContactDetailsValidationFailed](analytics, errorCode);
    }
  }, [isGeneralError]);

  if (isGeneralError) {
    let errorMessage = 'billing.address.validation.general-error.message';

    if (errorCode === 'ERR_TAAS_ADDRESSES_AVALARA_400') {
      errorMessage = 'billing.address.validation.avalara-400-error.message';
    } else if (errorCode === 'ERR_TAAS_ADDRESSES_NA') {
      errorMessage = 'billing.address.validation.address-na-error.message';
    }

    return (
      <Wrapper>
        <SectionMessage
          appearance="error"
          title={t('billing.address.validation.general-error.title')}
          actions={[
            {
              text: t('common.contact-us'),
              href: `${config.wacUrl}/company/contact/purchasing-licensing`,
              key: 'contact-us',
            },
          ].map(({ text, ...restAction }) => (
            <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
          ))}
        >
          {t(errorMessage)}
        </SectionMessage>
      </Wrapper>
    );
  }
  return null;
};
