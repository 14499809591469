/* eslint-disable import/prefer-default-export */

import { sum } from 'util/reducers';
import { selectChosenOptions } from 'modules/change/table/duck/selectors/chosenOptions';
import { Selector } from 'model/State';
import {
  AccountChangeOption,
  PriceAdjustement,
  AddToCartAnalyticsProducts,
  AddToCartAnalyticsData,
} from 'modules/accounts/model';
import { LOYALTY_DISCOUNT_IDENTIFIER, UPGRADE_CREDIT_IDENTIFIER } from 'util/constants';
import { selectChangeCurrency } from 'modules/change/table/duck/selectors/currency';

export const selectAmount: Selector<number> = (state) =>
  selectChosenOptions(state).ifSuccess((options: AccountChangeOption[]) =>
    options.map((o) => o.amount).reduce(sum, 0),
  ) || 0;

export const selectOriginalAmount: Selector<number> = (state) =>
  selectChosenOptions(state).ifSuccess((options: AccountChangeOption[]) =>
    options.map((o) => o.originalAmount).reduce(sum, 0),
  ) || 0;

export const selectLoyaltyDiscount: Selector<number> = (state) =>
  selectChosenOptions(state).ifSuccess((options: AccountChangeOption[]) =>
    options
      .map((o) => {
        const loyaltyPriceAdjustment =
          o.adjustments &&
          o.adjustments.length > 0 &&
          o.adjustments.find(
            (adjustment: PriceAdjustement) =>
              adjustment.description === LOYALTY_DISCOUNT_IDENTIFIER,
          );
        return loyaltyPriceAdjustment ? loyaltyPriceAdjustment.amount : 0;
      })
      .reduce(sum, 0),
  ) || 0;

export const getChoosenOptionDetails: Selector<AddToCartAnalyticsProducts[] | null> = (state) =>
  selectChosenOptions(state).ifSuccess((options: AccountChangeOption[]) =>
    options.map((o) => {
      const productDiscount =
        o.adjustments.length &&
        o.adjustments
          .filter((ao) => ao.description !== UPGRADE_CREDIT_IDENTIFIER)
          .map((ao) => ao.amount)
          .reduce(sum, 0);

      return {
        productDiscount,
        productName: o.productDescription,
        productTier: o.unitCount,
        productPrice: o.originalAmount,
      };
    }),
  );

export const getAddToCartEventData: Selector<AddToCartAnalyticsData> = (state) => {
  const total = selectAmount(state);
  const subtotal = selectOriginalAmount(state);
  const loyaltyDiscount = selectLoyaltyDiscount(state);
  const products = getChoosenOptionDetails(state) || [];
  const totalSaving = products.map((val) => val.productDiscount).reduce(sum, 0);
  const currency = selectChangeCurrency(state).result as string;

  return {
    loyaltyDiscount,
    totalSaving,
    subtotal,
    total,
    products,
    currency,
  };
};
