// @flow
import { TOGGLE_EULA, ROUTE } from 'modules/checkout/review/duck/actions';

const reducer = (state: boolean = false, action: any) => {
  switch (action.type) {
    case ROUTE:
      return false;
    case TOGGLE_EULA:
      return !state;
    default:
      return state;
  }
};

export default reducer;
