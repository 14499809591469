import Progress from 'redux-progress';
import flatten from 'lodash/flatten';

import { selectProductKeys } from 'modules/addon/duck/selectors/productKeys';
import { Selector } from 'model/State';
import ProductTier from 'model/hams/ProductTier';
import { LicenseType } from 'model/hams/LicenseType';
import { OrderableItem } from 'model/hams/Pricing';
import { selectProductTiers } from 'modules/catalog/pricing';

export class MultiProductTier extends ProductTier {
  children: ProductTier[];

  constructor(tier: ProductTier) {
    super(tier.unitCount, tier.unitLabel, tier.productKey);
    this.children = [tier];
  }

  getOrderableItem(licenseType: LicenseType): OrderableItem {
    return this.children
      .map((t) => t.getOrderableItem(licenseType))
      .reduce((p, c) => ({
        ...p,
        amount: p.amount + c.amount,
      }));
  }

  getAllOrderableItems(licenseType: LicenseType): OrderableItem[] {
    return this.children.map((t) => t.getOrderableItem(licenseType));
  }
}

const mergeTiers = (tiers, tier) => {
  if (!tier) {
    return tiers;
  }
  const existing = tiers.find(
    (t) => t.unitCount === tier.unitCount && t.unitLabel === tier.unitLabel,
  );

  if (existing) {
    existing.children.push(tier);
  } else {
    tiers.push(new MultiProductTier(tier));
  }
  return tiers;
};

export const selectMergedAddonsProductTiers: Selector<Progress<MultiProductTier[]>> = (state) =>
  Progress.all(
    ...selectProductKeys(state).map((productKey) => selectProductTiers(state, { productKey })),
  )
    .map(flatten)
    .map((arr) => arr.reduce(mergeTiers, [])) as Progress<MultiProductTier[]>;
