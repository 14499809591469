import { combineEpics } from 'redux-observable';

import { route } from 'modules/mac/duck/actions/configUser';

import { timeAction, counter } from './util';

export default combineEpics(
  counter(route.type, 'mac.config-user'),
  timeAction(route.type, 'mac.config-user'),
);
