// @flow
import Progress from 'redux-progress';
import ProductTier from 'model/hams/ProductTier';
import type { HamsCart } from 'modules/cart/duck/model';

export const SOFTWARE = 'SOFTWARE';
export const SUPPORT = 'SUPPORT';

export type ConfigurationType = 'SOFTWARE' | 'SUPPORT';

export type PerProductConfiguration = {
  productKey: string,
  selected: boolean,
  tier: ProductTier | null,
  expanded?: boolean,
};

export type GeneralProductConfiguration = {
  addons: PerProductConfiguration[],
  product: PerProductConfiguration,
  terms: number,
  submitProgress: Progress<HamsCart>,
};
