// @flow
import { sum } from 'util/reducers';
import { createSelector } from 'reselect';

import { buildTermOption, buildTermOptions } from 'modules/configure/duck/util';
import { selectOrderableItems } from 'modules/configure/duck/selectors/items';
import { isSubscription } from 'modules/configure/duck/selectors/mainProduct';
import type { TermOption } from 'model/TermOption';
import type { Selector } from 'model/State';

import Progress from 'redux-progress';
import { selectDeploymentOption } from 'modules/addon/duck/selectors/deploymentGroup';
import { SERVER } from 'model/hams/DeploymentOption';

const selectChosenTerms: Selector<number> = (state) =>
  state.configure ? state.configure.terms : 1;

export const selectTermAmount: Selector<number> = createSelector(selectOrderableItems, (items) =>
  items.map((item) => item.renewalAmount).reduce(sum, 0),
);

export const selectMonthsPerTerm: Selector<number> = createSelector(selectOrderableItems, (items) =>
  items.length > 0 ? items[0].monthsValid : 12,
);

const SINGLE_YEAR_CAP = 1;
const MULTI_YEAR_CAP = 2;

export const selectTermsAmount: Selector<number> = () => MULTI_YEAR_CAP;

export const selectAddonTermsAmount: Selector<Progress<number>> = createSelector(
  selectDeploymentOption,
  (deploymentOptionProgress) => {
    return Progress.all(deploymentOptionProgress).map(([deploymentOption]) =>
      deploymentOption === SERVER ? SINGLE_YEAR_CAP : MULTI_YEAR_CAP,
    );
  },
);

export const selectTermOptions: Selector<TermOption[]> = createSelector(
  selectTermAmount,
  selectMonthsPerTerm,
  isSubscription,
  selectTermsAmount,
  buildTermOptions,
);

export const selectTerm: Selector<TermOption> = createSelector(
  selectChosenTerms,
  selectMonthsPerTerm,
  selectTermAmount,
  isSubscription,
  (terms, months, amount, subscription) => buildTermOption(terms, amount, months, subscription),
);
