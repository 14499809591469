import { combineReducers } from 'redux';

import accountId from './accountId';
import btfAccountId from './btfAccountId';
import cart from './cart';
import orders from './orders';
import contactEmail from './contactEmail';
import accounts from './accounts';
import configUser from './configUser';
import contact from './contact';
import { BTFData, MACAccountIdState, MACCartState, MACContactState } from '../models';
import { OrdersData } from '../models/orders';
import { ContactEmailData } from '../models/contactEmail';
import { AccountData } from '../models/accounts';
import { ConfigUserData } from '../models/configUser';

// TODO: Refactor naming to be more descriptive
export type MACState = {
  accountId: MACAccountIdState;
  btfAccountId: BTFData;
  cart: MACCartState;
  orders: OrdersData;
  contactEmail: ContactEmailData;
  accounts: AccountData;
  configUser: ConfigUserData;
  contact: MACContactState;
};

export default combineReducers({
  accountId,
  btfAccountId,
  cart,
  orders,
  contactEmail,
  accounts,
  configUser,
  contact,
});
