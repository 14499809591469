import React from 'react';

import SectionMessage from '@atlaskit/section-message';

import t from 'modules/i18n/intl';

import { MessageNotificationWrapper } from './styled';

export type Props = {
  isInProgress: boolean;
};

const PaymentProcessingMessage: React.FC<Props> = ({ isInProgress }) =>
  isInProgress ? (
    <MessageNotificationWrapper>
      <SectionMessage title={t('review.payment-processing.title')}>
        {t('review.payment-processing.description')}
      </SectionMessage>
    </MessageNotificationWrapper>
  ) : null;

export default PaymentProcessingMessage;
