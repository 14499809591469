import React from 'react';
import { useSelector } from 'react-redux';

import config from 'appconfig';
import {
  QuoteMigrationState,
  selectQuoteMigrationData,
  cartStatus,
} from 'modules/cart/duck/selectors';
import { FeatureFlags, checkFeatureGate } from 'util/statsig';
import { QuoteMigrationStatuses } from 'util/QuoteMigrationStatuses';
import { MigrationProcessBanner } from './MigrationProcessBanner';
import { State } from 'model/State';

const buildUrl = ({ ccpQuoteTxaId, ccpQuoteUuid }): string | undefined =>
  ccpQuoteTxaId && ccpQuoteUuid
    ? config.adminHubUrl.concat(`/${ccpQuoteTxaId}/quote/${ccpQuoteUuid}`)
    : undefined;

type ConsiderMigrationStatusPropTypes = {
  children: React.ReactNode;
  migrationState: QuoteMigrationState | null;
};

const ConsiderMigrationStatus: React.FC<ConsiderMigrationStatusPropTypes> = (props) => {
  if (!props.migrationState) return null;

  const { ccpQuoteTxaId, ccpQuoteUuid, ccpQuoteMigrationStatus } = props.migrationState;

  const redirectToBAC = () => {
    const urlToBAC = buildUrl({ ccpQuoteTxaId, ccpQuoteUuid });
    if (urlToBAC) window.location.href = urlToBAC;
  };

  switch (ccpQuoteMigrationStatus) {
    case QuoteMigrationStatuses.COMPLETED:
      redirectToBAC();
      return null;
    case QuoteMigrationStatuses.IN_PROGRESS:
      return <MigrationProcessBanner />;
    case QuoteMigrationStatuses.FAILED:
      return props.children;
    default:
      return props.children;
  }
};

export const WithQuoteMigrationHandle: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const migrationState = useSelector(selectQuoteMigrationData);
  const status = useSelector((state) => cartStatus(state as State));
  const ccpQuoteMigrationFlagIsOn = checkFeatureGate(
    FeatureFlags.CART_HAMS_CCP_OPEN_QUOTE_MIGRATION,
  );

  return ccpQuoteMigrationFlagIsOn && status === 'DELETED' ? (
    <ConsiderMigrationStatus migrationState={migrationState}>{children}</ConsiderMigrationStatus>
  ) : (
    <>{children}</>
  );
};
