// past tense verbs describing the action
export enum UIAction {
  Clicked = 'clicked',
  Rendered = 'rendered',
  Expanded = 'expanded',
  Collapsed = 'collapsed',
  Toggled = 'toggled',
  Selected = 'selected',
  Input = 'input',
}

export enum UIActionSubject {
  Link = 'link',
  Button = 'button',
  LinkButton = 'linkButton',
  Modal = 'modal',
  Row = 'row',
  Accordion = 'accordion',
  Form = 'form',
  Select = 'select',
  Input = 'input',
  Banner = 'banner',
  CartConfig = 'cartConfig',
  Switcher = 'switcher',
  TableHeaderCellContent = 'tableHeaderCellContent',
  Recommendation = 'recommendation',
  CTA = 'cta',
  Review = 'review',
  ProductConfiguration = 'productConfiguration',
}

export enum UISource {
  Modal = 'modal',
  Banner = 'banner',
  Page = 'page',
  Table = 'table',
}

export type UIFields = Readonly<{
  action: UIAction;
  actionSubject: UIActionSubject;
  actionSubjectId?: string;
  attributes?: Record<string, any>;
  tags?: string[];
}>;

export type UIContext = Readonly<{
  source: string;
  objectType?: string;
  objectId?: string;
  containerType?: string;
  containerId?: string;
}>;

export interface CartUIEvent extends UIFields, UIContext {}
