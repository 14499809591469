import { selectXsrfToken } from 'modules/auth/selectors';

import watchProgress from 'model/watchProgress';
import * as auth from 'modules/auth';
import {
  fetchArchivedAccountIds,
  fetchArchivedLicenseIds,
  fetchHamsBilledByEmail,
  fetchLicenseByEmail,
} from '../contactEmail';

export default async (dispatch) => {
  const xsrfToken = await watchProgress(selectXsrfToken);
  if (!xsrfToken) {
    auth.actions.goToLogin();
    return;
  }
  await dispatch(fetchHamsBilledByEmail());
  await dispatch(fetchLicenseByEmail());
  await dispatch(fetchArchivedAccountIds());
  await dispatch(fetchArchivedLicenseIds());
};
