// @flow
import DataCenterProduct from 'model/editions/DataCenterProduct';
import {
  CloudProductKeys,
  DataCenterProductKeys,
  ProductGroupKeys,
  ServerProductKeys,
} from 'data/products/keys';
import t from 'modules/i18n/intl';
import ServerProduct from 'model/editions/ServerProduct';
import SoftwareProductGroup from 'model/SoftwareProductGroup';
import CloudProduct from 'model/editions/CloudProduct';

export const confluenceQuestionsServer = new ServerProduct({
  key: ServerProductKeys.CONFLUENCE_QUESTIONS,
  groupKey: ProductGroupKeys.CONFLUENCE_QUESTIONS,
  tagLine: t('products.com.atlassian.confluence.plugins.confluence-questions.tagline'),
  description: t('products.com.atlassian.confluence.plugins.confluence-questions.description'),
  name: t('products.com.atlassian.confluence.plugins.confluence-questions.name'),
  addon: true,
  parentKeys: [ServerProductKeys.CONFLUENCE],
  moreLink:
    'https://marketplace.atlassian.com/plugins/com.atlassian.confluence.plugins.confluence-questions',
});

export const confluenceQuestionsDatacenter = new DataCenterProduct(
  DataCenterProductKeys.CONFLUENCE_QUESTIONS,
  confluenceQuestionsServer,
);

export const confluenceQuestionsCloud = new CloudProduct(
  CloudProductKeys.CONFLUENCE_QUESTIONS,
  confluenceQuestionsServer,
  [CloudProductKeys.CONFLUENCE],
);

export const confluenceQuestionsProductGroup = new SoftwareProductGroup(
  confluenceQuestionsServer,
  confluenceQuestionsDatacenter,
  confluenceQuestionsCloud,
);
