// @flow
import * as Cookies from 'js-cookie';
import { thunkProgress } from 'redux-progress';
import castArray from 'lodash/castArray';

import { doFetchLanguage, getLanguages } from 'modules/i18n/duck/api';
import type { LanguageDef } from 'modules/i18n/duck/model';
import type { Dispatch, GetState } from 'model/State';
import type { WrapperFunction } from 'routes/wrapRouteThunks';
import { defaultLanguage } from './model';

export const SELECT_LANGUAGE = 'i18n/language/SELECT';
export const FETCH_LANGUAGE = 'i18n/language/FETCH';
export const COOKIE_EXPIRY = 365;

const saveLanguage = (language: string) =>
  Cookies.set('__i18n', language, { expires: COOKIE_EXPIRY, path: '/purchase' });

export const fetchLanguage = (lang: string) => async (dispatch: Dispatch, getState: GetState) => {
  if (lang === defaultLanguage.locale) {
    return;
  }
  const state = getState();
  if (
    !state.i18n.translations[lang] ||
    state.i18n.translations[lang].failed ||
    state.i18n.translations[lang].isNone
  ) {
    await dispatch(thunkProgress(FETCH_LANGUAGE, doFetchLanguage(lang), { payload: { lang } }));
  }
};

export const selectLanguage = (
  languages: LanguageDef | LanguageDef[],
  storePreference = true,
) => async (dispatch: Dispatch) => {
  const language = castArray(languages)[0];

  if (!language) {
    return;
  }

  if (storePreference) {
    saveLanguage(language.locale);
  }

  dispatch({
    type: SELECT_LANGUAGE,
    payload: { language: language.locale },
  });
  await dispatch(fetchLanguage(language.locale));
};

export const languageInitWrapper: WrapperFunction = (next) => (
  dispatch: Dispatch,
  getState: GetState,
) => Promise.all([dispatch(selectLanguage(getLanguages(), false)), next(dispatch, getState)]);
