// @flow
import React from 'react';

import PaymentCreditCardDetails from 'modules/checkout/review/details/PaymentCreditCartDetails';
import { EditButton, Header, Section, Column } from 'modules/checkout/review/details/Commons';
import PaymentBankDetails from 'modules/checkout/review/details/PaymentBankDetails';
import PaymentNet30Details from 'modules/checkout/review/details/PaymentNet30Details';
import PaymentOnAccountDetails from 'modules/checkout/review/details/PaymentOnAccountDetails';
import PaymentAdditionalDetails from 'modules/checkout/review/details/PaymentAdditionalDetails';
import PaymentPaypalDetails from 'modules/checkout/review/details/PaymentPaypalDetails';
import t from 'modules/i18n/intl';
import type { HamsCart } from 'modules/cart/duck/model';
import * as payment from 'modules/checkout/payment';
import type { PurchaseMode } from 'modules/settings/purchaseMode';

type Props = {
  cart: HamsCart,
  purchaseMode: PurchaseMode,
  onPaymentDetailsEdit: () => void,
  onRemovePayment: () => void,
};

const PaymentMethodDetailsComponent = (props: Props) => {
  const checkoutMode = payment.api.storage.checkoutMode.load();
  let paymentDetailsComponent = null;

  if (props.purchaseMode === 'BUY') {
    // we don't need a default case in this switch
    // eslint-disable-next-line default-case
    switch (checkoutMode) {
      case payment.model.CheckoutModes.STRIPE_CREDIT_CARD:
      case payment.model.CheckoutModes.CREDIT_CARD:
        paymentDetailsComponent = <PaymentCreditCardDetails />;
        break;
      case payment.model.CheckoutModes.BANK_TRANSFER:
        paymentDetailsComponent = <PaymentBankDetails />;
        break;
      case payment.model.CheckoutModes.PAY_ON_TERMS:
        paymentDetailsComponent = <PaymentNet30Details />;
        break;
      case payment.model.CheckoutModes.PAY_ON_ACCOUNT:
        paymentDetailsComponent = <PaymentOnAccountDetails />;
        break;
      case payment.model.CheckoutModes.PAYPAL:
        paymentDetailsComponent = <PaymentPaypalDetails onRemovePayment={props.onRemovePayment} />;
        break;
    }
  }

  return (
    <Column>
      <Section>
        {paymentDetailsComponent ? (
          <>
            <Header>
              {t('review.payment-method')}
              &nbsp;
              <EditButton onClick={props.onPaymentDetailsEdit} />
            </Header>
            {paymentDetailsComponent}
          </>
        ) : null}
        <PaymentAdditionalDetails {...props} />
      </Section>
    </Column>
  );
};

export default PaymentMethodDetailsComponent;
