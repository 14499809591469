// @flow
import React from 'react';
import styled from 'styled-components';
import type { MarketplaceEula } from 'modules/checkout/duck/model';
import { Dimensions, Font } from 'components/visuals';

type Props = {
  marketplaceEulas: MarketplaceEula[],
};

const Wrapper = styled.div`
  font-family: ${Font.family};
  font-size: 12px;
  padding-top: ${Dimensions.units(2)}px;
`;

const EulasList = styled.ul`
  list-style: none;
`;

const Link = styled.a`
  font-weight: 500;
`;

const ThirdPartyTerms = ({ marketplaceEulas }: Props) => {
  const eulas = marketplaceEulas.filter((eula) => eula.link !== null);
  if (eulas.length === 0) {
    return null;
  }
  return (
    <Wrapper>
      <p>I also accept the terms and conditions for the following third-party products:</p>
      <EulasList>
        {eulas.map((eula) => (
          <li key={eula.name}>
            <Link href={eula.link} target="_blank" rel="noopener noreferrer">
              {eula.name}
            </Link>
          </li>
        ))}
      </EulasList>
    </Wrapper>
  );
};
export default ThirdPartyTerms;
