import Progress from 'redux-progress';
import * as actions from 'modules/auth/actions';

export default (state = Progress.none, action) => {
  switch (action.type) {
    case actions.FETCH_XSRF_TOKEN:
      return action.progress;
    default:
      return state;
  }
};
