import React from 'react';
import styled from 'styled-components';

const Wrapped = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const AppBody = (props) => <Wrapped {...props} />;

export default AppBody;
