import Progress from 'redux-progress';

import { BTFAccountAction, BTFData } from '../models/btfAccount';
import {
  IS_BTF_ALREADY_USED_FOR_CMT,
  MANAGE_APP_LINK_FOR_BTF_UNDER_CMT,
  SITE_NAME_WITH_DOMAIN_FOR_BTF_UNDER_CMT,
} from 'modules/mac/duck/actions/btfAccountId';

export const initialState: BTFData = {
  isBtfAlreadyUsedForCMT: Progress.none,
  manageAppLinkForBtfUnderCmt: Progress.none,
  siteNameWithDomainForBtfUnderCmt: Progress.none,
};

export default (state: BTFData = initialState, action: BTFAccountAction): BTFData => {
  switch (action.type) {
    case IS_BTF_ALREADY_USED_FOR_CMT:
      return {
        ...state,
        isBtfAlreadyUsedForCMT: action.progress,
      };
    case MANAGE_APP_LINK_FOR_BTF_UNDER_CMT:
      return {
        ...state,
        manageAppLinkForBtfUnderCmt: action.progress,
      };
    case SITE_NAME_WITH_DOMAIN_FOR_BTF_UNDER_CMT:
      return {
        ...state,
        siteNameWithDomainForBtfUnderCmt: action.progress,
      };
    default:
      return state;
  }
};
