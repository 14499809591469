/* eslint-disable import/prefer-default-export */

import * as contacts from 'modules/checkout/contacts/duck/actions/route';
import * as payment from 'modules/checkout/payment/duck/actions/route';
import * as quote from 'modules/checkout/quote/duck/actions/route';
import * as review from 'modules/checkout/review/duck/actions/route';
import { selectPurchaseMode } from 'modules/settings/purchaseMode';
import { CartThunk } from 'model/State';

export const routeToPaymentOrQuote = (): CartThunk<Promise<any>> => (dispatch, getState) => {
  if (selectPurchaseMode(getState()) === 'QUOTE') {
    return dispatch(quote.route());
  }
  return dispatch(payment.route());
};

export const goNext = (): CartThunk<Promise<any>> => (dispatch, getState) => {
  const state = getState();
  const { steps } = state.checkout;

  if (steps.contact && !steps.contact.complete) {
    return dispatch(contacts.route());
  }

  if (steps.payment && !steps.payment.complete) {
    return dispatch(routeToPaymentOrQuote());
  }

  return dispatch(review.route());
};
