// @flow
import React from 'react';

import { Subheader, Section } from 'modules/checkout/review/details/Commons';
import t from 'modules/i18n/intl';

const PayOnTermsInstructions = () => <p>{t('review.pay-on-terms.instructions')}</p>;

const PaymentNet30Details = () => (
  <Section>
    <Subheader>{t('review.pay-on-terms')}</Subheader>
    <PayOnTermsInstructions />
  </Section>
);

export default PaymentNet30Details;
