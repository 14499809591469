/* eslint-disable consistent-return */
import { Middleware, AnyAction } from 'redux';
import { CartAnalyticsClient } from 'modules/analytics';
import { UI_EVENT, OPERATIONAL_EVENT, SCREEN_EVENT, TRACK_EVENT } from 'modules/analytics/actions';

const createAnalyticsMiddleware = (analyticsClient: CartAnalyticsClient): Middleware => () => (
  next,
) => (action: AnyAction) => {
  switch (action.type) {
    case UI_EVENT:
      analyticsClient.sendUIEvent(action.payload);
      break;
    case OPERATIONAL_EVENT:
      analyticsClient.sendOperationalEvent(action.payload);
      break;
    case SCREEN_EVENT:
      analyticsClient.sendScreenEvent(action.payload);
      break;
    case TRACK_EVENT:
      analyticsClient.sendTrackEvent(action.payload);
      break;
    default:
      return next(action);
  }
};

export default createAnalyticsMiddleware;
/* eslint-enable consistent-return */
