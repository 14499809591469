import styled from 'styled-components';
import { colors } from '@atlaskit/theme';

type WithFontSize = {
  fontSize?: string;
};

const CellSubLine = styled.div`
  color: ${colors.N400};
  font-size: ${(props: WithFontSize) => props.fontSize || '12px'};
  font-weight: 400;
  position: relative;
  top: -4px;
`;

export default CellSubLine;
