import { MACLicensesPage } from './LicensesPage';
import { MACLicensesPreselectedPage } from './LicensesPreselectedPage';
import { MACOrdersPage } from './OrdersPage';
import { MACConfigUserPage } from './ConfigUserPage';
import { MACEditDetailsPage } from './EditDetailsPage';
import { MACLicenseEvaluationPage } from './LicenseEvaluationPage';
import { MACPaymentDetailsPage } from './PaymentDetailsPage';
import { MACUpdateCreditCardPage } from './UpdateCreditCardPage';
import macReducer from './duck/reducer';

export * from './duck/actions';
export {
  MACLicensesPage,
  MACLicensesPreselectedPage,
  MACOrdersPage,
  MACConfigUserPage,
  MACEditDetailsPage,
  MACLicenseEvaluationPage,
  MACPaymentDetailsPage,
  MACUpdateCreditCardPage,
};

export default macReducer;
