/* eslint-disable react/no-unused-prop-types */
// @flow
import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';

import AtlassianEulaCheckbox from 'modules/checkout/review/eula/AtlassianEulaCheckbox';
import ThirdPartyEulas from 'modules/checkout/review/eula/ThirdPartyTerms';
import type { CheckoutReview, MarketplaceEula } from 'modules/checkout/review/duck/model';
import SubmitButton from 'modules/checkout/review/SubmitButton';
import SubmitError from 'modules/checkout/review/SubmitError';
import { PayOnTermsIneligibleError } from 'modules/checkout/review/PayOnTermsIneligibleError';
import TimeoutWarning from 'modules/checkout/review/TimeoutWarning';
import PaymentProcessingMessage from './PaymentProcessingMessage';
import type { PurchaseMode } from 'modules/settings/purchaseMode';
import type { HamsCart } from 'modules/cart/duck/model';
import { Dimensions, Font } from 'components/visuals';
import config from 'appconfig';
import t from 'modules/i18n/intl';
import * as payment from 'modules/checkout/payment';

import { CartAnalyticsWrapper } from 'modules/analytics';
import macLoginURITransformer from 'util/macLoginURITransformer';

export type ActionLineProps = {
  cart: HamsCart,
  reviewState: CheckoutReview,
  marketplaceEulas: MarketplaceEula[],
  onEulaAcceptedChange: () => void,
  onSubmit?: (() => void) | null,
  submissionRef?: (submissionElement: HTMLElement) => void,
  onResetCart: () => void,
  processing?: boolean,
  purchaseMode: PurchaseMode,
  inSiteConfiguratorMode: boolean,
  currentContactEmail: string | null,
};

const Wrapper = styled.div`
  margin-top: ${Dimensions.units(5)}px;
  padding-top: ${Dimensions.units(2)}px;
  border-top: 1px solid ${colors.N50};
  text-align: right;
`;

const SubAction = styled.div`
  font-family: ${Font.family};
  font-size: 16px;
  padding-top: ${Dimensions.units(1)}px;

  a {
    font-weight: 500;
  }
`;

const CancelChanges = (props: ActionLineProps) => {
  const macLink = macLoginURITransformer({
    url: `${config.macUrl}/billing/quotes?cancelled=true`,
    currentContactEmail: props.currentContactEmail,
  });

  return (
    <SubAction>
      {t('review.or')}
      &nbsp;
      <a href={macLink} onClick={props.onResetCart}>
        {t('review.cancel-changes')}
      </a>
    </SubAction>
  );
};

const inProgress = (state) => state.submitProgress.inProgress || state.orderStatus.inProgress;

const ActionLine = (props: ActionLineProps) => {
  const {
    purchaseMode,
    onResetCart,
    reviewState,
    onSubmit,
    cart,
    marketplaceEulas,
    processing = false,
    submissionRef,
  } = props;

  const { isPayOnTermsEligible, currency, inEditMode } = cart;

  const checkoutMode = payment.api.storage.checkoutMode.load();

  let isOrderPayOnTermsEligible = true;

  if (purchaseMode === 'BUY' && checkoutMode === payment.model.CheckoutModes.PAY_ON_TERMS) {
    isOrderPayOnTermsEligible = isPayOnTermsEligible;
  }

  return (
    <CartAnalyticsWrapper>
      <Wrapper>
        {!isOrderPayOnTermsEligible && <PayOnTermsIneligibleError currency={currency} />}
        <SubmitError {...props} onResetCart={onResetCart} />
        <TimeoutWarning {...reviewState} />
        {purchaseMode === 'BUY' ? (
          <PaymentProcessingMessage isInProgress={inProgress(reviewState) || onSubmit === null} />
        ) : null}
        <AtlassianEulaCheckbox
          {...props}
          eulaAccepted={reviewState.eulaAccepted}
          isDisabled={!isOrderPayOnTermsEligible}
        />
        <ThirdPartyEulas cart={cart} marketplaceEulas={marketplaceEulas} />
        <SubmitButton
          eulaAccepted={reviewState.eulaAccepted}
          purchaseMode={purchaseMode}
          isDisabled={!isOrderPayOnTermsEligible}
          ref={submissionRef}
          {...props}
          processing={inProgress(reviewState) || processing}
        />
        {inEditMode ? <CancelChanges {...props} /> : null}
      </Wrapper>
    </CartAnalyticsWrapper>
  );
};

export default ActionLine;
