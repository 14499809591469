import { thunkProgress } from 'redux-progress';
import fetchCountryDetailsFromHams from 'api/data/fetchCountryDetails';
import type { Dispatch, GetState } from 'model/State';

export const FETCH_DETAILS = 'data/countries/FETCH_DETAILS';
export const fetchMissingDetails = (isoCountryCode: string) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const country = getState().data.countries.details[isoCountryCode];
  const allCountries = getState().data.countries.all?.result;
  const isIsoCountryCodeInAllCountries = allCountries?.some(
    (currentCountry) => currentCountry.isoCountryCode === isoCountryCode,
  );

  if ((!country || !(country.inProgress || country.success)) && isIsoCountryCodeInAllCountries) {
    dispatch(
      thunkProgress(FETCH_DETAILS, fetchCountryDetailsFromHams(isoCountryCode), {
        isoCountryCode,
      }),
    );
  }
};
