// @flow
import { connect } from 'react-redux';

import { selectors, actions } from 'modules/configure';
import type { Dispatch, State } from 'model/State';
import {
  makeSelectProductTiers,
  makeSelectProductName,
  makeSelectProductDescription,
  makeIsMarketplaceAddon,
  makeSelectProductMoreLink,
} from 'modules/catalog/pricing';
import ProductListRow, {
  type OwnProps,
  type StateProps,
  type DispatchProps,
} from 'components/common/ProductListRow';
import selectCurrency from 'modules/cart/duck/selectors/currency';
import { getProduct } from '../../../data/products';

const makeMapStateToProps = () => {
  const selectProductTiers = makeSelectProductTiers();
  const selectAddonTier = selectors.makeSelectAddonTier();
  const selectProductName = makeSelectProductName();
  const selectProductDescription = makeSelectProductDescription();
  const isMarketplaceAddon = makeIsMarketplaceAddon();
  const selectProductExternalUrl = makeSelectProductMoreLink();

  return (state: State, ownProps: OwnProps): StateProps => ({
    currency: selectCurrency(state).result,
    productName: selectProductName(state, ownProps),
    selected: selectors.isProductSelected(state, ownProps),
    tiers: selectProductTiers(state, ownProps),
    tier: selectAddonTier(state, ownProps),
    child: selectors.isChildProduct(state, ownProps),
    subscription: selectors.isSubscription(state, ownProps),
    academic: selectors.isAcademic(state),
    expanded: selectors.isExpanded(state, ownProps),
    description: selectProductDescription(state, ownProps),
    marketplaceAddon: isMarketplaceAddon(state, ownProps),
    externalUrl: selectProductExternalUrl(state, ownProps),
    additionalTierLabel: getProduct(ownProps.productKey)
      ? getProduct(ownProps.productKey).additionalTierLabel
      : null,
  });
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
  onAddonToggle: () => dispatch(actions.toggleAddon(ownProps.productKey)),
  onTierSelect: (productKey, tier) => dispatch(actions.selectProductTier(productKey, tier)),
  onAddonExpand: () => dispatch(actions.toggleAddonExpanded(ownProps.productKey)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(ProductListRow);
