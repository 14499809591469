import { AxiosRequestConfig } from 'axios';
import hamsClient from 'modules/hams';

export const updateCartItem = (
  hamsClientConfig: AxiosRequestConfig,
  cartUUID: string,
  itemId: number,
  sku: string,
  unitCount: number,
  setCartError,
) => {
  if (cartUUID === '') {
    return Promise.reject(new Error('Empty CartID'));
  }

  return hamsClient
    .put(
      `/1.0/public/order/cart/${cartUUID}/items/${itemId}/orderable`,
      {
        sku,
        unitCount,
      },
      hamsClientConfig,
    )
    .then((r) => {
      if (r.status === 200) {
        setCartError(false);
        return r.data;
      }
      return null;
    })
    .catch(() => setCartError(true));
};
