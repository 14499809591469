import braintree from 'braintree-web';
import hamsClient from 'modules/hams';

const generateClientToken = async (currency) =>
  (
    await hamsClient.post(
      `/1.0/public/order/cart/generateBraintreeClientToken?currency=${currency}`,
    )
  ).data.token;

export default async (currency) => {
  const clientToken = await generateClientToken(currency);
  const client = await braintree.client.create({ authorization: clientToken });
  return braintree.paypalCheckout.create({ client });
};
