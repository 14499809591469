type PromiseFunction = (params: Record<string, string | number | boolean>) => Promise<unknown>;

export const fetchWithParamValidation = (
  func: PromiseFunction,
  params: Parameters<PromiseFunction>[0],
) => {
  const paramsValid = Object.values(params).every((param) => {
    if (typeof param === 'string') return param !== '';
    if (typeof param === 'number') return true;
    if (typeof param === 'boolean') return true;

    return !!param;
  });

  if (!paramsValid) return Promise.resolve(undefined);

  return func(params);
};
