import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { HamsAccountMigrationInfo } from './api';
import { IAnalyticsContext } from 'modules/migrate/analytics/AnalyticsContext';
import TableWrapper from 'modules/migrate/comparator/TableWrapper';
import AccountRow from 'modules/migrate/comparator/row/AccountRow';
import TableHeader from 'modules/migrate/comparator/TableHeader';
import TableFooter from 'modules/migrate/comparator/TableFooter';
import ErrorBanner from 'modules/migrate/comparator/ErrorBanner';
import { SearchSenBar } from 'modules/migrate/comparator/SearchSENBar';
import { CollapseSection } from 'modules/migrate/comparator/CollapseSection';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { itemMapper } from './AccountsTable';

export interface HamsAccountMigrationInfoWithEmail extends HamsAccountMigrationInfo {
  ownerEmail?: string;
}

interface Props {
  items: HamsAccountMigrationInfoWithEmail[] | null;
  error: Error | null;
  showLoadMore: boolean;
  loadNextPage: () => void;
  loading: boolean;
  analytics: IAnalyticsContext;
  summaryProductsNumberInitial: number;
  isPartner: boolean;
}

const TableOuterBordersWrapper = styled.div`
  border-top: 2px solid ${colors.N700};
  border-bottom: 2px solid ${colors.N700};
`;
const OuterTableWrapper = styled.div``;
export const AccountsTableUI = ({
  items = [],
  error,
  showLoadMore = false,
  loadNextPage,
  loading,
  analytics,
  summaryProductsNumberInitial,
  isPartner,
}: Props) => {
  const [foundBySENItems, setFoundBySENItems] = useState<HamsAccountMigrationInfoWithEmail[]>([]);
  const [showSearchBySENInitial, setShowSearchBySENInitial] = useState<boolean>(false);
  const [showProductsAndAppsInitial, setShowProductsAndAppsInitial] = useState<boolean>(true);
  const [summaryProductsNumber, setSummaryProductsNumber] = useState<number>(
    summaryProductsNumberInitial,
  );

  useEffect(() => {
    setShowSearchBySENInitial(isPartner || !items || items.length === 0);
  }, [items]);

  const intl = useIntl();

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'document.title.cloud-price-comparator' });
  }, []);

  const itemsToDisplay: HamsAccountMigrationInfoWithEmail[] = [
    ...foundBySENItems.map(itemMapper),
    ...(items || []),
  ];
  const currentNumber = useMemo(() => itemsToDisplay?.length || 0, [itemsToDisplay]);
  const currency = useMemo(() => itemsToDisplay?.[0]?.currency || 'USD', [itemsToDisplay]);

  const [allAmounts, updateAllAmounts] = useState<number[]>([]);
  const totalAmount = useMemo(
    () => allAmounts.reduce((acc, current) => (current ? acc + current : acc), 0),
    [allAmounts],
  );

  return (
    <>
      <OuterTableWrapper data-cy-outer-wrapper>
        <TableWrapper>
          <>
            <TableHeader />
            <TableOuterBordersWrapper>
              <CollapseSection
                title="migrate.comparator.table.section.search-by-sen"
                isShown={showSearchBySENInitial}
                setIsShown={setShowSearchBySENInitial}
              >
                {setFoundBySENItems && (
                  <SearchSenBar
                    foundBySENItems={foundBySENItems}
                    itemsToDisplay={itemsToDisplay}
                    setFoundBySENItems={setFoundBySENItems}
                    summaryProductsNumberInitial={summaryProductsNumberInitial}
                    setSummaryProductsNumber={setSummaryProductsNumber}
                    showProductsAndAppsInitial={showProductsAndAppsInitial}
                    setShowProductsAndAppsInitial={setShowProductsAndAppsInitial}
                    updateAllAmounts={updateAllAmounts}
                  />
                )}
              </CollapseSection>
              <CollapseSection
                title="migrate.comparator.table.section.products-and-apps"
                isShown={showProductsAndAppsInitial}
                setIsShown={setShowProductsAndAppsInitial}
                disabled={itemsToDisplay.length === 0}
              >
                {itemsToDisplay?.map((account, index) => (
                  <AccountRow
                    account={account}
                    key={account.accountId}
                    productTableIndex={index}
                    updateAllAmounts={updateAllAmounts}
                  />
                ))}
              </CollapseSection>
            </TableOuterBordersWrapper>
            <TableFooter
              summaryProductsNumber={summaryProductsNumber}
              currentNumber={currentNumber}
              showLoadMore={showLoadMore}
              loadNextPage={loadNextPage}
              loading={loading}
              analytics={analytics}
              totalAmount={totalAmount}
              currency={currency}
            />
          </>
        </TableWrapper>
      </OuterTableWrapper>
      {error && <ErrorBanner error={error} />}
    </>
  );
};
