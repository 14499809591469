/* eslint-disable react/jsx-no-target-blank */
// @flow
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from '@atlaskit/checkbox';
import { colors } from '@atlaskit/theme';
import { Header, Paragraph, RealignCheckbox, Subtle } from 'modules/checkout/styled';
import type { AutoRenewalProps } from 'modules/checkout/payment/duck/model';
import t from 'modules/i18n/intl';
import config from 'appconfig';
import TierLabel from 'components/common/TierLabel';
import LicenseTypeLabel from 'components/common/LicenseTypeLabel';
import { Dimensions } from 'components/visuals';
import { LICENSE_COMMERCIAL } from 'model/hams/LicenseType';
import macLoginURITransformer from 'util/macLoginURITransformer';

const Wrapper = styled.div`
  flex: 100%;
  border-top: 1px solid ${colors.N50};
  padding: ${Dimensions.units(4)}px;
`;

const RenewalList = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  padding-left: 0px;
`;

const RenewalItem = styled.li`
  width: 50%;
  min-height: ${Dimensions.units(10)}px;
`;

const ItemTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;
const ItemSubtitle = styled.div`
  font-size: 14px;
  color: ${colors.N90};
`;

const LoginToMyAtlassian = ({ hrefUrl }: { hrefUrl: string }) => (
  <a target="_blank" href={hrefUrl}>
    {t('payment.login-to-my')}
  </a>
);

export default (props: AutoRenewalProps) =>
  props.isAvailable && (
    <Wrapper data-cy="auto-renew-items">
      <Header>
        {t('payment.auto-renew.title')} <Subtle>({t('payment.optional')})</Subtle>
      </Header>
      <Paragraph>
        {t('payment.auto-renew.license-includes-fixed-maintenance-period')}
        <a
          href={`${config.wacUrl}/licensing/purchase-licensing#softwaremaintenance-1`}
          target="_blank"
        >
          {t('payment.auto-renew.maintenance-learn-more')}
        </a>
      </Paragraph>
      <Paragraph>{t('payment.auto-renew.description')}</Paragraph>
      <Paragraph>
        <FormattedMessage
          id="payment.auto-renew.update-settings"
          values={{
            login: (
              <LoginToMyAtlassian
                hrefUrl={macLoginURITransformer({
                  url: config.macUrl,
                  currentContactEmail: props.currentContactEmail,
                })}
              />
            ),
          }}
        />
      </Paragraph>
      {props.items.length > 1 && (
        <RealignCheckbox>
          <Checkbox
            label={t('payment.agree-to-renew-all-products')}
            isChecked={props.items.every((i) => i.selected)}
            onChange={(e) => props.selectAll(e.target.checked)}
          />
        </RealignCheckbox>
      )}
      <RenewalList>
        {props.items.map((item) => (
          <RenewalItem key={item.id}>
            <RealignCheckbox>
              <Checkbox
                label={
                  <>
                    <ItemTitle>{item.productDetails.productDescription}</ItemTitle>
                    <ItemSubtitle>
                      {item.showTierLabel && (
                        <>
                          <TierLabel {...item.productDetails} />
                          &nbsp;•&nbsp;
                        </>
                      )}
                      Renews on:&nbsp;
                      {item.renewDate.toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </ItemSubtitle>
                    {item.productDetails.licenseType !== LICENSE_COMMERCIAL && (
                      <ItemSubtitle>
                        <LicenseTypeLabel licenseType={item.productDetails.licenseType} />
                      </ItemSubtitle>
                    )}
                    {item.accountId && (
                      <ItemSubtitle>
                        {item.accountName}/SEN-{item.accountId}
                      </ItemSubtitle>
                    )}
                  </>
                }
                isChecked={item.selected}
                onChange={(e) => props.selectItem(e.target.checked, item.id)}
              />
            </RealignCheckbox>
          </RenewalItem>
        ))}
      </RenewalList>
    </Wrapper>
  );
