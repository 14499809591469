/* eslint-disable react/jsx-no-target-blank */

import React from 'react';
import { Field } from '@atlaskit/form';
import styled from 'styled-components';
import config from 'appconfig';
import { WithNoSubmitFieldTextStateless } from '../../components/common/WithNoSubmitTextbox';
import Subtext from './Subtext';

const FieldWidth = styled.div`
  width: 75%;
`;

type Props = {
  value: string;
  onChange: (arg0: string) => void;
  // eslint-disable-next-line react/require-default-props
  className?: string;
};

export default (props: Props) => (
  <div className={props.className}>
    <FieldWidth>
      <Field name="add-a-po-number" label="Add a PO number">
        {({ fieldProps }) => (
          <WithNoSubmitFieldTextStateless
            {...fieldProps}
            value={props.value}
            onChange={(e) => props.onChange((e.target as HTMLInputElement).value)}
          />
        )}
      </Field>
    </FieldWidth>
    <Subtext>
      Purchase orders <strong>are not accepted</strong> as a form of payment and we cannot accept
      any conflicting terms and conditions associated with the purchase order.&nbsp;
      <a target="_blank" href={`${config.wacUrl}/licensing/purchase-licensing#ordering-4`}>
        Learn more
      </a>
    </Subtext>
  </div>
);
