// @flow
import { createSelector } from 'reselect';

import { selectMainProductKey } from 'modules/configure/duck/selectors/mainProduct';
import { selectSelectedAddonKeys, isChildProduct } from 'modules/configure/duck/selectors/addons';
import { getPerProductConfiguration } from 'modules/configure/duck/selectors/productConfiguration';
import type { ContainsProductKey } from 'modules/configure/duck/selectors';
import { makeSelectDefaultTier, makeSelectProductTiers } from 'modules/catalog/pricing';
import ProductTier from 'model/hams/ProductTier';
import type { Selector } from 'model/State';

const getConfiguredTier: Selector<ProductTier | null, ContainsProductKey> = (state, props) => {
  const config = getPerProductConfiguration(state, props);
  return config ? config.tier : null;
};

export const makeSelectProductTierForProduct = (): Selector<
  ProductTier | null,
  ContainsProductKey,
> =>
  createSelector(
    getConfiguredTier,
    makeSelectDefaultTier(),
    (configuredTier, defaultTier) => configuredTier || defaultTier || null,
  );

const selectMainProductTier: Selector<ProductTier | null> = (state) => {
  const productKey = selectMainProductKey(state);
  return productKey ? makeSelectProductTierForProduct()(state, { productKey }) : null;
};

export const makeSelectAddonTier = (): Selector<ProductTier | null, ContainsProductKey> =>
  createSelector(
    makeSelectProductTiers(),
    selectMainProductTier,
    makeSelectProductTierForProduct(),
    isChildProduct,
    (tiers, mainTier, tier, child) =>
      (!child || !mainTier
        ? tier
        : tiers.ifSuccess((data) => data.find((t) => t.unitCount === mainTier.unitCount))) || null,
  );

export const selectProductAndSelectedAddonTiers: Selector<ProductTier[]> = (state) =>
  [
    // $FlowFixMe main product tier could be null, but we filter them out below
    selectMainProductTier(state),
    // $FlowFixMe addon tiers could be null, but we filter them out below
    ...selectSelectedAddonKeys(state).map((productKey) =>
      makeSelectAddonTier()(state, { productKey }),
    ),
  ].filter((t) => t !== null);
