// @flow
import type { MapToProps } from 'util/react-redux/connectStateAndDispatch';
import Progress from 'redux-progress';
import type { PaymentPageProps } from 'modules/checkout/payment/duck/model/PaymentPageProps';
import * as actions from 'modules/checkout/payment/duck/actions';
import getIsTaxedUsOrder from 'modules/checkout/payment/duck/selectors/getIsTaxedUsOrder';
import getCanSubmit from 'modules/checkout/payment/duck/selectors/getCanSubmit';
import getCashProps from 'modules/checkout/payment/duck/selectors/getCashProps';
import getCreditCardProps from 'modules/checkout/payment/duck/selectors/getCreditCardProps';
import getStripeCreditCardProps from 'modules/checkout/payment/duck/selectors/stripe';
import getPaypalProps from 'modules/checkout/payment/duck/selectors/getPaypalProps';
import getAutoRenewalProps from 'modules/checkout/payment/duck/selectors/getAutoRenewalProps';
import { isCartAFreeOrder } from 'modules/cart/duck/selectors';
import * as additionalDetails from 'modules/checkout/additionalDetails';
import { PurchaseModes, setPurchaseMode } from 'modules/settings/purchaseMode';
import { getCurrentContactDetails } from 'modules/auth/selectors/contactDetails';
import getAvailablePaymentMethods from './getAvailablePaymentMethods';

import { selectInSiteConfiguratorMode } from 'modules/siteconfigurator/duck/selectors';

const getPageProps: MapToProps<Progress<?PaymentPageProps>> = (state, dispatch) =>
  Progress.all(
    state.cart.payload,
    getCreditCardProps(state, dispatch),
    getStripeCreditCardProps(state, dispatch),
    getCashProps(state, dispatch),
    getPaypalProps(state, dispatch),
    getAutoRenewalProps(state, dispatch),
    getAvailablePaymentMethods(state),
    getCurrentContactDetails(state),
  ).map(
    ([
      cart,
      creditCard,
      stripeCreditCard,
      cash,
      paypal,
      autoRenewal,
      availablePaymentMethods,
      currentContact,
    ]) =>
      cart && {
        availablePaymentMethods,
        checkoutMode: state.checkout.payment.checkoutMode,
        totalIncTax: cart.totalIncTax,
        creditCardOnly: cart.creditCardOnly,
        billingIsoCountryCode: cart.billingOrganisationDetails.isoCountryCode,
        currency: cart.currency,
        isFreeOrder: isCartAFreeOrder(cart),
        creditCard,
        stripeCreditCard,
        paypal,
        cash,
        autoRenewal,
        additionalDetails: additionalDetails.getProps(state, dispatch),
        showAdditionalDetails: cart.status !== 'QUOTED' || cart.inEditMode,
        canSubmit: getCanSubmit(state),
        submission: state.checkout.payment.submission,
        taxedUsOrder: getIsTaxedUsOrder(state),
        selectMode: (method) => dispatch(actions.selectMode(method)),
        submit: () => dispatch(actions.submit()),
        switchToQuote: () => dispatch(setPurchaseMode(PurchaseModes.QUOTE)),
        cardError: String(state.location.query.cardError) === 'true',
        ccErrorMessage: state.location.query.ccErrorMessage,
        inSiteConfiguratorMode: selectInSiteConfiguratorMode(state),
        currentContactEmail: currentContact?.contactDetails.email || null,
      },
  );

export default getPageProps;
