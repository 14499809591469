import Progress, { thunkProgress } from 'redux-progress';
import { hideHeader } from 'actions/navigation/header';
import { CartThunk } from 'model/State';
import { FETCH_SECONDARY_CART, fetchCart } from 'modules/cart/duck/actions/fetchOrCreate';
import sanitiseUUID from 'util/uuid';
import { setOriginalCartItemDetails } from '../actions';
import watchProgress from 'model/watchProgress';
import { PurchaseModes, setPurchaseMode } from 'modules/settings/purchaseMode';
import { HamsCart } from 'modules/cart/duck/model';
import { OriginalItemDetails } from '../model';
import { selectBiennialCartUUID } from '../selectors';
import { setCartFormValidationState, setInSiteConfiguratorMode } from './setCart';
import { getCartProductItems, getEdition } from '../../utils';
import { CurrentContact } from 'modules/auth/model';
import * as cartActions from 'modules/cart/duck/actions';
import setCartDetailsToHams from 'modules/cart/duck/api/setDetails';
import { selectors } from 'modules/auth';
import FormValidationError from 'model/hams/FormValidationError';

import { reportLogOnly } from 'util/errorReporter';

export const routeThunk: CartThunk = () => async (dispatch, getState) => {
  const state = getState();

  async function setCartDetails(payload) {
    (await dispatch(cartActions.setCartDetails(payload))).unwrap();
  }

  const setNewCartPayload = async (
    cart: HamsCart,
    currentUserContact: CurrentContact | null,
    isBiennialCart: boolean = false,
  ) => {
    const cleanedPhoneNumber = (val: any) => {
      const autoPopulatedValue = '-';
      if (!val) {
        return autoPopulatedValue;
      }
      if (typeof val === 'string' && val.trim().length === 0) {
        return autoPopulatedValue;
      }
      return val;
    };

    const updatedBCPhone = cleanedPhoneNumber(cart.billingContactDetails.phone);
    const updatedTCPhone = cleanedPhoneNumber(cart.technicalContactDetails.phone);
    const isUpdatedBCPhone = cart.billingContactDetails.phone !== updatedBCPhone;
    const isUpdatedTCPhone = cart.technicalContactDetails.phone !== updatedTCPhone;

    // Always populate the phone no field
    const newCart = {
      ...cart,
      billingContactDetails: {
        ...cart.billingContactDetails,
        phone: updatedBCPhone,
      },
      technicalContactDetails: {
        ...cart.technicalContactDetails,
        phone: updatedTCPhone,
      },
      purchaserContactDetails: {
        ...cart.purchaserContactDetails,
      },
    };

    // only populate email if logged in
    if (currentUserContact) {
      newCart.purchaserContactDetails.email = currentUserContact.contactDetails.email;
    }

    if (isBiennialCart) {
      const promise = setCartDetailsToHams(
        newCart,
        selectors.selectHamsClientConfig(getState()),
        getState,
      );
      promise
        .then(() => {
          dispatch(setCartFormValidationState(Progress.success(null)));
        })
        .catch((error) => {
          if (error instanceof FormValidationError) {
            if (currentUserContact) {
              reportLogOnly('SiteConfiguratorFormValidationErrorLogged', error);
            } else {
              reportLogOnly('SiteConfiguratorFormValidationErrorNotLogged', {
                isUpdatedBCPhone,
                isUpdatedTCPhone,
                error,
              });
            }
            dispatch(setCartFormValidationState(Progress.success(error)));
          }
        });

      await dispatch(thunkProgress(FETCH_SECONDARY_CART, promise));
    } else {
      await setCartDetails(newCart);
    }
  };

  const fetchBiennialCartID = () => {
    const queryParamsBiennielUuid = selectBiennialCartUUID(state);
    return queryParamsBiennielUuid && sanitiseUUID(queryParamsBiennielUuid);
  };

  const setOriginalCartItems = async (cart: HamsCart) => {
    const originalCartItemDetails: OriginalItemDetails = getCartProductItems(cart).reduce(
      (mapping, item) => ({
        ...mapping,
        [item.id]: {
          edition: getEdition(item),
          tierCount: item.productDetails.unitCount,
        },
      }),
      {},
    );
    await dispatch(setOriginalCartItemDetails(originalCartItemDetails));
  };

  // This is on the siteconfigurator route, which is being loaded in an iframe in BUX
  // Hide the site header and footer for this flow
  await dispatch(hideHeader());
  await dispatch(setInSiteConfiguratorMode(true));
  await dispatch(setPurchaseMode(PurchaseModes.BUY));

  // check if the user is logged in, and if so, set the cart purchaserContact.email to the logged in user email
  const currentUserContact: CurrentContact | null = await watchProgress(
    (s) => s.auth.currentContact,
  );

  reportLogOnly('SiteConfiguratorFlowStarted', {
    annualUUID: state.location.query.uuid,
    biennialUUID: state.location.query.biennial_uuid,
  });

  // Set annual billing cart (already fetched) in store
  const annualBillingCart: HamsCart | null = await watchProgress(
    (storeState) => storeState.cart.payload,
  );

  if (annualBillingCart !== null) {
    // Store the itemIds from annual cart alongwith their edition and tierCount in the cart in the store
    await setOriginalCartItems(annualBillingCart);

    // Set the purchaserEmail to the currently logged in user (if logged in).
    // We can let this action be async, there's no need to wait for it to complete
    if (!currentUserContact) {
      setNewCartPayload(annualBillingCart, currentUserContact);
    }
  }

  // Fetch and set biennial billing cart in store
  const biennielUUID = fetchBiennialCartID();
  const biennialBillingCartProgress: Progress<HamsCart | null> = await dispatch(
    fetchCart(biennielUUID),
  );

  biennialBillingCartProgress.ifSuccess(async (cart) => {
    if (cart) {
      // Store the itemIds from biennial cart alongwith their edition and tierCount in the cart in the store
      await setOriginalCartItems(cart);

      // Set the purchaserEmail to the currently logged in user (if logged in)
      // We can let this action be async, there's no need to wait for it to complete
      if (currentUserContact) {
        dispatch(setCartFormValidationState(Progress.success(null)));
      } else {
        setNewCartPayload(cart, currentUserContact, true);
      }
    }
  });
};
