// @flow
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Font } from 'components/visuals';
import { colors } from '@atlaskit/theme';
import t from 'modules/i18n/intl';
import { LOYALTY_DISCOUNT_IDENTIFIER } from 'util/constants';
import { emitUIEpicEvent, utils } from 'modules/analytics';
import { UISource } from 'modules/analytics/model/UIEvent';
import type { RowProps } from 'modules/change/landing/duck/model';
import AmountWithCurrency from 'components/common/AmountWithCurrency';
import config from 'appconfig';

const Wrapper = styled.div`
  text-align: right;
  color: ${colors.N50};
`;

const CrossedOriginalAmount = styled.div`
  text-decoration: line-through;
`;

const PriceAdjustment = styled.div`
  font-size: 12px;
`;

const LoyaltyDiscountInfo = styled.span`
  font-family: ${Font.displayFamily};
`;

type DispatchProps = {
  loyaltyDiscountFaqClicked: () => void,
};

const PriceInfo = ({ entry, loyaltyDiscountFaqClicked }: RowProps & DispatchProps) => (
  <Wrapper>
    {entry.selectedOption.result &&
      entry.selectedOption.result.originalAmount > 0 &&
      entry.selectedOption.result.originalAmount !== entry.selectedOption.result.amount && (
        <div>
          <CrossedOriginalAmount>
            <AmountWithCurrency
              amount={entry.selectedOption.result.originalAmount}
              currency={entry.selectedOption.result.currency}
            />
          </CrossedOriginalAmount>
        </div>
      )}
    {entry.selectedOption.result && entry.selectedOption.result.adjustments.length > 0 && (
      <>
        {entry.selectedOption.result.adjustments
          .filter((adjustment) => adjustment.amount > 0)
          .map((adjustment, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <PriceAdjustment key={i}>
              {adjustment.description === LOYALTY_DISCOUNT_IDENTIFIER ? null : (
                <AmountWithCurrency
                  amount={adjustment.amount}
                  currency={entry.selectedOption.result.currency}
                />
              )}
              &nbsp;
              {adjustment.description === LOYALTY_DISCOUNT_IDENTIFIER ? (
                <LoyaltyDiscountInfo>
                  <a
                    href={`${config.wacUrl}/licensing/data-center-loyalty-discounts`}
                    target="_blank"
                    onClick={loyaltyDiscountFaqClicked}
                    rel="noopener noreferrer"
                  >
                    {t('upgrade.options.adjustment.loyalty-discount', {
                      percentageDiscount: adjustment.percentage,
                    })}
                  </a>
                </LoyaltyDiscountInfo>
              ) : (
                adjustment.description
              )}
            </PriceAdjustment>
          ))}
      </>
    )}
  </Wrapper>
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loyaltyDiscountFaqClicked: () => {
    dispatch(
      emitUIEpicEvent({
        data: utils.getLinkClickEventData('loyaltyPricingFaqLink', UISource.Banner),
        selectorsOrObjects: [
          {
            eventContainer: 'rowChangeOptions',
            eventComponent: 'loyaltyPricingPriceInfo',
            parentLabel: 'loyalty discount applied',
            label: 'DC Discount FAQ',
            uuid: '0456912b-c520-4adf-ab88-7f6b3ad756c6',
          },
        ],
      }),
    );
  },
});

export default connect(null, mapDispatchToProps)(PriceInfo);
