// @flow
import type { AxiosRequestConfig } from 'axios';
import hamsClient from 'modules/hams';
import { mapProductKeyToLegacyProductKey, ServerProductKeys } from 'data/products/keys';

type Options = {
  accountId?: string,
  clientConfig: AxiosRequestConfig,
};

export const doCheckCrossgradeAvailable = async ({
  accountId,
  clientConfig,
}: Options): Promise<boolean> => {
  const { data } = await hamsClient.get('/1.0/public/account/crossgrade/available', {
    ...clientConfig,
    params: { accountId },
  });
  return data;
};

const productsToShowCloudOption = [
  ServerProductKeys.BAMBOO,
  ServerProductKeys.JIRA_PORTFOLIO,
  ServerProductKeys.BITBUCKET,
];

export const fetchMigrateOptionsForAccount = (
  accountId: string,
  hamsClientConfig: AxiosRequestConfig,
) =>
  hamsClient
    .post(
      '/1.0/public/account/migrateOptions',
      { accountId },
      {
        ...hamsClientConfig,
        validateStatus: (status) => status === 404 || (status >= 200 && status < 300),
      },
    )
    .then((response) => ({
      cloudUpgradeAvailable:
        response.status === 404
          ? false
          : response.data.available ||
            productsToShowCloudOption.includes(
              mapProductKeyToLegacyProductKey(response.data.productKey),
            ),
      accountMigrateOptions: response.status === 404 ? null : response.data,
    }));

export const checkMigrateAvailableForUser = (
  hamsClientConfig: AxiosRequestConfig,
  offset = 0,
  limit = 25,
) =>
  hamsClient
    .get(`/1.0/public/account/migrate/list?offset=${offset}&limit=${limit}`, hamsClientConfig)
    .then((response) => {
      if (response.data.items.find((item) => item.available === true)) return true;

      if (
        response.data.items.length < limit ||
        response.data.items.length === response.data.totalItems
      )
        return false;

      return checkMigrateAvailableForUser(hamsClientConfig, offset + limit, limit);
    });
