// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Checkbox } from '@atlaskit/checkbox';

import TotalPrice from 'modules/checkout/payment/components/TotalPrice';
import { Paragraph, SubHeader, RealignCheckbox } from 'modules/checkout/styled';
import { CheckoutModes } from 'modules/checkout/payment/duck/model/';
import type { PaymentPageProps } from 'modules/checkout/payment/duck/model';
import t from 'modules/i18n/intl';

const WeAlsoAcceptBankCheckqueOrAch = ({ currency }: PaymentPageProps) =>
  currency !== 'JPY' ? (
    <>
      {' '}
      <FormattedMessage id="payment.we-also-accept-bank-cheque-or-ach" />
    </>
  ) : null;

const BankTransferOnlyContent = (props: PaymentPageProps) => {
  const { currency } = props;

  return (
    <>
      <SubHeader>{t('payment.bank-transfer-or-check.content.title', { currency })}</SubHeader>
      <Paragraph>
        <FormattedMessage
          id="payment.bank-transfer-or-check.content.description"
          values={{ currency }}
        />
        <WeAlsoAcceptBankCheckqueOrAch currency={currency} />
      </Paragraph>
      <TotalPrice {...props} />
    </>
  );
};

const PayOnAccountTitle = () => <FormattedMessage id="payment.pay-on-account.content.title" />;
const PayOnAccountDescription = ({ currency }: PaymentPageProps) => (
  <Paragraph>
    <FormattedMessage id="payment.pay-on-account.content.description" />
    <WeAlsoAcceptBankCheckqueOrAch currency={currency} />
  </Paragraph>
);

const PayOnAccountOnlyContent = (props: PaymentPageProps) => (
  <>
    <SubHeader>
      <PayOnAccountTitle />
    </SubHeader>
    <PayOnAccountDescription />
    <TotalPrice {...props} />
  </>
);

const PayOnTermsTermsAndConditions = ({ cash }: PaymentPageProps) => (
  <>
    <h4>{t('payment.net30.t&c')}</h4>
    <Paragraph>{t('payment.net30.advocate-review-pending')}</Paragraph>
    <Paragraph>{t('payment.net30.user-acceptance')}</Paragraph>
    <RealignCheckbox>
      <Checkbox
        label={t('payment.net30.t&c.checkbox.label')}
        isChecked={cash.payOnTermAgreed}
        onChange={(e) => cash.agreeToPayOnTerm(e.target.checked)}
      />
    </RealignCheckbox>
  </>
);

const PayOnTermsTitle = () => <FormattedMessage id="payment.net30.title" />;
const PayOnTermsDescription = ({ currency }: PaymentPageProps) => (
  <Paragraph>
    <FormattedMessage id="payment.pay-on-terms.content.description" />
    <WeAlsoAcceptBankCheckqueOrAch currency={currency} />
  </Paragraph>
);

const PayOnTermsOnlyContent = (props: PaymentPageProps) => (
  <>
    <SubHeader>
      <PayOnTermsTitle />
    </SubHeader>
    <PayOnTermsDescription {...props} />
    <TotalPrice {...props} />
    <PayOnTermsTermsAndConditions {...props} />
  </>
);

export type OptionalPaymentMethodProps = {
  title: any,
  targetCheckoutMode: any,
  actualCheckoutMode: any,
  label: any,
  children: any,
  selectMode: any,
  testId?: string,
};
const OptionalPaymentMethod = ({
  title,
  label,
  targetCheckoutMode,
  actualCheckoutMode,
  selectMode,
  children,
  testId,
}: OptionalPaymentMethodProps) => (
  <>
    <SubHeader>
      {title} (
      <em>
        <FormattedMessage id="payment.cash.optional" />
      </em>
      )
    </SubHeader>
    <RealignCheckbox>
      <Checkbox
        label={label}
        isChecked={targetCheckoutMode === actualCheckoutMode}
        onChange={(e: any) =>
          selectMode(e.target.checked ? targetCheckoutMode : CheckoutModes.BANK_TRANSFER)
        }
        testId={testId}
      />
    </RealignCheckbox>
    {actualCheckoutMode === targetCheckoutMode ? children : null}
  </>
);

const BankTransferWithMulitpleOptionsContent = (props: PaymentPageProps) => (
  <>
    <BankTransferOnlyContent {...props} />
    {props.cash.canPayOnAccount && (
      <OptionalPaymentMethod
        title={<PayOnAccountTitle />}
        label={<FormattedMessage id="payment.pay-on-account.checkbox.label" />}
        targetCheckoutMode={CheckoutModes.PAY_ON_ACCOUNT}
        actualCheckoutMode={props.checkoutMode}
        selectMode={props.selectMode}
      >
        <PayOnAccountDescription {...props} />
      </OptionalPaymentMethod>
    )}
    {props.cash.canPayOnTerm && (
      <OptionalPaymentMethod
        title={<PayOnTermsTitle />}
        label={
          <>
            <FormattedMessage id="payment.net30.checkbox.label" />
            {!props.inSiteConfiguratorMode ? (
              <FormattedMessage id="payment.net30.checkbox.label-license-key-text" />
            ) : (
              ''
            )}
          </>
        }
        targetCheckoutMode={CheckoutModes.PAY_ON_TERMS}
        actualCheckoutMode={props.checkoutMode}
        selectMode={props.selectMode}
        testId="cashOnTermsCheckbox"
      >
        <PayOnTermsTermsAndConditions {...props} />
      </OptionalPaymentMethod>
    )}
  </>
);

const TabContent = (props: PaymentPageProps) => {
  const { cash, checkoutMode, availablePaymentMethods, selectMode } = props;

  if (!cash.isSelected) {
    return null;
  }

  if (cash.multipleOptionsAvailable) {
    return (
      <div>
        <BankTransferWithMulitpleOptionsContent {...props} />
      </div>
    );
  }

  if (!availablePaymentMethods.onTerms && checkoutMode === CheckoutModes.PAY_ON_TERMS) {
    selectMode(CheckoutModes.PAYPAL);
  }

  switch (checkoutMode) {
    case CheckoutModes.BANK_TRANSFER:
      return (
        <div>
          <BankTransferOnlyContent {...props} />
        </div>
      );
    case CheckoutModes.PAY_ON_ACCOUNT:
      return (
        <div>
          <PayOnAccountOnlyContent {...props} />
        </div>
      );
    case CheckoutModes.PAY_ON_TERMS:
      return (
        <div>
          <PayOnTermsOnlyContent {...props} />
        </div>
      );
    default:
      throw new Error(`Unexpected cash checkout mode ${checkoutMode}`);
  }
};

export default TabContent;
