/* eslint-disable import/no-named-as-default */
/* eslint-disable react/no-unused-prop-types, react/prop-types */
// @flow

import React, { useContext } from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import connect from 'react-redux/es/connect/connect';
import CartItemButton from 'components/navigation/secondary/CartItemButton';
import PurchaseModeSwitch from 'components/navigation/secondary/PurchaseModeSwitch';
import t from 'modules/i18n/intl';
import { FormattedMessage } from 'react-intl';
import { LocationState } from 'redux-first-router';

import type { Dispatch, State } from 'model/State';
import routesMap from 'routes';

import Link from 'components/common/Link';
import NavLink from 'components/common/NavLink';
import { Font, Dimensions } from 'components/visuals';
import MaintenanceBanner from 'components/common/MaintenanceBanner';
import { goToProductList } from 'actions';
import config from 'appconfig';
import { selectPurchaseMode, setPurchaseMode } from 'modules/settings/purchaseMode';
import type { PurchaseMode } from 'modules/settings/purchaseMode';
import { actions as cartActions, selectors as cartSelectors } from 'modules/cart';
import { selectors as reviewSelectors, actions as reviewActions } from 'modules/checkout/review';
import * as upgrade from 'modules/upgrade';
import * as renew from 'modules/renew';

import { isCheckoutPage } from 'modules/checkout/selectors';
import { getComparatorUrl } from 'modules/migrate/comparator/utils';
import { getCurrentContactEmail } from 'modules/auth/selectors/contactDetails';
import macLoginURITransformer from 'util/macLoginURITransformer';

import { CartAnalyticsWrapper, CartAnalyticsContext } from 'modules/analytics';
import { analyticsEvents, AnalyticsTrigger } from 'components/common/analytics';

type StateProps = {
  displayMenu: boolean,
  purchaseMode: PurchaseMode,
  quoteNumber: string | null,
  inEditMode: boolean,
  isOutstandingPayment: boolean,
  cartItemCount: number,
  showLinkToReview: boolean,
  showPurchaseModeSelector: boolean,
  maintenanceEnabled: boolean,
  cartStatus: string,
  location: LocationState<RoutesMap>,
  currentContactEmail: string | null,
};

type DispatchProps = {
  onGoToCart: () => void,
  onPurchaseModeChange: (PurchaseMode) => void,
};

type Props = StateProps & DispatchProps;

type PropsWithMacLink = Props & { macBillingQuoteLink: string };

const Wrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.N50};
  padding-bottom: ${Dimensions.units(1)}px;
`;

const MenuList = styled.ul`
  margin: 0 0 ${Dimensions.units(1)}px auto;
  padding-left: 0px;
`;

const MenuItem = styled.li`
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 400;
  padding: 0 4px;
  color: ${colors.N800};
  margin-left: ${Dimensions.units(3)}px;
  font-family: ${Font.family};
  cursor: pointer;
  position: relative;

  > a:hover {
    text-decoration: underline !important;
  }

  ${(props) =>
    props.isActive &&
    `&::before {
      content: '';
      position: absolute;
      bottom: -22px;
      left: 0;
      height: 4px;
      background: ${colors.B400};
      width: 100%;
  }`}
`;

const MigrateCTATrigger = styled.div`
  && > span {
    vertical-align: middle;
  }
`;

const macBillingQuotesUrl = `${config.macUrl}/billing/quotes`;

export const Menu = (props: PropsWithMacLink) => {
  const analytics = useContext(CartAnalyticsContext);
  const comparatorUrl = getComparatorUrl();

  const isActive = (pathname: string, strict: boolean = false) => {
    return strict
      ? props.location.pathname === pathname
      : props.location.pathname.includes(pathname);
  };

  return (
    <>
      <Wrapper>
        {props.showPurchaseModeSelector && (
          <PurchaseModeSwitch selected={props.purchaseMode} select={props.onPurchaseModeChange} />
        )}
        <MenuList>
          <MenuItem isActive={isActive('/', true)}>
            <NavLink to={goToProductList()}>{t('navigation.header.menu.shop')}</NavLink>
          </MenuItem>
          <MenuItem>
            <Link to={props.macBillingQuoteLink}>{t('navigation.header.menu.pay-quote')}</Link>
          </MenuItem>
          <MenuItem isActive={isActive('/renew')}>
            <NavLink to={renew.landing.actions.route()}>
              {t('navigation.header.menu.renew')}
            </NavLink>
          </MenuItem>
          <MenuItem isActive={isActive('/upgrade')}>
            <NavLink to={upgrade.landing.actions.route()}>
              {t('navigation.header.menu.upgrade')}
            </NavLink>
          </MenuItem>
          <MenuItem>
            <DropdownMenu
              trigger={({ triggerRef, ...triggerProps }) => (
                <span {...triggerProps} ref={triggerRef}>
                  <MigrateCTATrigger data-testid="navigation.header.menu.migrate">
                    {t('navigation.header.menu.migrate')} <ChevronDownIcon />
                  </MigrateCTATrigger>
                </span>
              )}
              onOpenChange={({ isOpen }) =>
                isOpen && analyticsEvents[AnalyticsTrigger.SecondaryMenu](analytics)
              }
            >
              <DropdownItemGroup>
                <DropdownItem
                  data-testid="navigation.header.menu.migrate.cloud"
                  href={comparatorUrl}
                >
                  {t('navigation.header.menu.migrate.cloud')}
                </DropdownItem>
                <DropdownItem
                  data-testid="navigation.header.menu.migrate.datacenter"
                  href="/purchase/upgrade/datacenter"
                >
                  {t('navigation.header.menu.migrate.datacenter')}
                </DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          </MenuItem>
          <MenuItem>
            <CartItemButton count={props.cartItemCount} onClick={props.onGoToCart} />
          </MenuItem>
        </MenuList>
      </Wrapper>
      <MaintenanceBanner enabled={props.maintenanceEnabled} />
    </>
  );
};

const QuoteLineText = styled.div`
  font-family: ${Font.displayFamily};
  font-size: 24px;
  font-weight: 400;
`;

const EditQuoteLine = (props: PropsWithMacLink) => (
  <Wrapper>
    <QuoteLineText>Editing Quote {props.quoteNumber}</QuoteLineText>
    <div style={{ marginLeft: 'auto' }}>
      {props.showLinkToReview ? (
        <NavLink to={reviewActions.route()}>{t('navigation.header.back-to-quote-summary')}</NavLink>
      ) : null}
    </div>
  </Wrapper>
);

const BackLink = styled.a`
  font-family: ${Font.family};
  line-height: 34px;
`;

const PayQuoteLine = (props: PropsWithMacLink) => (
  <Wrapper>
    <QuoteLineText>Paying Quote {props.quoteNumber}</QuoteLineText>
    <div style={{ marginLeft: 'auto' }}>
      <BackLink href={props.macBillingQuoteLink}>{t('navigation.header.back-to-quotes')}</BackLink>
    </div>
  </Wrapper>
);

const OutstandingPaymentLine = (props: PropsWithMacLink) => (
  <Wrapper>
    <QuoteLineText>
      <FormattedMessage id="navigation.header.outstanding-payment" /> {props.quoteNumber}
    </QuoteLineText>
  </Wrapper>
);

const QuoteLine = (props: PropsWithMacLink) => {
  if (props.isOutstandingPayment) {
    return <OutstandingPaymentLine {...props} />;
  }
  if (props.inEditMode) {
    return <EditQuoteLine {...props} />;
  }
  return <PayQuoteLine {...props} />;
};

const SecondaryMenu = (props: Props) => {
  if (!props.displayMenu) {
    return null;
  }

  const macBillingQuoteLink = macLoginURITransformer({
    url: macBillingQuotesUrl,
    currentContactEmail: props.currentContactEmail,
  });

  return props.inEditMode || props.cartStatus === 'QUOTED' ? (
    <QuoteLine {...props} macBillingQuoteLink={macBillingQuoteLink} />
  ) : (
    <CartAnalyticsWrapper>
      <Menu {...props} macBillingQuoteLink={macBillingQuoteLink} />
    </CartAnalyticsWrapper>
  );
};

const shouldDisplayMenu = (state) => {
  const route = routesMap[state.location.type];
  const pageOptions = route ? route.pageOptions : null;
  return !pageOptions || !pageOptions.hideSecondaryMenu;
};

const mapStateToProps = (state: State): StateProps => ({
  displayMenu: shouldDisplayMenu(state),
  purchaseMode: selectPurchaseMode(state),
  quoteNumber: cartSelectors.quoteNumber(state),
  cartStatus: cartSelectors.cartStatus(state),
  cartItemCount: cartSelectors.itemCount(state),
  showLinkToReview: !reviewSelectors.isReviewPage(state),
  showPurchaseModeSelector: !isCheckoutPage(state),
  maintenanceEnabled:
    state.meta.launchdarkly.ifSuccess((r) => r['cart-maintenance-banner']) || false,
  inEditMode: cartSelectors.inEditMode(state),
  isOutstandingPayment: cartSelectors.isOutstandingPayment(state),
  location: state.location,
  currentContactEmail: getCurrentContactEmail(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onPurchaseModeChange: (value: PurchaseMode) => dispatch(setPurchaseMode(value)),
  onGoToCart: () => dispatch(cartActions.route()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryMenu);
