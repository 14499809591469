// @flow

import Category from 'model/Category';
import premierSupport from 'data/products/support.premier';
import { prioritySupportDatacenterBitbucket } from 'data/products/support.priority';
import technicalAccountManagement from 'data/products/technical.account.management';

export default new Category(
  'enterprise',
  premierSupport,
  prioritySupportDatacenterBitbucket,
  technicalAccountManagement,
);
