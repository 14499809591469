import React, { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { colors } from '@atlaskit/theme';

import { Dimensions, Font } from 'components/visuals';
import { DeploymentAndBillingCycleContext } from './comparator/Switchers';
import { DEPLOY_OPTION } from './comparator/utils';
import Breadcrumbs from './Breadcrumbs';

const Header = styled.h1`
  text-align: center;
  font-size: 44px;
  font-family: ${Font.displayFamily};
  font-weight: 500;
  line-height: 60px;
  color: ${colors.N900};
`;

const IntroLineFirstWrapper = styled.div`
  font-size: 18px;
  font-family: ${Font.family};
  text-align: center;
  color: ${colors.N800};
`;

const IntroLineSecondWrapper = styled(IntroLineFirstWrapper)`
  padding-bottom: ${Dimensions.units(6)}px;
`;

export const PageIntroLine = () => {
  const { deployOption } = useContext(DeploymentAndBillingCycleContext);
  const isServerDeploy = deployOption === DEPLOY_OPTION.SERVER;

  return (
    <>
      <IntroLineFirstWrapper>
        <FormattedMessage
          id={`migrate.comparator.page.intro-line.${isServerDeploy ? 'server' : 'cloud'}.line-1`}
        />
      </IntroLineFirstWrapper>
      <IntroLineSecondWrapper>
        <FormattedMessage
          id={`migrate.comparator.page.intro-line.${isServerDeploy ? 'server' : 'cloud'}.line-2`}
        />
      </IntroLineSecondWrapper>
    </>
  );
};

export const PageHeader = () => (
  <>
    <Breadcrumbs />
    <Header>
      <FormattedMessage id="migrate.comparator.page.header" />
    </Header>
  </>
);

export default PageHeader;
