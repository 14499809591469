import Cookies from 'js-cookie';
import Progress from 'redux-progress';
import { AxiosRequestConfig } from 'axios';
import { State } from 'model/State';

export type XsrfToken = string;

export const selectXsrfToken = (state: State): Progress<XsrfToken | null> => {
  const xsrfCookie = Cookies.get('ATL-XSRF-Token');

  if (xsrfCookie) return Progress.success(xsrfCookie);

  return state?.auth.xsrfToken;
};

export const isXsrfPresent = (state: State) => !!selectXsrfToken(state).result;

export const selectHamsAuthHeaders = (state: State) => {
  if (Cookies.get('ATL-XSRF-Token')) return {};

  return (
    state?.auth.xsrfToken.ifSuccess((token) => (token ? { 'ATL-XSRF-Token': token } : null)) || {}
  );
};

export const selectHamsClientConfig = (state: State): AxiosRequestConfig => {
  const result: AxiosRequestConfig = {
    headers: selectHamsAuthHeaders(state),
  };

  return result;
};
