import { ConfigUserAction, ConfigUserData } from '../models/configUser';
import { RESTORE_USER_EMAIL, SET_SWITCH_USER_EMAIL } from '../actions/configUser/types';
import { MACSwitchedUser } from '../api/storage';

const storedSwitchedUser = MACSwitchedUser().load();

export const initialState = {
  email: storedSwitchedUser || '',
};

export default (state: ConfigUserData = initialState, action: ConfigUserAction): ConfigUserData => {
  switch (action.type) {
    case SET_SWITCH_USER_EMAIL:
    case RESTORE_USER_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    default:
      return state;
  }
};
