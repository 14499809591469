// @flow
import * as React from 'react';
import styled from 'styled-components';
import Progress from 'redux-progress';
import ChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-up';
import { colors } from '@atlaskit/theme';
import { Checkbox } from '@atlaskit/checkbox';
import ChooseTier from 'components/common/ChooseTier';
import t from 'modules/i18n/intl';

import ProductTier from 'model/hams/ProductTier';
import { Dimensions, Font } from 'components/visuals';
import ProductLogo, { TextProductLogo } from 'components/common/ProductLogo';
import { getProduct } from 'data/products/index';

export type OwnProps = {
  productKey: string,
};

export type StateProps = {
  child: boolean,
  productName: string,
  tiers: Progress<ProductTier[]>,
  tier: ProductTier | null,
  selected: boolean,
  subscription: boolean,
  currency: Progress<string>,
  academic: boolean,
  expanded: boolean,
  description: React.Node,
  marketplaceAddon: boolean,
  externalUrl: string | null,
  additionalTierLabel: React.Node | null,
};

export type DispatchProps = {
  onAddonToggle: () => void,
  onAddonExpand: () => void,
  onTierSelect: (string, ProductTier) => void,
};

export type Props = OwnProps & StateProps & DispatchProps;

const Wrapper = styled.div`
  border: 1px solid ${colors.N60};
  background-color: ${(props) => (props.selected ? colors.N10 : 'inherit')};
  box-sizing: border-box;
  position: relative;
  padding: 0;
  :hover {
    background-color: ${colors.N10};
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
  width: ${(props) => (props.width ? props.width : 'inherit')};
`;

const RowWrapper = styled(FlexWrapper)`
  min-height: ${Dimensions.units(8)}px;
  padding: ${Dimensions.units(1)}px ${Dimensions.units(3)}px;
`;

const AddonInfo = styled.div`
  padding: 0 0 ${Dimensions.units(2)}px ${Dimensions.units(7.5)}px;
  font-size: 13px;
  font-family: ${Font.family};
  font-weight: 400;
  width: 520px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: ${Dimensions.units(50)}px;
  padding: ${Dimensions.units(1)}px 0;
  display: flex;
  justify-content: flex-start;
  & p {
    margin: 0;
    line-height: 30px;
  }
`;

const ArrowWrapper = styled.div`
  margin-left: ${Dimensions.units(2)}px;
  cursor: pointer;
  padding: 2px;
`;

const UnmatchedTierMessageWrapper = styled.span`
  font-family: ${Font.family};
  font-size: 13px;
  font-weight: 400;
`;

const CheckboxWrapper = styled.span`
  margin-right: ${Dimensions.units(1)}px;
`;

const UnmatchedTierMessage = ({ name }: { name: string }) => (
  <UnmatchedTierMessageWrapper>
    {name || 'This product'} cannot be added at this user tier.
  </UnmatchedTierMessageWrapper>
);

const Logo = ({ productKey, productName }: { productKey: string, productName: string }) => {
  const product = getProduct(productKey);
  return product ? (
    <ProductLogo product={product} />
  ) : (
    <TextProductLogo>{productName}</TextProductLogo>
  );
};

export default ({
  productKey,
  productName,
  description,
  selected,
  expanded,
  onAddonToggle,
  onAddonExpand,
  subscription,
  currency,
  tier,
  tiers,
  additionalTierLabel,
  onTierSelect,
  academic,
  marketplaceAddon,
  externalUrl,
  child,
}: Props) => (
  <Wrapper selected={selected} onClick={onAddonToggle}>
    <RowWrapper>
      <FlexWrapper justify="flex-start" width="50%">
        <CheckboxWrapper role="none" onClick={(e) => e.stopPropagation()}>
          <Checkbox
            isChecked={selected}
            onChange={onAddonToggle}
            name="addons"
            value={productKey}
          />
        </CheckboxWrapper>
        <LogoWrapper>
          <Logo productKey={productKey} productName={productName} />
        </LogoWrapper>
      </FlexWrapper>
      <FlexWrapper justify="flex-end" width="50%">
        {child && tier === null ? (
          <UnmatchedTierMessage name={productName} />
        ) : (
          <div>
            {additionalTierLabel}
            <ChooseTier
              tiers={tiers.result || []}
              selected={tier}
              onChange={(tierElement) => onTierSelect(productKey, tierElement)}
              academic={academic}
              isDisabled={child}
              showPrices={!subscription}
              currency={currency}
            />
          </div>
        )}
        <ArrowWrapper
          onClick={(e) => {
            e.stopPropagation();
            onAddonExpand();
          }}
        >
          {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </ArrowWrapper>
      </FlexWrapper>
    </RowWrapper>
    {expanded ? (
      <AddonInfo>
        {description}
        &nbsp;
        {marketplaceAddon && <span>This is an app from the Atlassian Marketplace.</span>}
        &nbsp;
        {externalUrl && (
          <a
            href={externalUrl}
            style={{ fontWeight: 'bold' }}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="noreferrer"
          >
            {t('common.more')}&nbsp;&raquo;
          </a>
        )}
      </AddonInfo>
    ) : null}
  </Wrapper>
);
