// @flow
import { combineEpics } from 'redux-observable';

import { FETCH_LANGUAGE } from 'modules/i18n/duck/actions';

import { timeAction, counter } from './util';

export default combineEpics(
  timeAction(FETCH_LANGUAGE, 'language.fetch'),
  counter(FETCH_LANGUAGE, 'language.fetch'),
);
