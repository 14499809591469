import Progress from 'redux-progress';
import { Selector } from 'model/State';

// TODO: Double check the bottleneck. Currency is possibly null!!!
// const selectCurrency: Selector<Progress<string|null>> = (state) => watch the errors
// state.cart.payload.map((p) => (p && p.currency) ?? ''); is a possible solution
const selectCurrency: Selector<Progress<string>> = (state) =>
  state.cart.payload.map((p) => p && p.currency);

export default selectCurrency;
