import { UIAction, UIActionSubject } from 'modules/analytics/model/UIEvent';

import { TrackAction, TrackActionSubject } from 'modules/analytics/model/TrackEvent';

export enum AnalyticsTrigger {
  ContactDetailsContinue = 'contactDetailsContinue',
  ContactDetailsValidationFailed = 'contactDetailsValidationFailed',
  ContactDetailsValidationFieldFailed = 'contactDetailsValidationFieldFailed',
  CreditCardPaymentFailed = 'creditCardPaymentFailed',
}

export const analyticsEvents = {
  [AnalyticsTrigger.ContactDetailsContinue]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'billingContactDetailsContinue',
      attributes: {
        eventContainer: 'billingContactDetails',
        eventComponent: UIActionSubject.Button,
        parentLabel: 'billingContactDetails',
        label: 'continue',
        uuid: '36e77f8f-f2dc-4b44-bca8-1210eb08da5a',
      },
    });
  },
  [AnalyticsTrigger.ContactDetailsValidationFailed]: (analytics, errorCode) => {
    analytics.sendUiEvent({
      action: TrackAction.Failed,
      actionSubject: TrackActionSubject.BillingContact,
      actionSubjectId: 'billingContactDetailsValidationFailed',
      attributes: {
        eventContainer: 'billingContactDetails',
        eventComponent: UIActionSubject.Form,
        parentLabel: 'billingContactDetails',
        label: 'Validation failed',
        uuid: 'e095ebe7-625b-4218-a541-5d62b19ee3ed',
        errorCode,
      },
    });
  },
  [AnalyticsTrigger.ContactDetailsValidationFieldFailed]: (analytics, errorCode) => {
    analytics.sendUiEvent({
      action: TrackAction.Failed,
      actionSubject: TrackActionSubject.BillingContact,
      actionSubjectId: 'billingContactDetailsValidationFieldFailed',
      attributes: {
        eventContainer: 'billingContactDetails',
        eventComponent: UIActionSubject.Input,
        parentLabel: 'billingContactDetails',
        label: 'Validation field failed',
        uuid: '8f179e32-af70-4375-bcfb-7b4b8e17624f',
        errorCode,
      },
    });
  },
  [AnalyticsTrigger.CreditCardPaymentFailed]: (analytics, errorMessage) => {
    analytics.sendUiEvent({
      action: UIAction.Rendered,
      actionSubject: UIActionSubject.CartConfig,
      actionSubjectId: 'ccErrorMessage',
      attributes: {
        eventContainer: 'PaymentPage',
        eventComponent: 'CardError',
        uuid: 'af9d649c-9d5b-41b3-943a-1da4d55d1766',
        label: 'Credit Card Error Message',
        errorMessage,
      },
    });
  },
};
