import t from 'modules/i18n/intl';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { colors } from '@atlaskit/theme';
import { Dimensions } from 'components/visuals';
import styled from 'styled-components';
import { HamsCartItem } from 'modules/cart/duck/model';
import Tooltip from 'components/common/Tooltip';
import Info from '@atlaskit/icon/glyph/info';
import { OrderableItem } from 'model/hams/Pricing';
import { getAppListPrice } from '../utils';
import AmountWithCurrency from 'components/common/AmountWithCurrency';
import { formattedDatePeriod } from 'components/common/MaintenancePeriod';

type Props = {
  currency: string;
  cartAppItem: HamsCartItem;
  appTierMapping: { [cartItemId: number]: OrderableItem[] };
};

const IconWrapper = styled.span`
  padding-left: ${Dimensions.units(0.5)}px;
`;

export const CartItemDescriptionDiv = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #6d778a;
`;

export const StyledTableCell = styled.td`
  padding: ${Dimensions.units(3)}px 0;
`;

export const StyledTableProratedPriceCell = styled(StyledTableCell)`
  text-align: right;
`;

export const StyledTableRow = styled.tr`
  border-top: 2px solid ${colors.N40};
  border-bottom: 2px solid ${colors.N40};
`;

export const ProductDescriptionDiv = styled.div`
  font-size: 18px;
  line-height: 21px;
`;

const AppInfoRow = ({ currency, cartAppItem, appTierMapping }: Props) => {
  const annualListPrice = getAppListPrice(appTierMapping, cartAppItem);

  const itemPeriodDates = formattedDatePeriod(cartAppItem, CartItemDescriptionDiv);
  return (
    <StyledTableRow key={cartAppItem.id}>
      <StyledTableCell>
        <ProductDescriptionDiv>
          {cartAppItem.productDetails.productDescription}
        </ProductDescriptionDiv>
        <CartItemDescriptionDiv>
          <FormattedMessage id="annualbilling.table.product.description.cloud" />
        </CartItemDescriptionDiv>
      </StyledTableCell>
      <StyledTableCell>{/* Empty field since we don't render Edition for Apps */}</StyledTableCell>
      <StyledTableCell>
        {cartAppItem.productDetails.unitCount}
        <Tooltip
          direction="right-start"
          content={t('annualbilling.billing-tier-app-details-tooltip')}
          distance={Dimensions.units(1)}
          contentPadding={Dimensions.units(2)}
          displayAsFlag
        >
          <IconWrapper>
            <Info label="Info icon" size="small" primaryColor={colors.N600} />
          </IconWrapper>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell>
        <AmountWithCurrency
          amount={annualListPrice}
          currency={currency}
          weight="400"
          size="14px"
          dataRole="annualListPrice"
        />
      </StyledTableCell>
      <StyledTableProratedPriceCell>
        <AmountWithCurrency
          amount={cartAppItem.totalIncTax}
          currency={currency}
          weight="400"
          size="14px"
          dataRole="proratedPrice"
        />
        {itemPeriodDates}
      </StyledTableProratedPriceCell>
    </StyledTableRow>
  );
};

export { AppInfoRow };
