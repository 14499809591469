import React from 'react';

import TaxIdInput from 'modules/checkout/contacts/TaxIdInput';
import { FormSection } from 'modules/form';

import { Country } from './Country';
import { GeneralErrors } from './GeneralErrors';
import { CompanyName, AddressLine1, AddressLine2, City, State, PostCode } from './FormField';

import { AddressFormProps } from './types';

export const AddressForm: React.FC<AddressFormProps> = (props: AddressFormProps) => {
  const { name, state, country } = props;

  return (
    <FormSection name={name}>
      <CompanyName />

      <Country {...props} />

      <AddressLine1 />

      <AddressLine2 />

      <City />

      <State state={state} country={country} />

      <PostCode country={country} />

      <TaxIdInput {...props} />

      <GeneralErrors />
    </FormSection>
  );
};
