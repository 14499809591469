/* eslint-disable import/prefer-default-export */

export const NOTIFICATION_FLAG_TIMEOUT = 5000;
export const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const uuidPattern = /[^a-zA-Z0-9\-_]+/g;
export const LOYALTY_DISCOUNT_IDENTIFIER = 'Crossgrade Loyalty discount';
export const UPGRADE_CREDIT_IDENTIFIER = 'Upgrade Credit';
export const PREMIUM_EDITION = 'premium';
export const STANDARD_EDITION = 'standard';

export const OVERVIEW_LIMIT = 10;

// Links constants
export const PartnersLink = 'https://partners.atlassian.com';
