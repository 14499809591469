import React, { useMemo } from 'react';
import styled from 'styled-components';

import { colors } from '@atlaskit/theme';

import {
  PriceSummary,
  PriceSummaryProps,
  FieldCell,
  FieldValueCell,
  SavedAmountMessage,
} from 'components/common/PriceSummary';
import TableHeader from 'modules/change/table/TableHeader';
import TableBody from 'modules/change/table/TableBody';
import { TableProps } from 'modules/change/table/duck/model';
import t from 'modules/i18n/intl';
import { Dimensions, Font } from 'components/visuals';
import AddToCart, { Wrapper as AddToCartWrapper } from 'components/common/AddToCart';
import { calculatePercentage } from 'util/calculations';
import UFOLoadHold from '@atlassian/ufo-load-hold';

const Wrapper = styled.div`
  & > ${AddToCartWrapper.selector} {
    border-top: 0;
  }
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  font-family: ${Font.displayFamily};
  margin-bottom: ${Dimensions.units(3)}px;
`;

const TableWrapper = styled.div`
  border-top: 2px solid ${colors.N40};
`;

const Table = styled.table`
  table-layout: fixed;
`;

const NoEntries = styled.div`
  font-family: ${Font.displayFamily};
  font-size: 16px;
`;

const ChangeOptionsTableVisual = (props: TableProps) => {
  const priceSummaryProps: PriceSummaryProps | null = useMemo(() => {
    if (!props.showPriceSummary) {
      return null;
    }

    const isAmountSaved: boolean = props.originalAmount > props.amount;

    const summaryRenderingObj: PriceSummaryProps = {
      currency: props.currency,
      rowGroups: [
        {
          rows: [
            {
              field: <FieldCell text={t('common.subtotal')} />,
              value: (
                <FieldValueCell testId="original-amount" text={props.originalAmount} showCurrency />
              ),
            },
            {
              field: <FieldCell text={t('common.loyalty-discount')} />,
              value: (
                <FieldValueCell
                  testId="loyalty-discount"
                  text={props.loyaltyDiscount}
                  showCurrency
                  prefix="-"
                />
              ),
            },
          ],
        },
      ],
      footerRows: [
        {
          field: <FieldCell text={t('common.total')} />,
          value: (
            <FieldValueCell
              testId="total-amount"
              text={props.amount}
              showCurrency
              classes="darkText"
            />
          ),
          classes: 'totalPayAmount',
        },
      ],
    };

    // eslint-disable-next-line no-unused-expressions
    isAmountSaved &&
      summaryRenderingObj.footerRows.push({
        field: null,
        value: (
          <SavedAmountMessage
            percentage={calculatePercentage(props.loyaltyDiscount, props.originalAmount)}
            currency={props.currency}
            amount={props.loyaltyDiscount}
            isPartner={props.isPartner}
          />
        ),
        classes: 'savingsText',
      });

    return summaryRenderingObj;
  }, [
    props.showPriceSummary,
    props.currency,
    props.amount,
    props.originalAmount,
    props.loyaltyDiscount,
  ]);

  if (props.foundEntries.length > 0) {
    return (
      <Wrapper>
        <Title>{t('common.search-results')}</Title>
        <TableWrapper>
          <Table>
            <TableBody {...props} entries={props.foundEntries} />
          </Table>
        </TableWrapper>
        {props.showPriceSummary && !!props.amount && <PriceSummary {...priceSummaryProps!} />}
        <AddToCart
          isRenewUpgrade={props.isRenewUpgrade}
          amount={props.amount}
          currency={props.currency}
          showSearchDescription
          onAddToCart={props.onAddToCart}
          submitProgress={props.addItemsProgress}
          isExpert={props.isExpert}
          purchaseMode={props.purchaseMode}
          isSticky={false}
          hideBorder
          showAmountDetails={!props.showPriceSummary}
          isDisabled={!props.hasFoundSelected}
        />
      </Wrapper>
    );
  }

  return props.entries.length > 0 ? (
    <Wrapper>
      <Title>{t('common.your-products')}</Title>
      <Table>
        <TableHeader {...props} />
        <TableBody {...props} />
      </Table>
      {props.showPriceSummary && !!props.amount && <PriceSummary {...priceSummaryProps!} />}
      <UFOLoadHold name="change-options-add-to-cart-hold">
        {props.list.fold({
          success: (list) => {
            const { limit, offset, totalItems } = list;
            return (
              <AddToCart
                isRenewUpgrade={props.isRenewUpgrade}
                amount={props.amount}
                currency={props.currency}
                onAddToCart={props.onAddToCart}
                submitProgress={props.addItemsProgress}
                isExpert={props.isExpert}
                purchaseMode={props.purchaseMode}
                isSticky={false}
                hideBorder
                showAmountDetails={!props.showPriceSummary}
                isDisabled={!props.hasSelected}
                pagination={
                  totalItems && limit && limit < totalItems
                    ? {
                        limit,
                        offset,
                        totalItems,
                        onPaginationChange: props.onFetchPaginatedAccounts,
                      }
                    : null
                }
              />
            );
          },
        })}
      </UFOLoadHold>
    </Wrapper>
  ) : (
    <NoEntries>{t('change.no-products')}</NoEntries>
  );
};

export default ChangeOptionsTableVisual;
