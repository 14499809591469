import React, { useContext } from 'react';
import styled from 'styled-components';

import Button from '@atlaskit/button/standard-button';
import { colors } from '@atlaskit/theme';

import { PurchaseMode, PurchaseModes } from 'modules/settings/purchaseMode';
import t from 'modules/i18n/intl';
import NavLink from 'components/common/NavLink';
import { goToProductList } from 'actions';
import { Dimensions, Font } from 'components/visuals';
import { HamsCart } from 'modules/cart/duck/model';

import { CartAnalyticsContext } from 'modules/analytics';
import { analyticsEvents, AnalyticsTrigger } from 'modules/siteconfigurator/analytics';

type Props = {
  onSubmit: () => void;
  onStartShopping: () => void;
  purchaseMode: PurchaseMode;
  cart: HamsCart;
  onTogglePurchaseMode: () => void;
  inSiteConfiguratorMode?: boolean;
};

const onSubmit = (props: Props, analytics) => () => {
  if (props.inSiteConfiguratorMode) {
    analyticsEvents[AnalyticsTrigger.Continue](analytics, { cart: props.cart });
  }
  props.onSubmit();
};

const onSubmitWithModeChange = (props: Props, analytics) => () => {
  if (props.inSiteConfiguratorMode) {
    analyticsEvents[AnalyticsTrigger.SaveQuote](analytics, { cart: props.cart });
  }
  props.onTogglePurchaseMode();
  props.onSubmit();
};

const StyledButton = styled(Button)`
  padding: 0 16px;
  font-weight: 500;
  font-family: ${Font.family};
  font-size: 16px;
`;

const Wrapper = styled.div`
  font-family: ${Font.family};
  padding-top: ${Dimensions.units(3)}px;
`;

const FlexWrapper = styled.div`
  display: flex;
  font-weight: 500;
  color: ${colors.B400};
`;

const FlexSeparator = styled.div`
  flex-grow: 1;
`;

const RightSection = styled.div`
  text-align: right;
`;

const Link = styled.a`
  display: block;
  margin-top: ${Dimensions.units(1)}px;
  font-size: 16px;
  cursor: pointer;
`;

const getLabels = (props: Props) => {
  switch (true) {
    case props.inSiteConfiguratorMode:
      return {
        primaryLabel: t('payment.continue'),
        secondaryLabel: t('cart.save-as-quote-message'),
      };

    case props.purchaseMode === PurchaseModes.BUY:
      return {
        primaryLabel: t('cart.checkout'),
        secondaryLabel: t('cart.save-as-quote-message'),
      };
    default:
      return {
        primaryLabel: t('cart.save-quote'),
        secondaryLabel: t('cart.place-this-order-today'),
      };
  }
};

const ActionLine = (props: Props) => {
  const actionLabels = getLabels(props);
  const analytics = useContext(CartAnalyticsContext);

  return (
    <Wrapper>
      {!props.inSiteConfiguratorMode && props.cart.items.length === 0 ? (
        <Button appearance="primary" onClick={props.onStartShopping}>
          {t('cart.start-shopping')}
        </Button>
      ) : (
        <FlexWrapper>
          {!props.inSiteConfiguratorMode && (
            <NavLink to={goToProductList()} style={{ fontFamily: Font.family, fontSize: '16px' }}>
              {t('cart.continue-shopping')}
            </NavLink>
          )}
          <FlexSeparator />
          <RightSection>
            <StyledButton onClick={onSubmit(props, analytics)} appearance="primary">
              {actionLabels.primaryLabel}
            </StyledButton>
            {props.cart.totalIncTax > 0 ? (
              <Link role="button" onClick={onSubmitWithModeChange(props, analytics)}>
                {actionLabels.secondaryLabel}
              </Link>
            ) : null}
          </RightSection>
        </FlexWrapper>
      )}
    </Wrapper>
  );
};

export default ActionLine;
