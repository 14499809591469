import { getActionLink, ActionLink } from 'util/ActionLink';
import React from 'react';
import styled from 'styled-components';
import Progress from 'redux-progress';
import { connect } from 'react-redux';
import Spinner from '@atlaskit/spinner';
import PromotionFail from 'modules/promotion/components/PromotionFail';
import { Dispatch, State } from 'model/State';
import { StoredPromotion } from 'modules/promotion/duck/model';
import { goToProductList } from 'actions';

type StateProps = {
  promotion: Progress<StoredPromotion>;
};

type DispatchProps = {
  continueShopping: ActionLink;
};

type Props = StateProps & DispatchProps;

const Wrapper = styled.div`
  margin: 30px;
  > * {
    margin-right: 10px;
  }
`;

export const PromotionPageStateless = ({ continueShopping, promotion }: Props) =>
  promotion.fold({
    failed: (e) => <PromotionFail link={continueShopping} reason={e.reason || 'invalid'} />,
    success: () => (
      <Wrapper>
        <Spinner interactionName="promotion-page-success-spinner" size="medium" /> You will be
        redirected in a second...
      </Wrapper>
    ),
    loading: () => (
      <Wrapper>
        <Spinner interactionName="promotion-page-loading-spinner" size="medium" /> Validating
        Promotion...
      </Wrapper>
    ),
  });

const mapStateToProps = (state: State): StateProps => ({
  promotion: state.promotion.fetched,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  continueShopping: getActionLink(dispatch, goToProductList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromotionPageStateless);
