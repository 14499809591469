import React from 'react';
import styled from 'styled-components';
import Progress from 'redux-progress';

import Header from 'modules/addon/Header';
import Accordion from 'modules/addon/Accordion';
import DeploymentSection from 'modules/addon/DeploymentSection';
import CloudAddonConfiguration from 'modules/addon/CloudAddonConfiguration';
import AddonNotFoundBanner from 'modules/addon/AddonNotFoundBanner';
import { MultiProductTier } from 'modules/addon/duck/selectors/mergedProductTiers';
import AcademicToggle from 'modules/addon/AcademicToggle';
import * as model from 'modules/addon/duck/model';
import { CLOUD, SERVER } from 'model/hams/DeploymentOption';
import { TermOption } from 'model/TermOption';
import AddToCart from 'components/common/AddToCart';
import AddToCartErrorMessage from 'components/common/AddToCartErrorMessage';
import SectionMessage from '@atlaskit/section-message';
import { FormattedMessage } from 'react-intl';
import {
  AddonsWithAllowedServerProductsKeys,
  AddonsWithReducedAllowedServerProductsKeys,
} from '../../data/products/keys';
import { isAddon } from '../../model/hams/Pricing';
import { ServerAddonsEOLWarningMessage } from '../../components/navigation/top/WarningMessage';
import { FeatureFlags, checkFeatureGate } from 'util/statsig/index';

export type Events = {
  onModeChange: (mode: string) => () => void;
  onParentChange: (parent: model.ParentData) => void;
  onTermChange: (key: string, term: TermOption) => void;
  onSearchForAddonsClick: () => void;
  onAddToCart: () => void;
  onDeploymentChange: (key: string) => void;
  onSenChange: (sen: string) => void;
  onEmailChange: (email: string) => void;
  onSearchSubmit: () => void;
  onTierSelect: (tier: MultiProductTier) => void;
  onAcademicToggle: () => void;
};

const hasMissingPricing = (props) => Object.values(props.data.pricing).includes(null);

export type Manual = {
  manual: TermOption;
};

export type Props = model.AddonState & {
  data: model.AddonPageData & Partial<model.OverviewData>;
  overviewProgress: Progress<model.OverviewData>;
  isAddToCartEnabled: boolean;
  events: Events;
  terms: Manual;
  isDisableBambooCrowdServerAddonsFFOn: boolean;
  currentContactEmail: string | null;
  isServerAddonsEOLEndgameFFOn: boolean;
};

const BodyWrapper = styled.div`
  display: flex;
`;

const BodyMiddleSeparator = styled.div`
  flex-grow: 1;
`;

const SectionMessageWrapper = styled.div`
  margin-top: 16px;
`;

const Body = (props: Props) => (
  <>
    <AddToCartErrorMessage
      submitProgress={props.submitProgress}
      onAddToCart={props.events.onAddToCart}
    />
    <BodyWrapper>
      <Accordion {...props} />
      <BodyMiddleSeparator />
      <AddToCart
        purchaseMode={props.data.purchaseMode}
        currency={props.data.currency}
        amount={props.data.amount}
        isSticky
        onAddToCart={props.events.onAddToCart}
        isDisabled={!props.isAddToCartEnabled}
        submitProgress={!props.submitProgress.failed ? props.submitProgress : Progress.none}
      />
    </BodyWrapper>
  </>
);

const VisualAddonPage = (props: Props) => {
  const isAccessRebrandingFFOn = checkFeatureGate(
    FeatureFlags.CART_RENAME_ATLASSIAN_ACCESS_TO_GUARD,
  );

  const suggestedParentKey = props.data.suggestedParent?.key;
  const addonDescription = props.data.pricing[0]?.productDescription;

  const isAddonWithServerOptionAllowed = (allowedKeysList) => {
    const allowedServerAddonsList = Object.values(allowedKeysList);
    const isParentAddonInAllowedList =
      suggestedParentKey && allowedServerAddonsList.includes(suggestedParentKey);
    const isAddonInAllowedList = allowedServerAddonsList.some((productKey) =>
      addonDescription?.toLowerCase().includes(productKey as string),
    );

    return (
      isAddon(props.data.pricing[0]?.productType) &&
      (isParentAddonInAllowedList || isAddonInAllowedList)
    );
  };

  const shouldEnableServerOption = isAddonWithServerOptionAllowed(
    AddonsWithAllowedServerProductsKeys,
  );

  const shouldEnableServerOptionForBambooCrowd = props.isDisableBambooCrowdServerAddonsFFOn
    ? isAddonWithServerOptionAllowed(AddonsWithReducedAllowedServerProductsKeys)
    : true;

  const hasAllowedServerOption = props.isDisableBambooCrowdServerAddonsFFOn
    ? shouldEnableServerOptionForBambooCrowd
    : shouldEnableServerOption;

  const renderBody = () =>
    props.data.deploymentOption === SERVER && !hasAllowedServerOption ? null : <Body {...props} />;

  return hasMissingPricing(props) ? (
    <AddonNotFoundBanner {...props} />
  ) : (
    <>
      <AcademicToggle {...props} />
      <Header {...props} />
      {!hasAllowedServerOption && (
        <ServerAddonsEOLWarningMessage
          isServerAddonsEOLEndgameFFOn={props.isServerAddonsEOLEndgameFFOn}
        />
      )}
      {props.data.deploymentOption === CLOUD &&
        props.data.productKeys.includes('com.radiantminds.roadmaps-jira.ondemand') && (
          <SectionMessageWrapper>
            <SectionMessage appearance="warning">
              <FormattedMessage
                id="products.com.radiantminds.roadmaps-jira.warning"
                values={{
                  addon: props.data.pricing[0].productDescription,
                }}
              />
            </SectionMessage>
          </SectionMessageWrapper>
        )}
      <DeploymentSection
        {...props}
        hasAllowedServerOption={hasAllowedServerOption}
        isAccessRebrandingFFOn={isAccessRebrandingFFOn}
      />
      {props.data.deploymentOption === CLOUD ? (
        <CloudAddonConfiguration {...props} />
      ) : (
        renderBody()
      )}
    </>
  );
};

export default VisualAddonPage;
