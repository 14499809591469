// @flow
import paramsSerializer from 'util/paramsSerializer';
import axios from 'axios';
import type { AxiosRequestConfig } from 'axios';
import config from 'appconfig';

const getMarketplaceClient = (clientConfig?: AxiosRequestConfig = {}) =>
  axios.create({
    baseURL: config.marketplaceUrl,
    maxContentLength: 50000,
    withCredentials: false,
    paramsSerializer,
    ...clientConfig,
  });

export default getMarketplaceClient;
