// @flow
import { ProductGroupKeys, ServerProductKeys, DataCenterProductKeys } from 'data/products/keys';
import config from 'appconfig';
import t from 'modules/i18n/intl';
import ServerProduct from 'model/editions/ServerProduct';
import DataCenterProduct from 'model/editions/DataCenterProduct';
import SoftwareProductGroup from 'model/SoftwareProductGroup';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import { ExtraTierLabel, ExtraTierLabelCell } from '../../components/common/TierLabel';

export const bambooServer = new ServerProduct({
  key: ServerProductKeys.BAMBOO,
  groupKey: ProductGroupKeys.BAMBOO,
  tagLine: t('products.bamboo.tagline'),
  description: t('products.bamboo.description'),
  name: t('products.bamboo.name'),
  moreLink: `${config.wacUrl}/software/bamboo`,
  additionalTierLabel: (
    <ExtraTierLabel additionalTierLabel={<FormattedMessage id="tier.extra.label.local_agents" />} />
  ),
  additionalTierLabelCell: (
    <ExtraTierLabelCell
      additionalTierLabel={<FormattedMessage id="tier.extra.cell.local_agents" />}
    />
  ),
});

export const bambooDataCenter = new DataCenterProduct(
  DataCenterProductKeys.BAMBOO,
  bambooServer,
  [],
  [],
);

export const bambooProductGroup = new SoftwareProductGroup(bambooServer, bambooDataCenter);
