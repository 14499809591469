// @flow
import React from 'react';
import styled from 'styled-components';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import { Dimensions } from 'components/visuals';
import Tooltip from 'components/common/Tooltip';

type Props = {
  children: string,
};

const Wrapper = styled.span`
  position: relative;
  left: ${Dimensions.units(1)}px;
  top: ${Dimensions.units(-1)}px;
`;

const Help = ({ children }: Props) => (
  <Wrapper>
    <Tooltip direction="right" tag="span" content={children} position="top" delay={0}>
      <QuestionCircleIcon size="small" />
    </Tooltip>
  </Wrapper>
);

export default Help;
