/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';

import { HamsCart, HamsCartItem } from 'modules/cart/duck/model';
import Cell from 'modules/cart/summary/cells/Cell';
import AmountWithCurrency from 'components/common/AmountWithCurrency';
import { TaxIncludedSymbol } from './price-symbols';
import Tooltip from 'components/common/Tooltip';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import t from 'modules/i18n/intl';

type Props = {
  item: HamsCartItem;
  cart: HamsCart;
  isChild: boolean;
};

const shouldShowPrice = ({ isChild, item }: Props) => !isChild || item.totalIncTax > 0;

const DiscountNote = styled.div`
  color: ${colors.N50};
  font-size: 12px;
  line-height: 12px;
`;

const CrossedOriginalAmount = styled.div`
  color: ${colors.N50};
  text-decoration: line-through;
`;

const IconWrapper = styled.span`
  position: relative;
  span {
    width: 12px;
    position: absolute;
    top: -15px;
  }
`;

const originalPrice = (item) =>
  item.totalIncTax + (item.creditAmount || 0) + (item.discountAmount || 0);

const Discount = ({ item: { productDetails, discountDetails, discountAmount }, cart }: Props) => {
  const hasDiscountForJiraAutomation =
    productDetails?.productKey === 'com.codebarrel.addons.automation.data-center' &&
    discountDetails?.some((item) => item.reason === 'PRORATED_PRICE_EOL');

  return discountAmount > 0 ? (
    <DiscountNote data-cy="discount-note">
      <AmountWithCurrency amount={discountAmount} currency={cart.currency} size="inherit" />{' '}
      discount
      {hasDiscountForJiraAutomation ? (
        <IconWrapper>
          <Tooltip direction="down" content={t('cart.jira-automation-discount-tooltip')}>
            <QuestionCircleIcon size="small" label="discount" />
          </Tooltip>
        </IconWrapper>
      ) : null}
    </DiscountNote>
  ) : null;
};

const Credit = ({ item, cart }: Props) =>
  item.creditAmount > 0 ? (
    <DiscountNote>
      <AmountWithCurrency amount={item.creditAmount} currency={cart.currency} size="12px" /> credit
    </DiscountNote>
  ) : null;

const FreeLabel = styled.span`
  font-size: 16px;
`;

const PriceCell = (props: Props) => (
  <Cell>
    {shouldShowPrice(props) && (
      <>
        <div>
          {props.item.totalIncTax > 0 ? (
            <div>
              <AmountWithCurrency amount={props.item.totalIncTax} currency={props.cart.currency} />
              {props.item.totalTax > 0 ? <TaxIncludedSymbol /> : null}
            </div>
          ) : (
            <FreeLabel>Free</FreeLabel>
          )}
        </div>
        {props.item.creditAmount > 0 || props.item.discountAmount > 0 ? (
          <>
            <CrossedOriginalAmount>
              <AmountWithCurrency
                amount={originalPrice(props.item)}
                currency={props.cart.currency}
                size="14px"
              />
            </CrossedOriginalAmount>
            <Discount {...props} />
            <Credit {...props} />
          </>
        ) : null}
      </>
    )}
  </Cell>
);

export default PriceCell;
