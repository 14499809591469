import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Progress from 'redux-progress';
import styled from 'styled-components';

import DetailsSection from 'modules/checkout/review/details/DetailsSection';
import ActionLine from 'modules/checkout/review/ActionLine';
import { submit, toggleEula } from 'modules/checkout/review/duck/actions';
import { CheckoutReview, MarketplaceEula } from 'modules/checkout/review/duck/model';
import {
  selectMarketplaceAddonEulas,
  selectUnavailableMarketplaceAddonInCart,
} from 'modules/checkout/review/duck/selectors';
import t from 'modules/i18n/intl';
import { goToProductList } from 'actions';
import PageLoading from 'components/common/PageLoading';
import { Dimensions, Font } from 'components/visuals';
import { HamsCart, HamsEnrichedCart } from 'modules/cart/duck/model';
import { Dispatch, State } from 'model/State';
import { PurchaseMode, selectPurchaseMode } from 'modules/settings/purchaseMode';
import * as contact from 'modules/checkout/contacts';
import * as payment from 'modules/checkout/payment';
import * as cart from 'modules/cart';
import * as upgrade from 'modules/upgrade';
import * as renew from 'modules/renew';
import CartSummary from 'modules/cart/summary/CartSummary';
import { Option } from 'modules/cart/summary/OptionsSelect';
import { selectCartPayload, selectCartItemsBeingDeleted } from 'modules/cart/duck/selectors';
import {
  canEditContactInSiteConfigurator,
  selectInSiteConfiguratorMode,
} from 'modules/siteconfigurator/duck/selectors';
import { getCurrentContactEmail } from 'modules/auth/selectors/contactDetails';

import {
  useConfirmPostReviewNewPaymentMethod,
  processOrderAfterPaymentAuthentication,
  ProcessOrderAfterPaymentAuthenticationInput,
  isSuccessful,
} from 'modules/stripe';

import { monitorStatus } from 'modules/checkout/review/duck/actions/monitorStatus';

import { isStripeCheckoutModeSelected } from 'modules/checkout/payment/duck/selectors/stripe';

import CartAnalyticsClient, { AnalyticsClient } from 'modules/analytics/CartAnalyticsClient';
import {
  TrackAction,
  TrackActionSubject,
  TrackEventNames,
  TrackSource,
} from 'modules/analytics/model/TrackEvent';
import traceUFOPress from '@atlassian/ufo-trace-press';

type StateProps = {
  cart: Progress<HamsEnrichedCart>;
  deletingItems: { [id: number]: true };
  reviewState: CheckoutReview;
  marketplaceEulas: Progress<MarketplaceEula[]>;
  purchaseMode: PurchaseMode;
  unavailableMarketplaceAddons: Progress<String[]>;
  // eslint-disable-next-line react/no-unused-prop-types
  stripeSelected: boolean;
  inSiteConfiguratorMode: boolean;
  canEditContactInSiteConfigurator: boolean;
  currentContactEmail: string | null;
};

type DispatchProps = {
  onContactDetailsEdit: () => void;
  onDelete: (number) => void;
  onEulaAcceptedChange: () => void;
  onPaymentDetailsEdit: () => void;
  onRemovePayment: () => void;
  goToCardErrorRoute: () => void;
  onSubmit: (e: React.BaseSyntheticEvent) => any;
  onResetCart: () => void;
  options: Option[];
  // eslint-disable-next-line react/no-unused-prop-types
  continueMonitoringStatus: (cartId: string) => any;
  afterPaymentAuthentication: (input: ProcessOrderAfterPaymentAuthenticationInput) => any;
};

type Props = StateProps & DispatchProps;

const Wrapper = styled.div`
  padding-top: ${Dimensions.units(3)}px;
`;

const Header = styled.h2`
  font-family: ${Font.displayFamily};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.3px;
  margin-bottom: ${Dimensions.units(7)}px;
`;

const SubHeader = styled.h3`
  font-family: ${Font.displayFamily};
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.3px;
  margin-bottom: ${Dimensions.units(3)}px;
`;

const isQuoteInPayingMode = (hamsCart: HamsCart) =>
  hamsCart.status === 'QUOTED' && !hamsCart.inEditMode;

const filterAvailableOptions = (hamsCart: HamsCart, options: Option[]) => {
  if (isQuoteInPayingMode(hamsCart)) {
    return [];
  }
  return options;
};

const useStripeOnSubmit = ({
  onSubmit,
  goToCardErrorRoute,
  cart: cartPayload,
  continueMonitoringStatus,
  afterPaymentAuthentication,
}: Props) => {
  const confirmCallback = useConfirmPostReviewNewPaymentMethod();
  const submitCallback = useCallback(
    async (event: React.BaseSyntheticEvent) => {
      const initialSubmitProgress = await onSubmit(event);

      if (initialSubmitProgress.failed) {
        return;
      }

      const hamsWantsUsToReauthenticateTheCard =
        initialSubmitProgress.result.status === 'AWAITING_AUTHENTICATION';
      if (!hamsWantsUsToReauthenticateTheCard) {
        return;
      }

      const client: AnalyticsClient = CartAnalyticsClient.getClient();

      const sendTrackGASV3Event = (status: string, succeeded: boolean) => {
        client.sendTrackEvent({
          action: TrackAction.Recieved,
          actionSubject: TrackActionSubject.CardPayment,
          source: TrackSource.ReviewPageVisual,
          attributes: {
            eventName: TrackEventNames.InitiateConfirmCardPayment,
            status,
            succeeded,
          },
        });
      };

      sendTrackGASV3Event('pending', false);

      const cartId = cartPayload.result.uuid;

      const confirmationForNewPaymentMethodResult = await confirmCallback({
        uuid: cartId,
      });

      try {
        await afterPaymentAuthentication({
          uuid: cartId,
        });
      } catch (_) {
        await goToCardErrorRoute();
      }

      if (!isSuccessful(confirmationForNewPaymentMethodResult)) {
        sendTrackGASV3Event('completed', false);
        await goToCardErrorRoute();
        return;
      }

      sendTrackGASV3Event('completed', true);

      const postAuthenticationSubmitProgress = await continueMonitoringStatus(cartId);
      const hamsStillWantsUsToReauthenticate =
        postAuthenticationSubmitProgress.status === 'AWAITING_AUTHENTICATION';
      if (hamsStillWantsUsToReauthenticate) {
        throw new Error('Authenticated payment but still received AWAITING_AUTHENTICATION');
      }
    },
    [goToCardErrorRoute, onSubmit],
  );

  return submitCallback;
};

const EulaActionLine = (props: any) => <ActionLine {...props} />;

export const ReviewPageVisual = (props: Props) => (
  <PageLoading
    progress={Progress.all(props.cart, props.marketplaceEulas, props.unavailableMarketplaceAddons)}
  >
    {([cartPayload, marketplaceEulas]) =>
      !cartPayload ? null : (
        <Wrapper>
          <>
            {props.purchaseMode === 'BUY' ? (
              <Header>{t('review.review-order')}</Header>
            ) : (
              <Header>{t('review.review-quote')}</Header>
            )}
            <div>
              <SubHeader>{t('review.your-products')}</SubHeader>
              <CartSummary
                cart={cartPayload}
                deletingItems={props.deletingItems}
                onDelete={props.onDelete}
                options={filterAvailableOptions(cartPayload, props.options)}
                purchaseMode={props.purchaseMode}
                inSiteConfiguratorMode={props.inSiteConfiguratorMode}
              />
            </div>
            <DetailsSection
              cart={cartPayload}
              purchaseMode={props.purchaseMode}
              onPaymentDetailsEdit={props.onPaymentDetailsEdit}
              onContactDetailsEdit={props.onContactDetailsEdit}
              onRemovePayment={props.onRemovePayment}
              inSiteConfiguratorMode={props.inSiteConfiguratorMode}
              canEditContactInSiteConfigurator={props.canEditContactInSiteConfigurator}
            />
            <EulaActionLine
              cart={cartPayload}
              marketplaceEulas={marketplaceEulas}
              reviewState={props.reviewState}
              onEulaAcceptedChange={props.onEulaAcceptedChange}
              onSubmit={props.onSubmit}
              onResetCart={props.onResetCart}
              purchaseMode={props.purchaseMode}
              inSiteConfiguratorMode={props.inSiteConfiguratorMode}
              goToCardErrorRoute={props.goToCardErrorRoute}
              currentContactEmail={props.currentContactEmail}
            />
          </>
        </Wrapper>
      )
    }
  </PageLoading>
);

const StripeReviewPageVisual = (props: Props) => {
  const stripeOnSubmit = useStripeOnSubmit(props);

  return <ReviewPageVisual {...props} onSubmit={stripeOnSubmit} />;
};

const StripeReviewPageVisualIfSelected = (props: Props) => {
  const SelectedReviewPageVisualComponent = props.stripeSelected
    ? StripeReviewPageVisual
    : ReviewPageVisual;

  return <SelectedReviewPageVisualComponent {...props} />;
};

const mapStateToProps = (state: State): StateProps => ({
  cart: selectCartPayload(state),
  deletingItems: selectCartItemsBeingDeleted(state),
  reviewState: state.checkout.review,
  marketplaceEulas: selectMarketplaceAddonEulas(state),
  purchaseMode: selectPurchaseMode(state),
  stripeSelected: isStripeCheckoutModeSelected(state),
  unavailableMarketplaceAddons: selectUnavailableMarketplaceAddonInCart(state),
  inSiteConfiguratorMode: selectInSiteConfiguratorMode(state),
  canEditContactInSiteConfigurator: canEditContactInSiteConfigurator(state),
  currentContactEmail: getCurrentContactEmail(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onDelete: (itemId: number) => {
    traceUFOPress('items-delete');
    dispatch(cart.actions.removeItems(itemId));
  },
  onContactDetailsEdit: () => dispatch(contact.actions.route()),
  onPaymentDetailsEdit: () => dispatch(payment.actions.route()),
  onEulaAcceptedChange: () => dispatch(toggleEula()),
  onSubmit: () => dispatch(submit()),
  continueMonitoringStatus: (cartId: string) => dispatch(monitorStatus(cartId)),
  goToCardErrorRoute: () => dispatch(payment.actions.route({ cardError: true })),
  onRemovePayment: () => dispatch(payment.actions.removePayment()),
  onResetCart: () => dispatch(cart.actions.resetCart()),
  afterPaymentAuthentication: (input: ProcessOrderAfterPaymentAuthenticationInput) =>
    dispatch(processOrderAfterPaymentAuthentication(input)),
  options: [
    {
      label: t('review.new-product'),
      to: goToProductList(),
    },
    {
      label: t('review.maintenance-renewal'),
      to: renew.landing.actions.route(),
    },
    {
      label: t('review.license-upgrade'),
      to: upgrade.landing.actions.route(),
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(StripeReviewPageVisualIfSelected);
