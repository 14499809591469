// @flow
import React from 'react';
import { connect } from 'react-redux';
import Progress from 'redux-progress';
import CloudProductConfiguration from 'modules/configure/CloudProductConfiguration';
import ServerProductConfiguration from 'modules/configure/ServerProductConfiguration';
import { selectors } from 'modules/configure';
import PageLoading from 'components/common/PageLoading';
import type { HamsProductPricing } from 'model/hams/Pricing';
import { isHosted } from 'model/hams/Pricing';
import type { State } from 'model/State';

type Props = {
  pricing: Progress<HamsProductPricing | null>,
};

const SoftwareProductConfiguration = (props: Props) => (
  <PageLoading progress={props.pricing}>
    {(pricing) =>
      pricing &&
      (isHosted(pricing.productType) ? (
        <CloudProductConfiguration />
      ) : (
        <ServerProductConfiguration />
      ))
    }
  </PageLoading>
);

const mapStateToProps = (state: State): Props => ({
  pricing: selectors.selectMainProductPricing(state),
});

export default connect(mapStateToProps)(SoftwareProductConfiguration);
