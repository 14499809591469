/* eslint-disable import/prefer-default-export */
// @flow
import type { PerProductConfiguration } from 'modules/configure/duck/model';
import type { ContainsProductKey } from 'modules/configure/duck/selectors/index';
import type { Selector } from 'model/State';

type SelectorType = Selector<PerProductConfiguration | null, ContainsProductKey>;

export const getPerProductConfiguration: SelectorType = (state, { productKey }) => {
  if (!state.configure) {
    return null;
  }
  if (state.configure.product.productKey === productKey) {
    return state.configure.product;
  }
  return state.configure.addons.find((a) => a.productKey === productKey) || null;
};
