export const Dimensions = {
  unit: 8,
  pageWidth: 960,
  units: (n: number) => n * Dimensions.unit,
};

export const Font = {
  family:
    'CharlieText, -apple-system, system-ui, "Segoe UI", Roboto, "Noto Sans", Ubuntu, "Droid Sans", "Helvetica Neue", sans-serif',
  displayFamily:
    'CharlieDisplay, -apple-system, system-ui, "Segoe UI", Roboto, "Noto Sans", Ubuntu, "Droid Sans", "Helvetica Neue", sans-serif',
};
