import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import t from 'modules/i18n/intl';

import { Radio } from '@atlaskit/radio';
import { BindProps } from 'modules/form';
import Help from 'components/common/Help';

type TaxIdRadioProps = {
  vatResponsible: boolean | null;
  isTaxIdFieldEmpty: boolean;
};

const TaxIdRadio = ({ field, vatResponsible, isTaxIdFieldEmpty }: BindProps<TaxIdRadioProps>) => {
  useEffect(() => {
    if (!!field && (vatResponsible || vatResponsible === false) && !isTaxIdFieldEmpty) {
      field.edit(vatResponsible);
    } else {
      field.edit(null);
    }
  }, [isTaxIdFieldEmpty]);

  const radioChangeHandle = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === 'required') {
      field.edit(true);
    } else if (e.currentTarget.value === 'notRequired') {
      field.edit(false);
    }
  }, []);

  if (!field || isTaxIdFieldEmpty) return null;

  return (
    <>
      <Radio
        isChecked={vatResponsible === true}
        name="vatRequired"
        value="required"
        label={
          <>
            {t('checkout.contact.tax-id-vat-required')}
            <Help>{t('checkout.contact.tax-id-vat-required.help')}</Help>
          </>
        }
        onChange={radioChangeHandle}
      />
      <Radio
        isChecked={vatResponsible === false}
        name="vatNotRequired"
        value="notRequired"
        label={t('checkout.contact.tax-id-vat-not-required')}
        onChange={radioChangeHandle}
      />
    </>
  );
};

export default TaxIdRadio;
