// @flow
import React from 'react';
import paypal from 'paypal-checkout';
import styled from 'styled-components';
import type { PaymentPageProps } from 'modules/checkout/payment/duck/model';
import { Fineprint } from 'modules/checkout/styled';
import t from 'modules/i18n/intl';
import { Dimensions } from 'components/visuals';
import config from 'appconfig';

const Wrapper = styled.div`
  ${(props) => !props.visible && 'display: none'};
`;
const Subtext = styled(Fineprint)`
  width: ${Dimensions.units(50)}px;
  margin-left: auto;
`;

export default class PaypalButton extends React.PureComponent<PaymentPageProps> {
  async componentDidMount() {
    const checkout = this.props.paypal.createInstance();
    paypal.Button.render(
      {
        env: config.paypal.env,
        style: {
          size: 'medium',
          shape: 'rect',
          tagline: false,
        },
        payment: async () =>
          (await checkout).createPayment({
            displayName: 'Atlassian',
            flow: this.props.autoRenewal.items.some((i) => i.selected) ? 'vault' : 'checkout',
            amount: this.props.totalIncTax,
            currency: this.props.currency,
          }),
        onAuthorize: async (data) => {
          const token = await (await checkout).tokenizePayment(data);
          await this.props.paypal.authorize(token);
        },
        onError: this.props.paypal.onFail,
      },
      '#paypal-button',
    );
  }

  render() {
    return (
      <Wrapper visible={this.props.paypal.isSelected}>
        <div id="paypal-button" />
        <Subtext>{t('payment.paypal.payment-authorization')}</Subtext>
      </Wrapper>
    );
  }
}
