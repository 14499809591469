import { AxiosRequestConfig } from 'axios';
import hamsClient from 'modules/hams';
import { ValidateCountry } from 'modules/checkout/contacts/duck/model';

/* Validates that the supplied contact and country are a valid combination. */
const validateCountryPurchaserContactMatchApi = async (
  country: string,
  email: string,
  hamsClientConfig: AxiosRequestConfig,
): Promise<ValidateCountry> =>
  (
    await hamsClient.post(
      '1.0/public/contact/validate-country-change',
      {
        country,
        email,
      },
      hamsClientConfig,
    )
  ).data;

/* Checks that the supplied contact and country are a valid combination.
    A less verbose version of /validate-country-change, doesn't require auth */
const checkCountryTechnicalContactMatchApi = async (
  country: string,
  email: string,
  hamsClientConfig: AxiosRequestConfig,
): Promise<ValidateCountry> =>
  (
    await hamsClient.post(
      '1.0/public/contact/check-country-change',
      {
        country,
        email,
      },
      hamsClientConfig,
    )
  ).data;

export { validateCountryPurchaserContactMatchApi, checkCountryTechnicalContactMatchApi };
