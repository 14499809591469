// @flow
import delay from 'util/delay';
import { thunkProgress } from 'redux-progress';
import hamsClient from 'modules/hams';

import next from 'modules/checkout/review/duck/actions/next';
import type { Dispatch, GetState } from 'model/State';
import { selectHamsClientConfig } from 'modules/auth/selectors';

import * as payment from 'modules/checkout/payment';

export const FETCH_STATUS = 'checkout/review/FETCH_STATUS';
export const REQUEST_TIMEOUT = 'checkout/review/REQUEST_TIMEOUT';
const STATUS_AWAITING_AUTHENTICATION = 'AWAITING_AUTHENTICATION';

export const monitorStatus = (cartId: string) => async (dispatch: Dispatch, getState: GetState) => {
  const monitor = async () => {
    const response = await hamsClient.post(
      `/1.0/public/order/status/${cartId}`,
      undefined,
      selectHamsClientConfig(getState()),
    );
    switch (response.data.status) {
      case 'INCOMPLETE':
        await delay(500);
        return monitor();
      case 'AWAITING_AUTHENTICATION':
      case 'PROCESSING_COMPLETE':
      case 'REVIEW_REQUIRED':
        return response.data;
      default:
        throw response.data.status;
    }
  };

  const processError = (error) => {
    if (
      error === 'CREDIT_CARD_DECLINED' ||
      error === 'PAYMENT_FAILED' ||
      error === 'PROCESSING_FAILED'
    ) {
      dispatch(payment.actions.route({ cardError: true }));
    } else if (error === 'MISSING_PO_NUMBER') {
      dispatch(payment.actions.route({ missingPoNumber: true }));
    }
  };

  dispatch({ type: REQUEST_TIMEOUT, payload: false });
  const stopwatchId = setTimeout(() => dispatch({ type: REQUEST_TIMEOUT, payload: true }), 30000);
  try {
    const result = await dispatch(thunkProgress(FETCH_STATUS, monitor()));
    result.fold({
      success: (monitorResponse) => {
        if (monitorResponse.status === STATUS_AWAITING_AUTHENTICATION) {
          // Needs to be handled by Stripe CC package hooks instead
          return monitorResponse;
        }
        return dispatch(next());
      },
      failed: processError,
    });

    return result.unwrap();
  } finally {
    clearTimeout(stopwatchId);
  }
};
