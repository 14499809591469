import config from 'appconfig';

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import paramsSerializer from 'util/paramsSerializer';

import { getCohortId } from 'modules/meta/cohort';

export class HamsError extends Error {
  constructor(public readonly response: AxiosResponse) {
    super(`Hams Error ${response.status}: ${JSON.stringify(response.data)}`);
    Object.assign(this, response.data, { response });
  }
}

const createHamsClient = (clientConfig: AxiosRequestConfig = {}) => {
  const instance = axios.create({
    baseURL: config.hamsUrl,
    maxContentLength: 50000,
    paramsSerializer,
    withCredentials: true,
    xsrfCookieName: 'ATL-XSRF-Token',
    xsrfHeaderName: 'ATL-XSRF-Token',
    ...clientConfig,
    headers: {
      'atlassian-cohort': getCohortId(),
      ...clientConfig.headers,
    },
  });

  instance.interceptors.response.use(undefined, (e) => {
    if (e.response && e.response.data) {
      throw new HamsError(e.response);
    }
    throw e;
  });

  return instance;
};

export default createHamsClient;
