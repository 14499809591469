import { getActionPath } from 'util/ActionLink';
import React from 'react';
import styled from 'styled-components';

import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';

import { Props } from 'modules/addon/VisualAddonPage';
import { Font } from 'components/visuals';
import * as marketplace from 'modules/marketplace';
import t from 'modules/i18n/intl';

const Wrapper = styled.div`
  font-family: ${Font.family};
`;

const AddonNotFoundBanner = (props: Props) => (
  <Wrapper>
    <SectionMessage
      appearance="error"
      title={t('addon.not-found.title')}
      actions={[
        {
          text: t('addon.not-found.search'),
          // @ts-ignore SectionMessage doesn't declare the "e" arg in the type, but it does provide it and we need it!
          onClick: (e: any) => {
            e.preventDefault();
            props.events.onSearchForAddonsClick();
          },
          href: getActionPath(marketplace.actions.route()),
        },
      ].map(({ text, ...restAction }) => (
        <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
      ))}
    >
      {t('addon.not-found.text')}
    </SectionMessage>
  </Wrapper>
);

export default AddonNotFoundBanner;
