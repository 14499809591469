import { HamsCart$Contacts } from 'modules/cart/duck/model';
import { ContactDetails, OrganisationDetails } from 'model/hams/contact';

export type CheckoutContactsData = HamsCart$Contacts & {
  isDifferentBilling: boolean;
  isDifferentTechnical: boolean;
};

export const defaultContacts: ContactDetails = Object.freeze({
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
});
export const defaultOrganisation: OrganisationDetails = Object.freeze({
  address1: '',
  address2: '',
  city: '',
  isoCountryCode: '',
  organisationName: '',
  postcode: '',
  state: '',
  taxId: '',
});

export const defaultData: CheckoutContactsData = Object.freeze({
  purchaserContactDetails: defaultContacts,
  billingContactDetails: defaultContacts,
  billingOrganisationDetails: defaultOrganisation,
  technicalContactDetails: defaultContacts,
  technicalOrganisationDetails: defaultOrganisation,
  resellerOrder: false,
  expertOrder: false,
  isDifferentBilling: false,
  isDifferentTechnical: false,
});
