// @flow
import merge from 'lodash/merge';
import { envTypes } from '@atlassiansox/metal-client';
import type { EnvironmentConfig } from 'model/EnvironmentConfig';
import staging from 'appconfig/envs/staging';

export default (): EnvironmentConfig =>
  merge(staging(), {
    clicktaleEnabled: false,
    hamsUrl: 'http://hamlet.bizbox.atlassian.com:8888',
    idLoginUrl: 'http://auth.bizbox.atlassian.com:8888',
    launchdarklyId: '5760d1160b163a072400013c',
    logoutUrl: () =>
      `http://auth.bizbox.atlassian.com:8888/?continue=${encodeURIComponent(
        document.location.href,
      )}`,
    macUrl: 'http://my.bizbox.atlassian.com:8888',
    tnsSessionUrl: 'http://hamlet.bizbox.atlassian.com:8888/tns/session.js',
    i18nUrl: 'https://cart-static.ap-southeast-2.dev.public.atl-paas.net/i18n',
    sentry: {
      environment: 'local',
    },
    metal: {
      environment: envTypes.LOCAL,
    },
    root: 'http://localhost:3000/purchase',
  });
