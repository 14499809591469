import { combineReducers } from 'redux';
import contacts from 'modules/checkout/contacts';
import payment from 'modules/checkout/payment';
import quote from 'modules/checkout/quote';
import review from 'modules/checkout/review';
import steps from 'modules/checkout/steps';
import complete from 'modules/checkout/complete';
import additionalDetails from 'modules/checkout/additionalDetails';

export default combineReducers({
  complete,
  contacts,
  payment,
  quote,
  review,
  steps,
  additionalDetails,
});
