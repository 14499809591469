import { getCohortId } from 'modules/meta/cohort';
import MetalClient from '@atlassiansox/metal-client';

const createMetalClient = ({ env, buildVersion = '0.0.0' }) => {
  const productInfo = {
    env,
    metalId: 'b8e17fa0-0b35-11ea-8d71-362b9e155667', // include your Metal ID
    version: buildVersion, // '123', The version of your application
  };

  const settings: any = {
    meta: {},
  };

  const cohortNumber = getCohortId();
  if (typeof cohortNumber === 'number' && cohortNumber > 0 && cohortNumber <= 100) {
    let rolloutStage;
    if (cohortNumber === 1) {
      rolloutStage = '1';
    } else if (cohortNumber <= 10) {
      rolloutStage = '2';
    } else if (cohortNumber <= 50) {
      rolloutStage = '3';
    } else if (cohortNumber <= 90) {
      rolloutStage = '4';
    } else {
      rolloutStage = '5';
    }

    settings.meta.rollout_stage = rolloutStage;
  }

  return new MetalClient({ productInfo, settings });
};

export default createMetalClient;
