// @flow
import type { AxiosRequestConfig } from 'axios';
import hamsClient, { HamsError } from 'modules/hams';
import type { AccountChangeOption } from 'modules/accounts/model';
import { setInteractionError } from '@atlassian/ufo-set-interaction-error';

import { isHamsConcurrencyError } from 'util/apiHelper';

export const fetchUpgradeCrossgradeList = (hamsClientConfig: AxiosRequestConfig) =>
  hamsClient
    .get(
      '/1.0/public/account/crossgrade/list',
      {
        params: {
          _: new Date().getTime(),
        },
      },
      hamsClientConfig,
    )
    .then((r) => r.data);

export const addUpgradeItemsToCart = (
  cartId: string,
  accountChanges: AccountChangeOption[],
  hamsClientConfig: AxiosRequestConfig,
) =>
  hamsClient
    .post(
      `/1.0/public/order/cart/${cartId}/addAccountCrossgradeItemsToCart`,
      {
        accountChanges: accountChanges.map((accountChangeOption) => ({ accountChangeOption })),
      },
      {
        ...hamsClientConfig,
        validateStatus: (status) => (status >= 200 && status < 300) || status === 400,
      },
    )
    .then((r) => {
      const hamsConcurrencyError = isHamsConcurrencyError(r);
      if (r.status === 400 && !hamsConcurrencyError) {
        throw new HamsError(r);
      } else if (r.status === 400 && hamsConcurrencyError) {
        return new HamsError(r);
      } else {
        return r.data;
      }
    })
    .catch(() => {
      setInteractionError('upgrade-submit-changelist', {
        errorMessage: 'upgrade-submit-changelist',
        name: 'upgrade-submit-changelist',
      });
      throw new Error('Error while adding upgrade item to cart');
    });

export const fetchUpgradeOptionsForAccount = (
  accountId: string,
  email: string | null,
  hamsClientConfig: AxiosRequestConfig,
) =>
  hamsClient
    .post(
      '/1.0/public/account/crossgradeOptions',
      { accountId, email },
      {
        ...hamsClientConfig,
        validateStatus: (status) => status === 404 || (status >= 200 && status < 300),
      },
    )
    .then((r) => (r.status === 404 ? null : r.data))
    .catch(() => {
      setInteractionError('upgrade-changeoptions', {
        errorMessage: 'fetch upgrade tiers options error',
        name: 'fetch upgrade tiers options error',
      });
      throw new Error('Error while fetching upgrade tiers options');
    });
