import React, { useCallback } from 'react';

import {
  useHAMSCreatePaymentMethod,
  CreditCardForm,
  CreditCardErrorMessage,
} from '@atlassian/commerce-ui/credit-card-hams';

import { storePaymentDetails, handleFailures, isSuccessful, useHAMSGateway } from 'modules/stripe';

export const useSubmitPaymentMethod = () => {
  const createPaymentMethod = useHAMSCreatePaymentMethod();
  const gateway = useHAMSGateway();

  const submitCallback = useCallback(async () => {
    const result = await createPaymentMethod!();

    handleFailures(result);

    if (!isSuccessful(result)) {
      return result;
    }

    const { paymentMethod } = result.payload;

    storePaymentDetails(gateway!, paymentMethod);

    return result;
  }, [createPaymentMethod]);

  if (createPaymentMethod === null) {
    return null;
  }

  return submitCallback;
};

export const Form = () => <CreditCardForm autoFocus frameEmbeddingMitigation={false} />;

export { CreditCardErrorMessage as ErrorMessage };
