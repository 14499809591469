import { progressResult, selectLatestFrom } from 'util/rxOperators';
import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { actions, selectors } from 'modules/checkout/contacts/duck';
import { State$ } from 'model/State';
import { CheckoutContactsData } from 'modules/checkout/contacts/duck/model';

export default (action$: Observable<any>, state$: State$) =>
  action$.pipe(
    ofType(actions.EDIT),
    filter((action) => action.path === 'resellerOrder'),
    selectLatestFrom(state$),
    progressResult(selectors.getData),
    map((d: CheckoutContactsData) => d.resellerOrder),
    distinctUntilChanged(),
    map((isReseller) => actions.edit('isDifferentTechnical', isReseller)),
  );
