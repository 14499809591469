import { progressResult } from 'util/rxOperators';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import * as properties from 'modules/tracking/selectors/props';
import { CartEpic } from 'model/State';
import { createTrackEvent, utils } from 'modules/analytics';
import {
  TrackAction,
  TrackActionSubject,
  TrackSource,
  TrackEventNames,
} from 'modules/analytics/model/TrackEvent';

export const fetchContactDetails: CartEpic = (action$, state$) =>
  state$.pipe(
    progressResult(properties.contact),
    distinctUntilChanged(),
    filter(Boolean),
    map((contactDetails) =>
      createTrackEvent(
        utils.getTrackEventData(
          TrackAction.Fetched,
          TrackActionSubject.ContactDetails,
          TrackSource.Identity,
          TrackEventNames.ContactDetailsFetched,
        ),
        contactDetails,
      ),
    ),
  );

export default fetchContactDetails;
