import { combineReducers } from 'redux';
import creditCard from './creditCard';
import checkoutMode from './checkoutMode';
import checkoutMethodsAvailable from './checkoutMethodsAvailable';
import eligibleAutoRenewal from './eligibleAutoRenewal';
import selectedAutoRenewItems from './selectedAutoRenewItems';
import submission from './submission';
import payOnTermAgreed from './payOnTermAgreed';
import paypal from './paypal';

export default combineReducers({
  checkoutMode,
  checkoutMethodsAvailable,
  creditCard,
  eligibleAutoRenewal,
  selectedAutoRenewItems,
  submission,
  payOnTermAgreed,
  paypal,
});
