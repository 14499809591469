import Progress, { thunkProgress } from 'redux-progress';
import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import hamsClient from 'modules/hams';

import { getProduct } from 'data/products';
import { ApiKeysDictionary } from 'data/products/keys';
import { HamsProductPricing } from 'model/hams/Pricing';
import { LICENSE_ACADEMIC, LICENSE_COMMERCIAL, LICENSE_STARTER } from 'model/hams/LicenseType';
import { Dispatch, GetState, Selector, State } from 'model/State';
import ProductTier from 'model/hams/ProductTier';
import selectCurrency from 'modules/cart/duck/selectors/currency';
import watchProgress from 'model/watchProgress';
import selectCartPayload from 'modules/cart/duck/selectors/cart';
import { HamsCart } from 'modules/cart/duck/model';

export type PricingMap = {
  [currency: string]: {
    [product: string]: Progress<HamsProductPricing>;
  };
};

export const PRICING_FETCH = 'catalog/pricing/FETCH';

export const generateTiers = (productPricing: HamsProductPricing): ProductTier[] => {
  const tiers: ProductTier[] = [];
  productPricing.orderableItems.forEach((item) => {
    let tier = tiers.find((t) => t.unitCount === item.unitCount);
    if (!tier) {
      tier = new ProductTier(item.unitCount, item.unitLabel, productPricing.productKey);
      tiers.push(tier);
    }
    tier.pricingPlans[item.licenseType] = item;
  });
  return sortBy(
    tiers,
    (t) => t.isUnlimited(),
    (t) => t.unitCount,
  );
};

export const selectProductPricing = (
  state: State,
  props: { productKey: string },
): Progress<HamsProductPricing> =>
  selectCurrency(state).flatMap(
    (currency) =>
      state.catalog.pricing[currency] && state.catalog.pricing[currency][props.productKey],
  ) || Progress.none;

export const makeSelectProductTiers = () =>
  createSelector(selectProductPricing, (pricing) => pricing.map((p) => p && generateTiers(p)));

export const selectProductTiers: Selector<
  Progress<ProductTier[]>,
  { productKey: string }
> = makeSelectProductTiers();

export const makeSelectDefaultTier = () =>
  createSelector(makeSelectProductTiers(), (tiers) =>
    tiers.ifSuccess((result) => result[0] || null),
  );

const selectStaticProduct = (_, { productKey }: { productKey: string }) => getProduct(productKey);

export const makeSelectProductName = () =>
  createSelector(selectStaticProduct, selectProductPricing, (product, pricing) =>
    product ? product.name : pricing.ifSuccess((info) => info.productDescription),
  );

export const makeSelectProductDescription = () =>
  createSelector(selectStaticProduct, selectProductPricing, (product, pricing) =>
    product ? product.description : pricing.ifSuccess((info) => info.productDescription),
  );

export const makeSelectProductMoreLink = () =>
  createSelector(selectStaticProduct, (product) => product?.moreLink);

export const makeIsMarketplaceAddon = () =>
  createSelector(selectStaticProduct, selectProductPricing, (product, pricing) =>
    product
      ? product.vendor !== 'Atlassian'
      : pricing.ifSuccess((info) => info.marketplaceAddon) === true,
  );

export const makeSelectProductTagline = () =>
  createSelector(selectStaticProduct, (product) => product?.tagLine);

const selectPresentPricingKeys = (state: State): string[] =>
  selectCurrency(state).ifSuccess((currency) => {
    const productMap = state.catalog.pricing[currency];
    return (
      productMap &&
      Object.keys(productMap).filter(
        (key) => productMap[key].success === true || productMap[key].inProgress === true,
      )
    );
  }) || [];

const toApiKey = (key) => ApiKeysDictionary[key] || key;

export const hamsFetchPricing = (keys: string[], currency) =>
  hamsClient.get('/1.0/public/pricing/public/query', {
    params: {
      productKey: keys.map(toApiKey),
      requestingApplication: 'WAC',
      licenseType: [LICENSE_ACADEMIC, LICENSE_COMMERCIAL, LICENSE_STARTER],
      currency,
    },
  });

/**
 * Fetches the pricing of the requested products from Hams.
 * It caches data from previous calls in the state.
 * It returns a Progress of an array of the requested pricings
 * @param keys Product keys to fetch pricing for
 * @returns {Progress<HamsProductPricing[]>} A Progress of an array of pricings,
 * corresponding to the provided keys.
 */
export const fetchPricing = (...keys: string[]) => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const storeKeys = selectPresentPricingKeys(getState());

  const keysToBeFetched = keys.filter((key) => !storeKeys.includes(key));
  if (keysToBeFetched.length > 0) {
    const cart: HamsCart = await watchProgress(selectCartPayload);
    let { currency } = cart;
    if (cart.currentEndUserCurrency) {
      currency = cart.currentEndUserCurrency;
    }
    const hamsPromise = hamsFetchPricing(keysToBeFetched, currency);

    const extractProduct = (productKey: string) => (response) =>
      response.data.products.find((product) => product.productKey === toApiKey(productKey)) || null;

    await Promise.all(
      keysToBeFetched.map((productKey) =>
        dispatch(
          thunkProgress(PRICING_FETCH, hamsPromise.then(extractProduct(productKey)), {
            payload: { productKey, currency },
          }),
        ),
      ),
    );
  }

  return Progress.all(
    ...keys.map((productKey) => selectProductPricing(getState(), { productKey })),
  );
};

const reducer = (state: PricingMap = {}, action: any) => {
  switch (action.type) {
    case PRICING_FETCH:
      return {
        ...state,
        [action.payload.currency]: {
          ...state[action.payload.currency],
          [action.payload.productKey]: action.progress,
        },
      };
    default:
      return state;
  }
};

export default reducer;
