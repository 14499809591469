import React from 'react';
import SectionMessage from '@atlaskit/section-message';

import { Props } from 'modules/addon/VisualAddonPage';
import Parent from 'modules/addon/Parent';
import t from 'modules/i18n/intl';

const ResultBody = (props) => <Parent isSelected {...props} />;

const NoResult = () => (
  <SectionMessage appearance="warning" title={t('addon.search.error.no-results.title')}>
    {t('addon.search.error.no-results.text')}
  </SectionMessage>
);

const Failed = () => (
  <SectionMessage appearance="warning" title={t('addon.search.error.invalid.title')}>
    {t('addon.search.error.invalid.text')}
  </SectionMessage>
);

const SearchResult = (props: Props) =>
  props.data.searchParentOption.fold({
    success: (result) => (result ? <ResultBody parent={result} {...props} /> : <NoResult />),
    failed: () => <Failed />,
  });

export default SearchResult;
