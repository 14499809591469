import Progress from 'redux-progress';
import * as actions from 'modules/checkout/contacts/duck/actions';

const defaultState = {
  email: null,
  progress: Progress.none,
};

export default (state = defaultState, action) => {
  const { type, email, progress } = action;
  switch (type) {
    case actions.LOAD:
      return defaultState;
    case actions.VALIDATE_PURCHASER_LOGIN:
      return { email, progress };
    default:
      return state;
  }
};
