import type { Selector, State as S } from 'model/State';
import { AccountDetail } from '../models';

export const selectCMTSelfServeLink: Selector<unknown> = (state: S) =>
  state.mac.accountId.CMTSelfServeLink?.ifSuccess((r) => r);

export const selectChildrenOverviews: Selector<unknown> = (state: S) =>
  state.mac.accountId.childrenOverviews?.ifSuccess((r) => r);

export const selectDetails: (state: S, accountId: string) => AccountDetail = (
  state: S,
  accountId: string,
) => state.mac.accountId.details?.[accountId]?.ifSuccess((r) => r);

export const selectIsOnBehalfOfEmail: Selector<unknown> = (state: S) =>
  state.mac.accountId.isOnBehalfOfEmail?.ifSuccess((r) => r);

export const selectIsMACSiteApplicableForConcession: Selector<unknown> = (state: S) =>
  state.mac.accountId.isSiteApplicableForConcession?.ifSuccess((r) => r);

export const selectIsMACSiteOnConcession: Selector<unknown> = (state: S) =>
  state.mac.accountId.isSiteOnConcession?.ifSuccess((r) => r);
