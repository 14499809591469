import { ProductGroupKey } from 'data/products/keys';
import { Group } from 'modules/catalog/groups';
import { DATACENTER, SERVER, CLOUD } from 'model/hams/DeploymentOption';
import SupportProduct from 'model/SupportProduct';

export default class SupportProductGroup {
  key: ProductGroupKey;
  server: SupportProduct;
  dataCenter: SupportProduct;

  constructor(server: SupportProduct, dataCenter: SupportProduct) {
    if (server.groupKey === null || dataCenter.groupKey === null) {
      throw Error(`Null group key in Support product group: ${server.key} / ${dataCenter.key}`);
    }

    this.key = server.groupKey;
    this.server = server;
    this.dataCenter = dataCenter;

    if (dataCenter && dataCenter.groupKey !== server.groupKey) {
      throw Error(`Mismatch in DC product group keys: ${this.key} / ${dataCenter.groupKey}`);
    }
  }

  getDeploymentOptions(): Group {
    const options: Group = {
      [CLOUD]: null,
      [SERVER]: null,
      [DATACENTER]: null,
    };

    if (this.server) {
      options[SERVER] = this.server.key;
    }

    if (this.dataCenter) {
      options[DATACENTER] = this.dataCenter.key;
    }

    return options;
  }
}
