/* eslint-disable no-param-reassign */
import { sum } from 'util/reducers';
import Progress from 'redux-progress';
import produce from 'immer';
import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import flatten from 'lodash/flatten';
import { HamsCart, HamsEnrichedCart, HamsCartEnrichedItem } from 'modules/cart/duck/model';
import { State, Selector } from 'model/State';

const sumProperty = (parent, mapper) => parent.childItems.map(mapper).reduce(sum, mapper(parent));

export const isParentItem = (item: HamsCartEnrichedItem) =>
  item.childItems && item.childItems.length > 0;

export const showTierLabel = (item: HamsCartEnrichedItem) => !isParentItem(item);

const cartPayloadTransformer = produce((draft) => {
  if (draft) {
    const allChildItems = draft.items.filter((i) => i.parentCartItemId);

    // pick parents
    draft.items = draft.items.filter((i) => !i.parentCartItemId);

    // group their children
    draft.items.forEach((parent) => {
      parent.childItems = allChildItems.filter((i) => i.parentCartItemId === parent.id);
      parent.totalIncTax = sumProperty(parent, (i) => i.totalIncTax);
      parent.totalTax = sumProperty(parent, (i) => i.totalTax);
      parent.discountAmount = sumProperty(parent, (i) => i.discountAmount);
      parent.creditAmount = sumProperty(parent, (i) => i.creditAmount);
      parent.creditApplications = [
        ...parent.creditApplications,
        ...flatten(parent.childItems.map((i) => i.creditApplications)),
      ];
      parent.childItems.forEach((i) => {
        delete i.creditApplications;
        delete i.totalIncTax;
        delete i.totalTax;
        delete i.discountAmount;
        delete i.creditAmount;
      });
    });

    // all the cloud parents go at the end of the list
    draft.items = sortBy(draft.items, (i) => (i.childItems.length > 0 ? 1 : 0));
  }
});

const selectCartPayload: Selector<Progress<HamsEnrichedCart>> = createSelector(
  (s: State) => s.cart.payload,
  (cartPayload) => cartPayload.map(cartPayloadTransformer),
);

export const selectCart: Selector<Progress<HamsCart | null>> = (state: State) => state.cart.payload;

export default selectCartPayload;
/* eslint-enable no-param-reassign */
