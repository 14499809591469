/* eslint-disable import/prefer-default-export */
// @flow
import Progress from 'redux-progress';
import type { QuotePageProps } from 'modules/checkout/quote/duck/model';
import * as actions from 'modules/checkout/quote/duck/actions';
import * as additionalDetails from 'modules/checkout/additionalDetails';
import type { State, Dispatch, Selector } from 'model/State';
import { setPurchaseMode } from 'modules/settings/purchaseMode';

const getCanCoTerm = (state: State): Progress<Boolean> =>
  state.checkout.quote.canCoTerm.failed ? Progress.success(true) : state.checkout.quote.canCoTerm;

// eslint-disable-next-line max-len
export const getPageProps = (
  state: State,
  dispatch: Dispatch,
): Selector<Progress<?QuotePageProps>> =>
  Progress.all(state.cart.payload, getCanCoTerm(state)).map(
    ([cart, coTerm]) =>
      cart && {
        submission: state.checkout.quote.submission,
        additionalDetails: additionalDetails.getProps(state, dispatch),
        hasTax: cart.totalTax > 0,
        canCoTerm: coTerm,
        onGoToPayment: () => dispatch(setPurchaseMode('BUY')),
        onSubmit: () => dispatch(actions.submit()),
      },
  );
