import styled from 'styled-components';
import { colors } from '@atlaskit/theme';

import { Dimensions, Font } from 'components/visuals';

export const Wrapper = styled.div`
  width: ${Dimensions.pageWidth}px;
  background-color: #fff;
  overflow: auto;
  display: block;
  transition: height 3s;
  padding-bottom: 20px;
  font-family: ${Font.family};

  h4 {
    font-weight: 400;
  }
`;

export const ColumnWrapper = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
`;

export const Column = styled.div`
  width: 33.33333333%;
  max-width: 300px;
  padding: 0 20px 0;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
`;

export const LinkWrapper = styled.div`
  margin-bottom: 16px;
`;

export const LogoWrapper = styled.div`
  &:hover {
    background: ${colors.N40};
  }
  padding: 4px;
  display: inline-block;
`;

export const Logo = styled.div``;

export const Header = styled.h2`
  font-weight: 500;
  font-family: ${Font.displayFamily}
  font-style: normal;
  color: #253858;
  font-size: .75rem;
  line-height: 18px;
  letter-spacing: 1px;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

export const Subtext = styled.p`
  color: #6C798F;
  line-height: 18px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: ${Font.family}
  font-style: normal;
  font-size: .75rem;
`;
