import React from 'react';
import styled from 'styled-components';
import {
  Column,
  ColumnWrapper,
  Header,
  LinkWrapper,
  Subtext,
  Wrapper,
} from 'components/navigation/top/dropdowns/commons';
import config from 'appconfig';
import Link from 'components/common/Link';
import { Font } from 'components/visuals';

const WAC = config.wacUrl;

const ColumnNoStyle = styled(Column)`
  margin-top: 42px;
`;

const SubHeader = styled.h4`
  font-weight: 400;
`;

export default () => (
  <Wrapper>
    <ColumnWrapper>
      <Column>
        <Header>By team size</Header>
        <Link to={`${WAC}/software/startups`}>
          <LinkWrapper>
            <div>
              <SubHeader>Startups</SubHeader>
              <Subtext>Great for startups, from incubator to IPO</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to={`${WAC}/software/small-business`}>
          <LinkWrapper>
            <div>
              <SubHeader>Small business</SubHeader>
              <Subtext>Get the right tools for your growing business</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to={`${WAC}/enterprise`}>
          <LinkWrapper>
            <div>
              <SubHeader>Enterprise</SubHeader>
              <Subtext>Learn how we make big teams successful</Subtext>
            </div>
          </LinkWrapper>
        </Link>
      </Column>

      <Column>
        <Header>By team function</Header>
        <Link to={`${WAC}/teams/software`}>
          <LinkWrapper>
            <div>
              <SubHeader>Software</SubHeader>
              <Subtext>Plan, build, &amp; ship quality products</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to={`${WAC}/teams/marketing`}>
          <LinkWrapper>
            <div>
              <SubHeader>Marketing</SubHeader>
              <Subtext>Bring together a winning strategy</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to={`${WAC}/teams/hr`}>
          <LinkWrapper>
            <div>
              <SubHeader>HR</SubHeader>
              <Subtext>Streamline people management</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to={`${WAC}/teams/legal`}>
          <LinkWrapper>
            <div>
              <SubHeader>Legal</SubHeader>
              <Subtext>Operate securely and reliably</Subtext>
            </div>
          </LinkWrapper>
        </Link>
      </Column>
      <ColumnNoStyle>
        <Link to={`${WAC}/teams/operations`}>
          <LinkWrapper>
            <div>
              <SubHeader>Operations</SubHeader>
              <Subtext>Run your business efficiently</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to={`${WAC}/teams/it`}>
          <LinkWrapper>
            <div>
              <SubHeader>IT</SubHeader>
              <Subtext>Provide great service and support</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to={`${WAC}/teams/finance`}>
          <LinkWrapper>
            <div>
              <SubHeader>Finance</SubHeader>
              <Subtext>Simplify all finance processes</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to={`${WAC}/teams/software`}>
          <LinkWrapper>
            <div>
              <SubHeader>Other</SubHeader>
              <Subtext>Deliver smart and fast in any team</Subtext>
            </div>
          </LinkWrapper>
        </Link>
      </ColumnNoStyle>
    </ColumnWrapper>
    <Link style={{ fontFamily: Font.family }} to={`${WAC}/software`}>
      View all products
    </Link>
  </Wrapper>
);
