import Progress from 'redux-progress';
import { parseZone } from 'moment';
import sortBy from 'lodash/sortBy';

import { createSelector } from 'reselect';
import { ParentData } from 'modules/addon/duck/model';
import selectValidParents from 'modules/addon/duck/selectors/validParents';
import {
  MultiProductTier,
  selectMergedAddonsProductTiers,
} from 'modules/addon/duck/selectors/mergedProductTiers';
import selectIsSubscription from 'modules/addon/duck/selectors/isSubscription';

import { LICENSE_ACADEMIC, LICENSE_COMMERCIAL, LICENSE_STARTER } from 'model/hams/LicenseType';
import { Selector } from 'model/State';
import { buildTermOptions } from 'modules/configure/duck/util';
import { selectAddonTermsAmount } from 'modules/configure/duck/selectors';

export const findTier = (tiers: MultiProductTier[], unitCount: number): MultiProductTier | null => {
  const unlimitedTier = tiers.find((t) => t.unitCount < 0) || null;
  if (unitCount < 0) {
    return unlimitedTier;
  }
  const nonUnlimitedTiers = tiers.filter((t) => t.unitCount >= 0);
  const matchingTiers = nonUnlimitedTiers.filter((t) => t.unitCount >= unitCount);
  return matchingTiers.length > 0 ? sortBy(matchingTiers, 'unitCount')[0] : null;
};

export const allowedLicenseTypes = [LICENSE_COMMERCIAL, LICENSE_ACADEMIC, LICENSE_STARTER];

const buildParentsFromCart = (
  cart,
  parents,
  tiers,
  chosenTerms,
  isSubscription,
  termsAmount,
): ParentData[] =>
  !cart
    ? []
    : cart.items
        .filter((item) => parents.includes(item.productDetails.productKey))
        .map((item) => ({ item, tier: findTier(tiers, item.productDetails.unitCount) }))
        .filter(({ tier }) => !!tier)
        .map(({ item, tier }) => {
          const { productDetails } = item;
          const key = `choose-cart-${item.id}`;
          const oi = tier.getOrderableItem(productDetails.licenseType);
          const terms = buildTermOptions(
            oi.renewalAmount,
            oi.monthsValid,
            isSubscription,
            termsAmount,
          );
          const term = chosenTerms[key] || terms[0];
          return {
            amount: oi.amount,
            cartItemId: item.id,
            key,
            name: productDetails.productDescription,
            productKey: productDetails.productKey,
            orderableItems: tier.getAllOrderableItems(productDetails.licenseType),
            term,
            terms,
            type: productDetails.licenseType,
            unitCount: productDetails.unitCount,
            unitLabel: productDetails.unitLabel,
          };
        });

const buildParentsFromAccounts = (
  overview,
  parents,
  tiers,
  chosenTerms,
  contact,
  isSubscription,
  termsAmount,
): ParentData[] =>
  !overview
    ? []
    : overview.accounts
        .filter((account) => parents.includes(account.productKey))
        .map((account) => ({ account, tier: findTier(tiers, account.unitCount) }))
        .filter(({ tier }) => !!tier)
        .map(({ account, tier }) => {
          const key = `choose-account-${account.accountId}`;
          const oi = tier.getOrderableItem(account.licenseType);
          const terms = buildTermOptions(
            oi.renewalAmount,
            oi.monthsValid,
            isSubscription,
            termsAmount,
          );
          const term = chosenTerms[key] || terms[0];

          return {
            amount: oi.amount,
            key,
            license: {
              accountId: account.accountId,
              accountName: account.accountName,
              available: allowedLicenseTypes.indexOf(account.licenseType) !== -1,
              email: contact.email,
              expiration: parseZone(account.expireDate),
            },
            name: account.productDescription,
            orderableItems: tier.getAllOrderableItems(account.licenseType),
            productKey: account.productKey,
            term,
            terms,
            type: account.licenseType,
            unitCount: account.unitCount,
            unitLabel: account.unitLabel,
          };
        });

const licensesComparator = (a, b) => {
  if (a.unitCount !== b.unitCount) {
    return b.unitCount - a.unitCount;
  }
  if (!a.license && !b.license) {
    return a.name.localeCompare(b.name);
  }
  if (!a.license) {
    return -1;
  }
  if (!b.license) {
    return 1;
  }
  return a.license.expiration - b.license.expiration;
};

const selectChooseParentOptions: Selector<Progress<ParentData[]>> = createSelector(
  (state) => state.account.overview,
  (state) => state.cart.payload,
  (state) => state.addon.terms,
  (state) => state.auth.currentContact,
  selectValidParents,
  selectMergedAddonsProductTiers,
  selectIsSubscription,
  selectAddonTermsAmount,
  (
    overviewProgress,
    cartProgress,
    chosenTerms,
    contactProgress,
    parentsProgress,
    tiersProgress,
    isSubscriptionProgress,
    termsAmountProgress,
  ) =>
    Progress.all(
      overviewProgress,
      cartProgress,
      contactProgress,
      parentsProgress,
      tiersProgress,
      isSubscriptionProgress,
      termsAmountProgress,
    ).map(([overview, cart, contact, parents, tiers, isSubscription, termsAmount]) =>
      buildParentsFromAccounts(
        overview,
        parents,
        tiers,
        chosenTerms,
        contact,
        isSubscription,
        termsAmount,
      )
        .concat(
          buildParentsFromCart(cart, parents, tiers, chosenTerms, isSubscription, termsAmount),
        )
        .sort(licensesComparator),
    ),
);

export default selectChooseParentOptions;
