import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { FormattedMessage } from 'react-intl';
import Button from '@atlaskit/button/standard-button';
import { Props } from 'modules/addon/VisualAddonPage';
import t from 'modules/i18n/intl';

import config from 'appconfig';
import { Dimensions, Font } from 'components/visuals';
import { getSoftwareProduct } from 'data/products';
import macLoginURITransformer from 'util/macLoginURITransformer';

const CloudWrapper = styled.div`
  font-family: ${Font.family};
  padding: ${Dimensions.units(2)}px;
  font-size: 16px;
`;

type WithDisabled = { disabled: boolean };

const TextWrapper = styled.p`
  color: ${(props: WithDisabled) => (props.disabled ? colors.N60 : 'inherit')};
`;

const ButtonWrapper = styled.div`
  margin: 12px 0 0 0;
`;

const CloudAddonConfiguration = ({ data, currentContactEmail }: Props) => {
  const disabled = Boolean(getSoftwareProduct(data.productKeys[0])?.isDisabled);

  const macAddonLink = macLoginURITransformer({
    url: `${config.macUrl}/addon/tryondemand/${data.productKeys[0]}`,
    currentContactEmail,
  });

  return (
    <CloudWrapper>
      <TextWrapper disabled={disabled}>
        <FormattedMessage
          id="addon.cloud.add-to-site.text"
          values={{
            addon: data.pricing[0].productDescription,
          }}
        />
      </TextWrapper>
      <ButtonWrapper>
        <Button
          href={macAddonLink}
          appearance="primary"
          isDisabled={disabled}
          data-cy="add-to-site-button"
        >
          {t('addon.cloud.add-to-site.cta')}
        </Button>
      </ButtonWrapper>
    </CloudWrapper>
  );
};

export default CloudAddonConfiguration;
