// @flow
import { thunkProgress } from 'redux-progress';
import compact from 'lodash/compact';
import remove from 'lodash/remove';

import * as api from 'modules/upgrade/duck/tiers/api';
import { fetchPaginatedActiveAccounts, OverviewParams } from 'modules/accounts/api';
import type { Dispatch, GetState } from 'model/State';
import { selectHamsClientConfig } from 'modules/auth/selectors';
import * as change from 'modules/change';
import * as actions from 'modules/change/search/duck/actions';
import type { AccountEntry } from 'modules/change/table/duck/model';
import { isSSSDisabled } from 'modules/change/table/duck/util';

const filterChangeOptions = (overview) => {
  overview.accounts
    .filter((acc) => acc.billingType === 'SUBSCRIPTION')
    .forEach((acc) => remove(acc.changeOptions, (option) => option.maintenanceMonths !== 12));
  return overview;
};

export const fetchPaginatedAccounts = (props: OverviewParams) => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const state = getState();

  if (state.change.table.paginated[`${props?.offset}`]) {
    return dispatch(
      thunkProgress(
        change.table.actions.LOAD_PAGINATED_CHANGE_LIST,
        state.change.table.paginated[`${props?.offset}`],
      ),
    );
  }

  const productKeys = (props && props.productKeys) || [];

  return dispatch(
    thunkProgress(
      change.table.actions.LOAD_PAGINATED_CHANGE_LIST,
      fetchPaginatedActiveAccounts(selectHamsClientConfig(state), { ...props, productKeys }),
    ),
  );
};

export const fetchAccountChangeOptions = (entry: AccountEntry, email: string | null) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  return dispatch(
    thunkProgress(
      change.table.actions.FETCH_ACCOUNT_CHANGE_OPTIONS,
      api
        .fetchUpgradeOptionsForAccount(entry.accountId, email, selectHamsClientConfig(getState()))
        .then((account) => ({ accounts: compact([account]) }))
        .then(filterChangeOptions),
      {
        payload: {
          accountId: entry.accountId,
          isSSSDisabled: isSSSDisabled(entry),
        },
      },
    ),
  );
};

export const fetchAccount = (accountId: string, email: string | null) => (
  dispatch: Dispatch,
  getState: GetState,
) =>
  dispatch(
    thunkProgress(
      change.table.actions.LOAD_CHANGE_LIST,
      api
        .fetchUpgradeOptionsForAccount(accountId, email, selectHamsClientConfig(getState()))
        .then((account) => ({ accounts: compact([account]) }))
        .then(filterChangeOptions),
    ),
  );

export const searchAccount = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  return dispatch(
    thunkProgress(
      change.search.actions.SUBMIT_FORM,
      api.fetchUpgradeOptionsForAccount(
        (state.change.search.sen || '').replace(/SEN-/i, ''),
        state.change.search.email || '',
        selectHamsClientConfig(state),
      ),
    ),
  ).then((accountProgress) =>
    dispatch(
      thunkProgress(actions.SCROLL_FORM, dispatch(actions.scrollForm(accountProgress, true))),
    ),
  );
};

export const resetAccount = () => (dispatch: Dispatch) => {
  dispatch(change.search.actions.resetForm());
};
