import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import styled from 'styled-components';

import { Props as ParentProps } from 'modules/addon/Parent';
import TierLabel from 'components/common/TierLabel';
import t from 'modules/i18n/intl';

type Props = ParentProps & { isSelected: boolean };

const SecondLine = styled.div`
  font-size: 14px;
`;

const ParentLabel = (props: Props) => (
  <>
    <div>
      {props.parent.name}
      &nbsp;
      <TierLabel {...props.parent} />: &nbsp;
      {t(`license.type.${props.parent.type.toLowerCase()}`)}
    </div>
    {props.parent.license && (
      <SecondLine>
        SEN-{props.parent.license.accountId}
        &nbsp; / &nbsp;
        {props.parent.license.accountName}
        &nbsp; / &nbsp;
        <FormattedMessage
          id="addon.expires"
          values={{
            date: (
              <FormattedDate
                value={props.parent.license.expiration.toDate()}
                day="numeric"
                month="short"
                year="numeric"
              />
            ),
          }}
        />
      </SecondLine>
    )}
    {props.parent.cartItemId && <SecondLine>&#40;{t('addon.choose.in-your-cart')}&#41;</SecondLine>}
  </>
);

export default ParentLabel;
