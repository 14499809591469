import * as React from 'react';
import styled from 'styled-components';

const MenuListUl = styled.ul`
  margin: 0;
  padding: 0;
  display: inline-block;
`;

type PropTypes = {
  children: React.ReactNode;
  isMACPage: boolean;
};

const MenuList: React.FC<PropTypes> = ({ children, isMACPage }) => (
  <MenuListUl data-cy={isMACPage ? 'mac-top-menu' : 'sc-top-menu'}>{children}</MenuListUl>
);

export default MenuList;
