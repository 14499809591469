// @flow
import { thunkProgress } from 'redux-progress';
import * as selectors from 'modules/marketplace/search/duck/selectors';
import { fetchSearchFromMarketplace } from 'modules/catalog/addons';
import type { Dispatch, GetState } from 'model/State';

export const FETCH_MORE_SEARCH = 'marketplace/search/fetch-more';

export const fetchMore = () => async (dispatch: Dispatch, getState: GetState) => {
  const prev = selectors.params(getState());
  const params = {
    ...prev,
    offset: prev.offset + prev.limit,
  };

  await dispatch(thunkProgress(FETCH_MORE_SEARCH, fetchSearchFromMarketplace(params), { params }));
};
