/*
These types come from HAMS API.
 */

import { LicenseType } from './LicenseType';

export type BillingType = 'PERPETUAL' | 'SUBSCRIPTION';
export type HamsProductType =
  | 'ADDON'
  | 'ADDON_PRICING_PLAN'
  | 'BTF_SUBSCRIPTION_ADDON'
  | 'BTF_SUBSCRIPTION'
  | 'PERPETUAL'
  | 'PERPETUAL_PRICING_PLAN'
  | 'HOSTED'
  | 'SUBSCRIPTION'
  | 'HOSTED_ADDON'
  | 'ONDEMAND_ADDON'
  | 'SUBSCRIPTION_ADDON'
  | 'TRAINING'
  | 'UNIVERSITY'
  | 'UNIVERSITY_PRICING_PLAN  ';

export const isHosted = (type: HamsProductType) =>
  ['HOSTED', 'HOSTED_ADDON', 'ONDEMAND_ADDON', 'SUBSCRIPTION'].includes(type);

export const isDataCenterProduct = (type: HamsProductType) => type === 'BTF_SUBSCRIPTION';

export const isAddon = (type: HamsProductType) =>
  [
    'ADDON',
    'ADDON_PRICING_PLAN',
    'BTF_SUBSCRIPTION_ADDON',
    'HOSTED_ADDON',
    'ONDEMAND_ADDON',
    'SUBSCRIPTION_ADDON',
  ].includes(type);

export const isAddonForServer = (type: HamsProductType) =>
  ['ADDON', 'HOSTED_ADDON', 'BTF_SUBSCRIPTION_ADDON', 'ADDON_PRICING_PLAN'].includes(type);

export type OrderableItem = {
  orderableItemId: string;
  description: string;
  publiclyAvailable: boolean;
  saleType: string;
  amount: number;
  renewalAmount: number;
  renewalFrequency: string;
  licenseType: LicenseType;
  unitCount: number;
  monthsValid: number;
  editionDescription: string;
  editionId: string;
  editionType: string;
  editionTypeIsDeprecated: boolean;
  unitLabel: string;
  enterprise: boolean;
  starter: boolean;
  edition: string | null;
  sku: string;
};

export type HamsProductPricing = {
  productDescription: string;
  productKey: string;
  productType: HamsProductType;
  discountOptOut: boolean;
  lastModified: string;
  marketplaceAddon: boolean;
  contactSalesForAdditionalPricing: boolean;
  dataCenter: boolean;
  userCountEnforced: boolean;
  parentDescription: string | null;
  parentKey: string | null;
  billingType: BillingType;
  orderableItems: OrderableItem[];
};

export type PriceStrategy = {
  currency: string;
  priceBands: {
    unitStart: number;
    unitLimit: number;
    unitBlockSize: number;
    price: number;
  }[];
};
