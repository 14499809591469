import set from 'lodash/set';

import { routesMap } from './index';
import sanitiseUUID from 'util/uuid';

// the routing middleware keeps the uuid query parameter in the URL between routes,
// only if the cart in the state is QUOTED and not on edit mode (pay quote flow)
export default () => (store) => (next) => (action) => {
  if (typeof action === 'object' && routesMap[action.type]) {
    const state = store.getState();
    if (
      state.location.query &&
      state.location.query.uuid &&
      state.cart.payload.ifSuccess((cart) => cart && cart.status === 'QUOTED')
    ) {
      set(action, 'meta.query.uuid', sanitiseUUID(state.location.query.uuid));
      next(action);
      return;
    }
  }

  next(action);
};
