import { thunkProgress } from 'redux-progress';

import updateCurrencyCartFromHams from 'modules/cart/duck/api/updateCurrency';
import createCartFromHams from 'modules/cart/duck/api/create';
import { selectHamsClientConfig } from 'modules/auth/selectors/hamsClientConfig';
import { resetCart } from 'modules/cart/duck/actions/reset';
import { CartThunk } from 'model/State';
import { selectUUID } from 'modules/cart/duck/selectors/updateCurrency';
import selectCurrency from 'modules/cart/duck/selectors/currency';
import watchProgress from 'model/watchProgress';
import { showNotificationFlag } from 'modules/meta/notificationFlag/actions';

export const UPDATE_CURRENCY = 'cart/UPDATE_CURRENCY';

export const updateCurrency = (): CartThunk => (dispatch, getState) => {
  const doFetchCart = async () => {
    const state = getState();
    const cartId = selectUUID(state);
    if (cartId) {
      const currentCartCurrency = await watchProgress(selectCurrency);
      const cart = await updateCurrencyCartFromHams(selectHamsClientConfig(state), cartId);
      if (cart) {
        if (currentCartCurrency !== cart.currency) {
          dispatch(showNotificationFlag('currency.updated.title'));
        }
        return cart;
      }
      dispatch(resetCart());
    }
    return createCartFromHams(selectHamsClientConfig(state));
  };

  return dispatch(thunkProgress(UPDATE_CURRENCY, doFetchCart()));
};
