const macLoginURITransformer = ({
  url,
  currentContactEmail,
}: {
  url: string;
  currentContactEmail: string | null;
}) =>
  `https://id.${
    url.includes('.stg.internal.') ? 'stg.internal.' : ''
  }atlassian.com/login?application=mac&continue=${encodeURIComponent(url)}${
    currentContactEmail ? `&login_hint=${currentContactEmail}` : ''
  }&prompt=none`;

export default macLoginURITransformer;
