import React from 'react';

import styled from 'styled-components';
import { colors } from '@atlaskit/theme';

/**
 * E.g. - or * before or after the actual price
 */
const PriceMarkingText = styled.span`
  color: ${colors.N80};
  font-weight: lighter;
`;

export const TaxIncludedSymbol = () => <PriceMarkingText>*</PriceMarkingText>;

export const PriceDeductionSymbol = () => <PriceMarkingText>-</PriceMarkingText>;
