import Progress, { thunkProgress } from 'redux-progress';
import setCartDetailsToHams from 'modules/cart/duck/api/setDetails';
import { CartThunk } from 'model/State';
import { HamsCart } from 'modules/cart/duck/model';
import { selectors } from 'modules/auth';

export const SET_DETAILS = 'cart/SET_DETAILS';
export const setCartDetails = (cart: Partial<HamsCart>): CartThunk<Promise<Progress<any>>> => (
  dispatch,
  getState,
) =>
  dispatch(
    thunkProgress(
      SET_DETAILS,
      setCartDetailsToHams(cart, selectors.selectHamsClientConfig(getState()), getState),
    ),
  );
