import { envType as analyticsEnvType } from '@atlassiansox/analytics-web-client';

import {
  BIZBOX,
  DEVELOPMENT,
  E2E,
  Environment,
  LOCAL,
  PRODUCTION,
  STAGING,
} from 'model/EnvironmentConfig';
import {
  TrackAction,
  TrackEventNames,
  TrackActionSubject,
  TrackSource,
} from 'modules/analytics/model/TrackEvent';
import { UIAction, UIActionSubject, UISource } from 'modules/analytics/model/UIEvent';
import { UIEventData, TrackEventData } from './actions';

export const analyticsEnvMapper = (cartEnv: Environment) => {
  switch (cartEnv) {
    case DEVELOPMENT:
      return analyticsEnvType.DEV;
    case STAGING:
      return analyticsEnvType.STAGING;
    case PRODUCTION:
      return analyticsEnvType.PROD;
    case LOCAL:
    case E2E:
    case BIZBOX:
      return analyticsEnvType.LOCAL;
    default:
      return analyticsEnvType.PROD;
  }
};

export const matchInternalUrl = (url: string) =>
  url.match(/https?:\/\/(([^/])+\.)?atlassian\.(com|net)(\/|$)/);

/**
 * Redacts external URLs to avoid recording them in analytics
 * @param {string} url The URL you are sanitizing
 * @returns {string} <external> if it's an external URL, otherwise, just the normal URL you passed in
 */
export const sanitizeUrl = (url: string): string => (matchInternalUrl(url) ? url : '<external>');

export const getTrackEventData = (
  action: TrackAction,
  actionSubject: TrackActionSubject,
  source: TrackSource,
  eventName: TrackEventNames,
  others?: Record<string, any>,
): TrackEventData => ({
  trackFields: {
    action,
    actionSubject,
    attributes: {
      eventName,
    },
  },
  trackContext: {
    source,
  },
  ...others,
});

export const getUIEventData = (
  action: UIAction,
  actionSubject: UIActionSubject,
  actionSubjectId: string,
  source: UISource,
  attributes?: Record<string, any>,
  others?: Record<string, any>,
): UIEventData => ({
  uiFields: {
    action,
    actionSubject,
    actionSubjectId,
    attributes,
  },
  uiContext: {
    source,
  },
  ...others,
});

export const getLinkClickEventData = (
  actionSubjectId: string,
  source: UISource,
  others?: Record<string, any>,
): UIEventData =>
  getUIEventData(
    UIAction.Clicked,
    UIActionSubject.Link,
    actionSubjectId,
    source,
    { eventComponent: UIActionSubject.Link, event: UIAction.Clicked },
    others,
  );

export const getButtonClickEventData = (
  actionSubjectId: string,
  source: UISource,
  others?: Record<string, any>,
): UIEventData =>
  getUIEventData(
    UIAction.Clicked,
    UIActionSubject.Button,
    actionSubjectId,
    source,
    { eventComponent: UIActionSubject.Button, event: UIAction.Clicked },
    others,
  );

export const getShowModalData = (
  actionSubjectId: string,
  source: UISource,
  others?: Record<string, any>,
): UIEventData =>
  getUIEventData(
    UIAction.Rendered,
    UIActionSubject.Modal,
    actionSubjectId,
    source,
    { eventComponent: UIActionSubject.Modal, event: UIAction.Rendered },
    others,
  );

export const getShowBanner = (
  actionSubjectId: string,
  source: UISource,
  others?: Record<string, any>,
): UIEventData =>
  getUIEventData(
    UIAction.Rendered,
    UIActionSubject.Banner,
    actionSubjectId,
    source,
    { eventComponent: UIActionSubject.Banner, event: UIAction.Rendered },
    others,
  );

export const getAdditionalAnalyticsAttributes = (
  eventContainer: UISource,
  uuid: string,
  parentLabel?: string | null,
  label?: string,
): Record<string, any> => ({
  eventContainer,
  uuid,
  parentLabel,
  label,
});
