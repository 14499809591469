// @flow
import Progress from 'redux-progress';
import * as actions from 'modules/marketplace/search/duck/actions';
import { AMOUNT_PER_PAGE } from 'modules/marketplace/search/duck/contants';

export default (state: Progress<boolean> = Progress.success(false), action: *) => {
  switch (action.type) {
    case actions.FETCH_SEARCH:
    case actions.FETCH_MORE_SEARCH:
      return action.progress.map((r) => r.length >= AMOUNT_PER_PAGE);
    default:
      return state;
  }
};
