import { CartThunk } from 'model/State';
import { PCCartCountryValidationType } from 'modules/checkout/contacts/duck/model';
import { validateCartCountryMatch } from './validateCartCountry';
import { validateCountryPurchaserContactMatch } from './validateContactCountry';

export const PC_CART_COUNTRY_VALIDATIONS = 'checkout/contact/CHECK_PURCHASER_VALIDATIONS';

export const puchaserCartCountryValidations = (
  payload: [any, PCCartCountryValidationType | null],
) => ({
  type: PC_CART_COUNTRY_VALIDATIONS,
  payload,
});

export const checkValidationsForPurchaser = (
  isoCountryCode: string,
  emailAddress: string,
): CartThunk<Promise<any>> => (dispatch) =>
  Promise.all([
    dispatch(validateCartCountryMatch(isoCountryCode)),
    dispatch(validateCountryPurchaserContactMatch(isoCountryCode, emailAddress)),
  ]).then((d) => dispatch(puchaserCartCountryValidations(d)));
