import React from 'react';
import Button from '@atlaskit/button/standard-button';
import * as model from './duck/model';

type Props = {
  callback: string;
  purchasedProductLicenses: model.License[];
};

const extractHostname = (url: string) =>
  url.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0];

const hiddenLicenseInput = ({ productKey, licenseKey }: model.License) => (
  <input key={productKey} type="hidden" name="license" value={`${productKey}|${licenseKey}`} />
);

const CallbackForm = ({ callback, purchasedProductLicenses }: Props) => (
  <form method="POST" action={callback}>
    {purchasedProductLicenses.map(hiddenLicenseInput)}
    <Button appearance="primary" type="submit">
      {`Apply license to ${extractHostname(callback)}`}
    </Button>
  </form>
);

export default CallbackForm;
