import React, { useContext, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { colors } from '@atlaskit/theme';

import { Dimensions, Font } from 'components/visuals';
import Toggler from './Toggler';
import { BILL_CYCLE, DEPLOY_OPTION } from './utils';

import appconfig from 'appconfig';

import { AnalyticsContext } from 'modules/migrate/analytics/AnalyticsContext';
import { comparatorEvents, comparatorCTAEvents } from 'modules/migrate/analytics/analyticsEvents';
import { generateAnalytics } from 'modules/migrate/analytics/utils';

export interface IDeploymentAndBillingCycleContext {
  billCycle: string;
  toggleBillCycle: (val: string) => void;
  deployOption: string;
  toggleDeployment: (val: string) => void;
}

const SwitchWrapper = styled.div`
  text-align: center;
  margin-bottom: ${Dimensions.units(3)}px;
  min-width: 300px;
`;

const Title = styled.div`
  color: ${colors.N700};
  font-weight: 500;
  letter-spacing: 0.3px;
  font-size: 24px;
  font-family: ${Font.displayFamily};
  margin-bottom: ${Dimensions.units(1.5)}px;
`;

const Subtitle = styled.div`
  color: ${colors.N700};
  font-weight: 500;
  letter-spacing: 0.3px;
  font-size: 20px;
  font-family: ${Font.displayFamily};
`;

const CenterSwitch = styled.div`
  margin: 0 auto;
  width: fit-content;
  margin-bottom: ${Dimensions.units(2)}px;
`;

const DCLink = styled.a`
  font-family: ${Font.family};
  font-size: 14px;
  line-height: 20px;
`;

export const DeploymentAndBillingCycleContext = React.createContext<IDeploymentAndBillingCycleContext>(
  {
    billCycle: BILL_CYCLE.MONTHLY,
    toggleBillCycle: () => undefined,
    deployOption: DEPLOY_OPTION.CLOUD,
    toggleDeployment: () => undefined,
  },
);

export const DeploymentAndBillingCycleContextProvider = ({ children }) => {
  const [billCycle, setBillCycle] = useState(BILL_CYCLE.MONTHLY);
  const [deployOption, setDeployOption] = useState(DEPLOY_OPTION.CLOUD);
  const analytics = useContext(AnalyticsContext);
  const { onToggleBillCycleAnalytics, onToggleDeploymentAnalytics } = generateAnalytics(
    analytics,
    comparatorEvents,
  );

  useEffect(() => {
    analytics.setAttributes({ billingCycleSelected: billCycle, deploymentSelected: deployOption });
  }, [billCycle, deployOption]);

  const toggleBillCycle = useCallback(
    (selectedVal) => {
      if (selectedVal !== billCycle) {
        setBillCycle(selectedVal);
        onToggleBillCycleAnalytics({
          billingCycleSelected: selectedVal,
          deploymentSelected: deployOption,
        });
      }
    },
    [billCycle],
  );

  const toggleDeployment = useCallback(
    (selectedVal) => {
      if (selectedVal !== deployOption) {
        setDeployOption(selectedVal);
        if (selectedVal === DEPLOY_OPTION.SERVER) {
          setBillCycle(BILL_CYCLE.ANNUAL);
        }
        onToggleDeploymentAnalytics({
          billingCycleSelected:
            selectedVal === DEPLOY_OPTION.SERVER ? BILL_CYCLE.ANNUAL : billCycle,
          deploymentSelected: selectedVal,
        });
      }
    },
    [deployOption],
  );

  return (
    <DeploymentAndBillingCycleContext.Provider
      value={{
        billCycle,
        toggleBillCycle,
        deployOption,
        toggleDeployment,
      }}
    >
      {children}
    </DeploymentAndBillingCycleContext.Provider>
  );
};

export const DeploymentSwitch: React.FC = () => {
  const { deployOption, toggleDeployment } = useContext(DeploymentAndBillingCycleContext);
  const analytics = useContext(AnalyticsContext);
  const { onCTAViewDataCenterPricingAnalytics } = generateAnalytics(analytics, comparatorCTAEvents);

  return (
    <SwitchWrapper>
      <Title>
        <FormattedMessage id="migrate.comparator.switch.deployment.title" />
      </Title>
      <CenterSwitch>
        <Toggler
          left={{
            value: DEPLOY_OPTION.CLOUD,
            label: 'migrate.comparator.deployment.cloud',
            tooltip: 'migrate.comparator.deployment.cloud.tooltip',
          }}
          right={{
            value: DEPLOY_OPTION.SERVER,
            label: 'migrate.comparator.deployment.server',
            tooltip: 'migrate.comparator.deployment.server.tooltip',
          }}
          selectedValue={deployOption}
          onOptionClick={toggleDeployment}
          dataTestId="deployment.option.toggler"
        />
      </CenterSwitch>
      <DCLink
        href={`${appconfig.wacUrl}/purchase/upgrade/datacenter`}
        onClick={() => onCTAViewDataCenterPricingAnalytics()}
        rel="noopener noreferrer"
        target="_blank"
      >
        <FormattedMessage id="migrate.comparator.deployment.dc-pricing" />
      </DCLink>
    </SwitchWrapper>
  );
};

export const BillingCycleSwitch: React.FC = () => {
  const { deployOption, billCycle, toggleBillCycle } = useContext(DeploymentAndBillingCycleContext);

  const isCloudSelected = deployOption === DEPLOY_OPTION.CLOUD;

  return (
    <SwitchWrapper>
      <Title data-testid="billing-cycle.switcher.title">
        <FormattedMessage id="migrate.comparator.switch.cycle.title" />
      </Title>
      {isCloudSelected ? (
        <CenterSwitch>
          <Toggler
            left={{
              value: BILL_CYCLE.MONTHLY,
              label: 'migrate.comparator.cycle.monthly',
              tooltip: 'migrate.comparator.cycle.monthly.tooltip',
            }}
            right={{
              value: BILL_CYCLE.ANNUAL,
              label: 'migrate.comparator.cycle.annual',
              tooltip: 'migrate.comparator.cycle.annual.tooltip',
            }}
            selectedValue={billCycle}
            onOptionClick={toggleBillCycle}
            dataTestId="billing-cycle.toggler"
          />
        </CenterSwitch>
      ) : (
        <Subtitle>
          <FormattedMessage id="migrate.comparator.cycle.annual" />
        </Subtitle>
      )}
    </SwitchWrapper>
  );
};
