import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import config from 'appconfig';
import { CloseSiteConfigurator, SectionMessage } from 'components/common/GenericErrorMessage';
import { analyticsEvents, AnalyticsTrigger } from 'modules/siteconfigurator/analytics';
import t from 'modules/i18n/intl';
import { CartAnalyticsContext } from 'modules/analytics';
import { HamsCart } from 'modules/cart/duck/model';
import { SectionMessageAction } from '@atlaskit/section-message';
import macLoginURITransformer from 'util/macLoginURITransformer';

const macLink = (path: string) =>
  macLoginURITransformer({
    url: `${config.macUrl}${path}`,
    currentContactEmail: null,
  });

const CartContactErrorMsg = (props: { cart: HamsCart }) => {
  const analytics = useContext(CartAnalyticsContext);
  return (
    <>
      <FormattedMessage id="annualbilling.error.cart-contact-details-1" />
      <a
        href={macLink('/details/edit')}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => analyticsEvents[AnalyticsTrigger.CartConfigContactDetailsAdd](analytics)}
      >
        <FormattedMessage
          id="annualbilling.error.same-contacts-cart-contact-details-2"
          values={{ primaryBillingContactEmail: props.cart.billingContactDetails.email }}
        />
      </a>
      <FormattedMessage id="annualbilling.error.same-contacts-cart-contact-details-3" />
    </>
  );
};

export const CartPartnerLoginErrorMsg = () => {
  const analytics = useContext(CartAnalyticsContext);

  useEffect(() => {
    analyticsEvents[AnalyticsTrigger.CartConfigPartnerLoginError](analytics);
  }, []);

  return (
    <SectionMessage
      appearance="error"
      title={t('annualbilling.error.cart-partner-title')}
      actions={[CloseSiteConfigurator].map(({ text, ...restAction }) => (
        <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
      ))}
    >
      <FormattedMessage
        id="annualbilling.error.cart-partner-content"
        values={{
          loginLink: (
            <a
              href={macLink('')}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => analyticsEvents[AnalyticsTrigger.CartConfigPartnerLogin](analytics)}
            >
              {`${config.macUrl}`}
            </a>
          ),
        }}
      />
    </SectionMessage>
  );
};

const CartDistinctContactsErrorMsg = (props: { cart: HamsCart }) => {
  const analytics = useContext(CartAnalyticsContext);
  return (
    <>
      <FormattedMessage id="annualbilling.error.cart-contact-details-1" />
      <p>
        <FormattedMessage id="annualbilling.error.diff-contacts-cart-contact-details-2" />
        <a
          href={macLink('/details/edit')}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => analyticsEvents[AnalyticsTrigger.CartConfigContactDetailsAdd](analytics)}
        >
          <FormattedMessage id="annualbilling.error.diff-contacts-cart-contact-details-3" />
        </a>
        <FormattedMessage id="annualbilling.error.diff-contacts-cart-contact-details-4" />
        <ol>
          <li>
            <FormattedMessage
              id="annualbilling.error.diff-contacts-cart-contact-details-5"
              values={{ primaryBillingContactEmail: props.cart.billingContactDetails.email }}
            />
          </li>
          <li>
            <FormattedMessage
              id="annualbilling.error.diff-contacts-cart-contact-details-6"
              values={{ primaryTechnicalContactEmail: props.cart.technicalContactDetails.email }}
            />
          </li>
          <li>
            <FormattedMessage id="annualbilling.error.diff-contacts-cart-contact-details-7" />
          </li>
        </ol>
      </p>
      <p>
        <FormattedMessage id="annualbilling.error.diff-contacts-cart-contact-details-8" />
      </p>
    </>
  );
};

export const CartContactDetailsError = (props: { cart: HamsCart }) => {
  const analytics = useContext(CartAnalyticsContext);

  useEffect(() => {
    analyticsEvents[AnalyticsTrigger.CartConfigContactDetailsError](analytics);
  }, []);

  return (
    <SectionMessage
      appearance="error"
      title={t('common.generic-error.title')}
      actions={[CloseSiteConfigurator].map(({ text, ...restAction }) => (
        <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
      ))}
    >
      {props.cart.billingContactDetails.email === props.cart.technicalContactDetails.email ? (
        <CartContactErrorMsg cart={props.cart} />
      ) : (
        <CartDistinctContactsErrorMsg cart={props.cart} />
      )}
    </SectionMessage>
  );
};
