import React from 'react';
import styled from 'styled-components';

import { Checkbox } from '@atlaskit/checkbox';
import { colors } from '@atlaskit/theme';

import t from 'modules/i18n/intl';
import { TableProps } from 'modules/change/table/duck/model';
import { Font } from 'components/visuals';

const StyledTableHeader = styled.thead`
  font-family: ${Font.displayFamily};
`;

const Header = styled.th`
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: left;
  color: ${colors.N500};
  padding: 0;

  &:first-child {
    width: 32px;
  }
`;

const TableHeader = (props: TableProps) => {
  const enableSelectAll = props.isMixedCurrency || props.isSelectAllDisabled;
  return (
    <StyledTableHeader>
      <tr>
        <Header>
          {enableSelectAll || (
            <Checkbox
              isChecked={props.selected.allAccounts}
              onChange={props.onAllEntriesToggle}
              label={undefined}
              name="select-all"
              value="true"
            />
          )}
        </Header>
        <Header>{t('change.headers.product')}</Header>
        <Header data-cy="user-tier-header">{props.userTierHeader}</Header>
        {props.isExpirationVisible && <Header>{props.expirationHeader}</Header>}
        <Header data-cy="option-header">{props.selectOptionHeader}</Header>
      </tr>
    </StyledTableHeader>
  );
};

export default TableHeader;
