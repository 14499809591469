import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { Dimensions, Font } from 'components/visuals';

export const Header = styled.h2`
  font-family: ${Font.displayFamily};
`;

export const SubHeader = styled.h3`
  font-family: ${Font.displayFamily};
  font-size: 20px;
  margin-bottom: 14px;
`;

export const Subtle = styled.em`
  font-size: 65%;
  font-weight: normal;
  color: ${colors.N90};
  margin-left: ${Dimensions.unit}px;
  font-family: ${Font.family};
`;

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`;

// Fix Atlaskit Checkbox alignment
export const RealignCheckbox = styled.span`
  margin-left: -10px;
`;

export const Fineprint = styled.p`
  color: ${colors.N80};
  font-size: 12px;
  margin-bottom: 10px;
`;

export const FooterArea = styled.div`
  border-top: 1px solid ${colors.N50}
  padding-top: 20px;
  text-align: right;
  
  button {
    padding: 0 20px;
  }
`;
