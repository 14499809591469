import Progress from 'redux-progress';
import { FETCH_CHECKOUT_METHODS_AVAILABLE } from 'modules/checkout/payment/duck/actions/checkoutMethodsAvailable';

const checkoutMethodsAvailable = (state = Progress.none, action) => {
  if (action.type === FETCH_CHECKOUT_METHODS_AVAILABLE) {
    return action.progress;
  }
  return state;
};

export default checkoutMethodsAvailable;
