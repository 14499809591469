// @flow
import Progress from 'redux-progress';
import { createSelector } from 'reselect';
import defaultMessages from 'modules/i18n/messages.json';
import type { Selector } from 'model/State';

const selectTranslations = (state) => state.i18n.translations[state.i18n.language] || Progress.none;

const buildMessages = (translations) => ({
  ...defaultMessages,
  ...translations,
});

export const selectActiveLanguage = createSelector(
  (state) => state.i18n.language,
  selectTranslations,
  (selectedLanguage, translations) => (translations.success ? selectedLanguage : 'en'),
);

export const selectI18NMessages: Selector<{ [string]: string }> = createSelector(
  selectTranslations,
  (translations) => buildMessages(translations.result),
);
