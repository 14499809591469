import Progress, { thunkProgress } from 'redux-progress';

import fetchCartFromHams from 'modules/cart/duck/api/fetch';
import createCartFromHams from 'modules/cart/duck/api/create';
import { selectHamsClientConfig } from 'modules/auth/selectors/hamsClientConfig';
import { resetCart } from 'modules/cart/duck/actions/reset';
import { CartThunk } from 'model/State';
import { HamsCart } from 'modules/cart/duck/model';

// Fetch a cart from the backend AND reset the current store cart to it
export const FETCH_CART = 'cart/FETCH';

// Fetch a cart from the backend, but do NOT reset the current store cart
export const FETCH_SECONDARY_CART = 'cart/FETCH_SECONDARY_CART';

// Switch the current store cart to the cart being passed in the payload
export const SWITCH_TO_DIFFERENT_CART = 'cart/SWITCH_TO_DIFFERENT_CART';

export const fetchOrCreateCart = (
  cartId?: string | null,
): CartThunk<Promise<Progress<HamsCart>>> => (dispatch, getState) => {
  const doFetchCart = async () => {
    if (cartId) {
      const cart = await fetchCartFromHams(selectHamsClientConfig(getState()), cartId);
      if (cart) {
        return cart;
      }
      dispatch(resetCart());
    }
    return createCartFromHams(selectHamsClientConfig(getState()));
  };

  return dispatch(thunkProgress(FETCH_CART, doFetchCart()));
};

// Used to simply fetch a cart from HAMS
// This does not reset the current cart, or change the current cart in the store
export const fetchCart = (cartId: string): CartThunk<Promise<Progress<HamsCart>>> => async (
  dispatch,
  getState,
) => {
  const doFetchCart = async () => {
    const cart = await fetchCartFromHams(selectHamsClientConfig(getState()), cartId);
    if (cart) {
      return cart;
    }
    return null;
  };

  return dispatch(thunkProgress(FETCH_SECONDARY_CART, doFetchCart()));
};
