// @flow
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import { SubHeader } from 'modules/checkout/styled';
import t from 'modules/i18n/intl';
import type { PaymentPageProps } from 'modules/checkout/payment/duck/model';
import { CheckoutModes } from 'modules/checkout/payment/duck/model/PaymentState';

export default ({ checkoutMode }: PaymentPageProps) =>
  checkoutMode === CheckoutModes.FREE && (
    <div>
      <SubHeader>{t('payment.payment-info')}</SubHeader>
      <SectionMessage title={t('payment.free-order')}>
        {t('payment.proceed-to-review-order')}
      </SectionMessage>
    </div>
  );
