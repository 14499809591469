import { thunkProgress } from 'redux-progress';
import type { Dispatch, GetState } from 'model/State';
import {
  ORDERS_COUNT_BY_EMAIL,
  QUOTES_POST,
  SEARCH_FOR_CONTACT_POST,
  LATEST_REFUND,
  REFUND_PDF,
  INVOICE_PDF,
} from './types';
import {
  invoicePdf,
  ordersCountByEmail,
  latestRefund,
  quotes,
  refundPDF,
  searchForContact,
} from '../../api';

export const fetchOrdersCountByEmail = () => (dispatch: Dispatch, getState: GetState) => {
  dispatch(thunkProgress(ORDERS_COUNT_BY_EMAIL, ordersCountByEmail(getState())));
};

export const fetchQuotesPost = (maxResults: number, offset: number) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  dispatch(thunkProgress(QUOTES_POST, quotes(maxResults, offset, getState())));
};

export const fetchSearchForContactPost = (
  maxResults: number,
  offset: number,
  orderNumberMask: number,
  technicalContactCompanyDomainName: string,
  integerPartOfTotalAmount: number,
) => (dispatch: Dispatch, getState: GetState) => {
  dispatch(
    thunkProgress(
      SEARCH_FOR_CONTACT_POST,
      searchForContact(
        maxResults,
        offset,
        orderNumberMask,
        technicalContactCompanyDomainName,
        integerPartOfTotalAmount,
        getState(),
      ),
    ),
  );
};

export const fetchLatestRefund = (orderId: string) => (dispatch: Dispatch, getState: GetState) => {
  dispatch(thunkProgress(LATEST_REFUND, latestRefund(orderId, getState())));
};

export const fetchRefundPDF = (refundId: string) => (dispatch: Dispatch, getState: GetState) => {
  dispatch(thunkProgress(REFUND_PDF, refundPDF(refundId, getState())));
};

export const fetchInvoicePDF = (orderId: string) => (dispatch: Dispatch, getState: GetState) => {
  dispatch(thunkProgress(INVOICE_PDF, invoicePdf(orderId, getState())));
};
