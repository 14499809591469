// @flow
import React from 'react';
import { connect } from 'react-redux';
import SectionMessage from '@atlaskit/section-message';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import config from 'appconfig';
import t from 'modules/i18n/intl';
import { Font, Dimensions } from 'components/visuals';
import type { State } from 'model/State';
import { selectShouldDisplayDataCenterApprovedAppsMessage } from './duck/server/selectors';

const Wrapper = styled.div`
  margin-bottom: ${Dimensions.units(3)}px;
  font-family: ${Font.family};
`;

type StateProps = {
  show: boolean,
};

const DataCenterApprovedAppsMessage = (props: StateProps) =>
  props.show && (
    <Wrapper>
      <SectionMessage appearance="information">
        <FormattedMessage
          id="review.data-center-approved-message"
          values={{
            dataCenterAppsFaqLink: (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a href={`${config.wacUrl}/licensing/data-center-approved-apps`} target="_blank">
                {t('review.data-center-approved-message.dataCenterAppsFaq')}
              </a>
            ),
          }}
        />
      </SectionMessage>
    </Wrapper>
  );

const mapStateToProps = (state: State): StateProps => ({
  show: selectShouldDisplayDataCenterApprovedAppsMessage(state),
});

export default connect(mapStateToProps)(DataCenterApprovedAppsMessage);
