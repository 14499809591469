import { FETCH_ACCOUNT_CHANGE_OPTIONS } from 'modules/change/table/duck/actions';
import { showJiraCoreModal } from 'modules/upgrade/duck/datacenter/actions/jiraCoreModal';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { ServerProductKeys } from 'data/products/keys';

export const epic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(FETCH_ACCOUNT_CHANGE_OPTIONS),
    filter((a) => a.progress.success && a.payload.productKey === ServerProductKeys.JIRA_CORE),
    map(showJiraCoreModal),
  );
