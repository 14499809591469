import type { Config } from '@atlassian/ufo-config';
import { init } from '@atlassian/ufo-interaction-metrics-init';
import { CartAnalyticsClient } from 'modules/analytics';

export const initUfo = (config: Config): void => {
  init(Promise.resolve(CartAnalyticsClient.getInternalClient()), {
    enabled: true,
    ...config,
  });
};
