import reportError from 'util/errorReporter';
import Progress from 'redux-progress';
import { createSelector } from 'reselect';

import { CHOOSE, MANUAL, SEARCH } from 'modules/addon/duck/model';
import { LICENSE_ACADEMIC, LICENSE_COMMERCIAL } from 'model/hams/LicenseType';
import selectChosenParentOption from 'modules/addon/duck/selectors/chosenParentOption';

import selectSearchParentOption from 'modules/addon/duck/selectors/searchParentOption';
import { selectManualTier } from 'modules/addon/duck/selectors/tier';
import { selectIsAcademicOn } from 'modules/addon/duck/selectors/academic';
import { OrderableItem } from 'model/hams/Pricing';
import { Selector } from 'model/State';

const selectOrderableItems: Selector<Progress<OrderableItem[]>> = createSelector(
  (state) => state.addon.mode,
  selectIsAcademicOn,
  selectChosenParentOption,
  selectSearchParentOption,
  selectManualTier,
  (mode, academic, chosenParentProgress, searchParentProgress, manualTier) => {
    switch (mode) {
      case CHOOSE:
        return chosenParentProgress.map((parent) => (parent && parent.orderableItems) || []);
      case SEARCH:
        return searchParentProgress.map((parent) => (parent && parent.orderableItems) || []);
      case MANUAL:
        return manualTier.map((tier) =>
          tier.getAllOrderableItems(academic ? LICENSE_ACADEMIC : LICENSE_COMMERCIAL),
        );
      default:
        reportError(`Unknown addon config mode ${mode}`);
        return Progress.none;
    }
  },
);

export default selectOrderableItems;
