import Progress from 'redux-progress';

import type { OrdersAction, OrdersData } from '../models/orders';
import {
  ORDERS_COUNT_BY_EMAIL,
  QUOTES_POST,
  SEARCH_FOR_CONTACT_POST,
  LATEST_REFUND,
} from 'modules/mac/duck/actions/orders';

export const initialState: OrdersData = {
  ordersCountByEmail: Progress.none,
  quotes: Progress.none,
  invoices: Progress.none,
  refundLatest: Progress.none,
};

export default (state: OrdersData = initialState, action: OrdersAction): OrdersData => {
  switch (action.type) {
    case ORDERS_COUNT_BY_EMAIL:
      return {
        ...state,
        ordersCountByEmail: action.progress,
      };
    case QUOTES_POST:
      return {
        ...state,
        quotes: action.progress,
      };
    case SEARCH_FOR_CONTACT_POST:
      return {
        ...state,
        invoices: action.progress,
      };
    case LATEST_REFUND:
      return {
        ...state,
        refundLatest: action.progress,
      };
    default:
      return state;
  }
};
