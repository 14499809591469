import React from 'react';
import { FlagsProvider } from '@atlaskit/flag';
import { FeatureGatesInitialization } from '@atlassian/feature-gates-react';
import uuid from 'uuid';

import { statsigConfig } from 'appconfig/statsig';
import ErrorBoundary from 'modules/error-info/ErrorBoundary';
import OriginI18nProvider from 'modules/i18n/I18nProvider';
import OriginNotificationWrapper from 'modules/meta/notificationFlag/notificationWrapper';
import { CommerceSharedComponentIntegrations } from 'modules/shared-components';
import AppBody from 'components/navigation/AppBody';
import Loader from 'components/common/Loader';

type ReactFCWithChildren = React.FC<{ children: React.ReactNode }>;

// There are some problems with nested components of such imported entities,
// So we need this override to avoid TS complier errors
const I18nProvider = OriginI18nProvider as ReactFCWithChildren;
const NotificationWrapper = OriginNotificationWrapper as ReactFCWithChildren;

const randomizationId = uuid();

const Providers: ReactFCWithChildren = ({ children }) =>
  statsigConfig.environment && (
    <FeatureGatesInitialization
      options={{
        ...statsigConfig,
        environment: statsigConfig.environment,
      }}
      identifiers={{ randomizationId }}
      initializingComponent={<Loader />}
      overrides={window.FEATURE_GATES_OVERRIDES}
    >
      <I18nProvider>
        <ErrorBoundary>
          <CommerceSharedComponentIntegrations>
            <FlagsProvider>
              <NotificationWrapper>
                <AppBody mode="light">
                  <ErrorBoundary>{children}</ErrorBoundary>
                </AppBody>
              </NotificationWrapper>
            </FlagsProvider>
          </CommerceSharedComponentIntegrations>
        </ErrorBoundary>
      </I18nProvider>
    </FeatureGatesInitialization>
  );

export default Providers;
