// @flow
import * as storage from 'modules/checkout/payment/duck/api/storage';
import type { CheckoutMode } from 'modules/checkout/payment/duck/model';
import { SELECT_MODE } from 'modules/checkout/payment/duck/actions';

const initialState = () => storage.checkoutMode.load();

export default (state: CheckoutMode = initialState(), action: *) => {
  switch (action.type) {
    case SELECT_MODE:
      return action.payload;
    default:
      return state;
  }
};
