// @flow
import { thunkProgress } from 'redux-progress';
import compact from 'lodash/compact';

import { fetchPaginatedActiveAccounts, OverviewParams } from 'modules/accounts/api';
import * as api from 'modules/upgrade/duck/datacenter/api';
import type { Dispatch, GetState } from 'model/State';
import { selectHamsClientConfig } from 'modules/auth/selectors';
import * as change from 'modules/change';
import * as actions from 'modules/change/search/duck/actions';

const CLOUD_ACCOUNT_PRODUCT_KEY = 'atlassian.cloud';
const ORGANIZATION_ACCOUNT_PRODUCT_KEY = 'atlassian.organisation';
export const fetchPaginatedAccounts = (props: OverviewParams) => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const state = getState();

  if (state.change.table.paginated[`${props?.offset}`]) {
    return dispatch(
      thunkProgress(
        change.table.actions.LOAD_PAGINATED_CHANGE_LIST,
        state.change.table.paginated[`${props?.offset}`],
      ),
    );
  }

  return dispatch(
    thunkProgress(
      change.table.actions.LOAD_PAGINATED_CHANGE_LIST,
      fetchPaginatedActiveAccounts(selectHamsClientConfig(state), {
        productKeys: [CLOUD_ACCOUNT_PRODUCT_KEY, ORGANIZATION_ACCOUNT_PRODUCT_KEY],
        productTypes: ['BTF_SUBSCRIPTION', 'BTF_SUBSCRIPTION_ADDON'],
        ...props,
      }),
    ),
  );
};

export const fetchAccountChangeOptions = (
  accountId: string,
  email: string | null,
  productKey: string | null,
) => (dispatch: Dispatch, getState: GetState) => {
  return dispatch(
    thunkProgress(
      change.table.actions.FETCH_ACCOUNT_CHANGE_OPTIONS,
      api
        .fetchUpgradeOptionsForAccount(accountId, email, selectHamsClientConfig(getState()))
        .then((account) => {
          return { accounts: compact([account]) };
        }),
      { payload: { accountId, productKey } },
    ),
  );
};

export const fetchAccount = (accountId: string, email: string | null) => (
  dispatch: Dispatch,
  getState: GetState,
) =>
  dispatch(
    thunkProgress(
      change.table.actions.LOAD_CHANGE_LIST,
      api
        .fetchUpgradeOptionsForAccount(accountId, email, selectHamsClientConfig(getState()))
        .then((account) => ({ accounts: compact([account]) })),
    ),
  );

export const searchAccount = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  return dispatch(
    thunkProgress(
      change.search.actions.SUBMIT_FORM,
      api.fetchUpgradeOptionsForAccount(
        (state.change.search.sen || '').replace(/SEN-/i, ''),
        state.change.search.email || '',
        selectHamsClientConfig(state),
      ),
    ),
  ).then((accountProgress) =>
    dispatch(
      thunkProgress(actions.SCROLL_FORM, dispatch(actions.scrollForm(accountProgress, false))),
    ),
  );
};

export const resetAccount = () => (dispatch: Dispatch) => {
  dispatch(change.search.actions.resetForm());
};
