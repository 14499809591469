import React from 'react';

import {
  Header,
  Subheader,
  Section,
  EditButton,
  Column,
} from 'modules/checkout/review/details/Commons';
import type { HamsCart } from 'modules/cart/duck/model';
import type { ContactDetails, OrganisationDetails } from 'model/hams/contact';
import t from 'modules/i18n/intl';

type Props = {
  cart: HamsCart;
  onContactDetailsEdit: () => void;
  inSiteConfiguratorMode: boolean;
  canEditContactInSiteConfigurator: boolean;
};

const DetailsComponent = ({ details }: { details: ContactDetails }) => (
  <p>
    {details.firstName} {details.lastName} <br />
    {details.email} <br />
    {details.phone}
  </p>
);

const OrganisationDetailsComponent = ({ details }: { details: OrganisationDetails }) => (
  <p>
    {details.organisationName} <br />
    {details.address1} {details.address2}
    <br />
    {details.city}
    &nbsp;{details.state}
    &nbsp;{details.postcode}
    <br />
    {details.isoCountryCode}
    <br />
  </p>
);

const ContactDetailsComponent = ({
  cart,
  onContactDetailsEdit,
  inSiteConfiguratorMode,
  canEditContactInSiteConfigurator,
}: Props) => (
  <Column>
    <>
      <Header>
        {t('review.contact-details')}
        {(!inSiteConfiguratorMode || canEditContactInSiteConfigurator) && (
          <EditButton data-cy="editContactDetailsButton" onClick={onContactDetailsEdit} />
        )}
      </Header>
      <Section>
        <Subheader>{t('review.your-contact-info')}</Subheader>
        <DetailsComponent details={cart.purchaserContactDetails} />
      </Section>
      {cart.technicalContactDetails.email !== cart.purchaserContactDetails.email ? (
        <Section>
          {cart.resellerOrder || cart.expertOrder ? (
            <Subheader>{t('review.end-user-technical-contact')}</Subheader>
          ) : (
            <Subheader>{t('review.end-user')}</Subheader>
          )}
          <DetailsComponent details={cart.technicalContactDetails} />
        </Section>
      ) : null}
      {cart.billingContactDetails.email !== cart.purchaserContactDetails.email ? (
        <Section>
          <Subheader>{t('review.billing-contact')}</Subheader>
          <DetailsComponent details={cart.billingContactDetails} />
        </Section>
      ) : null}
      <Section>
        {cart.resellerOrder || cart.expertOrder ? (
          <Subheader>{t('review.end-user-company-info')}</Subheader>
        ) : (
          <Subheader>{t('review.company-info')}</Subheader>
        )}
        <OrganisationDetailsComponent details={cart.technicalOrganisationDetails} />
      </Section>
      {cart.resellerOrder || cart.expertOrder ? (
        <Section>
          <Subheader>{t('review.billing-company-info')}</Subheader>
          <OrganisationDetailsComponent details={cart.billingOrganisationDetails} />
        </Section>
      ) : null}
    </>
  </Column>
);

export default ContactDetailsComponent;
