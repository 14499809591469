/* eslint-disable react/jsx-no-target-blank */
// @flow
import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@atlaskit/checkbox';
import { FormattedMessage } from 'react-intl';
import { SubHeader, Subtle, RealignCheckbox } from 'modules/checkout/styled';
import PoNumberInput from 'modules/checkout/PoNumberInput';
import type { QuotePageProps } from 'modules/checkout/duck/model';
import { Dimensions } from 'components/visuals';
import config from 'appconfig';

const Columns = styled.div`
  margin-top: ${Dimensions.units(3)}px;
  display: flex;
  > * {
    flex: 1;
  }
  > :first-child {
    padding-right: ${Dimensions.units(10)}px;
  }
`;

export default ({ additionalDetails, hasTax, canCoTerm }: QuotePageProps) => (
  <>
    <SubHeader>
      Additional Details <Subtle>(Optional)</Subtle>
    </SubHeader>
    <Columns>
      <div>
        <RealignCheckbox>
          <Checkbox
            label={<FormattedMessage id="quote.additionalDetails.taxExempt" values={{ hasTax }} />}
            isDisabled={!hasTax}
            isChecked={additionalDetails.hasTaxExemptRequest}
            onChange={(e) => additionalDetails.edit({ hasTaxExemptRequest: e.target.checked })}
          />
        </RealignCheckbox>
        <RealignCheckbox>
          <Checkbox
            name="coTerm"
            label={
              <span>
                <FormattedMessage id="quote.additionalDetails.coTerm" values={{ canCoTerm }} />
                .&nbsp;
                <a
                  target="_blank"
                  href={`${config.wacUrl}/licensing/purchase-licensing#softwaremaintenance-5`}
                >
                  Learn more
                </a>
              </span>
            }
            isDisabled={!canCoTerm}
            isChecked={additionalDetails.hasCoTermRequest}
            onChange={(e) => additionalDetails.edit({ hasCoTermRequest: e.target.checked })}
          />
        </RealignCheckbox>
        <p>
          Please be aware that by selecting one of these options, your quote will be manually
          reviewed by our support team within the next 1 to 3 business days. If you need to{' '}
          <a target="_blank" href={`${config.wacUrl}/company/contact`}>
            contact us
          </a>{' '}
          for any other reason, please proceed with saving the quote and contact us referencing the
          quote number.
        </p>
      </div>
      <div>
        <PoNumberInput
          value={additionalDetails.poNumber}
          onChange={(poNumber) => additionalDetails.edit({ poNumber })}
        />
      </div>
    </Columns>
  </>
);
