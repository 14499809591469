/* eslint-disable no-param-reassign */
// @flow
import Progress from 'redux-progress';
import * as actions from 'modules/checkout/payment/duck/actions';
import type { CreditCardState } from 'modules/checkout/payment/duck/model';

const initState: CreditCardState = {
  configureProgress: Progress.none,
  tnsFocus: null,
  cardName: '',
  expiryMonth: '',
  expiryYear: '',
};

export default (state: CreditCardState = initState, action: any): void => {
  switch (action.type) {
    case actions.ROUTE:
      return initState;
    case actions.CONFIGURE_TNS_FORM:
      return {
        ...initState,
        configureProgress: action.progress,
      };
    case actions.TNS_FOCUS:
      return {
        ...state,
        tnsFocus: action.field,
      };
    case actions.EDIT_TNS_FORM:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
