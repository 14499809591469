import { colors } from '@atlaskit/theme';
import TooltipComponent from 'react-tooltip-lite';
import { Dimensions } from 'components/visuals';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type ToggleOptionType = {
  value: string;
  label: string;
  tooltip?: string;
};

type TogglerProps = {
  left: ToggleOptionType;
  right: ToggleOptionType;
  selectedValue: string;
  onOptionClick: (val: string) => void;
  dataTestId?: string;
};

type StyledOptionProps = {
  isSelected: boolean;
};

const Wrapper = styled.div`
  display: flex;
`;

const Option = styled.div<StyledOptionProps>`
  background-color: ${({ isSelected }) => (isSelected ? colors.N900 : colors.N0)};
  color: ${({ isSelected }) => (isSelected ? colors.N0 : colors.N900)};
  padding: ${Dimensions.units(0.8)}px ${Dimensions.units(1.8)}px;
  border: 1px solid ${colors.N900};
  cursor: pointer;
`;

const LeftOption = styled(Option)`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const RightOption = styled(Option)`
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const TooltipWrapper = styled(TooltipComponent)`
  & .react-tooltip-lite {
    border-radius: 4px;
  }
`;

const TooltipContentWrapper = styled.div`
  max-width: 112px;
  color: ${colors.N0};
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
`;

const commonTooltipOptionsWithContent = (content) => ({
  content: (
    <TooltipContentWrapper>
      <FormattedMessage id={content} />
    </TooltipContentWrapper>
  ),
  background: '#2e2b2b',
  hoverDelay: 1000,
});

const Toggler: React.FC<TogglerProps> = ({
  left,
  right,
  selectedValue,
  onOptionClick,
  dataTestId,
}) => {
  let leftOption = (
    <LeftOption
      onClick={() => onOptionClick(left.value)}
      isSelected={selectedValue === left.value}
      data-cy-is-selected={selectedValue === left.value}
    >
      <FormattedMessage id={left.label} />
    </LeftOption>
  );

  if (left.tooltip) {
    leftOption = (
      <TooltipWrapper direction="left" {...commonTooltipOptionsWithContent(left.tooltip)}>
        {leftOption}
      </TooltipWrapper>
    );
  }

  let rightOption = (
    <RightOption
      onClick={() => onOptionClick(right.value)}
      isSelected={selectedValue === right.value}
      data-cy-is-selected={selectedValue === right.value}
    >
      <FormattedMessage id={right.label} />
    </RightOption>
  );

  if (right.tooltip) {
    rightOption = (
      <TooltipWrapper direction="right" {...commonTooltipOptionsWithContent(right.tooltip)}>
        {rightOption}
      </TooltipWrapper>
    );
  }

  return (
    <Wrapper data-testid={dataTestId}>
      {leftOption}
      {rightOption}
    </Wrapper>
  );
};

export default Toggler;
