import React from 'react';
import styled from 'styled-components';

import { Radio } from '@atlaskit/radio';
import { colors } from '@atlaskit/theme';

import { ParentData } from 'modules/addon/duck/model';
import { Props as ModuleProps } from 'modules/addon/VisualAddonPage';
import ParentLabel from 'modules/addon/ParentLabel';
import ParentTermSelect from 'modules/addon/ParentTermSelect';
import ParentDisabled from 'modules/addon/ParentDisabled';
import { Dimensions, Font } from 'components/visuals';
import AmountWithCurrency from 'components/common/AmountWithCurrency';

export type Props = ModuleProps & { parent: ParentData; isSelected: boolean };

const Wrapper = styled.div`
  font-size: 16px;
  padding: ${Dimensions.units(1)}px 0;
  border-bottom: 1px solid ${colors.N400};

  &:last-child {
    border-bottom: none;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
`;

const PriceWrapper = styled.div`
  font-weight: 500;
  font-family: ${Font.displayFamily};
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const Parent = (props: Props) => {
  const isDisabled = props.parent.license && !props.parent.license.available;
  return (
    <Wrapper>
      <InnerWrapper>
        <div>
          <Radio
            isInvalid={false}
            isDisabled={isDisabled}
            onChange={() => props.events.onParentChange(props.parent)}
            isChecked={props.isSelected}
            label={<ParentLabel {...props} />}
          />
        </div>
        <FlexGrow />
        <PriceWrapper>
          <AmountWithCurrency
            size="18px"
            amount={props.parent.amount}
            currency={props.data.currency}
          />
        </PriceWrapper>
      </InnerWrapper>
      {props.isSelected && <ParentTermSelect {...props} />}
      {isDisabled && <ParentDisabled {...props} />}
    </Wrapper>
  );
};

export default Parent;
