import React from 'react';
import Link from 'components/common/Link';
import { Font } from 'components/visuals';
import {
  Column,
  ColumnWrapper,
  Header,
  LinkWrapper,
  Subtext,
  Wrapper,
} from 'components/navigation/top/dropdowns/commons';

export default () => (
  <Wrapper>
    <ColumnWrapper>
      <Column>
        <Header>Atlassian support</Header>
        <Link to="https://support.atlassian.com/help">
          <LinkWrapper>
            <div>
              <h4>Technical support</h4>
              <Subtext>Raise a support ticket</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to="/licensing/purchase-licensing">
          <LinkWrapper>
            <div>
              <h4>Purchasing &amp; licensing</h4>
              <Subtext>Help on pricing or your account</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to="https://confluence.atlassian.com/display/ALLDOC/Atlassian+Documentation">
          <LinkWrapper>
            <div>
              <h4>Documentation</h4>
              <Subtext>Guides to all of our products</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to="/enterprise/services">
          <div>
            <div>
              <h4>Enterprise services</h4>
              <Subtext>Support for large teams</Subtext>
            </div>
          </div>
        </Link>
      </Column>
      <Column>
        <Header>Community support</Header>
        <Link to="https://community.atlassian.com">
          <LinkWrapper>
            <div>
              <h4>Community help</h4>
              <Subtext>Get help from other users</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to="/partners">
          <LinkWrapper>
            <div>
              <h4>Partner Program</h4>
              <Subtext>Learn about Atlassian Partners</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to="/partners/search">
          <LinkWrapper>
            <div>
              <h4>Find a Partner</h4>
              <Subtext>Custom Professional Services</Subtext>
            </div>
          </LinkWrapper>
        </Link>
      </Column>
      <Column>
        <Header>Learn &amp; connect</Header>
        <Link to="/company">
          <LinkWrapper>
            <div>
              <h4>About us</h4>
              <Subtext>Learn about our company</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to="/company/careers">
          <LinkWrapper>
            <div>
              <h4>Careers</h4>
              <Subtext>Search our latest job openings</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to="/university">
          <LinkWrapper>
            <div>
              <h4>University</h4>
              <Subtext>Self-paced and live training</Subtext>
            </div>
          </LinkWrapper>
        </Link>
        <Link to="https://www.atlassian.com/blog">
          <LinkWrapper>
            <div>
              <h4>Blogs</h4>
              <Subtext>Thought leadership on all topics</Subtext>
            </div>
          </LinkWrapper>
        </Link>
      </Column>
    </ColumnWrapper>
    <Link style={{ fontFamily: Font.family }} to="https://support.atlassian.com">
      View all support
    </Link>
  </Wrapper>
);
