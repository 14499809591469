/* eslint-disable import/prefer-default-export */
import Progress, { thunkProgress } from 'redux-progress';
import { CartThunk } from 'model/State';
import { goNext } from 'modules/checkout/duck/actions';
import * as steps from 'modules/checkout/steps';
import { actions as cartActions } from 'modules/cart';
import getShowSubmitLoading from '../selectors/getShowSubmitLoading';

export const SUBMIT = 'checkout/contact/SUBMIT';
export const submit = (): CartThunk<Promise<Progress<any>> | null> => (dispatch, getState) => {
  const state = getState();

  const requestsInProgress = getShowSubmitLoading(state);
  if (requestsInProgress) {
    return Promise.resolve(Progress.success());
  }
  return state.checkout.contacts.data.ifSuccess((data) => {
    const payload = {
      ...state.cart.payload.result,
      ...data,
      billingContactDetails: data.isDifferentBilling
        ? data.billingContactDetails
        : data.purchaserContactDetails,
      technicalContactDetails: data.isDifferentTechnical
        ? data.technicalContactDetails
        : data.purchaserContactDetails,
      technicalOrganisationDetails: data.resellerOrder
        ? data.technicalOrganisationDetails
        : data.billingOrganisationDetails,
    };

    return dispatch(
      thunkProgress(
        SUBMIT,
        (async () => {
          (await dispatch(cartActions.setCartDetails(payload))).unwrap();
          dispatch(steps.markComplete('contact'));
          dispatch(goNext());
        })(),
      ),
    );
  });
};
