import {
  envType as analyticsEnvType,
  tenantType,
  userType,
} from '@atlassiansox/analytics-web-client';

import * as OperationalEvent from './OperationalEvent';
import * as ScreenEvent from './ScreenEvent';
import * as TrackEvent from './TrackEvent';
import * as UIEvent from './UIEvent';

type ValuesOf<T extends Record<any, any>> = T extends Record<any, infer K> ? K : T;

export interface CommonFields {
  env: ValuesOf<typeof analyticsEnvType>;
  product: string;
  subproduct?: string;
  version: string;
  origin?: string;
  platform?: string;

  tenantIdType: ValuesOf<typeof tenantType>; // NOT USED in CART
  tenantId?: string; // NOT USED in CART
  locale: string;
  userId?: string;
  userIdType?: ValuesOf<typeof userType>;
}

export type ReferrerAnalytics =
  | {
      referrerParams: any;
      referrerUrl: string;
    }
  | {
      referrerParams: null;
      referrerUrl: null;
    };
export type PageAnalytics = Readonly<{
  path: string;
  url: string;
  languagePreference: string;
  languageMultiplePreferences: Readonly<string[]>;
}>;

export type AnalyticsEvent =
  | UIEvent.CartUIEvent
  | ScreenEvent.CartScreenEvent
  | TrackEvent.CartTrackEvent
  | OperationalEvent.CartOperationalEvent;

export const PRODUCT_NAME = 'cart';

export { OperationalEvent, ScreenEvent, TrackEvent, UIEvent };
