// @flow
import React from 'react';
import SearchAddons from 'modules/marketplace/search/SearchAddons';
import RecommendedAddons from 'modules/marketplace/recommended/RecommendedAddons';
import { ExternalProductKeys } from 'data/products/keys';
import marketplace from 'data/products/marketplace';
import Header from 'components/common/Header';

export default () => (
  <div>
    <Header
      productKey={ExternalProductKeys.MARKETPLACE}
      productName={marketplace.name}
      tagLine={marketplace.tagLine}
      showTagline
    />
    <SearchAddons />
    <RecommendedAddons />
  </div>
);
