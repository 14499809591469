import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import ChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';

import Spinner from '@atlaskit/spinner';
import Attach from 'modules/addon/ChooseParent';
import Search from 'modules/addon/Search';
import Manual from 'modules/addon/Manual';
import { Props } from 'modules/addon/VisualAddonPage';
import PageBanners from 'modules/addon/PageBanners';
import MultiAddonInfo from 'modules/addon/MultiAddonInfo';
import * as addon from 'modules/addon';
import { Dimensions, Font } from 'components/visuals';

const OuterWrapper = styled.div`
  width: ${Dimensions.units(95)}px;
`;

const Wrapper = styled.div`
  border: 1px solid ${colors.N80};
  border-radius: 4px;
  font-family: ${Font.family};
  flex-grow: 1;
`;

const Entry = styled.div`
  border-top: 1px solid ${colors.N80};
  &:first-child {
    border-top: none;
  }
`;

type WithExpanded = { expanded: boolean };

const Header = styled.div`
  background-color: ${(props: WithExpanded) => (props.expanded ? colors.N0 : colors.N20)};
  padding: 0 14px;
  line-height: 48px;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  &:hover {
    background-color: ${colors.N0};
  }
`;
const Title = styled.span`
  font-family: ${Font.displayFamily};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  color: ${colors.N400};
`;

const Line = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: ${colors.N500};
`;

const Body = styled.div`
  padding: ${Dimensions.units(2)}px 0;
  border-top: 1px solid ${colors.N80};
  margin: 0 ${Dimensions.units(2)}px;
`;

const LoadingWrapper = styled.div`
  text-align: center;
`;

const Chevron = () => (
  <span style={{ position: 'absolute', right: '16px', top: '6px' }}>
    <ChevronDownIcon label="open" />
  </span>
);

const Loader = () => (
  <LoadingWrapper>
    <Spinner interactionName="accordion-spinner" size="medium" />
  </LoadingWrapper>
);

const Accordion = (props: Props) => (
  <OuterWrapper data-testid="addons.accordion.container">
    <PageBanners {...props} />
    <MultiAddonInfo {...props} />
    <Wrapper>
      <Entry>
        <Header
          expanded={props.mode === addon.model.SEARCH}
          onClick={props.events.onModeChange(addon.model.SEARCH)}
        >
          <Title>search</Title>
          <Line> - Look up a license by SEN</Line>
          {props.mode !== addon.model.SEARCH && <Chevron />}
        </Header>
        {props.mode === addon.model.SEARCH && (
          <Body>
            <Search {...props} />
          </Body>
        )}
      </Entry>
      <Entry>
        <Header
          expanded={props.mode === addon.model.CHOOSE}
          onClick={props.events.onModeChange(addon.model.CHOOSE)}
        >
          <Title>choose</Title>
          <Line> - Choose a license to attach to</Line>
          {props.mode !== addon.model.CHOOSE && <Chevron />}
        </Header>
        {props.mode === addon.model.CHOOSE && (
          <Body>
            {props.overviewProgress.fold({
              loading: () => <Loader />,
              success: (overviewData) => (
                <Attach {...props} data={{ ...props.data, ...overviewData }} />
              ),
            })}
          </Body>
        )}
      </Entry>
      <Entry>
        <Header
          expanded={props.mode === addon.model.MANUAL}
          onClick={props.events.onModeChange(addon.model.MANUAL)}
        >
          <Title>manual</Title>
          <Line> - Already know your user tier?</Line>
          {props.mode !== addon.model.MANUAL ? <Chevron /> : null}
        </Header>
        {props.mode === addon.model.MANUAL ? (
          <Body>
            <Manual {...props} />
          </Body>
        ) : null}
      </Entry>
    </Wrapper>
  </OuterWrapper>
);

export default Accordion;
