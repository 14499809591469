import * as React from 'react';

import styled from 'styled-components';

import { Dimensions } from 'components/visuals';
import { SubHeader } from 'modules/checkout/styled';

import t from 'modules/i18n/intl';

import LoadingBlanket from './LoadingBlanket';
import ConfigureErrorMessage from './ConfigureErrorMessage';
import GenericErrorMessage from 'components/common/GenericErrorMessage';

type Props = React.PropsWithChildren<{
  progress: any;
  inSiteConfiguratorMode: boolean;
}>;

interface ICreditCardContainerProps {
  visible: boolean;
}

const CreditCardContainer = styled.section`
  width: ${Dimensions.units(50)}px;
  ${(props: ICreditCardContainerProps) => !props.visible && 'visibility: hidden'}
`;

const CreditCardFormWrapper = ({ progress, children, inSiteConfiguratorMode }: Props) => (
  <>
    <SubHeader>{t('payment.credit-card.content.title')}</SubHeader>
    {progress.fold({
      failed: (e) =>
        inSiteConfiguratorMode ? <GenericErrorMessage error={e} /> : <ConfigureErrorMessage />,
      loading: () => <LoadingBlanket />,
    })}
    <CreditCardContainer visible={progress.success} className="pii">
      {children}
    </CreditCardContainer>
  </>
);

export default CreditCardFormWrapper;
