import { selectLatestFrom } from 'util/rxOperators';
import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { filter, mapTo } from 'rxjs/operators';
import * as actions from 'modules/cart/duck/actions';
import { State$ } from 'model/State';

export default (action$: Observable<any>, state$: State$) =>
  action$.pipe(
    ofType(actions.CART_DELETE_ITEM),
    filter((action) => action.progress.isCompleted),
    selectLatestFrom(state$),
    mapTo(actions.flushQueue()),
  );
