import { State } from 'model/State';
import { getFromHamsClient } from 'util/mac/api/account/get';
import { fetchWithParamValidation } from 'util/fetchWithParamValidation';
import { selectSwitchedUser } from '../selectors';

export const childrenOverviews = (accountId: string, state: State) =>
  fetchWithParamValidation(({ id }) => getFromHamsClient(`${id}/childrenOverviews`, state), {
    id: accountId,
  });

export const detail = (accountId: string, state: State) =>
  fetchWithParamValidation(({ id }) => getFromHamsClient(`${id}/detail`, state), {
    id: accountId,
  });

export const isOnBehalfOfEmail = (accountId: string, state: State) => {
  const email = selectSwitchedUser(state);

  return fetchWithParamValidation(
    ({ id, onBehalf }) =>
      getFromHamsClient(`${id}/partnerProfile`, state, { onBehalfOfEmail: onBehalf }),
    { id: accountId, onBehalf: email },
  );
};

export const isSiteOnConcession = (accountId: string, state: State) =>
  fetchWithParamValidation(({ id }) => getFromHamsClient(`${id}/isSiteOnConcession`, state), {
    id: accountId,
  });

export const isSiteApplicableForConcession = (accountId: string, state: State) =>
  fetchWithParamValidation(
    ({ id }) => getFromHamsClient(`${id}/isSiteApplicableForConcession`, state),
    {
      id: accountId,
    },
  );

export const CMTSelfServeLink = (accountId: string, state: State) => {
  const email = selectSwitchedUser(state);

  return fetchWithParamValidation(
    ({ id, mail }) => getFromHamsClient(`${id}/CMTSelfServeLink/${mail}`, state),
    {
      id: accountId,
      mail: email,
    },
  );
};
