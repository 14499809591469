import { progressResult } from 'util/rxOperators';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged, filter, skipUntil } from 'rxjs/operators';
import * as actions from 'modules/checkout/contacts/duck/actions';
import {
  getSelectedBillingOrganisationCurrency,
  isResellerOrder,
} from 'modules/checkout/contacts/duck/selectors/getBillingContacts';
import { EDIT } from 'modules/checkout/contacts/duck/actions/edit';
import { State$ } from 'model/State';

const isEditAction$ = (action$) =>
  action$.pipe(
    map((a: any) => a.type === EDIT),
    filter(Boolean),
  );

export default (action$: Observable<any>, state$: State$) =>
  state$.pipe(
    filter((state) => !isResellerOrder(state).result),
    progressResult(getSelectedBillingOrganisationCurrency),
    distinctUntilChanged(),
    filter(Boolean),
    skipUntil(isEditAction$(action$)),
    map((newCurrency: any) => actions.validatePreEmptiveCompanyShowNotification(newCurrency)),
  );
