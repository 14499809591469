import Progress from 'redux-progress';
import * as Rx from 'rxjs';
import { first, map } from 'rxjs/operators';
import { State$, Selector } from './State';

export function createEpic() {
  const subject = new Rx.ReplaySubject(1);
  const epic = (action$: any, state$: State$) => {
    state$.subscribe(subject);
    return Rx.empty();
  };
  const watchProgress = <T>(selector: Selector<Progress<T>>): Promise<T> =>
    subject
      .pipe(
        map(selector),
        first((p) => p.isCompleted),
        map((p) => p.unwrap()),
      )
      .toPromise();

  return { epic, watchProgress };
}

const { epic, watchProgress } = createEpic();
export { epic };
export default watchProgress;
