// @flow
import React from 'react';
import styled from 'styled-components';
import SelectWithExtraInfo from 'components/common/SelectWithExtraInfo';
import { Dimensions } from 'components/visuals';
import ProductTier from 'model/hams/ProductTier';
import { LICENSE_ACADEMIC, LICENSE_COMMERCIAL } from 'model/hams/LicenseType';
import AmountWithCurrency from 'components/common/AmountWithCurrency';
import TierLabel from 'components/common/TierLabel';

type Props = {
  tiers: ProductTier[],
  selected: ProductTier | null,
  onChange: (ProductTier) => void,
  isDisabled?: boolean,
  currency: Progress<string>,
  academic: boolean,
  showPrices: boolean,
};

const getOrderableItem = (tier: ProductTier, academic: boolean) =>
  tier.getOrderableItem(academic ? LICENSE_ACADEMIC : LICENSE_COMMERCIAL);

const SelectWrapper = styled.div`
  margin-right: ${Dimensions.units(1)}px;
`;

const getExtraInfo = (academic: boolean, showPrices: boolean, currency: string) => (
  tier: ProductTier,
) => {
  if (!showPrices) {
    return null;
  }
  const orderableItem = getOrderableItem(tier, academic);
  return <AmountWithCurrency currency={currency} amount={orderableItem.amount} />;
};

const Small = styled.span`
  font-size: 12px;
`;
const UnitCount = styled.span`
  font-size: 16px;
`;

const ChooseTier = (props: Props) => (
  <SelectWrapper>
    <SelectWithExtraInfo
      items={props.tiers}
      onChange={props.onChange}
      selected={props.selected}
      getOptionLabel={(tier) => (
        <Small>
          <TierLabel
            {...tier}
            formatUnitCount={(unitCount) => <UnitCount>{unitCount}</UnitCount>}
          />
        </Small>
      )}
      getOptionExtraInfo={getExtraInfo(props.academic, props.showPrices, props.currency)}
      getOptionValue={(tier) => getOrderableItem(tier, props.academic).orderableItemId}
      isDisabled={props.isDisabled}
    />
  </SelectWrapper>
);

ChooseTier.defaultProps = {
  isDisabled: false,
};

export default ChooseTier;
