import Progress from 'redux-progress';
import { HamsError } from 'modules/hams';

import selectOrderableItems from 'modules/addon/duck/selectors/orderableItems';
import selectTerm from 'modules/addon/duck/selectors/term';
import * as cart from 'modules/cart';
import { CartThunk } from 'model/State';

export const addToCart = (): CartThunk => (dispatch, getState) =>
  Progress.all(selectOrderableItems(getState()), selectTerm(getState())).ifSuccess(
    async ([items, term]) => {
      if (items.length > 0 && term) {
        const result = await dispatch(
          cart.actions.add({
            newItems: items.map((item) => ({
              orderableItemId: item.orderableItemId,
              maintenanceMonths: term.months,
              renewalMonths: term.months,
            })),
          }),
        );

        const isHamsError = result.result instanceof HamsError;

        if (result.success && !isHamsError) {
          dispatch(cart.actions.route());
        }
      }
    },
  );
