/* eslint-disable import/prefer-default-export */
import type { Dispatch, GetState } from 'model/State';
import selectCurrency from 'modules/cart/duck/selectors/currency';
import watchProgress from 'model/watchProgress';
import * as actions from 'modules/meta/notificationFlag/actions';
import { showCompanyCountryCurrency } from 'modules/checkout/contacts/duck/actions/showCountryCurrency';
import {
  getValidateCountryContactMatch,
  getValidateCartCurrencyCountryMatch,
  getTechnicalContactValidation,
} from 'modules/checkout/contacts/duck/selectors/getCountryValidity';
import getLockPurchaserEmail from 'modules/checkout/contacts/duck/selectors/getLockPurchaserEmail';
import { selectors } from 'modules/checkout/contacts/duck';
import { CartCountryValidation } from '../model';

export const validatePreEmptiveCompanyShowNotification = (newCurrency: string) => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  let currency = newCurrency;
  dispatch(showCompanyCountryCurrency(false));
  const currentCartCurrency = await watchProgress(selectCurrency);
  if (getLockPurchaserEmail(getState())) {
    const contactsData = await watchProgress(selectors.getData);
    let validateCountryContactMatch;
    if (!contactsData.isDifferentTechnical) {
      validateCountryContactMatch = await watchProgress(getValidateCountryContactMatch);
      if (validateCountryContactMatch.irreconcilable.length !== 0) {
        return;
      }
    }
    const validateCartCurrencyCountryMatch = (await watchProgress(
      getValidateCartCurrencyCountryMatch,
    )) as CartCountryValidation;
    if (validateCartCurrencyCountryMatch.lockedCartCurrency) {
      currency = validateCartCurrencyCountryMatch.lockedCartCurrency;
    }
    if (!validateCartCurrencyCountryMatch.compatible) {
      return;
    }
  }
  if (currency !== currentCartCurrency) {
    dispatch(showCompanyCountryCurrency(true));
    dispatch(actions.showNotificationFlag('currency.update.title'));
  }
};

export const validatePreEmptiveResellerShowNotification = (newCurrency: string) => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  let currency = newCurrency;
  const currentCartCurrency = await watchProgress(selectCurrency);

  const contactsData = await watchProgress(selectors.getData);
  if (contactsData.technicalContactDetails.email) {
    const technicalContactValidation = (await watchProgress(
      getTechnicalContactValidation,
    )) as CartCountryValidation;
    if (!technicalContactValidation.compatible) {
      return;
    }
  }

  if (getValidateCartCurrencyCountryMatch(getState()).isNone) {
    if (currency !== currentCartCurrency) {
      dispatch(actions.showNotificationFlag('currency.update.title'));
    }
  }
  const validateCartCurrencyCountryMatch = (await watchProgress(
    getValidateCartCurrencyCountryMatch,
  )) as CartCountryValidation;
  if (validateCartCurrencyCountryMatch.lockedCartCurrency) {
    currency = validateCartCurrencyCountryMatch.lockedCartCurrency;
  }

  if (!validateCartCurrencyCountryMatch.compatible) {
    return;
  }

  if (validateCartCurrencyCountryMatch.compatible && currency !== currentCartCurrency) {
    dispatch(actions.showNotificationFlag('currency.update.title'));
  }
};

export const validateReactiveShowNotification = (currentCurrency, previousCurrency) => async (
  dispatch: Dispatch,
) => {
  if (currentCurrency !== previousCurrency) {
    dispatch(actions.showNotificationFlag('currency.updated.title'));
  }
};
