import { combineEpics } from 'redux-observable';

import { route } from 'modules/mac/duck/actions/paymentDetails';

import { timeAction, counter } from './util';

export default combineEpics(
  counter(route.type, 'mac.payment-details'),
  timeAction(route.type, 'mac.payment-details'),
);
