// @flow
import { createSelector } from 'reselect';

import { SOFTWARE, SUPPORT } from 'modules/configure/duck/model';
import type { ConfigurationType } from 'modules/configure/duck/model';
import { getProduct } from 'data/products';
import type { Selector } from 'model/State';
import { SUPPORT as PRODUCT_TYPE_SUPPORT } from 'model/ProductType';
import { LICENSE_ACADEMIC, LICENSE_COMMERCIAL } from 'model/hams/LicenseType';
import { isAcademicSwitchOn } from 'modules/settings/academic';
import type { LicenseType } from 'model/hams/LicenseType';

export const selectConfigurationType: Selector<ConfigurationType | null> = (state) => {
  const { configure } = state;
  if (!configure) {
    return null;
  }
  const product = getProduct(configure.product.productKey);
  if (product !== null && product.type === PRODUCT_TYPE_SUPPORT) {
    return SUPPORT;
  }
  return SOFTWARE;
};

export const isAcademic: Selector<boolean> = createSelector(
  isAcademicSwitchOn,
  selectConfigurationType,
  (academicOn, type) => type === SOFTWARE && academicOn,
);

export const selectLicenseType: Selector<LicenseType> = createSelector(isAcademic, (academic) =>
  academic ? LICENSE_ACADEMIC : LICENSE_COMMERCIAL,
);
