/* eslint-disable no-param-reassign */
import set from 'lodash/set';
import { produce } from 'immer';
import { actions } from './';

export default produce((state, action) => {
  switch (action.type) {
    case actions.FETCH:
      set(state, [action.isoCountryCode, action.stateKey], action.progress);
      break;
    default:
  }
}, {});
