// @flow
import type { Dispatch, GetState } from 'model/State';

export const ROUTE = 'upgrade/tiers/ROUTE';
export const route = (params?: { [string]: string }) => ({
  type: ROUTE,
  meta: {
    query: params,
  },
});

export const routeIncludeParams = () => (dispatch: Dispatch, getState: GetState) =>
  dispatch(route(getState().location.query));
