/* eslint-disable import/prefer-default-export */
// @flow
import reportError from 'util/errorReporter';
import { redirect } from 'redux-first-router';
import { CLOUD, DATACENTER, SERVER } from 'model/hams/DeploymentOption';
import watchProgress from 'model/watchProgress';

import type { Dispatch, GetState } from 'model/State';
import { goToProductList } from 'actions';
import { isCartAFreeOrder } from 'modules/cart/duck/selectors';
import { actions as paymentActions } from 'modules/checkout/payment';
import { fetchAddonsInfo } from 'modules/catalog/addons';
import { markComplete } from 'modules/checkout/steps';
import { selectPurchaseMode } from 'modules/settings/purchaseMode';

const doYourBestAtGuessingTheHosting = (productDetails) => {
  if (productDetails.isDataCenter) {
    return DATACENTER;
  }
  return productDetails.productKey.endsWith('.ondemand') ? CLOUD : SERVER;
};

export const load = async (dispatch: Dispatch, getState: GetState) => {
  const cart = await watchProgress((state) => state.cart.payload);
  const state = getState();
  const { checkoutMode } = state.checkout.payment;
  const purchaseMode = selectPurchaseMode(state);

  if (!cart) {
    await dispatch(redirect(goToProductList()));
    return;
  }

  if (!isCartAFreeOrder(cart) && !checkoutMode && purchaseMode !== 'QUOTE' && !cart.inEditMode) {
    reportError('Checkout mode is null in a non-free, non-quote order');
    await dispatch(redirect(paymentActions.route()));
    return;
  }
  if (cart.inEditMode) {
    dispatch(markComplete('contact'));
    dispatch(markComplete('payment'));
  } else {
    const checkoutSteps = getState().checkout.steps;
    if (!checkoutSteps.payment.complete || !checkoutSteps.contact.complete) {
      await dispatch(redirect(goToProductList()));
      return;
    }
  }

  const marketplaceAddonKeys = cart.items
    .filter((item) => item.productDetails.marketplaceAddon)
    .map((item) => ({
      productKey: item.productDetails.productKey,
      hosting: doYourBestAtGuessingTheHosting(item.productDetails),
    }));

  await dispatch(fetchAddonsInfo(marketplaceAddonKeys));
};
