import { PropsSelector } from 'modules/tracking/selectors/props';
import { Dispatch, GetState } from 'model/State';
import { TrackEvent } from 'modules/analytics/model';
import { emitTrackEvent } from 'modules/analytics/actions';
import * as selectors from 'modules/tracking/selectors';

export interface TrackEventData {
  trackFields: TrackEvent.TrackFields;
  trackContext: TrackEvent.TrackContext;
  [s: string]: any;
}

export const createTrackEvent = (
  paramTrackData: TrackEventData,
  ...extraPropSelectors: (PropsSelector | Record<string, any>)[]
) => (dispatch: Dispatch, getState: GetState) => {
  const trackFields: TrackEvent.TrackFields = {
    ...paramTrackData.trackFields,
    attributes: Object.assign(
      {},
      paramTrackData.trackFields.attributes,
      selectors.props.common(getState()),
      ...extraPropSelectors.map((selectorOrObject) =>
        typeof selectorOrObject === 'function' ? selectorOrObject(getState()) : selectorOrObject,
      ),
    ),
  };

  const trackContext: TrackEvent.TrackContext = {
    ...paramTrackData.trackContext,
  };

  dispatch(
    emitTrackEvent({
      ...trackFields,
      ...trackContext,
    }),
  );
};
