import { progressResult, selectLatestFrom } from 'util/rxOperators';
import { Observable, interval, race } from 'rxjs';
import { distinctUntilChanged, map, debounce } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import * as actions from 'modules/checkout/contacts/duck/actions';
import * as selectors from 'modules/checkout/contacts/duck/selectors';
import { State$ } from 'model/State';
import { CheckoutContactsData } from 'modules/checkout/contacts/duck/model';

const validateImmediatelyOnLoad = (action$: Observable<any>) =>
  action$.pipe(ofType(actions.LOAD), map(actions.validatePurchaserLogin));

const validateDebouncedOnChange = (action$: Observable<any>, state$: State$) =>
  action$.pipe(
    ofType(actions.EDIT),
    selectLatestFrom(state$),
    progressResult(selectors.getData),
    map((d: CheckoutContactsData) => d.purchaserContactDetails.email),
    distinctUntilChanged(),
    debounce(() => race(interval(800), action$.pipe(ofType(actions.BLUR)))),
    map(actions.validatePurchaserLogin),
  );

export default combineEpics(validateImmediatelyOnLoad, validateDebouncedOnChange);
