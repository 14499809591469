/* eslint-disable react/jsx-no-target-blank */
// @flow
import React from 'react';

import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import { colors } from '@atlaskit/theme';

import Tooltip from 'components/common/Tooltip';
import t from 'modules/i18n/intl';

const SearchBySenTooltip = () => (
  <Tooltip
    direction="right"
    content={
      <>
        <p>{t('addon.search.tooltip.text')}</p>
        <a href="https://confluence.atlassian.com/x/khSzDQ" target="_blank">
          {t('addon.search.tooltip.cta')}
        </a>
      </>
    }
  >
    <QuestionCircleIcon size="small" primaryColor={colors.N80} />
  </Tooltip>
);

export default SearchBySenTooltip;
