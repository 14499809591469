import React from 'react';
import styled from 'styled-components';
import TermsSelect from 'modules/configure/terms/TermsSelect';
import TermsTooltip from 'modules/configure/terms/TermsTooltip';
import { Props } from 'modules/addon/VisualAddonPage';
import ChooseTier from 'modules/configure/common/ChooseTier';
import { Dimensions, Font } from 'components/visuals';
import t from 'modules/i18n/intl';

const Wrapper = styled.div`
  display: flex;
`;

const Header = styled.h4`
  font-family: ${Font.displayFamily};
  font-size: 20px;
  font-weight: 500;
  margin-bottom: ${Dimensions.units(1)}px;
`;

const Manual = (props: Props) => (
  <Wrapper>
    <div>
      <Header>{t('addon.manual.user-tier')}</Header>
      <ChooseTier
        tiers={props.data.tiers}
        academic={props.data.isAcademicOn}
        selected={props.manual.tier || props.data.tiers[0]}
        onChange={props.events.onTierSelect}
        showPrices={!props.data.isSubscription}
        currency={props.data.currency}
      />
    </div>
    <div>
      <Header>
        {props.data.isSubscription ? t('addon.subscription-term') : t('addon.maintenance-period')}
        &nbsp;
        <TermsTooltip subscription={props.data.isSubscription} />
      </Header>
      <TermsSelect
        options={props.data.manualTerms}
        selected={props.terms.manual || props.data?.manualTerms[0]}
        onChange={(tier) => props.events.onTermChange('manual', tier)}
        currency={props.data.currency}
      />
    </div>
  </Wrapper>
);

export default Manual;
