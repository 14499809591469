export type EnvironmentConfig = {
  idLoginUrl: string;
  hamsUrl: string;
  launchdarklyId: string | null;
  logoutUrl: () => string;
  macUrl: string;
  marketplaceUrl: string;
  padresUrl: string;
  paypal: {
    env: 'sandbox' | 'production';
  };
  segmentKey: string;
  tnsSessionUrl: string;
  wacUrl: string;
  i18nUrl: string;
  clicktaleEnabled: boolean;
  cloudMigrationToolsUrl: string;
  statsigClientSDK: string;
  adminHubUrl: string;
  emailMAC: (userEmail: string) => string;
  billingDetailsMAC: string;
  switchUserMAC: string;
  emailPreferencesMAC: string;
  switchAccountMAC: string;
  logOutMAC: string;
  root: string;
  calculatePricingURL: string;
};

export const BIZBOX = 'BIZBOX';
export const DEVELOPMENT = 'DEVELOPMENT';
export const E2E = 'E2E';
export const LOCAL = 'LOCAL';
export const PRODUCTION = 'PRODUCTION';
export const STAGING = 'STAGING';

export type Environment = 'BIZBOX' | 'DEVELOPMENT' | 'E2E' | 'LOCAL' | 'PRODUCTION' | 'STAGING';
