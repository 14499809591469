import React from 'react';

import TableHeader from 'modules/cart/summary/TableHeader';
import TableBody from 'modules/cart/summary/TableBody';
import TableFooter from 'modules/cart/summary/TableFooter';
import { Option } from 'modules/cart/summary/OptionsSelect';
import EmptyCart from 'modules/cart/summary/EmptyCart';
import { PurchaseMode } from 'modules/settings/purchaseMode';
import { HamsEnrichedCart } from 'modules/cart/duck/model';

type Props = {
  cart: HamsEnrichedCart;
  deletingItems: { [id: number]: true };
  onDelete: (id: number) => void;
  options?: Option[];
  purchaseMode: PurchaseMode;
  hideTotalPrice?: boolean;
  inSiteConfiguratorMode?: boolean;
};

const CartSummary = (props: Props) =>
  props.cart.items.length === 0 ? (
    <EmptyCart {...props} />
  ) : (
    <table>
      <TableHeader cart={props.cart} />
      <TableBody {...props} />
      <TableFooter
        cart={props.cart}
        options={props.options || []}
        purchaseMode={props.purchaseMode}
        hideTotalPrice={props.hideTotalPrice}
        inSiteConfiguratorMode={props.inSiteConfiguratorMode}
      />
    </table>
  );

CartSummary.defaultProps = {
  options: [],
  inSiteConfiguratorMode: false,
};

export default CartSummary;
