import React, { useCallback, useContext, useEffect, useState } from 'react';

import { fetchAccounts, HamsAccountMigrationInfo } from './api';
import { AccountsTableUI } from './AccountsTableUI';
import { AuthenticationContext } from 'modules/migrate/Authenticated';
import { mapProductKeyToLegacyProductKey, ServerProductKey } from 'data/products/keys';
import { AnalyticsContext } from 'modules/migrate/analytics/AnalyticsContext';
import { client as metalClient } from 'modules/telemetry';
import { catalog } from '@atlassiansox/metal-client';
import { getPricingLinkForServerProduct } from 'modules/migrate/comparator/utils';
import UFOSegment from '@atlassian/ufo-segment';

export const itemMapper = (item) => {
  const productKey = mapProductKeyToLegacyProductKey(item.productKey);

  return {
    ...item,
    productKey,
    pricingLink: getPricingLinkForServerProduct(productKey as ServerProductKey) || '',
  };
};

export const AccountsTable: React.FC<{
  summaryProductsNumberInitial: number;
  isPartner: boolean;
}> = ({ summaryProductsNumberInitial, isPartner }) => {
  const [loading, setLoading] = useState(true);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [nextPage, setNextPage] = useState(0);
  const [items, setItems] = useState<HamsAccountMigrationInfo[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const authenticationContext = useContext(AuthenticationContext);
  const analytics = useContext(AnalyticsContext);

  const loadData = useCallback(
    (page: number) => {
      (async () => {
        try {
          const start = new Date().getTime();
          const result = await fetchAccounts({ authenticationContext, page });
          setItems([...(items || []), ...result.items.map(itemMapper)]);
          setShowLoadMore(result.nextPage);
          setError(null);
          // eslint-disable-next-line no-unused-expressions
          metalClient?.metric.submit({
            name: catalog.userInteraction.TASK_SUCCESS,
            value: 1,
            page: 'MIGRATE',
            task: 'loadAccountsPage',
          });
          // eslint-disable-next-line no-unused-expressions
          metalClient?.metric.submit({
            name: catalog.userInteraction.TASK_DURATION,
            value: new Date().getTime() - start,
            page: 'MIGRATE',
            task: 'loadAccountsPage',
          });
        } catch (e) {
          // eslint-disable-next-line no-unused-expressions
          metalClient?.metric.submit({
            name: catalog.userInteraction.TASK_FAILURE,
            value: 1,
            page: 'MIGRATE',
            task: 'loadAccountsPage',
          });
          setError(e);
        }
        setLoading(false);
      })();
    },
    [items],
  );

  const loadNextPage = useCallback(() => {
    setLoading(true);
    loadData(nextPage);
    setNextPage(nextPage + 1);
  }, [loadData, nextPage]);

  useEffect(() => {
    if (!isPartner) {
      loadNextPage();
    }
  }, []);

  return (
    <UFOSegment name="acounts-table-load-data">
      <AccountsTableUI
        items={items}
        error={error}
        showLoadMore={showLoadMore}
        loadNextPage={loadNextPage}
        loading={loading}
        analytics={analytics}
        summaryProductsNumberInitial={summaryProductsNumberInitial}
        isPartner={isPartner}
      />
    </UFOSegment>
  );
};

export default AccountsTable;
