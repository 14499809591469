import { thunkProgress } from 'redux-progress';
import { Dispatch, GetState } from 'model/State';
import hamsClient from 'modules/hams';
import { selectXsrfToken } from '../selectors';

const doFetchToken = async () => {
  const response = await hamsClient.get('/1.0/public/auth/xsrf/plain', {
    validateStatus: (status) => status === 200 || status === 403 || status === 401,
    responseType: 'text',
  });
  return response.status === 403 || response.status === 401 ? null : response.data;
};

export const FETCH_XSRF_TOKEN = 'FETCH_XSRF_TOKEN';
export const fetchXsrfToken = () => async (dispatch: Dispatch, getState: GetState) => {
  if (selectXsrfToken(getState()).isNone) {
    await dispatch(thunkProgress(FETCH_XSRF_TOKEN, doFetchToken()));
  }
};
