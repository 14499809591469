/* eslint-disable class-methods-use-this */
// @flow
import { ExternalProductKeys } from 'data/products/keys';
import t from 'modules/i18n/intl';
import ExternalProduct from 'model/ExternalProduct';
import * as marketplace from 'modules/marketplace/duck/actions';
import { APP } from 'model/ProductType';

class MarketplaceProduct extends ExternalProduct {
  constructor() {
    super({
      key: ExternalProductKeys.MARKETPLACE,
      tagLine: t('products.marketplace.tagline'),
      description: t('products.marketplace.description'),
      name: t('products.marketplace.name'),
      type: APP,
      vendor: null,
    });
  }

  getLink(): Object {
    return marketplace.route();
  }
}

export default new MarketplaceProduct();
