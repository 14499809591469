// @flow
import * as React from 'react';
import { injectIntl } from 'react-intl';
import messages from './messages.json';

type Translate = (id: string | Array<string>, values: Object | void) => React.Node;

const translate = (intl) => (id, values): Translate => {
  if (
    (process.env.NODE_ENV === 'development' || window.CART_CHECK_I18N === true) &&
    !messages[id]
  ) {
    throw new Error(`Missing I18N message: ${id}`);
  }
  return intl.formatMessage({ id }, values);
};

export const Intl = injectIntl(({ intl, children }: any) => children(translate(intl)));
const t: Translate = (id, values) => <Intl>{(intl) => intl(id, values)}</Intl>;

export default t;
