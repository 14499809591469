import { redirect } from 'redux-first-router';
import Progress, { thunkProgress } from 'redux-progress';
import castArray from 'lodash/castArray';
import { actions as cartActions } from 'modules/cart/duck';
import { CartThunk } from 'model/State';

export const ADD_ITEMS: 'addtocart/ADD_ITEMS' = 'addtocart/ADD_ITEMS';
export type AddItemsAction = {
  readonly type: typeof ADD_ITEMS;
  readonly progress: Progress<void>;
};

const getCartItem = (orderableItemId) => {
  if (orderableItemId.startsWith('newPricingPlanItem:')) {
    return {
      orderableItemId,
      maintenanceMonths: 12,
      renewalMonths: 12,
    };
  }
  throw new Error(`Invalid orderableItemId: ${orderableItemId}`);
};

export const addItems = (): CartThunk<Promise<any>> => (dispatch, getState) =>
  dispatch(
    thunkProgress(
      ADD_ITEMS,
      (async () => {
        const state = getState();
        const orderableItemId =
          (state.location.query && state.location.query.orderableItemId) || null;
        if (!orderableItemId) {
          throw new Error('Missing orderableItemId');
        }
        const newItems = castArray(orderableItemId).map(getCartItem);
        (await dispatch(cartActions.add({ newItems }))).unwrap();
        dispatch(redirect(cartActions.route()));
      })(),
    ),
  );
