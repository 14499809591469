// @flow
import { checkCrossgradeAvailable, fetchAccountMigrateOptions } from './actions';
import type { Dispatch, GetState } from 'model/State';
import { emitScreenEvent } from 'modules/analytics/actions';
import * as properties from 'modules/tracking/selectors/props';

export default async (dispatch: Dispatch, state: GetState, redirectAction: any) => {
  await Promise.all([
    dispatch(checkCrossgradeAvailable()),
    dispatch(
      fetchAccountMigrateOptions(
        state.location.query.accountId || state.location.query.partnerAccountId,
      ),
    ),
  ]).then(([crossgradeProgress, migrateProgress]) => {
    if (!crossgradeProgress.result && !migrateProgress.result) {
      dispatch(redirectAction);
    } else {
      dispatch(
        emitScreenEvent({
          name: 'THREE_DOORS_PAGE',
          attributes: {
            ...properties.common(state),
            dcOption: crossgradeProgress.result,
            cloudOption: migrateProgress.result,
          },
        }),
      );
    }
  });
};
