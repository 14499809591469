import { createAction } from 'redux-action';

export const LOAD_CHANGE_LIST = 'change/table/LOAD';
export const LOAD_PAGINATED_CHANGE_LIST = 'change/table/LOAD_PAGINATED';
export const SUBMIT_CHANGE_LIST = 'change/table/SUBMIT';
export const RESET_CHANGE_LIST = 'change/table/RESET';
export const SCROLL_TO_ACCOUNT = 'change/table/SCROLL_TO_ACCOUNT';
export const FETCH_ACCOUNT_CHANGE_OPTIONS = 'change/table/FETCH_ACCOUNT_CHANGE_OPTIONS';

export const resetChangeList = createAction(RESET_CHANGE_LIST);
export const scrollToAccount = createAction(SCROLL_TO_ACCOUNT);

export const PICK_OPTION = 'change/account/option/CHOOSE';
export const pickOptionForAccount = createAction(PICK_OPTION);

export const PICK_TERM = 'change/account/term/CHOOSE';
export const pickTermForAccount = createAction(PICK_TERM);

export const TOGGLE_ACCOUNT = 'change/account/TOGGLE';
export const toggleAccount = createAction(TOGGLE_ACCOUNT);

export const TOGGLE_ALL_ACCOUNTS = 'change/account/all/TOGGLE';
export const toggleAllAccounts = createAction(TOGGLE_ALL_ACCOUNTS);
