/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import Login from 'modules/addon/Login';
import { Props } from 'modules/addon/VisualAddonPage';
import SearchResult from 'modules/addon/SearchResult';
import t from 'modules/i18n/intl';
import SearchBySen from 'components/search/SearchBySenForm';
import SearchBySenTooltip from 'components/search/SearchBySenTooltip';

const SearchBody = (props: Props) => (
  <>
    <div>
      {t('addon.search.text')}
      &nbsp;
      <SearchBySenTooltip />
    </div>
    <SearchBySen
      {...props.search}
      {...props.events}
      {...props.data}
      searchInProgress={props.search.result.inProgress}
    />
    <SearchResult {...props} />
  </>
);

const Search = (props: Props) =>
  (props.data.isAuthenticated && <SearchBody {...props} />) || (
    <Login>{t('addon.search.login-reason')}</Login>
  );

export default Search;
