import Progress, { thunkProgress } from 'redux-progress';
import { CartThunk } from 'model/State';
import { selectHamsClientConfig, selectXsrfToken } from 'modules/auth/selectors';
import watchProgress from 'model/watchProgress';
import * as api from 'modules/accounts/api';

export const FETCH_ACCOUNTS = 'accounts/FETCH';
export const SEARCH_ACCOUNTS = 'accounts/SEARCH';

export const fetchAccountOverview = (): CartThunk => async (dispatch, getState) => {
  dispatch({ type: FETCH_ACCOUNTS, progress: Progress.inProgress });

  const xsrf = await watchProgress(selectXsrfToken);
  if (xsrf) {
    await dispatch(
      thunkProgress(FETCH_ACCOUNTS, api.fetchAccounts(selectHamsClientConfig(getState()))),
    );
  } else {
    dispatch({ type: FETCH_ACCOUNTS, progress: Progress.success(null) });
  }
};

export const searchAccount = (
  accountId: string,
  email: string,
): CartThunk<Promise<Progress<any>>> => async (dispatch, getState) => {
  await watchProgress(selectXsrfToken);
  return dispatch(
    thunkProgress(
      SEARCH_ACCOUNTS,
      api.searchAccount(selectHamsClientConfig(getState()), accountId, email),
      { accountId, email },
    ),
  );
};
