import { CART_DELETE_ITEM_ENQUEUE, CART_DELETE_ITEM_FLUSH } from '../actions';

export default (state: number[] = [], action: any) => {
  switch (action.type) {
    case CART_DELETE_ITEM_ENQUEUE:
      return [...state, ...action.payload.itemIds];
    case CART_DELETE_ITEM_FLUSH:
      return [];
    default:
      return state;
  }
};
