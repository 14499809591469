// @flow
import merge from 'lodash/merge';
import { envTypes } from '@atlassiansox/metal-client';
import type { EnvironmentConfig } from 'model/EnvironmentConfig';
import staging from 'appconfig/envs/staging';

export default (): EnvironmentConfig =>
  merge(staging(), {
    clicktaleEnabled: false,
    hamsUrl: 'https://wac.stg.internal.atlassian.com/hamlet',
    launchdarklyId: null,
    logoutUrl: () => '#logout',
    sentry: null,
    i18nUrl: 'https://cart-static.ap-southeast-2.dev.public.atl-paas.net/i18n',
    metal: {
      environment: envTypes.LOCAL,
    },
    root: 'http://cart.e2e.atlassian.com/purchase',
  });
