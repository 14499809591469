import { useEffect, useState } from 'react';

export type Condition = () => boolean;
const useAlwaysReturnTrueOnceConditionIsMet = (condition: Condition): boolean => {
  const [conditionHasBeenMet, setConditionHasBeenMet] = useState(condition);

  const evaluatedCondition = condition();
  useEffect(() => {
    if (evaluatedCondition) {
      setConditionHasBeenMet(true);
    }
  }, [evaluatedCondition]);

  return evaluatedCondition || conditionHasBeenMet;
};

export default useAlwaysReturnTrueOnceConditionIsMet;
