import React, { useContext } from 'react';
import Progress from 'redux-progress';
import styled from 'styled-components';

import UFOLoadHold from '@atlassian/ufo-load-hold';
import { LoadingButton } from '@atlaskit/button';
import { colors } from '@atlaskit/theme';
import { Dimensions, Font } from 'components/visuals';
import AmountWithCurrency from 'components/common/AmountWithCurrency';
import { CartAnalyticsContext } from 'modules/analytics';
import { AnalyticsClient } from 'modules/analytics/CartAnalyticsClient';
import type { PurchaseMode } from 'modules/settings/purchaseMode';
import t from 'modules/i18n/intl';
import type { HamsCart } from 'modules/cart/duck/model';
import type { ICartAnalyticsContext } from 'modules/analytics/CartAnalyticsUIContext';
import AddToCartErrorMessage from 'components/common/AddToCartErrorMessage';
import Pagination, { PaginationProps } from 'components/common/Pagination';

export type Props = {
  isSticky?: boolean;
  hideBorder?: boolean;
  amount: number | null;
  currency: string;
  submitProgress: Progress<HamsCart>;
  purchaseMode: PurchaseMode;
  isExpert?: boolean;
  showSearchDescription?: boolean;
  onAddToCart: (analytics?: AnalyticsClient | ICartAnalyticsContext) => void;
  isDisabled?: boolean;
  showError?: boolean;
  showAmountDetails?: boolean;
  pagination?: PaginationProps | null;
  isRenewUpgrade?: boolean;
};

export const Wrapper = styled.div<Props>`
  text-align: right;
  padding-top: ${Dimensions.units(2)}px;
  margin-bottom: ${Dimensions.units(2)}px;
  font-family: ${Font.displayFamily};
  top: 0;
  position: ${(props) => (props.isSticky ? 'sticky' : 'relative')};
  border-top: ${(props) =>
    props.isSticky || props.hideBorder ? 'none' : `1px solid ${colors.N80}`};
`;

const SubTotalLabel = styled.p`
  color: ${colors.N400};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 500;
`;

const DiscountsNotice = styled.p`
  font-family: ${Font.family};
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 15px;
  max-width: ${Dimensions.units(22)}px;
  display: inline-block;
`;

const TaxCreditPartnerDiscountInfo = styled.p`
  font-size: 12px;
  margin-bottom: ${Dimensions.units(3)}px;
  color: ${colors.N500};
`;

const NavWrapper = styled.div<Props>`
  margin-top: ${(props) => (props.isSticky ? 0 : `${Dimensions.units(1)}px`)};
`;

const Nav = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Description = styled.p`
  text-align: right;
  color: ${colors.N500};
  font-family: ${Font.family};
  margin-bottom: ${Dimensions.units(2)}px;
`;

const PaginationWrapper = styled.div`
  margin-right: auto;
`;

const ButtonContent = styled.span`
  display: inline-block;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 500;
  font-family: ${Font.family};
`;

const getText = (purchaseMode: PurchaseMode) =>
  purchaseMode === 'BUY' ? t('common.add-to-cart') : t('common.add-to-quote');

const ExpertNoticeText = ({ isExpert }: { isExpert?: boolean }) =>
  isExpert ? <DiscountsNotice>{t('common.discounts-applied')}</DiscountsNotice> : null;

const AddToCart = (props: Props) => {
  const analytics = useContext(CartAnalyticsContext);

  return (
    <Wrapper {...props}>
      {props.showError ? (
        <AddToCartErrorMessage
          submitProgress={props.submitProgress}
          onAddToCart={props.onAddToCart}
        />
      ) : null}
      {!props.showAmountDetails && !!props.amount && (
        <TaxCreditPartnerDiscountInfo>
          {t('addtocart.extra-tax-credit-discount-info')}
        </TaxCreditPartnerDiscountInfo>
      )}
      <NavWrapper {...props}>
        {props.showSearchDescription && <Description>{t('common.search-description')}</Description>}
        <Nav>
          {props.pagination && (
            <PaginationWrapper data-cy="pagination">
              <Pagination
                limit={props.pagination.limit}
                offset={props.pagination.offset}
                totalItems={props.pagination.totalItems}
                onPaginationChange={props.pagination.onPaginationChange}
              />
            </PaginationWrapper>
          )}
          <UFOLoadHold name="adding-to-cart" hold={props.submitProgress.inProgress} />
          <LoadingButton
            appearance="primary"
            onClick={() => {
              props.onAddToCart(analytics);
            }}
            interactionName={props.isRenewUpgrade ? 'upgrade-submit-changelist' : 'items-add'}
            isLoading={props.submitProgress.inProgress}
            isDisabled={props.isDisabled}
            data-cy="add-to-cart"
          >
            <ButtonContent>{getText(props.purchaseMode)}</ButtonContent>
          </LoadingButton>
        </Nav>
      </NavWrapper>
      {props.showAmountDetails && !!props.amount && (
        <>
          <SubTotalLabel>{t('common.subtotal')}</SubTotalLabel>
          <div>
            <AmountWithCurrency
              currency={props.currency}
              amount={props.amount}
              format="code"
              size="xx-large"
              dataRole="subtotal"
            />
          </div>
          <ExpertNoticeText isExpert={props.isExpert} />
        </>
      )}
    </Wrapper>
  );
};

AddToCart.defaultProps = {
  hideBorder: false,
  isDisabled: false,
  showError: true,
  showAmountDetails: true,
  showSearchDescription: false,
  pagination: null,
};

export default AddToCart;
