import React from 'react';
import styled from 'styled-components';

import Spinner from '@atlaskit/spinner';
import TrashIcon from '@atlaskit/icon/glyph/trash';

import { HamsCartItem } from 'modules/cart/duck/model';

import Cell from './Cell';

type Props = {
  onDelete: () => void;
  deleting: boolean;
  deleteAvailable: boolean;
  item: HamsCartItem;
};

const IconButton = styled.button`
  background: transparent;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;

  &:hover {
    background-color: transparent;
  }
`;

const StyledSpinner = styled(Spinner)`
  &:focus {
    outline: none;
  }
`;

const DeleteIcon = (props: Props) =>
  props.deleting ? (
    <StyledSpinner interactionName="items-delete-spinner" size="small" />
  ) : (
    <IconButton data-delete-item-id={`${props.item.id}`} onClick={props.onDelete}>
      <TrashIcon size="small" label="remove" />
    </IconButton>
  );

const ActionsCell = (props: Props) => (
  <Cell>{props.deleteAvailable && <DeleteIcon {...props} />}</Cell>
);
export default ActionsCell;
