/* eslint-disable import/prefer-default-export */
// @flow
import { sum } from 'util/reducers';
import { createSelector } from 'reselect';
import { selectOrderableItems } from 'modules/configure/duck/selectors/items';
import { selectTerm } from 'modules/configure/duck/selectors/terms';
import { isSubscription } from 'modules/configure/duck/selectors/mainProduct';
import type { Selector } from 'model/State';

export const selectTotalAmount: Selector<number> = createSelector(
  selectOrderableItems,
  selectTerm,
  isSubscription,
  (items, term, subscription) =>
    subscription ? term.amount : items.map((item) => item.amount).reduce(sum, term.amount),
);
