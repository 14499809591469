import transition from 'styled-transition-group';

export default transition.div.attrs({
  unmountOnExit: true,
  timeout: 300,
})`
  &:enter { 
    max-height: 0px;
    overflow: hidden; 
  }
  &:enter-active {
    max-height: ${(p) => p.maxHeight}px;
    transition: max-height 300ms ease-out;
  }
  &:exit { 
    max-height:  ${(p) => p.maxHeight}px;
    overflow: hidden; 
  }
  &:exit-active {
    max-height: 0px;
    transition: max-height 300ms ease-out;
  }`;
