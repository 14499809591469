// @flow
import type { MapToProps } from 'util/react-redux/connectStateAndDispatch';
import type { AutoRenewalProps } from 'modules/checkout/payment/duck/model';
import * as actions from 'modules/checkout/payment/duck/actions';
import getAutoRenewItems from 'modules/checkout/payment/duck/selectors/getAutoRenewItems';
import canAutoRenew from 'modules/checkout/payment/duck/selectors/getCanAutoRenew';

const getAutoRenewalProps: MapToProps<AutoRenewalProps> = (state, dispatch) =>
  getAutoRenewItems(state).map((autoRenewItems) => ({
    isAvailable: autoRenewItems.length > 0 && canAutoRenew(state),
    items: autoRenewItems,
    selectItem: (selected, id) => dispatch(actions.selectAutoRenewItems(selected, [id])),
    selectAll: (selected) => dispatch(actions.selectAutoRenewAll(selected)),
  }));

export default getAutoRenewalProps;
