import reportError from 'util/errorReporter';
import cookies from 'js-cookie';

// export const cookieSettings = { expires: 3650, path: '/purchase' };
export const cookieSettings = { path: '/purchase' };

function qualifyKey(host: string, key: string): string {
  const prefix = `${host.split('.').reverse().join('.')}.angular.storage`;
  return `${prefix}.${key}`;
}

class AtlStorage {
  key: string;
  storage: Storage;
  cookieName: string | null;

  constructor(
    key: string,
    storage: Storage,
    cookieName: string | null = null,
    host: string = window.location.hostname,
  ) {
    this.storage = storage;
    this.key = qualifyKey(host, key);
    this.cookieName = cookieName;
  }

  loadFromCookie = () => (this.cookieName ? cookies.getJSON(this.cookieName) : null);

  loadFromStorage = () => {
    const stored = this.storage.getItem(this.key);
    if (stored != null) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        reportError(e);
      }
    }
    return null;
  };

  load = () => this.loadFromStorage();

  reset = () => {
    if (this.cookieName) {
      cookies.remove(this.cookieName, cookieSettings);
    }
    this.storage.removeItem(this.key);
  };

  store = (value: any) => {
    if (this.cookieName) {
      cookies.set(this.cookieName, value, cookieSettings);
    }
    this.storage.setItem(this.key, JSON.stringify(value));
  };
}

export default AtlStorage;
