import { OrderableItem, PriceStrategy } from 'model/hams/Pricing';
import {
  ServerProductKeys,
  EnterpriseAvailableServerProducts,
  ServerProductKey,
  PricingPlansList,
  PricingPlan,
} from 'data/products/keys';
import { HamsAccountMigrationInfo } from '../api';
import { PRICING_PLANS } from '../utils';

export const getMonthlyAmount = (priceStrategy: PriceStrategy, unitCount: number): number => {
  const adjustToBlock = (block, num) => {
    let newBlock = num;
    while (newBlock % block !== 0) {
      newBlock += 1;
    }
    return newBlock;
  };

  const adjustToUnitLimit = (unitLimit, newUnitCount) => {
    if (unitLimit >= 0) {
      return Math.min(unitLimit, newUnitCount);
    }
    return newUnitCount;
  };

  return priceStrategy?.priceBands
    .filter((priceBand) => priceBand.unitBlockSize > 0 && priceBand.unitStart <= unitCount)
    .map(
      (priceBand) =>
        (adjustToBlock(
          priceBand.unitBlockSize,
          adjustToUnitLimit(
            priceBand.unitLimit - priceBand.unitStart + 1,
            unitCount - priceBand.unitStart + 1,
          ),
        ) /
          priceBand.unitBlockSize) *
        priceBand.price,
    )
    .reduce((a, b) => a + b, 0);
};

export const isKnownProduct = (productKey) =>
  Object.values(ServerProductKeys).indexOf(productKey) !== -1;

export const isEnterpriseAvailableProduct = (productKey: ServerProductKey) =>
  EnterpriseAvailableServerProducts.includes(productKey);

export const getAnnualTierIndex = (
  account: HamsAccountMigrationInfo,
  plan: string,
  numberOfUsers: number,
) => {
  if (!account.annualTiers) {
    return -1;
  }

  const index = account.annualTiers
    .sort((a, b) => a.unitCount - b.unitCount)
    .findIndex(({ edition, unitCount }) => {
      if (!edition) {
        return numberOfUsers <= unitCount;
      }
      return edition === plan.toLowerCase() && numberOfUsers <= unitCount;
    });

  if (index === -1) {
    return account.annualTiers.length - 1;
  }
  return index;
};

export const DEFAULT_UNIT_COUNT_LIMIT = 10000;

export const getMaxUnitNumber = (annualTiers: Array<OrderableItem> | null): number => {
  if (annualTiers && annualTiers.length) {
    const lastTier = annualTiers[annualTiers.length - 1];
    return lastTier.unitCount;
  }
  return DEFAULT_UNIT_COUNT_LIMIT;
};

export const getDefaultRecommendedPlan = (
  freeTierLimit: number,
  unitCount: number,
  plansList: PricingPlansList,
  maximumUnitCount: number,
): { defaultRecommendedPlan: PricingPlan; isEnterprise: boolean } => {
  let defaultRecommendedPlan = plansList[0];
  let isEnterprise = false;

  if (unitCount > maximumUnitCount) {
    isEnterprise = true;
  } else if (
    unitCount > freeTierLimit &&
    unitCount <= maximumUnitCount &&
    plansList.includes(PRICING_PLANS.STANDARD)
  ) {
    defaultRecommendedPlan = PRICING_PLANS.STANDARD;
  }
  return { defaultRecommendedPlan, isEnterprise };
};
