// @flow
import React from 'react';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';

import t from 'modules/i18n/intl';
import config from 'appconfig';
import { MessageNotificationWrapper } from './styled';

type Props = {
  requestTimeout: boolean,
};

const TimeoutWarning = (props: Props) =>
  !props.requestTimeout ? null : (
    <MessageNotificationWrapper>
      <SectionMessage
        appearance="warning"
        title={t('review.taking-longer-than-usual')}
        actions={[
          {
            text: t('common.contact-us'),
            href: `${config.wacUrl}/company/contact/purchasing-licensing`,
          },
        ].map(({ text, ...restAction }) => (
          <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
        ))}
      >
        {t('review.cart-expired')}
      </SectionMessage>
    </MessageNotificationWrapper>
  );

export default TimeoutWarning;
