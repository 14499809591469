import { IAnalyticsContext } from 'modules/migrate/analytics/AnalyticsContext';
import { UIAction, UIActionSubject } from 'modules/analytics/model/UIEvent';

interface Option {
  action: UIAction;
  actionSubject: UIActionSubject;
  actionSubjectId: string;
  eventComponent?: string;
  parentLabel?: string;
  label?: string;
  uuid: string;
}

type Params = { [key: string]: string | string[] | number | boolean };

type OptionStorage<T> = {
  [key in keyof T]: (additionalAttrs?: Params) => void;
};

export const generateAnalytics = <T extends { [key in keyof T]: Option }>(
  analytics: IAnalyticsContext,
  events: T,
  defaultAttrs?: Params,
): OptionStorage<T> =>
  Object.keys(events).reduce((resultEvents, key) => {
    const {
      action,
      actionSubject,
      actionSubjectId,
      parentLabel,
      eventComponent,
      label,
      uuid,
    } = events[key];
    const newResultEvents = resultEvents;
    newResultEvents[key] = (additionalAttrs?: Params) =>
      analytics.sendUiEvent({
        action,
        actionSubject,
        actionSubjectId,
        attributes: {
          parentLabel,
          label,
          uuid,
          eventComponent,
          schemaVersion: 1,
          ...defaultAttrs,
          ...additionalAttrs,
        },
      });

    return newResultEvents;
  }, {} as OptionStorage<T>);
