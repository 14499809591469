import React from 'react';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';
import { ServerProductKeys } from 'data/products/keys';
import { Dimensions } from 'components/visuals';
import { colors } from '@atlaskit/theme';

const Wrapper = styled.div`
  text-align: center;
  padding: ${Dimensions.units(1)}px;
  border-radius: ${Dimensions.units(1.25)}px;
  background-color: ${colors.N20};
`;

const CloudUnavailableTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  min-height: ${Dimensions.units(2.75)}px;
`;

const CloudUnavailableLink = styled.a`
  font-size: 14px;
  line-height: 20px;
`;

type Props = {
  titleId: string;
  link?: string;
  orLink?: string;
  linkId?: string;
  linkText?: string;
  orLinkText?: string;
  productKey?: string;
};

const BasicMaintenanceMode = ({ productKey }: { productKey: string }) => {
  if (productKey !== ServerProductKeys.CRUCIBLE && productKey !== ServerProductKeys.FISHEYE) {
    return null;
  }
  const productName = productKey === ServerProductKeys.CRUCIBLE ? 'Crucible' : 'Fisheye';
  return (
    <a
      href="https://confluence.atlassian.com/fisheye/fisheye-and-crucible-are-in-basic-maintenance-mode-987143949.html"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="migrate.table.row.basic-maintenance" values={{ productName }} />
    </a>
  );
};

export const CloudUnavailable = ({
  titleId,
  link,
  orLink,
  linkId,
  linkText,
  orLinkText,
  productKey,
}: Props) => (
  <Wrapper>
    <CloudUnavailableTitle>
      <FormattedMessage id={titleId} />
    </CloudUnavailableTitle>
    {link && linkId && (
      <CloudUnavailableLink href={link} target="_blank" rel="noopener noreferrer">
        <FormattedMessage id={linkId} />
      </CloudUnavailableLink>
    )}
    {link && linkText && (
      <CloudUnavailableLink href={link} target="_blank" rel="noopener noreferrer">
        {linkText}
      </CloudUnavailableLink>
    )}
    {orLink && orLinkText && (
      <>
        <FormattedMessage id="migrate.table.row.or" />
        <CloudUnavailableLink href={orLink} target="_blank" rel="noopener noreferrer">
          {orLinkText}
        </CloudUnavailableLink>
      </>
    )}
    {productKey && <BasicMaintenanceMode productKey={productKey} />}
  </Wrapper>
);
