import React from 'react';
import Cell from 'modules/cart/summary/cells/Cell';
import { TextProductLogo } from 'components/common/ProductLogo';
import { FormattedMessage } from 'react-intl';

type Props = {
  notes: string;
};

const TextCell = (props: Props) => (
  <Cell>
    <TextProductLogo size="small">
      <FormattedMessage id="review.sla-credit-prefix" /> {props.notes}
    </TextProductLogo>
  </Cell>
);

export default TextCell;
