// @flow
import reportError from 'util/errorReporter';

import Product from 'model/Product';

import ServerProduct from 'model/editions/ServerProduct';
import DataCenterProduct from 'model/editions/DataCenterProduct';
import ExternalProduct from 'model/ExternalProduct';
import CloudProduct from 'model/editions/CloudProduct';
import SoftwareProductGroup from 'model/SoftwareProductGroup';
import SoftwareProduct from 'model/SoftwareProduct';
import SupportProductGroup from 'model/SupportProductGroup';
import SupportProduct from 'model/SupportProduct';
import type { Group } from 'modules/catalog/groups';
import * as Bamboo from 'data/products/bamboo';
import * as Bitbucket from 'data/products/bitbucket';
import * as Confluence from 'data/products/confluence';
import * as ConfluenceQuestions from 'data/products/confluence.questions';
import * as ConfluenceTeamCalendars from 'data/products/confluence.teamcalendars';
import * as Crowd from 'data/products/crowd';
import * as Crucible from 'data/products/crucible';
import * as Fisheye from 'data/products/fisheye';
import * as JiraSoftware from 'data/products/jira.software';
import * as JiraServiceDesk from 'data/products/jira.servicedesk';
import * as JiraCore from 'data/products/jira.core';
import * as JiraCapture from 'data/products/jira.capture';
import * as JiraPortfolio from 'data/products/jira.portfolio';

import Marketplace from 'data/products/marketplace';
import PremierSupport from 'data/products/support.premier';
import * as PrioritySupport from 'data/products/support.priority';
import StatusPage from 'data/products/statuspage';
import TechnicalAccountManagement from 'data/products/technical.account.management';

import type { ProductGroupKey, ProductKey } from 'data/products/keys';

export const AllServerProducts: ServerProduct[] = [
  Bamboo.bambooServer,
  Bitbucket.bitbucketServer,
  Confluence.confluenceServer,
  ConfluenceQuestions.confluenceQuestionsServer,
  ConfluenceTeamCalendars.confluenceTeamCalendarsServer,
  Crowd.crowdServer,
  Crucible.crucibleServer,
  Fisheye.fisheyeServer,
  JiraCapture.jiraCaptureServer,
  JiraCore.jiraCoreServer,
  JiraPortfolio.jiraPortfolioServer,
  JiraServiceDesk.jiraServiceDeskServer,
  JiraSoftware.jiraSoftwareServer,
];

export const AllDataCenterProducts: DataCenterProduct[] = [
  Bamboo.bambooDataCenter,
  Bitbucket.bitbucketDataCenter,
  Confluence.confluenceDataCenter,
  ConfluenceQuestions.confluenceQuestionsDatacenter,
  ConfluenceTeamCalendars.confluenceTeamCalendarsDatacenter,
  Crowd.crowdDataCenter,
  JiraCapture.jiraCaptureDataCenter,
  JiraPortfolio.jiraPortfolioDatacenter,
  JiraServiceDesk.jiraServiceDeskDataCenter,
  JiraSoftware.jiraSoftwareDatacenter,
];

export const AllCloudProducts: CloudProduct[] = [
  Bitbucket.bitbucketCloud,
  Confluence.confluenceCloud,
  ConfluenceQuestions.confluenceQuestionsCloud,
  ConfluenceTeamCalendars.confluenceTeamCalendarsCloud,
  JiraCapture.jiraCaptureCloud,
  JiraCore.jiraCoreCloud,
  JiraPortfolio.jiraPortfolioCloud,
  JiraServiceDesk.jiraServiceDeskCloud,
  JiraSoftware.jiraSoftwareCloud,
];

export const AllExternalProducts: ExternalProduct[] = [
  StatusPage,
  Marketplace,
  TechnicalAccountManagement,
];

export const AllSupportProducts: Product[] = [
  PremierSupport,
  PrioritySupport.prioritySupportServerBamboo,
  PrioritySupport.prioritySupportServerBitbucket,
  PrioritySupport.prioritySupportDatacenterBamboo,
  PrioritySupport.prioritySupportDatacenterBitbucket,
];

export const AllProducts: Product[] = [
  ...AllServerProducts,
  ...AllDataCenterProducts,
  ...AllCloudProducts,
  ...AllExternalProducts,
  ...AllSupportProducts,
];

export const SoftwareProductGroups: SoftwareProductGroup[] = [
  Bamboo.bambooProductGroup,
  Bitbucket.bitbucketProductGroup,
  Confluence.confluenceProductGroup,
  ConfluenceQuestions.confluenceQuestionsProductGroup,
  ConfluenceTeamCalendars.confluenceTeamCalendarsProductGroup,
  Crowd.crowdProductGroup,
  Crucible.crucibleProductGroup,
  Fisheye.fisheyeProductGroup,
  JiraCapture.jiraCaptureProductGroup,
  JiraCore.jiraCoreProductGroup,
  JiraPortfolio.jiraPortfolioProductGroup,
  JiraServiceDesk.jiraServiceDeskProductGroup,
  JiraSoftware.jiraSoftwareProductGroup,
];

export const SupportProductGroups: SupportProductGroup[] = [
  PrioritySupport.prioritySupportProductGroupWithBitbucket,
  PrioritySupport.prioritySupportProductGroupWithBamboo,
];

export const ProductMap: Map<ProductKey, Product> = new Map(
  AllProducts.map((product) => [product.key, product]),
);

export const SupportProductMap: Map<ProductKey, Product> = new Map(
  AllSupportProducts.map((product) => [product.key, product]),
);

export const SoftwareProductGroupMap: Map<ProductGroupKey, SoftwareProductGroup> = new Map(
  SoftwareProductGroups.map((group) => [group.key, group]),
);

export const SupportProductGroupMap: Map<ProductGroupKey, SupportProductGroup> = new Map(
  SupportProductGroups.map((group) => [group.key, group]),
);

export function getProductOr<T>(key: any, def: T): Product | T {
  return ProductMap.get(key) || def;
}

export function getProduct(key: string): Product | null {
  return getProductOr(key, null);
}

export function getSoftwareProduct(key: string): SoftwareProduct | null {
  const product = ProductMap.get((key: any));
  return product && product instanceof SoftwareProduct ? product : null;
}

export function getSupportProduct(key: any): SupportProduct | null {
  const map = SupportProductMap;
  const product = map.get(key);
  return product && product instanceof SupportProduct ? product : null;
}

export function getStaticProductGroup(key: ProductGroupKey): Group | null {
  const data = SoftwareProductGroupMap.get(key) || SupportProductGroupMap.get(key);
  if (data) {
    return data.getDeploymentOptions();
  }
  return null;
}

export function getSoftwareProductGroup(product: SoftwareProduct): SoftwareProductGroup | null {
  const softwareProductGroup = SoftwareProductGroupMap.get(product.groupKey);
  if (softwareProductGroup === null) {
    reportError(`Non existant product group with key ${product.groupKey}`);
  }
  return softwareProductGroup || null;
}
