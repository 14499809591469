import React from 'react';
import styled from 'styled-components';

import { colors } from '@atlaskit/theme';

import { Props } from 'modules/addon/VisualAddonPage';
import { Dimensions, Font } from 'components/visuals';

const List = styled.ul`
  padding: 0;
  margin-bottom: ${Dimensions.units(4)}px;
  border: 1px solid ${colors.N80};
  border-radius: 4px;
`;

const Entry = styled.li`
  list-style: none;
  font-family: ${Font.displayFamily};
  font-size: 20px;
  font-weight: 400;
  padding: ${Dimensions.units(2)}px;
  border-bottom: 1px solid ${colors.N80};

  &:last-child {
    border-bottom: none;
  }
`;

const MultiAddonInfo = (props: Props) =>
  (props.data.productKeys.length > 1 && (
    <List>
      {props.data.pricing.map((pricing) => (
        <Entry key={pricing.productKey}>{pricing.productDescription}</Entry>
      ))}
    </List>
  )) ||
  null;

export default MultiAddonInfo;
