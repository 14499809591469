import { FormSection as AkFormSection } from '@atlaskit/form';
import { colors } from '@atlaskit/theme';
import React from 'react';
import styled from 'styled-components';
import Slide from 'modules/checkout/contacts/Slide';
import ContactInputs from 'modules/checkout/contacts/ContactInputs';
import * as styles from 'modules/checkout/contacts/styles';
import { Props } from 'modules/checkout/contacts/CheckoutContactPage';
import Help from 'components/common/Help';
import { Checkbox, Field } from 'modules/form';
import t from 'modules/i18n/intl';
// Prevent Atlaskit from fading checkbox label when disabled
const LabelNoFade = styled.span`
  color: ${colors.N800};
`;

export default ({
  data,
  isExpert,
  lockDifferentTechnical,
  inSiteConfiguratorMode,
}: Partial<Props>) => (
  <>
    <AkFormSection
      title={
        <>
          <Field
            name="isDifferentTechnical"
            isDisabled={lockDifferentTechnical || inSiteConfiguratorMode}
          >
            <Checkbox
              name="technical"
              label={
                <LabelNoFade>
                  {t('checkout.contact.technical.contact.text')}
                  <Help>
                    {isExpert || data.resellerOrder
                      ? t('checkout.contact.technical-contact.reseller-confirmation-message')
                      : t('checkout.contact.technical-contact.confirmation-message')}
                  </Help>
                </LabelNoFade>
              }
              value="technical"
            />
          </Field>
          <Slide in={!data.isDifferentTechnical} maxHeight={32}>
            <styles.CheckboxSubtext>
              {t('checkout.contact.technical.contact.tip')}
            </styles.CheckboxSubtext>
          </Slide>
        </>
      }
    />
    <Slide in={data.isDifferentTechnical} maxHeight={285}>
      <styles.Section>
        <ContactInputs
          name="technicalContactDetails"
          isDifferentTechnical={data.isDifferentTechnical}
          lockEmail={inSiteConfiguratorMode}
          expertOrder={data.expertOrder}
          resellerOrder={data.resellerOrder}
        />
      </styles.Section>
    </Slide>
  </>
);
