import { combineReducers } from 'redux';
import submitProgress from './submitProgress';
import eulaAccepted from './eulaAccepted';
import orderStatus from './orderStatus';
import requestTimeout from './requestTimeout';

export default combineReducers({
  eulaAccepted,
  orderStatus,
  requestTimeout,
  submitProgress,
});
