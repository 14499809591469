import { combineEpics } from 'redux-observable';
import orgCountrySelected from './fetchCountryDetailsAndTaxRate';
import resellerToggled from './resellerToggled';
import scrollToValidationBanner from './scrollToMessageBanner';
import validatePurchaserLogin from './validatePurchaserLogin';
import forceStateUppercase from './forceStateUppercase';
import validateCountryContactMatch from './validateContactCountry';
import showPreEmptiveCurrencyNotificationCompany from './showPreEmptiveCurrencyNotificationCompany';
import showPreEmptiveCurrencyNotificationReseller from './showPreEmptiveCurrencyNotificationReseller';
import showReactiveCurrencyNotification from './showReactiveCurrencyNotification';

export default combineEpics(
  orgCountrySelected,
  resellerToggled,
  forceStateUppercase,
  scrollToValidationBanner,
  validatePurchaserLogin,
  validateCountryContactMatch,
  showPreEmptiveCurrencyNotificationCompany,
  showPreEmptiveCurrencyNotificationReseller,
  showReactiveCurrencyNotification,
);
