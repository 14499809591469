import { Meta } from './types';

const fieldsMeta: Meta = {
  organisationName: {
    errors: [],
    label: 'checkout.contact.company-name',
  },
  address1: {
    errors: [
      'ERR_TAAS_ADDRESSES_INVALID_STREET_NAME_001',
      'ERR_TAAS_ADDRESSES_INVALID_STREET_NUMBER_002',
    ],
    characterLimit: 50,
    lengthBreachKey: 'LINE1_LENGTH_BREACH',
    label: 'checkout.contact.address-line-1',
  },
  address2: {
    errors: [],
    characterLimit: 100,
    lengthBreachKey: 'LINE2_LENGTH_BREACH',
    label: 'checkout.contact.address-line-2',
  },
  city: {
    errors: ['ERR_TAAS_ADDRESSES_CITY_NOT_DETERMINED_003'],
    characterLimit: 50,
    lengthBreachKey: 'CITY_LENGTH_BREACH',
    label: 'checkout.contact.city',
  },
  state: {
    errors: ['REGION_LENGTH_BREACH', 'ERR_TAAS_ADDRESSES_REGION_NOT_SUPPORTED_005'],
    label: 'checkout.contact.province',
  },
  postcode: {
    errors: [],
    characterLimit: 11,
    lengthBreachKey: 'POSTAL_CODE_LENGTH_BREACH',
    label: 'checkout.contact.postcode',
  },
};

export default fieldsMeta;
