/* eslint-disable react/jsx-no-target-blank */
// @flow
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getAddToCartEventData } from 'modules/change/table/duck/selectors/amount';
import { UISource } from 'modules/analytics/model/UIEvent';
import { emitUIEpicEvent, utils } from 'modules/analytics';
import type {
  AccountEntry,
  TableProvidedEvents,
  UpgradePageEvents,
} from 'modules/change/table/duck/model';
import ChangeOptionsTable from 'modules/change/table/ChangeOptionsTable';
import SearchBySenBar from 'modules/change/search/SearchBar';
import type { SearchOwnProps } from 'modules/change/search/duck/model';
import {
  CloudProductMessage,
  NoOptionsMessage,
} from 'modules/upgrade/components/NoUpgradeOptionsMessages';
import ChangeOptions from 'modules/upgrade/components/ChangeOptions';
import { datacenter } from 'modules/upgrade';
import config from 'appconfig';
import TierLabel from 'components/common/TierLabel';
import { Dimensions, Font } from 'components/visuals';
import type { Dispatch } from 'model/State';
import { isHosted } from 'model/hams/Pricing';
import t from 'modules/i18n/intl';
import UpgradeJiraCoreModal from './UpgradeJiraCoreModal';
import { ServerProductKeys } from '../../data/products/keys';

const UnitCount = styled.span`
  font-size: 16px;
`;

const Explanation = styled.div`
  font-family: ${Font.family};
  margin-bottom: ${Dimensions.units(4)}px;

  & > p {
    font-size: 16px;
  }
`;

const Header = styled.h2`
  font-family: ${Font.displayFamily};
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-bottom: ${Dimensions.units(4)}px;
`;

const JiraSoftwareDcTextWrapper = styled.div`
  text-align: right;
  color: black;
`;

const ExtraPriceInfo = ({ entry }: { entry: AccountEntry }) => {
  if (entry.productKey === ServerProductKeys.JIRA_CORE) {
    return (
      <JiraSoftwareDcTextWrapper>
        {t('upgrade.jira-core.to.jira-software-dc')}
      </JiraSoftwareDcTextWrapper>
    );
  }
  return null;
};

const getOptionLabel = (option) => (
  <TierLabel formatUnitCount={(count) => <UnitCount>{count}</UnitCount>} {...option} />
);
const getTermLabel = (term) => (
  <span>
    <FormattedMessage
      id="upgrade.month-subscription-term"
      values={{
        formattedCount: <UnitCount>{term.months}</UnitCount>,
        count: term.months,
      }}
    />
  </span>
);

const getNoOptionsMessage = (entry) =>
  isHosted(entry.productType) ? (
    <CloudProductMessage />
  ) : (
    <NoOptionsMessage options={entry.options} />
  );

type Props = TableProvidedEvents & SearchOwnProps & UpgradePageEvents;

const Heading = () => (
  <>
    <Header>{t('upgrade.datacenter.heading')}</Header>
    <Explanation>
      <p>{t('upgrade.datacenter.description')}</p>
      <p>
        <a href={`${config.wacUrl}/licensing/data-center`} target="_blank">
          {t('upgrade.datacenter.learnMoreLink')}
        </a>
        &nbsp; {t('upgrade.or')} &nbsp;
        <a href={`${config.wacUrl}/migration/cloud/explore`} target="_blank">
          {t('upgrade.datacenter.learnCompareCloudDCLink')}
        </a>
      </p>
    </Explanation>
  </>
);

const UpgradeDatacenterPage = ({
  onAddToCart,
  onFetchAccountChangeOptions,
  onSearchSubmit,
  onClearSearch,
  onFetchPaginatedAccounts,
}: Props) => (
  <>
    <Heading />

    <SearchBySenBar
      onSearchSubmit={onSearchSubmit}
      onClearSearch={onClearSearch}
      noSearchResultsTitle={t('upgrade.search.noResults.title')}
      noSearchResultsMessage={t('upgrade.search.noResults.body')}
      invalidSearchTitle={t('upgrade.search.invalidSearch.title')}
      invalidSearchMessage={t('upgrade.search.invalidSearch.body')}
    />

    <ChangeOptionsTable
      isRenewUpgrade
      onAddToCart={onAddToCart}
      onFetchAccountChangeOptions={onFetchAccountChangeOptions}
      onFetchPaginatedAccounts={onFetchPaginatedAccounts}
      isExpirationVisible={false}
      selectOptionHeader={t('upgrade.headers.cost-data-center')}
      userTierHeader={t('upgrade.headers.cost-renew-server')}
      getOptionLabel={getOptionLabel}
      getTermLabel={getTermLabel}
      getNoOptionsMessage={getNoOptionsMessage}
      getChangeSelector={(rowProps) => (
        <ChangeOptions
          {...rowProps}
          showPriceInfo
          extraPriceInfo={<ExtraPriceInfo {...rowProps} />}
        />
      )}
      showPriceSummary
      showServerRenewalPrice
    />

    <UpgradeJiraCoreModal />
  </>
);

const mapDispatchToProps = (dispatch: Dispatch): TableProvidedEvents => ({
  onSearchSubmit: () => dispatch(datacenter.actions.searchAccount()),
  onClearSearch: () => dispatch(datacenter.actions.resetAccount()),
  onFetchPaginatedAccounts: (params) => dispatch(datacenter.actions.fetchPaginatedAccounts(params)),
  onAddToCart: () => {
    dispatch(
      emitUIEpicEvent({
        data: utils.getButtonClickEventData('addToCart', UISource.Page),
        selectorsOrObjects: [
          getAddToCartEventData,
          {
            eventContainer: 'upgradePurchase',
            eventComponent: 'upgradeDcPurchase',
            parentLabel: 'addToCart',
            label: 'upgradeDcAddToCart',
            uuid: '9cac66e2-97b1-47f3-93ba-8322cfc87e8d',
          },
        ],
      }),
    );
    dispatch(datacenter.actions.addAccountUpgradeItemsToCart());
  },
  onFetchAccountChangeOptions: (entry: AccountEntry) =>
    dispatch(datacenter.actions.fetchAccountChangeOptions(entry.accountId, null, entry.productKey)),
});

export default connect(null, mapDispatchToProps)(UpgradeDatacenterPage);
