// @flow
import Progress from 'redux-progress';
import type { QuoteState } from './model';
import { SUBMIT } from './actions';
import { FETCH_CAN_COTERM } from './actions/fetchCanCoTerm';

const initialState = {
  submission: Progress.none,
  canCoTerm: Progress.none,
};

export default (state: QuoteState = initialState, action: any): QuoteState => {
  switch (action.type) {
    case SUBMIT:
      return { ...state, submission: action.progress };
    case FETCH_CAN_COTERM:
      return { ...state, canCoTerm: action.progress };
    default:
      return state;
  }
};
