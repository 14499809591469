import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { createSelector } from 'reselect';
import { State, Selector } from 'model/State';

const getEmails = (s: State) =>
  s.checkout.contacts.data.ifSuccess((d) =>
    compact([
      d.purchaserContactDetails.email,
      d.isDifferentBilling ? d.billingContactDetails.email : null,
      d.isDifferentTechnical ? d.technicalContactDetails.email : null,
    ]),
  ) || [];

const getEmailDomain = (email: string) => email.substr(email.indexOf('@'));

export default createSelector(
  (s: State) => s.checkout.contacts.data.ifSuccess((d) => d.resellerOrder),
  getEmails,
  (resellerOrder, emails) => !resellerOrder && uniq(emails.map(getEmailDomain)).length > 1,
) as Selector<boolean>;
