import { selectLatestFrom } from 'util/rxOperators';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { createScreenEvent } from 'modules/analytics';
import { State, CartEpic } from 'model/State';

export const pages: CartEpic = (action$, state$) =>
  action$.pipe(
    selectLatestFrom(state$),
    map((s: State) => s.location.type),
    distinctUntilChanged(),
    map((type) => createScreenEvent(type)),
  );

export default pages;
