import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { colors } from '@atlaskit/theme';

import { LoadingButton } from '@atlaskit/button';
import { FormFooter } from '@atlaskit/form';

import { ContactDetails } from 'model/hams/contact';

import t from 'modules/i18n/intl';
import { FormContext } from 'modules/form/Form';
import {
  analyticsEvents as siteconfiguratorAnalyticsEvents,
  AnalyticsTrigger as SiteconfiguratorAnalyticsTrigger,
} from 'modules/siteconfigurator/analytics';
import { analyticsEvents, AnalyticsTrigger } from 'modules/checkout/contacts/analytics';
import { ExtendedOrganisationDetails } from 'modules/auth/model';
import {
  selectCurrentContactDetails,
  selectCurrentContactOrganisationDetails,
} from 'modules/siteconfigurator/duck/selectors';
import { CartAnalyticsContext } from 'modules/analytics';
import type { State } from '../../../model/State';
import { RESTRICTED_COUNTRIES_ISO_CODES, RESTRICTED_EMAIL_DOMAINS } from 'modules/utils';
import { extractTLDFrom } from 'modules/siteconfigurator/utils';
import UFOLoadHold from '@atlassian/ufo-load-hold';

import {
  TrackAction,
  TrackActionSubject,
  TrackSource,
  TrackEventNames,
} from 'modules/analytics/model/TrackEvent';
import CartAnalyticsClient, { AnalyticsClient } from 'modules/analytics/CartAnalyticsClient';

const FooterArea = styled.div`
  border-top: 1px solid ${colors.N50};
  padding-top: 20px;
  text-align: right;
  min-width: 100%;
  min-width: -webkit-fill-available;
  min-width: fill-available;

  button {
    padding: 0 20px;
  }
`;

interface SubmitButtonProps {
  inSiteConfiguratorMode?: boolean;
  isSubmitLoading: boolean;
  setSubmitDisabled: Dispatch<SetStateAction<boolean>>;
  hasNewItems?: boolean;
  currrentUserContactDetails?: ContactDetails;
  currrentUserOrganisationDetails?: ExtendedOrganisationDetails;
  hasAllFreeRenewItems?: boolean;
}

const checkIfAtLeastOneISORestricted = (...isoCodes: Array<string>) =>
  isoCodes.some((iso) => RESTRICTED_COUNTRIES_ISO_CODES.includes(iso));

const checkIfAtLeastOneEmailRestricted = (...emails: Array<string>) =>
  emails.some((email) => RESTRICTED_EMAIL_DOMAINS.includes(extractTLDFrom(email)));

const SubmitButtonComponent: React.FC<SubmitButtonProps> = ({
  inSiteConfiguratorMode,
  isSubmitLoading,
  hasNewItems,
  currrentUserContactDetails,
  currrentUserOrganisationDetails,
  setSubmitDisabled,
  hasAllFreeRenewItems,
}: SubmitButtonProps) => {
  const analytics = useContext(CartAnalyticsContext);
  const { data } = useContext(FormContext);

  const billingOrganisationDetailsCountryIso = data.billingOrganisationDetails?.isoCountryCode;
  const technicalOrganisationDetailsCountryIso = data.technicalOrganisationDetails?.isoCountryCode;

  const billingContactDetailsEmail = data.billingContactDetails?.email;
  const technicalContactDetailsEmail = data.technicalContactDetails?.email;

  const currentUserOrganisationDetailsIso = currrentUserOrganisationDetails?.isoCountryCode ?? '';
  const currentUserOrganisationDetailsEmail = currrentUserContactDetails?.email ?? '';

  const isEmailORCountryISOCodesRestricted =
    checkIfAtLeastOneEmailRestricted(
      billingContactDetailsEmail,
      technicalContactDetailsEmail,
      currentUserOrganisationDetailsEmail,
    ) ||
    checkIfAtLeastOneISORestricted(
      billingOrganisationDetailsCountryIso,
      technicalOrganisationDetailsCountryIso,
      currentUserOrganisationDetailsIso,
    );

  const isSubmitDisabled =
    isEmailORCountryISOCodesRestricted && (hasNewItems || !hasAllFreeRenewItems);

  useEffect(() => {
    const client: AnalyticsClient = CartAnalyticsClient.getClient();

    client.sendTrackEvent({
      action: TrackAction.Changed,
      actionSubject: TrackActionSubject.CountryChangedContactForm,
      source: TrackSource.ContactDetailsScreen,
      attributes: {
        eventName: TrackEventNames.ContactDetailsUpdated,
        eventContainer: 'checkoutContactForm',
        eventComponent: 'checkoutContactForm',
        parentLabel: 'Contact details form',
        label: 'Country changed',
        uuid: 'a808686f-17ab-46aa-0544-d977ddc24e89',
        country: billingOrganisationDetailsCountryIso || technicalOrganisationDetailsCountryIso,
        isBlocked: isSubmitDisabled,
      },
    });
  }, [
    isSubmitDisabled,
    billingOrganisationDetailsCountryIso,
    technicalOrganisationDetailsCountryIso,
  ]);

  useEffect(() => {
    setSubmitDisabled(isSubmitDisabled);
  }, [isSubmitDisabled]);

  return (
    <FormFooter>
      <FooterArea>
        <UFOLoadHold name="submitting-contacts" hold={isSubmitLoading} />
        <LoadingButton
          type="submit"
          appearance="primary"
          isLoading={isSubmitLoading}
          isDisabled={isSubmitDisabled || isSubmitLoading}
          interactionName="checkout-contacts-submit"
          onClick={() =>
            inSiteConfiguratorMode
              ? siteconfiguratorAnalyticsEvents[
                  SiteconfiguratorAnalyticsTrigger.ContactDetailsContinue
                ](analytics)
              : analyticsEvents[AnalyticsTrigger.ContactDetailsContinue](analytics)
          }
        >
          {t('checkout.contact.continue')}
        </LoadingButton>
      </FooterArea>
    </FormFooter>
  );
};

const mapStateToProps = (state: State): any => ({
  currrentUserContactDetails: selectCurrentContactDetails(state),
  currrentUserOrganisationDetails: selectCurrentContactOrganisationDetails(state),
});

export const SubmitButton = connect(mapStateToProps)(SubmitButtonComponent);
