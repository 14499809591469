import React, { useEffect, useContext, useState } from 'react';
import Progress from 'redux-progress';
import { colors } from '@atlaskit/theme';
import { FormattedMessage } from 'react-intl';
import { Header } from './SiteConfigurator';
import ActionLine from 'modules/cart/ActionLine';
import { HamsCart, HamsCartItem } from 'modules/cart/duck/model';
import { OriginalItemDetails } from './duck/model';
import { AnnualBillingTable } from 'modules/siteconfigurator/table/AnnualBillingTable';
import AdminHubInfo from 'modules/siteconfigurator/AdminHubInfo';
import { PurchaseMode } from 'modules/settings/purchaseMode';
import { Dimensions, Font } from 'components/visuals';
import styled from 'styled-components';
import { getCloudParentItem, UpdateCartItemParams } from './utils';

import { CartAnalyticsContext } from 'modules/analytics';
import { analyticsEvents, AnalyticsTrigger } from './analytics';

export const SummaryInfo = styled.div`
  font-size: 12px;
  color: #6d778a;
`;

const StyledDiv = styled.div`
  font-family: ${Font.displayFamily};
  padding-top: ${Dimensions.units(1)}px;
`;

const BelowTableDiv = styled.div`
  margin: ${Dimensions.units(1)}px 0 ${Dimensions.units(1)}px;
  text-align: right;
  color: ${colors.N80};
`;

type Props = {
  cart: HamsCart | null;
  purchaseMode: PurchaseMode;
  onSubmit: () => void;
  onStartShopping: () => void;
  onTogglePurchaseMode: () => void;
  originalCartItemDetails: OriginalItemDetails;
  onChangeBillingPeriod: (billingPeriod: number) => void;
  setAnalyticsTrigger: (trigger: string) => void;
  inSiteConfiguratorMode: boolean;
  onUpdateCartItem: ({
    accountId,
    sku,
    unitCount,
    setIsPageLoading,
    setAnnualBillingCartError,
    setBiennialBillingCartError,
  }: UpdateCartItemParams) => void;
  selectedBillingPeriod: number;
  selectedAnalyticsTrigger: string;
  setIsPageLoading: (isPageLoading: Progress<boolean>) => void;
  setAnnualBillingCartError: (error: boolean) => void;
  setBiennialBillingCartError: (error: boolean) => void;
};

const AnnualBillingConfigurator = (props: Props) => {
  const { cart, selectedBillingPeriod } = props;
  const analytics = useContext(CartAnalyticsContext);
  const [selectedCartItem, setSelectedCartItem] = useState<HamsCartItem | null>(null);

  useEffect(() => {
    if (cart) {
      analyticsEvents[AnalyticsTrigger.ConfigurePage](analytics, { cart, selectedBillingPeriod });
    }
  }, []);

  useEffect(() => {
    if (analyticsEvents[props.selectedAnalyticsTrigger]) {
      analyticsEvents[props.selectedAnalyticsTrigger](analytics, {
        cart,
        cartItem: cart?.items?.find((item) => item.id === selectedCartItem?.id),
        selectedBillingPeriod,
      });
    }
  }, [cart]);

  if (!cart || !cart.items.length) {
    return null;
  }

  const updateCartItems = (cartItem: HamsCartItem, sku: string, unitCount: number) => {
    const { setIsPageLoading, setAnnualBillingCartError, setBiennialBillingCartError } = props;
    setSelectedCartItem(cartItem);
    props.onUpdateCartItem({
      accountId: cartItem.accountId || -1,
      sku,
      unitCount,
      setIsPageLoading,
      setAnnualBillingCartError,
      setBiennialBillingCartError,
    });
  };

  const accountName = getCloudParentItem(cart)?.accountName;

  return (
    <StyledDiv>
      <Header>
        <FormattedMessage id="annualbilling.billing-summary-header" />
      </Header>
      <AdminHubInfo accountName={accountName} />
      <SummaryInfo>
        <FormattedMessage id="annualbilling.billing-summary-info" />
      </SummaryInfo>
      <AnnualBillingTable {...props} onUpdateCartItem={updateCartItems} />
      <BelowTableDiv>
        <FormattedMessage id="annualbilling.taxes-included" />
      </BelowTableDiv>
      <div>
        <ActionLine {...props} cart={cart} />
      </div>
    </StyledDiv>
  );
};

export default AnnualBillingConfigurator;
