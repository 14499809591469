import Progress from 'redux-progress';
import find from 'lodash/find';
import { createSelector } from 'reselect';
import { CheckoutContactsData, EnhancedOrganisation } from 'modules/checkout/contacts/duck/model';
import * as taxRates from 'modules/data/taxRates/selectors';
import { OrganisationDetails } from 'model/hams/contact';
import { State, Selector } from 'model/State';

function makeOrganisationSelector(
  orgSelector: (arg0: CheckoutContactsData) => OrganisationDetails,
) {
  const getOrganisation = (s: State) => s.checkout.contacts.data.map(orgSelector);
  const getIsoCountryCode = (s: State) => getOrganisation(s).ifSuccess((c) => c.isoCountryCode);

  const getCountry = createSelector(
    getIsoCountryCode,
    (s: State) => s.data.countries.details,
    (s: State) => s.data.countries.all,
    (isoCountryCode, countryDetails, allCountries) =>
      isoCountryCode
        ? Progress.race(
            countryDetails[isoCountryCode] || Progress.none,
            allCountries.map((c) => find(c, { isoCountryCode })),
          )
        : Progress.success(null),
  );

  const getTaxRate = (s: State) =>
    taxRates.getTaxRate(s, {
      isoCountryCode: getIsoCountryCode(s) || '',
      stateKey: getOrganisation(s).ifSuccess((o) => o.state) || '',
    });

  return createSelector(getOrganisation, getCountry, getTaxRate, (_org, _country, taxRate) =>
    Progress.all(_org, _country).map(
      ([org, country]): EnhancedOrganisation => ({
        ...org,
        country,
        taxRate,
        showTaxId: taxRate.ifSuccess((t) => t.taxIdLabel !== 'Tax ID') || false,
        taxIdLabel:
          taxRate.ifSuccess((t) =>
            t.taxIdLabel === 'ABN' ? 'Australian Business Number (ABN)' : t.taxIdLabel,
          ) || '',
      }),
    ),
  );
}

export const getBillingOrganisation: Selector<
  Progress<EnhancedOrganisation>
> = makeOrganisationSelector((c) => c.billingOrganisationDetails);
export const getTechnicalOrganisation: Selector<
  Progress<EnhancedOrganisation>
> = makeOrganisationSelector((c) => c.technicalOrganisationDetails);
