// @flow
import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';

import { Section, Subheader } from 'modules/checkout/review/details/Commons';
import t from 'modules/i18n/intl';
import * as payment from 'modules/checkout/payment';

const CardDisclaimer = styled.p`
  color: ${colors.N50};
  font-size: 12px;
  line-height: 24px;
`;

const getLastDigits = (masked) => (masked && masked.substr ? masked.substr(12) : null);

const PaymentCreditCardDetails = () => {
  const paymentDetails = payment.api.storage.paymentDetails.load();
  if (!paymentDetails) {
    return null;
  }

  return (
    <Section>
      <Subheader>{t('review.credit-card')}</Subheader>
      <p>
        {paymentDetails.gatewayCardName}
        <br />
        {paymentDetails.gatewayCardScheme}
        &nbsp;
        {t('review.ending-in')}
        &nbsp;
        {getLastDigits(paymentDetails.gatewayCardNumberMasked)}
        <br />
        {t('review.expires')}
        &nbsp;
        {paymentDetails.gatewayCardExpiryDateMonth} / {paymentDetails.gatewayCardExpiryDateYear}
      </p>
      <CardDisclaimer>{t('review.credit-card-disclaimer')}</CardDisclaimer>
    </Section>
  );
};

export default PaymentCreditCardDetails;
