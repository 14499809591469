import { thunkProgress } from 'redux-progress';

import selectCartPayload from 'modules/cart/duck/selectors/cart';
import { fetchOrCreateCart } from 'modules/cart/duck/actions';
import { monitorStatus } from 'modules/checkout/review/duck/actions/monitorStatus';
import submitCreditCard from 'modules/checkout/review/duck/actions/submitCreditCard';
import submitFreeOrder from 'modules/checkout/review/duck/actions/submitFreeOrder';
import convertToQuote from 'modules/checkout/review/duck/actions/convertToQuote';
import submitOnAccount from 'modules/checkout/review/duck/actions/submitOnAccount';
import submitOnTerms from 'modules/checkout/review/duck/actions/submitOnTerms';
import submitWithPaypal from 'modules/checkout/review/duck/actions/submitWithPaypal';
import { selectPurchaseMode } from 'modules/settings/purchaseMode';
import * as payment from 'modules/checkout/payment';
import { CartThunk, Dispatch, GetState, State } from 'model/State';

import * as CheckoutTypes from './CheckoutTypes';

import { mightRequirePaymentMethodToBeRefilled, TNS_ERROR_MESSAGE } from './error-keys';
import { api } from 'modules/checkout/payment';
import { setInteractionError } from '@atlassian/ufo-set-interaction-error';

export const CHECKOUT = 'checkout/review/CHECKOUT';

export const PLACE_ORDER = 'checkout/review/PLACE_ORDER';

export const HamsOrderStatusCode = {
  CREDIT_CARD_DECLINED: 'CREDIT_CARD_DECLINED',
};

const checkoutModesToCheckoutTypes: {
  [S in keyof typeof payment.model.CheckoutModes]: CheckoutTypes.CheckoutType;
} = {
  [payment.model.CheckoutModes.STRIPE_CREDIT_CARD]: CheckoutTypes.STRIPE,
  [payment.model.CheckoutModes.CREDIT_CARD]: CheckoutTypes.CREDIT_CARD,
  [payment.model.CheckoutModes.PAY_ON_ACCOUNT]: CheckoutTypes.PAY_ON_ACCOUNT,
  [payment.model.CheckoutModes.PAY_ON_TERMS]: CheckoutTypes.PAY_ON_TERMS,
  [payment.model.CheckoutModes.PAYPAL]: CheckoutTypes.PAYPAL,
  [payment.model.CheckoutModes.BANK_TRANSFER]: CheckoutTypes.CART_CONVERT_TO_QUOTE,
  [payment.model.CheckoutModes.FREE]: CheckoutTypes.FREE,
};

type SubmitFunction = (
  cartId: string,
  checkoutType: CheckoutTypes.CheckoutType,
) => (dispatch: Dispatch, getState: GetState) => Promise<any>;

const checkoutTypeToHandler: {
  [S in CheckoutTypes.CheckoutType]: SubmitFunction;
} = {
  [CheckoutTypes.FREE]: submitFreeOrder,
  [CheckoutTypes.STRIPE]: submitCreditCard,
  [CheckoutTypes.CART_CONVERT_TO_QUOTE]: convertToQuote,
  [CheckoutTypes.CREDIT_CARD]: submitCreditCard,
  [CheckoutTypes.PAY_ON_ACCOUNT]: submitOnAccount,
  [CheckoutTypes.PAY_ON_TERMS]: submitOnTerms,
  [CheckoutTypes.PAYPAL]: submitWithPaypal as SubmitFunction,
};

const getCheckoutOperation = (state: State): CheckoutTypes.CheckoutType => {
  const cart = selectCartPayload(state).unwrap();
  const purchaseMode = selectPurchaseMode(state);
  const { checkoutMode } = state.checkout.payment;

  if (cart.totalIncTax === 0) {
    return CheckoutTypes.FREE;
  }

  if (cart.inEditMode || purchaseMode === 'QUOTE') {
    return CheckoutTypes.CART_CONVERT_TO_QUOTE;
  }

  if (!checkoutModesToCheckoutTypes[checkoutMode]) {
    throw new Error(`Unknown checkout mode ${checkoutMode}`);
  }

  return checkoutModesToCheckoutTypes[checkoutMode];
};

const enqueueOrder = (): CartThunk => async (dispatch, getState) => {
  const checkoutType = getCheckoutOperation(getState());
  const cart = selectCartPayload(getState()).unwrap();

  const processResult = await dispatch(
    checkoutTypeToHandler[checkoutType](cart.uuid, checkoutType) as any,
  );

  if (processResult) {
    if (processResult.failed) {
      let UFOEvent = '';
      switch (checkoutType) {
        case 'stripe':
          UFOEvent = 'cart-submit-stripe';
          break;
        case 'cart.convert.to.quote':
          UFOEvent = 'cart-submit-convert-to-quote';
          break;
        case 'pay.on.terms':
          UFOEvent = 'cart-submit-pay-on-terms';
          break;
        case 'pay.on.account':
          UFOEvent = 'cart-submit-pay-on-account';
          break;
        case 'paypal':
          UFOEvent = 'cart-submit-paypal';
          break;
        case 'free':
          UFOEvent = 'cart-submit-free';
          break;
        default:
          UFOEvent = 'unknown-payment-method';
          break;
      }
      setInteractionError(UFOEvent, {
        errorMessage: `${UFOEvent} error`,
        name: `${UFOEvent} error`,
      });

      if (mightRequirePaymentMethodToBeRefilled(processResult.error?.errorKey)) {
        // we will clear the payment details from storage as best effort if we get the tns error message
        if (processResult.error.errorDetail === TNS_ERROR_MESSAGE) {
          api.storage.paymentDetails.reset();
        }

        await dispatch(fetchOrCreateCart(cart.uuid));
        await dispatch(
          payment.actions.route({
            cardError: true,
            ccErrorMessage: processResult.error.errorDetail,
          }),
        );
      }

      return processResult;
    }

    processResult.unwrap();
  }

  return dispatch(monitorStatus(cart.uuid));
};

export const submit = () => (dispatch, getState) =>
  dispatch(
    thunkProgress(CHECKOUT, dispatch(enqueueOrder()), {
      checkoutType: getCheckoutOperation(getState()),
    }),
  );
