import { ExternalProductKeys } from 'data/products/keys';
import t from 'modules/i18n/intl';
import ExternalProduct from 'model/ExternalProduct';

export default new ExternalProduct({
  key: ExternalProductKeys.STATUSPAGE,
  tagLine: t('products.statuspage.tagline'),
  description: t('products.statuspage.description'),
  name: t('products.statuspage.name'),
  externalUrl: 'https://www.statuspage.io/pricing?utm_medium=purchase&utm_source=wac',
});
