import { thunkProgress } from 'redux-progress';

import type { Dispatch, GetState } from 'model/State';
import { GET_CUSTOMER_BY_EMAIL, IS_MEMBER_OF_GROUP, IS_EMAIL, EXTERNAL } from './types';
import { getCustomerByEmail, isMemberOfGroup, isEmail, external } from '../../api';
import { getCurrentContactEmail, getCurrentContactName } from 'modules/auth/selectors';
import { MACCurrentContactData } from '../../models';

export const fetchGetCustomerByEmail = (email: string, includeDeactivated: boolean = false) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const state = getState();

  const getCustomerByEmailData = getCustomerByEmail(email, includeDeactivated, state);
  dispatch(thunkProgress(GET_CUSTOMER_BY_EMAIL, getCustomerByEmailData, { email }));
};

export const fetchIsMemberOfGroup = (groupName: string) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const state = getState();

  const isMemberOfGroupData = isMemberOfGroup(groupName, state);
  dispatch(thunkProgress(IS_MEMBER_OF_GROUP, isMemberOfGroupData));
};

export const fetchIsEmail = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();

  const isEmailData = isEmail(state);
  dispatch(thunkProgress(IS_EMAIL, isEmailData));
};

export const fetchExternal = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const email = getCurrentContactEmail(state);
  const firstName = getCurrentContactName(state);

  if (!email || !firstName) return;

  const data: MACCurrentContactData = {
    contactDetails: {
      email,
      firstName,
    },
  };

  const externalData = external(state, data);
  dispatch(thunkProgress(EXTERNAL, externalData));
};
