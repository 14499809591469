import { combineReducers } from 'redux';
import annualBillingCart from './annualBillingCart';
import biennialBillingCart from './biennialBillingCart';
import selectedBillingPeriod from './selectedBillingPeriod';
import inSiteConfiguratorMode from './siteConfiguratorMode';
import originalCartItemDetails from './originalCartItemDetails';
import cartFormValidationError from './cartFormValidationError';
import analyticsTrigger from './analitycsTrigger';

export default combineReducers({
  selectedBillingPeriod,
  inSiteConfiguratorMode,
  annualBillingCart,
  biennialBillingCart,
  originalCartItemDetails,
  analyticsTrigger,
  cartFormValidationError,
});
