// @flow
import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';

import Product from 'model/Product';
import { Dimensions, Font } from 'components/visuals';
import ProductEntryList from 'components/products/ProductEntryList';
import t from 'modules/i18n/intl';

type Props = {
  name: string,
  products: Product[],
};

const CategoryHeader = styled.h2`
  color: ${colors.N800};
  font-size: 24px;
  margin-top: 30px !important;
  margin-bottom: ${Dimensions.units(4)}px;
  font-weight: 500;
  font-family: ${Font.displayFamily};
  letter-spacing: 0.3px;
`;

export default (props: Props) => (
  <div>
    <CategoryHeader>{t(`categories.${props.name}`)}</CategoryHeader>
    <ProductEntryList products={props.products} />
  </div>
);
