import * as React from 'react';
import { FC } from 'react';

import * as Sentry from '@sentry/browser';

import {
  MetalIntegration,
  GasV3Integration,
  SentryBrowserIntegration,
} from '@atlassian/commerce-ui/telemetry-integrations';

import { client as metalClient } from 'modules/telemetry';
import { CartAnalyticsClient } from 'modules/analytics';

const MetalIntegrationIfAvailable: FC = ({ children }) => {
  if (metalClient === null) {
    return <>{children}</>;
  }

  return <MetalIntegration client={metalClient}>{children}</MetalIntegration>;
};

export const CommerceSharedComponentIntegrations: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <GasV3Integration client={CartAnalyticsClient.getInternalClient()}>
      <MetalIntegrationIfAvailable>
        <SentryBrowserIntegration client={Sentry}>{children}</SentryBrowserIntegration>
      </MetalIntegrationIfAvailable>
    </GasV3Integration>
  );
};
