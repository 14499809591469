import { UIAction, UIActionSubject } from 'modules/analytics/model/UIEvent';

export enum AnalyticsTrigger {
  SaveQuoteSubmit = 'saveQuoteSubmit',
  ConfirmAndPay = 'confirmAndPay',
}

export const analyticsEvents = {
  [AnalyticsTrigger.SaveQuoteSubmit]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'reviewCartPageSaveQuoteSubmit',
      attributes: {
        eventContainer: 'reviewCartPage',
        eventComponent: UIActionSubject.Button,
        parentLabel: UIActionSubject.Review,
        label: 'Save a quote submit',
        uuid: '02e7f68b-1b8c-4df8-a4bf-e49086e26366',
      },
    });
  },
  [AnalyticsTrigger.ConfirmAndPay]: (
    analytics,
    { isQuoted, itemsQuantity, purchasingDurationDays },
  ) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'reviewCartPagePaymentConfirmAndPay',
      attributes: {
        eventContainer: 'reviewCartPage',
        eventComponent: UIActionSubject.Button,
        parentLabel: UIActionSubject.Review,
        label: 'confirmAndPay',
        uuid: 'a66df03d-57e7-4275-b91b-f783f1cd37eb',
        isQuoted,
        itemsQuantity,
        purchasingDurationDays,
      },
    });
  },
};
