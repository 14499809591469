import { thunkProgress } from 'redux-progress';
import { apply } from 'modules/promotion/duck/actions';
import * as api from 'modules/promotion/duck/api';
import { Dispatch, GetState } from 'model/State';

export const LOAD_PROMOTION = 'promotion/LOAD';

export default async (dispatch: Dispatch, getState: GetState) => {
  const fetchAndApply = async () => {
    const promoCode = getState().location.query?.promoCode;
    if (!promoCode) {
      throw new Error('promoCode not provided');
    }

    const promotion = await api.getPromotion(promoCode);
    if (!promotion) {
      throw new Error('promotion not found');
    }
    if (!promotion.available) {
      throw promotion;
    }

    await dispatch(apply(promotion));

    return promotion;
  };

  return dispatch(thunkProgress(LOAD_PROMOTION, fetchAndApply()));
};
