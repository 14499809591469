// @flow

import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import { supportedLanguages } from '../../../modules/i18n/duck/model';

type Props = {
  language: string,
  select: (string) => () => void,
};

const Label = styled.span`
  cursor: pointer;

  &:after {
    width: 0;
    height: 0;
    display: inline-block;
    content: '&darr;';
    text-indent: -99999px;
    vertical-align: middle;
    margin-top: 1px;
    margin-left: 3px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid ${colors.N800};
  }
`;

const VisualComponent = (props: Props) => (
  <DropdownMenu
    trigger={({ triggerRef, ...triggerProps }) => (
      <span {...triggerProps} ref={triggerRef}>
        <Label>Languages</Label>
      </span>
    )}
  >
    <DropdownItemGroup title="Language" style={{ height: '350px' }}>
      {supportedLanguages.map((lang) => (
        <DropdownItem
          isDisabled={props.language === lang.locale}
          onClick={props.select(lang)}
          key={lang.locale}
        >
          {lang.name}
        </DropdownItem>
      ))}
    </DropdownItemGroup>
  </DropdownMenu>
);

export default VisualComponent;
