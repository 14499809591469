import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useIntl } from 'react-intl'; // TODO: Replace react-intl with intl-next for hook usage
import type { Dispatch } from 'model/State';

import Header from 'components/navigation/top/Header';
import BottomMenu from 'components/navigation/bottom/BottomMenu';
import { MigratePage } from 'modules/migrate/MigratePage';
import PageWidthContainer from 'components/navigation/PageWidthContainer';
import SecondaryMenu from 'components/navigation/secondary/SecondaryMenu';
import PageSwitcher from 'components/PageSwitcher';
import { State } from 'model/State';
import WarningMessage from 'components/navigation/top/WarningMessage';
import MACWarningMessage from 'components/navigation/top/MACWarningMessage';
import { FormStateProvider } from 'modules/stripe';
import { Dimensions } from 'components/visuals';
import { WithQuoteMigrationHandle } from 'modules/checkout/payment/components/withQuoteMigrationHandle';
import { redirect } from 'redux-first-router';
import { goToProductList, PRODUCT_LIST } from 'actions';
import routesMap from 'routes';
import traceUFOPageLoad from '@atlassian/ufo-trace-pageload';
import traceUFOTransition from '@atlassian/ufo-trace-transition';

type Props = {
  location: string;
  showHeader: boolean;
  displayMACTopMenu: boolean;
  redirectToProductList: () => void;
  ufoName: string;
};

const shouldDisplayMACTopMenu = (state) => {
  const route = routesMap[state.location.type];
  const pageOptions = route ? route.pageOptions : null;
  return pageOptions ? pageOptions.isMACPage : false;
};

const DivForSpace = styled.div`
  height: ${Dimensions.units(8)}px;
`;

const AppSwitcher: React.FC<Props> = ({
  ufoName,
  location,
  showHeader,
  displayMACTopMenu,
  redirectToProductList,
}) => {
  const isInitial = useRef(true);
  const isProductListPage = location === PRODUCT_LIST;

  if (window.location.href.includes('/purchase/product/jira-core')) redirectToProductList();

  if (location === 'MIGRATE' || location === 'BUILDER') return <MigratePage />;

  const isRenewUpgrade =
    location.includes('renew') ||
    (location.includes('upgrade') && !location.includes('upgrade/datacenter'));

  const intl = useIntl();

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'document.title.general' });
  }, []);

  useEffect(() => {
    isInitial.current = false;
  }, []);

  useEffect(() => {
    if (isInitial.current) {
      traceUFOPageLoad(ufoName, ufoName);
    } else {
      traceUFOTransition(ufoName);
    }
  }, [ufoName]);

  const isRenewUpgradeView = isRenewUpgrade ? (
    <DivForSpace />
  ) : (
    <WarningMessage isProductListPage={isProductListPage} />
  );

  return (
    <>
      <PageWidthContainer dataTestId="page-content">
        {showHeader && (
          <>
            <Header isMACPage={displayMACTopMenu} />
            {displayMACTopMenu ? <MACWarningMessage /> : isRenewUpgradeView}
            <SecondaryMenu />
          </>
        )}
        {/* TODO: Review and fix missing attributes */}
        <FormStateProvider>
          <WithQuoteMigrationHandle>
            <PageSwitcher />
          </WithQuoteMigrationHandle>
        </FormStateProvider>
      </PageWidthContainer>
      {showHeader && <BottomMenu />}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  redirectToProductList: () => dispatch(redirect(goToProductList())),
});

export default connect(
  (state: State) => ({
    // TODO: Review RoutesMap type, maybe routes/index.js should be rewritten to typescript
    ufoName: state.location.routesMap[state.location.type].ufoName,
    location: state.location.type,
    showHeader: state.navigation.displayHeader,
    displayMACTopMenu: shouldDisplayMACTopMenu(state),
  }),
  mapDispatchToProps,
)(AppSwitcher);
