/* eslint-disable import/prefer-default-export */

import castArray from 'lodash/castArray';
import { Selector } from 'model/State';
import { ConfirmationData } from 'modules/checkout/complete/duck/model';

export const selectConfirmationData: Selector<ConfirmationData> = (state) => ({
  ordernumber: state.location.query.ordernumber,
  mode: state.location.query.mode === 'quote' ? 'quote' : 'order',
  reviewRequired: state.location.query.reviewRequired === 'true',
  singleAddon: state.location.query.singleAddon === 'true',
  checkoutMode: state.location.query.checkoutMode || 'cc',
  lasso: state.location.query.lasso === 'true',
  fromMAC: state.location.query.fromMAC === 'true',
  callback: state.location.query.callback || null,
  hasTraining: state.location.query.hasTraining === 'true',
  editedCartId: state.location.query.editedCartId,
  claimCloudId: castArray(state.location.query.claimCloudId).filter(Boolean),
});
