import React from 'react';

import { HamsCart, HamsCartEnrichedItem } from 'modules/cart/duck/model';
import LogoCell from 'modules/cart/summary/cells/LogoCell';

import TierCell from 'modules/cart/summary/cells/TierCell';
import TermCell from 'modules/cart/summary/cells/TermCell';
import PriceCell from 'modules/cart/summary/cells/PriceCell';
import ActionsCell from 'modules/cart/summary/cells/ActionsCell';

type Props = {
  cart: HamsCart;
  item: HamsCartEnrichedItem;
  deleting: boolean;
  onDelete: () => void;
  isChild: boolean;
  inSiteConfiguratorMode?: boolean;
};

const canDeleteItem = ({ cart, item }: Props) => {
  if (cart.isCoTermRequestSatisfied && !item.isCoTermedItem) {
    return false;
  }
  return !(cart.status === 'QUOTED' && !cart.inEditMode);
};

const TableLine = (props: Props) => (
  <tr>
    <LogoCell {...props} />
    <TierCell {...props} />
    <TermCell {...props} />
    <PriceCell {...props} />
    <ActionsCell
      {...props}
      deleteAvailable={!props.isChild && !props.inSiteConfiguratorMode && canDeleteItem(props)}
    />
  </tr>
);

export default TableLine;
