import * as React from 'react';
import { report } from 'modules/error-info';
import { ConnectedUuidErrorMessageVisual } from 'components/common/GenericErrorMessage';
import PageWidthContainer from 'components/navigation/PageWidthContainer';

type Props = {
  children: React.ReactNode;
};

type State = {
  error?: {
    error: Error;
    uuid: string;
  };
};

class ErrorBoundary extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { error: undefined };
  }

  componentDidCatch(rawError) {
    const error = report(rawError);
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      const { error, uuid } = this.state.error;
      return (
        <PageWidthContainer>
          <ConnectedUuidErrorMessageVisual error={error} uuid={uuid} />
        </PageWidthContainer>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
