// @flow
import React from 'react';
import LoadingButton from '@atlaskit/button/loading-button';
import { FormFooter } from '@atlaskit/form';
import { FooterArea } from 'modules/checkout/styled';
import type { QuotePageProps } from 'modules/checkout/duck/model';

export default (props: QuotePageProps) => (
  <FormFooter>
    <FooterArea>
      <LoadingButton type="submit" appearance="primary" isLoading={props.submission.inProgress}>
        Continue
      </LoadingButton>
    </FooterArea>
  </FormFooter>
);
