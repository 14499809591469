/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { colors } from '@atlaskit/theme';
import { Dimensions } from 'components/visuals';
import { getHamsUpgradeOptionIneligibilityMsg } from 'modules/change/table/duck/util';
import t from 'modules/i18n/intl';
import GenericErrorMessage from 'components/common/GenericErrorMessage';

import config from 'appconfig';

const NoOptionsHeader = styled.div`
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin-bottom: ${Dimensions.units(1)}px;
`;

const NoOptionsBody = styled.div`
  font-size: 12px;
  color: ${colors.N200};
`;

export const CloudProductMessage = () => (
  <>
    <NoOptionsHeader>{t('common.contact-us')}</NoOptionsHeader>
    <NoOptionsBody>
      <FormattedMessage
        id="upgrade.cloud-upgrade"
        values={{
          contactUs: (
            <a href={`${config.wacUrl}/migration/contact`} target="_blank">
              {t('common.contact-us')}
            </a>
          ),
        }}
      />
    </NoOptionsBody>
  </>
);

export const HighestTierMessage = () => (
  <>
    <NoOptionsHeader>{t('upgrade.cannot-be-upgraded')}</NoOptionsHeader>
    <NoOptionsBody>{t('upgrade.tiers.highest-tier')}</NoOptionsBody>
  </>
);

const ContactUs = () => (
  <a
    target="_blank"
    href="https://support.atlassian.com/contact/#/?inquiry_category=server_end_of_sale_support&support_type=customer"
  >
    {t('common.contact-us')}
  </a>
);

export const NoOptionsMessage = (props) =>
  props.options.fold({
    success: (result) => (
      <>
        <NoOptionsHeader>{t('upgrade.unable-to-migrate')}</NoOptionsHeader>
        <NoOptionsBody>
          <FormattedMessage
            id={getHamsUpgradeOptionIneligibilityMsg(result.ineligibilityReason || 'OTHERS')}
            values={{
              contactUs: <ContactUs />,
            }}
          />
        </NoOptionsBody>
      </>
    ),
    failed: (error) => <GenericErrorMessage error={error} />,
  });

export const ServerNoUpgradeMessage = () => (
  <>
    <NoOptionsHeader>{t('upgrade.upgrade-is-unavailable')}</NoOptionsHeader>
    <NoOptionsBody>
      <FormattedMessage
        id="upgrade.server-upgrade"
        values={{
          contactUs: <ContactUs />,
        }}
      />
    </NoOptionsBody>
  </>
);
