// @flow
import React from 'react';

import { Header, Subheader, Section, EditButton } from 'modules/checkout/review/details/Commons';
import t from 'modules/i18n/intl';
import type { HamsCart } from 'modules/cart/duck/model';

type Props = {
  cart: HamsCart,
  onPaymentDetailsEdit: () => void,
};

const PaymentAdditionalDetailsComponent = ({ cart, onPaymentDetailsEdit }: Props) => (
  <>
    <Section>
      <Header>
        {t('review.additional-details')}
        &nbsp;
        <EditButton onClick={onPaymentDetailsEdit} />
      </Header>
      {cart.hasCoTermRequest &&
        cart.hasTaxExemptRequest &&
        cart.isCoTermRequestSatisfied &&
        t('review.added.tax-exemption-and-coterm-satisfied')}
      {cart.hasCoTermRequest &&
        cart.hasTaxExemptRequest &&
        !cart.isCoTermRequestSatisfied &&
        t('review.added.tax-exemption-and-coterm-added')}
      {!cart.hasCoTermRequest && cart.hasTaxExemptRequest && t('review.added.tax-exemption')}
      {cart.hasCoTermRequest &&
        !cart.hasTaxExemptRequest &&
        cart.isCoTermRequestSatisfied &&
        t('review.added.coterm-satisfied')}
      {cart.hasCoTermRequest &&
        !cart.hasTaxExemptRequest &&
        !cart.isCoTermRequestSatisfied &&
        t('review.added.coterm-added')}
    </Section>
    <Section>
      {cart.purchaseOrderNumber ? (
        <div>
          <Subheader>{t('review.pon')}</Subheader>
          <p>{cart.purchaseOrderNumber}</p>
        </div>
      ) : (
        <div>
          <p>{t('review.pon-not-added')}</p>
        </div>
      )}
    </Section>
  </>
);

const PaymentAdditionalDetails = (props: Props) =>
  props.cart.purchaseOrderNumber ||
  props.cart.hasCoTermRequest ||
  props.cart.hasTaxExemptRequest ||
  props.cart.promotionalCode ? (
    <PaymentAdditionalDetailsComponent {...props} />
  ) : null;

export default PaymentAdditionalDetails;
