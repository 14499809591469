// @flow
import React from 'react';
import {
  CloudProductKeys,
  DataCenterProductKeys,
  ProductGroupKeys,
  ServerProductKeys,
} from 'data/products/keys';
import config from 'appconfig';
import { FormattedMessage } from 'react-intl';
import ServerProduct from 'model/editions/ServerProduct';
import SoftwareProductGroup from 'model/SoftwareProductGroup';
import DataCenterProduct from 'model/editions/DataCenterProduct';
import CloudProduct from 'model/editions/CloudProduct';
import { deploymentOptions, saml } from 'model/features';
import { connect } from 'react-redux';

type PropsType = {
  productName: string,
};

const FormattedNameMessage = ({ productName }: PropsType) => (
  <FormattedMessage id={`products.${productName}.name`} />
);
const MessageComponent = connect(() => ({
  productName: 'jira-servicemanagement',
}))(FormattedNameMessage);

export const jiraServiceDeskServer = new ServerProduct({
  key: ServerProductKeys.JIRA_SERVICE_DESK,
  groupKey: ProductGroupKeys.JIRA_SERVICE_DESK,
  tagLine: <FormattedMessage id="products.jira-servicedesk.tagline" />,
  description: <FormattedMessage id="products.jira-servicedesk.description" />,
  name: <MessageComponent />,
  recommended: [ServerProductKeys.CONFLUENCE],
  moreLink: `${config.wacUrl}/software/jira/service-desk`,
});

export const jiraServiceDeskServerWithPromo = new ServerProduct({
  key: ServerProductKeys.JIRA_SERVICE_DESK,
  groupKey: ProductGroupKeys.JIRA_SERVICE_DESK,
  tagLine: <FormattedMessage id="products.jira-servicedesk.tagline" />,
  description: <FormattedMessage id="products.jira-servicedesk.ondemand.promo.description" />,
  name: <MessageComponent />,
  recommended: [ServerProductKeys.CONFLUENCE],
  moreLink: `${config.wacUrl}/software/jira/service-desk`,
});

export const jiraServiceDeskDataCenter = new DataCenterProduct(
  DataCenterProductKeys.JIRA_SERVICE_DESK,
  jiraServiceDeskServer,
  [],
  [deploymentOptions, saml],
);

export const jiraServiceDeskCloud = new CloudProduct(
  CloudProductKeys.JIRA_SERVICE_DESK,
  jiraServiceDeskServer,
);

export const jiraServiceDeskProductGroup = new SoftwareProductGroup(
  jiraServiceDeskServer,
  jiraServiceDeskDataCenter,
  jiraServiceDeskCloud,
);
