/* eslint-disable import/prefer-default-export */
// @flow
import { redirect } from 'redux-first-router';
import * as actions from 'modules/upgrade/duck/tiers/actions';
import type { Dispatch, GetState } from 'model/State';
import watchProgress from 'model/watchProgress';

import * as auth from 'modules/auth';
import { resetChangeList } from 'modules/change/table/duck/actions';

export const load = async (dispatch: Dispatch, getState: GetState) => {
  dispatch(resetChangeList());

  const xsrfToken = await watchProgress(auth.selectors.selectXsrfToken);
  if (!xsrfToken) {
    auth.actions.goToLogin();
    return;
  }

  const accountId =
    getState().location.query.accountId || getState().location.query.partnerAccountId;
  if (accountId) {
    const account = await dispatch(actions.fetchAccount(accountId));
    account.ifSuccess((overview) => {
      if (overview.accounts.length === 0) {
        dispatch(redirect(actions.route()));
      }
    });
  } else {
    await dispatch(actions.fetchPaginatedAccounts());
  }
};
