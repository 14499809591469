// @flow
import { HIDE_HEADER } from 'actions/navigation/header';

export default function header(state = true, action: *) {
  switch (action.type) {
    case HIDE_HEADER:
      return action.payload;
    default:
      return state;
  }
}
