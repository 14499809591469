// @flow
import without from 'lodash/without';
import uniq from 'lodash/uniq';
import * as actions from 'modules/checkout/payment/duck/actions';
import type { AutoRenewItem } from 'model/hams/AutoRenewEligibility';

export default (state: number[] = [], action: *) => {
  switch (action.type) {
    case actions.FETCH_ELIGIBLE_AUTO_RENEWAL:
      return (
        action.progress.ifSuccess((r: AutoRenewItem[]) =>
          r.filter((i) => i.autoRenewalEnabled).map((i) => i.id),
        ) || []
      );
    case actions.SELECT_AUTO_RENEW_ITEMS:
      return action.selected ? uniq([...state, ...action.ids]) : without(state, ...action.ids);
    default:
      return state;
  }
};
