// @flow
import connectStateAndDispatch from 'util/react-redux/connectStateAndDispatch';
import React, { useCallback, useEffect } from 'react';
import { colors } from '@atlaskit/theme';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { actions, selectors } from 'modules/checkout/payment';
import type { PaymentPageProps } from 'modules/checkout/payment/duck/model/PaymentPageProps';
import { Header, Paragraph } from 'modules/checkout/styled';
import { TnsValidationError } from 'modules/checkout/payment/duck/model';
import AutoRenewal from 'modules/checkout/payment/components/AutoRenewal';
import * as CreditCard from 'modules/checkout/payment/components/creditCard';
import * as Cash from 'modules/checkout/payment/components/cash';
import * as Paypal from 'modules/checkout/payment/components/paypal';
import FreeOrder from 'modules/checkout/payment/components/FreeOrder';
import AdditionalDetails from 'modules/checkout/payment/components/AdditionalDetails';
import PaymentFooter from 'modules/checkout/payment/components/PaymentFooter';
import PaypalErrorMessage from 'modules/checkout/payment/components/paypal/PaypalErrorMessage';
import CardError from 'modules/checkout/payment/components/CardError';
import { useSubmitPaymentMethod } from 'modules/stripe-credit-card';

import * as api from 'modules/checkout/payment/duck/api';

import PageLoading from 'components/common/PageLoading';
import scrollToWhen from 'react-redux-scroll/scroll-to-when-hoc';
import GenericErrorMessage from 'components/common/GenericErrorMessage';
import { Dimensions, Font } from 'components/visuals';
import t from 'modules/i18n/intl';
import { CheckoutModes } from '../duck/model/PaymentState';

import { CartAnalyticsWrapper } from 'modules/analytics';
import { selectInSiteConfiguratorMode } from 'modules/siteconfigurator/duck/selectors';

const WrapperForm = styled.form`
  font-family: ${Font.family};
  width: ${Dimensions.pageWidth}px;
  margin: 36px auto 48px;
`;

const useStripeOnSubmit = ({ submit }: PaymentPageProps) => {
  const submitPaymentMethod = useSubmitPaymentMethod();

  const stripeButtonOnClickCallback = useCallback(
    async (e) => {
      e.preventDefault();
      if (submitPaymentMethod === null) {
        return;
      }
      const { payload } = await submitPaymentMethod();
      if (payload !== undefined) {
        submit();
      }
    },
    [submitPaymentMethod, submit],
  );

  return submitPaymentMethod !== null ? stripeButtonOnClickCallback : null;
};

const PaymentBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  border: 1px solid ${colors.N50};
  border-radius: 5px;
`;

const PaymentMethodMenu = styled.div`
  flex: 0.7;
`;

// Make it look like a hyperlink, but it's not (i.e. no href)
const Link = styled.a`
  cursor: pointer;
`;

export const ScrollWhenError = scrollToWhen((a) => a.type === actions.SUBMIT && a.progress.failed)(
  'div',
);
const PaymentDetails = styled(ScrollWhenError)`
  flex: 1;
  min-height: 350px;
  padding: ${Dimensions.units(4)}px;
  padding-left: ${Dimensions.units(4)}px;
  border-left: 1px solid ${colors.N50};
`;

const ContactSupport = () => (
  <a target="_blank" rel="noopener noreferrer" href="https://support.atlassian.com">
    {t('payment.support-team')}
  </a>
);

export const VisualPaymentPage = (props: PaymentPageProps) => {
  if (props.paypal.failed) {
    return props.inSiteConfiguratorMode ? (
      <GenericErrorMessage error={props.paypal.failed} />
    ) : (
      <PaypalErrorMessage />
    );
  }

  return (
    props.submission.fold({
      failed: (e) => !(e instanceof TnsValidationError) && <GenericErrorMessage error={e} />,
    }) || (
      <WrapperForm {...props} onSubmit={props.submit}>
        {props.cardError && <CardError errorMessage={props.ccErrorMessage} />}
        <Header>{t('payment.choose-payment-option')}</Header>
        <Paragraph>{t('payment.select-a-payment-option')}</Paragraph>
        {props.availablePaymentMethods.quote && (
          <Paragraph>
            <FormattedMessage
              id="payment.save-your-cart-as-quote"
              values={{
                saveCart: (
                  <Link role="link" onClick={() => props.switchToQuote()}>
                    {t('payment.save-your-cart')}
                  </Link>
                ),
              }}
            />
          </Paragraph>
        )}
        <Paragraph>
          <FormattedMessage id="payment.contact-support" values={{ support: <ContactSupport /> }} />
        </Paragraph>
        <PaymentBox>
          <PaymentMethodMenu>
            <Paypal.TabButton {...props} />
            <CreditCard.TabButton {...props} />
            <Cash.TabButton {...props} />
          </PaymentMethodMenu>
          <PaymentDetails>
            {props.availablePaymentMethods.free ? (
              <FreeOrder {...props} checkoutMode={CheckoutModes.FREE} />
            ) : (
              <>
                <CreditCard.TabContent {...props} />
                <Paypal.TabContent {...props} />
                <Cash.TabContent {...props} />
                <FreeOrder {...props} />
              </>
            )}
          </PaymentDetails>
          <AutoRenewal {...props.autoRenewal} currentContactEmail={props.currentContactEmail} />
        </PaymentBox>
        {props.showAdditionalDetails && <AdditionalDetails {...props.additionalDetails} />}
        <PaymentFooter {...props} />
      </WrapperForm>
    )
  );
};

const StripeVisualPaymentPage = (props: PaymentPageProps) => {
  const stripeOnSubmit = useStripeOnSubmit(props);
  return <VisualPaymentPage {...props} submit={stripeOnSubmit} />;
};

const NonStripeVisualPaymentPage = (props: PaymentPageProps) => {
  const submitCallback = useCallback((e) => {
    e.preventDefault();
    props.submit();
  });

  return <VisualPaymentPage {...props} submit={submitCallback} />;
};

const StripeVisualPaymentPageIfSelected = (props: PaymentPageProps) => {
  // TODO: Remove this once we fully support Stripe
  useEffect(() => {
    if (
      !props.availablePaymentMethods.stripe &&
      props.checkoutMode === CheckoutModes.STRIPE_CREDIT_CARD
    ) {
      api.storage.paymentDetails.reset();
      props.selectMode(CheckoutModes.CREDIT_CARD);
    } else if (
      !props.availablePaymentMethods.tns &&
      props.checkoutMode === CheckoutModes.CREDIT_CARD
    ) {
      api.storage.paymentDetails.reset();
      props.selectMode(CheckoutModes.STRIPE_CREDIT_CARD);
    }
  }, [
    props.selectMode,
    props.availablePaymentMethods.stripe,
    props.availablePaymentMethods.tns,
    props.checkoutMode,
  ]);

  const SelectedVisualPaymentPageComponent = props.stripeCreditCard.isSelected
    ? StripeVisualPaymentPage
    : NonStripeVisualPaymentPage;

  return <SelectedVisualPaymentPageComponent {...props} />;
};

const WebPaymentVisualPaymentPageIfSelected = (props: PaymentPageProps) => {
  useEffect(() => {
    if (
      props.creditCardOnly &&
      [
        CheckoutModes.PAY_ON_ACCOUNT,
        CheckoutModes.PAY_ON_TERMS,
        CheckoutModes.BANK_TRANSFER,
      ].includes(props.checkoutMode)
    ) {
      api.storage.paymentDetails.reset();
      props.selectMode(CheckoutModes.STRIPE_CREDIT_CARD);
    }
  }, []);

  return <StripeVisualPaymentPageIfSelected {...props} />;
};

const mapToProps = (state, dispatch) => ({
  progress: selectors.getPageProps(state, dispatch),
  inSiteConfiguratorMode: selectInSiteConfiguratorMode(state),
});
export default connectStateAndDispatch(mapToProps)(({ progress }) => (
  <PageLoading progress={progress}>
    {(result) =>
      result && (
        <CartAnalyticsWrapper>
          <WebPaymentVisualPaymentPageIfSelected {...result} />
        </CartAnalyticsWrapper>
      )
    }
  </PageLoading>
));
