/* eslint-disable class-methods-use-this */
import AnalyticsWebClient, { tenantType, userType } from '@atlassiansox/analytics-web-client';
import { OutputParams, parseUrl } from 'query-string';
import cookies from 'js-cookie';

import environment from '../../appconfig/environment';
import { utils } from 'modules/analytics';
import { isAnalyticsDisabled } from 'util/analytics';
import {
  TrackEvent,
  OperationalEvent,
  ScreenEvent,
  UIEvent,
  CommonFields,
  PRODUCT_NAME,
  AnalyticsEvent,
  PageAnalytics,
  ReferrerAnalytics,
} from 'modules/analytics/model';

export interface AnalyticsClient {
  sendUIEvent(e: UIEvent.CartUIEvent, onEventSent?: any): void; // onEventSent is an exposed onEventSent from '@atlassiansox/analytics-web-client'. Will be called when Event is sent.
  sendScreenEvent(e: ScreenEvent.CartScreenEvent, onEventSent?: any): void;
  sendTrackEvent(e: TrackEvent.CartTrackEvent, onEventSent?: any): void;
  sendOperationalEvent(e: OperationalEvent.CartOperationalEvent, onEventSent?: any): void;
}

export default class CartAnalyticsClient implements AnalyticsClient {
  private static client: CartAnalyticsClient;
  private analyticsClient: AnalyticsWebClient;
  private userId: string = '';

  private constructor() {
    const clientParamsObj: CommonFields = {
      env: utils.analyticsEnvMapper(environment),
      product: PRODUCT_NAME,
      version: process.env.REACT_APP_BUILD_NUMBER ? process.env.REACT_APP_BUILD_NUMBER : 'unknown',
      locale: 'en-US',
      tenantIdType: tenantType.NONE,
      userIdType: userType.ATLASSIAN_ACCOUNT,
    };
    this.analyticsClient = new AnalyticsWebClient(clientParamsObj);
  }

  /**
   * You should never need this unless you're intergrating directly with something that
   * expects the real analytics client.
   */
  public static getInternalClient() {
    return CartAnalyticsClient.getClient().analyticsClient;
  }

  public static getClient(): CartAnalyticsClient {
    if (!CartAnalyticsClient.client) {
      CartAnalyticsClient.client = new CartAnalyticsClient();
    }

    return CartAnalyticsClient.client;
  }

  public sendUIEvent = (e: UIEvent.CartUIEvent, onEventSent?: any) => {
    this.sendEvent(e, this.analyticsClient.sendUIEvent, onEventSent);
  };

  public sendScreenEvent = (e: ScreenEvent.CartScreenEvent, onEventSent?: any) => {
    this.sendEvent(e, this.analyticsClient.sendScreenEvent, onEventSent);
  };

  public sendTrackEvent = (e: TrackEvent.CartTrackEvent, onEventSent?: any) => {
    this.sendEvent(e, this.analyticsClient.sendTrackEvent, onEventSent);
  };

  public sendOperationalEvent = (e: OperationalEvent.CartOperationalEvent, onEventSent?: any) => {
    this.sendEvent(e, this.analyticsClient.sendOperationalEvent, onEventSent);
  };

  private get referrerAnalytics(): ReferrerAnalytics {
    if (document.referrer !== '') {
      const { url, query } = parseUrl(document.referrer);

      const referrerParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'].reduce(
        (params, key) => {
          const newParams = params;
          if (query[key]) {
            newParams[key] = query[key];
          }

          return newParams;
        },
        {} as OutputParams,
      );

      const referrerUrl = utils.sanitizeUrl(url);

      return {
        referrerParams,
        referrerUrl,
      };
    }

    return {
      referrerParams: null,
      referrerUrl: null,
    };
  }

  private static removeEmailFromPageUrl() {
    const url = new URL(window.location.href);
    const hasEmailQuery = url.searchParams.get('email');

    if (hasEmailQuery) {
      url.searchParams.delete('email');
    }

    return url.href;
  }

  private get pageAnalytics(): PageAnalytics {
    return {
      path: window.location.pathname,
      url: CartAnalyticsClient.removeEmailFromPageUrl(),
      languagePreference: window.navigator.language,
      languageMultiplePreferences: window.navigator.languages,
    };
  }

  private sendEvent(
    e: AnalyticsEvent,
    send: (data: any, onEventSent?: any) => void,
    onEventSent?: any,
  ) {
    if (isAnalyticsDisabled() || process.env.NODE_ENV === 'test') {
      // do not send analytics while running unit tests
      return;
    }

    const userId = cookies.get('__aid_user_id');
    if (userId && this.userId !== userId) {
      this.userId = userId;
      this.analyticsClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, this.userId);
    }
    const finalEventData = {
      ...e,
      attributes: {
        ...e.attributes,
        ...this.pageAnalytics,
        ...this.referrerAnalytics,
        loginUserDetected: cookies.get('login_user_detected') || 'false',
      },
    };

    send.call(this.analyticsClient, finalEventData, onEventSent);
  }
}
/* eslint-enable class-methods-use-this */
