// @flow
import { envTypes } from '@atlassiansox/metal-client';
import type { EnvironmentConfig } from 'model/EnvironmentConfig';

export default (): EnvironmentConfig => ({
  atlAdminUrl: 'https://admin.stg.atlassian.com',
  clicktaleEnabled: true,
  hamsUrl: 'https://wac.stg.internal.atlassian.com/hamlet',
  idLoginUrl: 'https://id.stg.internal.atlassian.com/login',
  launchdarklyId: '5760d13530f55306ec000133',
  logoutUrl: () =>
    `https://id.stg.internal.atlassian.com/logout?continue=${encodeURIComponent(
      document.location.href,
    )}`,
  macUrl: 'https://my.stg.internal.atlassian.com',
  marketplaceUrl: 'https://marketplace.stg.internal.atlassian.com',
  padresUrl: 'https://marketplace.stg.internal.atlassian.com/padres',
  paypal: {
    env: 'sandbox',
  },
  segmentKey: 'EWfeqJKJ0rPNOe3fcXrh2dg7bVXZNi2I',
  sentry: {
    debug: true,
    environment: 'stg',
  },
  tnsSessionUrl:
    'https://secure.na.tnspayments.com/form/version/34/merchant/TESTATLASSIAN02/session.js',
  wacUrl: 'https://wac.stg.internal.atlassian.com',
  i18nUrl: 'https://cart-static.us-east-1.staging.public.atl-paas.net/i18n',
  metal: {
    environment: envTypes.STAGING,
  },
  cloudMigrationToolsUrl: 'https://api-private.stg.atlassian.com/cloud-migration-tools',
  root: 'https://wac.stg.internal.atlassian.com/purchase',
  partnerPortal: 'https://partners.stg.internal.atlassian.com',
  intercomAppID: 'odnoznr4',
  adminHubUrl: 'https://admin.stg.atlassian.com',
  statsigClientSDK: 'd6e6637d-7fb6-42f3-adf2-b874adb88525',
  emailMAC: (userEmail) =>
    `https://id.stg.internal.atlassian.com/login?application=mac&continue=https://id.stg.internal.atlassian.com/manage-profile&prompt=none&login_hint=${userEmail}`,
  billingDetailsMAC: 'https://wac.stg.internal.atlassian.com/purchase/my/edit-details',
  switchUserMAC: 'https://wac.stg.internal.atlassian.com/purchase/my/config',
  emailPreferencesMAC: 'https://my.stg.internal.atlassian.com/email', // TODO: this will be update with this ticket: https://hello.jira.atlassian.cloud/browse/CHT-2683
  switchAccountMAC:
    'https://id.stg.internal.atlassian.com/login?application=mac&continue=https://wac.stg.internal.atlassian.com/purchase/my&prompt=select_account',
  logOutMAC:
    'https://id.stg.internal.atlassian.com/logout?application=mac&continue=https://wac.stg.internal.atlassian.com/purchase/my',
  calculatePricingURL: 'https://wac.stg.internal.atlassian.com/migration/assess/calculate-pricing',
});
