import React, { useEffect, useState } from 'react';
import { FormattedDate } from 'react-intl';
import styled from 'styled-components';
import { Dimensions } from 'components/visuals';

import ChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-up';
import { colors } from '@atlaskit/theme';
import EmojiFrequentIcon from '@atlaskit/icon/glyph/emoji/frequent';
import { MACTableType } from './TableContent';
import AmountWithCurrency from 'components/common/AmountWithCurrency';
import { fetchDetails } from '../duck/actions/accountId';
import { useDispatch, useSelector } from 'react-redux';
import { LicensesExpandedRow } from '../components/licensesExtendedRow';
import { selectDetails } from '../duck/selectors';
import { State } from 'model/State';

interface Props {
  rowItem: any;
  tableType: MACTableType;
  senNumber?: string;
  isCollapsedAll?: boolean;
  isArchived?: boolean;
  isTrial?: boolean;
}

const Cell = styled.td<{ width: number }>`
  font-size: 16px;
  align-self: center;
  width: ${({ width }) => width}%;
  padding-left: 0;

  && span {
    line-height: unset;
  }
`;

const CollapseSectionWrapper = styled.tr<{ isArchived?: boolean }>`
  margin-bottom: ${Dimensions.units(1)}px;
  height: ${Dimensions.units(4)}px;
  padding: ${Dimensions.units(0.5)}px ${Dimensions.units(0.5)}px;
  cursor: pointer;
  border-top: solid 2px ${colors.N30};
  ${({ isArchived }) => isArchived && `background-color: ${colors.N40};`}
`;

const ChildSection = styled.tr<{ isSectionVisible: boolean }>`
  visibility: ${(props) => (props.isSectionVisible ? 'visible' : 'collapse')};
  height: ${(props) => (props.isSectionVisible ? 'unset' : '0')};
  background-color: ${colors.N20};
  vertical-align: top !important;
`;

const licensesColumns = (
  showContent,
  { accountId, licenseId, expireDate, productDescription, accountName },
  isTrial,
) => {
  return [
    <Cell width={15} key="licenses-row-sen">
      {showContent ? <ChevronUpIcon label="" /> : <ChevronDownIcon label="" />}
      {isTrial && <EmojiFrequentIcon label="" />}
      SEN-{accountId || `L${licenseId}`}
    </Cell>,
    <Cell width={15} key="licenses-row-product">
      {productDescription}
    </Cell>,
    <Cell width={15} key="licenses-row-site-name">
      {accountName}
    </Cell>,
    <Cell width={15} key="licenses-row-support-expire">
      <FormattedDate value={new Date(expireDate)} year="numeric" month="short" day="2-digit" />
    </Cell>,
    <Cell width={15} key="licenses-row-support">
      SUPPORT LINK here
    </Cell>,
  ];
};

const ordersColumns = (showContent, { cartOrderDetails, orderNumber, status }) => {
  const createdDate = new Date(cartOrderDetails?.created);
  return [
    <Cell width={15} key="orders-row-sen">
      {showContent ? <ChevronUpIcon label="" /> : <ChevronDownIcon label="" />}
      {orderNumber}
    </Cell>,
    <Cell width={15} key="orders-row-created">
      <FormattedDate value={createdDate} year="numeric" month="short" day="2-digit" />
    </Cell>,
    <Cell width={15} key="orders-row-total">
      <AmountWithCurrency
        amount={cartOrderDetails?.totalIncTax}
        currency={cartOrderDetails?.currency || 'USD'}
      />
    </Cell>,
    <Cell width={15} key="orders-row-status">
      {status}
    </Cell>,
    <Cell width={15} key="orders-row-actions">
      Actions should added here
    </Cell>, // list of actions should depend on "tableType" - quote/invoice
  ];
};

export const CollapseRow: React.FC<Props> = ({
  rowItem,
  tableType,
  senNumber,
  isCollapsedAll,
  isArchived,
  isTrial,
}) => {
  const isSENPreselected = senNumber && rowItem.accountId === +senNumber;
  const [showContent, setShowContent] = useState<boolean>(isSENPreselected || false);
  const isLicensesTable = tableType === 'licenses';
  const dispatch = useDispatch();

  useEffect(() => {
    if (isCollapsedAll) {
      setShowContent(false);
    }
  }, [isCollapsedAll]);

  const onSectionToggle = () => {
    if (!showContent) {
      if (rowItem.parentAccountId) {
        dispatch(fetchDetails(rowItem.parentAccountId));
      } else {
        dispatch(fetchDetails(rowItem.accountId || rowItem.licenseId));
      }
    }

    setShowContent((prevState) => !prevState);
  };

  const tableColumns = isLicensesTable
    ? licensesColumns(showContent, rowItem, isTrial)
    : ordersColumns(showContent, rowItem);

  const accountDetails: any = useSelector((state) =>
    selectDetails(state as State, rowItem.accountId),
  );

  return (
    <>
      <CollapseSectionWrapper
        onClick={onSectionToggle}
        data-cy-mac-table-row
        isArchived={isArchived}
      >
        {tableColumns.map((row) => row)}
      </CollapseSectionWrapper>
      <ChildSection isSectionVisible={showContent}>
        {tableType === 'licenses' && (
          <LicensesExpandedRow productKey={rowItem.productKey} accountDetails={accountDetails} />
        )}
      </ChildSection>
    </>
  );
};
