import appconfig from 'appconfig';
import { IAuthenticationContext } from 'modules/migrate/Authenticated';
import { HamsAccountMigrationInfo } from 'modules/migrate/comparator/api';

export const fetchAccount = async ({
  authenticationContext,
  email,
  accountId,
}: {
  authenticationContext: IAuthenticationContext;
  email: string;
  accountId: string;
}): Promise<HamsAccountMigrationInfo | null> => {
  const response = await fetch(`${appconfig.hamsUrl}/1.0/public/account/migrateOptions`, {
    method: 'POST',
    body: JSON.stringify({ email, accountId }),
    headers: {
      'Content-Type': 'application/json',
      ...authenticationContext.getRequestHeaders(),
    },
    mode: 'cors',
    credentials: 'include',
  });

  if (response.status !== 200) {
    if (response.status === 404) {
      return null;
    }

    const error = new Error();
    try {
      Object.assign(error, await response.json());
    } catch (e) {
      error.message = e.message;
    }
    throw error;
  }

  return response.json();
};
