/* eslint-disable import/prefer-default-export */
// @flow
import errorReporter from 'util/errorReporter';
import { thunkProgress } from 'redux-progress';
import { CheckoutModes } from 'modules/checkout/payment/duck/model';
import * as selectors from 'modules/checkout/payment/duck/selectors';
import { TnsValidationError } from 'modules/checkout/payment/duck/model/TnsErrors';
import { updateTnsSessionFromForm } from 'modules/checkout/payment/duck/actions/updateTnsSessionFromForm';
import type { Dispatch, GetState } from 'model/State';
import { actions as cartActions } from 'modules/cart';
import * as steps from 'modules/checkout/steps';
import { goNext } from 'modules/checkout/duck/actions';

const updateTnsSessionFromFormIfNotStripe = (dispatch: Dispatch, getState: GetState) => {
  if (getState().checkout.payment.checkoutMode === CheckoutModes.STRIPE_CREDIT_CARD) {
    return undefined;
  }

  return dispatch(updateTnsSessionFromForm());
};

export const SUBMIT = 'checkout/payment/SUBMIT';
export const submit = () => (dispatch: Dispatch, getState: GetState) => {
  const cart = getState().cart.payload.unwrap();

  if (!cart) {
    errorReporter('No cart present while submiting payment');
    return Promise.reject();
  }

  const { payment } = getState().checkout;

  async function setCartDetails() {
    (
      await dispatch(
        cartActions.setCartDetails({
          ...cart,
          ...getState().checkout.additionalDetails,
          quotedPaymentMethod:
            payment.checkoutMode === CheckoutModes.BANK_TRANSFER ? 'BANK_TRANSFER' : 'NONE',
        }),
      )
    ).unwrap();
  }

  async function updateAutoRenewal() {
    if (selectors.getCanAutoRenew(getState())) {
      const items = selectors
        .getAutoRenewItems(getState())
        .unwrap()
        .map((i) => ({
          itemId: i.id,
          enabled: i.selected,
        }));
      if (items.length > 0) {
        (await dispatch(cartActions.setAutoRenewItems(items, cart.uuid))).unwrap();
      }
    }
  }

  return dispatch(
    thunkProgress(
      SUBMIT,
      (async () => {
        try {
          if (!cart.quoteNumber) {
            await setCartDetails();
            await Promise.all([dispatch(updateTnsSessionFromFormIfNotStripe), updateAutoRenewal()]);
          } else {
            await Promise.all([dispatch(updateTnsSessionFromFormIfNotStripe), updateAutoRenewal()]);
            dispatch(steps.markComplete('contact'));
          }

          dispatch(steps.markComplete('payment'));
          dispatch(goNext());
        } catch (e) {
          if (!(e instanceof TnsValidationError)) {
            errorReporter(e);
          }
          throw e;
        }
      })(),
    ),
  );
};
