import React from 'react';
import { connect } from 'react-redux';
import RedirectToProductList from './components/redirectToProductList';
import MACPageHeader from './components/macPageHeader';

const EditDetailsPage = () => (
  <>
    <RedirectToProductList />
    <MACPageHeader />
    MAC EditDetails PAGE
  </>
);

export const MACEditDetailsPage = connect(null, null)(EditDetailsPage);
