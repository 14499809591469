/* eslint-disable class-methods-use-this */

import * as React from 'react';
import { ProductType } from 'model/ProductType';
import { ProductGroupKey, ProductKey } from 'data/products/keys';
import { goProductConfig } from 'modules/configure/duck/actions/route';

export type ProductFeature = {
  description: React.ReactNode;
  weight: number;
  color?: string;
};

export type Props = {
  readonly key: ProductKey;
  tagLine: React.ReactNode;
  description: React.ReactNode;
  name: React.ReactNode;
  vendor?: string | null;
  externalUrl?: string | null;
  moreLink?: string | null;
  deploymentsNote?: React.ReactNode | null;
  features?: ProductFeature[];
  additionalTierLabel?: React.ReactNode | null;
  additionalTierLabelCell?: React.ReactNode | null;
};

export default class Product {
  key: ProductKey;
  tagLine: React.ReactNode;
  description: React.ReactNode;
  name: React.ReactNode;
  vendor: string | null;
  externalUrl: string | null;
  moreLink: string | null;
  deploymentsNote: React.ReactNode | null;
  features: ProductFeature[];
  additionalTierLabel: React.ReactNode | null;
  additionalTierLabelCell: React.ReactNode | null;

  constructor({
    key,
    tagLine,
    description,
    name,
    vendor = 'Atlassian',
    externalUrl,
    moreLink,
    deploymentsNote,
    features = [],
    additionalTierLabel,
    additionalTierLabelCell,
  }: Props) {
    this.key = key;
    this.tagLine = tagLine;
    this.description = description;
    this.name = name;
    this.vendor = vendor || null;
    this.externalUrl = externalUrl || null;
    this.moreLink = moreLink || null;
    this.deploymentsNote = deploymentsNote || null;
    this.features = features.sort((a, b) => a.weight - b.weight);
    this.additionalTierLabel = additionalTierLabel || null;
    this.additionalTierLabelCell = additionalTierLabelCell || null;
  }

  // eslint-disable-next-line class-methods-use-this
  get type(): ProductType {
    throw new Error('Not implemented');
  }

  getRecommendations(): string[] {
    return [];
  }

  getGroupKey(): ProductGroupKey | null {
    return null;
  }

  getLink(): Object | string | null {
    return this.externalUrl || goProductConfig(this.key);
  }
}
