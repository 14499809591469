import styled from 'styled-components';
import { Dimensions, Font as visuals } from 'components/visuals';
import AkSelect from '@atlaskit/select';

export const Section = styled.div`
  margin-bottom: ${Dimensions.units(5)}px;
  width: ${Dimensions.pageWidth / 2}px;
`;

export const CheckboxSubtext = styled.p`
  font-size: small;
  position: relative;
  left: ${Dimensions.units(4)}px;
  font-family: ${visuals.family};
  font-weight: 400;
  margin: 0;
`;

// PUR-17947
// AtlasKit <Select> styling is broken in Safari: your typing is outside the visible area.
// Try at https://atlaskit.atlassian.com/packages/core/select
// I (Henry Luk) observed this is due to auto-fill icon. As a workaround, we're removing that icon.
export const Select = styled(AkSelect)`
  input::-webkit-contacts-auto-fill-button {
    display: none !important;
  }
`;
