import { AxiosRequestConfig } from 'axios';
import t from 'modules/i18n/intl';
import { State } from 'model/State';
import hamsClient from 'modules/hams';
import { HamsCart } from 'modules/cart/duck/model';
import FormValidationError from 'model/hams/FormValidationError';
import { reportErrorOnly } from 'util/errorReporter';
import { isTaaSValidationTaxIdFFOn } from '../../../../util/featureFlags';
import { setInteractionError } from '@atlassian/ufo-set-interaction-error';

const getRequiredFieldsErrors = (cart: Partial<HamsCart>, getState: () => State) => {
  const reduxState = getState();

  const contactSectionsRequiredFields = ['email', 'firstName', 'lastName', 'phone'];

  const organisationSectionsRequiredFields = (
    isoCountryCode: string | undefined,
    taxId: string | undefined,
  ) => {
    const country = isoCountryCode
      ? reduxState.data.countries.details[isoCountryCode]?.result
      : undefined;

    return [
      'address1',
      'city',
      'isoCountryCode',
      'organisationName',
      country?.requiresPostcode && 'postcode',
      country?.requiresStates && 'state',
      isoCountryCode === 'CL' && taxId && 'vatResponsible',
    ].filter(Boolean);
  };

  const formFields = {
    purchaserContactDetails: contactSectionsRequiredFields,
    billingContactDetails: contactSectionsRequiredFields,
    technicalContactDetails: contactSectionsRequiredFields,
    billingOrganisationDetails: organisationSectionsRequiredFields(
      cart.billingOrganisationDetails?.isoCountryCode,
      cart.billingOrganisationDetails?.taxId,
    ),
    technicalOrganisationDetails: organisationSectionsRequiredFields(
      cart.technicalOrganisationDetails?.isoCountryCode,
      cart.technicalOrganisationDetails?.taxId,
    ),
  };

  const errorMessage = t('billing.address.validation.required');

  const validationErrors: Array<{ field: string; errorKey: string }> = [];

  Object.entries(formFields).forEach(([formSection, fields]) => {
    fields.forEach((field) => {
      const value = cart[formSection][field];

      if (!value && !(field === 'vatResponsible' && value === false)) {
        validationErrors.push({
          field: `${formSection}.${field}`,
          errorKey: errorMessage,
        });
      }
    });
  });

  return validationErrors;
};

const validateCartTaxIds = async (
  cart: Partial<HamsCart>,
  fieldKey: string,
  clientConfig: AxiosRequestConfig,
) => {
  const orgDetails = cart[fieldKey];

  if (orgDetails && orgDetails.taxId) {
    const { isoCountryCode: country, state, taxId } = orgDetails;
    const taxIds = [{ id: taxId, label: 'vatNumber' }];

    const res = await hamsClient.post(
      `/1.0/public/country/taas/validateTaxIds`,
      {
        country,
        state,
        taxIds,
      },
      clientConfig,
    );

    if (res.data.status === 'ERROR') {
      const taxIdValidationErrors = [];
      const validationResult = res.data.taxIdValidationResponse;

      const resultWithVatNumberError = validationResult.find(
        (err) => err.taxLabel === 'vatNumber' && !err.valid,
      );

      if (resultWithVatNumberError) {
        taxIdValidationErrors.push({
          field: `${fieldKey}.taxId`,
          errorKey: 'Invalid or badly formatted tax ID',
        });
      }

      return taxIdValidationErrors;
    }
    return null;
  }
  return null;
};

export default async (
  cart: Partial<HamsCart>,
  clientConfig: AxiosRequestConfig,
  getState: () => State,
) => {
  const state = getState();
  const isTaaSValidationTaxIdOn = isTaaSValidationTaxIdFFOn(state).result;

  const requiredFieldErrors = getRequiredFieldsErrors(cart, getState);

  if (requiredFieldErrors && requiredFieldErrors.length > 0) {
    // @ts-ignore
    throw new FormValidationError(requiredFieldErrors);
  }

  if (isTaaSValidationTaxIdOn) {
    const billingTaxIdValidationErrors = await validateCartTaxIds(
      cart,
      'billingOrganisationDetails',
      clientConfig,
    );

    if (billingTaxIdValidationErrors && billingTaxIdValidationErrors.length > 0) {
      // @ts-ignore
      throw new FormValidationError(billingTaxIdValidationErrors);
    }

    const technicalTaxIdValidationErrors = await validateCartTaxIds(
      cart,
      'technicalOrganisationDetails',
      clientConfig,
    );

    if (technicalTaxIdValidationErrors && technicalTaxIdValidationErrors.length > 0) {
      // @ts-ignore
      throw new FormValidationError(technicalTaxIdValidationErrors);
    }
  }

  try {
    const r = await hamsClient.post(
      `/1.0/public/order/cart/${cart.uuid}/setCartDetails`,
      cart,
      clientConfig,
    );
    return r.data;
  } catch (error) {
    setInteractionError('checkout-contacts-submit', {
      errorMessage: 'checkout contacts submit error',
      name: 'checkout contacts submit error',
    });
    if (error?.response?.status === 400 && error.i18nFieldErrors) {
      throw new FormValidationError(error.i18nFieldErrors);
    }

    // report to sentry
    reportErrorOnly(error);

    throw error;
  }
};
