/* eslint-disable import/prefer-default-export */
// @flow
import * as storage from 'modules/checkout/payment/duck/api/storage';
import * as actions from 'modules/checkout/payment/duck/actions';
import * as steps from 'modules/checkout/steps';
import type { Dispatch } from 'model/State';

export const removePayment = () => (dispatch: Dispatch) => {
  storage.paymentDetails.reset();
  storage.paypalDetails.reset();
  dispatch(steps.markComplete('payment', false));
  dispatch(actions.route());
};
