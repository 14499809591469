// @flow
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import type { AccountEntry, TableProvidedEvents } from 'modules/change/table/duck/model';
import TierLabel from 'components/common/TierLabel';
import { isHosted } from 'model/hams/Pricing';
import ChangeOptionsTable from 'modules/change/table/ChangeOptionsTable';
import SearchBySenBar from 'modules/change/search/SearchBar';
import type { SearchOwnProps } from 'modules/change/search/duck/model';
import {
  CloudProductMessage,
  HighestTierMessage,
  ServerNoUpgradeMessage,
} from 'modules/upgrade/components/NoUpgradeOptionsMessages';
import ChangeOptions from 'modules/upgrade/components/ChangeOptions';
import { tiers } from 'modules/upgrade';
import t from 'modules/i18n/intl';
import type { Dispatch } from 'model/State';
import { Font } from 'components/visuals';

import DataCenterApprovedAppsMessage from 'modules/renew/DataCenterApprovedAppsMessage';
import { isSSSDisabled } from 'modules/change/table/duck/util';

const Header = styled.h2`
  font-family: ${Font.displayFamily};
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-bottom: 0;
`;

const UnitCount = styled.span`
  font-size: 16px;
`;

const getOptionLabel = (option) => (
  <TierLabel formatUnitCount={(count) => <UnitCount>{count}</UnitCount>} {...option} />
);
const getTermLabel = (term) => (
  <span>
    <FormattedMessage
      id="upgrade.months"
      values={{
        formattedCount: <UnitCount>{term.months}</UnitCount>,
        count: term.months,
      }}
    />
  </span>
);
const getNoOptionsMessage = (entry) =>
  isHosted(entry.productType) ? <CloudProductMessage /> : <HighestTierMessage />;

const UpgradeTiersPage = (props: TableProvidedEvents & SearchOwnProps) => {
  const {
    onAddToCart,
    onFetchAccountChangeOptions,
    onFetchPaginatedAccounts,
    onSearchSubmit,
    onClearSearch,
  } = props;
  return (
    <>
      <Header>{t('upgrade.tiers.header')}</Header>
      <SearchBySenBar
        onSearchSubmit={onSearchSubmit}
        onClearSearch={onClearSearch}
        noSearchResultsTitle={t('upgrade.search.noResults.title')}
        noSearchResultsMessage={t('upgrade.search.noResults.body')}
        invalidSearchTitle={t('upgrade.search.invalidSearch.title')}
        invalidSearchMessage={t('upgrade.search.invalidSearch.body')}
      />
      <DataCenterApprovedAppsMessage />
      <ChangeOptionsTable
        isRenewUpgrade
        onAddToCart={onAddToCart}
        onFetchAccountChangeOptions={onFetchAccountChangeOptions}
        onFetchPaginatedAccounts={onFetchPaginatedAccounts}
        isExpirationVisible
        selectOptionHeader={t('upgrade.headers.user-tier')}
        userTierHeader={t('upgrade.headers.current-user-tier')}
        expirationHeader={t('upgrade.headers.current-expiration')}
        getOptionLabel={getOptionLabel}
        getTermLabel={getTermLabel}
        getNoOptionsMessage={getNoOptionsMessage}
        getSSMessage={() => <ServerNoUpgradeMessage />}
        isSSDisabled={(entry) => isSSSDisabled(entry)}
        getChangeSelector={(rowProps, options) => (
          <ChangeOptions
            {...rowProps}
            options={options}
            showLicenseDisclaimer={rowProps.entry.isSelected}
          />
        )}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): TableProvidedEvents => ({
  onSearchSubmit: () => dispatch(tiers.actions.searchAccount()),
  onClearSearch: () => dispatch(tiers.actions.resetAccount()),
  onAddToCart: () => dispatch(tiers.actions.addAccountUpgradeItemsToCart()),
  onFetchAccountChangeOptions: (entry: AccountEntry) => {
    dispatch(tiers.actions.fetchAccountChangeOptions(entry));
  },
  onFetchPaginatedAccounts: (params) => dispatch(tiers.actions.fetchPaginatedAccounts(params)),
});

export default connect(null, mapDispatchToProps)(UpgradeTiersPage);
