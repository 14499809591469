import reportError from 'util/errorReporter';
import { thunkProgress } from 'redux-progress';
import castArray from 'lodash/castArray';
import isEmpty from 'lodash/isEmpty';

import hamsRemoveItems from 'modules/cart/duck/api/removeItems';
import * as selectors from 'modules/cart/duck/selectors';
import { CartThunk } from 'model/State';
import { telemetryCounter, telemetryTimer, telemetryCounterFailure } from 'modules/telemetry/util';

export const CART_DELETE_ITEM = 'cart/items/REMOVE';

export const CART_DELETE_ITEM_ENQUEUE = 'cart/items/removeQueue/ENQUEUE';
export const CART_DELETE_ITEM_FLUSH = 'cart/items/removeQueue/FLUSH';

const doRemoveItem = async (dispatch, getState, itemIdOrArray: number | number[]) => {
  const itemIds = castArray(itemIdOrArray);
  const cart = selectors.selectCartPayload(getState()).result;

  if (!cart) {
    reportError('Removing items from a cart with no cart present in the state');
    return;
  }

  const cartId = cart.uuid;
  const { itemsBeingDeleted } = getState().cart;

  if (!isEmpty(itemsBeingDeleted)) {
    dispatch({
      type: CART_DELETE_ITEM_ENQUEUE,
      payload: { itemIds },
    });
  } else {
    await dispatch(
      thunkProgress(CART_DELETE_ITEM, hamsRemoveItems(cartId, itemIds), { payload: { itemIds } }),
    );
  }
};

export const removeItems = (itemIdOrArray: number | number[]): CartThunk => async (
  dispatch,
  getState,
) => {
  const ITEMS_DELETE = 'items.delete';
  try {
    const start = new Date().getTime();
    await doRemoveItem(dispatch, getState, itemIdOrArray);
    dispatch(telemetryCounter(ITEMS_DELETE));
    dispatch(telemetryTimer(ITEMS_DELETE, new Date().getTime() - start, '3000'));
  } catch (e) {
    dispatch(telemetryCounterFailure(ITEMS_DELETE, e));
    throw e;
  }
};

export const flushQueue = (): CartThunk => async (dispatch, getState) => {
  const queue = getState().cart.itemDeletionQueue;
  if (queue.length > 0) {
    dispatch({ type: CART_DELETE_ITEM_FLUSH });
    await dispatch(removeItems(queue));
  }
};
