import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { Font } from 'components/visuals';
import t from 'modules/i18n/intl';
import { HamsCart } from 'modules/cart/duck/model';

type Props = {
  cart: HamsCart;
};

const Header = styled.th`
  color: ${colors.N500};
  font-family: ${Font.displayFamily};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;
  vertical-align: baseline;
  letter-spacing: 1px;
  padding-left: 0;

  &:nth-last-child(2) {
    text-align: right;
  }
`;

const TableHeader = ({ cart }: Props) => (
  <thead>
    <tr>
      <Header>{t('cart.product')}</Header>
      <Header>{t('cart.user-tier')}</Header>
      <Header>{t('cart.maintenance-period')}</Header>
      <Header>{t('cart.price', { currency: cart.currency })}</Header>
      <Header />
    </tr>
  </thead>
);

export default TableHeader;
