import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormHeader } from '@atlaskit/form';
import { actions, selectors } from 'modules/checkout/contacts/duck';
import { CheckoutContactsProps } from 'modules/checkout/contacts/duck/model';
import ResellerWarningFlag from 'modules/checkout/contacts/ResellerWarningFlag';
import TechnicalContact from 'modules/checkout/contacts/TechnicalContact';
import TechnicalOrganisation from 'modules/checkout/contacts/TechnicalOrganisation';
import BillingContact from 'modules/checkout/contacts/BillingContact';
import PurchaserContact from 'modules/checkout/contacts/PurchaserContact';
import BillingOrganisation from 'modules/checkout/contacts/BillingOrganisation';
import SubmitErrorFlag from 'modules/checkout/contacts/SubmitErrorMessage';
import { actions as cartActions } from 'modules/cart';
import Form, { FormProps } from 'modules/form';
import PageLoading from 'components/common/PageLoading';
import { State } from 'model/State';
import { Font as visuals } from 'components/visuals';
import { scrollToWhen } from 'react-redux-scroll';

import { selectInSiteConfiguratorMode } from 'modules/siteconfigurator/duck/selectors';
import { CartAnalyticsWrapper } from 'modules/analytics';
import { SubmitButton } from './SubmitButton';

export type Props = FormProps &
  CheckoutContactsProps & {
    resetCart: () => void;
    inSiteConfiguratorMode?: boolean;
  };

const Wrapper = styled.div`
  position: relative;
  font-family: ${visuals.displayFamily};

  h2 {
    font-size: 24px;
  }
`;

export const ScrollableBanner = scrollToWhen(actions.SCROLL_TO_MESSAGE_BANNER)('div');

export const VisualCheckoutContactsForm = (props: Props) => {
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

  return (
    <Wrapper>
      <Form
        name="checkoutContact"
        data={props.data}
        validity={props.validity}
        edit={props.edit}
        onBlur={props.onBlur}
        onSubmit={!isSubmitDisabled ? props.onSubmit : () => {}}
      >
        <FormHeader>
          <ScrollableBanner>
            {props.showsResellerWarning && <ResellerWarningFlag {...props} />}
            <SubmitErrorFlag progress={props.submitProgress} resetCart={props.resetCart} />
          </ScrollableBanner>
        </FormHeader>

        <PurchaserContact {...props} />
        <BillingOrganisation {...props} />
        <BillingContact {...props} />
        <TechnicalContact {...props} />
        <TechnicalOrganisation {...props} />
        <SubmitButton
          setSubmitDisabled={setSubmitDisabled}
          inSiteConfiguratorMode={props.inSiteConfiguratorMode}
          isSubmitLoading={props.isSubmitLoading}
          hasNewItems={props.hasNewItems}
          hasAllFreeRenewItems={props.hasAllFreeRenewItems}
        />
      </Form>
    </Wrapper>
  );
};

const mapStateToProps = (state: State) => ({
  progress: selectors.getPageProps(state),
  inSiteConfiguratorMode: selectInSiteConfiguratorMode(state),
});

const mapDispatchToProps = (dispatch) => ({
  edit: (path: string, value: any) => {
    dispatch(actions.edit(path, value));
  },
  onBlur: (path: string) => dispatch(actions.blur(path)),
  onSubmit: () => dispatch(actions.submit()),
  resetCart: () => {
    dispatch(cartActions.resetCart());
    window.location.reload();
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ progress, ...props }: { progress: any & Props }) => (
  <PageLoading progress={progress}>
    {(results) => (
      <CartAnalyticsWrapper>
        <VisualCheckoutContactsForm {...props} {...results} />
      </CartAnalyticsWrapper>
    )}
  </PageLoading>
));
