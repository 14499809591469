import { createSelector } from 'reselect';
import { State } from 'model/State';

export default createSelector(
  (state: State) => state.cart.itemsBeingDeleted,
  (state) => state.cart.itemDeletionQueue,
  (itemsBeingDeleted, itemDeletionQueue) =>
    [...itemsBeingDeleted, ...itemDeletionQueue].reduce(
      (obj, item) => ({ ...obj, [item]: true }),
      {},
    ),
);
