// @flow
import { combineEpics } from 'redux-observable';

import * as change from 'modules/change';

import { timeAction, counter } from './util';

export default combineEpics(
  timeAction(change.landing.actions.CHECK_CROSSGRADE_AVAILABLE, 'upgrade.crossgrade', '3000'),
  counter(change.landing.actions.CHECK_CROSSGRADE_AVAILABLE, 'upgrade.crossgrade'),

  timeAction(change.table.actions.FETCH_ACCOUNT_CHANGE_OPTIONS, 'upgrade.changeoptions'),
  counter(change.table.actions.FETCH_ACCOUNT_CHANGE_OPTIONS, 'upgrade.changeoptions'),

  timeAction(change.table.actions.LOAD_CHANGE_LIST, 'upgrade.load.changeoptions', '7000'),
  counter(change.table.actions.LOAD_CHANGE_LIST, 'upgrade.load.changeoptions'),

  timeAction(change.table.actions.SUBMIT_CHANGE_LIST, 'upgrade.submit.changelist', '5000'),
  counter(change.table.actions.SUBMIT_CHANGE_LIST, 'upgrade.submit.changelist'),
);
