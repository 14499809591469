// @flow
import { ProductGroupKeys, ServerProductKeys } from 'data/products/keys';
import config from 'appconfig';
import t from 'modules/i18n/intl';
import ServerProduct from 'model/editions/ServerProduct';
import SoftwareProductGroup from 'model/SoftwareProductGroup';

export const crucibleServer = new ServerProduct({
  key: ServerProductKeys.CRUCIBLE,
  groupKey: ProductGroupKeys.CRUCIBLE,
  tagLine: t('products.crucible.tagline'),
  description: t('products.crucible.description'),
  name: t('products.crucible.name'),
  recommended: [ServerProductKeys.FISHEYE],
  moreLink: `${config.wacUrl}/software/crucible`,
});

export const crucibleProductGroup = new SoftwareProductGroup(crucibleServer);
