import { State } from 'model/State';

import { fetchWithParamValidation } from 'util/fetchWithParamValidation';
import { getFromHamsClient } from 'util/mac/api/account/get';
import { selectSwitchedUser } from '../selectors';

export const hamsBilledByEmail = (state: State) => {
  const contactEmail = selectSwitchedUser(state);
  return fetchWithParamValidation(
    ({ email }) => getFromHamsClient(`/overview/hamsBilledByEmail/${email}`, state),
    {
      email: contactEmail,
    },
  );
};

export const licenseByEmail = (state: State) => {
  const contactEmail = selectSwitchedUser(state);
  return fetchWithParamValidation(
    ({ email }) => getFromHamsClient(`/overview/licenseByEmail/${email}`, state),
    {
      email: contactEmail,
    },
  );
};

export const archivedAccountIds = (state: State) => {
  const contactEmail = selectSwitchedUser(state);

  return fetchWithParamValidation(
    ({ email }) => getFromHamsClient(`/archivedAccountIds/${email}`, state),
    {
      email: contactEmail,
    },
  );
};

export const archivedLicenseIds = (state: State) => {
  const contactEmail = selectSwitchedUser(state);

  return fetchWithParamValidation(
    ({ email }) => getFromHamsClient(`/archivedLicenseIds/${email}`, state),
    {
      email: contactEmail,
    },
  );
};
