import config from 'appconfig';

export default async () => {
  const result = await fetch(`${config.hamsUrl}/1.0/public/country/taas/all`);

  if (!result.ok) {
    throw Error('Unable to fetch countries');
  }
  return (await result.json()).countryList;
};
