import hamsClient from 'modules/hams';
import { thunkProgress } from 'redux-progress';
import { selectHamsClientConfig } from 'modules/auth/selectors';

type Dispatch = import('model/State').Dispatch;
type GetState = import('model/State').GetState;

export type ProcessOrderAfterPaymentAuthenticationInput = {
  uuid: string;
};

const STRIPE_AFTER_PAYMENT_AUTH = 'stripe/AFTER_PAYMENT_AUTHENTICATION';

const processOrderAfterPaymentAuthentication = ({
  uuid,
}: ProcessOrderAfterPaymentAuthenticationInput) => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const orderAfterPaymentAuth = async () => {
    const response = await hamsClient.post(
      `/1.0/public/order/cart/${uuid}/processOrderAfterPaymentAuthentication`,
      undefined,
      {
        ...selectHamsClientConfig(getState()),
        // All of these are considered expected responses
        validateStatus: (status) => status >= 200 || status < 300 || status === 400,
      },
    );

    return response;
  };

  const result = await dispatch(thunkProgress(STRIPE_AFTER_PAYMENT_AUTH, orderAfterPaymentAuth()));
  return result;
};

export default processOrderAfterPaymentAuthentication;
