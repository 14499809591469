import { TrackEvent, OperationalEvent, ScreenEvent, UIEvent } from 'modules/analytics/model';
import { PropsSelector } from 'modules/tracking/selectors/props';

import createScreenEvent from './screen';
import { UIEventData } from './ui';

export const UI_EVENT = 'analytics/UI_EVENT';
export const TRACK_EVENT = 'analytics/TRACK_EVENT';
export const OPERATIONAL_EVENT = 'analytics/OPERATIONAL_EVENT';
export const SCREEN_EVENT = 'analytics/SCREEN_EVENT';
export const UI_EPIC_ACTION = 'analytics/epic/ui';

export interface UIEpicActionPayload {
  data: UIEventData;
  selectorsOrObjects: (PropsSelector | Record<string, any>)[];
}
export interface UIEventEpicAction {
  type: typeof UI_EPIC_ACTION;
  payload: UIEpicActionPayload;
}

export const emitUIEpicEvent = (payload: UIEpicActionPayload): UIEventEpicAction => ({
  type: UI_EPIC_ACTION,
  payload,
});

export const emitOperationalEvent = (event: OperationalEvent.CartOperationalEvent) => ({
  type: OPERATIONAL_EVENT,
  payload: event,
});

export const emitTrackEvent = (event: TrackEvent.CartTrackEvent) => ({
  type: TRACK_EVENT,
  payload: event,
});

export const emitUIEvent = (event: UIEvent.CartUIEvent) => ({
  type: UI_EVENT,
  payload: event,
});

export const emitScreenEvent = (event: ScreenEvent.CartScreenEvent) => ({
  type: SCREEN_EVENT,
  payload: event,
});

export { createScreenEvent };
export * from './track';
export * from './ui';
