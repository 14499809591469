/* eslint-disable import/prefer-default-export */

import { StoredPromotion } from 'modules/promotion/duck/model';
import { CartThunk } from 'model/State';
import { HamsCart } from 'modules/cart/duck/model';
import { route as goAddon } from 'modules/addon/duck/actions/route';
import AtlStorage from 'api/AtlStorage';
import { actions as cartActions, api as cartApi } from 'modules/cart/duck';
import selectCartPayload from 'modules/cart/duck/selectors/cart';
import watchProgress from 'model/watchProgress';

const storage = new AtlStorage('marketplace.promotion', window.sessionStorage);

export const apply = (promotion: StoredPromotion): CartThunk => async (dispatch) => {
  const cart: HamsCart = await watchProgress(selectCartPayload);
  const updatedCart = await cartApi.addPromoToCart(cart.uuid, promotion.code);
  if (updatedCart.marketplacePromotion) {
    if (!updatedCart.marketplacePromotion.discountActive) {
      storage.store(promotion);
      dispatch(goAddon(promotion.products.join(','), promotion.code));
    } else {
      dispatch(cartActions.route());
    }
  }
};
