// @flow
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import styled from 'styled-components';
import { Field, ErrorMessage } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';

import CvvHelp from 'modules/checkout/payment/components/CvvHelp';
import type { CreditCardProps } from 'modules/checkout/payment/duck/model';
import t, { Intl } from 'modules/i18n/intl';
import MastercardLogo from 'modules/checkout/payment/components/img/logo-mastercard.svg';
import VisaLogo from 'modules/checkout/payment/components/img/logo-visa.svg';
import AmexLogo from 'modules/checkout/payment/components/img/logo-amex.svg';
import { Dimensions } from 'components/visuals';

type Props = CreditCardProps;

const ExpiryWrapper = styled.div`
  display: flex;

  > :first-child {
    margin-right: 5px;
  }
`;

const CardLogos = styled.div`
  position: relative;
  top: 8px;
  float: right;

  img {
    height: 12px;
    margin: 0 3px;
  }
`;

const CardNumberAndExpiry = styled.div`
  display: flex;

  > :first-child {
    margin-right: 15px;
  }
`;

const SecurityCodeWrapper = styled.div`
  width: max-content;
  position: relative;
  max-width: 100px;
`;

const FieldHelp = styled.span`
  position: absolute;
  top: ${Dimensions.units(2)}px;
  right: -${Dimensions.units(2)}px;
`;

// Setting width explicitly because TNS freaks out with Atlaskit's %-based widths
const TnsFieldText = ({ width, innerRef, isInvalid, ...props }: any) => (
  <Textfield
    {...props}
    type="text"
    isReadOnly
    style={{ minWidth: width, maxWidth: width }}
    isInvalid={isInvalid}
    ref={innerRef}
  />
);

const ExpiryDateFields = ({ expiryMonth, expiryYear, edit, ...props }: *) => (
  <ExpiryWrapper>
    <Textfield
      {...props}
      onBlur={() => {}} // A hack for Atlaskit's bug that removes validation status
      id="gatewayCardExpiryDateMonth"
      placeholder="MM"
      maxLength={2}
      type="Number"
      value={expiryMonth}
      onChange={(e) => edit({ expiryMonth: e.target.value })}
    />
    <Textfield
      {...props}
      onBlur={() => {}} // A hack for Atlaskit's bug that removes validation status
      id="gatewayCardExpiryDateYear"
      placeholder="YY"
      maxLength={2}
      type="Number"
      value={expiryYear}
      onChange={(e) => edit({ expiryYear: e.target.value })}
    />
  </ExpiryWrapper>
);

export default class TnsForm extends React.PureComponent<Props> {
  tnsFields = {
    cardNumber: React.createRef(),
    securityCode: React.createRef(),
  };

  componentDidUpdate = () => this.configureTns();
  componentDidMount = () => this.configureTns();

  updateTnsFocus = () => {
    Object.entries(this.tnsFields).forEach(([field, ref]: any) => {
      if (ref.current) {
        if (this.props.tnsFocus === field) {
          ref.current.onFocus();
        } else {
          ref.current.onBlur();
        }
      }
    });
  };

  async configureTns() {
    if (this.props.isSelected && this.props.configureProgress.isNone) {
      await this.props.configureTnsForm({
        cardNumber: '#gatewayCardNumber',
        securityCode: '#gatewayCardSecurityCode',
        expiryMonth: '#gatewayCardExpiryDateMonth',
        expiryYear: '#gatewayCardExpiryDateYear',
      });
    }

    this.updateTnsFocus();
  }

  render() {
    return (
      <Intl>
        {(translate) => (
          <>
            <CardNumberAndExpiry>
              <div>
                <CardLogos>
                  <img src={MastercardLogo} alt="Mastercard" />
                  <img src={VisaLogo} alt="Visa" />
                  <img src={AmexLogo} alt="American Express" />
                </CardLogos>
                <Field
                  name="card-number"
                  label={translate('payment.credit-card.card-number')}
                  isRequired
                  isInvalid={!this.props.validity.get('cardNumber').isValid}
                >
                  {({ fieldProps }) => (
                    <>
                      <TnsFieldText
                        {...fieldProps}
                        id="gatewayCardNumber"
                        width={Dimensions.units(32)}
                        innerRef={this.tnsFields.cardNumber}
                      />
                      {!this.props.validity.get('cardNumber').isValid && (
                        <ErrorMessage>
                          {this.props.validity.get('cardNumber').error.unsupportedScheme
                            ? t('payment.credit-card.unsupported-cc')
                            : t('payment.credit-card.invalid-cc')}
                        </ErrorMessage>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <Field
                name="expiry-date"
                label={translate('payment.credit-card.expiry-date')}
                isRequired
                onBlur={() => {}} // A hack for Atlaskit's bug that removes validation status
                isInvalid={!this.props.validity.get('expiry').isValid}
              >
                {({ fieldProps }) => (
                  <>
                    <ExpiryDateFields
                      {...fieldProps}
                      expiryMonth={this.props.expiryMonth}
                      expiryYear={this.props.expiryYear}
                      edit={this.props.editTnsForm}
                    />
                    {!this.props.validity.get('expiry').isValid && (
                      <ErrorMessage>{t('payment.credit-card.card-expiration')}</ErrorMessage>
                    )}
                  </>
                )}
              </Field>
            </CardNumberAndExpiry>

            <Field
              name="name-on-card"
              label={translate('payment.credit-card.name-on-card')}
              isRequired
              value={this.props.cardName}
              onChange={(e) => this.props.editTnsForm({ cardName: e.target.value })}
              isInvalid={!this.props.validity.get('cardName').isValid}
            >
              {/* id for PDV */}
              {({ fieldProps }) => (
                <>
                  <Textfield {...fieldProps} id="gatewayCardName" />
                  {!this.props.validity.get('cardName').isValid && (
                    <ErrorMessage>{t('payment.credit-card.cardholder-name')}</ErrorMessage>
                  )}
                </>
              )}
            </Field>
            <SecurityCodeWrapper>
              <FieldHelp>
                <CvvHelp />
              </FieldHelp>
              <Field
                name="code"
                label={translate('payment.credit-card.security-code')}
                isRequired
                isInvalid={!this.props.validity.get('securityCode').isValid}
              >
                {({ fieldProps }) => (
                  <>
                    <TnsFieldText
                      {...fieldProps}
                      id="gatewayCardSecurityCode"
                      width={Dimensions.units(8)}
                      innerRef={this.tnsFields.securityCode}
                    />
                    {!this.props.validity.get('securityCode').isValid && (
                      <ErrorMessage>{t('payment.credit-card.enter-security-code')}</ErrorMessage>
                    )}
                  </>
                )}
              </Field>
            </SecurityCodeWrapper>
          </>
        )}
      </Intl>
    );
  }
}
