import Progress from 'redux-progress';
import { AddToCartState } from './model';
import { AddItemsAction, ADD_ITEMS } from './actions';

export const initial = {
  progress: Progress.none,
};

export default (state: AddToCartState = initial, action: AddItemsAction): AddToCartState => {
  switch (action.type) {
    case ADD_ITEMS:
      return {
        ...state,
        progress: action.progress,
      };
    default:
      return state;
  }
};
