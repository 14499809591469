// @flow
import React from 'react';
import styled from 'styled-components';
import ProductLogo from 'components/common/ProductLogo';
import { Dimensions, Font } from 'components/visuals';

type Props = {
  productKey: string,
  productName: string,
  showTagline?: boolean,
  tagLine?: string | null,
};

const Wrapper = styled.header`
  margin-top: ${Dimensions.units(3)}px;
  margin-bottom: ${Dimensions.units(1)}px;
`;

const TagLine = styled.h2`
  font-size: 16px;
  display: inline-block;
  margin-left: ${Dimensions.units(1)}px;
  padding: 0;
  transform: translateY(-50%);
  margin-top: 0;
  vertical-align: bottom;
  font-family: ${Font.family};
  font-weight: 400;
`;

const Header = ({ productKey, productName, tagLine, showTagline }: Props) => (
  <Wrapper>
    <ProductLogo product={productKey} alt={productName} />
    {showTagline && tagLine ? <TagLine>{tagLine}</TagLine> : null}
  </Wrapper>
);

Header.defaultProps = {
  showTagline: false,
  tagLine: null,
};

export default Header;
