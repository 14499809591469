import Progress from 'redux-progress';
import { HamsCart, HamsCartItem } from 'modules/cart/duck/model';
import { CloudProductKey, ExtraProductKeys, Supported20KCloudProducts } from 'data/products/keys';
import { HamsPricingResponse } from 'model/hams/HamsPricingResponse';
import { OrderableItem } from 'model/hams/Pricing';
import { PREMIUM_EDITION, STANDARD_EDITION } from 'util/constants';
import config from 'appconfig';

export const getEdition = (cartItem: HamsCartItem) => cartItem.productDetails.edition;
export const isPremiumEdition = (cartItem: HamsCartItem) =>
  getEdition(cartItem).toLowerCase() === PREMIUM_EDITION;
export const isStandardEdition = (cartItem: HamsCartItem) =>
  getEdition(cartItem).toLowerCase() === STANDARD_EDITION;

export const isAppItem = (item) =>
  item.productDetails.atlassianAddon || item.productDetails.marketplaceAddon;
export const getCartItems = (cart: HamsCart) =>
  cart.items
    .filter((item) => item.productDetails.productKey !== ExtraProductKeys.ATLASSIAN_CLOUD)
    .sort((a, b) => {
      if (a && a.accountId && b && b.accountId) {
        return a.accountId - b.accountId;
      }
      return 0;
    });
export const getCloudParentItem = (cart: HamsCart) =>
  cart.items.find((item) => item.productDetails.productKey === 'atlassian.cloud');
export const getCartAppItems = (cart: HamsCart) => getCartItems(cart).filter(isAppItem);
export const getCartProductItems = (cart: HamsCart) =>
  getCartItems(cart).filter((item) => !isAppItem(item));

export const getPricingOptions = (
  pricingOptions: HamsPricingResponse | null,
  cartItem: HamsCartItem,
) =>
  pricingOptions?.products?.find(
    (pricingOption) => pricingOption.productKey === cartItem.productDetails.productKey,
  )?.orderableItems || [];
export const getEditionTierMapping = (editionOptions: OrderableItem[]) => {
  const editionsAndTiersPerRow: { [editionName: string]: OrderableItem[] } = {};
  editionOptions.forEach((option: OrderableItem) => {
    if (!option.edition) {
      return;
    }
    if (editionsAndTiersPerRow[option.edition]) {
      editionsAndTiersPerRow[option.edition].push(option);
    } else {
      editionsAndTiersPerRow[option.edition] = [option];
    }
  });
  return editionsAndTiersPerRow;
};

type AnnualBillingMessage = {
  notify: boolean;
  checkoutMode?: string;
  isError?: boolean;
  purchaseMode?: string;
  reviewRequired?: boolean;
  cart?: HamsCart;
};

export type UpdateCartItemParams = {
  accountId: number;
  sku: string;
  unitCount: number;
  setIsPageLoading: (progress: Progress<boolean>) => void;
  setAnnualBillingCartError: (boolean) => void;
  setBiennialBillingCartError: (boolean) => void;
};

export const sendAnnualBillingMessageToBux = (message: AnnualBillingMessage) => {
  window.parent.postMessage(message, config.atlAdminUrl);
};

export const getProductListPrice = (
  editionTierMapping: { [editionName: string]: OrderableItem[] },
  selectedEdition: string,
  selectedTier: number,
) =>
  editionTierMapping[selectedEdition]?.find((item) => item.unitCount === selectedTier)?.amount || 0;
export const getAppListPrice = (appTierMapping, cartItem: HamsCartItem) =>
  appTierMapping[cartItem.id].find(
    (item) =>
      item.unitCount === cartItem.productDetails.unitCount && item.renewalFrequency === 'ANNUAL',
  )?.amount || 0;

export const getItemsByAccountIDFromBillingCarts = (
  accountId: number | undefined,
  annualBillingCart: HamsCart | null,
  biennialBillingCart: HamsCart | null,
) => ({
  annualBillingItem:
    (accountId &&
      annualBillingCart &&
      annualBillingCart.items.find((item) => item.accountId === accountId)?.id) ||
    0,
  biennialBillingItem:
    (accountId &&
      biennialBillingCart &&
      biennialBillingCart.items.find((item) => item.accountId === accountId)?.id) ||
    0,
});

export const getItemMaintenanceStartDate = (cartItem: HamsCartItem | undefined): Date | undefined =>
  cartItem?.maintenanceStartDate;
export const getItemMaintenanceEndDate = (cartItem: HamsCartItem | undefined): Date | undefined =>
  cartItem?.maintenanceEndDate;

export const is20kCloudProduct = (productKey: CloudProductKey) =>
  Supported20KCloudProducts.includes(productKey);

export const extractTLDFrom = (email: string) =>
  !(typeof email === 'string') ? '' : email.split('.').pop() || '';
