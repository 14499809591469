import Progress from 'redux-progress';
import { createSelector } from 'reselect';

import { QuoteMigrationStatuses } from 'util/QuoteMigrationStatuses';

import { selectCart } from './cart';
import { HamsCart } from '../model';

const isNotUndefined = (...values: Array<unknown>) => values.every((value) => value !== undefined);

export type QuoteMigrationState = Pick<
  HamsCart,
  'ccpQuoteTxaId' | 'ccpQuoteUuid' | 'ccpQuoteMigrationStatus'
>;

const defaultQuoteMigrationState: QuoteMigrationState = {
  ccpQuoteMigrationStatus: QuoteMigrationStatuses.IN_PROGRESS,
  ccpQuoteTxaId: null,
  ccpQuoteUuid: null,
};

type QuoteMigrationSelector = (
  cartProgress: Progress<HamsCart | null>,
) => QuoteMigrationState | null;

const selectQuoteMigration: QuoteMigrationSelector = (cartProgress) =>
  cartProgress.ifSuccess((cart) => {
    if (cart) {
      const { ccpQuoteId, ccpQuoteMigrationStatus, ccpQuoteTxaId, ccpQuoteUuid } = cart;

      if (isNotUndefined(ccpQuoteId, ccpQuoteMigrationStatus, ccpQuoteTxaId, ccpQuoteUuid)) {
        return {
          ...defaultQuoteMigrationState,
          ccpQuoteId,
          ccpQuoteMigrationStatus,
          ccpQuoteTxaId,
          ccpQuoteUuid,
        };
      }
    }

    return defaultQuoteMigrationState;
  });

const selectQuoteMigrationData = createSelector(selectCart, selectQuoteMigration);

export default selectQuoteMigrationData;
