// @flow
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Button from '@atlaskit/button/standard-button';
import { CartAnalyticsContext } from 'modules/analytics';
import { UIAction, UIActionSubject } from 'modules/analytics/model/UIEvent';
import { getCurrentContactEmail } from 'modules/auth/selectors/contactDetails';
import { StepList } from 'modules/configure/common/Steps';
import DeploymentStep from 'modules/configure/deployment/DeploymentStep';
import Header from 'modules/configure/common/Header';
import ProductTitle from 'modules/configure/common/ProductTitle';
import { selectors } from 'modules/configure';
import { Dimensions, Font } from 'components/visuals';
import type { State } from 'model/State';
import macLoginURITransformer from 'util/macLoginURITransformer';
import t from 'modules/i18n/intl';
import config from 'appconfig';

type Props = {
  productKey: string,
  currentContactEmail: string | null,
};

const CloudWrapper = styled.div`
  font-family: ${Font.family};
  padding: ${Dimensions.units(2)}px;
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  margin: 12px 0 0 0;
`;

const CloudProductConfiguration = (props: Props) => {
  const analytics = useContext(CartAnalyticsContext);

  const onGetStartedClick = () => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'productConfigurationGetStarted',
      attributes: {
        eventContainer: 'productConfigurationPage',
        eventComponent: UIActionSubject.Button,
        parentLabel: UIActionSubject.ProductConfiguration,
        label: 'Get Started',
        uuid: '25d8eae5-664a-4cd9-ab24-f02addc419a0',
      },
    });
  };

  const macLink = macLoginURITransformer({
    url: `${config.macUrl}/addon/tryondemand/${props.productKey}`,
    currentContactEmail: props.currentContactEmail,
  });

  return (
    <StepList>
      <Header />
      <DeploymentStep />
      <CloudWrapper>
        <p>
          <FormattedMessage
            id="cloud-getting-started"
            values={{ product: <ProductTitle productKey={props.productKey} /> }}
          />
        </p>

        <ButtonWrapper>
          <Button
            href={`${config.wacUrl}/ondemand/signup/form?product=${props.productKey}`}
            appearance="primary"
            onClick={onGetStartedClick}
          >
            {t('configure.get-started')}
          </Button>
        </ButtonWrapper>
        <p>
          {t('common.already-using-atlassian-cloud')}&nbsp;
          <a href={macLink}>
            <FormattedMessage
              id="common.add-product-to-existing-site"
              values={{ product: <ProductTitle productKey={props.productKey} /> }}
            />
            &nbsp;&gt;&gt;
          </a>
        </p>
      </CloudWrapper>
    </StepList>
  );
};

const mapStateToProps = (state: State) => ({
  productKey: selectors.selectMainProductKey(state),
  currentContactEmail: getCurrentContactEmail(state),
});

export default connect(mapStateToProps)(CloudProductConfiguration);
