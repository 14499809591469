import { SET_ANNUAL_CART, SET_UPDATED_ANNUAL_CART } from '../actions';
import * as cartActions from 'modules/cart/duck/actions';

export default function annualBillingCart(state = null, action: any) {
  switch (action.type) {
    case SET_ANNUAL_CART:
    case cartActions.FETCH_CART:
      return action.progress;
    case SET_UPDATED_ANNUAL_CART: {
      if (action.progress.result) {
        return action.progress;
      }
      return state;
    }
    default:
      return state;
  }
}
