import React from 'react';
import AkSpinner from '@atlaskit/spinner';
import { styled } from 'styled-components';

const LoadingWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const Loader: React.FC = () => (
  <LoadingWrapper>
    <AkSpinner size="medium" /> Application is loading...
  </LoadingWrapper>
);

export default Loader;
