// @flow
import React from 'react';

import TotalPrice from 'modules/checkout/payment/components/TotalPrice';
import type { PaymentPageProps } from 'modules/checkout/payment/duck/model';

import CreditCardFormContainer from './CreditCardFormContainer';
import TnsForm from './TnsForm';

export default ({ creditCard, ...props }: PaymentPageProps) =>
  creditCard.isSelected && (
    <CreditCardFormContainer
      progress={creditCard.configureProgress}
      inSiteConfiguratorMode={props.inSiteConfiguratorMode}
    >
      <TnsForm {...creditCard} />
      <TotalPrice {...props} />
    </CreditCardFormContainer>
  );
