import React from 'react';
import Progress from 'redux-progress';
import Flag from '@atlaskit/flag';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import t from 'modules/i18n/intl';
import FormValidationError from 'model/hams/FormValidationError';

export type Props = {
  progress: Progress<any>;
  resetCart: () => void;
};

export default ({ progress, resetCart }: Props) =>
  progress.fold({
    failed: (error) => {
      if (error instanceof FormValidationError) {
        return (
          <Flag
            id="1"
            appearance="error"
            icon={<Error label="Error icon" secondaryColor={colors.R300} />}
            title={t('checkout.contact.submit-error-message')}
          />
        );
      }

      return (
        <SectionMessage
          appearance="error"
          title={t('checkout.contact.unable-to-submit')}
          actions={[
            {
              text: t('checkout.contact.refresh-page'),
              onClick: () => window.location.reload(),
              key: 'checkout-contact-refresh-page',
            },
            {
              text: t('checkout.contact.start-new-cart'),
              onClick: () => resetCart(),
              key: 'checkout-contact-start-new-cart',
            },
            {
              text: t('common.contact-us'),
              href: 'https://www.atlassian.com/company/contact/purchasing-licensing',
              key: 'common-contact-us',
            },
          ].map(({ text, ...restAction }) => (
            <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
          ))}
        >
          {t('checkout.contact.repeated-issue-error-message')}
          {error && error.uuid && <p>Ref: {error.uuid}</p>}
        </SectionMessage>
      );
    },
  });
