import reportError from 'util/errorReporter';
import uniq from 'lodash/uniq';
import { Account, AccountWithChangeOptions } from 'modules/accounts/model';
import { AccountEntry } from 'modules/change/table/duck/model';
import ProductTier from 'model/hams/ProductTier';

import { ServerUpgradeDisabledKeys } from 'data/products/keys';

const order: any = {
  BTF_SUBSCRIPTION_ADDON: -50,
  BTF_SUBSCRIPTION: -50,
  HOSTED: 50,
  SUBSCRIPTION: 50,
};

const HamsUpgradeOptionsIneligibilitytoUIMsgMapping: { [key: string]: string } = {
  ACCOUNT_INACTIVE: 'upgrade.crossgrade.options.fail.ACCOUNT_INACTIVE',
  NO_OPTIONS_AVAILABLE: 'upgrade.crossgrade.options.fail.NO_OPTIONS_AVAILABLE',
  ACCOUNT_ALREADY_CROSSGRADED: 'upgrade.crossgrade.options.fail.ACCOUNT_ALREADY_CROSSGRADED',
  OTHERS: 'upgrade.crossgrade.options.fail.OTHERS',
};

const productTypeComparator = (a, b) => (order[a.productType] || 0) - (order[b.productType] || 0);

export const unitCountComparator = (a: AccountEntry, b: AccountEntry) => {
  const aUnlimited = ProductTier.isUnlimited(a.unitCount);
  const bUnlimited = ProductTier.isUnlimited(b.unitCount);
  if (aUnlimited && bUnlimited) {
    return 0;
  }
  if (aUnlimited) {
    return 1;
  }
  if (bUnlimited) {
    return -1;
  }
  return a.unitCount - b.unitCount;
};

export const entryComparator = (a: AccountEntry, b: AccountEntry) => {
  const productType = productTypeComparator(a, b);
  if (productType !== 0) {
    return productType;
  }

  const unitCount = unitCountComparator(a, b);
  if (unitCount !== 0) {
    return unitCount;
  }

  // @ts-ignore CIW5-18
  return a.expiration - b.expiration;
};

export const isMixedCurrency = (accounts: AccountWithChangeOptions[]): boolean =>
  uniq(
    accounts
      .filter((a) => a.changeOptions && a.changeOptions.length > 0)
      .map((a) => a.changeOptions.length > 0 && a.changeOptions[0].currency),
  ).length > 1;

export const isSelectAllDisabled = (entries: AccountEntry[]): boolean =>
  entries.every((a) => a.isDisabled);

export const isAccountCurrencyMatchingCartCurrency = (account, changeOptions, currency) => {
  if (!changeOptions || changeOptions.length === 0 || !currency) {
    return true;
  }
  // Find this accounts currency from its changeOptions.
  // changeOptions currency fields should always be the same in each account
  const accountCurrencyArray = uniq(changeOptions.map((o) => o.currency));
  if (accountCurrencyArray.length > 1) {
    reportError(`Mismatched currencies in change options for account SEN-${account.accountId}`);
    return false;
  }
  if (accountCurrencyArray.length === 0) {
    return false;
  }
  return currency === accountCurrencyArray[0];
};

export const getHamsUpgradeOptionIneligibilityMsg = (error: string): string =>
  HamsUpgradeOptionsIneligibilitytoUIMsgMapping[error];

export const isSSSDisabled = (account: Account) =>
  Object.values(ServerUpgradeDisabledKeys).includes(account.productKey);
