import mapValues from 'lodash/mapValues';
import { ConfirmationData } from 'modules/checkout/complete/duck/model';

export const ROUTE = 'checkout/complete/ROUTE';

export const route = (data: ConfirmationData) => ({
  type: ROUTE,
  meta: {
    query: mapValues(data, (v) => `${v}`),
  },
});
