/* eslint-disable import/prefer-default-export */

import { ROUTE as CONTACT } from 'modules/checkout/contacts/duck/actions';
import { ROUTE as PAYMENT } from 'modules/checkout/payment/duck/actions';
import { ROUTE as QUOTE } from 'modules/checkout/quote/duck/actions';
import { ROUTE as REVIEW } from 'modules/checkout/review/duck/actions';
import { Selector } from 'model/State';

const routeActions = [CONTACT, PAYMENT, QUOTE, REVIEW];

export const isCheckoutPage: Selector<boolean> = (state) =>
  routeActions.indexOf(state.location.type) !== -1;
