// @flow
import { redirect } from 'redux-first-router';

import type { Dispatch, GetState } from 'model/State';
import { api as cartApi } from 'modules/cart';
import * as upgrade from 'modules/upgrade';
import * as renew from 'modules/renew';
import selectCartPayload from 'modules/cart/duck/selectors/cart';
import watchProgress from 'model/watchProgress';

export const RENEW_ROUTE = 'software/renew/ROUTE';
export const UPGRADE_ROUTE = 'software/upgrade/ROUTE';

export const load = async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const { accountId } = state.location.payload;
  const { callback, email } = state.location.query;

  const cart = await watchProgress(selectCartPayload);

  if (callback) {
    cartApi.storage.cartProperties(cart.uuid).store({ callback: decodeURIComponent(callback) });
  }

  const query: Object = { accountId };
  if (email) {
    query.email = email;
  }

  if (state.location.type === RENEW_ROUTE) {
    return dispatch(redirect(renew.landing.actions.route(query)));
  }
  return dispatch(redirect(upgrade.landing.actions.route(query)));
};
