// @flow
import type { AxiosRequestConfig } from 'axios';
import hamsClient, { HamsError } from 'modules/hams';
import { setInteractionError } from '@atlassian/ufo-set-interaction-error';

import type { AccountChangeOption } from 'modules/accounts/model';

import { isHamsConcurrencyError } from 'util/apiHelper';

export const fetchRenewList = (hamsClientConfig: AxiosRequestConfig) =>
  hamsClient
    .get(
      '/1.0/public/account/renew/list',
      {
        params: {
          _: new Date().getTime(),
        },
      },
      hamsClientConfig,
    )
    .then((r) => r.data);

export const addRenewItemsToCart = (
  cartId: string,
  accountChanges: AccountChangeOption[],
  hamsClientConfig: AxiosRequestConfig,
) =>
  hamsClient
    .post(
      `/1.0/public/order/cart/${cartId}/addAccountRenewalItemsToCart`,
      {
        accountChanges: accountChanges.map((accountChangeOption) => ({ accountChangeOption })),
      },
      {
        ...hamsClientConfig,
        validateStatus: (status) => (status >= 200 && status < 300) || status === 400,
      },
    )
    .then((r) => {
      const hamsConcurrencyError = isHamsConcurrencyError(r);
      if (r.status === 400 && !hamsConcurrencyError) {
        setInteractionError('upgrade-submit-changelist', {
          errorMessage: 'renew add items error',
          name: 'renew add items error',
        });
        throw new HamsError(r);
      } else if (r.status === 400 && hamsConcurrencyError) {
        setInteractionError('upgrade-submit-changelist', {
          errorMessage: 'renew add items error',
          name: 'renew add items error',
        });
        return new HamsError(r);
      } else {
        return r.data;
      }
    });

export const fetchRenewOptionsForAccount = (
  accountId: string,
  email: string | null,
  hamsClientConfig: AxiosRequestConfig,
) =>
  hamsClient
    .post(
      '/1.0/public/account/renewalOptions',
      { accountId, email },
      {
        ...hamsClientConfig,
        validateStatus: (status) => status === 404 || (status >= 200 && status < 300),
      },
    )
    .then((r) => (r.status === 404 ? null : r.data));
