/* eslint-disable import/prefer-default-export */
import Progress from 'redux-progress';
import { Selector } from 'model/State';

export const getValidateCountryContactMatch: Selector<Progress<object>> = (state) =>
  state.checkout.contacts.validateCountryContactMatch.purchaserContactValidation;

export const getValidateCartCurrencyCountryMatch: Selector<Progress<object>> = (state) =>
  state.checkout.contacts.validateCartCurrencyCountryMatch;

export const getTechnicalContactValidation: Selector<Progress<object>> = (state) =>
  state.checkout.contacts.validateCountryContactMatch.technicalContactValidation;
