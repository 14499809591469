import React from 'react';
import { FormattedDate } from 'react-intl';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';

import { colors } from '@atlaskit/theme';
import { Checkbox } from '@atlaskit/checkbox';

// @ts-ignore
import scrollToWhen from 'react-redux-scroll/scroll-to-when-hoc';

import t from 'modules/i18n/intl';
import { SCROLL_TO_ACCOUNT } from 'modules/change/table/duck/actions';
import { RowProps } from 'modules/change/table/duck/model';
import AccountSummary from 'modules/change/table/row/AccountSummary';
import ExtraLicenseNotes from 'modules/change/table/row/ExtraLicenseNotes';
import { EntitlementBlackList } from 'modules/change/table/constants';

import TierLabel from 'components/common/TierLabel';
import GenericErrorMessage from 'components/common/GenericErrorMessage';
import { isHosted } from 'model/hams/Pricing';
import ShowPricingButton from './ShowPricingButton';
import AmountWithCurrency from 'components/common/AmountWithCurrency';
import { Dimensions } from 'components/visuals';

const StyledCell = styled.td`
  padding: 16px 0;

  &:first-child {
    width: ${Dimensions.units(33)}px;
  }

  &:last-child {
    text-align: right;
  }
`;

const ExpirationCell = styled(StyledCell)`
  font-size: 16px;
`;

const UnitCount = styled.span`
  font-size: 16px;
`;

const StyledRow = styled.tr`
  border-bottom: 1px solid ${colors.N40};
  background-color: ${(props: RowProps) =>
    props.entry.options !== null && props.entry.options.length > 0 && props.entry.isSelected
      ? colors.N20
      : 'transparent'};
  &:last-child {
    border-bottom: none;
  }
`;

const ContentWrapper = styled.div`
  display: inline-block;
  text-align: left;
  width: 100%;
`;

const DisabledWrapper = styled.div`
  display: block;
  text-align: left;
  position: relative;
  font-size: 12px;
  color: ${colors.N200};
`;

const DisabledTitle = styled.div`
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const DisabledContent = styled.div`
  font-weight: 400;
`;

const PriceContainer = styled.div`
  position: static;
  width: ${Dimensions.units(39)}px;
  height: ${Dimensions.units(3)}px;
`;

const ScrollableContainer = scrollToWhen({
  pattern: (action, props) =>
    action.type === SCROLL_TO_ACCOUNT && action.payload.accountId === props.entry.accountId,
  excludeProps: ['entry'],
})('div');

const FetchOptionsButton = (props: RowProps) => (
  <ShowPricingButton
    data-account-id={props.entry.accountId as any}
    onClick={() => props.onFetchAccountChangeOptions(props.entry)}
    isLoading={props.entry.options.inProgress}
    isRenewUpgrade={props.isRenewUpgrade}
  />
);

const TableRow = (props: RowProps) => {
  const isAllowedProduct = !EntitlementBlackList.includes(props.entry.productKey);
  const showServerRenewalPrice: boolean =
    !!props.showServerRenewalPrice && props.entry.serverRenewalPrice !== null && isAllowedProduct;

  return (
    <StyledRow {...props}>
      <StyledCell colSpan={2}>
        <ScrollableContainer entry={props.entry}>
          <Checkbox
            isChecked={props.entry.isSelected}
            isDisabled={
              (props.isSSDisabled && props.isSSDisabled(props.entry)) || props.entry.isDisabled
            }
            onChange={() => props.onEntryToggle(props.entry)}
            label={<AccountSummary {...props.entry} />}
            name={`account-${props.entry.accountId}`}
            value="true"
          />
        </ScrollableContainer>
      </StyledCell>
      <StyledCell>
        {showServerRenewalPrice && (
          <PriceContainer data-cy="server-renewal-price">
            <AmountWithCurrency
              currency={props.entry.accountCurrency}
              amount={props.entry.serverRenewalPrice}
            />
          </PriceContainer>
        )}
        {!isHosted(props.entry.productType) && (
          <TierLabel
            unitCount={props.entry.unitCount}
            unitLabel={props.entry.unitLabel}
            formatUnitCount={(count) => <UnitCount>{count}</UnitCount>}
          />
        )}
        <ExtraLicenseNotes {...props} />
      </StyledCell>
      {props.isExpirationVisible && (
        <ExpirationCell>
          <FormattedDate
            value={props.entry.expiration.toDate()}
            day="numeric"
            month="short"
            year="numeric"
          />
        </ExpirationCell>
      )}
      <StyledCell>
        <ContentWrapper>
          {props.entry.isCurrencyMatchingLocked ? (
            <DisabledWrapper>
              <DisabledTitle>{t('change.currency-disabled.title')}</DisabledTitle>
              <DisabledContent>{t('change.currency-disabled.body')}</DisabledContent>
            </DisabledWrapper>
          ) : (
            props.entry.options.fold({
              failed: (error) => <GenericErrorMessage error={error} />,
              success: ({ optionsList }) => {
                if (props.isSSDisabled && props.isSSDisabled(props.entry)) {
                  return props.getSSMessage && props.getSSMessage();
                }

                return optionsList?.length > 0
                  ? props.getChangeSelector(props as any)
                  : props.getNoOptionsMessage(props.entry);
              },
              loading: () => <FetchOptionsButton {...props} />,
              none: () => <FetchOptionsButton {...props} />,
            })
          )}
        </ContentWrapper>
      </StyledCell>
    </StyledRow>
  );
};

const compare = (prev: RowProps, next: RowProps): boolean => isEqual(prev.entry, next.entry);
export default React.memo(TableRow, compare);
