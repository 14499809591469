// @flow
import { combineEpics } from 'redux-observable';

import { actions } from 'modules/checkout/review';

import { timeAction, counter } from './util';

const aspectMapper = (action) => `place.order.${action.checkoutType || 'unknown'}`;

export default combineEpics(
  counter(actions.PLACE_ORDER, aspectMapper),
  timeAction(actions.PLACE_ORDER, aspectMapper),
);
