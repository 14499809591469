import React from 'react';

import Cell from 'modules/cart/summary/cells/Cell';
import AmountWithCurrency from 'components/common/AmountWithCurrency';

import { PriceDeductionSymbol } from './price-symbols';

type Props = {
  amountIncTax: number;
  currency: string;
};

const SLAPriceDeductionCell = (props: Props) => (
  <Cell {...props}>
    <PriceDeductionSymbol />{' '}
    <AmountWithCurrency amount={props.amountIncTax} currency={props.currency} />
  </Cell>
);

export default SLAPriceDeductionCell;
