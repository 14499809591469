// @flow
import React from 'react';
import styled from 'styled-components';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import { Dimensions } from 'components/visuals';
import { colors } from '@atlaskit/theme';
import t from 'modules/i18n/intl';
import Tooltip from 'components/common/Tooltip';
import config from 'appconfig';

type Props = {
  subscription: boolean,
};

const QuestionCircleIconWrapper = styled('div')`
  span {
    line-height: ${Dimensions.units(2)}px;
  }
`;

const SubscriptionTooltip = () => (
  <Tooltip
    direction="right"
    content={
      <span>
        {t('purchase.subscription-info')}&nbsp;
        <a href={`${config.wacUrl}/licensing/purchase-licensing#softwaremaintenance`}>
          {t('purchase.learn-more')}
        </a>
      </span>
    }
  >
    <QuestionCircleIconWrapper>
      <QuestionCircleIcon size="small" primaryColor={colors.N80} />
    </QuestionCircleIconWrapper>
  </Tooltip>
);

const MaintenanceTooltip = () => (
  <Tooltip
    direction="right"
    content={
      <span>
        {t('purchase.maintenance-included')}&nbsp;
        {t('purchase.maintenance-term')}
        <a href={`${config.wacUrl}/licensing/purchase-licensing#softwaremaintenance`}>
          &nbsp;{t('purchase.learn-more')}
        </a>
      </span>
    }
  >
    <QuestionCircleIconWrapper>
      <QuestionCircleIcon size="small" primaryColor={colors.N80} />
    </QuestionCircleIconWrapper>
  </Tooltip>
);

const TermsTooltip = (props: Props) =>
  props.subscription ? <SubscriptionTooltip /> : <MaintenanceTooltip />;

export default TermsTooltip;
