/* eslint-disable no-param-reassign,consistent-return */
import Progress from 'redux-progress';
import produce from 'immer';

import { SEARCH_ACCOUNTS } from 'modules/accounts/actions';
import { AddonState, SEARCH } from 'modules/addon/duck/model';

import {
  CHANGE_EMAIL,
  CHANGE_MODE,
  CHANGE_SEN,
  CHOOSE_PARENT,
  CHOOSE_TERM,
  CHOOSE_TIER,
  ROUTE,
} from 'modules/addon/duck/actions';
import * as cart from 'modules/cart';

const initialState: AddonState = {
  mode: SEARCH,
  choose: {
    accountId: null,
    cartItemId: null,
  },
  search: {
    sen: null,
    email: null,
    result: Progress.none,
  },
  manual: {
    tier: null,
  },
  terms: {},
  submitProgress: Progress.none,
};

export default produce((draft, action): AddonState | void => {
  switch (action.type) {
    case CHANGE_EMAIL:
      draft.search.email = action.payload;
      break;
    case CHANGE_SEN:
      draft.search.sen = action.payload;
      break;
    case CHANGE_MODE:
      draft.mode = action.payload;
      break;
    case CHOOSE_PARENT:
      draft.choose.cartItemId = action.payload.cartItemId || null;
      draft.choose.accountId = (action.payload.license && action.payload.license.accountId) || null;
      break;
    case CHOOSE_TERM:
      draft.terms[action.payload.key] = action.payload.term;
      break;
    case CHOOSE_TIER:
      draft.manual.tier = action.payload;
      draft.terms.manual = null;
      break;
    case SEARCH_ACCOUNTS:
      draft.search.result = action.progress;
      break;
    case cart.actions.ADD_PRODUCT_TO_CART:
      draft.submitProgress = action.progress;
      if (action.progress.isCompleted && action.progress.result?.errorKey) {
        draft.submitProgress = Progress.fail(action.progress.result);
      }
      break;
    case ROUTE:
      return initialState;
    default:
      break;
  }
}, initialState);
