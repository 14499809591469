// @flow
import { StepHeader, StepWrapper } from '../common/Steps';
import SelectWithExtraInfo from '../../../components/common/SelectWithExtraInfo';
import type { Dispatch } from 'model/State';
import { connect } from 'react-redux';
import { actions } from '..';
import React from 'react';

import { SupportProductKeys } from '../../../data/products/keys';
import { Dimensions, Font } from '../../../components/visuals';
import SectionMessage from '@atlaskit/section-message';
import styled from 'styled-components';
import t from '../../i18n/intl';

type NewNotificationSectionProps = {
  titleText: string,
  bodyText: string,
};

const NewNotificationSectionWrapper = styled.div`
  width: 480px;
  font-family: ${Font.family};
  margin-top: ${Dimensions.unit * 3}px;
`;

const NewNotificationSection = ({ titleText, bodyText }: NewNotificationSectionProps) => (
  <NewNotificationSectionWrapper>
    <SectionMessage appearance="information" title={titleText}>
      {bodyText}
    </SectionMessage>
  </NewNotificationSectionWrapper>
);

const ExtraContentWrapper = styled.span`
  font-weight: 600;
`;

const ColumnWrapper = styled.div`
  flex: 1;
`;

type DispatchProps = {
  onChange: (string) => void,
};

type Props = DispatchProps & StateProps & { productKey: string };
const selectOptions = [
  {
    label: 'Bitbucket',
    value: SupportProductKeys.PRIORITY_SUPPORT_DATA_CENTER_BITBUCKET,
    titleText: t('purchase.priority-support.select-product.notification.header-bitbucket'),
    bodyText: t('purchase.priority-support.select-product.notification.body-bitbucket'),
    countDescription: t('purchase.priority-support.select-product.bitbucket-select-extra'),
  },
  {
    label: 'Bamboo',
    value: SupportProductKeys.PRIORITY_SUPPORT_DATA_CENTER_BAMBOO,
    titleText: t('purchase.priority-support.select-product.notification.header-bamboo'),
    bodyText: t('purchase.priority-support.select-product.notification.body-bamboo'),
    countDescription: t('purchase.priority-support.select-product.bamboo-select-extra'),
  },
];

const ProductsToSupportStep = ({ onChange, productKey }: Props) => {
  const items = selectOptions;
  const selectedItem = items.find((item) => item.value === productKey) || items[0];

  return (
    <StepWrapper>
      <div style={{ display: 'flex' }}>
        <ColumnWrapper>
          <StepHeader>{t('purchase.priority-support.select-product.header')}</StepHeader>
          <SelectWithExtraInfo
            items={items}
            getOptionLabel={({ label }) => label}
            getOptionExtraInfo={({ countDescription }) => (
              <ExtraContentWrapper>{countDescription}</ExtraContentWrapper>
            )}
            getOptionValue={({ value }) => value}
            onChange={onChange}
            selected={selectedItem}
          />
          <NewNotificationSection
            titleText={selectedItem.titleText}
            bodyText={selectedItem.bodyText}
          />
        </ColumnWrapper>
      </div>
    </StepWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onChange: ({ value }) => dispatch(actions.goProductConfig(value)),
});

export default connect(null, mapDispatchToProps)(ProductsToSupportStep);
