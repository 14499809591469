/* eslint-disable react/jsx-no-target-blank */
// @flow
import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';

import config from 'appconfig';
import type { HamsCart } from 'modules/cart/duck/model';
import { Font } from 'components/visuals';

type Props = {
  cart: HamsCart,
};

type Agreement = {
  name: string,
  link: string,
  predicate: (HamsCart) => boolean,
};

const agreements: Agreement[] = [
  {
    name: 'Enterprise Support and Services Terms of Use',
    link: `${config.wacUrl}/legal/enterprise-support-and-service-terms-of-use`,
    predicate: (cart) => cart.showEnterpriseSupportAgreement,
  },
  {
    name: 'Software License Agreement',
    link: `${config.wacUrl}/legal/software-license-agreement`,
    predicate: (cart) =>
      !!cart.items.find(
        (item) => !item.productDetails.isTraining && !item.productDetails.marketplaceAddon,
      ),
  },
  {
    name: 'Marketplace Terms of Use',
    link: `${config.wacUrl}/licensing/marketplace/termsofuse`,
    predicate: (cart) => !!cart.items.find((item) => item.productDetails.marketplaceAddon),
  },
  {
    name: 'Training Terms and Policies',
    link: `${config.wacUrl}/legal/training-terms-and-policies`,
    predicate: (cart) => cart.showTrainingAgreement,
  },
  {
    name: 'Privacy Policy',
    link: `${config.wacUrl}/legal/privacy-policy`,
    predicate: () => true,
  },
  {
    name: 'Reseller Agreement',
    link: `${config.wacUrl}/licensing/resellers-licensing`,
    predicate: (cart) => cart.resellerOrder && !cart.expertOrder,
  },
];

const Label = styled.span`
  font-size: 12px;
  font-family: ${Font.family};
  font-weight: 400;
`;

const LinkWrapper = styled.span`
  &:last-child::before {
    content: ' and ';
  }

  &:last-child:first-child::before {
    // we don't need 'and' if the first element is also the last element... only 1 element!
    content: none;
  }

  &::after {
    content: ', ';
  }

  &:last-child::after,
  &:first-child:nth-last-child(2)::after {
    // we don't show a comma after the last element, or when there are only 2 elements
    content: none;
  }
`;

const Link = styled.a`
  font-weight: 500;
  color: ${colors.B400};
`;

const AtlassianEulaTerms = ({ cart }: Props) => {
  const selectedAgreements = agreements.filter((agreement) => agreement.predicate(cart));
  const labels = selectedAgreements.map((agreement) => (
    <LinkWrapper key={agreement.name}>
      <Link href={agreement.link} target="_blank">
        the {agreement.name}
      </Link>
    </LinkWrapper>
  ));
  return <Label>I agree to {labels}.</Label>;
};

export default AtlassianEulaTerms;
