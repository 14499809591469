import Progress from 'redux-progress';
import * as actions from 'modules/checkout/contacts/duck/actions';

export default (state = Progress.none, action: any) => {
  switch (action.type) {
    case actions.CHECK_COUNTRY_CART_CURRENCY_MATCH:
      return action.progress;
    default:
      return state;
  }
};
