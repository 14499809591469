import Progress from 'redux-progress';
import { createSelector } from 'reselect';
import { parseZone } from 'moment';
import intersection from 'lodash/intersection';

import { ParentData } from 'modules/addon/duck/model';
import { mapProductKeyToLegacyProductKey } from 'data/products/keys';
import { selectAddonTermsAmount } from 'modules/configure/duck/selectors';
import { buildTermOptions } from 'modules/configure/duck/util';
import { selectMergedAddonsProductTiers } from 'modules/addon/duck/selectors/mergedProductTiers';
import { allowedLicenseTypes, findTier } from 'modules/addon/duck/selectors/chooseParentOptions';
import selectIsSubscription from 'modules/addon/duck/selectors/isSubscription';
import selectValidParents from 'modules/addon/duck/selectors/validParents';
import { Selector } from 'model/State';

const selectSearchParentOption: Selector<Progress<ParentData | null>> = createSelector(
  selectIsSubscription,
  (state) => state.addon.search.result,
  (state) => state.addon.terms,
  selectMergedAddonsProductTiers,
  selectValidParents,
  selectAddonTermsAmount,
  (
    isSubscriptionProgress,
    searchResult,
    chosenTerms,
    tiersProgress,
    validParentsProgress,
    termsAmountProgress,
  ) =>
    Progress.all(
      isSubscriptionProgress,
      searchResult,
      tiersProgress,
      validParentsProgress,
      termsAmountProgress,
    ).flatMap(([isSubscription, result, tiers, validParents, termsAmount]) => {
      const parentKeys = [mapProductKeyToLegacyProductKey(result?.productKey), result?.productKey];
      const matchingParents = intersection(validParents, parentKeys);
      if (result && !matchingParents.length) {
        return Progress.fail();
      }

      const tier = result && findTier(tiers, result.unitCount);
      if (!tier) {
        return Progress.success(null);
      }

      const key = `search-account-${result.accountId}`;
      const oi = tier.getOrderableItem(result.licenseType);
      const terms = buildTermOptions(oi.renewalAmount, oi.monthsValid, isSubscription, termsAmount);
      const term = chosenTerms[key] || terms[0];

      return Progress.success({
        amount: oi.amount,
        key,
        license: {
          accountId: result.accountId,
          accountName: result.accountName,
          available: allowedLicenseTypes.indexOf(result.licenseType) !== -1,
          email: result.accountName,
          expiration: parseZone(result.expireDate),
        },
        name: result.productDescription,
        orderableItems: tier.getAllOrderableItems(result.licenseType),
        term,
        terms,
        type: result.licenseType,
        unitCount: result.unitCount,
        unitLabel: result.unitLabel,
      });
    }),
);

export default selectSearchParentOption;
