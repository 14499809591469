import Progress from 'redux-progress';
import * as actions from 'modules/auth/actions';

export default (state = Progress.none, action) => {
  switch (action.type) {
    case actions.FETCH_CURRENT_CONTACT:
      return action.progress || state;
    default:
      return state;
  }
};
