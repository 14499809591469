import config from 'appconfig';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import Button from '@atlaskit/button';
import { colors } from '@atlaskit/theme';
import Badge from '@atlaskit/badge';
import EmojiFrequentIcon from '@atlaskit/icon/glyph/emoji/frequent';
import Checkbox from '@atlaskit/checkbox';

import Link from 'components/common/Link';
import Table from 'components/common/Table/Table';
import StopSellingJWMWarningMessage from 'components/navigation/top/StopSellingJWMWarningMessage';
import { Dimensions, Font } from 'components/visuals';
import t from 'modules/i18n/intl';
import { State } from 'model/State';
import RedirectToProductList from './components/redirectToProductList';
import TableContent, { MACTableType } from './table/TableContent';
import {
  selectArchivedAccountIds,
  selectArchivedLicenseIds,
  selectHamsBilledByEmail,
  selectLicenseByEmail,
} from './duck/selectors/contactEmail';

const LicensesPageHeader = styled.h2`
  color: ${colors.N800};
  font-size: 24px;
  margin-top: 30px !important;
  margin-bottom: ${Dimensions.units(4)}px;
  font-weight: 500;
  font-family: ${Font.displayFamily};
  letter-spacing: 0.3px;
`;

const StyledLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledSearchBarWrapper = styled.div`
  padding: ${Dimensions.units(2)}px;
  border-top: 1px solid ${colors.N40};
  border-bottom: 1px solid ${colors.N40};
`;

const StyledCheckboxWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: ${Dimensions.units(2)}px;

  && label {
    display: inline-grid;
    margin-right: ${Dimensions.units(2)}px;
  }

  && span {
    line-height: 1.5;
  }
`;

const StyledPipeSymbol = styled.span`
  color: #ccc;
  padding-right: 3px;
`;

const tableHeadersLicenses = [
  {
    display: 'SEN',
    key: 'licenses-sen',
    width: 20,
  },
  {
    display: 'Product',
    key: 'licenses-product',
    width: 30,
  },
  {
    display: 'Name',
    key: 'licenses-site-name',
    width: 15,
  },
  {
    display: 'Support Expires',
    key: 'licenses-support-expires',
    width: 15,
  },
  {
    display: 'Support',
    key: 'licenses-support',
    width: 20,
  },
];

type Props = {
  senNumber?: string;
};

export const MACLicensesPage = (props: Props) => {
  const [isCollapsedAll, setIsCollapsedAll] = useState(false);
  const [licensesFiltered, setLicensesFiltered] = useState(true);
  const [trialFiltered, setTrialFiltered] = useState(false);
  const [archivedFiltered, setArchivedFiltered] = useState(false);

  const hamsLicensesByEmail: any = useSelector(
    (state) => selectHamsBilledByEmail(state as State) || [],
  );
  const licensesByEmail: any = useSelector((state) => selectLicenseByEmail(state as State) || []);
  const archivedAccountIds = useSelector(selectArchivedAccountIds) || [];
  const archivedLicenseIds = useSelector(selectArchivedLicenseIds) || [];

  const isArchived = (license) =>
    archivedLicenseIds.some((id) => id === license.licenseId) ||
    archivedAccountIds.some((id) => id === license.accountId);
  const isLicenseTrial = ({ licenseType }) => licenseType === 'EVALUATION';

  const licensesItems = [...hamsLicensesByEmail, ...licensesByEmail];
  licensesItems.sort(
    (firstLicenseItem, secondLicenseItem) =>
      new Date(secondLicenseItem.expireDate).getTime() -
      new Date(firstLicenseItem.expireDate).getTime(),
  );

  const initialLicensesByFilters = {
    archived: [],
    trial: [],
    active: [],
  };

  const licensesByFilters = useMemo(
    () =>
      licensesItems.reduce((acc, currentLicense) => {
        if (isArchived(currentLicense)) {
          return {
            ...acc,
            archived: [...acc.archived, currentLicense],
          };
        }
        if (isLicenseTrial(currentLicense)) {
          return {
            ...acc,
            trial: [...acc.trial, currentLicense],
          };
        }
        return {
          ...acc,
          active: [...acc.active, currentLicense],
        };
      }, initialLicensesByFilters),
    [
      hamsLicensesByEmail.length,
      licensesByEmail.length,
      archivedAccountIds.length,
      archivedLicenseIds.length,
    ],
  );

  useEffect(() => {
    if (isCollapsedAll) {
      setIsCollapsedAll(false);
    }
  }, [isCollapsedAll]);

  const handleCollapseAll = () => {
    setIsCollapsedAll(true);
  };

  return (
    <>
      <RedirectToProductList />
      <StopSellingJWMWarningMessage />
      <section className="licenses-support-section">
        <LicensesPageHeader>{t('navigation.header.support')}</LicensesPageHeader>
        <ul>
          <li>
            <FormattedMessage
              id="mac.licenses.see.faq.information"
              values={{
                link: (
                  <a href="https://www.atlassian.com/licensing/purchase-licensing">
                    {t('mac.licenses.see.faq.information-link')}
                  </a>
                ),
              }}
            />
          </li>
          <li>
            <FormattedMessage
              id="mac.licenses.visit-support.information"
              values={{
                link: (
                  <a href="https://support.atlassian.com/">
                    {t('mac.licenses.visit-support.information-link')}
                  </a>
                ),
              }}
            />
          </li>
          <li>
            <FormattedMessage
              id="mac.licenses.information.marketplace"
              values={{
                link: (
                  <a href="https://marketplace.atlassian.com/">{t('products.marketplace.name')}</a>
                ),
              }}
            />
          </li>
        </ul>
        <br />
        <FormattedMessage
          id="mac.licenses.more-questions"
          values={{
            link: <a href="https://www.atlassian.com/company/contact">{t('common.contact-us')}</a>,
          }}
        />
      </section>
      <LicensesPageHeader>{t('mac.licenses.title')}</LicensesPageHeader>
      <StyledLinksWrapper>
        <Button appearance="link" href={config.calculatePricingURL}>
          {t('mac.explore.cloud.pricing')}
        </Button>
        <StyledPipeSymbol>|</StyledPipeSymbol>
        <Button appearance="link">
          <Link to="/my/license-evaluation">{t('mac.new.trial.license')}</Link>
        </Button>
      </StyledLinksWrapper>
      <StyledSearchBarWrapper>
        Search bar placeholder
        <StyledCheckboxWrapper>
          <Checkbox
            isChecked={licensesFiltered}
            onChange={() => {
              setLicensesFiltered(!licensesFiltered);
            }}
            label={
              <>
                <FormattedMessage id="mac.licenses.filtering.licenses" />
                {!!licensesByFilters.active.length && (
                  <Badge max={false}>{licensesByFilters.active.length}</Badge>
                )}
              </>
            }
            name="licenses-filter"
            isDisabled={!licensesByFilters.active.length}
          />
          <Checkbox
            isChecked={trialFiltered}
            onChange={() => {
              setTrialFiltered(!trialFiltered);
            }}
            label={
              <>
                <EmojiFrequentIcon label="" />
                <FormattedMessage id="mac.licenses.filtering.trial" />
                {!!licensesByFilters.trial.length && (
                  <Badge max={false}>{licensesByFilters.trial.length}</Badge>
                )}
              </>
            }
            name="trial-licenses-filter"
            isDisabled={!licensesByFilters.trial.length}
          />
          <Checkbox
            isChecked={archivedFiltered}
            onChange={() => {
              setArchivedFiltered(!archivedFiltered);
            }}
            label={
              <>
                <FormattedMessage id="mac.licenses.filtering.archived" />
                {!!licensesByFilters.archived.length && (
                  <Badge max={false}>{licensesByFilters.archived.length}</Badge>
                )}
              </>
            }
            name="archived-licenses-filter"
            isDisabled={!licensesByFilters.archived.length}
          />
        </StyledCheckboxWrapper>
      </StyledSearchBarWrapper>
      <StyledLinksWrapper>
        <Button appearance="link" onClick={handleCollapseAll}>
          <FormattedMessage id="mac.licenses.table.collapse-all" />
        </Button>
      </StyledLinksWrapper>
      <Table
        body={
          <>
            {archivedFiltered && (
              <TableContent
                items={licensesByFilters.archived}
                tableType={MACTableType.licenses}
                senNumber={props.senNumber}
                isCollapsedAll={isCollapsedAll}
                isArchived
              />
            )}
            {trialFiltered && (
              <TableContent
                items={licensesByFilters.trial}
                tableType={MACTableType.licenses}
                senNumber={props.senNumber}
                isCollapsedAll={isCollapsedAll}
                isTrial
              />
            )}
            {licensesFiltered && (
              <TableContent
                items={licensesByFilters.active}
                tableType={MACTableType.licenses}
                senNumber={props.senNumber}
                isCollapsedAll={isCollapsedAll}
              />
            )}
          </>
        }
        footers={[]}
        headers={tableHeadersLicenses}
        dataAttr="licenses"
      />
    </>
  );
};
