import { thunkProgress } from 'redux-progress';
import { CartThunk } from 'model/State';

import doClearCart from 'modules/cart/duck/api/clearCart';

export const OPEN_DIALOG = 'cart/clear/dialog/OPEN';
export const CLOSE_DIALOG = 'cart/clear/dialog/CLOSE';
export const CLEAR_CART = 'cart/CLEAR';

export const openClearCartDialog = () => ({ type: OPEN_DIALOG });
export const closeClearCartDialog = () => ({ type: CLOSE_DIALOG });

export const clearCart = (): CartThunk => async (dispatch, getState) => {
  const cart = getState().cart.payload.result;

  if (cart && cart.uuid) {
    await dispatch(thunkProgress(CLEAR_CART, doClearCart(cart.uuid)));
    dispatch(closeClearCartDialog());
  }
};
