import getMarketplaceClient from 'util/getMarketplaceClient';
import Progress, { thunkProgress } from 'redux-progress';
import { MarketplaceProductKey } from 'modules/marketplace/data';
import { DeploymentOption, CLOUD, DATACENTER } from 'model/hams/DeploymentOption';
import { Dispatch, GetState, State } from 'model/State';

type Link = {
  href: string;
  type?: string;
  title?: string;
};

export type MarketplaceAddon = {
  name: string;
  key: string;
  tagLine: string;
  summary: string;
  _links: {
    self: Link;
    alternate: Link;
  };
  _embedded: {
    version?: {
      vendorLinks?: {
        eula?: string;
      };
    };
    logo: {
      _links: {
        self: Link;
        image: Link;
      };
    };
  };
};

export type AddonsMap = {
  [addon: string]: Progress<MarketplaceAddon | null>;
};

export const MARKETPLACE_INFO_FETCH = 'catalog/addons/FETCH';

export const selectMarketplaceAddon = (
  state: State,
  props: { productKey: string },
): Progress<MarketplaceAddon | null> => state.catalog.addons[props.productKey] || Progress.none;

const getMarketplaceProductKey = (key) => key.replace(/(\.ondemand|\.data-center)$/, '');

const getSuffix = (hosting) => {
  switch (hosting) {
    case DATACENTER:
      return '.data-center';
    case CLOUD:
      return '.ondemand';
    default:
      return '';
  }
};

export const getCommerceProductKey = (key: string, hosting: DeploymentOption) =>
  `${getMarketplaceProductKey(key)}${getSuffix(hosting)}`;

// getCloudVersionKey returns the key for the cloud version of a addon by adding proper prefix
export const getCloudVersionKey = (key: string): string => getCommerceProductKey(key, CLOUD);

export const fetchAddonInfo = ({
  productKey,
  hosting,
}: {
  productKey: string;
  hosting: DeploymentOption;
}) => async (dispatch: Dispatch, getState: GetState) => {
  const productKeyCommerce = getCommerceProductKey(productKey, hosting);
  const state = getState();
  const marketplaceAddon = selectMarketplaceAddon(state, { productKey: productKeyCommerce });
  if (marketplaceAddon.failed || marketplaceAddon.isNone) {
    const promise = getMarketplaceClient()
      .get(
        `/rest/2/addons/${getMarketplaceProductKey(
          productKey,
        )}?withVersion=true&hosting=${hosting}`,
        {
          validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
        },
      )
      .then((r) => (r.status === 404 ? null : r.data));

    await dispatch(
      thunkProgress(MARKETPLACE_INFO_FETCH, promise, {
        payload: { productKey: productKeyCommerce, hosting },
      }),
    );
  }
};

export const fetchAddonsInfo = (items: { productKey: string; hosting: DeploymentOption }[]) => (
  dispatch: Dispatch,
) => Promise.all(items.map(fetchAddonInfo).map(dispatch));

export const fetchRecommendedAppsFromMarketplace = (
  application: MarketplaceProductKey,
  limit: number = 5,
) => {
  const params = {
    application,
    limit,
    marketingLabel: 'mac-cart',
    cost: 'marketplace',
  };
  return getMarketplaceClient()
    .get('rest/2/addons', { params }) // eslint-disable-next-line no-underscore-dangle
    .then((r) => r.data._embedded.addons);
};

export type MarketplaceSearchParams = {
  application?: MarketplaceProductKey;
  text: string;
  offset: number;
  limit: number;
};

export const fetchSearchFromMarketplace = (params: MarketplaceSearchParams) =>
  getMarketplaceClient()
    .get('/rest/2/addons', { params }) // eslint-disable-next-line no-underscore-dangle
    .then((r) => r.data._embedded.addons);

const reducer = (state: AddonsMap = {}, action: any) => {
  switch (action.type) {
    case MARKETPLACE_INFO_FETCH:
      return {
        ...state,
        [action.payload.productKey]: action.progress,
      };
    default:
      return state;
  }
};

export default reducer;
