import { createSelector } from 'reselect';
import Progress from 'redux-progress';

import { selectIsAcademicOn } from 'modules/addon/duck/selectors/academic';
import selectIsSubscription from 'modules/addon/duck/selectors/isSubscription';
import { selectManualTier } from 'modules/addon/duck/selectors/tier';
import { Selector } from 'model/State';
import { TermOption } from 'model/TermOption';
import { LICENSE_ACADEMIC, LICENSE_COMMERCIAL } from 'model/hams/LicenseType';
import { buildTermOptions } from 'modules/configure/duck/util';
import { selectAddonTermsAmount } from 'modules/configure/duck/selectors';

const getLicenseType = (state) =>
  selectIsAcademicOn(state) ? LICENSE_ACADEMIC : LICENSE_COMMERCIAL;

export const selectManualTerms: Selector<
  Progress<TermOption[] | null>
> = createSelector(
  selectManualTier,
  getLicenseType,
  selectIsSubscription,
  selectAddonTermsAmount,
  (tierProgress, licenseType, isSubscriptionProgress, termsAmountProgress) =>
    Progress.all(
      tierProgress,
      isSubscriptionProgress,
      termsAmountProgress,
    ).map(([tier, isSubscription, termsAmount]) =>
      tier
        ? buildTermOptions(
            tier.getOrderableItem(licenseType).renewalAmount,
            tier.getOrderableItem(licenseType).monthsValid,
            isSubscription,
            termsAmount,
          )
        : null,
    ),
);
