import * as storage from 'modules/checkout/payment/duck/api/storage';
import * as steps from 'modules/checkout/steps';
import { IAvailablePaymentMethods } from 'modules/checkout/payment/duck/selectors/getAvailablePaymentMethods';
import { CheckoutModes } from 'modules/checkout/payment/duck/model/PaymentState';

export const SELECT_MODE = 'checkout/payment/SELECT_MODE';
export const selectMode = (method) => (dispatch) => {
  storage.checkoutMode.store(method);
  dispatch(steps.markComplete('payment', false));
  dispatch({
    type: SELECT_MODE,
    payload: method,
  });
};

export const clearMode = () => (dispatch) => {
  storage.checkoutMode.reset();
  dispatch(steps.markComplete('payment', false));
  dispatch({
    type: SELECT_MODE,
    payload: null,
  });
};

const getDefaultCheckoutMethod = (availableCheckoutMethods: IAvailablePaymentMethods) => {
  try {
    if (availableCheckoutMethods.free) return CheckoutModes.FREE;

    if (availableCheckoutMethods.paypal) return CheckoutModes.PAYPAL;

    if (availableCheckoutMethods.tns) return CheckoutModes.CREDIT_CARD;

    if (availableCheckoutMethods.stripe) return CheckoutModes.STRIPE_CREDIT_CARD;

    if (availableCheckoutMethods.onAccount) return CheckoutModes.PAY_ON_ACCOUNT;

    if (availableCheckoutMethods.onTerms) return CheckoutModes.PAY_ON_TERMS;

    if (availableCheckoutMethods.quote) return CheckoutModes.BANK_TRANSFER;

    return '';
  } catch {
    throw new Error('No available checkout method');
  }
};

export const selectDefaultMode = (availableCheckoutMethods: IAvailablePaymentMethods) => {
  const defaultCheckoutMethod = getDefaultCheckoutMethod(availableCheckoutMethods);
  return selectMode(defaultCheckoutMethod);
};
