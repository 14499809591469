import { combineEpics } from 'redux-observable';

import { route } from 'modules/mac/duck/actions/editDetails';

import { timeAction, counter } from './util';

export default combineEpics(
  counter(route.type, 'mac.edit-details'),
  timeAction(route.type, 'mac.edit-details'),
);
