// @flow
import Progress from 'redux-progress';
import * as Rx from 'rxjs';
import { first } from 'rxjs/operators';
import type { TnsPaymentDetails } from 'modules/checkout/payment/duck/model/TnsPaymentDetails';
import {
  TnsResponseError,
  TnsValidationError,
} from 'modules/checkout/payment/duck/model/TnsErrors';
import loadTnsSessionScript from 'modules/checkout/payment/duck/api/loadTnsSessionScript';

function getFocus$(paymentSession) {
  const subject = new Rx.Subject();
  const fields = ['cardNumber', 'securityCode'];
  fields.forEach((field) => paymentSession.onFocus([field], () => subject.next(field)));
  paymentSession.onBlur(fields, () => subject.next(null));

  return subject.asObservable();
}

const isValidScheme = (scheme) => ['MASTERCARD', 'VISA', 'AMEX'].includes(scheme);

function createError(response) {
  if (response.status === 'fields_in_error') {
    const { errors } = response;
    return new TnsValidationError({
      cardNumber: errors.cardNumber ? Progress.fail() : Progress.success(),
      securityCode: errors.securityCode ? Progress.fail() : Progress.success(),
      expiry: errors.expiryMonth || errors.expiryYear ? Progress.fail() : Progress.success(),
    });
  }
  return new TnsResponseError(response);
}

export default class TnsSession {
  paymentSession: any;
  updateResult$: Rx.Observable<TnsPaymentDetails>;
  fieldFocus$: Rx.Observable<string | null> = Rx.empty();

  constructor(paymentSession: any, updateResult$: any) {
    this.paymentSession = paymentSession;
    this.updateResult$ = updateResult$;
    this.fieldFocus$ = getFocus$(paymentSession);
  }

  static async configure(sessionId: string, fields: {}): Promise<TnsSession> {
    const paymentSession = await loadTnsSessionScript();
    const updateResult$ = new Rx.Subject();

    await new Promise((resolve, reject) =>
      paymentSession.configure({
        session: sessionId,
        fields,
        frameEmbeddingMitigation: ['x-frame-options', 'csp'],
        callbacks: {
          initialized: (response) => {
            if (response.status === 'ok') {
              resolve();
            } else {
              reject(response);
            }
          },
          formSessionUpdate: (response) => {
            if (response.status === 'ok') {
              const { card } = response.sourceOfFunds.provided;
              if (!isValidScheme(card.scheme)) {
                updateResult$.error(
                  new TnsValidationError({
                    cardNumber: Progress.fail({ unsupportedScheme: true }),
                  }),
                );
              } else {
                updateResult$.next({
                  gatewayCardExpiryDateYear: card.expiry.year,
                  gatewayCardExpiryDateMonth: card.expiry.month,
                  gatewayCardNumberMasked: card.number,
                  gatewayCardScheme: card.scheme,
                });
              }
            } else {
              updateResult$.error(createError(response));
            }
          },
        },
      }),
    );

    return new TnsSession(paymentSession, updateResult$.asObservable());
  }

  updateFromForm = async (): Promise<TnsPaymentDetails> => {
    const promise = this.updateResult$.pipe(first()).toPromise();
    this.paymentSession.updateSessionFromForm();
    return promise;
  };
}
