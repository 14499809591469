import React, { useEffect, useContext, useMemo, Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import AmountWithCurrency from 'components/common/AmountWithCurrency';
import { HamsAccountMigrationInfo } from 'modules/migrate/comparator/api';
import { getAnnualTierIndex, getMonthlyAmount } from 'modules/migrate/comparator/row/utils';
import { DeploymentAndBillingCycleContext } from 'modules/migrate/comparator/Switchers';
import { BILL_CYCLE, DEPLOY_OPTION, PRICING_PLANS } from '../../utils';

export const StyledAmount = styled(AmountWithCurrency)`
  display: block;
  font-size: 20px;
  color: #000;
  font-weight: 600;
`;

interface Props {
  account: HamsAccountMigrationInfo;
  recommendedPlan: string;
  numberOfUsers: number;
  productTableIndex: number;
  updateAllAmounts: Dispatch<SetStateAction<number[]>>;
}

const ServerCloudPrice: React.FC<Props> = ({
  recommendedPlan,
  account,
  numberOfUsers,
  productTableIndex,
  updateAllAmounts,
}) => {
  let messageId: string = 'migrate.table.row.per-month';
  let amount: number = 0;

  const { billCycle, deployOption } = useContext(DeploymentAndBillingCycleContext);
  const monthlyAmount = useMemo(() => {
    let priceStrategy;
    switch (recommendedPlan) {
      case PRICING_PLANS.STANDARD:
        priceStrategy = account.monthlyPriceStrategy;
        break;
      case PRICING_PLANS.PREMIUM:
        priceStrategy = account.premiumMonthlyPriceStrategy;
        break;
      default:
        return 0;
    }
    return getMonthlyAmount(priceStrategy, numberOfUsers);
  }, [account, numberOfUsers, recommendedPlan]);

  const annualAmount = useMemo(() => {
    const annualTierIndex = getAnnualTierIndex(account, recommendedPlan, numberOfUsers);
    return account.annualTiers?.[annualTierIndex].amount;
  }, [recommendedPlan, numberOfUsers]);

  if (deployOption === DEPLOY_OPTION.CLOUD) {
    if (billCycle === BILL_CYCLE.MONTHLY) {
      amount = monthlyAmount;
    } else {
      amount = annualAmount;
      messageId = 'migrate.table.row.per-year';
    }
  } else if (billCycle === BILL_CYCLE.ANNUAL) {
    amount = account.serverRenewalPrice || 0;
    messageId = 'migrate.table.row.till-eol';
  }

  if (recommendedPlan === PRICING_PLANS.FREE) {
    amount = 0;
  }

  useEffect(() => {
    updateAllAmounts((prevState) => {
      const amounts = [...prevState];
      amounts[productTableIndex] = amount;
      return [...amounts];
    });
  }, [amount]);

  const styledAmountProps = {
    format: 'code',
    currency: account.currency || 'USD',
    amount,
    useSpace: true,
  };

  return (
    <FormattedMessage
      id={messageId}
      values={{
        amount: <StyledAmount {...styledAmountProps} />,
      }}
    />
  );
};

export default ServerCloudPrice;
