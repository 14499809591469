// @flow
import { envTypes } from '@atlassiansox/metal-client';
import type { EnvironmentConfig } from 'model/EnvironmentConfig';

export default (): EnvironmentConfig => ({
  atlAdminUrl: 'https://admin.atlassian.com',
  clicktaleEnabled: true,
  hamsUrl: 'https://www.atlassian.com/hamlet',
  idLoginUrl: 'https://id.atlassian.com/login',
  launchdarklyId: '5760d1160b163a072400013d',
  logoutUrl: () =>
    `https://id.atlassian.com/logout?continue=${encodeURIComponent(document.location.href)}`,
  macUrl: 'https://my.atlassian.com',
  marketplaceUrl: 'https://marketplace.atlassian.com',
  padresUrl: 'https://marketplace.atlassian.com/padres',
  paypal: {
    env: 'production',
  },
  segmentKey: 'kiv6wyh2nw',
  sentry: {
    environment: 'prod',
  },
  tnsSessionUrl:
    'https://secure.na.tnspayments.com/form/version/34/merchant/ATLASSIAN02/session.js',
  wacUrl: 'https://www.atlassian.com',
  i18nUrl: 'https://cart-static.us-east-1.prod.public.atl-paas.net/i18n',
  metal: {
    environment: envTypes.PROD,
  },
  cloudMigrationToolsUrl: 'https://api-private.atlassian.com/cloud-migration-tools',
  root: 'https://www.atlassian.com/purchase',
  partnerPortal: 'https://partners.atlassian.com',
  intercomAppID: 'odnoznr4',
  adminHubUrl: 'https://admin.atlassian.com',
  statsigClientSDK: 'aaf4c849-51e4-4db0-94b3-38c905cf060b',
  emailMAC: (userEmail) =>
    `https://id.atlassian.com/login?application=mac&continue=https://id.atlassian.com/manage-profile&prompt=none&login_hint=${userEmail}`,
  billingDetailsMAC: 'https://www.atlassian.com/purchase/my/edit-details',
  switchUserMAC: 'https://www.atlassian.com/purchase/my/config',
  emailPreferencesMAC: 'https://my.stg.internal.atlassian.com/email', // TODO: this will be update with this ticket: https://hello.jira.atlassian.cloud/browse/CHT-2683
  switchAccountMAC:
    'https://id.atlassian.com/login?application=mac&continue=https://www.atlassian.com/purchase/my&prompt=select_account',
  logOutMAC:
    'https://id.atlassian.com/logout?application=mac&continue=https://www.atlassian.com/purchase/my',
  calculatePricingURL: 'https://www.atlassian.com/migration/assess/calculate-pricing',
});
