import React from 'react';
import styled from 'styled-components';
import QuoteIcon from 'modules/checkout/quote/img/quote-icon.svg';
import { Dimensions } from 'components/visuals';

const Wrapper = styled.div`
  margin-top: ${Dimensions.units(3)}px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: url('${QuoteIcon}') no-repeat 60px center;
  padding: ${Dimensions.units(3)}px ${Dimensions.units(25)}px;
`;

export default () => (
  <Wrapper>
    <h3>Price quote</h3>
    <p>
      We&#39;ll send you a no-obligation price quote for the product(s) you have selected. When
      you&#39;re ready to pay your quote, you can pay by credit card, bank transfer or check.
    </p>
    <p>Your quote will be emailed to you and available for download as a PDF.</p>
    <p>Quotes are valid for 30 days.</p>
  </Wrapper>
);
