/* eslint-disable import/prefer-default-export */

import Progress from 'redux-progress';

import { selectAllAccounts } from 'modules/change/table/duck/selectors/accounts';
import { Selector } from 'model/State';
import { AccountChangeOption } from 'modules/accounts/model';

export const getChangeOptions = (state, account) =>
  state.change.table.options[account.accountId] ||
  (account.changeOptions && account.changeOptions.length > 0
    ? Progress.success(account.changeOptions)
    : Progress.none);

const isAccountSelected = (state) => (account) =>
  state.change.table.selected.accounts[account.accountId] &&
  getChangeOptions(state, account).ifSuccess(({ optionsList }) => optionsList.length > 0);

const getDefaultOption = (state, account) =>
  getChangeOptions(state, account).ifSuccess(({ optionsList }) => optionsList[0]);

const isChangeOptionSelected = (state, account) => (changeOption) => {
  const defaultOption = getDefaultOption(state, account);
  return (
    changeOption.orderableItemId ===
      (state.change.table.selected.orderableItemIds[account.accountId] ||
        (defaultOption && defaultOption.orderableItemId)) &&
    changeOption.maintenanceMonths ===
      (state.change.table.selected.months[account.accountId] !== undefined
        ? state.change.table.selected.months[account.accountId]
        : defaultOption && defaultOption.maintenanceMonths)
  );
};

const getAccountChangeOption = (state) => (account) =>
  getChangeOptions(state, account).ifSuccess(
    ({ optionsList }) =>
      optionsList.find(isChangeOptionSelected(state, account)) || getDefaultOption(state, account),
  );

export const selectChosenOptions: Selector<Progress<AccountChangeOption[]>> = (state) =>
  selectAllAccounts(state).map((accounts) =>
    accounts.filter(isAccountSelected(state)).map(getAccountChangeOption(state)).filter(Boolean),
  );
