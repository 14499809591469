// @flow
import React from 'react';
import styled from 'styled-components';
import type { DeploymentOption } from 'model/hams/DeploymentOption';
import { CLOUD, DATACENTER, SERVER } from 'model/hams/DeploymentOption';
import Cloud from 'components/common/img/cloud.svg';
import Server from 'components/common/img/server.svg';
import Datacenter from 'components/common/img/datacenter.svg';

type Props = {
  deployment: DeploymentOption,
  isDisabled: boolean,
};

const Icon = styled.img`
  opacity: ${(props) => (props.disabled ? '40%' : '100%')};
  height: 24px;
  color: inherit;
`;

const DeploymentIcon = ({ deployment, isDisabled }: Props) => {
  switch (deployment) {
    case CLOUD:
      return <Icon disabled={isDisabled} src={Cloud} alt="cloud" />;
    case SERVER:
      return <Icon disabled={isDisabled} src={Server} alt="server" />;
    case DATACENTER:
      return <Icon disabled={isDisabled} src={Datacenter} alt="datacenter" />;
    default:
      return null;
  }
};

export default DeploymentIcon;
