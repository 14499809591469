import Progress, { thunkProgress } from 'redux-progress';
import { CartThunk } from 'model/State';
import { HamsCart } from 'modules/cart/duck/model';
import { selectHamsClientConfig } from 'modules/auth/selectors';
import { selectAnnualBillingCart, selectBiennialBillingCart } from '../selectors';
import { updateCartItem } from '../api';
import { loadPayloadInCart } from './setCart';
import { getItemsByAccountIDFromBillingCarts, UpdateCartItemParams } from '../../utils';

import { reportLogOnly } from 'util/errorReporter';

export const SET_UPDATED_ANNUAL_CART = 'siteconfigurator/SET_UPDATED_ANNUAL_CART';
export const SET_UPDATED_BIENNIAL_CART = 'siteconfigurator/SET_UPDATED_BIENNIAL_CART';

export const updateCart = ({
  accountId,
  sku,
  unitCount,
  setIsPageLoading,
  setAnnualBillingCartError,
  setBiennialBillingCartError,
}: UpdateCartItemParams): CartThunk => async (dispatch, getState) => {
  const state = getState();
  const hamsClientConfig = selectHamsClientConfig(state);
  const annualBillingCart = selectAnnualBillingCart(state);
  const biennialBillingCart = selectBiennialBillingCart(state);
  const itemIds = getItemsByAccountIDFromBillingCarts(
    accountId,
    annualBillingCart.result,
    biennialBillingCart.result,
  );

  setIsPageLoading(Progress.inProgress);
  const updatedAnnualCart: Promise<HamsCart | null> = updateCartItem(
    hamsClientConfig,
    annualBillingCart.result?.uuid || '',
    itemIds.annualBillingItem,
    sku,
    unitCount,
    setAnnualBillingCartError,
  );
  const updatedBiennialCart: Promise<HamsCart | null> = updateCartItem(
    hamsClientConfig,
    biennialBillingCart.result?.uuid || '',
    itemIds.biennialBillingItem,
    sku,
    unitCount,
    setBiennialBillingCartError,
  );

  await dispatch(thunkProgress(SET_UPDATED_ANNUAL_CART, updatedAnnualCart));
  await dispatch(thunkProgress(SET_UPDATED_BIENNIAL_CART, updatedBiennialCart));
  await dispatch(loadPayloadInCart());
  setIsPageLoading(Progress.success(true));

  const productDetailsBefore = annualBillingCart.result?.items.find(
    (product) => product.accountId === accountId,
  )?.productDetails;
  const parsedSku = sku.split(':');
  const messageObjectTier = {
    before: { unitCount: productDetailsBefore?.unitCount },
    after: { unitCount },
  };
  const messageObjectPlan = {
    before: { edition: productDetailsBefore?.edition },
    after: { edition: parsedSku[parsedSku.length - 1] },
  };
  if (productDetailsBefore?.unitCount !== unitCount) {
    reportLogOnly('SiteConfiguratorUpdateTier', messageObjectTier);
  } else {
    reportLogOnly('SiteConfiguratorUpdatePlan', messageObjectPlan);
  }
};
