import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { colors } from '@atlaskit/theme';

import { Dimensions, Font } from 'components/visuals';
import appconfig from 'appconfig';
import { GridRow } from './Layout';
import { GridColumn } from '@atlaskit/page';

const BreadcrumbsWrapper = styled.div`
  padding-top: ${Dimensions.units(3)}px;
  font-family: ${Font.family};
  font-size: 0.875rem;
  color: ${colors.N100};

  & a {
    color: ${colors.N100};
  }

  & a:hover {
    color: ${colors.N400};
  }

  & a:visited {
    color: ${colors.P500};
  }
`;

const breadcrumbLinks = [
  {
    link: `${appconfig.wacUrl}/migration`,
    text: 'migrate.comparator.page.breadcrumbs.migration-center',
  },
  {
    link: `${appconfig.wacUrl}/migration/assess`,
    text: 'migrate.comparator.page.breadcrumbs.assess-options',
  },
  {
    link: `${appconfig.wacUrl}/migration/assess/calculate-pricing`,
    text: 'migrate.comparator.page.breadcrumbs.estimate-pricing-and-costs',
  },
];

export const Breadcrumbs = () => (
  <GridRow>
    <GridColumn medium={1} />
    <GridColumn medium={10}>
      <BreadcrumbsWrapper>
        {breadcrumbLinks.map(({ link, text }) => (
          <React.Fragment key={text}>
            <a href={link} rel="noopener noreferrer" target="_blank">
              <FormattedMessage id={text} />
            </a>
            {'  /  '}
          </React.Fragment>
        ))}
        <a href={`${appconfig.wacUrl}/purchase/price-comparison`} rel="noopener noreferrer">
          <FormattedMessage id="migrate.comparator.page.breadcrumbs.migration-pricing-calculator" />
        </a>
      </BreadcrumbsWrapper>
    </GridColumn>
    <GridColumn medium={1} />
  </GridRow>
);

export default Breadcrumbs;
