import Progress from 'redux-progress';
import { combineReducers } from 'redux';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';

import launchdarkly from './launchdarkly';
import notificationFlag from './notificationFlag';

export default combineReducers({
  launchdarkly,
  notificationFlag,
});

export type MetaModuleState = {
  launchdarkly: Progress<LDFlagSet>;
  notificationFlag: any[];
};
