import cookies from 'js-cookie';

/**
 * GDPR check return true if the user does not give use consent to do
 * Performance and Analytics
 */
const noAnalyticsCookiesConsent = () => /,2:0/.test(cookies.get('OptanonConsent') || '');

/**
 * GDPR check return true if the user does not give use consent to do
 * Targeting
 */
const noTargetingCookiesConsent = () => /,4:0/.test(cookies.get('OptanonConsent') || '');

export const isAnalyticsDisabled = () =>
  (<any>window).CART_DISABLE_ANALYTICS ||
  noAnalyticsCookiesConsent() ||
  noTargetingCookiesConsent();
