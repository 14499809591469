// @flow
import React, { useContext } from 'react';
import PaymentMethodButton from 'modules/checkout/payment/components/PaymentMethodButton';
import type { PaymentPageProps } from 'modules/checkout/payment/duck/model/index';
import { CheckoutModes } from 'modules/checkout/payment/duck/model/index';
import t from 'modules/i18n/intl';

import { CartAnalyticsContext } from 'modules/analytics';
import { analyticsEvents, AnalyticsTrigger } from 'modules/siteconfigurator/analytics';
import { PAYPAL_TEST_ID } from './TabButton.testids';

export default (props: PaymentPageProps) => {
  const analytics = useContext(CartAnalyticsContext);
  const onSelect = () => {
    if (props.inSiteConfiguratorMode) {
      analyticsEvents[AnalyticsTrigger.PayPal](analytics);
    }
    props.selectMode(CheckoutModes.PAYPAL);
  };

  return (
    props.paypal.isAvailable && (
      <PaymentMethodButton
        title={t('payment.paypal.button.title')}
        isSelected={props.paypal.isSelected}
        select={onSelect}
        testId={PAYPAL_TEST_ID}
      >
        {t('payment.paypal.button.description')}
      </PaymentMethodButton>
    )
  );
};
