import React from 'react';
import styled from 'styled-components';

import { Props } from 'modules/addon/VisualAddonPage';
import { actions } from 'modules/addon';
import { Dimensions, Font } from 'components/visuals';
import ChooseDeployment from 'modules/configure/deployment/ChooseDeployment';
import t from 'modules/i18n/intl';

const Header = styled.h2`
  font-size: 24px;
  font-family: ${Font.displayFamily};
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-bottom: ${Dimensions.units(2)}px;
`;

const DeploymentSection = ({
  data,
  events,
  hasAllowedServerOption,
  isAccessRebrandingFFOn,
}: Props & {
  hasAllowedServerOption: boolean;
  isAccessRebrandingFFOn: boolean;
}) => {
  const selectedDeployment = data.productKeys[0];

  return (
    data.deploymentGroup &&
    data.deploymentOption && (
      <>
        <Header>{t('purchase.choose-deployment-step')}</Header>
        <ChooseDeployment
          options={data.deploymentGroup}
          selected={selectedDeployment}
          alwaysShowCloud
          onChange={events.onDeploymentChange}
          action={actions.route}
          hasAllowedServerOption={hasAllowedServerOption}
          isAccessRebrandingFFOn={isAccessRebrandingFFOn}
        />
      </>
    )
  );
};

export default DeploymentSection;
