import { thunk } from 'routes';
import * as cart from 'modules/cart';
import { actions as authActions } from 'modules/auth';
import * as launchdarkly from 'modules/meta/launchdarkly';
import { Dispatch, GetState } from 'model/State';
import * as purchaseMode from 'modules/settings/purchaseMode';
import { MACSwitchedUser } from 'modules/mac/duck/api/storage';
import { restoreSwitchUserEmail } from 'modules/mac/duck/actions/configUser';
import { fetchIsMemberOfGroup } from 'modules/mac/duck/actions/contact';

export const init = () => (dispatch: Dispatch) =>
  Promise.all([
    dispatch(authActions.fetchXsrfToken()).then(() =>
      Promise.all([
        dispatch(authActions.fetchUserDetails()),
        dispatch(authActions.fetchCurrentContact()),
        dispatch(cart.actions.init()),
      ]),
    ),
    dispatch(purchaseMode.init()),
    dispatch(launchdarkly.actions.loadFeatureFlags()),
  ]);

export const MACInit = () => (dispatch: Dispatch) =>
  dispatch(authActions.fetchXsrfToken()).then(() =>
    Promise.all([
      dispatch(authActions.fetchUserDetails()),
      dispatch(authActions.fetchCurrentContact()),
    ]),
  );

export const setDefaultUser = (dispatch: Dispatch) => {
  const storedUser = MACSwitchedUser();
  const storedUserEmail = storedUser.load();
  if (!storedUserEmail) {
    return dispatch(restoreSwitchUserEmail());
  }
  return null;
};

export const checkIsMACSwitchUserGroupMember = (dispatch: Dispatch) =>
  dispatch(fetchIsMemberOfGroup('mac-switch-user'));

export default (originalThunk: thunk) => async (dispatch: Dispatch, getState: GetState) => {
  const { location } = getState();
  const locationKind = location.kind;
  const isMACLocationType = location.type.startsWith('mac/');

  if (locationKind !== 'load') {
    return originalThunk(dispatch, getState);
  }

  if (isMACLocationType) {
    return Promise.all([
      await dispatch(MACInit()),
      setDefaultUser(dispatch),
      checkIsMACSwitchUserGroupMember(dispatch),
      originalThunk(dispatch, getState),
    ]);
  }

  return Promise.all([await dispatch(init()), originalThunk(dispatch, getState)]);
};
