import { NOTIFICATION_FLAG_TIMEOUT } from 'util/constants';
import { GetState } from 'model/State';

export const REMOVE_NOTIFICATION_FLAG = 'meta/notificationFlag/REMOVE_NOTIFICATION_FLAG';
export const CANCEL_ALL_NOTIFICATION_FLAGS = 'meta/notificationFlag/CANCEL_ALL_NOTIFICATION_FLAGS';
export const SHOW_NOTIFICATION_FLAG = 'meta/notificationFlag/SHOW_NOTIFICATION_FLAG';

export const removeNotificationFlag = (timeoutId) => (dispatch) =>
  dispatch({ type: REMOVE_NOTIFICATION_FLAG, timeoutId });

export const cancelAllNotificationFlags = () => (dispatch, getState: GetState) => {
  const state = getState();
  const notificationFlagArray = state.meta.notificationFlag;
  notificationFlagArray.map((n) => clearTimeout(n.timeoutId));
  return dispatch({ type: CANCEL_ALL_NOTIFICATION_FLAGS });
};

export const showNotificationFlag = (message) => (dispatch) => {
  const timeoutId = setTimeout(
    () => dispatch(removeNotificationFlag(timeoutId)),
    NOTIFICATION_FLAG_TIMEOUT,
  );
  return dispatch({ type: SHOW_NOTIFICATION_FLAG, timeoutId, message });
};
