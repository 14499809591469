import {
  HIDE_JIRA_CORE_MODAL,
  SHOW_JIRA_CORE_MODAL,
} from 'modules/upgrade/duck/datacenter/actions/jiraCoreModal';

const initial = { showUpgradeJiraCoreModal: false };

const reducer = (state = initial, action) => {
  switch (action.type) {
    case SHOW_JIRA_CORE_MODAL:
      return { showUpgradeJiraCoreModal: true };
    case HIDE_JIRA_CORE_MODAL:
      return { showUpgradeJiraCoreModal: false };
    default:
      return state;
  }
};

export default reducer;
