import Progress from 'redux-progress';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export default <T, R>(selector: (t: T) => Progress<R>): ((o: Observable<T>) => Observable<R>) => (
  source: Observable<T>,
) =>
  source.pipe(
    map(selector),
    filter((p) => p.success),
    map((p) => p.unwrap()),
  );
