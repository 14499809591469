// @flow
/* eslint-disable react/prop-types */
import * as React from 'react';
import Progress from 'redux-progress';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import Flag from '@atlaskit/flag';
import { colors } from '@atlaskit/theme';
import Error from '@atlaskit/icon/glyph/error';

type Props<T> = {
  +progress: Progress<T>,
  +errorMessage: React.Node,
  +nullMessage?: React.Node,
  +children: (T) => any,
};
const Wrapper = styled.div`
  margin: 30px;
  > * {
    margin-right: 10px;
  }
`;
const ProgressLoader = <T>(props: Props<T>) =>
  props.progress.fold({
    loading: () => (
      <Wrapper>
        <Spinner interactionName="progress-loader" size="medium" /> Loading ...
      </Wrapper>
    ),
    failed: () => (
      <Wrapper>
        <Flag
          id="1"
          appearance="error"
          icon={<Error label="Error icon" secondaryColor={colors.R300} />}
          title={props.errorMessage}
        />
      </Wrapper>
    ),
    success: (e) => (props.nullMessage && !e ? <h1>{props.nullMessage}</h1> : props.children(e)),
  }) || null;

export default ProgressLoader;
