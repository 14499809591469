import * as React from 'react';
import Progress from 'redux-progress';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import { Dimensions } from 'components/visuals';
import GenericErrorMessage from 'components/common/GenericErrorMessage';

type Props<T> = {
  progress: Progress<T>;
  errorMessage?: (any) => React.ReactNode;
  children: (props: T) => any;
};

const LoadingWrapper = styled.div`
  text-align: center;
  padding-top: ${Dimensions.units(10)}px;
`;

export const LoadingSpinner = () => (
  <LoadingWrapper>
    <Spinner size="medium" interactionName="page-loading-spinner" />
  </LoadingWrapper>
);

const PageLoading = <T,>(props: Props<T>) =>
  props.progress.fold({
    none: () => <LoadingSpinner />,
    loading: () => <LoadingSpinner />,
    failed: props.errorMessage,
    success: props.children,
  });

PageLoading.defaultProps = {
  errorMessage: (error: Object) => <GenericErrorMessage error={error} />,
};

export default PageLoading;
