/* eslint-disable import/prefer-default-export */

import Progress from 'redux-progress';
import { Selector, State } from 'model/State';
import { HamsProductPricing } from 'model/hams/Pricing';
import { selectProductPricing } from 'modules/catalog/pricing';

export const selectPromotionProducts: Selector<Progress<HamsProductPricing[]>> = (state: State) => {
  const promotion = state.promotion.storage;
  if (!promotion) {
    return Progress.none;
  }

  const pricings: Progress<HamsProductPricing | null>[] = promotion.products.map((productKey) =>
    selectProductPricing(state, { productKey }),
  );

  // $FlowFixMe flow doesn't understand this will be a Progress of an array of pricings.
  return Progress.all(...pricings);
};
