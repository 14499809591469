import React, { useCallback } from 'react';

import { PaymentMethod, loadStripe } from '@stripe/stripe-js';

import useAlwaysReturnTrueOnceConditionIsMet from './useAlwaysReturnTrueOnceConditionIsMet';

import {
  HAMSCreditCardFormState,
  useHAMSGateway,
  isError,
  isException,
  isFailure,
  isSuccessful,
  isSystemErrorResult,
  useCommerceStripe,
  LanguagePreferencesProvider,
  useHAMSConfirmCardPaymentWithStripe,
} from '@atlassian/commerce-ui/credit-card-hams';

import * as api from 'modules/checkout/payment/duck/api';

import config from 'appconfig';

import connectStateAndDispatch from 'util/react-redux/connectStateAndDispatch';
import { isStripeCheckoutModeSelected } from 'modules/checkout/payment/duck/selectors/stripe';
import { getLanguageLocale } from 'modules/i18n/duck/api';

import fetchPaymentInfo from './fetchPaymentInfo';

import { State } from 'model/State';

export { useHAMSGateway, useCommerceStripe };
export { default as processOrderAfterPaymentAuthentication } from './processOrderAfterPaymentAuthentication';
export type ProcessOrderAfterPaymentAuthenticationInput = import('./processOrderAfterPaymentAuthentication').ProcessOrderAfterPaymentAuthenticationInput;

export { isError, isException, isFailure, isSuccessful, isSystemErrorResult };

export type Stripe = Exclude<ReturnType<typeof useCommerceStripe>, undefined>;

export const handleFailures = (ccResult) => {
  if (isException(ccResult)) {
    // Should consider a better way of handling errors in the future
    throw ccResult.exception;
  }
};

// HAMS currently requries a brand but it's not used functionally, just for aesthetics
const DEFAULT_BRAND_HACK = 'VISA';

export const storePaymentDetails = (
  gateway: string,
  // eslint-disable-next-line camelcase
  { id, card, billing_details }: PaymentMethod,
) =>
  api.storage.paymentDetails.store({
    paymentMethodId: id,
    gatewayCardNumberMasked: `xxxxxxxxxxxx${card?.last4}`,
    gatewayCardName: billing_details.name,
    // eslint-disable-next-line camelcase
    gatewayCardExpiryDateMonth: card?.exp_month,
    // eslint-disable-next-line camelcase
    gatewayCardExpiryDateYear: card?.exp_year,
    gatewayCardScheme: card !== undefined ? card.brand.toUpperCase() : DEFAULT_BRAND_HACK,
    gateway,
  });

export type PostReviewReconfirmNewPaymentMethodInput = {
  uuid: string;
};

export const useConfirmPostReviewNewPaymentMethod = () => {
  const confirm = useHAMSConfirmCardPaymentWithStripe();

  const callback = useCallback(
    async (input: PostReviewReconfirmNewPaymentMethodInput) => {
      const { paymentMethodId, paymentIntentClientSecret, stripeKey } = await fetchPaymentInfo(
        input,
      );

      // Publishable key could be totally separate from the one we were originally told to use
      const newStripe = await loadStripe(stripeKey);

      if (newStripe === null) {
        throw new Error(`Unable to loadStripe with publishable key: ${stripeKey}`);
      }

      const originalPaymentDetails = api.storage.paymentDetails.load();
      const newPaymentDetails = {
        ...originalPaymentDetails,
        paymentMethodId,
      };
      api.storage.paymentDetails.store(newPaymentDetails);

      const result = await confirm(newStripe, paymentIntentClientSecret);

      handleFailures(result);

      return result;
    },
    [confirm],
  );

  return callback;
};

const BaseFormStateProvider = ({ children, language }) => (
  <LanguagePreferencesProvider languages={[language]}>
    <HAMSCreditCardFormState
      stripeKeyUrl={`${config.hamsUrl}/1.0/public/creditCard/stripeKey`}
      paymentMethodTokenUrl={`${config.hamsUrl}/1.0/public/creditCard/stripePaymentMethodToken`}
    >
      {children}
    </HAMSCreditCardFormState>
  </LanguagePreferencesProvider>
);

const FormStateProviderWhenStripeCheckoutModeSelected = ({
  children,
  isStripeCreditCardSelected,
  language,
}) => {
  const shouldRender = useAlwaysReturnTrueOnceConditionIsMet(() => isStripeCreditCardSelected);

  return shouldRender ? (
    <BaseFormStateProvider language={language}>{children}</BaseFormStateProvider>
  ) : (
    children
  );
};

const mapToProps = (state: State) => ({
  isStripeCreditCardSelected: isStripeCheckoutModeSelected(state),
  language: getLanguageLocale(),
});
export const FormStateProvider = connectStateAndDispatch(mapToProps)(
  FormStateProviderWhenStripeCheckoutModeSelected,
);
