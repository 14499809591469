// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Progress from 'redux-progress';

import { selectors, actions } from 'modules/configure';
import { StepHeader, StepWrapper } from 'modules/configure/common/Steps';
import ChooseTier from 'modules/configure/common/ChooseTier';
import ProductTitle from 'modules/configure/common/ProductTitle';
import DatacenterUpgradeMessage from 'modules/configure/tier/DatacenterUpgradeMessage';
import ProductTier from 'model/hams/ProductTier';
import type { Dispatch, State } from 'model/State';

import { makeSelectProductTiers } from 'modules/catalog/pricing';
import selectCurrency from 'modules/cart/duck/selectors/currency';
import { getProduct } from '../../../data/products';

type StateProps = {
  academic: boolean,
  currency: string,
  productKey: string,
  subscription: boolean,
  tiers: Progress<ProductTier[]>,
  tier: ProductTier | null,
  additionalTierLabel: React.ReactNode | null,
};

type DispatchProps = {
  onTierSelect: (string, ProductTier) => void,
};

type Props = StateProps & DispatchProps;

const TierStep = (props: Props) => (
  <StepWrapper>
    <StepHeader>
      <FormattedMessage
        id="purchase.choose-tier"
        values={{ product: <ProductTitle productKey={props.productKey} /> }}
      />
    </StepHeader>
    {props.additionalTierLabel}
    <div style={{ display: 'flex' }}>
      <ChooseTier
        tiers={props.tiers.result || []}
        academic={props.academic}
        selected={props.tier}
        onChange={(tier) => props.onTierSelect(props.productKey, tier)}
        showPrices={!props.subscription}
        currency={props.currency}
      />
      <div style={{ flexGrow: '1' }} />

      <DatacenterUpgradeMessage productKey={props.productKey} />
    </div>
  </StepWrapper>
);

const makeMapStateToProps = () => {
  const selectProductTiers = makeSelectProductTiers();
  const selectProductTierForProduct = selectors.makeSelectProductTierForProduct();
  return (state: State): StateProps => {
    const productKey = selectors.selectMainProductKey(state);
    return {
      productKey: (productKey: any),
      academic: selectors.isAcademic(state),
      tiers: selectProductTiers(state, { productKey }),
      currency: selectCurrency(state).result,
      tier: productKey ? selectProductTierForProduct(state, { productKey }) : null,
      subscription: selectors.isSubscription(state),
      additionalTierLabel: getProduct(productKey)
        ? getProduct(productKey).additionalTierLabel
        : null,
    };
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onTierSelect: (key, tier) => dispatch(actions.selectProductTier(key, tier)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(TierStep);
