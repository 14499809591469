// @flow

import * as Cookies from 'js-cookie';
import {
  chineseLanguage,
  defaultLanguage,
  dutchLanguage,
  frenchLanguage,
  germanLanguage,
  italianLanguage,
  japaneseLanguage,
  koreanLanguage,
  polishLanguage,
  portugueseLanguage,
  russianLanguage,
  spanishLanguage,
  supportedLanguages,
} from './model';

const langPathMap = {
  [defaultLanguage.code]: 'en_GB',
  [portugueseLanguage.locale]: 'pt_BR',
  [germanLanguage.locale]: germanLanguage.code,
  [frenchLanguage.locale]: frenchLanguage.code,
  [spanishLanguage.locale]: spanishLanguage.code,
  [russianLanguage.locale]: russianLanguage.code,
  [italianLanguage.locale]: italianLanguage.code,
  [japaneseLanguage.locale]: japaneseLanguage.code,
  [koreanLanguage.locale]: koreanLanguage.code,
  [dutchLanguage.locale]: dutchLanguage.code,
  [polishLanguage.locale]: polishLanguage.code,
  [chineseLanguage.locale]: chineseLanguage.code,
};

export const doFetchLanguage = async (lang: string) => {
  const translationFileCode = langPathMap[lang];
  /* eslint-disable import/no-dynamic-require */
  const translations = require(`../../../translations/json/${translationFileCode}.json`);

  return translations;
};

const findSupportedLanguage = (langCode) =>
  langCode &&
  (supportedLanguages.find((lang) => lang.locale === langCode) ||
    supportedLanguages.find((lang) => langCode.startsWith(lang.code)));

export const getLanguages = () =>
  [Cookies.get('__i18n'), ...(navigator.languages || []), navigator.language, defaultLanguage.code]
    .map(findSupportedLanguage)
    .filter(Boolean);

export const getLanguage = () => getLanguages()[0];

export const getLanguageLocale = () => getLanguage().locale;
