import Progress from 'redux-progress';
import * as actions from 'modules/checkout/contacts/duck/actions';
import { ValidateCountry } from 'modules/checkout/contacts/duck/model/ContactCountryValidation';

const initialState: ValidateCountry = {
  purchaserContactValidation: Progress.none,
  technicalContactValidation: Progress.none,
};

export default (state: ValidateCountry = initialState, action: any) => {
  switch (action.type) {
    case actions.FETCH_PURCHASER_EMAIL_COUNTRY_VALIDATION:
      return { ...state, purchaserContactValidation: action.progress };
    case actions.CLEAR_FETCH_PURCHASER_EMAIL_VALIDATION:
      return { ...state, purchaserContactValidation: Progress.none };
    case actions.FETCH_TECHNICAL_EMAIL_COUNTRY_VALIDATION:
      return { ...state, technicalContactValidation: action.progress };
    case actions.CLEAR_FETCH_TECHNICAL_EMAIL_VALIDATION:
      return { ...state, technicalContactValidation: Progress.none };
    default:
      return state;
  }
};
