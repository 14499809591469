import React, { useEffect, useState, useContext, Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';
import t from 'modules/i18n/intl';
import styled from 'styled-components';

import AkButton from '@atlaskit/button/standard-button';
import SectionMessage from '@atlaskit/section-message';

import { Font, Dimensions } from 'components/visuals';

import validateEmailAddress from 'util/email';

import { AuthenticationContext } from 'modules/migrate/Authenticated';
import { fetchAccount } from 'modules/migrate/search/api';
import { HamsAccountMigrationInfoWithEmail } from 'modules/migrate/comparator/AccountsTableUI';

import SearchBySenForm from 'components/search/SearchBySenForm';
import ClearItems from './ClearItems';

import { AnalyticsContext } from 'modules/migrate/analytics/AnalyticsContext';
import { comparatorEvents } from 'modules/migrate/analytics/analyticsEvents';
import { generateAnalytics } from 'modules/migrate/analytics/utils';

interface Props {
  foundBySENItems: HamsAccountMigrationInfoWithEmail[];
  itemsToDisplay: HamsAccountMigrationInfoWithEmail[];
  setFoundBySENItems: (items: HamsAccountMigrationInfoWithEmail[]) => void;
  summaryProductsNumberInitial: number;
  updateAllAmounts: Dispatch<SetStateAction<number[]>>;
  showProductsAndAppsInitial: boolean;
  setSummaryProductsNumber: Dispatch<SetStateAction<number>>;
  setShowProductsAndAppsInitial: (arg: boolean) => void;
}

const SearchSENBarWrapper = styled.div`
  font-family: ${Font.family};
  margin-bottom: ${Dimensions.units(2)}px;
`;

const FindSenLink = styled(AkButton)`
  margin: 0 auto ${Dimensions.units(2) + 3}px ${Dimensions.units(1)}px;
`;

const SearchSENContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const ErrorWrapper = styled.div`
  height: ${Dimensions.units(10)}px;
  padding-bottom: ${Dimensions.units(2)}px;
`;

const NoResult = () => (
  <SectionMessage appearance="warning" title={t('migrate.non-billing-user.search.noResults.title')}>
    <FormattedMessage id="migrate.non-billing-user.search.noResults.body" />
  </SectionMessage>
);

const Failed = () => (
  <SectionMessage
    appearance="warning"
    title={t('migrate.non-billing-user.search.invalidSearch.title')}
  >
    <FormattedMessage id="migrate.non-billing-user.search.invalidSearch.body" />
  </SectionMessage>
);

export const SearchSenBar: React.FC<Props> = ({
  foundBySENItems,
  itemsToDisplay,
  setFoundBySENItems,
  summaryProductsNumberInitial,
  setSummaryProductsNumber,
  showProductsAndAppsInitial,
  setShowProductsAndAppsInitial,
  updateAllAmounts,
}) => {
  const [result, setResult] = useState<HamsAccountMigrationInfoWithEmail | undefined | null>(
    undefined,
  );
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [resetSenForm, setResetSenForm] = useState<boolean>(false);
  const [sen, setSEN] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isSenValid, setIsSenValid] = useState<boolean>(true);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const authenticationContext = useContext(AuthenticationContext);
  const analytics = useContext(AnalyticsContext);
  const { onSearchBySenAnalytics } = generateAnalytics(analytics, comparatorEvents);

  const onSubmitSearch = async () => {
    onSearchBySenAnalytics();
    setLoading(true);
    setResult(undefined);
    setError(false);
    try {
      const newResult: HamsAccountMigrationInfoWithEmail | null = await fetchAccount({
        authenticationContext,
        accountId: (sen || '').replace(/SEN-/i, ''),
        email,
      });

      setResult(newResult);

      if (newResult) {
        const isAccountAvailable = itemsToDisplay.find((i) => i.accountId === newResult.accountId);
        if (!isAccountAvailable) {
          newResult.ownerEmail = email;
          setFoundBySENItems([...foundBySENItems, newResult]);
          setSummaryProductsNumber((prevState) => prevState + 1);
          if (!showProductsAndAppsInitial) {
            setShowProductsAndAppsInitial(true);
          }
        }
        setResetSenForm(true);
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
      setResetSenForm(false);
    }
  };

  const clearSenForm = () => {
    setEmail('');
    setSEN('');
  };

  useEffect(() => {
    if (resetSenForm) {
      clearSenForm();
    }
  }, [resetSenForm]);

  const selectIsSenValid = (senToCheck: string) => senToCheck === '' || senToCheck.length > 0;

  const onSenChange = (newSen: string) => {
    setSEN(newSen);
    setIsSenValid(selectIsSenValid(newSen));
  };

  const onClearProducts = () => {
    setFoundBySENItems([]);
    setSummaryProductsNumber(summaryProductsNumberInitial);
    foundBySENItems.forEach((item, index) => {
      updateAllAmounts((prevState) => {
        const amounts = [...prevState];
        amounts[index] = 0;
        return [...amounts];
      });
    });
  };

  const onEmailChange = (newEmail: string) => {
    setEmail(newEmail);
    setIsEmailValid(validateEmailAddress(newEmail) || newEmail === '');
  };

  const submitTitle =
    foundBySENItems.length > 0
      ? t('migrate.non-billing-user.search.addAnother')
      : t('migrate.non-billing-user.search.add');

  return (
    <SearchSENBarWrapper>
      <SearchSENContainerWrapper>
        <SearchBySenForm
          sen={sen}
          email={email}
          isSenValid={isSenValid}
          isEmailValid={isEmailValid}
          searchInProgress={loading}
          onSenChange={onSenChange}
          onEmailChange={onEmailChange}
          onSearchSubmit={() => onSubmitSearch()}
          submitTitle={submitTitle}
          labels={{
            sen: t('change.search.label.server.sen'),
            email: t('change.search.label.email'),
          }}
          isRequired
        />
        <FindSenLink
          href="https://confluence.atlassian.com/x/khSzDQ"
          target="_blank"
          appearance="link"
        >
          {t('migrate.non-billing-user.search-builder.title.link')}
        </FindSenLink>
        {Boolean(foundBySENItems.length) && <ClearItems onClearProducts={onClearProducts} />}
      </SearchSENContainerWrapper>
      {(error || result === null) && (
        <ErrorWrapper>
          {result === null && <NoResult />}
          {error && <Failed />}
        </ErrorWrapper>
      )}
    </SearchSENBarWrapper>
  );
};
