import { FormSection as AkFormSection } from '@atlaskit/form';
import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import Slide from 'modules/checkout/contacts/Slide';
import ContactInputs from 'modules/checkout/contacts/ContactInputs';
import * as styles from 'modules/checkout/contacts/styles';
import { Props } from 'modules/checkout/contacts/CheckoutContactPage';
import t from 'modules/i18n/intl';
import Help from 'components/common/Help';
import { Checkbox, Field } from 'modules/form';

// Prevent Atlaskit from fading checkbox label when disabled - Same as Technical Contact
const LabelNoFade = styled.span`
  color: ${colors.N800};
`;

export default ({ data, isExpert, inSiteConfiguratorMode }: Partial<Props>) => (
  <>
    <AkFormSection
      title={
        <>
          <Field name="isDifferentBilling" isDisabled={inSiteConfiguratorMode}>
            <Checkbox
              name="billing"
              label={
                <LabelNoFade>
                  {t('checkout.contact.add-billing-contact')}
                  <Help>{t('checkout.contact.billing-contact-info')}</Help>
                </LabelNoFade>
              }
              value="billing"
            />
          </Field>
          <Slide in={!data.isDifferentBilling} maxHeight={isExpert ? 64 : 32}>
            <>
              <styles.CheckboxSubtext>
                {t('checkout.contact.billing-contact-as-default')}
              </styles.CheckboxSubtext>
              {isExpert && (
                <styles.CheckboxSubtext>
                  {t('checkout.contact.billing-contact-atlassian-account-creation')}
                </styles.CheckboxSubtext>
              )}
            </>
          </Slide>
        </>
      }
    />

    <Slide in={data.isDifferentBilling} maxHeight={285}>
      <styles.Section>
        <ContactInputs name="billingContactDetails" lockEmail={inSiteConfiguratorMode} />
      </styles.Section>
    </Slide>
  </>
);
