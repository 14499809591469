import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Dimensions } from 'components/visuals';

import { FormattedMessage } from 'react-intl';
import Button from '@atlaskit/button/standard-button';
import ChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-up';
import { colors } from '@atlaskit/theme';

interface Props {
  title: string;
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
  children: React.ReactNode;
}

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  align-self: center;
`;

const TitleDisabled = styled(Title)`
  color: ${colors.DN500};
`;

const ChildSectionDisabled = styled.div`
  margin: ${Dimensions.units(2)}px 0;
`;

const CollapseSectionWrapper = styled.div`
  margin-bottom: ${Dimensions.units(1)}px;
  height: ${Dimensions.units(4)}px;
  padding: ${Dimensions.units(0.5)}px ${Dimensions.units(1.5)}px;
  display: flex;
  justify-content: space-between;
  color: ${colors.B500};
  background-color: ${colors.N30};
  cursor: pointer;
`;

const CollapseSectionWrapperDisabled = styled(CollapseSectionWrapper)`
  cursor: unset;
`;

const ChildSection = styled.div<{ isSectionVisible: boolean }>`
  visibility: ${(props) => (props.isSectionVisible ? 'visible' : 'hidden')};
  height: ${(props) => (props.isSectionVisible ? 'unset' : '0')};
`;

export const CollapseSection: React.FC<Props> = ({
  title,
  isShown,
  setIsShown,
  disabled,
  children,
}) => {
  const onSectionToggle = () => {
    setIsShown((prevState) => !prevState);
  };

  const CollapsedSectionEnabled = (
    <>
      <CollapseSectionWrapper onClick={onSectionToggle} data-cy-enabled>
        <Title>
          <FormattedMessage id={title} />
        </Title>
        <Button
          iconBefore={isShown ? <ChevronUpIcon label="" /> : <ChevronDownIcon label="" />}
          appearance="subtle-link"
        />
      </CollapseSectionWrapper>
      <ChildSection isSectionVisible={isShown}>{children}</ChildSection>
    </>
  );

  const CollapsedSectionDisabled = (
    <>
      <CollapseSectionWrapperDisabled onClick={onSectionToggle} data-cy-disabled>
        <TitleDisabled>
          <FormattedMessage id={title} />
        </TitleDisabled>
      </CollapseSectionWrapperDisabled>
      <ChildSectionDisabled>
        <FormattedMessage id="migrate.table.add-product-by-sen" />
      </ChildSectionDisabled>
    </>
  );

  return disabled ? CollapsedSectionDisabled : CollapsedSectionEnabled;
};
