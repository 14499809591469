// @flow
import React from 'react';
import { colors } from '@atlaskit/theme';
import styled from 'styled-components';
import TooltipComponent from 'react-tooltip-lite';

import { Font, Dimensions } from 'components/visuals';
import './Tooltip.css';

type Props = {
  direction: string,
  content: React.Node,
  children: React.Node,
  block?: boolean,
  eventOn?: string,
  eventOff?: string,
  useHover?: boolean,
  displayAsFlag?: boolean,
  distance?: number,
  contentPadding?: number,
};

const TooltipWrapper = styled(TooltipComponent)`
  display: ${(props) => (props.block ? 'block' : 'inline-block')};
`;

const TooltipContentWrapper = styled.div`
  max-width: 188px;
  font-family: ${Font.displayFamily};
  font-size: 14px;
  font-weight: 400;
  background: ${colors.N0};
  padding: ${({ contentPadding }) => `${contentPadding}px`};
`;

const Tooltip = (props: Props) => {
  let popupDistance = props.distance;
  if (!props.distance) {
    popupDistance = props.displayAsFlag ? 20 : 10;
  } else {
    popupDistance = props.distance;
  }

  return (
    <TooltipWrapper
      className={props.displayAsFlag ? 'flag-message' : ''}
      arrow={!props.displayAsFlag}
      distance={popupDistance} // 10 is the default value
      block={props.block}
      direction={props.direction}
      effect="solid"
      background={props.displayAsFlag ? colors.N0 : colors.B75}
      padding="1px"
      tipContentHover
      eventOn={props.eventOn}
      eventOff={props.eventOff}
      useHover={props.useHover}
      content={
        <TooltipContentWrapper contentPadding={props.contentPadding}>
          {props.content}
        </TooltipContentWrapper>
      }
    >
      {props.children}
    </TooltipWrapper>
  );
};

Tooltip.defaultProps = {
  eventOn: 'onMouseOver',
  eventOff: 'onMouseLeave',
  useHover: true,
  displayAsFlag: false,
  block: false,
  distance: undefined,
  contentPadding: Dimensions.units(2.5),
};

export default Tooltip;
