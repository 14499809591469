import React from 'react';
import styled from 'styled-components';

import { colors } from '@atlaskit/theme';

import { RowProps } from 'modules/change/table/duck/model';
import t from 'modules/i18n/intl';

const ExtraInfo = styled.div`
  font-size: 12px;
  color: ${colors.N200};
`;

const ExtraLicenseNotes = ({ entry }: RowProps) => {
  if (!entry.licenseType) {
    return null;
  }

  switch (entry.licenseType) {
    case 'COMMERCIAL':
      return null;
    default:
      return <ExtraInfo>{t(`license.type.${entry.licenseType.toLowerCase()}`)}</ExtraInfo>;
  }
};

export default ExtraLicenseNotes;
