// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import SelectWithExtraInfo from 'components/common/SelectWithExtraInfo';
import AmountWithCurrency from 'components/common/AmountWithCurrency';
import { Font } from 'components/visuals';
import type { TermOption } from 'model/TermOption';
import t from 'modules/i18n/intl';

type Props = {
  options: TermOption[],
  selected: TermOption | null,
  onChange: (TermOption) => void,
  currency: Progress<string>,
};

const Wrapper = styled.span`
  font-family: ${Font.family};
`;

const MonthsWrapper = styled.span`
  font-size: 16px;
`;

const TermLabel = ({ term }: { term: TermOption }) => (
  <Wrapper>
    <FormattedMessage
      id="purchase.months"
      values={{ months: <MonthsWrapper>{term.months}</MonthsWrapper> }}
    />
  </Wrapper>
);

const IncludedWrapper = styled.span`
  font-weight: 500;
`;
const Included = () => <IncludedWrapper>{t('purchase.included')}</IncludedWrapper>;

const TermsSelect = (props: Props) => (
  <SelectWithExtraInfo
    items={props.options}
    getOptionValue={(option) => option.months}
    getOptionLabel={(option) => <TermLabel term={option} />}
    getOptionExtraInfo={(option) =>
      option.amount === 0 ? (
        <Included />
      ) : (
        <AmountWithCurrency currency={props.currency} amount={option.amount} />
      )
    }
    onChange={props.onChange}
    selected={props.selected}
  />
);

export default TermsSelect;
