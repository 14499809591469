import React from 'react';

import { GridColumn } from '@atlaskit/page';
import { GridRow } from 'modules/migrate/Layout';

export const GridWrapper = ({ children }) => (
  <GridRow>
    <GridColumn medium={2} />
    <GridColumn medium={8}>{children}</GridColumn>
    <GridColumn medium={2} />
  </GridRow>
);
