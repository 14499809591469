// @flow
import React from 'react';
import {
  CloudProductKeys,
  DataCenterProductKeys,
  ProductGroupKeys,
  ServerProductKeys,
} from 'data/products/keys';
import config from 'appconfig';
import { FormattedMessage } from 'react-intl';
import ServerProduct from 'model/editions/ServerProduct';
import SoftwareProductGroup from 'model/SoftwareProductGroup';
import CloudProduct from 'model/editions/CloudProduct';
import DataCenterProduct from 'model/editions/DataCenterProduct';

export const jiraPortfolioServer = new ServerProduct({
  key: ServerProductKeys.JIRA_PORTFOLIO,
  groupKey: ProductGroupKeys.JIRA_PORTFOLIO,
  tagLine: <FormattedMessage id="products.com.radiantminds.roadmaps-jira.tagline" />,
  description: <FormattedMessage id="products.com.radiantminds.roadmaps-jira.description" />,
  name: <FormattedMessage id="products.com.radiantminds.roadmaps-jira.name" />,
  addon: true,
  moreLink: `${config.wacUrl}/software/jira/portfolio`,
  parentKeys: [ServerProductKeys.JIRA_SOFTWARE],
});

export const jiraPortfolioDatacenter = new DataCenterProduct(
  DataCenterProductKeys.JIRA_PORTFOLIO,
  jiraPortfolioServer,
);

export const jiraPortfolioCloud = new CloudProduct(
  CloudProductKeys.JIRA_PORTFOLIO,
  jiraPortfolioServer,
  [CloudProductKeys.JIRA_SOFTWARE],
  null,
  true,
);

export const jiraPortfolioProductGroup = new SoftwareProductGroup(
  jiraPortfolioServer,
  jiraPortfolioDatacenter,
  jiraPortfolioCloud,
);
