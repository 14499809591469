// @flow
import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { SubHeader } from 'modules/checkout/styled';

type Props = {
  isSelected: boolean,
  select: () => void,
  title: React.Node,
  children: any,
  testId: string,
};

const Wrapper = styled.div`
  padding: 18px;
  outline: none;
  cursor: pointer;

  &:first-child {
    border-radius: 4px 0 0 0;
  }

  ${(props) =>
    props.isSelected
      ? `
    background: ${colors.B50};
    border: 1px solid ${colors.B200};
    margin: -1px;  
    position: relative;
    
    &::after {
      content: "";
      border-bottom: none;
      border-left: none;
      border-top: 1px solid ${colors.B200};
      border-right: 1px solid ${colors.B200};
      width: 16px;
      height: 18px;
      line-height: 16px;
      right: -9px;
      top: 0;
      margin-top: auto;
      margin-bottom: auto;
      bottom: 0;
      background: ${colors.B50};
      position: absolute;
      transform: rotate(46grad);
    }`
      : `
    background: ${colors.N20};
    border-bottom: 1px solid ${colors.N50};
  `}
`;

const Description = styled.p`
  color: ${colors.N500};
  font-size: 13px;
`;

export default (props: Props) => (
  <Wrapper
    data-testid={props.testId}
    isSelected={props.isSelected}
    onClick={() => !props.isSelected && props.select()}
  >
    <SubHeader>{props.title}</SubHeader>
    <Description>{props.children}</Description>
  </Wrapper>
);
