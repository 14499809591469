// @flow
import { thunkProgress } from 'redux-progress';

import { selectors } from 'modules/marketplace/recommended';
import type { Dispatch, GetState } from 'model/State';
import { fetchRecommendedAppsFromMarketplace } from 'modules/catalog/addons';

export const FETCH_RECOMMENDED = 'marketplace/recommended/fetch';

export const fetch = () => (dispatch: Dispatch, getState: GetState) =>
  dispatch(
    thunkProgress(
      FETCH_RECOMMENDED,
      fetchRecommendedAppsFromMarketplace(selectors.selectedProduct(getState())),
    ),
  );
