import React from 'react';
import { connect } from 'react-redux';

import { State } from 'model/State';
import RedirectToProductList from './components/redirectToProductList';
import MACPageHeader from './components/macPageHeader';

type Props = {
  accountId: string;
};

const PaymentDetailsPage = (props: Props) => (
  <>
    <RedirectToProductList />
    <MACPageHeader />
    MAC PaymentDetails PAGE
    <br />
    Account ID: {props.accountId}
  </>
);

const mapStateToProps = (state: State) => ({
  accountId: state.location.payload.accountId,
});

export const MACPaymentDetailsPage = connect(mapStateToProps, null)(PaymentDetailsPage);
