// @flow
/* eslint-disable import/no-duplicates */
import categories from 'data/categories';
import type { PropsSelector } from 'modules/tracking/selectors/props';
import Product from 'model/Product';
import type { State } from 'model/State';

const productCategories = {
  ...categories.map((category) => ({
    ...category.products.map((p) => ({ [p]: category })),
  })),
};

export const mapProduct = (product: Product, position: number = 1) => ({
  name: product.name,
  category: (productCategories[product] || {}).name,
  sku: product.key,
  vendor: product.vendor,
  position,
  type: product.type,
});

export const product = (...products: Product[]): PropsSelector => () => ({
  product: (products.map((p, index) => mapProduct(p, index + 1)): any[]),
});

export const allProducts: PropsSelector = product(
  ...categories.reduce((arr, c) => [...arr, ...c.products], []),
);

export const getProductKey = (s: State) =>
  s.location && s.location.payload && s.location.payload.productKey;

export const getProductSEN = (s: State) =>
  s.location && s.location.query && s.location.query.accountId;
