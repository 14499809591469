import * as React from 'react';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';
import { BindProps } from 'modules/form/BindFieldInput';
import Warning from '@atlaskit/icon/glyph/warning';
import { Dimensions } from '../../../components/visuals';
import { colors } from '@atlaskit/theme';

// PUR-17957
// Atlaskit FieldText has a typo in its style (missing an '&' before [Disabled] section).
// That section is necessary for Safari's correct rendering when the input is disabled.
// Otherwise the text disappears. This is a workaround to fix that.
const FixStyleBug = styled.span`
  input[disabled] {
    -webkit-text-fill-color: unset;
    -webkit-opacity: 1;
  }
`;

const IconWrapper = styled.div`
  display: inline-block;
  margin-right: ${Dimensions.units(1)}px;
`;

export default ({
  field,
  akRef,
  onValidation,
  setIsFieldEmpty,
  disabled,
  ...props
}: BindProps<typeof Textfield>) => {
  const isInvalid = field.invalidErrorMessage
    ? !!field.invalidErrorMessage
    : !field.validity.isValid;

  const isDisabled = disabled || props.isDisabled;

  const handleChange = ({ target: { value } }) => {
    field.edit(value);
    if (onValidation) {
      onValidation(value);
    }
    if (setIsFieldEmpty) {
      setIsFieldEmpty(!value);
    }
  };

  return field ? (
    <FixStyleBug>
      <>
        <Textfield
          {...props}
          ref={akRef}
          isInvalid={isInvalid}
          value={field.value}
          isDisabled={isDisabled}
          onChange={handleChange}
          // Atlaskit <Field> overrides onBlur of its child component.
          // I.e. you can't set onBlur on FieldText. So make sure our wrapper overrules it here.
          onBlur={field.onBlur}
          elemAfterInput={
            isInvalid ? (
              <IconWrapper>
                <Warning label="Warning icon" primaryColor={colors.Y300} />
              </IconWrapper>
            ) : null
          }
          // Atlaskit <TextField> fires native html input validation. Disabled to keep existing form validation
          isRequired={false}
        />
      </>
    </FixStyleBug>
  ) : null;
};
