import React from 'react';
import styled from 'styled-components';
import t from 'modules/i18n/intl';
import { Dimensions, Font } from 'components/visuals';
import config from 'appconfig';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import Info from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { State } from 'model/State';
import MACPageHeader from 'modules/mac/components/macPageHeader';

const InfoSectionMessage = styled.div`
  font-family: ${Font.displayFamily};
  margin-bottom: ${Dimensions.units(4)}px;
  margin-top: ${Dimensions.units(3)}px;

  > * + * {
    margin-top: ${Dimensions.units(3)}px;
  }
`;

const infoIcon = () => <Info label="Info icon" primaryColor={colors.P200} />;

type Props = {
  name?: string;
};

const MACWarningMessage = ({ name }: Props) => {
  const manageMacLinkText = t('mac.top.message.greeting.link');
  const manageMacLinkUrl = `${config.wacUrl}/licensing/purchase-licensing#manage-account`;

  const warningMessageTitle = t('mac.warning.message.title');
  const warningMessageDescription = t('mac.warning.message.description');

  const exploreCloudLinkText = t('mac.warning.message.explore-cloud.link');
  const exploreCloudLinkUrl = `${config.wacUrl}/migration/assess/journey-to-cloud`;

  const exploreCloudPricingText = t('mac.warning.message.explore-cloud-pricing.link');
  const exploreCloudPricingUrl = `${config.wacUrl}/migration/assess/calculate-pricing`;

  const exploreDCPricingText = t('mac.warning.message.explore-dc-pricing.link');
  const exploreDCPricingUrl = `${config.wacUrl}/purchase/upgrade/datacenter`;

  return (
    <InfoSectionMessage>
      <MACPageHeader />
      <p>
        <FormattedMessage id="mac.top.message.greeting.text" values={{ name }} />
        &nbsp;
        <a href={manageMacLinkUrl}>{manageMacLinkText}</a>
      </p>
      <SectionMessage
        appearance="discovery"
        icon={infoIcon}
        title={warningMessageTitle}
        actions={[
          <SectionMessageAction key="explore-cloud" href={exploreCloudPricingUrl}>
            {exploreCloudPricingText}
          </SectionMessageAction>,

          <SectionMessageAction key="explore-dc" href={exploreDCPricingUrl}>
            {exploreDCPricingText}
          </SectionMessageAction>,
        ]}
      >
        {warningMessageDescription}
        &nbsp;
        <a target="_blank" rel="noopener noreferrer" href={exploreCloudLinkUrl}>
          {exploreCloudLinkText}
        </a>
      </SectionMessage>
    </InfoSectionMessage>
  );
};

export default connect(
  (state: State) => ({
    name: state.auth.currentContact.result?.contactDetails?.firstName,
  }),
  null,
)(MACWarningMessage);
