/* eslint-disable react/jsx-no-target-blank */
// @flow
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import SectionMessage from '@atlaskit/section-message';
import { ProductGroupKeys, SupportProductKeys } from 'data/products/keys';
import { Dimensions, Font } from '../../components/visuals';
import config from 'appconfig';
import t from 'modules/i18n/intl';
import SupportProduct from 'model/SupportProduct';
import SupportProductGroup from 'model/SupportProductGroup';
import styled from 'styled-components';
import { checkFeatureGate, FeatureFlags } from '../../util/statsig';

type PropsType = {
  supportKeyName: string,
  descriptionKeyName: string,
  productKey: string,
};

export const NewNotificationSectionWrapper = styled.div`
  width: 480px;
  font-family: ${Font.family};
  margin-top: ${Dimensions.unit * 3}px;
`;

const cloudAccessLink = `${config.wacUrl}/enterprise/cloud/access`;
const moreLink =
  'https://confluence.atlassian.com/display/Support/Priority+Support+Server+Offering+Details';
const endOfSupportLink = 'https://confluence.atlassian.com/migration/assess/journey-to-cloud';

const FormattedDescriptionMessage = ({
  supportKeyName,
  descriptionKeyName,
  productKey,
}: PropsType) => {
  const isNewDescription = descriptionKeyName === 'longDescription';
  const isServerEndOfSupportBanner = productKey === SupportProductKeys.PRIORITY_SUPPORT_SERVER;

  return isNewDescription ? (
    <>
      {isServerEndOfSupportBanner && (
        <>
          <NewNotificationSectionWrapper>
            <SectionMessage appearance="information">
              <FormattedMessage
                id="purchase.server-product.priority-support"
                values={{
                  link: (
                    <>
                      <br />
                      <a href={endOfSupportLink} target="_blank">
                        {t('purchase.server-product.priority-support-link')}
                      </a>
                    </>
                  ),
                }}
              />
            </SectionMessage>
          </NewNotificationSectionWrapper>
          <br />
        </>
      )}
      <FormattedMessage
        id="products.priority-support.server.longDescription-p2"
        values={{
          link: (
            <a href={moreLink}>{t('products.priority-support.server.longDescription-p2-link')}</a>
          ),
        }}
      />
    </>
  ) : (
    <FormattedMessage id={`products.priority-support.${supportKeyName}.${descriptionKeyName}`} />
  );
};

const DescriptionComponent = connect((state) => ({
  supportKeyName: 'server',
  productKey: state.configure?.product.productKey,
}))(FormattedDescriptionMessage);

const DeploymentsNoteComponent = () => {
  const isAccessRebrandingFFOn = checkFeatureGate(
    FeatureFlags.CART_RENAME_ATLASSIAN_ACCESS_TO_GUARD,
  );

  return isAccessRebrandingFFOn ? (
    <span>
      {t('purchase.cloud-product.priority-support.guard')}
      &nbsp;
      <a href={cloudAccessLink} target="_blank">
        <strong>{t('purchase-learn-more-about-guard')}</strong>
      </a>
    </span>
  ) : (
    <span>
      {t('purchase.cloud-product.priority-support')}
      &nbsp;
      <a href={cloudAccessLink} target="_blank">
        <strong>{t('purchase-learn-more-about-atlassian-access')}</strong>
      </a>
    </span>
  );
};

const props = {
  groupKey: ProductGroupKeys.LEGACY_PRIORITY_SUPPORT,
  tagLine: t('products.priority-support.server.tagline'),
  description: <DescriptionComponent descriptionKeyName="description" />,
  name: t('products.priority-support.server.name'),
  longDescription: <DescriptionComponent descriptionKeyName="longDescription" />,
  deploymentsNote: <DeploymentsNoteComponent />,
  moreLink,
};

export const prioritySupportServerBitbucket = new SupportProduct({
  ...props,
  groupKey: ProductGroupKeys.PRIORITY_SUPPORT_BITBUCKET,
  key: SupportProductKeys.PRIORITY_SUPPORT_SERVER,
});

export const prioritySupportServerBamboo = new SupportProduct({
  ...props,
  groupKey: ProductGroupKeys.PRIORITY_SUPPORT_BAMBOO,
  key: SupportProductKeys.PRIORITY_SUPPORT_SERVER,
});

export const prioritySupportDatacenterBitbucket = new SupportProduct({
  ...props,
  groupKey: ProductGroupKeys.PRIORITY_SUPPORT_BITBUCKET,
  key: SupportProductKeys.PRIORITY_SUPPORT_DATA_CENTER_BITBUCKET,
  enableProductSelect: true,
});

export const prioritySupportDatacenterBamboo = new SupportProduct({
  ...props,
  groupKey: ProductGroupKeys.PRIORITY_SUPPORT_BAMBOO,
  key: SupportProductKeys.PRIORITY_SUPPORT_DATA_CENTER_BAMBOO,
  enableProductSelect: true,
});

export const prioritySupportProductGroupWithBitbucket = new SupportProductGroup(
  prioritySupportServerBitbucket,
  prioritySupportDatacenterBitbucket,
);

export const prioritySupportProductGroupWithBamboo = new SupportProductGroup(
  prioritySupportServerBamboo,
  prioritySupportDatacenterBamboo,
);
