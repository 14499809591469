import React from 'react';
import styled from 'styled-components';
import { GridColumn } from '@atlaskit/page';
import { GridRow } from 'modules/migrate/Layout';
import {
  DeploymentAndBillingCycleContextProvider,
  BillingCycleSwitch,
  DeploymentSwitch,
} from 'modules/migrate/comparator/Switchers';
import { GridWrapper } from '../search/Visuals';
import PageHeader, { PageIntroLine } from '../PageHeader';

const SwitchersWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const PageHeadingWrapper = styled.div`
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(217, 250, 255, 0.5) 79.17%,
    rgba(179, 245, 255, 0.5) 100%
  );
`;

const TableWrapper = ({ children }) => (
  <DeploymentAndBillingCycleContextProvider>
    <PageHeadingWrapper>
      <PageHeader />
      <PageIntroLine />
    </PageHeadingWrapper>
    <GridRow>
      <GridColumn medium={3} />
      <GridColumn medium={6}>
        <SwitchersWrapper id="comparison-table">
          <DeploymentSwitch />
          <BillingCycleSwitch />
        </SwitchersWrapper>
      </GridColumn>
      <GridColumn medium={3} />
    </GridRow>
    <GridWrapper>{children}</GridWrapper>
  </DeploymentAndBillingCycleContextProvider>
);

export default TableWrapper;
