import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { Dimensions, Font } from 'components/visuals';

export const Cell = styled.td`
  text-align: left;
  font-weight: 500;

  &:last-child {
    text-align: right;
  }
`;

export const TotalPrice = styled.div`
  color: ${colors.N500};
  font-family: ${Font.displayFamily};
  font-size: 12px;
  letter-spacing: 1px;
  margin-top: ${Dimensions.units(1)}px;
  text-transform: uppercase;
`;

export const ParentItemWrapper = styled.tbody`
  border-bottom: 1px solid ${colors.N40};

  &:nth-last-child(2) {
    border-bottom: 2px solid ${colors.N40};
  }
`;
