// @flow
import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import { Dimensions, Font } from 'components/visuals';

export const Header = styled.h2`
  font-family: ${Font.displayFamily};
  font-size: 24px;
  font-weight: 500;
  color: ${colors.N700};
  margin-bottom: ${Dimensions.units(4)}px;
`;

export const Subheader = styled.h3`
  font-family: ${Font.displayFamily};
  font-size: 20px;
  font-weight: 500;
  color: ${colors.N700};
  margin-bottom: ${Dimensions.units(3)}px;
`;

export const Section = styled.section`
  margin-bottom: ${Dimensions.units(3)}px;
`;

const IconButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
`;

export const EditButton = (props: any) => (
  <IconButton {...props}>
    <EditFilledIcon size="small" />
  </IconButton>
);

export const Column = styled.div`
  max-width: 355px;
`;
