// @flow
import { thunkProgress } from 'redux-progress';
import type { Dispatch, GetState, State } from 'model/State';
import { selectHamsClientConfig } from 'modules/auth/selectors';
import {
  doCheckCrossgradeAvailable,
  fetchMigrateOptionsForAccount,
  checkMigrateAvailableForUser,
} from 'modules/change/landing/duck/api';

export const CHECK_CROSSGRADE_AVAILABLE = 'change/crossgrade/CHECK_AVAILABLE';
export const CHECK_MIGRATE_AVAILABLE = 'change/migrate/CHECK_AVAILABLE';
export const FETCH_ACCOUNT_MIGRATE_OPTIONS = 'change/migrate/FETCH';
export const UPGRADE_ROUTE = 'upgrade/ROUTE';

const fetchCrossgradeAvailable = (state: State) =>
  doCheckCrossgradeAvailable({
    accountId: state.location.query.accountId || state.location.query.partnerAccountId,
    clientConfig: selectHamsClientConfig(state),
  });

export const checkCrossgradeAvailable = () => (dispatch: Dispatch, getState: GetState) =>
  dispatch(thunkProgress(CHECK_CROSSGRADE_AVAILABLE, fetchCrossgradeAvailable(getState())));

export const fetchAccountMigrateOptions = (accountId: string) => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  if (!accountId) {
    return dispatch(
      thunkProgress(
        CHECK_MIGRATE_AVAILABLE,
        checkMigrateAvailableForUser(selectHamsClientConfig(getState())),
      ),
    );
  }

  const { cloudUpgradeAvailable, accountMigrateOptions } = await fetchMigrateOptionsForAccount(
    accountId,
    selectHamsClientConfig(getState()),
  );

  dispatch(
    thunkProgress(FETCH_ACCOUNT_MIGRATE_OPTIONS, accountMigrateOptions, { payload: { accountId } }),
  );

  return dispatch(thunkProgress(CHECK_MIGRATE_AVAILABLE, cloudUpgradeAvailable));
};
