// @flow
import { redirect } from 'redux-first-router';
import { selectPurchaseMode } from 'modules/settings/purchaseMode';
import * as additionalDetails from 'modules/checkout/additionalDetails';
import { CheckoutModes } from 'modules/checkout/payment/duck/model';
import { checkoutMode } from 'modules/checkout/payment/duck/api/storage';
import { fetchEligibleAutoRenewal } from 'modules/checkout/payment/duck/actions/fetchEligibleAutoRenewal';
import {
  selectDefaultMode,
  selectMode,
  clearMode,
} from 'modules/checkout/payment/duck/actions/selectMode';
import { goToProductList } from 'actions';
import type { Dispatch, GetState } from 'model/State';
import * as quote from 'modules/checkout/quote/duck/actions';
import watchProgress from 'model/watchProgress';
import * as contacts from 'modules/checkout/contacts';
import { isContactStepComplete } from 'modules/checkout/steps';
import { goToLogin } from 'modules/auth/actions';
import { selectXsrfToken } from 'modules/auth/selectors';

import { fetchCheckoutMethodsAvailable } from './checkoutMethodsAvailable';

export const ROUTE = 'checkout/payment/ROUTE';

export const route = (
  params: {
    cardError?: boolean,
    ccErrorMessage?: string,
    missingPoNumber?: boolean,
    uuid?: string,
  } = {},
) => ({
  type: ROUTE,
  meta: {
    query: params,
  },
});

const isAvailable = (mode, availability) => {
  switch (mode) {
    case CheckoutModes.PAYPAL:
      return availability.paypal;
    case CheckoutModes.CREDIT_CARD:
      return availability.tns;
    case CheckoutModes.PAY_ON_ACCOUNT:
      return availability.onAccount;
    case CheckoutModes.PAY_ON_TERMS:
      return availability.onTerms;
    case CheckoutModes.BANK_TRANSFER:
      return availability.quote;
    case CheckoutModes.STRIPE_CREDIT_CARD:
      return availability.stripe;
    default:
      return false;
  }
};

const selectDefaultModeDeprecated = () => selectMode(CheckoutModes.PAYPAL);

export const routeThunk = () => async (dispatch: Dispatch, getState: GetState) => {
  if (selectPurchaseMode(getState()) === 'QUOTE') {
    return dispatch(redirect(quote.route()));
  }

  if (!isContactStepComplete(getState())) {
    return dispatch(redirect(contacts.actions.route()));
  }

  const cart = await watchProgress((state) => state.cart.payload);

  if (!cart || cart.items.length === 0) {
    return dispatch(redirect(goToProductList()));
  }

  if (getState().location.query && getState().location.query.authenticated === 'true') {
    const xsrf = await watchProgress(selectXsrfToken);
    if (!xsrf) {
      return goToLogin();
    }
  }

  let currentMode = checkoutMode.load();
  if (!currentMode) {
    await dispatch(selectDefaultModeDeprecated());
  }

  const paymentMethodsPromise = dispatch(fetchCheckoutMethodsAvailable(cart.uuid)).then(
    (availablePaymentMethods) => {
      if (currentMode && !isAvailable(currentMode, availablePaymentMethods.result)) {
        currentMode = undefined;
        dispatch(clearMode());
      }
      if (!currentMode) {
        dispatch(() => selectDefaultMode(availablePaymentMethods.result));
      }
    },
  );

  dispatch(
    additionalDetails.edit({
      poNumber: cart.purchaseOrderNumber || '',
      hasCoTermRequest: false,
      hasTaxExemptRequest: false,
    }),
  );

  return Promise.all([dispatch(fetchEligibleAutoRenewal(cart.uuid)), paymentMethodsPromise]);
};
