import React from 'react';
import LoadingButton from '@atlaskit/button/loading-button';
import { FormattedMessage } from 'react-intl';

import { getMessage } from 'modules/migrate/analytics/messages';
import { UIAction, UIActionSubject } from 'modules/analytics/model/UIEvent';

import { IAnalyticsContext } from 'modules/migrate/analytics/AnalyticsContext';

interface Props {
  loadNextPage: () => void;
  loading: boolean;
  analytics: IAnalyticsContext;
}

const LoadMore: React.FC<Props> = ({ loadNextPage, loading, analytics }) => (
  <LoadingButton
    onClick={() => {
      loadNextPage();

      analytics.sendUiEvent({
        action: UIAction.Clicked,
        actionSubject: UIActionSubject.Button,
        attributes: {
          eventContainer: 'comparatorCosts',
          uuid: '904730f5-8e0c-4204-9182-98a0e6e58c0a',
          parentLabel: getMessage('migrate.comparator.title'),
          label: getMessage('migrate.table.show-more'),
        },
      });
    }}
    isLoading={loading}
  >
    <FormattedMessage id="migrate.table.show-more" />
  </LoadingButton>
);

export default LoadMore;
