import Progress from 'redux-progress';
import { selectProductKeys } from 'modules/addon/duck/selectors/productKeys';
import { Selector } from 'model/State';
import { HamsProductPricing } from 'model/hams/Pricing';
import { selectProductPricing } from 'modules/catalog/pricing';

export const selectAddonsPricing: Selector<Progress<(HamsProductPricing | null)[]>> = (state) => {
  const keys = selectProductKeys(state);
  const pricings: Progress<HamsProductPricing | null>[] = keys.map((productKey) =>
    selectProductPricing(state, { productKey }),
  );
  // $FlowFixMe flow doesn't get Progress.all
  return Progress.all(...pricings);
};
