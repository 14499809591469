import { UIAction, UIActionSubject } from 'modules/analytics/model/UIEvent';

export enum AnalyticsTrigger {
  SecondaryMenu = 'secondaryMenu',
}

export const analyticsEvents = {
  [AnalyticsTrigger.SecondaryMenu]: (analytics) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'compareCostsButton',
      attributes: {
        eventContainer: 'compareCostsCTA',
        eventComponent: UIActionSubject.Button,
        parentLabel: 'compareCostsCTA',
        label: 'compareCostCheck',
        uuid: '7bb558cb-090d-47bf-b8d9-d9a810fea0b0',
      },
    });
  },
};
