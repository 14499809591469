// @flow
import cookies from 'js-cookie';
import AtlStorage from 'api/AtlStorage';

const migrate = (key, storage) => {
  const val = storage.load();
  if (val) {
    cookies.set(key, val, { expires: 3650, path: '/purchase' });
  }
};

export default () => {
  migrate('cart_progress', new AtlStorage('btf.checkout.progress', window.sessionStorage));
};
