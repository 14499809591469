import React from 'react';
import Progress from 'redux-progress';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { Dimensions, Font } from 'components/visuals';
import t from 'modules/i18n/intl';
import { Radio } from '@atlaskit/radio';
import { Header } from '../../SiteConfigurator';
import { AnalyticsTrigger } from '../../analytics';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { CartItemDescriptionDiv } from '../../table/AppInfoRow';
import { getCloudParentItem } from '../../utils';
import { formattedDatePeriod } from 'components/common/MaintenancePeriod';
import { HamsCart } from 'modules/cart/duck/model';

type DispatchProps = {
  selectedBillingPeriod: number;
  selectedAnnualBillingCart: Progress<HamsCart | null>;
  selectedBiennialBillingCart: Progress<HamsCart | null>;
  onChangeBillingPeriod: (billingPeriod: number) => void;
  setAnalyticsTrigger: (trigger: string) => void;
  annualBillingCartError: boolean;
  biennialBillingCartError: boolean;
};

const StyledSection = styled.section`
  margin: ${Dimensions.units(2)}px 0 ${Dimensions.units(3)}px;
  font-family: ${Font.displayFamily};
`;

const PeriodSelectSection = styled.div`
  display: flex;
`;

const PeriodSelectItem = styled.div`
  width: 50%;
`;

const PeriodDatesDiv = styled(CartItemDescriptionDiv)`
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 15px;
  margin: ${Dimensions.units(1)}px 0 ${Dimensions.units(3)}px ${Dimensions.units(0.5)}px;
`;

const StyledContent = styled.p`
  color: #6d778a;
  margin-left: ${Dimensions.units(0.5)}px;
`;

const ErrorMessageContent = styled.p`
  color: ${colors.red};
`;

const CartError = () => (
  <ErrorMessageContent data-cy="error-message">
    <ErrorIcon size="small" label={t('annualbilling.error.update-cart-message')} />
    <FormattedMessage id="annualbilling.error.update-cart-message" />
  </ErrorMessageContent>
);

const AnnualBillingPeriod = ({
  selectedAnnualBillingCart,
  annualBillingCartError,
  selectedBiennialBillingCart,
  biennialBillingCartError,
  selectedBillingPeriod,
  onChangeBillingPeriod,
  setAnalyticsTrigger,
}: DispatchProps) => {
  const annualBillingCart = selectedAnnualBillingCart?.ifSuccess((cart) => cart) || null;
  const biennialBillingCart = selectedBiennialBillingCart?.ifSuccess((cart) => cart) || null;

  const onChangeBillingPeriodHandler = (period: string) => {
    onChangeBillingPeriod(parseInt(period, 10));
    setAnalyticsTrigger(AnalyticsTrigger.BillingCycle);
  };

  const annualPeriodDates =
    annualBillingCart && biennialBillingCart
      ? formattedDatePeriod(getCloudParentItem(annualBillingCart), PeriodDatesDiv)
      : null;
  const biennialPeriodDates =
    annualBillingCart && biennialBillingCart
      ? formattedDatePeriod(getCloudParentItem(biennialBillingCart), PeriodDatesDiv)
      : null;

  return (
    <StyledSection>
      <h2>
        <FormattedMessage id="annualbilling.billing-period.header" />
      </h2>
      <Header>
        <FormattedMessage id="annualbilling.billing-period.billing-period" />
      </Header>
      <PeriodSelectSection>
        <PeriodSelectItem>
          <Radio
            isChecked={selectedBillingPeriod === 1}
            value="1"
            label={t('common.year', { year: 1 })}
            name="billingPeriod"
            onChange={() => onChangeBillingPeriodHandler('1')}
          />
          {annualPeriodDates}
        </PeriodSelectItem>
        <PeriodSelectItem>
          <Radio
            isChecked={selectedBillingPeriod === 2}
            value="2"
            label={t('common.year', { year: 2 })}
            name="billingPeriod"
            onChange={() => onChangeBillingPeriodHandler('2')}
          />
          {biennialPeriodDates}
        </PeriodSelectItem>
      </PeriodSelectSection>
      <StyledContent>
        <FormattedMessage id="annualbilling.billing-period.billing-note" />
      </StyledContent>
      {selectedBillingPeriod === 1 && annualBillingCartError && <CartError />}
      {selectedBillingPeriod === 2 && biennialBillingCartError && <CartError />}
    </StyledSection>
  );
};

export default AnnualBillingPeriod;
