// @flow
import Progress from 'redux-progress';
import { createSelector } from 'reselect';

import { selectMainProductKey } from 'modules/configure/duck/selectors/mainProduct';
import type { Selector } from 'model/State';
import type { Group } from 'modules/catalog/groups';
import { selectProductGroup } from 'modules/catalog/groups';
import { getProduct } from 'data/products';

export const selectDeploymentOptions: Selector<Progress<Group>> = (state) =>
  state.configure ? selectProductGroup(state, state.configure.product) : Progress.none;

export const selectDeploymentNote: Selector<string | null> = createSelector(
  selectMainProductKey,
  (productKey) => {
    const product = productKey ? getProduct(productKey) : null;
    return product ? product.deploymentsNote : null;
  },
);
