import { AxiosRequestConfig } from 'axios';
import hamsClient from 'modules/hams';
import { HamsCart } from 'modules/cart/duck/model';

export default async function updateCurrency(
  config: AxiosRequestConfig,
  cartId: string,
): Promise<HamsCart | null> {
  const r = await hamsClient.post(
    `/1.0/public/order/cart/${cartId}/updateCurrency?_=${Date.now()}`,
    undefined,
    {
      ...config,
      validateStatus: (status) => status === 404 || (status >= 200 && status < 300),
    },
  );

  if (r.status === 404) {
    return null;
  }

  return r.data;
}
