import { combineReducers } from 'redux';
import addons, { AddonsMap } from './addons';
import pricing, { PricingMap } from './pricing';
import parents, { ParentsMap } from './parents';

export type Catalog = {
  addons: AddonsMap;
  parents: ParentsMap;
  pricing: PricingMap;
};

export default combineReducers({
  addons,
  parents,
  pricing,
});
