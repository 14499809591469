import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Font } from 'components/visuals';
import { colors } from '@atlaskit/theme';

import TableRow from './TableRow';

const TableHeaderFontWrapper = styled.div`
  font-family: ${Font.displayFamily};
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.3px;
  color: ${colors.N800};
`;

const TableHeader: React.FC = () => (
  <TableHeaderFontWrapper>
    <TableRow
      item={<FormattedMessage id="migrate.comparator.table.header.item" />}
      details={<FormattedMessage id="migrate.comparator.table.header.details" />}
      numberOfUsers={<FormattedMessage id="migrate.comparator.table.header.users" />}
      plan={<FormattedMessage id="migrate.comparator.table.header.cloud-plan" />}
      pricing={<FormattedMessage id="migrate.comparator.table.header.pricing" />}
    />
  </TableHeaderFontWrapper>
);

export default TableHeader;
