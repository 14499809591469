import { getActionLink, ActionLink } from 'util/ActionLink';
import React from 'react';
import Progress from 'redux-progress';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Spinner from '@atlaskit/spinner';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { typography } from '@atlaskit/theme';
import t, { Intl } from 'modules/i18n/intl';
import { Dimensions, Font } from 'components/visuals';
import { goToProductList } from 'actions';
import { Dispatch, State } from 'model/State';

type Props = {
  continueLink: ActionLink;
  progress: Progress<void>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Message = styled.p`
  ${typography.h600};
  font-family: ${Font.family};
  margin-bottom: ${Dimensions.units(2)}px;
`;

export function VisualAddToCartPage({ continueLink, progress }: Props) {
  if (progress.failed) {
    return (
      <Intl>
        {(translate) => (
          <SectionMessage
            appearance="error"
            actions={[
              {
                text: translate('addtocart.continue'),
                href: continueLink.href,
                // @ts-ignore: Atlaskit definition for for onClick is missing e argument
                onClick: (e) => {
                  e.preventDefault();
                  continueLink.navigate();
                },
              },
            ].map(({ text, ...restAction }) => (
              <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
            ))}
          >
            <p>{t('addtocart.failed')}</p>
          </SectionMessage>
        )}
      </Intl>
    );
  }

  return (
    <Container>
      <Message>{t('addtocart.creating')}</Message>
      <Spinner interactionName="add-to-cart-page-spinner" />
    </Container>
  );
}

const mapStateToProps = (state: State) => ({
  progress: state.addToCart.progress,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  continueLink: getActionLink(dispatch, goToProductList()),
});
export default connect(mapStateToProps, mapDispatchToProps)(VisualAddToCartPage);
