// @flow
import * as React from 'react';
import t from 'modules/i18n/intl';

export const MarketplaceProductKeys = Object.freeze({
  ALL: null,
  JIRA: 'jira',
  CONFLUENCE: 'confluence',
  BITBUCKET: 'bitbucket',
  BAMBOO: 'bamboo',
  FISHEYE: 'fisheye',
  CRUCIBLE: 'crucible',
});

export type MarketplaceProductKey = $Values<typeof MarketplaceProductKeys>;

export type MarketplaceProduct = {
  key: MarketplaceProductKey,
  label: React.Node,
};

export const AllMarketplace: MarketplaceProduct = {
  key: MarketplaceProductKeys.ALL,
  label: t('marketplace.search.filter.all'),
};

export const JiraMarketplace: MarketplaceProduct = {
  key: MarketplaceProductKeys.JIRA,
  label: 'Jira',
};

export const ConfluenceMarketplace: MarketplaceProduct = {
  key: MarketplaceProductKeys.CONFLUENCE,
  label: 'Confluence',
};

export const BitbucketMarketplace: MarketplaceProduct = {
  key: MarketplaceProductKeys.BITBUCKET,
  label: 'Bitbucket',
};

export const BambooMarketplace: MarketplaceProduct = {
  key: MarketplaceProductKeys.BAMBOO,
  label: 'Bamboo',
};

export const FisheyeMarketplace: MarketplaceProduct = {
  key: MarketplaceProductKeys.FISHEYE,
  label: 'Fisheye',
};

export const CrucibleMarketplace: MarketplaceProduct = {
  key: MarketplaceProductKeys.CRUCIBLE,
  label: 'Crucible',
};

export const SearchAddonsProductOptions: MarketplaceProduct[] = [
  AllMarketplace,
  JiraMarketplace,
  ConfluenceMarketplace,
  BitbucketMarketplace,
  BambooMarketplace,
  FisheyeMarketplace,
  CrucibleMarketplace,
];

export const RecommendedAddonsProductOptions: MarketplaceProduct[] = [
  JiraMarketplace,
  ConfluenceMarketplace,
  BitbucketMarketplace,
  BambooMarketplace,
];
