import React, { useContext } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import SectionMessage from '@atlaskit/section-message';

import { SearchDispatchProps, SearchOwnProps, SearchProps } from 'modules/change/search/duck/model';
import * as actions from 'modules/change/search/duck/actions';
import { mapStateToProps as searchMapStateToProps } from 'modules/change/search/duck/selectors';
import { selectors as authSelectors } from 'modules/auth';
import { Dimensions, Font } from 'components/visuals';
import SearchBySenForm from 'components/search/SearchBySenForm';
import { Dispatch, State } from 'model/State';
import t from 'modules/i18n/intl';

import { CartAnalyticsWrapper, CartAnalyticsContext } from 'modules/analytics';
import { analyticsEvents, AnalyticsTrigger } from './analytics';

const Wrapper = styled.div`
  font-family: ${Font.family};
  margin-top: ${Dimensions.units(3)}px;
  margin-bottom: ${Dimensions.units(2)}px;
`;

const Title = styled.h4`
  font-family: ${Font.family};
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
`;

const WarningWrapper = styled.div<{ withSpace?: boolean }>`
  margin-top: ${(props) => (props.withSpace ? `${Dimensions.units(2)}px` : 0)};
`;

const NoResult = (props: SearchProps) => (
  <WarningWrapper withSpace={!!props.foundAccounts?.length}>
    <SectionMessage appearance="warning" title={props.noSearchResultsTitle}>
      {props.noSearchResultsMessage}
    </SectionMessage>
  </WarningWrapper>
);

const Failed = (props: SearchProps) => (
  <WarningWrapper withSpace={!!props.foundAccounts?.length}>
    <SectionMessage appearance="warning" title={props.invalidSearchTitle}>
      {props.invalidSearchMessage}
    </SectionMessage>
  </WarningWrapper>
);

const SearchBySenBar = (props: SearchProps) => {
  const analytics = useContext(CartAnalyticsContext);
  const { onSearchSubmit, onClearSearch, ...restProps } = props;
  return (
    <Wrapper>
      <div>
        <Title>{t('change.search.search-products-title')}</Title>
        <SearchBySenForm
          isRequired
          labels={{
            sen: t('change.search.label.sen'),
            email: t('change.search.label.email'),
          }}
          submitTitle={t('change.search.button')}
          searchInProgress={props.submitProgress.inProgress}
          canClearSearch={!!props.foundAccounts?.length}
          onSearchSubmit={() => {
            analyticsEvents[AnalyticsTrigger.SearchBySEN](analytics, {
              isPartner: props.isPartner,
            });
            onSearchSubmit();
          }}
          onClearSearch={() => {
            analyticsEvents[AnalyticsTrigger.ClearSearchBySEN](analytics, {
              isPartner: props.isPartner,
            });
            onClearSearch();
          }}
          {...restProps}
        />
        {props.submitProgress.fold({
          success: (result) => !result && <NoResult {...props} />,
          failed: () => <Failed {...props} />,
        })}
      </div>
    </Wrapper>
  );
};

const SearchBySenBarWithAnalytics = (props: SearchProps) => (
  <CartAnalyticsWrapper>
    <SearchBySenBar {...props} />
  </CartAnalyticsWrapper>
);

const mapStateToProps = (state: State) => ({
  ...searchMapStateToProps(state),
  isPartner:
    authSelectors.getUserChannel(state).ifSuccess((userChannel) => userChannel.isPartner) || false,
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: SearchOwnProps): SearchDispatchProps => ({
  onSenChange: (sen: string) => dispatch(actions.changeSen(sen)),
  onEmailChange: (email: string) => dispatch(actions.changeEmail(email)),
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBySenBarWithAnalytics);
