import { mapProductKeyToLegacyProductKey } from 'data/products/keys';
import { getSoftwareProduct, getSoftwareProductGroup } from 'data/products';
import { State } from 'model/State';
import config from 'appconfig';
import { LICENSE_STARTER } from 'model/hams/LicenseType';

function selectProductKey({ location }: State): string | null {
  const productKey = location.query?.productKey;
  return (productKey && mapProductKeyToLegacyProductKey(productKey)) || null;
}

function getProductGroup(state) {
  const productKey = selectProductKey(state);
  const product = productKey && getSoftwareProduct(productKey);
  return product && getSoftwareProductGroup(product);
}

export function selectDataCenterMarketingUrl(state: State): string {
  const group = getProductGroup(state);
  return (
    (group && group.dataCenter && group.dataCenter.moreLink) ||
    `${config.wacUrl}/enterprise/data-center`
  );
}

export function selectIsStarterLicense(state: State): boolean {
  return state.change.landing.accountMigrateOptions.fold({
    success: (account) =>
      !!account && (account.licenseType === LICENSE_STARTER || !!account.isStarter),
  });
}
