import React, { useMemo, useState } from 'react';
import CartAnalyticsClient, { AnalyticsClient } from 'modules/analytics/CartAnalyticsClient';
import { UIAction, UIActionSubject, UISource } from 'modules/analytics/model/UIEvent';

interface Options {
  action: UIAction;
  actionSubject: UIActionSubject;
  actionSubjectId?: string;
  attributes?: { [key: string]: string | string[] | number };
}

export interface ICartAnalyticsContext {
  makeSendUIEvent: (options: Options) => () => void;
  sendUiEvent: (options: Options) => void;
  setAttribute: (key: string, value: string) => void;
}

export const CartAnalyticsContext = React.createContext<ICartAnalyticsContext>({
  makeSendUIEvent: () => () => {},
  sendUiEvent: () => {},
  setAttribute: () => {},
});

export const CartAnalyticsWrapper = ({ children }) => {
  const [storedAttributes, setStoredAttributes] = useState({});
  const client: AnalyticsClient = CartAnalyticsClient.getClient();

  const context = useMemo(() => {
    const sendUiEvent = ({ action, actionSubject, actionSubjectId, attributes }: Options) => {
      client.sendUIEvent({
        action,
        actionSubject,
        actionSubjectId,
        source: UISource.Page,
        attributes: {
          eventType: 'ui',
          eventComponent: actionSubject,
          event: action,
          ...storedAttributes,
          ...attributes,
        },
      });
    };

    const setAttribute = (key, value) => setStoredAttributes({ ...storedAttributes, [key]: value });

    return {
      makeSendUIEvent: (options) => () => {
        sendUiEvent(options);
      },
      sendUiEvent,
      setAttribute,
    };
  }, [storedAttributes]);

  return <CartAnalyticsContext.Provider value={context}>{children}</CartAnalyticsContext.Provider>;
};
