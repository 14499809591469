import * as actions from 'modules/meta/notificationFlag/actions';

export default (state: any = [], action) => {
  switch (action.type) {
    case actions.SHOW_NOTIFICATION_FLAG:
      // storing an array one one element at a time for now
      // the alaskit flag notifications are stacking off the page
      // there will be no stacking until the the flag component is fixed
      return state.concat({ timeoutId: action.timeoutId, message: action.message });
    case actions.REMOVE_NOTIFICATION_FLAG:
      return state.filter((n) => n.timeoutId !== action.timeoutId);
    case actions.CANCEL_ALL_NOTIFICATION_FLAGS:
      return [];
    default:
      return state;
  }
};
