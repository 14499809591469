import Progress from 'redux-progress';

import { selectAddonsPricing } from 'modules/addon/duck/selectors/addonsPricing';
import { Selector } from 'model/State';

const selectIsSubscription: Selector<Progress<boolean>> = (state) =>
  selectAddonsPricing(state).map((pricings) => {
    const foundPricings = pricings.filter((p) => p);
    return (
      foundPricings.length > 0 &&
      !!foundPricings[0] &&
      foundPricings[0].billingType === 'SUBSCRIPTION'
    );
  });

export default selectIsSubscription;
