export type DownloadablePDF = { data: { fileContent: string; suggestedFilename: string } };

/**
 * Creates a link element with the file content as a base64 encoded string and the suggested filename.
 * Clicks the link to download the file and removes the link from the DOM.
 * @param responseWithFileData The response containing the file content and suggested filename.
 * @returns void
 */
export const downloadFile = (responseWithFileData: DownloadablePDF) => {
  if (!responseWithFileData?.data?.fileContent) return;

  const { fileContent, suggestedFilename } = responseWithFileData.data;

  const a = document.createElement('a');
  a.href = `data:application/pdf;base64,${fileContent}`;
  a.download = suggestedFilename;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
