// @flow
import { CloudProductKeys, ProductGroupKeys, ServerProductKeys } from 'data/products/keys';
import config from 'appconfig';
import t from 'modules/i18n/intl';
import ServerProduct from 'model/editions/ServerProduct';
import SoftwareProductGroup from 'model/SoftwareProductGroup';
import CloudProduct from 'model/editions/CloudProduct';

export const jiraCoreServer = new ServerProduct({
  key: ServerProductKeys.JIRA_CORE,
  groupKey: ProductGroupKeys.JIRA_CORE,
  tagLine: t('products.jira-core.tagline'),
  description: t('products.jira-core.description'),
  name: t('products.jira-core.name'),
  moreLink: `${config.wacUrl}/software/jira/core`,
});

export const jiraCoreCloud = new CloudProduct(CloudProductKeys.JIRA_CORE, jiraCoreServer);

export const jiraCoreProductGroup = new SoftwareProductGroup(jiraCoreServer, null, jiraCoreCloud);
