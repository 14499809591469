import { createAction } from 'redux-action';
import Progress from 'redux-progress';
import { Dispatch, GetState, CartThunk } from 'model/State';
import { scrollToAccount } from 'modules/change/table/duck/actions';
import { selectChangeCurrency } from 'modules/change/table/duck/selectors/currency';
import { selectAccounts } from 'modules/change/table/duck/selectors';
import watchProgress from 'model/watchProgress';
import {
  isAccountCurrencyMatchingCartCurrency,
  isSSSDisabled,
} from 'modules/change/table/duck/util';
import { AccountWithChangeOptions } from 'modules/accounts/model';

export const SUBMIT_FORM = 'change/form/SUBMIT';
export const SELECT_FORM = 'change/form/SELECT';
export const SCROLL_FORM = 'change/form/SCROLL';
export const scrollForm = (
  accountProgress: Progress<AccountWithChangeOptions>,
  isSSSAllowed: boolean,
): CartThunk => async (dispatch: Dispatch, getState: GetState) => {
  // ensure only one currency is selected
  const state = getState();
  const account = accountProgress.result;
  if (!account) {
    return account;
  }
  const accounts = selectAccounts(state);
  dispatch(scrollToAccount({ accountId: account.accountId }));

  if (
    account &&
    (accounts.failed ||
      isAccountCurrencyMatchingCartCurrency(
        account,
        account.changeOptions,
        await watchProgress(selectChangeCurrency),
      ))
  ) {
    const foundAccountsIds = (state.change.search.foundAccounts || []).map((foundAccount) =>
      foundAccount.accountId.toString(),
    );
    dispatch({
      type: SELECT_FORM,
      payload: {
        account,
        isSSSDisabled: isSSSAllowed ? isSSSDisabled(account) : false,
        foundAccountsIds,
      },
    });
  }
  return account;
};

export const CHANGE_SEN = 'change/form/sen/CHANGE';
export const CHANGE_EMAIL = 'change/form/email/CHANGE';

export const TOGGLE_FORM = 'change/form/TOGGLE';
export const RESET_FORM = 'change/form/RESET';

export const changeEmail = createAction(CHANGE_EMAIL);
export const changeSen = createAction(CHANGE_SEN);

export const toggleForm = createAction(TOGGLE_FORM);
export const resetForm = createAction(RESET_FORM);
