import React from 'react';
import styled from 'styled-components';

import { colors } from '@atlaskit/theme';

import { getProduct } from 'data/products';
import { AddonPageData } from 'modules/addon/duck/model';
import ProductHeader from 'components/common/Header';
import { Font } from 'components/visuals';
import t from 'modules/i18n/intl';

const StyledHeader = styled.h2`
  font-family: ${Font.displayFamily};
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: ${colors.N800};
`;

const getProductName = (productKey, pricing) => {
  const product = getProduct(productKey);
  return (product && product.name) || pricing.productDescription;
};

const Header = ({ data }: { data: AddonPageData }) =>
  data.productKeys.length === 1 ? (
    <ProductHeader
      productKey={data.productKeys[0]}
      productName={getProductName(data.productKeys[0], data.pricing[0])}
    />
  ) : (
    <StyledHeader>{t('addon.configure-addons')}</StyledHeader>
  );

export default Header;
