import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { colors } from '@atlaskit/theme';
// @ts-ignore
import { useIntl } from 'react-intl'; // TODO: Replace react-intl with intl-next for hook usage
import Button from '@atlaskit/button';
import config from 'appconfig';
import { State } from 'model/State';
import { Dimensions } from 'components/visuals';
import t from 'modules/i18n/intl';
import RedirectToProductList from './components/redirectToProductList';
import Table from 'components/common/Table/Table';
import TableContent, { MACTableType } from './table/TableContent';
import { selectInvoices, selectQuotes } from './duck/selectors';
import OrderSearch from './components/OrderSearch';

const OrdersTitle = styled.h2`
  margin-top: ${Dimensions.units(11)}px;
  margin-bottom: ${Dimensions.units(1.5)}px;
  font-weight: 500;
  font-size: 29px;
`;

const LinksContainer = styled.div`
  border-bottom: 1px solid ${colors.N40};
  padding: 12px 0;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const OrdersHeaderContainer = styled(LinksContainer)`
  padding-left: 9px;
  margin-bottom: ${Dimensions.units(3.625)}px;
`;

const TabsContainer = styled(LinksContainer)`
  margin-bottom: ${Dimensions.units(3.25)}px;
`;

const LinkButton = styled(Button)`
  position: relative;
  &:not(:last-child):after {
    content: '';
    position: absolute;
    right: 0;
    top: 6px;
    background-color: ${colors.N900};
    opacity: 0.4;
    width: 1px;
    height: 20px;
  }
`;
// TODO: Update links here
const orderCenterLinks = [
  {
    text: t('mac.orders.menu.pricing'),
    href: `${config.wacUrl}/licensing/purchase-licensing`,
  },
  {
    text: t('mac.orders.menu.pay-quote'),
    href: '/',
  },
  {
    text: t('mac.orders.menu.new-order'),
    href: '/',
  },
  {
    text: t('mac.orders.menu.questions'),
    href: `${config.wacUrl}/company/contact`,
  },
];

const tableHeadersOrders = [
  {
    display: 'SEN #',
    key: 'orders-sen-number',
    width: 15,
  },
  {
    display: 'Created',
    key: 'orders-created',
    width: 15,
  },
  {
    display: 'Total',
    key: 'orders-total',
    width: 15,
  },
  {
    display: 'Status',
    key: 'orders-status',
    width: 15,
  },
  {
    display: 'Actions',
    key: 'orders-actions',
    width: 40,
  },
];

const activeTabs = [
  {
    tableType: MACTableType.orders,
    label: t('mac.orders.tabs.quotes'),
  },
  {
    tableType: MACTableType.invoices,
    label: t('mac.orders.tabs.invoices'),
  },
];

const OrdersPage = () => {
  const intl = useIntl();
  const orders = useSelector((state) => selectQuotes(state as State))?.results || [];
  const invoices = useSelector((state) => selectInvoices(state as State))?.results || [];
  const [tableType, setTableType] = useState<MACTableType>(MACTableType.orders);

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'document.title.mac.orders' });
  }, []);

  const handleTableSwitch = useCallback((type) => {
    setTableType(type);
  }, []);

  const tableItems = tableType === MACTableType.orders ? orders : invoices;

  return (
    <>
      <RedirectToProductList />

      <OrdersHeaderContainer>
        <OrdersTitle> {t('mac.orders.title')} </OrdersTitle>
        <div data-cy="mac-orders-links">
          {orderCenterLinks.map((link) => (
            <LinkButton appearance="link" href={link.href} key={link.text}>
              {link.text}
            </LinkButton>
          ))}
        </div>
      </OrdersHeaderContainer>

      <TabsContainer>
        <div>
          {activeTabs.map((tab) => (
            <LinkButton
              key={tab.tableType}
              onClick={() => handleTableSwitch(tab.tableType)}
              appearance={tab.tableType === tableType ? 'link' : 'subtle-link'}
            >
              {tab.label}
            </LinkButton>
          ))}
        </div>
        <OrderSearch />
      </TabsContainer>

      <Table
        body={<TableContent items={tableItems as any[]} tableType={tableType} />}
        footers={[]}
        headers={tableHeadersOrders}
        dataAttr={tableType}
      />
    </>
  );
};

export const MACOrdersPage = connect(null, null)(OrdersPage);
