import React from 'react';
import Warning from '@atlaskit/icon/glyph/warning';
import { colors } from '@atlaskit/theme';
import Flag from '@atlaskit/flag';
import { Props } from 'modules/checkout/contacts/CheckoutContactPage';
import t from 'modules/i18n/intl';

export default ({ edit }: Partial<Props>) => (
  <Flag
    id="1"
    appearance="normal"
    actions={[
      {
        content: t('checkout.contact.warning-dont-invoice-technical-contact'),
        onClick: (e) => {
          e.preventDefault();
          edit('resellerOrder', true);
        },
      },
    ]}
    icon={<Warning label="Warning icon" primaryColor={colors.Y300} />}
    description={
      <>
        <p>{t('checkout.contact.is-user-reseller')}</p>
        <p>{t('checkout.contact.warning-send-email-to-all-emails')}</p>
      </>
    }
    title={t('checkout.contact.different-email-domains')}
  />
);
