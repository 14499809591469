import { AxiosRequestConfig } from 'axios';

import hamsClient from 'modules/hams';
import { OVERVIEW_LIMIT } from 'util/constants';

export type OverviewParams = {
  limit?: number;
  offset?: number;
  accountStatus?: string;
  productKeys?: string[];
  productType?: string[];
};

export const fetchAccounts = (config: AxiosRequestConfig) =>
  hamsClient.get('/1.0/public/account/overview', config).then((r) => r.data);

export const searchAccount = (config: AxiosRequestConfig, accountId: string, email: string) =>
  hamsClient
    .post(
      '/1.0/public/account/overview/search',
      { accountId, email },
      {
        ...config,
        validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
      },
    )
    .then((r) => (r.status === 404 ? null : r.data));

export const fetchPaginatedActiveAccounts = (
  hamsClientConfig: AxiosRequestConfig,
  params: OverviewParams = {},
) => {
  const { limit = OVERVIEW_LIMIT, offset = 0, accountStatus = 'ACTIVE', ...filter } = params;
  return hamsClient
    .post(
      `/1.0/public/account/overview/paginated?limit=${limit}&offset=${offset}`,
      { accountStatus, ...filter },
      hamsClientConfig,
    )
    .then((r) => ({
      accounts: r.data.items,
      limit: r.data.limit,
      offset: r.data.offset,
      totalItems: r.data.totalItems,
    }));
};

export const fetchCanCoTerm = (config: AxiosRequestConfig) =>
  hamsClient.get('/1.0/public/account/cotermable', config).then((r) => r.data.cotermable);
