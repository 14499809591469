// @flow
import React from 'react';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Info from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';

type Props = {
  isOpen: boolean,
  onDismissed: () => void,
  title: React.Node,
};

const NotificationFlag = (props: Props) =>
  props.isOpen && props.isOpen[0] ? (
    <FlagGroup onDismissed={props.onDismissed}>
      <Flag
        id="1"
        appearance="normal"
        icon={<Info label="Info icon" primaryColor={colors.P300} />}
        title={props.title}
      />
    </FlagGroup>
  ) : null;

export default NotificationFlag;
