// @flow
import { thunkProgress } from 'redux-progress';
import * as steps from 'modules/checkout/steps';
import type { Dispatch, GetState } from 'model/State';
import * as cartActions from 'modules/cart/duck/actions';
import { goNext } from 'modules/checkout/duck/actions';

export const SUBMIT = 'checkout/quote/SUBMIT';
export const submit = () => async (dispatch: Dispatch, getState: GetState) => {
  async function setCartDetails() {
    (
      await dispatch(
        cartActions.setCartDetails({
          ...getState().cart.payload.unwrap(),
          ...getState().checkout.additionalDetails,
        }),
      )
    ).unwrap();
  }

  return dispatch(
    thunkProgress(
      SUBMIT,
      (async () => {
        await setCartDetails();
        dispatch(steps.markComplete('payment'));
        dispatch(goNext());
      })(),
    ),
  );
};
