import Progress from 'redux-progress';

import {
  HAMS_BILLED_BY_EMAIL,
  LICENSE_BY_EMAIL,
  ARCHIVED_ACCOUNT_IDS,
  ARCHIVED_LICENSE_IDS,
} from '../actions/contactEmail/types';

import { ContactEmailAction, ContactEmailData } from '../models/contactEmail';

export const initialState = {
  hamsBilledByEmail: Progress.none,
  licenseByEmail: Progress.none,
  archivedAccountIds: Progress.none,
  archivedLicenseIds: Progress.none,
};

export default (
  state: ContactEmailData = initialState,
  action: ContactEmailAction,
): ContactEmailData => {
  switch (action.type) {
    case HAMS_BILLED_BY_EMAIL:
      return {
        ...state,
        hamsBilledByEmail: action.progress,
      };
    case LICENSE_BY_EMAIL:
      return {
        ...state,
        licenseByEmail: action.progress,
      };
    case ARCHIVED_ACCOUNT_IDS:
      return {
        ...state,
        archivedAccountIds: action.progress,
      };
    case ARCHIVED_LICENSE_IDS:
      return {
        ...state,
        archivedLicenseIds: action.progress,
      };
    default:
      return state;
  }
};
