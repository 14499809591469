import type { Selector, State as S } from 'model/State';
import { MACCustomersByEmailData } from '../models';

export const selectGetCustomerByEmail: Selector<MACCustomersByEmailData> = (state: S) =>
  state.mac.contact.getCustomerByEmail;

// TODO: Replace with an appropriate type
export const selectIsMemberOfGroup: Selector<unknown> = (state: S) =>
  state.mac.contact.isMemberOfGroup?.ifSuccess((r) => r);

export const selectIsEmail: Selector<unknown> = (state: S) =>
  state.mac.contact.isEmail?.ifSuccess((r) => r);

export const selectExternal: Selector<unknown> = (state: S) =>
  state.mac.contact.external?.ifSuccess((r) => r);
