import { createSelector } from 'reselect';
import getClientValidities from 'modules/checkout/contacts/duck/selectors/getValidity/getClientValidities';
import getHamsValidities from 'modules/checkout/contacts/duck/selectors/getValidity/getHamsValidities';
import getPurchaserLoginValidity from 'modules/checkout/contacts/duck/selectors/getValidity/getPurchaserLoginValidity';
import { FormValidity } from 'modules/form/duck/model';
import { Selector } from 'model/State';

const getValidity: Selector<FormValidity> = createSelector(
  getPurchaserLoginValidity,
  getClientValidities,
  getHamsValidities,
  (purchaserValidity, clientValidities, hamsValidities) =>
    new FormValidity({
      ...purchaserValidity,
      ...clientValidities,
      ...hamsValidities,
    }),
);

export default getValidity;
