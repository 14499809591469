import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { Dimensions, Font } from 'components/visuals';

export const StepList = styled.div`
  padding: 0;
  counter-reset: steps-counter;
`;

export const StepHeader = styled.div`
  line-height: 24px;
  margin-bottom: ${Dimensions.units(2)}px;
  font-family: ${Font.displayFamily};
  font-size: 24px;
  color: ${colors.N700};
  font-weight: 500;

  &::before {
    content: counter(steps-counter) '. ';
  }
`;

export const StepWrapper = styled.section`
  margin-left: 0;
  list-style-position: inside;
  margin-top: ${Dimensions.units(6)}px;
  counter-increment: steps-counter;

  &:first-child:last-child > ${StepHeader}::before {
    content: none;
  }
`;
