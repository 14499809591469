/* eslint-disable no-param-reassign,no-return-assign */
import { produce } from 'immer';
import { AnyAction } from 'redux';
import Progress from 'redux-progress';

import AtlStorage from 'api/AtlStorage';
import { selectCartPayload } from 'modules/cart/duck/selectors';
import { Selector, State } from 'model/State';

const storage = new AtlStorage('btf.checkout.progress', window.sessionStorage, 'cart_progress');

type CheckoutStep = 'contact' | 'payment' | 'review' | 'complete';

export type CheckoutStepsState = {
  [key in CheckoutStep]: {
    index: number;
    complete: boolean;
  };
};

export const MARK_COMPLETE = 'checkout/steps/MARK_COMPLETE';
const RESET = 'checkout/steps/RESET';
const RESET_STEPS = 'checkout/steps/RESET_STEPS';

const defaultStatuses: CheckoutStepsState = Object.freeze({
  contact: { index: 0, complete: false },
  payment: { index: 1, complete: false },
  review: { index: 2, complete: false },
  complete: { index: 3, complete: false },
});

const load = (): CheckoutStepsState => storage.load() || defaultStatuses;

export const isContactStepComplete: Selector<Progress<boolean>> = (state: State) =>
  selectCartPayload(state).map(
    (cart) =>
      !!(
        (state.checkout.steps.contact && state.checkout.steps.contact.complete) ||
        (cart && cart.quoteNumber && !cart.inEditMode)
      ),
  );

export const markComplete = (step: CheckoutStep, complete: boolean = true) => {
  const statuses = produce(load(), (draft) => {
    draft[step].complete = complete;
  });

  storage.store(statuses);
  return { type: MARK_COMPLETE, step };
};
export const reset = () => {
  storage.reset();
  return { type: RESET };
};

// the difference between this and reset function is this one does not modify/use cookies
export const resetSteps = () => {
  return { type: RESET_STEPS };
};

const reducer = (state: CheckoutStepsState = load(), action: AnyAction) => {
  switch (action.type) {
    case MARK_COMPLETE:
    case RESET:
      return load();
    case RESET_STEPS:
      return defaultStatuses;
    default:
      return state;
  }
};

export default reducer;
