import React from 'react';
import { connect } from 'react-redux';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import t from 'modules/i18n/intl';
import { Dispatch } from 'model/State';
import * as payment from 'modules/checkout/payment';
import { MessageNotificationWrapper } from './styled';

interface PayOnTermsIneligibleErrorProps {
  onPaymentDetailsEdit: () => void;
  currency: string;
}

const PayOnTermsIneligibleErrorInternal: React.FC<PayOnTermsIneligibleErrorProps> = ({
  onPaymentDetailsEdit,
  currency,
}) => {
  const actions = [
    {
      text: t('review.change-payments'),
      onClick: () => onPaymentDetailsEdit(),
      key: t('review.change-payments'),
      testId: 'change-payment-method',
    },
  ];

  const limit = currency === 'USD' ? '$10,000' : 'YEN 1,000,000';

  return (
    <MessageNotificationWrapper>
      <SectionMessage
        appearance="error"
        title={t('review.cart-ineligible-for-payonterms', {
          limit,
        })}
        actions={actions.map(({ text, ...restAction }) => (
          <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
        ))}
      >
        {t('review.cart-ineligible-for-payonterms.description')}
      </SectionMessage>
    </MessageNotificationWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onPaymentDetailsEdit: () => dispatch(payment.actions.route()),
});

export const PayOnTermsIneligibleError = connect(
  null,
  mapDispatchToProps,
)(PayOnTermsIneligibleErrorInternal);
