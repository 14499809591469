import React, { useEffect, useState } from 'react';
import Button from '@atlaskit/button/standard-button';
import ModalDialog, {
  ModalTransition,
  ModalBody,
  ModalTitle,
  ModalHeader,
  ModalFooter,
} from '@atlaskit/modal-dialog';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { Font } from 'components/visuals';

import { CLOUD, DATACENTER } from 'model/hams/DeploymentOption';
import { ServerProductDisabledKeys } from 'data/products/keys';

import config from 'appconfig';
import t from 'modules/i18n/intl';
import { Dispatch } from 'model/State';
import { emitUIEpicEvent, utils } from 'modules/analytics';
import { UISource } from 'modules/analytics/model/UIEvent';
import { getMessage } from 'modules/migrate/analytics/messages';

const advancedRoadmapsName = 'Advanced Roadmaps (formerly Portfolio)';
const jiraInsightDiscovery = 'Insight Discovery';
const jiraSoftwareCloudName = 'Jira Software Cloud';
const jiraCloudPremiumName = 'Jira Software Cloud Premium';
const jiraWorkManagement = 'Jira Work Management';
const atlassianAccess = 'Atlassian Access';
const guard = 'Guard';

interface DialogProps {
  selectedDeployment: any;
  ssModalDialogOptions: any;
  productName: string;
  className?: string;
  close?: () => void;
  onShowModal: () => void;
  onChooseButtonClick: (optionName: string, optionLabel: string) => void;
  onJourneyToCloudClick: () => void;
  isAccessRebrandingFFOn?: boolean;
}

const isAdvancedRoadmaps = (selectedDeployment) =>
  selectedDeployment === ServerProductDisabledKeys.JIRA_PORTFOLIO;

const isCrowd = (selectedDeployment) => selectedDeployment === ServerProductDisabledKeys.CROWD;

const isCloudAvailable = (props: DialogProps) => {
  const { ssModalDialogOptions } = props;
  return (
    ssModalDialogOptions[CLOUD] &&
    ssModalDialogOptions[CLOUD].productKey != null &&
    !ssModalDialogOptions[CLOUD].isDisabledCloud
  );
};

const isDCAvailable = (props: DialogProps) => {
  const { ssModalDialogOptions } = props;
  return ssModalDialogOptions[DATACENTER] && ssModalDialogOptions[DATACENTER].productKey !== null;
};

const DialogHeading = (props: DialogProps) => (
  <span className={props.className}>
    <FormattedMessage id="ss.modal.dialog.title" />
  </span>
);

const DialogMessage = (props: DialogProps) => {
  const { productName, selectedDeployment, isAccessRebrandingFFOn } = props;

  const getDefaultMessage = (product: string) => (
    <FormattedMessage
      id="ss.modal.dialog.text"
      values={{
        serverSupportEndDate: <strong>{t('ss.modal.dialog.text.support.end.date')}</strong>,
        productName: <strong>{product}</strong>,
      }}
    />
  );

  const getDefaultLink = () => (
    <a
      href={`${config.wacUrl}/migration/journey-to-cloud`}
      onClick={() => props.onJourneyToCloudClick()}
    >
      {t('ss.warning.message.link')}
    </a>
  );

  const dialogContent = {
    [ServerProductDisabledKeys.JIRA_CORE]: {
      message: (
        <>
          <FormattedMessage
            id="ss.modal.dialog.jwm.text1"
            values={{
              productName: <strong>{productName}</strong>,
              serverSupportEndDate: <strong>{t('ss.modal.dialog.text.support.end.date')}</strong>,
            }}
          />
          <p>
            <FormattedMessage
              id="ss.modal.dialog.jwm.text2"
              values={{
                productName: <strong>{productName}</strong>,
                updatedProductName: <strong>{jiraWorkManagement}</strong>,
              }}
            />
          </p>
        </>
      ),
      link: (
        <a href={`${config.wacUrl}/software/jira/work-management`} rel="noreferrer" target="_blank">
          {t('ss.warning.message.jwm.link', { productName: jiraWorkManagement })}
        </a>
      ),
    },
    [ServerProductDisabledKeys.JIRA_PORTFOLIO]: {
      message: (
        <FormattedMessage
          id="ss.modal.dialog.roadmaps.text"
          values={{
            addonName: advancedRoadmapsName,
            productName: <strong>{jiraCloudPremiumName}</strong>,
            period: 30,
          }}
        />
      ),
      link: getDefaultLink(),
    },
    [ServerProductDisabledKeys.CROWD]: {
      message: isAccessRebrandingFFOn ? (
        <FormattedMessage
          id="ss.modal.dialog.roadmaps.text"
          values={{
            addonName: productName,
            productName: <strong>{guard}</strong>,
            period: 30,
          }}
        />
      ) : (
        <FormattedMessage
          id="ss.modal.dialog.roadmaps.text"
          values={{
            addonName: productName,
            productName: <strong>{atlassianAccess}</strong>,
            period: 30,
          }}
        />
      ),
      link: getDefaultLink(),
    },
    [ServerProductDisabledKeys.JIRA_INSIGHT_DISCOVERY]: {
      message: getDefaultMessage(jiraInsightDiscovery),
      link: getDefaultLink(),
    },
    default: {
      message: getDefaultMessage(productName),
      link: getDefaultLink(),
    },
  };

  const content = dialogContent[selectedDeployment] || dialogContent.default;

  return (
    <>
      {content.message}
      <p>{content.link}</p>
    </>
  );
};

const cloudButton = (props: DialogProps) => ({
  onClick: (e) => {
    e.preventDefault();
    props.onChooseButtonClick(CLOUD, getMessage('ss.modal.dialog.button.cloud'));
    props.ssModalDialogOptions[CLOUD].onChange();
  },
  text: t('ss.modal.dialog.button.cloud'),
  className: props.className,
});

const DCButton = (props: DialogProps) => ({
  onClick: (e) => {
    e.preventDefault();
    props.onChooseButtonClick(DATACENTER, getMessage('ss.modal.dialog.button.data.center'));
    props.ssModalDialogOptions[DATACENTER].onChange();
  },
  text: t('ss.modal.dialog.button.data.center'),
  className: props.className,
});

const tryButton = (props: DialogProps, url: string, productName: string) => ({
  onClick: () => {
    props.onChooseButtonClick('try', `Try ${productName}`);
  },
  text: t('ss.modal.dialog.button.try', { productName }),
  href: `${config.wacUrl}${url}`,
  className: props.className,
});

const DialogActions = (props: DialogProps) => {
  const { selectedDeployment, isAccessRebrandingFFOn } = props;

  if (isAdvancedRoadmaps(selectedDeployment)) {
    return [
      tryButton(
        props,
        '/try/cloud/signup?bundle=jira-software&edition=premium',
        jiraSoftwareCloudName,
      ),
      DCButton(props),
    ];
  }

  if (isCrowd(selectedDeployment)) {
    return isAccessRebrandingFFOn
      ? [tryButton(props, '/software/access', guard), DCButton(props)]
      : [tryButton(props, '/software/access', atlassianAccess), DCButton(props)];
  }

  if (isCloudAvailable(props) && isDCAvailable(props)) {
    return [cloudButton(props), DCButton(props)];
  }

  if (isDCAvailable(props)) {
    return [DCButton(props)];
  }

  return [cloudButton(props)];
};

const Dialog = (props: DialogProps) => (
  <ModalDialog autoFocus shouldCloseOnEscapePress width="medium">
    <ModalHeader>
      <ModalTitle>{DialogHeading(props)}</ModalTitle>
    </ModalHeader>

    <ModalBody>
      <p className={props.className}>
        <DialogMessage {...props} />
      </p>
    </ModalBody>
    <ModalFooter>
      {DialogActions(props).map((actionProps, index) => (
        <Button
          onClick={actionProps.onClick}
          {...props}
          appearance={index === 1 ? 'primary' : 'subtle'}
        >
          {actionProps.text}
        </Button>
      ))}
    </ModalFooter>
  </ModalDialog>
);

const StyledDialog = styled(Dialog)`
  font-family: ${Font.family};
`;

const StopSellingModalDialog = (props: DialogProps) => {
  const { selectedDeployment, onShowModal } = props;

  const showModalForServerOnly = Object.values(ServerProductDisabledKeys).includes(
    selectedDeployment,
  );

  const [isOpen, setIsOpen] = useState(showModalForServerOnly);
  useEffect(() => {
    setIsOpen(showModalForServerOnly);
    // eslint-disable-next-line no-unused-expressions
    showModalForServerOnly && onShowModal();
  }, [showModalForServerOnly]);

  const onClose = () => {
    setIsOpen(false);
  };

  return <ModalTransition>{isOpen && <StyledDialog close={onClose} {...props} />}</ModalTransition>;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onShowModal: () => {
    dispatch(
      emitUIEpicEvent({
        data: utils.getShowModalData('eolModalWindow', UISource.Page),
        selectorsOrObjects: [
          utils.getAdditionalAnalyticsAttributes(
            UISource.Page,
            'eolModalWindow',
            null,
            'modal-shown',
          ),
        ],
      }),
    );
  },
  onChooseButtonClick: (optionName, optionLabel) => {
    dispatch(
      emitUIEpicEvent({
        data: utils.getButtonClickEventData('eolModalWindowButton', UISource.Modal),
        selectorsOrObjects: [
          utils.getAdditionalAnalyticsAttributes(
            UISource.Modal,
            `${optionName} Button`,
            getMessage('ss.modal.dialog.title'),
            optionLabel,
          ),
        ],
      }),
    );
  },
  onJourneyToCloudClick: () => {
    dispatch(
      emitUIEpicEvent({
        data: utils.getLinkClickEventData('eolModalWindowLink', UISource.Modal),
        selectorsOrObjects: [
          utils.getAdditionalAnalyticsAttributes(
            UISource.Modal,
            'eolModalWindowLink',
            getMessage('ss.modal.dialog.title'),
            getMessage('ss.warning.message.link'),
          ),
        ],
      }),
    );
  },
});

export default connect(null, mapDispatchToProps)(StopSellingModalDialog);
