import React, { Dispatch, SetStateAction, useContext, useState, useEffect, useMemo } from 'react';

import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { Dimensions } from 'components/visuals';

import appconfig from 'appconfig';
import {
  ServerProductKeys,
  ServerProductKey,
  PricingPlan,
  PricingPlansList,
  ServerUpgradeDisabledKeys,
} from 'data/products/keys';
import { HamsAccountMigrationInfoWithEmail } from 'modules/migrate/comparator/AccountsTableUI';
import AccountDetails from 'modules/migrate/comparator/row/AccountDetails';
import { cloudUnavailableProducts, PRICING_PLANS } from 'modules/migrate/comparator/utils';
import {
  getDefaultRecommendedPlan,
  getMaxUnitNumber,
  isEnterpriseAvailableProduct,
  isKnownProduct,
} from 'modules/migrate/comparator/row/utils';
import TableRow from '../TableRow';
import NumberOfUsers from 'modules/migrate/comparator/row/NumberOfUsers';
import RecommendedPlan from 'modules/migrate/comparator/row/RecommendedPlan';
import ServerCloudPrice from './cloud/ServerCloudPrice';
import { CloudUnavailable } from './cloud/CloudUnavailable';
import { FormattedMessage } from 'react-intl';

import { AnalyticsContext } from 'modules/migrate/analytics/AnalyticsContext';
import { comparatorEvents } from 'modules/migrate/analytics/analyticsEvents';
import { generateAnalytics } from 'modules/migrate/analytics/utils';

import { DeploymentAndBillingCycleContext } from '../Switchers';

interface Props {
  account: HamsAccountMigrationInfoWithEmail;
  productTableIndex: number;
  updateAllAmounts: Dispatch<SetStateAction<number[]>>;
}

const RowWrapper = styled.div`
  padding: ${Dimensions.units(4)}px 0;
  border-bottom: 2px solid ${colors.N30};
  color: ${colors.N900};

  &:last-child {
    border-bottom: none;
  }
`;

const ProductName = styled.div`
  font-size: 16px;
`;

const CloudProductName = styled.div`
  font-size: 12px;
  color: ${colors.N600};
`;

const SlicedCloudName = styled.strong`
  display: block;
`;

const AccountRow = ({ account, productTableIndex, updateAllAmounts }: Props) => {
  const { deployOption } = useContext(DeploymentAndBillingCycleContext);

  let unavailableMessage: React.ReactNode | null;
  let slicedCloudName: string = '';

  const {
    productName,
    productKey,
    unitCount,
    cloudProductDescription,
    available,
    freeAvailable,
    monthlyPriceStrategy,
    premiumMonthlyPriceStrategy,
    annualTiers,
    pricingLink,
    unitLabel,
    freeTierLimit,
  } = account;

  const plansList: PricingPlansList = [];
  if (freeAvailable) {
    plansList.push(PRICING_PLANS.FREE);
  }
  if (monthlyPriceStrategy?.priceBands?.length) {
    plansList.push(PRICING_PLANS.STANDARD);
  }
  if (premiumMonthlyPriceStrategy?.priceBands?.length) {
    plansList.push(PRICING_PLANS.PREMIUM);
  }
  const maxUsersTier: number = getMaxUnitNumber(annualTiers);

  const {
    defaultRecommendedPlan,
    isEnterprise,
  }: { defaultRecommendedPlan: PricingPlan; isEnterprise: boolean } = useMemo(
    () => getDefaultRecommendedPlan(freeTierLimit, unitCount, plansList, maxUsersTier),
    [],
  );

  const analytics = useContext(AnalyticsContext);
  const comparatorAnalytics = generateAnalytics(analytics, comparatorEvents);

  const [analyticsEventName, setAnalyticsEventName] = useState('');
  const [numberOfUsers, setNumberOfUsers] = useState<number>(unitCount);
  const [recommendedPlan, setRecommendedPlan] = useState<string>(defaultRecommendedPlan);

  const hasAnnualPrices = annualTiers?.length;
  const hasStandardMonthlyPrices = monthlyPriceStrategy?.priceBands?.length;

  useEffect(() => {
    if (comparatorAnalytics[analyticsEventName]) {
      comparatorAnalytics[analyticsEventName]({
        productName,
        productKey,
        numberOfUsers,
        recommendedPlan,
      });
      setAnalyticsEventName('');
    }
  }, [numberOfUsers, recommendedPlan]);

  useEffect(() => {
    if (unavailableMessage) {
      updateAllAmounts((prevState) => {
        const amounts = [...prevState];
        amounts[productTableIndex] = 0;
        return [...amounts];
      });
    }
  }, [deployOption]);

  const onSetNumberOfUsers = (users: number) => {
    setNumberOfUsers(users);
    setAnalyticsEventName('onInputNumberOfUsersAnalytics');
  };

  const onSetRecommendedPlan = (plan: string) => {
    setRecommendedPlan(plan);
    setAnalyticsEventName('onSelectPricingPlanAnalytics');
  };

  if (!hasAnnualPrices || !hasStandardMonthlyPrices) {
    unavailableMessage = <CloudUnavailable titleId="migrate.table.row.unavailable" />;
  }

  if (!available) {
    unavailableMessage = (
      <CloudUnavailable
        titleId="migrate.table.row.no-equivalent"
        link={`${appconfig.marketplaceUrl}`}
        linkId="migrate.table.row.explore-marketplace"
      />
    );
  }

  if (isKnownProduct(productKey) && !available) {
    unavailableMessage = (
      <CloudUnavailable
        titleId="migrate.table.row.not-available-yet"
        link={`${appconfig.wacUrl}/roadmap/cloud`}
        linkId="migrate.table.row.see-roadmap"
      />
    );
  }

  if (productKey === ServerProductKeys.FISHEYE || productKey === ServerProductKeys.CRUCIBLE) {
    unavailableMessage = (
      <CloudUnavailable titleId="migrate.table.row.unavailable" productKey={productKey} />
    );
  }

  if (Object.prototype.hasOwnProperty.call(cloudUnavailableProducts, productKey)) {
    unavailableMessage = (
      <CloudUnavailable
        titleId={cloudUnavailableProducts[productKey].messageKey}
        link={`${appconfig.wacUrl}/software/bitbucket/pricing`}
        linkId="migrate.table.row.bitbucket-plans-pricing"
      />
    );
  }

  if (unitCount > maxUsersTier && isEnterpriseAvailableProduct(productKey as ServerProductKey)) {
    unavailableMessage = (
      <CloudUnavailable
        titleId="migrate.table.row.enterprise-plan"
        link={`${appconfig.wacUrl}/enterprise/contact`}
        linkId="migrate.table.row.contact-us"
      />
    );
  }

  if (
    productKey === ServerUpgradeDisabledKeys.CONFLUENCE_ANALYTICS ||
    productKey === ServerProductKeys.CONFLUENCE_TEAM_CALENDARS
  ) {
    unavailableMessage = (
      <CloudUnavailable
        titleId="migrate.table.row.as-feature"
        link={`${appconfig.wacUrl}/software/confluence/premium`}
        linkText="Confluence Premium"
        orLink={`${appconfig.wacUrl}/software/confluence/enterprise`}
        orLinkText="Enterprise"
      />
    );
  }

  if (
    productKey === ServerProductKeys.JIRA_PORTFOLIO ||
    productKey === ServerUpgradeDisabledKeys.JIRA_AUTOMATION
  ) {
    unavailableMessage = (
      <CloudUnavailable
        titleId="migrate.table.row.as-feature"
        link={`${appconfig.wacUrl}/software/jira/premium`}
        linkText="Jira Software Premium"
        orLink={`${appconfig.wacUrl}/software/jira/enterprise`}
        orLinkText="Enterprise"
      />
    );
  }

  const indexOfServerWord: number =
    productName.indexOf(' (Server)') !== -1 ? productName.indexOf(' (Server)') : productName.length;
  const slicedServerName: string = productName.slice(0, indexOfServerWord);

  if (cloudProductDescription) {
    const indexOfCloudWord: number =
      cloudProductDescription.indexOf(' (Cloud)') !== -1
        ? cloudProductDescription.indexOf(' (Cloud)')
        : cloudProductDescription.length;
    slicedCloudName = cloudProductDescription?.slice(0, indexOfCloudWord);
  }

  const itemName = (
    <ProductName>
      {slicedServerName}
      {cloudProductDescription &&
        slicedServerName.toLowerCase() !== slicedCloudName.toLowerCase() &&
        !unavailableMessage && (
          <CloudProductName>
            <FormattedMessage id="migrate.table.row.available-as" />
            <SlicedCloudName>{slicedCloudName}</SlicedCloudName>
          </CloudProductName>
        )}
    </ProductName>
  );

  return (
    <RowWrapper>
      <TableRow
        item={itemName}
        details={<AccountDetails {...account} />}
        numberOfUsers={
          <NumberOfUsers
            freeTierLimit={freeTierLimit}
            recommendedPlan={recommendedPlan}
            isEnterprise={isEnterprise}
            numberOfUsers={numberOfUsers}
            setNumberOfUsers={onSetNumberOfUsers}
            unitLabel={unitLabel}
            maxUsersTier={maxUsersTier}
          />
        }
        plan={
          <RecommendedPlan
            plansList={plansList}
            unitCount={unitCount}
            setNumberOfUsers={setNumberOfUsers}
            recommendedPlan={recommendedPlan}
            setRecommendedPlan={onSetRecommendedPlan}
            pricingLink={pricingLink}
            productName={productName}
            productKey={productKey}
            numberOfUsers={numberOfUsers}
          />
        }
        pricing={
          <ServerCloudPrice
            recommendedPlan={recommendedPlan}
            account={account}
            numberOfUsers={numberOfUsers}
            productTableIndex={productTableIndex}
            updateAllAmounts={updateAllAmounts}
          />
        }
        unavailableMessage={unavailableMessage}
      />
    </RowWrapper>
  );
};

export default AccountRow;
