import React, { useContext } from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { Dimensions, Font } from 'components/visuals';

import { DeploymentAndBillingCycleContext } from 'modules/migrate/comparator/Switchers';
import { IAnalyticsContext } from '../analytics/AnalyticsContext';
import {
  CloudSlimColumn,
  CloudWideColumn,
  FooterFirstCloudColumn,
  FooterFirstServerColumn,
  ServerColumn,
  TableRowWrapper,
} from './TableRow';
import { FormattedMessage } from 'react-intl';
import LoadMore from './LoadMore';
import { BILL_CYCLE, DEPLOY_OPTION } from './utils';
import AmountWithCurrency from 'components/common/AmountWithCurrency';

type Props = {
  summaryProductsNumber: number;
  currentNumber: number;
  totalAmount: number;
  showLoadMore: boolean;
  loadNextPage: () => void;
  loading: boolean;
  analytics: IAnalyticsContext;
  currency: string;
};

const StyledAmount = styled(AmountWithCurrency)`
  font-size: ${Dimensions.units(4)}px;
  color: ${colors.N900};
  font-weight: 600;
`;

const FooterWrapper = styled(TableRowWrapper)`
  padding: ${Dimensions.units(4)}px 0 ${Dimensions.units(1)}px;
  border-bottom: 2px solid ${colors.N30};
  color: ${colors.N900};
  text-align: right;
`;

const TotalWrapper = styled.div`
  font-size: 36px;
  font-family: ${Font.displayFamily};
  font-weight: 500;
  color: ${colors.DN0};
`;

const PeriodWrapper = styled.div`
  font-size: 12px;
`;

const Bold = styled.span`
  font-weight: 900;
`;

const LoadMoreWrapper = styled.div`
  margin: ${Dimensions.units(3)}px 0;
`;

const TableFooter: React.FC<Props> = ({
  summaryProductsNumber,
  currentNumber,
  showLoadMore,
  loadNextPage,
  loading,
  analytics,
  totalAmount,
  currency,
}) => {
  const { billCycle, deployOption: optionOfDeployment } = useContext(
    DeploymentAndBillingCycleContext,
  );

  let periodMessageId;

  if (billCycle === BILL_CYCLE.MONTHLY) {
    periodMessageId = 'migrate.table.footer.per-month';
  } else if (optionOfDeployment === DEPLOY_OPTION.SERVER) {
    periodMessageId = 'migrate.table.footer.till-eol';
  } else {
    periodMessageId = 'migrate.table.footer.per-year';
  }

  const CurrentlyDisplaying = (
    <FormattedMessage
      id="migrate.table.currently-displaying"
      values={{
        currentNumber: <Bold>{currentNumber}</Bold>,
        summaryNumber: <Bold>{summaryProductsNumber}</Bold>,
      }}
    />
  );

  const TotalDisplaying = (
    <FormattedMessage
      id="migrate.table.total-displaying"
      values={{
        number: <Bold>{currentNumber}</Bold>,
      }}
    />
  );

  const TotalComponent = (
    <TotalWrapper>
      <FormattedMessage id="common.total" />
    </TotalWrapper>
  );

  const styledAmountProps = {
    format: 'code',
    currency,
    amount: totalAmount,
    useSpace: true,
  };

  const PricingColumnComponent = (
    <>
      <TotalWrapper>
        <StyledAmount {...styledAmountProps} />
      </TotalWrapper>
      <PeriodWrapper data-testid="billing.total-price">
        <FormattedMessage
          id={periodMessageId}
          values={{
            displaying: showLoadMore ? CurrentlyDisplaying : TotalDisplaying,
          }}
        />
      </PeriodWrapper>
      {showLoadMore && (
        <LoadMoreWrapper>
          <LoadMore loadNextPage={loadNextPage} loading={loading} analytics={analytics} />
        </LoadMoreWrapper>
      )}
    </>
  );

  return (
    <DeploymentAndBillingCycleContext.Consumer>
      {({ deployOption }) => (
        <FooterWrapper>
          {deployOption === DEPLOY_OPTION.CLOUD ? (
            <>
              <FooterFirstCloudColumn />
              <CloudSlimColumn>{!showLoadMore && TotalComponent}</CloudSlimColumn>
              <CloudWideColumn>{PricingColumnComponent}</CloudWideColumn>
            </>
          ) : (
            <>
              <FooterFirstServerColumn />
              <ServerColumn>{!showLoadMore && TotalComponent}</ServerColumn>
              <ServerColumn>{PricingColumnComponent}</ServerColumn>
            </>
          )}
        </FooterWrapper>
      )}
    </DeploymentAndBillingCycleContext.Consumer>
  );
};

export default TableFooter;
