import { CART_DELETE_ITEM } from 'modules/cart/duck/actions/removeItems';

export default (state: number[] = [], action: any) => {
  if (action.type === CART_DELETE_ITEM) {
    if (action.progress.inProgress) {
      return [...state, ...action.payload.itemIds];
    }
    return state.filter((i) => action.payload.itemIds.indexOf(i) === -1);
  }
  return state;
};
