import React from 'react';
import styled from 'styled-components';
import { Dimensions } from 'components/visuals';
import { HamsCartItem } from 'modules/cart/duck/model';
import { OrderableItem } from 'model/hams/Pricing';
import { ParentItemWrapper } from 'components/common/Table/Wrappers';
import { ProductInfoRow } from './ProductInfoRow';
import { AppInfoRow } from './AppInfoRow';

type Props = {
  currency: string;
  cartProductItems: HamsCartItem[];
  cartAppItems: HamsCartItem[];
  setSelectedEdition: (string) => void;
  updateItem: (cartItem: HamsCartItem, editionTiers: OrderableItem[], unitCount: string) => void;
  editionsAndTiersPerRow: { [cartItemId: number]: { [editionName: string]: OrderableItem[] } };
  appTierMapping: { [cartItemId: number]: OrderableItem[] };
  selectedEdition: string;
  isCartItemOriginalEditionStandard: (cartItemId: number) => boolean;
  getOriginalTierCount: (cartItemId: number) => number;
  setAnalyticsTrigger: (trigger: string) => void;
};

const StyledDetailsCell = styled.td`
  padding: ${Dimensions.units(3)}px 0 ${Dimensions.units(2)}px;
  color: #707070;
  font-size: 15px;
`;

export const AnnualBillingTableBody = ({
  currency,
  cartProductItems,
  cartAppItems,
  updateItem,
  editionsAndTiersPerRow,
  appTierMapping,
  isCartItemOriginalEditionStandard,
  getOriginalTierCount,
  setAnalyticsTrigger,
}: Props) => {
  return (
    <ParentItemWrapper>
      {cartProductItems.map(
        (cartItem) =>
          cartItem.accountId && (
            <ProductInfoRow
              key={cartItem.id}
              currency={currency}
              cartItem={cartItem}
              editionTierMapping={editionsAndTiersPerRow[cartItem.id]}
              updateItem={updateItem}
              isCartItemOriginalEditionStandard={isCartItemOriginalEditionStandard}
              getOriginalTierCount={getOriginalTierCount}
              setAnalyticsTrigger={setAnalyticsTrigger}
            />
          ),
      )}
      {Boolean(cartAppItems.length) && (
        <tr>
          <StyledDetailsCell>App details</StyledDetailsCell>
        </tr>
      )}
      {cartAppItems.map((cartItem) => {
        return (
          <AppInfoRow
            key={`${cartItem}-app-info-row`}
            currency={currency}
            cartAppItem={cartItem}
            appTierMapping={appTierMapping}
          />
        );
      })}
    </ParentItemWrapper>
  );
};
