import React from 'react';
import styled from 'styled-components';

import { Grid, GridColumn } from '@atlaskit/page';

const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

export const GridRow = ({ children }) => (
  <Wrapper>
    <Grid layout="fluid">{children}</Grid>
  </Wrapper>
);

export const CenteredGridRow = ({ children, medium }) => (
  <GridRow>
    <GridColumn medium={(12 - medium) / 2} />
    <GridColumn medium={medium}>{children}</GridColumn>
    <GridColumn medium={(12 - medium) / 2} />
  </GridRow>
);
