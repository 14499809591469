import { State } from 'model/State';
import { createSelector } from 'reselect';
import FormValidationError from 'model/hams/FormValidationError';
import Progress from 'redux-progress';

export const selectBiennialCartUUID = (state: State) =>
  (state.location.query && state.location.query.biennial_uuid) || null;

export const selectChosenBillingPeriod = (state: State) =>
  state.siteConfigurator.selectedBillingPeriod;

export const selectAnnualBillingCart = (state: State) => state.siteConfigurator.annualBillingCart;

export const selectBiennialBillingCart = (state: State) =>
  state.siteConfigurator.biennialBillingCart;

export const selectInSiteConfiguratorMode = (state: State) =>
  state.siteConfigurator.inSiteConfiguratorMode;

export const selectOriginalCartItemDetails = (state: State) =>
  state.siteConfigurator.originalCartItemDetails;

export const selectAnalyticsTrigger = (state: State) => state.siteConfigurator.analyticsTrigger;

export const selectCartFormValidationError = (state: State) =>
  state.siteConfigurator.cartFormValidationError;

export const selectCurrentContactDetails = (state: State) =>
  state.auth.currentContact.result?.contactDetails || {};

export const selectCurrentContactOrganisationDetails = (state: State) =>
  state.auth.currentContact.result?.organisationDetails || {};

export const hasPartnerFormValidationError = (
  cartFormValidationError: Progress<FormValidationError | null>,
) =>
  Object.values(cartFormValidationError.result || {}).find((error: any) =>
    error?.errorKey?.includes('Atlassian Expert - please log-in'),
  );

export const hasLoginFormValidationError = createSelector(
  (s: State) => s.auth.currentContact.result as any,
  selectCartFormValidationError,
  (user, cartFormValidationError) => {
    return hasPartnerFormValidationError(cartFormValidationError) || !user;
  },
);

export const canEditContactInSiteConfigurator = createSelector(
  (s) => s.auth.currentContact.result,
  selectInSiteConfiguratorMode,
  (user, isSiteConfigurator) => isSiteConfigurator && !!user,
);
