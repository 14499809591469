// @flow
import React from 'react';
import { uid } from 'react-uid';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import type { CheckoutReview } from 'modules/checkout/review/duck/model';
import t from 'modules/i18n/intl';
import config from 'appconfig';
import styled from 'styled-components';
import { getProductDescriptionFromErrorMessage } from './errorReviewParserHelper';
import { MessageNotificationWrapper } from './styled';
import { HamsCart } from '../../cart/duck/model';

import GenericErrorMessage from 'components/common/GenericErrorMessage';

type Props = StateProps & {
  cart: HamsCart,
  reviewState: CheckoutReview,
  inSiteConfiguratorMode: boolean,
  onResetCart: () => void,
};

const GenericErrorWrapper = styled.div`
  text-align: left;
`;

const SubmitError = ({ reviewState, cart, onResetCart, inSiteConfiguratorMode }: Props) => {
  let errorSection = null;
  if (reviewState.submitProgress.failed) {
    let message = '';
    const actions = [];
    let productsWithDescription = [];

    if (inSiteConfiguratorMode) {
      return (
        <GenericErrorWrapper>
          <GenericErrorMessage error={reviewState.submitProgress.error} />
        </GenericErrorWrapper>
      );
    }

    if (reviewState.submitProgress.error.errorKey === 'cart.outdated') {
      message = t('review.cart-outdated');
      actions.push({
        text: t('checkout.contact.refresh-page'),
        onClick: () => window.location.reload(true),
      });
    } else {
      const errorDetails = reviewState.submitProgress.error.errorDetail;
      productsWithDescription = getProductDescriptionFromErrorMessage(errorDetails, cart.items);
      message =
        productsWithDescription.length === 0
          ? t('review.cart-expired')
          : t('review.cart-expired-data-center');

      actions.push({ text: t('common.start-new-cart'), onClick: onResetCart });
    }
    actions.push({
      text: t('common.contact-us'),
      href: `${config.wacUrl}/company/contact/purchasing-licensing`,
    });
    errorSection = (
      <MessageNotificationWrapper>
        <SectionMessage
          appearance="error"
          title={t('review.unable-to-process-cart')}
          actions={actions.map(({ text, ...restAction }) => (
            <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
          ))}
        >
          {message}
          {productsWithDescription.length > 0 && (
            <ul>
              {productsWithDescription.map((product) => (
                <li key={uid(product)}>{product}</li>
              ))}
            </ul>
          )}
        </SectionMessage>
      </MessageNotificationWrapper>
    );
  }
  return errorSection;
};

export default SubmitError;
