/* eslint-disable generator-star-spacing,func-names */
import { selectLatestFrom, progressResult } from 'util/rxOperators';
import { merge, Observable } from 'rxjs';
import { withLatestFrom, flatMap, filter, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import get from 'lodash/get';
import * as selectors from 'modules/tracking/selectors';
import * as auth from 'modules/auth';
import { CartEpic } from 'model/State';
import * as contacts from 'modules/checkout/contacts/duck';
import { hasErrorCountryPurchaserMismatch } from 'modules/checkout/contacts/duck/selectors/getContactValidations';
import { AnyAction } from 'redux';
import {
  TrackAction,
  TrackActionSubject,
  TrackSource,
  TrackEventNames,
} from 'modules/analytics/model/TrackEvent';
import { createTrackEvent, utils } from 'modules/analytics';
import {
  PCCartCountryValidationType,
  TCCartCountryValidationType,
  CheckoutContactsData,
} from 'modules/checkout/contacts/duck/model';
import { UserChannel } from 'modules/auth/model';

export const checkoutContacts: CartEpic<AnyAction> = (action$, state$) => {
  const contactData$: Observable<CheckoutContactsData> = state$.pipe(
    progressResult(contacts.selectors.getData),
  );

  const loadedContact$: Observable<CheckoutContactsData> = action$.pipe(
    ofType(contacts.actions.LOAD),
    selectLatestFrom(contactData$),
  );

  const savedContact$: Observable<CheckoutContactsData> = action$.pipe(
    ofType(contacts.actions.SUBMIT),
    filter((action) => action.progress.success),
    selectLatestFrom(contactData$),
  );

  const addedBillingTechnicalEvents$ = savedContact$.pipe(
    withLatestFrom(loadedContact$),
    flatMap(function* ([submitted, loaded]: [CheckoutContactsData, CheckoutContactsData]) {
      if (!loaded.isDifferentBilling && submitted.isDifferentBilling) {
        yield createTrackEvent(
          utils.getTrackEventData(
            TrackAction.Added,
            TrackActionSubject.BillingContact,
            TrackSource.ContactDetailsScreen,
            TrackEventNames.AddedBillingContact,
          ),
          selectors.props.cart,
        );
      }
      if (!loaded.isDifferentTechnical && submitted.isDifferentTechnical) {
        yield createTrackEvent(
          utils.getTrackEventData(
            TrackAction.Added,
            TrackActionSubject.TechnicalContact,
            TrackSource.ContactDetailsScreen,
            TrackEventNames.AddedTechnicalContact,
          ),
          selectors.props.cart,
        );
      }
    }),
  );

  const userChannel$: Observable<UserChannel> = state$.pipe(
    progressResult(auth.selectors.getUserChannel),
  );
  const resellerEvents$ = savedContact$.pipe(
    withLatestFrom(userChannel$),
    flatMap(function* ([submitted, channel]: [CheckoutContactsData, UserChannel]) {
      if (submitted.resellerOrder) {
        yield createTrackEvent(
          utils.getTrackEventData(
            TrackAction.Identified,
            TrackActionSubject.Reseller,
            TrackSource.ContactDetailsScreen,
            TrackEventNames.IdentifiedAsReseller,
          ),
          selectors.props.cart,
        );
        if (channel.isPartner) {
          yield createTrackEvent(
            utils.getTrackEventData(
              TrackAction.Identified,
              TrackActionSubject.Partner,
              TrackSource.ContactDetailsScreen,
              TrackEventNames.PartnerPlacingOrderForEndCustomer,
            ),
            selectors.props.cart,
          );
        }
      }
    }),
  );

  // cart-country check has higher precedence over purchaser-country check in showing error
  const cartCountryPurchaserCheck$ = action$.pipe(
    ofType(contacts.actions.PC_CART_COUNTRY_VALIDATIONS),
    map((action) => action.payload),
    flatMap(function* (payload: PCCartCountryValidationType) {
      const hasCartCountryError = !get(payload[0], ['result', 'compatible'], true);
      const hasPurchaserCountryError = !!(
        payload[1] && hasErrorCountryPurchaserMismatch(payload[1].result)
      );
      if (hasCartCountryError) {
        yield createTrackEvent(
          utils.getTrackEventData(
            TrackAction.Mismatched,
            TrackActionSubject.Country,
            TrackSource.ContactDetailsScreen,
            TrackEventNames.CartCountryMismatchError,
          ),
          selectors.props.cart,
        );
      } else if (hasPurchaserCountryError) {
        yield createTrackEvent(
          utils.getTrackEventData(
            TrackAction.Invalidated,
            TrackActionSubject.PurchaserCountry,
            TrackSource.ContactDetailsScreen,
            TrackEventNames.CountryPurchaserValidationError,
          ),
          selectors.props.cart,
        );
      }
    }),
  );

  // cart-country check has higher precedence over technical contact-country check in showing error
  const cartCountryTCCheck$ = action$.pipe(
    ofType(contacts.actions.TC_CART_COUNTRY_VALIDATIONS),
    map((action) => action.payload),
    flatMap(function* (payload: TCCartCountryValidationType) {
      const hasCartCountryError = !get(payload[0], ['result', 'compatible'], true);
      const hasTechnicalContactError = !get(payload[1], ['result', 'compatible'], true);
      if (hasCartCountryError) {
        yield createTrackEvent(
          utils.getTrackEventData(
            TrackAction.Mismatched,
            TrackActionSubject.Country,
            TrackSource.ContactDetailsScreen,
            TrackEventNames.CartCountryMismatchError,
          ),
          selectors.props.cart,
        );
      } else if (hasTechnicalContactError) {
        yield createTrackEvent(
          utils.getTrackEventData(
            TrackAction.Invalidated,
            TrackActionSubject.TechnicalContactCountry,
            TrackSource.ContactDetailsScreen,
            TrackEventNames.CountryDifferentTechnicalValidationError,
          ),
          selectors.props.cart,
        );
      }
    }),
  );

  return merge(
    addedBillingTechnicalEvents$,
    resellerEvents$,
    cartCountryPurchaserCheck$,
    cartCountryTCCheck$,
  );
};

export default checkoutContacts;
