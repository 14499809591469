/* eslint-disable chai-friendly/no-unused-expressions */

import React from 'react';
import Spinner from '@atlaskit/spinner';
import EditorSuccessIcon from '@atlaskit/icon/glyph/editor/success';
import styled from 'styled-components';
import RequireLoginModal from 'modules/checkout/contacts/RequireLoginModal';
import { Field, FormSection, FieldText, BindProps } from 'modules/form';
import { Intl } from 'modules/i18n/intl';

export type Props = {
  name: string;
  lockEmail?: boolean;
  isDifferentTechnical?: boolean;
  expertOrder?: boolean;
  resellerOrder?: boolean;
};

const EmailAndIndicator = styled.div`
  position: relative;
`;
const Icon = styled.span`
  position: absolute;
  width: 25px;
  right: 5px;
  top: 8px;
`;

/** We need this component because Atlaskit <Field> doesn't allow multiple children under it,
 *    or to put any wrapping element (e.g. <div>) between it and its children (TextField)
 *    (because it wants to set a bunch of props to its direct children).
 *  This component is needed to receive those props from <Field>,
 *    and lob it past the <div> to our <TextField>.
 *  So now we can have multiple children (e.g. icon).
 * */
class EmailInput extends React.Component<BindProps<typeof FieldText>> {
  inputRef = React.createRef<HTMLInputElement>();
  focus = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  render() {
    const { field, ...props } = this.props;
    return (
      !!field && (
        <EmailAndIndicator>
          <FieldText {...props} akRef={this.inputRef} field={field} type="email" />
          <Icon>
            {field.validity.progress.fold({
              success: () => <EditorSuccessIcon label="" size="medium" />,
              loading: () => <Spinner interactionName="contact-inputs-spinner" size="small" />,
            })}
          </Icon>
          {field.validity.error.requireLogin && (
            <RequireLoginModal cancel={() => field.edit('')} onCloseComplete={this.focus} />
          )}
        </EmailAndIndicator>
      )
    );
  }
}

const ContactInputs = (props: Props) => (
  <Intl>
    {(translate) => (
      <FormSection name={props.name}>
        <Field name="email" label={translate('checkout.contact.email-address')} isRequired>
          <EmailInput
            disabled={props.lockEmail}
            isDifferentTechnical={props.isDifferentTechnical}
            expertOrder={props.expertOrder}
            resellerOrder={props.resellerOrder}
          />
        </Field>
        <Field name="firstName" label={translate('checkout.contact.firstname')} isRequired>
          <FieldText type="text" />
        </Field>
        <Field name="lastName" label={translate('checkout.contact.lastname')} isRequired>
          <FieldText type="text" />
        </Field>
        <Field name="phone" label={translate('checkout.contact.phone')} isRequired>
          <FieldText type="tel" />
        </Field>
      </FormSection>
    )}
  </Intl>
);
ContactInputs.defaultProps = {
  lockEmail: false,
  isDifferentTechnical: false,
  expertOrder: false,
  resellerOrder: false,
};
export default ContactInputs;
