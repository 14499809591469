import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import t from 'modules/i18n/intl';
import amexCcv from 'modules/checkout/payment/components/img/amex-cvv.png';
import visaMcCcv from 'modules/checkout/payment/components/img/visa-mc-cvv.png';
import Help from 'components/common/Help';

const Section = styled.section`
  margin-bottom: 10px;
`;

const Heading = styled.h4`
  color: ${colors.N80};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
`;

export default () => (
  <Help>
    <Section>
      <Heading>{t('payment.amex')}</Heading>
      <img src={amexCcv} alt={t('payment.amex-cvv')} />
    </Section>
    <Section>
      <Heading>{t('payment.visa-mastercard')}</Heading>
      <img src={visaMcCcv} alt={t('payment.visa-mastercard-cvv')} />
    </Section>
  </Help>
);
