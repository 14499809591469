import t from 'modules/i18n/intl';
import config from 'appconfig';

export type TwoColumnCTAOption = {
  title: string;
  text: string;
  button: {
    href: string;
    text: string;
    appearance: any;
    analyticsEventName: string;
  };
  cohort: string;
};

type TwoColumnCTAOptions = {
  [key: string]: TwoColumnCTAOption;
};

type AdditionalCTALink = {
  text: string;
  link: {
    text: string;
    href: string;
    analyticsEventName: string;
  };
};

export const twoColumnCTAOptions: TwoColumnCTAOptions = {
  large: {
    title: t('migrate.recommendations.two-column-cta.large.title'),
    text: t('migrate.recommendations.two-column-cta.large.text'),
    button: {
      href: 'https://www.atlassian.com/enterprise/contact?formType=migration-options',
      text: t('migrate.recommendations.cta.button.contact-sales'),
      appearance: 'primary',
      analyticsEventName: 'onCTAContactUsAnalytics',
    },
    cohort: 'large',
  },
  medium: {
    title: t('migrate.recommendations.two-column-cta.large.title'),
    text: t('migrate.recommendations.two-column-cta.medium.text'),
    button: {
      href: 'https://support.atlassian.com/contact',
      text: t('migrate.recommendations.cta.button.contact-us'),
      appearance: 'primary',
      analyticsEventName: 'onCTAContactUsAnalytics',
    },
    cohort: 'medium',
  },
  small: {
    title: t('migrate.recommendations.two-column-cta.small.title'),
    text: t('migrate.recommendations.two-column-cta.small.text'),
    button: {
      href: `${config.wacUrl}/migration/plan`,
      text: t('migrate.recommendations.cta.button.get-started'),
      appearance: 'primary',
      analyticsEventName: 'onCTAGetStartedAnalytics',
    },
    cohort: 'small',
  },
  starter: {
    title: t('migrate.recommendations.two-column-cta.starter.title'),
    text: t('migrate.recommendations.two-column-cta.starter.text'),
    button: {
      href: `${config.wacUrl}/try`,
      text: t('migrate.recommendations.cta.button.get-started-in-cloud'),
      appearance: 'primary',
      analyticsEventName: 'onCTAGetStartedInCloudAnalytics',
    },
    cohort: 'starter',
  },
  partner: {
    title: t('migrate.recommendations.cta.partner.title'),
    text: t('migrate.recommendations.cta.partner.text'),
    button: {
      href: 'https://partners.atlassian.com/display/resources/Cloud',
      text: t('migrate.recommendations.cta.button.view-resources'),
      appearance: 'primary',
      analyticsEventName: 'onCTAViewResourcesAnalytics',
    },
    cohort: 'partner',
  },
};

export const additionalCTALinks: AdditionalCTALink[] = [
  {
    text: t('migrate.recommendations.cta.additional-links.choose-the-right-migration-path'),
    link: {
      text: t('migrate.recommendations.cta.additional-links.learn-more'),
      href: `${config.wacUrl}/migration/assess`,
      analyticsEventName: 'onCTALearnMoreAnalytics',
    },
  },
  {
    text: t('migrate.recommendations.cta.additional-links.find-more-pricing-resources'),
    link: {
      text: t('migrate.recommendations.cta.additional-links.view-resources'),
      href: `${config.wacUrl}/migration/assess/calculate-pricing`,
      analyticsEventName: 'onCTAViewPricingResourcesAnalytics',
    },
  },
];
