import Progress, { thunkProgress } from 'redux-progress';
import { Dispatch, GetState } from 'model/State';
import hamsClient from 'modules/hams';

import { selectHamsClientConfig } from '../selectors';
import { selectors } from '..';

export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const fetchUserDetails = () => async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  if (selectors.isXsrfPresent(state)) {
    await dispatch(
      thunkProgress(
        FETCH_USER_DETAILS,
        hamsClient
          .get('/1.0/public/auth/user/details', selectHamsClientConfig(state))
          .then((r) => r.data),
      ),
    );
  } else {
    dispatch({
      type: FETCH_USER_DETAILS,
      progress: Progress.success(null),
    });
  }
};
