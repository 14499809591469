// @flow
import { combineReducers } from 'redux';

import landing from 'modules/change/landing/duck';
import datacenter from './datacenter/reducer';

export default combineReducers({
  landing,
  datacenter,
});
