// @flow
import React from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button/standard-button';
import { colors } from '@atlaskit/theme';
import { Font } from 'components/visuals';
import t from 'modules/i18n/intl';

import type { PurchaseMode } from 'modules/settings/purchaseMode';

type Props = {
  selected: PurchaseMode,
  select: (selection: PurchaseMode) => void,
};

const NavGroup = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const NavItem = styled.li`
  display: inline;
`;

const SwitchButton = styled(Button)`
  line-height: 24px;
  height: 24px;
  border: 0;
  padding: 0 14px;
  font-family: ${Font.displayFamily};
  border-top: 1px solid ${colors.N80};
  border-bottom: 1px solid ${colors.N80};
`;

const LeftButton = styled(SwitchButton)`
  border-radius: 3px 0 0 3px;
  border-left: 1px solid ${colors.N80};
`;

const RightButton = styled(SwitchButton)`
  border-radius: 0 3px 3px 0;
  border-right: 1px solid ${colors.N80};
`;

const appearance = (selected) => (selected ? 'primary' : 'default');

export const VisualPurchaseModeSwitch = (props: Props) => (
  <nav>
    <NavGroup>
      <NavItem>
        <LeftButton
          appearance={appearance(props.selected === 'BUY')}
          onClick={() => props.select('BUY')}
          data-testId="choose-order-button"
        >
          {t('navigation.header.menu.order')}
        </LeftButton>
      </NavItem>
      <NavItem>
        <RightButton
          appearance={appearance(props.selected === 'QUOTE')}
          onClick={() => props.select('QUOTE')}
          data-testId="choose-quote-button"
        >
          {t('navigation.header.menu.quote')}
        </RightButton>
      </NavItem>
    </NavGroup>
  </nav>
);

export default VisualPurchaseModeSwitch;
