// @flow
import { combineEpics } from 'redux-observable';

import { actions } from 'modules/checkout/contacts';

import FormValidationError from '../../model/hams/FormValidationError';
import { timeAction, counter, DEFAULT_RESULT_FILTER } from './util';

const aspectType = 'checkout.contacts.submit';

const isSuccess = (action) => {
  if (action.progress.error instanceof FormValidationError) {
    return true;
  }
  return DEFAULT_RESULT_FILTER(action);
};

export default combineEpics(
  counter(actions.SUBMIT, aspectType, { isSuccess }),
  timeAction(actions.SUBMIT, aspectType, undefined, { isSuccess }),
);
