/* eslint-disable consistent-return,no-param-reassign */

import Progress from 'redux-progress';
import produce from 'immer';

import * as actions from './actions';
import { SearchState } from './model';

const initialState: SearchState = {
  foundAccounts: [],
  email: null,
  sen: null,
  submitProgress: Progress.none,
  visible: false,
};

export default produce((draft: SearchState, action): SearchState | void => {
  switch (action.type) {
    case actions.CHANGE_SEN:
      draft.sen = action.payload;
      break;
    case actions.CHANGE_EMAIL:
      draft.email = action.payload;
      break;
    case actions.TOGGLE_FORM:
      draft.visible = !draft.visible;
      break;
    case actions.SUBMIT_FORM:
      draft.submitProgress = action.progress;
      action.progress.ifSuccess((account) => account && draft.foundAccounts.push(account));
      break;
    case actions.RESET_FORM:
      return initialState;
    default:
      if (action.meta && action.meta.location) {
        return initialState;
      }

      break;
  }
}, initialState);
