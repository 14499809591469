import React from 'react';
import t from 'modules/i18n/intl';
import { HamsCart, HamsEnrichedCart } from 'modules/cart/duck/model';
import { selectors as authSelectors } from 'modules/auth';
import {
  FieldCell,
  FieldValueCell,
  PriceSummary,
  PriceSummaryProps,
  Row,
  RowGroup,
  SavedAmountMessage,
  WithTooltip,
} from 'components/common/PriceSummary';
import styled from 'styled-components';
import { Dimensions, Font } from 'components/visuals';
import { isContactStepComplete } from 'modules/checkout/steps';
import { connect } from 'react-redux';
import { State } from 'model/State';

const SummaryHeader = styled.h2`
  font-family: ${Font.displayFamily};
  font-size: ${Dimensions.units(3)}px;
  font-weight: 500;
  line-height: ${Dimensions.units(4)}px;
  margin-bottom: ${Dimensions.units(2)}px;
  flex-grow: 1;
`;

const TaxUnknown = styled.span`
  font-weight: 500;
`;

const getDiscountRows = (adjustments: HamsCart['adjustmentAmounts']): Row[] => {
  if (!adjustments) {
    return [];
  }
  const discountRows: Row[] = [];
  // eslint-disable-next-line no-unused-expressions
  adjustments.LOYALTY_DISCOUNT &&
    discountRows.push({
      id: 'cart.loyaltydiscount',
      field: <FieldCell text={t('common.loyalty-discount')} />,
      value: (
        <FieldValueCell
          testId="loyalty-discount"
          text={adjustments.LOYALTY_DISCOUNT}
          showCurrency
          prefix="-"
        />
      ),
    });
  // eslint-disable-next-line no-unused-expressions
  adjustments.EXPERT &&
    discountRows.push({
      id: 'cart.partnerdiscount',
      field: <FieldCell text={t('common.partner-discount')} />,
      value: (
        <FieldValueCell
          testId="partner-discount"
          text={adjustments.EXPERT}
          showCurrency
          prefix="-"
        />
      ),
    });
  // eslint-disable-next-line no-unused-expressions
  adjustments.MARKETPLACE_PROMOTION &&
    discountRows.push({
      id: 'cart.promotion',
      field: <FieldCell text={t('common.promotion-discount')} />,
      value: (
        <FieldValueCell
          testId="promotion-discount"
          text={adjustments.MARKETPLACE_PROMOTION}
          showCurrency
          prefix="-"
        />
      ),
    });
  // eslint-disable-next-line no-unused-expressions
  adjustments.UPGRADE_CREDIT &&
    discountRows.push({
      id: 'cart.credits',
      field: (
        <WithTooltip content={t('cart.credit-infotext')}>
          <span>{t('common.credits')}</span>
        </WithTooltip>
      ),
      value: (
        <FieldValueCell
          testId="credit-discount"
          text={adjustments.UPGRADE_CREDIT}
          showCurrency
          prefix="-"
        />
      ),
    });
  return discountRows;
};

type OwnProps = {
  cart: HamsEnrichedCart;
};

type Props = OwnProps & { isTaxUnknown: boolean; isPartner: boolean };

const CartPriceSummary = ({ cart, isTaxUnknown, isPartner }: Props) => {
  if (!cart || cart.items.length === 0) {
    return null;
  }
  const summaryProps: PriceSummaryProps = React.useMemo(() => {
    const detailsRows: RowGroup[] = [
      {
        id: 'cart.subtotalrows',
        rows: [
          {
            id: 'cart.subtotal',
            field: <FieldCell text={t('common.subtotal')} />,
            value: (
              <FieldValueCell
                testId="subtotal-amount"
                text={cart.totalProratedPrice}
                showCurrency
              />
            ),
          },
        ],
      },
    ];

    const discountRows = getDiscountRows(cart.adjustmentAmounts);
    // eslint-disable-next-line no-unused-expressions
    discountRows.length > 0 &&
      detailsRows.push({
        id: 'cart.discountrows',
        rows: getDiscountRows(cart.adjustmentAmounts),
      });

    detailsRows.push({
      id: 'cart.taxrows',
      rows: [
        {
          id: 'cart.tax',
          field: <FieldCell text={t('common.tax')} />,
          value: (
            <FieldValueCell
              testId="tax-amount"
              text={
                isTaxUnknown ? <TaxUnknown> {t('cart.tax-unknown')} </TaxUnknown> : cart.totalTax
              }
              showCurrency={!isTaxUnknown}
            />
          ),
        },
      ],
    });

    const footerRows: Row[] = [
      {
        id: 'cart.total',
        field: <FieldCell text={t('common.total')} />,
        value: <FieldValueCell testId="total-amount" text={cart.totalIncTax} showCurrency />,
        classes: 'totalPayAmount',
      },
    ];

    // eslint-disable-next-line no-unused-expressions
    cart.savedAmount &&
      footerRows.push({
        id: 'cart.savings',
        field: null,
        value: (
          <SavedAmountMessage
            percentage={`${cart.savedAmountPercent}%`}
            currency={cart.currency}
            amount={cart.savedAmount}
            isPartner={isPartner}
          />
        ),
        classes: 'savingsText',
      });

    return {
      currency: cart.currency,
      rowGroups: detailsRows,
      footerRows,
    };
  }, [
    cart.adjustmentAmounts,
    cart.currency,
    cart.savedAmountPercent,
    cart.savedAmount,
    cart.totalTax,
    cart.totalIncTax,
    cart.totalProratedPrice,
    isPartner,
  ]);

  return (
    <>
      <SummaryHeader>{t('cart.price-summary')}</SummaryHeader>
      <PriceSummary {...summaryProps} />
    </>
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps) => ({
  isTaxUnknown: !isContactStepComplete(state).result && ownProps.cart.totalTax === 0,
  isPartner:
    authSelectors.getUserChannel(state).ifSuccess((userChannel) => userChannel.isPartner) || false,
});

export default connect(mapStateToProps)(CartPriceSummary);
