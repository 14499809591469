/* eslint-disable import/no-named-as-default */
// @flow
import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { connectRoutes } from 'redux-first-router';
import ReduxThunk from 'redux-thunk';

import { createScrollMiddleware } from 'react-redux-scroll';
import routesMap, { routingOptions } from 'routes';
import reducers from 'reducers';
import epic from 'epic';
import locationReducerWrapper from 'reducers/locationReducerWrapper';
import devMiddleware from 'devtools/devMiddleware';
import * as errors from 'modules/error-info';
import * as telemetry from 'modules/telemetry';
import createRoutingMiddleware from 'routes/middleware';
import { createAnalyticsMiddleware, CartAnalyticsClient } from 'modules/analytics';

const actionCreators = {};
const composeEnhancers = (...args) => composeWithDevTools({ actionCreators })(...args);

const { reducer, middleware, enhancer, initialDispatch } = connectRoutes(routesMap, {
  ...routingOptions,
});

const scrollMiddleware = createScrollMiddleware();
const rootReducer = reducers({ location: locationReducerWrapper(reducer) });
const epicMiddleware = createEpicMiddleware();
const queryParamsMiddleware = createRoutingMiddleware();
const analyticsMiddleware = createAnalyticsMiddleware(CartAnalyticsClient.getClient());

const middlewares = [
  errors.reportErrorMiddleware,
  devMiddleware,
  ReduxThunk,
  epicMiddleware,
  queryParamsMiddleware,
  middleware,
  scrollMiddleware,
  analyticsMiddleware,
];

if (telemetry.middleware !== null) {
  middlewares.push(telemetry.middleware);
}

const combinedMiddlewares = applyMiddleware(...middlewares);
const enhancers = composeEnhancers(enhancer, combinedMiddlewares);
const store = createStore(rootReducer, {}, enhancers);
export default store;

epicMiddleware.run(epic);
initialDispatch();
