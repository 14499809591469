import Progress from 'redux-progress';

import { AddonPageData, OverviewData } from 'modules/addon/duck/model';
import { selectMergedAddonsProductTiers } from 'modules/addon/duck/selectors/mergedProductTiers';
import { selectProductKeys } from 'modules/addon/duck/selectors/productKeys';
import { selectAddonsPricing } from 'modules/addon/duck/selectors/addonsPricing';
import selectSuggestedParent from 'modules/addon/duck/selectors/suggestedParent';
import {
  selectDeploymentGroup,
  selectDeploymentOption,
} from 'modules/addon/duck/selectors/deploymentGroup';
import selectChooseParentOptions from 'modules/addon/duck/selectors/chooseParentOptions';
import selectChosenParentOption from 'modules/addon/duck/selectors/chosenParentOption';
import selectAmount from 'modules/addon/duck/selectors/amount';
import selectIsSubscription from 'modules/addon/duck/selectors/isSubscription';
import selectSearchParentOption from 'modules/addon/duck/selectors/searchParentOption';

import { selectIsEmailValid, selectIsSenValid } from 'modules/addon/duck/selectors/form';
import {
  selectIsAcademicAvailable,
  selectIsAcademicOn,
} from 'modules/addon/duck/selectors/academic';
import { selectManualTerms } from 'modules/addon/duck/selectors/terms';
import selectOrderableItems from 'modules/addon/duck/selectors/orderableItems';
import selectTerm from 'modules/addon/duck/selectors/term';
import selectCurrency from 'modules/cart/duck/selectors/currency';
import { selectPurchaseMode } from 'modules/settings/purchaseMode';
import selectIsAuthenticated from 'modules/auth/selectors/isAuthenticated';
import { Selector } from 'model/State';

export const selectPageData: Selector<Progress<AddonPageData>> = (state) =>
  Progress.all(
    selectCurrency(state),
    selectDeploymentGroup(state),
    selectDeploymentOption(state),
    selectIsSubscription(state),
    selectManualTerms(state),
    selectAddonsPricing(state),
    selectSuggestedParent(state),
    selectMergedAddonsProductTiers(state),
  ).map(
    ([
      currency,
      deploymentGroup,
      deploymentOption,
      isSubscription,
      manualTerms,
      pricing,
      suggestedParent,
      tiers,
    ]) => ({
      amount: selectAmount(state),
      currency,
      deploymentGroup,
      deploymentOption,
      isAcademicAvailable: selectIsAcademicAvailable(state),
      isAcademicOn: selectIsAcademicOn(state),
      isAuthenticated: selectIsAuthenticated(state),
      isEmailValid: selectIsEmailValid(state),
      isSenValid: selectIsSenValid(state),
      isSubscription,
      manualTerms,
      pricing,
      productKeys: selectProductKeys(state),
      purchaseMode: selectPurchaseMode(state),
      searchParentOption: selectSearchParentOption(state),
      suggestedParent,
      tiers,
    }),
  );

export const selectOverviewData: Selector<Progress<OverviewData>> = (state) =>
  Progress.all(
    selectChooseParentOptions(state),
    selectChosenParentOption(state),
    selectTerm(state),
  ).map(([chooseParentOptions, chosenParent, term]) => ({
    chooseParentOptions,
    chosenParent,
    term,
  }));

export const isAddToCartEnabled: Selector<boolean> = (state) =>
  !!Progress.all(selectOrderableItems(state), selectTerm(state)).ifSuccess(
    ([items, term]) => !!(items.length > 0 && term),
  );
