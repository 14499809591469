import React, { useState } from 'react';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import styled from 'styled-components';
import Flag from '@atlaskit/flag';
import { EnhancedOrganisation } from 'modules/checkout/contacts/duck/model';
import t from 'modules/i18n/intl';
import { Field, FieldText } from 'modules/form';
import TaxIdRadio from 'modules/checkout/contacts/TaxIdRadio';
import { Dimensions } from 'components/visuals';

export const Subtext = styled.p`
  font-size: small;
  position: relative;
  top: ${Dimensions.units(-1)}px;
  font-weight: 400;
`;

const TaxFreeMessage = ({ taxRate, country }: any) => {
  if (taxRate.taxFreeWithValidTaxId) {
    switch (taxRate.taxIdLabel) {
      case 'VAT Number':
        return <Subtext>{t('checkout.contact.tax.vat-added')}</Subtext>;
      case 'ABN':
        return <Subtext>{t('checkout.contact.tax.au-gst-added')}</Subtext>;
      case 'Organization Number':
        return (
          <Subtext>
            {t('checkout.contact.tax.org-no-vat-added', { country: country.countryIsoCode })}
          </Subtext>
        );
      case 'Registration Number':
        return <Subtext>{t('checkout.contact.tax.japanese-consumption-tax-added')}</Subtext>;
      case 'GUI Number':
        return <Subtext>{t('checkout.contact.tax.gui-vat-added')}</Subtext>;
      case 'GSTIN':
        return <Subtext>{t('checkout.contact.tax.gstin-added')}</Subtext>;
      case 'QST number':
        return <Subtext>{t('checkout.contact.tax.qst-added')}</Subtext>;
      default:
        return null;
    }
  }
  return null;
};

const countryIsoCodeChile = 'CL';

export default ({
  taxRate,
  showTaxId,
  taxIdLabel,
  country,
  vatResponsible,
  taxId,
}: Partial<EnhancedOrganisation>) =>
  taxRate?.fold({
    success: (rate) => {
      const [isTaxIdFieldEmpty, setIsTaxIdFieldEmpty] = useState(!taxId);
      const isCountryChile = country?.isoCountryCode === countryIsoCodeChile;
      const taxIdChilePlaceholder = isCountryChile ? 'XX.XXX.XXX-X' : '';

      return showTaxId ? (
        <>
          <Field
            name="taxId"
            label={taxIdLabel}
            errorMessage={() => t('checkout.contact.enter-valid-taxid', { taxIdLabel })}
          >
            <FieldText
              type="text"
              placeholder={taxIdChilePlaceholder}
              setIsFieldEmpty={setIsTaxIdFieldEmpty}
            />
          </Field>
          <TaxFreeMessage taxRate={rate} country={country} />
          {isCountryChile && (
            <Field
              name="vatResponsible"
              errorMessage={() => t('checkout.contact.tax-id-vat-error-message')}
            >
              <TaxIdRadio vatResponsible={vatResponsible} isTaxIdFieldEmpty={isTaxIdFieldEmpty} />
            </Field>
          )}
        </>
      ) : null;
    },
    failed: () => (
      <Flag
        id="1"
        appearance="error"
        icon={<Error label="Error icon" secondaryColor={colors.R300} />}
        title={t('checkout.contact.unable-to-load-tax-info')}
        description={t('checkout.contact.tax-description')}
      />
    ),
  }) || null;
