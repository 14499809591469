import t from 'modules/i18n/intl';
import config from 'appconfig';

export type CTAOption = {
  title: string;
  text?: string;
  buttons: {
    href: string;
    text: string;
    appearance: any;
    analyticsEventName: string;
  }[];
  cohort: string;
};

type CTAOptions = {
  [key: string]: CTAOption;
};

export const ctaOptions: CTAOptions = {
  large: {
    title: t('migrate.recommendations.cta.large.title'),
    text: t('migrate.recommendations.cta.large.text'),
    buttons: [
      {
        href: 'https://support.atlassian.com/contact',
        text: t('migrate.recommendations.cta.button.contact-us'),
        appearance: 'primary',
        analyticsEventName: 'onCTAContactUsAnalytics',
      },
      {
        href: `${config.wacUrl}/migration`,
        text: t('migrate.recommendations.cta.button.migration-center'),
        appearance: 'default',
        analyticsEventName: 'onCTAMigrationCenterAnalytics',
      },
    ],
    cohort: 'large',
  },
  medium: {
    title: t('migrate.recommendations.cta.medium.title'),
    text: t('migrate.recommendations.cta.medium.text'),
    buttons: [
      {
        href: 'https://support.atlassian.com/contact',
        text: t('migrate.recommendations.cta.button.contact-us'),
        appearance: 'primary',
        analyticsEventName: 'onCTAContactUsAnalytics',
      },
      {
        href: `${config.wacUrl}/migration`,
        text: t('migrate.recommendations.cta.button.migration-center'),
        appearance: 'default',
        analyticsEventName: 'onCTAMigrationCenterAnalytics',
      },
    ],
    cohort: 'medium',
  },
  small: {
    title: t('migrate.recommendations.cta.small.title'),
    buttons: [
      {
        href: `${config.wacUrl}/migration`,
        text: t('migrate.recommendations.cta.button.migration-center'),
        appearance: 'primary',
        analyticsEventName: 'onCTAMigrationCenterAnalytics',
      },
    ],
    cohort: 'small',
  },
  starter: {
    title: t('migrate.recommendations.cta.starter.title'),
    text: t('migrate.recommendations.cta.starter.text'),
    buttons: [
      {
        href: `${config.wacUrl}/migration`,
        text: t('migrate.recommendations.cta.button.migration-center'),
        appearance: 'primary',
        analyticsEventName: 'onCTAMigrationCenterAnalytics',
      },
      {
        href: `${config.wacUrl}/try`,
        text: t('migrate.recommendations.cta.button.try-cloud'),
        appearance: 'default',
        analyticsEventName: 'onCTATryCloudAnalytics',
      },
    ],
    cohort: 'starter',
  },
  partner: {
    title: t('migrate.recommendations.cta.partner.title'),
    text: t('migrate.recommendations.cta.partner.text'),
    buttons: [
      {
        href: 'https://partners.atlassian.com/display/resources/Cloud',
        text: t('migrate.recommendations.cta.button.view-resources'),
        appearance: 'primary',
        analyticsEventName: 'onCTAViewResourcesAnalytics',
      },
    ],
    cohort: 'partner',
  },
};
