// @flow
import React from 'react';
import { Checkbox } from '@atlaskit/checkbox';
import type { BindProps } from 'modules/form/BindFieldInput';

export default ({ field, ...props }: BindProps<typeof Checkbox>) =>
  !!field && (
    <Checkbox
      {...props}
      isChecked={field.value}
      onChange={(e: any) => {
        field.edit(e.target.checked);
        field.onBlur();
      }}
    />
  );
