// @flow
import { connect } from 'react-redux';
import type { State } from 'model/State';
import { makeSelectProductName, makeSelectProductTagline } from 'modules/catalog/pricing';
import Header from 'components/common/Header';

import { selectors } from '..';

const makeMapStateToProps = () => {
  const selectProductName = makeSelectProductName();
  const selectProductTagline = makeSelectProductTagline();

  return (state: State) => ({
    productKey: selectors.selectMainProductKey(state),
    productName: selectProductName(state, { productKey: selectors.selectMainProductKey(state) }),
    tagLine: selectProductTagline(state, { productKey: selectors.selectMainProductKey(state) }),
  });
};

Header.defaultProps = {
  showTagline: false,
};

export default connect(makeMapStateToProps)(Header);
