import { State, Dispatch } from 'model/State';

export type AdditionalDetailsState = {
  hasTaxExemptRequest: boolean;
  hasCoTermRequest: boolean;
  poNumber: string;
};
export type AdditionalDetailsProps = AdditionalDetailsState & {
  edit: (payload: Partial<AdditionalDetailsState>) => void;
};

export const EDIT = 'checkout/additionalDetails/EDIT';
export const edit = (payload: Partial<AdditionalDetailsState>) => ({ type: EDIT, payload });

export const getProps = (state: State, dispatch: Dispatch) => ({
  ...state.checkout.additionalDetails,
  edit: (payload) => dispatch(edit(payload)),
});

const initialState = {
  hasTaxExemptRequest: false,
  hasCoTermRequest: false,
  poNumber: '',
};
const reducer = (
  state: AdditionalDetailsState = initialState,
  action: any,
): AdditionalDetailsState => {
  switch (action.type) {
    case EDIT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export default reducer;
