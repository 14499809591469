// @flow
import type { Dispatch, GetState, State } from 'model/State';
import AtlStorage from 'api/AtlStorage';

const storage = new AtlStorage('btf.academic.mode', window.sessionStorage, 'academic_mode');

const load = (): boolean => !!storage.load();

const TOGGLE = 'settings/academic/TOGGLE';
const RESET = 'settings/academic/RESET';

export const toggleAcademic = () => (dispatch: Dispatch, getState: GetState) => {
  const newValue = !getState().settings.academic;
  storage.store(newValue);

  dispatch({
    type: TOGGLE,
  });
};

export const reset = () => {
  storage.reset();
  return { type: TOGGLE };
};

export const isAcademicSwitchOn = (state: State) => state.settings.academic;

export default (state: boolean = load(), action: *): boolean => {
  switch (action.type) {
    case TOGGLE:
      return !state;
    case RESET:
      return load();
    default:
      return state;
  }
};
