import React from 'react';

import { PRODUCT_LIST } from 'actions';
import PurchasePage from 'pages/PurchasePage';
import * as addon from 'modules/addon';
import * as addtocart from 'modules/addtocart';
import * as cart from 'modules/cart';
import * as complete from 'modules/checkout/complete';
import * as configure from 'modules/configure';
import * as contacts from 'modules/checkout/contacts';
import * as marketplace from 'modules/marketplace';
import * as payment from 'modules/checkout/payment';
import * as promotion from 'modules/promotion';
import * as quote from 'modules/checkout/quote';
import * as renew from 'modules/renew';
import * as review from 'modules/checkout/review';
import * as siteconfigurator from 'modules/siteconfigurator';
import * as software from 'modules/software';
import * as upgrade from 'modules/upgrade';
import * as mac from 'modules/mac';

import AddonPage from 'modules/addon/AddonPage';
import AddToCartPage from 'modules/addtocart/AddToCartPage';
import CartPage from 'modules/cart/CartPage';
import ConfirmationPage from 'modules/checkout/complete/ConfirmationPage';
import CheckoutContactPage from 'modules/checkout/contacts/CheckoutContactPage';
import MarketplacePage from 'modules/marketplace/MarketplacePage';
import PaymentPage from 'modules/checkout/payment/components/PaymentPage';
import ProductConfigurationPage from 'modules/configure/ProductConfigurationPage';
import PromotionPage from 'modules/promotion/PromotionPage';
import QuotePage from 'modules/checkout/quote/QuotePage';
import RenewPage from 'modules/renew/RenewPage';
import ReviewPage from 'modules/checkout/review/ReviewPage';
import SiteConfigurator from 'modules/siteconfigurator/SiteConfigurator';
import SoftwarePage from 'modules/software/SoftwarePage';
import UpgradeLandingPage from 'modules/upgrade/UpgradeLandingPage';
import UpgradeTiersPage from 'modules/upgrade/UpgradeTiersPage';
import UpgradeDatacenterPage from 'modules/upgrade/UpgradeDatacenterPage';
import { RenewLandingPage } from 'modules/renew/RenewLandingPage';
import {
  MACLicensesPage,
  MACLicensesPreselectedPage,
  MACOrdersPage,
  MACConfigUserPage,
  MACEditDetailsPage,
  MACLicenseEvaluationPage,
  MACPaymentDetailsPage,
  MACUpdateCreditCardPage,
} from 'modules/mac';

const pages = {
  [PRODUCT_LIST]: PurchasePage,
  [addon.actions.ROUTE]: AddonPage,
  [addtocart.actions.ROUTE]: AddToCartPage,
  [cart.actions.ROUTE]: CartPage,
  [complete.actions.ROUTE]: ConfirmationPage,
  [contacts.actions.ROUTE]: CheckoutContactPage,
  [configure.actions.ROUTE]: ProductConfigurationPage,
  [marketplace.actions.ROUTE]: MarketplacePage,
  [payment.actions.ROUTE]: PaymentPage,
  [promotion.actions.ROUTE]: PromotionPage,
  [quote.actions.ROUTE]: QuotePage,
  [renew.landing.actions.ROUTE]: RenewLandingPage,
  [renew.server.actions.ROUTE]: RenewPage,
  [siteconfigurator.actions.ROUTE]: SiteConfigurator,
  [review.actions.ROUTE]: ReviewPage,
  [software.buy.ROUTE]: SoftwarePage,
  [software.renewAndUpgrade.UPGRADE_ROUTE]: SoftwarePage,
  [software.renewAndUpgrade.RENEW_ROUTE]: SoftwarePage,
  [upgrade.datacenter.actions.ROUTE]: UpgradeDatacenterPage,
  [upgrade.landing.actions.ROUTE]: UpgradeLandingPage,
  [upgrade.tiers.actions.ROUTE]: UpgradeTiersPage,
  [mac.landing.ROUTE]: MACLicensesPage,
  [mac.licensesPreselected.ROUTE]: MACLicensesPreselectedPage,
  [mac.orders.ROUTE]: MACOrdersPage,
  [mac.configUser.ROUTE]: MACConfigUserPage,
  [mac.editDetails.ROUTE]: MACEditDetailsPage,
  [mac.licenseEvaluation.ROUTE]: MACLicenseEvaluationPage,
  [mac.paymentDetails.ROUTE]: MACPaymentDetailsPage,
  [mac.updateCreditCard.ROUTE]: MACUpdateCreditCardPage,
};

type Props = {
  page: string;
};

export default (props: Props) => {
  const Component = pages[props.page] || PurchasePage;
  return <Component />;
};
