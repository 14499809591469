/* eslint-disable no-param-reassign */
// @flow

import produce from 'immer';
import { FETCH_LANGUAGE, SELECT_LANGUAGE } from './actions';
import type { I18nState } from './model';
import { defaultLanguage } from './model';

const initialState: I18nState = {
  language: defaultLanguage.locale,
  translations: {},
};

export default (state: I18nState = initialState, action: any): I18nState => {
  switch (action.type) {
    case SELECT_LANGUAGE:
      return produce(state, (draft) => {
        draft.language = action.payload.language;
      });

    case FETCH_LANGUAGE:
      return produce(state, (draft) => {
        draft.translations[action.payload.lang] = action.progress;
      });
    default:
      return state;
  }
};
