import reportError from 'util/errorReporter';
import { createSelector } from 'reselect';
import Progress from 'redux-progress';

import { CHOOSE, MANUAL, SEARCH } from 'modules/addon/duck/model';
import selectChosenParentOption from 'modules/addon/duck/selectors/chosenParentOption';
import selectSearchParentOption from 'modules/addon/duck/selectors/searchParentOption';
import { selectManualTerms } from 'modules/addon/duck/selectors/terms';
import { TermOption } from 'model/TermOption';
import { Selector } from 'model/State';

const selectTerm: Selector<Progress<TermOption>> = createSelector(
  (state) => state.addon.mode,
  selectChosenParentOption,
  selectSearchParentOption,
  (state) => state.addon.terms.manual,
  selectManualTerms,
  (mode, chosenParentProgress, searchParentProgress, manualTerm, manualTerms) => {
    switch (mode) {
      case CHOOSE:
        return chosenParentProgress.map((parent) => parent && parent.term);
      case SEARCH:
        return Progress.success(
          searchParentProgress.fold({
            success: (parent) => parent && parent.term,
          }),
        );
      case MANUAL:
        return manualTerm
          ? manualTerms.map((terms) => terms && terms.find((t) => t.terms === manualTerm.terms))
          : manualTerms.map((terms) => terms && terms[0]);
      default:
        reportError(`Unknown addon config mode ${mode}`);
        return Progress.none;
    }
  },
);

export default selectTerm;
