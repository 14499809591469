import Progress from 'redux-progress';
import { SET_CART_FORM_VALIDATION_ERROR } from '../actions';

export default function cartFormValidationError(state = Progress.inProgress, action: any) {
  switch (action.type) {
    case SET_CART_FORM_VALIDATION_ERROR:
      return action.payload;
    default:
      return state;
  }
}
