import { combineReducers } from 'redux';
import userDetails from './userDetails';
import xsrfToken from './xsrfToken';
import currentContact from './currentContact';

export default combineReducers({
  currentContact,
  userDetails,
  xsrfToken,
});
