import isEqual from 'lodash/isEqual';
import { defaultData, CheckoutContactsData } from 'modules/checkout/contacts/duck/model';
import { ContactDetails, OrganisationDetails } from 'model/hams/contact';
import { CurrentContact, EXPERT, RESELLER } from 'modules/auth/model/CurrentContact';
import { HamsCart$Contacts } from 'modules/cart/duck/model';

export const sameContacts = (a: ContactDetails, b: ContactDetails) => isEqual(a, b);

const checkResellerOrder = (contact: CurrentContact | null) =>
  contact
    ? [EXPERT, RESELLER].includes(contact.organisationDetails.organisationType as string)
    : false;

const mapFromHamsCart = (
  {
    purchaserContactDetails,
    billingContactDetails,
    billingOrganisationDetails,
    technicalContactDetails,
    technicalOrganisationDetails,
    resellerOrder,
    expertOrder,
  }: HamsCart$Contacts,
  contact: CurrentContact | null,
  inSiteConfiguratorMode: boolean = false,
): CheckoutContactsData => {
  const purchaserContact =
    contact && inSiteConfiguratorMode ? contact.contactDetails : purchaserContactDetails;
  const isResellerOrder = resellerOrder || (inSiteConfiguratorMode && checkResellerOrder(contact));
  return {
    purchaserContactDetails: purchaserContact,
    billingContactDetails,
    billingOrganisationDetails,
    technicalContactDetails,
    technicalOrganisationDetails,
    resellerOrder: isResellerOrder,
    expertOrder,
    isDifferentBilling: !sameContacts(purchaserContact, billingContactDetails),
    isDifferentTechnical: resellerOrder || !sameContacts(purchaserContact, technicalContactDetails),
  };
};

const mapFromHamsCurrentContact = (
  contact: CurrentContact | null,
  billingOrganisationDetails: OrganisationDetails,
): CheckoutContactsData => {
  if (contact) {
    const resellerOrder = checkResellerOrder(contact);
    return {
      ...defaultData,
      resellerOrder,
      purchaserContactDetails: contact.contactDetails,
      billingOrganisationDetails: contact.organisationDetails,
      isDifferentTechnical: resellerOrder,
    };
  }
  return { ...defaultData, billingOrganisationDetails };
};

const isEmptyContact = (contact: ContactDetails) =>
  !contact.email && !contact.firstName && !contact.lastName && !contact.phone;

const isEmptyCompany = (company: OrganisationDetails) =>
  !company.address1 && !company.city && !company.isoCountryCode && !company.organisationName;

export default (
  cart: HamsCart$Contacts,
  currentContact: CurrentContact | null,
  inSiteConfiguratorMode: boolean = false,
): CheckoutContactsData => {
  const hasContactDetails =
    !isEmptyContact(cart.billingContactDetails) &&
    !isEmptyContact(cart.technicalContactDetails) &&
    !isEmptyContact(cart.purchaserContactDetails) &&
    !isEmptyCompany(cart.billingOrganisationDetails) &&
    !isEmptyCompany(cart.technicalOrganisationDetails);

  return hasContactDetails
    ? mapFromHamsCart(cart, currentContact, inSiteConfiguratorMode)
    : mapFromHamsCurrentContact(currentContact, cart.billingOrganisationDetails);
};
