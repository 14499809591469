// @flow

// A wrapper around react-redux connect function.
// React-redux normally takes 2 mappers:
// - mapStateToProps: state => props
// - mapDispatchToProps: dispatch => props
//
// Instead connectStateAndDispatch takes a single mapper:
// - mapStateAndDispatchToProps: (state, dispatch) => props
//
// Why?
// This allows us to compose a nested props structure with dispatch props built in it.
// It lends to a better code organisation and reusability esp. for nested components.

import { connect } from 'react-redux';
import type { State, Dispatch } from 'model/State';

export type MapToProps = (state: State, dispatch: Dispatch) => T;

export default (mapFromStateAndDispatch: MapToProps, options?: any) =>
  connect(
    (state) => state,
    null,
    (state, { dispatch }, ownProps) => ({
      ...ownProps,
      ...mapFromStateAndDispatch(state, dispatch),
    }),
    options,
  );
