// @flow
import {
  CloudProductKeys,
  DataCenterProductKeys,
  ProductGroupKeys,
  ServerProductKeys,
} from 'data/products/keys';
import config from 'appconfig';
import t from 'modules/i18n/intl';
import ServerProduct from 'model/editions/ServerProduct';
import DataCenterProduct from 'model/editions/DataCenterProduct';
import SoftwareProductGroup from 'model/SoftwareProductGroup';
import CloudProduct from 'model/editions/CloudProduct';
import { gitLFS, mirroring, optimizedAWS, saml } from 'model/features';

export const bitbucketServer = new ServerProduct({
  key: ServerProductKeys.BITBUCKET,
  groupKey: ProductGroupKeys.BITBUCKET,
  tagLine: t('products.bitbucket.tagline'),
  description: t('products.bitbucket.description'),
  name: t('products.bitbucket.name'),
  recommended: [ServerProductKeys.BAMBOO, ServerProductKeys.JIRA_SOFTWARE],
  moreLink: `${config.wacUrl}/software/bitbucket`,
  features: [optimizedAWS],
});

export const bitbucketCloud = new CloudProduct(
  CloudProductKeys.BITBUCKET,
  bitbucketServer,
  [],
  'https://bitbucket.org/account/signup/',
);

export const bitbucketDataCenter = new DataCenterProduct(
  DataCenterProductKeys.BITBUCKET,
  bitbucketServer,
  [],
  [gitLFS, mirroring, optimizedAWS, saml],
);

export const bitbucketProductGroup = new SoftwareProductGroup(
  bitbucketServer,
  bitbucketDataCenter,
  bitbucketCloud,
);
