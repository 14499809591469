// @flow
import React from 'react';
import LoadingButton from '@atlaskit/button/loading-button';
import { FormFooter } from '@atlaskit/form';
import styled from 'styled-components';
import type { PaymentPageProps } from 'modules/checkout/payment/duck/model';
import { Fineprint, FooterArea } from 'modules/checkout/styled';
import PaypalButton from 'modules/checkout/payment/components/paypal/PaypalButton';
import t from 'modules/i18n/intl';

const Agreement = styled(Fineprint)`
  margin-bottom: 10px;
  text-align: right;
`;

export default (props: PaymentPageProps) => (
  <FormFooter>
    <FooterArea>
      {props.autoRenewal.items.some((i) => i.selected) && (
        <Agreement>{t('payment.agree-to-autorenew')}</Agreement>
      )}
      {(!props.paypal.isAvailable || !props.paypal.isSelected) && (
        <LoadingButton
          type="submit"
          appearance="primary"
          isDisabled={!props.canSubmit || props.submit === null}
          isLoading={props.submission.inProgress || props.submit === null}
        >
          {t('payment.continue')}
        </LoadingButton>
      )}
      {props.paypal.isAvailable && <PaypalButton {...props} />}
    </FooterArea>
  </FormFooter>
);
