import { getActionPath } from 'util/ActionLink';
import React from 'react';
import { connect } from 'react-redux';
import Progress from 'redux-progress';
import styled from 'styled-components';
import Page, { Grid, GridColumn } from '@atlaskit/page';

import Button from '@atlaskit/button/standard-button';
import { colors } from '@atlaskit/theme';

import FeatureList from 'modules/change/landing/FeatureList';
import { tiers, datacenter } from 'modules/upgrade';
import * as renew from 'modules/renew';
import { selectors as changeSelectors } from 'modules/change/landing/duck';
import { Font, Dimensions } from 'components/visuals';
import { LoadingSpinner } from 'components/common/PageLoading';
import GenericErrorMessage from 'components/common/GenericErrorMessage';
import t from 'modules/i18n/intl';
import { Dispatch, State } from 'model/State';

import config from 'appconfig';
import { emitUIEpicEvent, UIEpicActionPayload, utils } from 'modules/analytics';
import { UISource } from 'modules/analytics/model/UIEvent';
import { getAdditionalAnalyticsAttributes } from 'modules/analytics/util';
import { ServerProductDisabledKeys, ServerUpgradeDisabledKeys } from 'data/products/keys';
import { cloudBenefits } from 'model/cloudBenefits';
import { cloudConsiderList } from 'model/cloudConsiderList';
import { dataCenterBenefits } from 'model/dataCenterBenefits';
import { getComparatorUrl } from 'modules/migrate/comparator/utils';

const StyledPage = styled.div`
  height: max-content;
`;

const Row = styled.div`
  margin: ${Dimensions.units(5)}px auto;
  max-width: 960px;
  font-family: ${Font.displayFamily};
`;

const ActionRow = styled(Row)`
  padding-bottom: ${Dimensions.units(5)}px;
  border-bottom: 1px solid ${colors.N40};
`;

const RowHeader = styled(Row)`
  margin-bottom: 0;
`;

const ActionRowParagraph = styled.p`
  padding-top: ${Dimensions.units(1)}px;
  padding-bottom: ${Dimensions.units(3)}px;
  font-size: 14px;
  line-height: 20px;
`;

const Column = styled.div`
  position: relative;
  padding: ${Dimensions.units(4)}px;
  min-height: 43rem;
  border: 1px solid ${colors.B400};
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25098), 0px 0px 0px 1px rgba(9, 30, 66, 0.0784314);
  border-radius: 3px;
`;

const MostPopularLabel = styled.span`
  position: absolute;
  top: -${Dimensions.units(1.25)}px;
  padding: 0 ${Dimensions.units(0.5)}px;
  color: ${colors.B500};
  background: ${colors.B75};
  border-radius: 3px;
`;

const DataCenterColumn = styled(Column)`
  border: none;
`;

const Title = styled.h1`
  font-weight: 500;
`;

const RenewUpgradeTitle = styled.h2`
  font-size: 29px;
  line-height: 32px;
  width: 70%;
`;

const ConsiderList = styled.ul`
  padding-left: ${Dimensions.units(2.5)}px;
  margin-bottom: ${Dimensions.units(2.5)}px;
  font-size: 12px;
  line-height: 25px;
  color: ${colors.N500};
`;

const BenefitsTitle = styled.h3`
  font-size: 14px;
  color: ${colors.N700} !important;
`;

const ColumnTitle = styled.h2`
  font-size: 22px;
  line-height: 46px;
  width: 70%;
`;

type Params = {
  [key: string]: any;
};

type StateProps = {
  crossgradeAvailable: Progress<boolean>;
  cloudUpgradeAvailable: Progress<boolean>;
  dataCenterMarketingUrl: string;
  params: Params | undefined;
  isUpgradePage?: boolean | false;
  isRenewPage?: boolean | false;
  isStarterLicense: boolean;
};

type DispatchProps = {
  onGoToTiers: () => void;
  onGoToServer: () => void;
  onGoToDatacenter: () => void;
  onGoToCloud: () => void;
  cloudInfoButtonClick: () => void;
  dcInfoButtonClick: () => void;
  cloudRoadmapLinkClick: () => void;
  serverEosInfoLinkClick: () => void;
};

type Props = StateProps & DispatchProps;

const onClick = (dispatcher) => (event) => {
  event.preventDefault();
  dispatcher();
};

const ActionRowMessage = (
  serverEosInfoLinkClick: () => void,
  message: React.ReactNode,
): React.ReactNode => (
  <ActionRowParagraph>
    {message}
    <a
      href={`${config.wacUrl}/migration/journey-to-cloud`}
      onClick={serverEosInfoLinkClick}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('change.server.option.renew-upgrade.single-server-deployment-link')}
    </a>
  </ActionRowParagraph>
);

const NoServerUpgradeRow = ({
  serverEosInfoLinkClick,
  hasDisabledServerLicenceFromMac,
}: {
  serverEosInfoLinkClick: () => void;
  hasDisabledServerLicenceFromMac: boolean;
}) =>
  hasDisabledServerLicenceFromMac ? (
    <>
      <GridColumn medium={6}>
        <RenewUpgradeTitle>{t('change.server.option.stop-upgrade.header')}</RenewUpgradeTitle>
      </GridColumn>
      <GridColumn medium={6}>
        {ActionRowMessage(
          serverEosInfoLinkClick,
          t('change.server.option.renew-upgrade.single-server-deployment-stop-upgrade'),
        )}
        <Button href="https://www.atlassian.com/migration" style={{ width: 'fit-content' }}>
          {t('change.server.option.stop-upgrade.tier.button')}
        </Button>
      </GridColumn>
    </>
  ) : (
    <>
      <GridColumn medium={6}>
        <RenewUpgradeTitle>{t('change.server.option.no-upgrade.header')}</RenewUpgradeTitle>
      </GridColumn>
      <GridColumn medium={6}>
        {ActionRowMessage(
          serverEosInfoLinkClick,
          t('change.server.option.renew-upgrade.single-server-deployment-no-upgrade'),
        )}
      </GridColumn>
    </>
  );

const ServerActionRow = (
  props: Props & {
    isServerUpgradeUnavailable: boolean;
    isDCAvailable: boolean;
  },
) => {
  const {
    params,
    onGoToTiers,
    isRenewPage,
    isUpgradePage,
    onGoToServer,
    serverEosInfoLinkClick,
    isServerUpgradeUnavailable,
    isDCAvailable,
  } = props;

  const hasCameFromMac = document.referrer.startsWith('https://my.');

  const hasDisabledServerLicenceFromMac =
    hasCameFromMac &&
    Object.values(ServerUpgradeDisabledKeys).some((key) =>
      (props.params?.productKey || '').includes(key),
    );

  let renewHeaderLabel = 'change.server.option.renew.header-cloud';
  let renewButtonLabel = 'change.server.option.continue-to-renew.license.button';
  let upgradeHeaderLabel = 'change.server.option.upgrade.header-cloud';
  let upgradeButtonLabel = 'change.server.option.continue-to-upgrade.license.button';
  if (hasCameFromMac) {
    renewHeaderLabel = 'change.server.option.renew.header';
    renewButtonLabel = 'change.server.option.renew.license.button';
    upgradeHeaderLabel = 'change.server.option.upgrade.header';
    upgradeButtonLabel = 'change.server.option.upgrade.tier.button';
  }

  const message = isDCAvailable
    ? t('change.server.option.renew-upgrade.single-server-deployment')
    : t('change.server.option.renew-upgrade.single-server-deployment-no-dc');
  return (
    <ActionRow>
      <Grid>
        {isServerUpgradeUnavailable || hasDisabledServerLicenceFromMac ? (
          <NoServerUpgradeRow
            serverEosInfoLinkClick={serverEosInfoLinkClick}
            hasDisabledServerLicenceFromMac={hasDisabledServerLicenceFromMac}
          />
        ) : (
          <>
            {isRenewPage && (
              <>
                <GridColumn medium={6}>
                  <RenewUpgradeTitle>{t(renewHeaderLabel)}</RenewUpgradeTitle>
                </GridColumn>
                <GridColumn medium={6}>
                  {ActionRowMessage(serverEosInfoLinkClick, message)}
                  <Button
                    href={getActionPath(renew.server.actions.route(params))}
                    onClick={onClick(onGoToServer)}
                    style={{ width: 'fit-content' }}
                  >
                    {t(renewButtonLabel)}
                  </Button>
                </GridColumn>
              </>
            )}
            {isUpgradePage && (
              <>
                <GridColumn medium={6}>
                  <RenewUpgradeTitle>{t(upgradeHeaderLabel)}</RenewUpgradeTitle>
                </GridColumn>
                <GridColumn medium={6}>
                  {ActionRowMessage(serverEosInfoLinkClick, message)}
                  <Button
                    href={getActionPath(tiers.actions.route(params))}
                    onClick={onClick(onGoToTiers)}
                    style={{ width: 'fit-content' }}
                  >
                    {t(upgradeButtonLabel)}
                  </Button>
                </GridColumn>
              </>
            )}
          </>
        )}
      </Grid>
    </ActionRow>
  );
};

const CloudColumn = (props: Props) => {
  const { onGoToCloud, cloudInfoButtonClick } = props;

  const comparatorUrl = getComparatorUrl();

  return (
    <Column>
      <ColumnTitle>{t('change.server.option.cloud.header')}</ColumnTitle>
      <MostPopularLabel>{t('change.main.most-popular')}</MostPopularLabel>
      <ConsiderList>
        {cloudConsiderList.map(({ description }) => (
          <li key={`${description}`}>{description}</li>
        ))}
      </ConsiderList>
      <Button
        appearance="primary"
        href={`${config.wacUrl}/migration/cloud/why-atlassian-cloud`}
        onClick={cloudInfoButtonClick}
        style={{ width: 'fit-content' }}
      >
        {t('upgrade.cloud.pricing')}
      </Button>
      <BenefitsTitle>{t('change.main.benefits')}</BenefitsTitle>
      <FeatureList features={cloudBenefits}>
        <a href={comparatorUrl} onClick={onGoToCloud} target="_blank" rel="noopener noreferrer">
          {t('upgrade.cloud.cloud-pricing')}
        </a>
      </FeatureList>
    </Column>
  );
};

const DatacenterColumn = (props: Props) => {
  const { params, onGoToDatacenter, dcInfoButtonClick, cloudRoadmapLinkClick } = props;
  return (
    <DataCenterColumn>
      <ColumnTitle>{t('upgrade.datacenter.column-title')}</ColumnTitle>
      <ConsiderList>
        <li>{t('product.dc.considers.enterprise-capabilities')}</li>
        <li>
          {t('product.dc.considers.critical-compliance')}
          <a
            href={`${config.wacUrl}/roadmap/cloud`}
            onClick={cloudRoadmapLinkClick}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('product.dc.considers.critical-compliance-link')}
          </a>
        </li>
      </ConsiderList>
      <Button
        href={`${config.wacUrl}/enterprise/data-center`}
        onClick={dcInfoButtonClick}
        style={{ width: 'fit-content' }}
      >
        {t('upgrade.datacenter.explore')}
      </Button>
      <BenefitsTitle>{t('change.main.benefits')}</BenefitsTitle>
      <FeatureList features={dataCenterBenefits}>
        <a
          href={getActionPath(datacenter.actions.route(params))}
          onClick={onGoToDatacenter}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('upgrade.datacenter.pricing')}
        </a>
      </FeatureList>
    </DataCenterColumn>
  );
};

export const VisualChangePage = (props: Props) => {
  const { crossgradeAvailable, cloudUpgradeAvailable, isStarterLicense } = props;

  const isServerUpgradeUnavailable =
    isStarterLicense &&
    Object.values(ServerProductDisabledKeys).some((key) =>
      (props.params?.productKey || '').includes(key),
    );

  return (
    Progress.all(crossgradeAvailable, cloudUpgradeAvailable).fold({
      failed: (e) => <GenericErrorMessage error={e} />,
      success: ([isDCAvailable, isCloudAvailable]) => {
        return (
          <StyledPage>
            <Page>
              <ServerActionRow
                {...props}
                isServerUpgradeUnavailable={isServerUpgradeUnavailable}
                isDCAvailable={isDCAvailable}
              />
              <RowHeader>
                <Grid>
                  <GridColumn medium={12}>
                    <Title>{t('change.main.title')}</Title>
                  </GridColumn>
                </Grid>
              </RowHeader>
              <Row>
                <Grid>
                  <GridColumn medium={6}>
                    {isCloudAvailable && <CloudColumn {...props} />}
                  </GridColumn>
                  <GridColumn medium={6}>
                    {isDCAvailable && <DatacenterColumn {...props} />}
                  </GridColumn>
                </Grid>
              </Row>
            </Page>
          </StyledPage>
        );
      },
    }) || <LoadingSpinner />
  );
};

const getButtonClickEventPayload = (
  uuId: string,
  parentLabel: string,
  label: string,
): UIEpicActionPayload => ({
  data: utils.getButtonClickEventData(uuId, UISource.Table),
  selectorsOrObjects: [getAdditionalAnalyticsAttributes(UISource.Table, uuId, parentLabel, label)],
});

const getLinkClickEventPayload = (
  uuId: string,
  parentLabel: string,
  label: string,
): UIEpicActionPayload => ({
  data: utils.getLinkClickEventData(uuId, UISource.Table),
  selectorsOrObjects: [getAdditionalAnalyticsAttributes(UISource.Table, uuId, parentLabel, label)],
});

const mapStateToProps = (state: State): StateProps => ({
  crossgradeAvailable: state.change.landing.crossgradeAvailable,
  cloudUpgradeAvailable: state.change.landing.cloudUpgradeAvailable,
  isStarterLicense: changeSelectors.selectIsStarterLicense(state),
  dataCenterMarketingUrl: changeSelectors.selectDataCenterMarketingUrl(state),
  params: state.location.query,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onGoToTiers: () => {
    dispatch(tiers.actions.routeIncludeParams());
    dispatch(
      emitUIEpicEvent(
        getButtonClickEventPayload('upgradeTierButton', 'Upgrade tier', 'Upgrade tier'),
      ),
    );
  },
  onGoToServer: () => {
    dispatch(renew.server.actions.routeIncludeParams());
    dispatch(
      emitUIEpicEvent(getButtonClickEventPayload('renewLicenseButton', 'Renew', 'Renew license')),
    );
  },
  onGoToDatacenter: () => {
    dispatch(
      emitUIEpicEvent(
        getLinkClickEventPayload('dcPricingLink', 'Data Center', 'View Data Center pricing'),
      ),
    );
  },
  onGoToCloud: () =>
    dispatch(
      emitUIEpicEvent(
        getLinkClickEventPayload('cloudPricingLink', 'Cloud', 'Explore cloud pricing'),
      ),
    ),
  cloudInfoButtonClick: () =>
    dispatch(
      emitUIEpicEvent(getButtonClickEventPayload('cloudInfoButton', 'Cloud', 'Learn why cloud')),
    ),
  dcInfoButtonClick: () =>
    dispatch(
      emitUIEpicEvent(
        getButtonClickEventPayload('dcInfoButton', 'Data Center', 'Learn more about Data Center'),
      ),
    ),
  cloudRoadmapLinkClick: () =>
    dispatch(
      emitUIEpicEvent(getLinkClickEventPayload('cloudRoadmapLink', 'Cloud', 'Cloud roadmap')),
    ),
  serverEosInfoLinkClick: () =>
    dispatch(
      emitUIEpicEvent(
        getLinkClickEventPayload(
          'serverEosInfoLink',
          'Server',
          'Learn more about the end of support for server',
        ),
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(VisualChangePage);
