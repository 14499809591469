import * as React from 'react';

import TotalPrice from 'modules/checkout/payment/components/TotalPrice';
import { PaymentPageProps } from 'modules/checkout/payment/duck/model/PaymentPageProps';

import CreditCardFormContainer from './CreditCardFormContainer';
import { Form, ErrorMessage } from 'modules/stripe-credit-card';
import styled from 'styled-components';

const ErrorMessageWithPadding = styled(ErrorMessage)`
  padding-top: 20px;
`;

const StripeTabContent = (props: PaymentPageProps) =>
  props.stripeCreditCard.isSelected && (
    <CreditCardFormContainer
      progress={{ success: true, fold: () => {} }}
      inSiteConfiguratorMode={props.inSiteConfiguratorMode}
    >
      <Form {...props.stripeCreditCard} />
      <ErrorMessageWithPadding />
      <TotalPrice {...props} />
    </CreditCardFormContainer>
  );

export default StripeTabContent;
