// @flow
import React from 'react';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import config from 'appconfig';
import t from 'modules/i18n/intl';

const PaypalErrorMessage = () => (
  <SectionMessage
    appearance="error"
    title={t('payment.paypal.unable-to-process')}
    actions={[
      {
        text: t('common.reload'),
        onClick: () => window.location.reload(true),
      },
      {
        text: t('common.contact-us'),
        href: `${config.wacUrl}/company/contact/purchasing-licensing`,
      },
    ].map(({ text, ...restAction }) => (
      <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
    ))}
  >
    {t('payment.paypal.retry')}
  </SectionMessage>
);

export default PaypalErrorMessage;
