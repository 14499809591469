/* eslint-disable consistent-return */
import { createSelector } from 'reselect';
import selectData from './selectData';

export default createSelector(
  selectData((d) => d.purchaserContactDetails.email),
  (s) => s.checkout.contacts.purchaserLoginValidation,
  (purchaserEmail, validation) => {
    if (purchaserEmail === validation.email) {
      return { 'purchaserContactDetails.email': validation.progress };
    }
  },
);
