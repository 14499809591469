// @flow
import Crypto from 'crypto-js';
import Progress from 'redux-progress';
import { createSelector } from 'reselect';
import type { PropsSelector } from 'modules/tracking/selectors/props';
import type { State } from 'model/State';
import { selectors as auth } from 'modules/auth';

const hash = (s) => Crypto.SHA256(s).toString();

const contactSelector: PropsSelector = createSelector(
  (s: State) => s.auth.currentContact,
  auth.getUserChannel,
  (c, uc) =>
    Progress.all(c, uc).map(
      ([contact, channel]) =>
        contact && {
          firstName: hash(contact.contactDetails.firstName),
          lastName: hash(contact.contactDetails.lastName),
          email: hash(contact.contactDetails.email),
          username: hash(contact.contactDetails.email),
          saleChannel: channel.isPartner ? 'partner' : 'direct',
        },
    ),
);
export default contactSelector;
