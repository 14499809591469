import t from 'modules/i18n/intl';
import { ProductFeature } from './Product';

const lowerCost: ProductFeature = {
  weight: 0,
  description: t('product.cloud.benefits.lower-cost'),
};

const flexiblePlans: ProductFeature = {
  weight: 5,
  description: t('product.cloud.benefits.flexible-plans'),
};

const optionsForPayment: ProductFeature = {
  weight: 10,
  description: t('product.cloud.benefits.options-for-payments'),
};

const modernUX: ProductFeature = {
  weight: 15,
  description: t('product.cloud.benefits.modern-ux'),
};

const builtInSecurity: ProductFeature = {
  weight: 20,
  description: t('product.cloud.benefits.built-in-security'),
};

const noManualUpdates: ProductFeature = {
  weight: 25,
  description: t('product.cloud.benefits.no-manual-upgrades'),
};

const greaterRD: ProductFeature = {
  weight: 30,
  description: t('product.cloud.benefits.greater-r&d'),
};

export const cloudBenefits: ProductFeature[] = [
  lowerCost,
  flexiblePlans,
  optionsForPayment,
  modernUX,
  builtInSecurity,
  noManualUpdates,
  greaterRD,
];
