export enum ServerProductKeys {
  BAMBOO = 'bamboo',
  BITBUCKET = 'bitbucket',

  CONFLUENCE = 'confluence',
  CONFLUENCE_QUESTIONS = 'com.atlassian.confluence.plugins.confluence-questions',
  CONFLUENCE_TEAM_CALENDARS = 'com.atlassian.confluence.extra.team-calendars',

  CROWD = 'crowd',
  CRUCIBLE = 'crucible',

  FISHEYE = 'fisheye',

  JIRA_SOFTWARE = 'jira-software',
  JIRA_SERVICE_DESK = 'jira-servicedesk',
  JIRA_CORE = 'jira-core',
  JIRA_PORTFOLIO = 'com.radiantminds.roadmaps-jira',
  JIRA_CAPTURE = 'com.atlassian.bonfire.plugin',
}

export enum AddonsWithReducedAllowedServerProductsKeys {
  FISHEYE = 'fisheye',
  CRUCIBLE = 'crucible',
}

export enum AddonsWithAllowedServerProductsKeys {
  CROWD = 'crowd',
  BAMBOO = 'bamboo',
  FISHEYE = 'fisheye',
  CRUCIBLE = 'crucible',
}

export enum ServerProductDisabledKeys {
  BITBUCKET = 'bitbucket',
  STASH = 'stash', // legacy bitbucket

  CONFLUENCE = 'confluence',
  CONFLUENCE_TEAM_CALENDARS = 'com.atlassian.confluence.extra.team-calendars',

  CROWD = 'crowd',

  JIRA_SOFTWARE = 'jira-software',
  JIRA_SERVICE_DESK = 'jira-servicedesk',
  JIRA_CORE = 'jira-core',
  JIRA_PORTFOLIO = 'com.radiantminds.roadmaps-jira',
  JIRA_INSIGHT_DISCOVERY = 'com.riadalabs.jira.plugins.insight-discovery',

  BAMBOO = 'bamboo',
}

export enum ServerUpgradeDisabledKeysWithoutProductKeys {
  JIRA_AUTOMATION = 'com.codebarrel.addons.automation',
  CONFLUENCE_ANALYTICS = 'com.addonengine.analytics',
  PROFORMA = 'com.thinktilt.proforma.add-on',
  CONFLUENCE_QUESTIONS = 'com.atlassian.confluence.plugins.confluence-questions',
}

export const ServerUpgradeDisabledKeys = {
  ...ServerProductDisabledKeys,
  ...ServerUpgradeDisabledKeysWithoutProductKeys,
};

export enum ExternalProductKeys {
  STATUSPAGE = 'statuspage',
  TECHNICAL_ACCOUNT_MANAGEMENT = 'tam',
  MARKETPLACE = 'marketplace',
  UNIVERSITY = 'university',
  ACCESS = 'access',
  GUARD = 'guard',
  ATLAS = 'atlas',
  JIRA_PRODUCT_DISCOVERY = 'jira-product-discovery',
}

export enum DataCenterProductKeys {
  BITBUCKET = 'bitbucket-data-center',
  CONFLUENCE = 'confluence-data-center',
  CONFLUENCE_QUESTIONS = 'com.atlassian.confluence.plugins.confluence-questions.data-center',
  CONFLUENCE_TEAM_CALENDARS = 'com.atlassian.confluence.extra.team-calendars.data-center',
  CROWD = 'crowd.data-center',
  JIRA_CAPTURE = 'com.atlassian.bonfire.plugin.data-center',
  JIRA_PORTFOLIO = 'com.radiantminds.roadmaps-jira.data-center',
  JIRA_SOFTWARE = 'jira-software.data-center',
  JIRA_SERVICE_DESK = 'jira-servicedesk.data-center',
  BAMBOO = 'bamboo-data-center',
}

export enum CloudProductKeys {
  BITBUCKET = 'com.atlassian.bitbucket',
  CONFLUENCE = 'confluence.ondemand',
  CONFLUENCE_QUESTIONS = 'com.atlassian.confluence.plugins.confluence-questions.ondemand',
  CONFLUENCE_TEAM_CALENDARS = 'team.calendars.confluence.ondemand',
  JIRA_CORE = 'jira-core.ondemand',
  JIRA_PORTFOLIO = 'com.radiantminds.roadmaps-jira.ondemand',
  JIRA_SERVICE_DESK = 'jira-servicedesk.ondemand',
  JIRA_SOFTWARE = 'jira-software.ondemand',
  JIRA_CAPTURE = 'bonfire.jira.ondemand',
}

export enum SupportProductKeys {
  PREMIER_SUPPORT = 'premier-support',
  PRIORITY_SUPPORT_SERVER = 'priority-support.server',
  PRIORITY_SUPPORT_DATA_CENTER = 'priority-support.data-center',
  PRIORITY_SUPPORT_DATA_CENTER_BITBUCKET = 'bitbucket-priority-support.data-center',
  PRIORITY_SUPPORT_DATA_CENTER_BAMBOO = 'bamboo-priority-support.data-center',
}

export enum ExtraProductKeys {
  SHAREPOINT_CONNECTOR = 'com.atlassian.confluence.extra.sharepoint',
  ATLASSIAN_CLOUD = 'atlassian.cloud',
}

// rewrite it so it could work with TypeScript
// const myObj = { a: 1, b: 'some_string' } as const;
// type values = typeof myObj[keyof typeof myObj];
export type CloudProductKey = $Values<typeof CloudProductKeys>;
export type ServerProductKey = $Values<typeof ServerProductKeys>;
export type ExternalProductKey = $Values<typeof ExternalProductKeys>;
export type DataCenterProductKey = $Values<typeof DataCenterProductKeys>;
export type SupportProductKey = $Values<typeof SupportProductKeys>;
export type ExtraProductKey = $Values<typeof ExtraProductKeys>;
export type ServerUpgradeDisabledKey = $Values<typeof ServerUpgradeDisabledKeys>;
export type ServerAddonsProductsKeys = $Values<typeof AddonsWithAllowedServerProductsKeys>;

export type LogoProductKey = ExternalProductKey | DataCenterProductKey;
export type ProductKey =
  | CloudProductKey
  | ServerProductKey
  | ExternalProductKey
  | DataCenterProductKey
  | SupportProductKey
  | ExtraProductKey
  | ServerUpgradeDisabledKey
  | ServerAddonsProductsKeys;

export enum ProductGroupKeys {
  BITBUCKET = 'bitbucket',
  BAMBOO = 'bamboo',
  JIRA = 'jira',
  JIRA_CORE = 'jira-core',
  JIRA_SOFTWARE = 'jira-software',
  JIRA_SERVICE_DESK = 'jira-servicedesk',
  JIRA_PORTFOLIO = 'com.radiantminds.roadmaps-jira',
  JIRA_CAPTURE = 'com.atlassian.bonfire.plugin',
  JIRA_PRODUCT_DISCOVERY = 'jira-product-discovery',
  CONFLUENCE = 'confluence',

  FISHEYE = 'fisheye',
  CRUCIBLE = 'crucible',
  CROWD = 'crowd',

  CONFLUENCE_TEAM_CALENDARS = 'com.atlassian.confluence.extra.team-calendars',
  CONFLUENCE_QUESTIONS = 'com.atlassian.confluence.plugins.confluence-questions',

  LEGACY_PRIORITY_SUPPORT = 'legacy-priority-support',
  PRIORITY_SUPPORT = 'priority-support',
  PRIORITY_SUPPORT_BITBUCKET = 'priority-support-bitbucket',
  PRIORITY_SUPPORT_BAMBOO = 'priority-support-bamboo',
}

export type ProductGroupKey = $Values<typeof ProductGroupKeys>;

export const LegacyKeysDictionary: {
  [key: string]: ProductKey;
} = {
  stash: ServerProductKeys.BITBUCKET,
  'stash-data-center': DataCenterProductKeys.BITBUCKET,
  jira: ServerProductKeys.JIRA_SOFTWARE,
  'com.pyxis.greenhopper.jira': ServerProductKeys.JIRA_SOFTWARE,
  'com.atlassian.servicedesk': ServerProductKeys.JIRA_SERVICE_DESK,
  'sharepoint.connector': ExtraProductKeys.SHAREPOINT_CONNECTOR,
};

export const ApiKeysDictionary: {
  [key: string]: string;
} = {
  [ServerProductKeys.BITBUCKET]: 'stash',
  [DataCenterProductKeys.BITBUCKET]: 'stash-data-center',
};

export function mapProductKeyToLegacyProductKey(productKey: ProductKey): ProductKey {
  if (productKey in LegacyKeysDictionary) {
    return LegacyKeysDictionary[productKey];
  }
  return productKey;
}

export const Supported20KServerProducts: ServerProductKey[] = [
  ServerProductKeys.JIRA_SOFTWARE,
  ServerProductKeys.CONFLUENCE,
];

export const EnterpriseAvailableServerProducts: ServerProductKey[] = [
  ServerProductKeys.JIRA_SOFTWARE,
  ServerProductKeys.JIRA_CORE,
  ServerProductKeys.JIRA_SERVICE_DESK,
  ServerProductKeys.CONFLUENCE,
  ServerProductKeys.CONFLUENCE_QUESTIONS,
];

export const Supported20KCloudProducts: CloudProductKey[] = [
  CloudProductKeys.JIRA_SOFTWARE,
  CloudProductKeys.CONFLUENCE,
];

export const IntercomEnabledProducts: CloudProductKey[] = [
  CloudProductKeys.JIRA_SOFTWARE,
  CloudProductKeys.JIRA_SERVICE_DESK,
  CloudProductKeys.JIRA_CORE,
  CloudProductKeys.CONFLUENCE,
];

export type PricingPlan = 'Free' | 'Standard' | 'Premium';

export type PricingPlansList = PricingPlan[];
