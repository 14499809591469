import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';

import config from 'appconfig';
import Login from 'modules/addon/Login';
import { Props } from 'modules/addon/VisualAddonPage';
import Parent from 'modules/addon/Parent';
import CommonNavLink from 'components/common/NavLink';
import * as configure from 'modules/configure';
import t from 'modules/i18n/intl';
import SoftwareProduct from 'model/SoftwareProduct';

const NavLink = styled(CommonNavLink)`
  font-weight: 500;
`;

const ChooseParentsList = (props) =>
  props.data.chooseParentOptions
    ? props.data.chooseParentOptions.map((parent) => (
        <Parent
          key={parent.key}
          parent={parent}
          isSelected={props.data.chosenParent === parent}
          {...props}
        />
      ))
    : null;

const ChooseParentBody = (props: Props) => {
  const loginParams = queryString.stringify({ continue: window.location.href });
  const loginUrl = `${config.idLoginUrl}?${loginParams}`;
  return (
    <>
      <div>
        <ChooseParentsList {...props} />
      </div>
      {!props.data.isAuthenticated &&
        props.data.chooseParentOptions?.filter((p) => !!p.license).length === 0 && (
          <div>
            <FormattedMessage
              id="addon.choose.login-to-attach"
              values={{
                loginHere: (
                  <a href={loginUrl}>
                    <FormattedMessage id="addon.choose.login-to-attach.login-here" />
                  </a>
                ),
              }}
            />
          </div>
        )}
    </>
  );
};

const BuyProductsTogether = ({
  data,
  suggestedParent,
}: Props & { suggestedParent: SoftwareProduct }) => (
  <div>
    <FormattedMessage
      id="addon.choose.not-in-account"
      values={{ name: suggestedParent.name as any }}
    />
    &nbsp;
    <NavLink to={configure.actions.goProductConfig(suggestedParent.key, data.productKeys)}>
      <FormattedMessage
        id="addon.choose.not-in-account.cta"
        values={{
          product: data.suggestedParent && (data.suggestedParent.name as any),
          addon: data.pricing[0].productDescription,
        }}
      />
      &nbsp; »
    </NavLink>
  </div>
);

const ChooseParent = (props: Props) => {
  if (
    props.data.isAuthenticated ||
    (props.data.chooseParentOptions && props.data.chooseParentOptions.length > 0)
  ) {
    return props.data.chooseParentOptions?.length === 0 && props.data.suggestedParent ? (
      <BuyProductsTogether suggestedParent={props.data.suggestedParent} {...props} />
    ) : (
      <ChooseParentBody {...props} />
    );
  }

  return <Login>{t('addon.choose.login-reason')}</Login>;
};

export default ChooseParent;
