import React from 'react';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Info from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import t from 'modules/i18n/intl';

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onDismissed: () => void;
};

export default (props: Props) => {
  if (props.isOpen) {
    return (
      <FlagGroup onDismissed={props.onDismissed}>
        <Flag
          icon={<Info label="Info icon" primaryColor={colors.P300} />}
          id="complete-survey-flag"
          title={t('survey.feedback-is-vital')}
          description={t('survey.take-a-quick-survey')}
          actions={[
            {
              content: t('survey.open-the-survey'),
              onClick: props.onOpen,
            },
          ]}
        />
      </FlagGroup>
    );
  }
  return null;
};
