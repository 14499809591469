/* eslint-disable import/prefer-default-export */

import Progress from 'redux-progress';
import TnsSession from 'modules/checkout/payment/duck/api/TnsSession';
import { AutoRenewEligibility } from 'model/hams/AutoRenewEligibility';

export enum CheckoutModes {
  STRIPE_CREDIT_CARD = 'STRIPE_CREDIT_CARD',
  CREDIT_CARD = 'CREDIT_CARD',
  PAYPAL = 'PAYPAL',
  BANK_TRANSFER = 'BANK_TRANSFER',
  PAY_ON_ACCOUNT = 'PAY_ON_ACCOUNT',
  PAY_ON_TERMS = 'PAY_ON_TERMS',
  FREE = 'FREE',
}

export type CheckoutModesType = typeof CheckoutModes[keyof typeof CheckoutModes];

export type CreditCardState = {
  configureProgress: Progress<TnsSession>;
  tnsFocus: string;
  cardName: string;
  expiryMonth: string;
  expiryYear: string;
};
export type PaypalState = {
  failed: boolean;
};

export type HamsCheckoutMethodAvailability = {
  free: boolean;
  onAccount: boolean;
  onTerms: boolean;
  paypal: boolean;
  quote: boolean;
  tns: boolean;
  stripe?: boolean;
};

export type PaymentState = {
  checkoutMode: CheckoutModesType;
  creditCard: CreditCardState;
  paypal: PaypalState;
  eligibleAutoRenewal: Progress<AutoRenewEligibility>;
  selectedAutoRenewItems: number[];
  submission: Progress<void>;
  payOnTermAgreed: boolean;
  checkoutMethodsAvailable: Progress<HamsCheckoutMethodAvailability | null>;
};
