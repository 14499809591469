import { UIAction, UIActionSubject } from 'modules/analytics/model/UIEvent';

export enum AnalyticsTrigger {
  SearchBySEN = 'searchBySEN',
  ClearSearchBySEN = 'clearSearchBySEN',
}

export const analyticsEvents = {
  [AnalyticsTrigger.SearchBySEN]: (analytics, { isPartner }) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'searchBySENButton',
      attributes: {
        eventContainer: 'searchBySENBar',
        eventComponent: UIActionSubject.Button,
        parentLabel: 'searchBySENBar',
        label: 'SearchBySEN',
        uuid: '6d693df9-d962-49f4-ad2a-3367c8e0a23e',
        isPartner,
      },
    });
  },
  [AnalyticsTrigger.ClearSearchBySEN]: (analytics, { isPartner }) => {
    analytics.sendUiEvent({
      action: UIAction.Clicked,
      actionSubject: UIActionSubject.Button,
      actionSubjectId: 'clearSearchBySENButton',
      attributes: {
        eventContainer: 'searchBySENBar',
        eventComponent: UIActionSubject.Button,
        parentLabel: 'searchBySENBar',
        label: 'ClearSearchBySEN',
        uuid: '4fa1f0d9-3ceb-490f-a7ea-fa58b60769b7',
        isPartner,
      },
    });
  },
};
