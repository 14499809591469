/* eslint-disable import/prefer-default-export */
// @flow
import { createSelector } from 'reselect';
import { selectProductAndSelectedAddonTiers } from './tiers';
import { selectLicenseType } from './configurationType';

export const selectOrderableItems = createSelector(
  selectProductAndSelectedAddonTiers,
  selectLicenseType,
  (tiers, licenseType) => tiers.map((tier) => tier.getOrderableItem(licenseType)),
);
