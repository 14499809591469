// @flow
import { thunkProgress } from 'redux-progress';
import hamsClient from 'modules/hams';
import { PLACE_ORDER } from 'modules/checkout/review/duck/actions/submit';
import type { Dispatch, GetState } from 'model/State';
import * as payment from 'modules/checkout/payment';
import { selectHamsClientConfig } from 'modules/auth/selectors';
import { addUpdatedTimeStamp } from 'modules/checkout/review/duck/util';

import { CreditCardPaymentDetails } from 'modules/checkout/payment/duck/model/CreditCardPaymentDetails';
import * as Gateways from 'modules/checkout/payment/duck/model/Gateways';

import * as CheckoutTypes from './CheckoutTypes';
import type { CheckoutType } from './CheckoutTypes';

const getCreditCardData = (checkoutType: CheckoutType) => {
  const paymentDetails = payment.api.storage.paymentDetails.load();

  switch (checkoutType) {
    case CheckoutTypes.STRIPE: {
      return {
        gateway: paymentDetails.gateway,
        creditCardToken: paymentDetails.paymentMethodId,
        creditCardMaskedNumber: paymentDetails.gatewayCardNumberMasked,
        creditCardName: paymentDetails.gatewayCardName,
        creditCardExpiryMonth: paymentDetails.gatewayCardExpiryDateMonth,
        creditCardExpiryYear: paymentDetails.gatewayCardExpiryDateYear,
        creditCardType: paymentDetails.gatewayCardScheme,
      };
    }
    default: {
      const sessionId = payment.api.storage.sessionId.load();
      return {
        gateway: Gateways.TNS,
        creditCardSessionId: sessionId,
        creditCardMaskedNumber: paymentDetails.gatewayCardNumberMasked,
        creditCardName: paymentDetails.gatewayCardName,
        creditCardExpiryMonth: paymentDetails.gatewayCardExpiryDateMonth,
        creditCardExpiryYear: +paymentDetails.gatewayCardExpiryDateYear + 2000,
        creditCardType: paymentDetails.gatewayCardScheme,
      };
    }
  }
};

// eslint-disable-next-line max-len
export default (cartId: string, checkoutType: CheckoutType) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const state = getState();

  const creditCardData: CreditCardPaymentDetails = {
    creditCardDetails: getCreditCardData(checkoutType),
    termsOfServiceAccepted: state.checkout.review.eulaAccepted,
  };
  const data = addUpdatedTimeStamp(creditCardData, state);

  return dispatch(
    thunkProgress(
      PLACE_ORDER,
      hamsClient
        .post(
          `/1.0/public/order/cart/${cartId}/processOrderWithCreditCard`,
          data,
          selectHamsClientConfig(state),
        )
        .then((r) => r),
      { checkoutType },
    ),
  );
};
