import React from 'react';
import styled from 'styled-components';
import Modal, { ModalBody } from '@atlaskit/modal-dialog';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const Body = styled.div`
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 16px;
`;

export default (props: Props) => {
  if (props.isOpen) {
    return (
      <Modal width={800} onClose={props.onClose}>
        <ModalBody>
          <Body>
            <iframe
              title="Qualtrics survey"
              width="100%"
              height={600}
              src="//atlassian.co1.qualtrics.com/jfe/form/SV_2lqWARB6uXP1l8p"
              frameBorder="0"
            />
          </Body>
        </ModalBody>
      </Modal>
    );
  }
  return null;
};
