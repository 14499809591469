// @flow
/* eslint-disable max-classes-per-file */
import Progress from 'redux-progress';
import mapValues from 'lodash/mapValues';

export class FieldValidity {
  +isValid: boolean;
  +error: Object;
  +isValidating: boolean;
  +progress: Progress<any>;

  constructor(progress: Progress<any>) {
    this.progress = progress;
    this.isValid = !progress.failed;
    this.isValidating = progress.inProgress;
    this.error = progress.error || {};
  }
}

const none = new FieldValidity(Progress.none);

export default class FormValidity {
  get(path) {
    return this.fields[path] || none;
  }

  +fields: { [string]: FieldValidity };

  constructor(errors: { [string]: Progress<any> } = {}) {
    this.fields = mapValues(errors, (progress) => new FieldValidity(progress));
  }

  get isAllValid() {
    return Object.values(this.fields).every((f: any) => f.isValid);
  }
}
/* eslint-enable max-classes-per-file */
