import { SearchStateProps } from 'modules/change/search/duck/model';
import { Selector } from 'model/State';

const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const selectIsEmailValid: Selector<boolean> = (state) =>
  state.change.search.email === null || EMAIL_REGEXP.test(state.change.search.email);

export const selectIsSenValid: Selector<boolean> = (state) =>
  state.change.search.sen === null || state.change.search.sen.length > 0;

export const mapStateToProps: Selector<SearchStateProps> = (state) => ({
  isEmailValid: selectIsEmailValid(state),
  isSenValid: selectIsSenValid(state),
  ...state.change.search,
});
