import { AxiosRequestConfig } from 'axios';
import hamsClient from 'modules/hams';
import { AutoRenewItem } from 'modules/cart/duck/actions';

export default async (cartUuid: string, items: AutoRenewItem[], clientConfig: AxiosRequestConfig) =>
  (
    await hamsClient.post(
      `/1.0/public/order/cart/${cartUuid}/setAutoRenewForCartItems`,
      { autoRenewalCartItemEntities: items },
      clientConfig,
    )
  ).data;
