// @flow
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Font } from 'components/visuals';
import { getLanguageLocale } from 'modules/i18n/duck/api';

type Props = {
  amount: number | React.ReactNode,
  currency?: string,
  format?: 'symbol' | 'code',
  size?: string,
  dataRole?: string | null,
  useSpace?: boolean,
  weight?: number | string,
};

const Wrapper = styled.span`
  font-size: ${(props) => props.size};
  font-family: ${Font.displayFamily};
  font-weight: ${(props) => props.weight};
`;

const AmountWithCurrency = (props: Props) => {
  const amountString = useMemo(() => {
    const formattedStr = new Intl.NumberFormat(getLanguageLocale(), {
      style: 'currency',
      currencyDisplay: props.format || 'symbol',
      currency: props.currency ? props.currency : 'USD', // default null currency values from HAMS
    })
      .format(props.amount)
      .replace(/\u00A0+/, props.useSpace ? ' ' : ''); // replace &nbsp; with normal space
    return formattedStr;
  }, [props.format, props.currency, props.amount, props.useSpace]);
  return (
    <Wrapper {...props} data-role={props.dataRole}>
      {amountString}
    </Wrapper>
  );
};

AmountWithCurrency.defaultProps = {
  size: '16px',
  dataRole: null,
  format: 'symbol',
  useSpace: false,
  weight: 500,
};

export default AmountWithCurrency;
