/**
 * This relatively unknown behaviour of the Shopping Cart is documented
 * in https://hello.atlassian.net/wiki/x/_waDEg
 */
import Progress, { thunkProgress } from 'redux-progress';
import hamsClient from 'modules/hams';
import { CartThunk } from 'model/State';
import { selectHamsClientConfig } from 'modules/auth/selectors';

export const FETCH_LICENSES = 'checkout/complete/licenses/FETCH';

export const fetchLicenses = (
  cartId: string,
  cartItemIdToAssociateWithServerId: string,
  serverId: string,
): CartThunk<Promise<Progress<any>>> => (dispatch, getState) => {
  const licenses = hamsClient.get(`/1.0/public/order/licenses/${cartId}/all`).then((response) => {
    const cartItem = response.data.purchasedProductLicenses.find(
      (item) => `${item.cartItemId}` === `${cartItemIdToAssociateWithServerId}`,
    );
    if (cartItem) {
      hamsClient.post(
        `/1.0/public/account/${cartItem.accountId}/associate`,
        { serverId },
        selectHamsClientConfig(getState()),
      );
    }
    return response.data;
  });
  return dispatch(thunkProgress(FETCH_LICENSES, licenses));
};
