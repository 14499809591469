/* eslint-disable func-names */
// @flow
import { progressResult } from 'util/rxOperators';
import Progress from 'redux-progress';
import toUpper from 'lodash/toUpper';
import { combineEpics } from 'redux-observable';
import { flatMap, filter, distinctUntilChanged } from 'rxjs/operators';
import * as selectors from 'modules/checkout/contacts/duck/selectors';
import * as actions from 'modules/checkout/contacts/duck/actions';
import type { EnhancedOrganisation } from 'modules/checkout/contacts/duck/model';
import type { State, State$ } from 'model/State';

const shouldMakeUppercase = (org: EnhancedOrganisation) =>
  Boolean(org.country && org.country.stateNames && org.country.stateNames.length > 0);

const makeOrgStateEpic = (getOrg: (State) => Progress<EnhancedOrganisation>, path: string) => (
  action$: *,
  state$: State$,
) =>
  state$.pipe(
    progressResult(getOrg),
    distinctUntilChanged(),
    filter(shouldMakeUppercase),
    // $FlowFixMe flatMap
    flatMap(function* (org) {
      const upperState = toUpper(org.state);
      if (upperState !== org.state) {
        yield actions.edit(path, upperState);
      }
    }),
  );

export default combineEpics(
  makeOrgStateEpic(selectors.getBillingOrganisation, 'billingOrganisationDetails.state'),
  makeOrgStateEpic(selectors.getTechnicalOrganisation, 'technicalOrganisationDetails.state'),
);
