import Progress from 'redux-progress';
import * as actions from 'modules/cart/duck/actions';
import * as changeActions from 'modules/change/table/duck/actions';

export default (state = Progress.none, action) => {
  switch (action.type) {
    case actions.FETCH_CART:
      return action.progress;
    case actions.UPDATE_CURRENCY:
      return action.progress;
    case actions.SET_DETAILS:
    case actions.ADD_PRODUCT_TO_CART:
    case actions.SET_AUTO_RENEW_ITEMS:
    case actions.CART_DELETE_ITEM:
    case actions.CLEAR_CART:
    case changeActions.SUBMIT_CHANGE_LIST:
      if (action.progress.success && !action.progress.result.errorKey) {
        return action.progress;
      }
      return state;
    case actions.RESET_CART:
      return Progress.success(null);
    case actions.SWITCH_TO_DIFFERENT_CART:
      return action.payload;
    default:
      return state;
  }
};
