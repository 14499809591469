import t from 'modules/i18n/intl';

const ccPossibleErrors = {
  declinedCard: 'Your card was declined.',
  expiredCard: 'Your card has expired.',
  incorectCvc: "Your card's security code is incorrect.",
  errorWhileProcessing: 'An error occurred while processing your card. Try again in a little bit.',
};

export const getRightErrorMessage = (ccErrorMessage): String => {
  switch (ccErrorMessage) {
    case ccPossibleErrors.declinedCard:
      return t('payment.error-declined-card');
    case ccPossibleErrors.expiredCard:
      return t('payment.error-expired-card');
    case ccPossibleErrors.incorectCvc:
      return t('payment.error-incorect-cvc');
    case ccPossibleErrors.errorWhileProcessing:
      return t('payment.error-while-processing');
    default:
      return t('payment.error-processing-payment');
  }
};
