import hamsClient from 'modules/hams';

export type FetchPaymentInfoInput = {
  uuid: string;
};

export type FetchPaymentInfoData = {
  paymentIntentClientSecret: string;
  paymentMethodId: string;
  stripeKey: string;
};

const fetchPaymentInfo = async ({ uuid }: FetchPaymentInfoInput): Promise<FetchPaymentInfoData> => {
  const { data } = await hamsClient.get(`/1.0/public/order/cart/${uuid}/fetchPaymentInfo`);
  return data;
};

export default fetchPaymentInfo;
