/* eslint-disable default-case */
// @flow
import * as actions from 'modules/marketplace/search/duck/actions';
import { AMOUNT_PER_PAGE } from 'modules/marketplace/search/duck/contants';
import type { MarketplaceSearchParams } from 'modules/catalog/addons';

const initial: MarketplaceSearchParams = {
  text: '',
  offset: 0,
  limit: AMOUNT_PER_PAGE,
};

export default (state: MarketplaceSearchParams = initial, action: *) => {
  switch (action.type) {
    case actions.FETCH_SEARCH:
    case actions.FETCH_MORE_SEARCH:
      if (action.progress.success) {
        return action.params;
      }
  }
  return state;
};
