import { State } from 'model/State';
import { getFromHamsClient } from 'util/mac/api/contact/get';
import { postToHamsClient } from 'util/mac/api/contact/post';
import { putToHamsClient } from 'util/mac/api/contact/put';
import { fetchWithParamValidation } from 'util/fetchWithParamValidation';
import { MACCurrentContactData } from '../models';
import { selectSwitchedUser } from '../selectors';
import { getCurrentContactEmail } from 'modules/auth/selectors';

export const getCustomerByEmail = async (
  email: string,
  includeDeactivated: boolean,
  state: State,
) =>
  fetchWithParamValidation(
    ({ userEmail, includeDeactivatedParam }) =>
      getFromHamsClient(`/email/${userEmail}?includeDeactivated=${includeDeactivatedParam}`, state),
    { userEmail: email, includeDeactivatedParam: includeDeactivated },
  );

export const isMemberOfGroup = (groupName: string, state: State) => {
  const currentUserEmail = getCurrentContactEmail(state) as string;

  return fetchWithParamValidation(
    ({ userEmail, group }) => getFromHamsClient(`/email/${userEmail}/isMemberOf/${group}`, state),
    { userEmail: currentUserEmail, group: groupName },
  );
};

export const isEmail = (state: State) => {
  const contactEmail = selectSwitchedUser(state);
  return fetchWithParamValidation(
    ({ userEmail }) => postToHamsClient(`/email/${userEmail}`, state, { email: userEmail }),
    {
      userEmail: contactEmail,
    },
  );
};

export const external = (state: State, data: MACCurrentContactData) => putToHamsClient(state, data);
