import { connect, useDispatch } from 'react-redux';
import Select from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import React, { useState } from 'react';
import styled from 'styled-components';
import { fetchSearchForContactPost } from '../duck/actions/orders';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { Dimensions } from '../../../components/visuals';
import { colors } from '@atlaskit/theme';
import Button from '@atlaskit/button';

const SearchContainer = styled.div`
  display: flex;
  gap: ${Dimensions.units(0.25)}px;
`;
export enum SearchType {
  ORDER_NUMBER = 'orderNumber',
  TOTAL_PRICE = 'totalPrice',
  COMPANY_DOMAIN = 'companyDomain',
}

const searchOptions = [
  { value: SearchType.ORDER_NUMBER, label: 'Order number' },
  { value: SearchType.TOTAL_PRICE, label: 'Total price' },
  { value: SearchType.COMPANY_DOMAIN, label: 'Company domain' },
];

const selectStyles = {
  control: (provided) => ({
    ...provided,
    background: `${colors.N10}`,
    minHeight: `${Dimensions.units(3.75)}px`,
    width: `${Dimensions.units(20.5)}px`,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: `${Dimensions.units(3.75)}px`,
  }),
};

const OrderSearch = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(searchOptions[0]);
  const [searchInputText, setSearchInputText] = useState('');
  const [placeholder, setPlaceholder] = useState('123456789');

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    setSearchInputText('');
    switch (option.value) {
      case SearchType.COMPANY_DOMAIN:
        setPlaceholder('atlassian.com');
        break;
      case SearchType.TOTAL_PRICE:
        setPlaceholder('1000');
        break;
      default:
        setPlaceholder('123456789');
    }
  };
  const handleSearchInputTextChange = (e, option) => {
    let newValue = e.target.value;
    if (option.value === SearchType.ORDER_NUMBER || option.value === SearchType.TOTAL_PRICE) {
      newValue = newValue.replace(/\D/g, '');
    }
    setSearchInputText(newValue);
  };

  const submitSearchUpdate = (value, option) => {
    const orderNumberMask = option.value === SearchType.ORDER_NUMBER ? value : undefined;
    const integerPartOfTotalAmount = option.value === SearchType.TOTAL_PRICE ? value : undefined;
    const technicalContactCompanyDomainName =
      option.value === SearchType.COMPANY_DOMAIN ? value : undefined;

    dispatch(
      fetchSearchForContactPost(
        100,
        0,
        orderNumberMask,
        technicalContactCompanyDomainName,
        integerPartOfTotalAmount,
      ),
    );
  };

  return (
    <>
      <SearchContainer>
        <Select
          options={searchOptions}
          defaultValue={searchOptions[0]}
          styles={selectStyles}
          onChange={handleSelectChange}
          value={selectedOption}
        />
        <Textfield
          placeholder={placeholder}
          style={{ height: `${Dimensions.units(3.25)}px`, width: `${Dimensions.units(22.5)}px` }}
          onKeyPress={(e) =>
            e.key === 'Enter' && submitSearchUpdate(searchInputText, selectedOption)
          }
          onChange={(e) => handleSearchInputTextChange(e, selectedOption)}
          value={searchInputText}
          data-cy="search-order-input"
        />
        <Button
          style={{
            border: `1px solid ${colors.N100}`,
            borderRadius: '3px',
            height: '100%',
            padding: '0px 5px',
            backgroundColor: `${colors.N30}`,
          }}
          data-cy="mac-search-invoice-btn"
          onClick={() => submitSearchUpdate(searchInputText, selectedOption)}
        >
          <SearchIcon size="small" primaryColor={colors.N300} label="" />
        </Button>
      </SearchContainer>
    </>
  );
};

export default connect(null, null)(OrderSearch);
