import React from 'react';
import { connect } from 'react-redux';
import { Intl } from 'modules/i18n/intl';
import type { State } from 'model/State';
import { selectProductPricing } from 'modules/catalog/pricing';
import { getProduct } from 'data/products';
import Product from 'model/Product';
import type { HamsProductPricing } from 'model/hams/Pricing';
import Progress from 'redux-progress';

type OwnProps = {
  productKey: string;
};

type Props = {
  product?: Product;
  pricing: HamsProductPricing;
};

const VisualProductTitle = ({ product, pricing }: Props) => {
  if (product) {
    const name = product.key === 'jira-software.ondemand' ? 'Jira' : product.name;
    return (
      <>
        {name}
        {pricing.dataCenter && <Intl>{(intl) => ` (${intl`purchase.data-center`})`}</Intl>}
      </>
    );
  }

  return (pricing && pricing.productDescription) || null;
};

const mapFromState = (state: State, props: OwnProps) => ({
  progress: selectProductPricing(state, props).map((pricing) => ({
    product: getProduct(props.productKey),
    pricing,
  })),
});

export default connect(mapFromState)(({ progress }: { progress: Progress<unknown> }) =>
  progress.ifSuccess((result) => {
    if (!(typeof result === 'object' && 'pricing' in (result as object))) return null;

    return <VisualProductTitle {...(result as Props)} />;
  }),
);
