import config from 'appconfig';

export default async (isoCountryCode: string) => {
  const result = await fetch(
    `${config.hamsUrl}/1.0/public/country/taas/findByIso/${isoCountryCode}`,
  );
  if (!result.ok) {
    throw Error('Unable to fetch states');
  }
  return result.json();
};
