import reportError from 'util/errorReporter';
import cookies from 'js-cookie';

export const getCohortInformation = () => {
  const cohortCookieVal = cookies.get('atlCohort');
  if (typeof cohortCookieVal === 'string') {
    try {
      return JSON.parse(decodeURIComponent(cohortCookieVal));
    } catch (e) {
      reportError(e);
    }
  }
  return undefined;
};

export const getCohortId = () => {
  try {
    const data = getCohortInformation();
    if (data && data.bucketAll && data.bucketAll.index) {
      return data.bucketAll.index;
    }
  } catch (e) {
    reportError(e);
  }

  return undefined;
};
