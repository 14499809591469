import Cookies from 'js-cookie';

const ids = (prefix: string) => ({
  load: () => Cookies.get(`${prefix}_id`) || null,
  store: (id: string) => {
    Cookies.set(`${prefix}_id`, id, { path: '/purchase', expires: 180 });
  },
  reset: () => {
    Cookies.remove(`${prefix}_id`, { path: '/purchase' });
  },
});

export const cartId = ids('cart');
export const editCartId = ids('edit_cart');
