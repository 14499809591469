// @flow
import { combineReducers } from 'redux';
import entries from './entries';
import selectedProduct from './selectedProduct';
import query from './query';
import params from './params';
import hasMore from './hasMore';

export default combineReducers({
  entries,
  selectedProduct,
  query,
  params,
  hasMore,
});
