import Progress from 'redux-progress';

import {
  DETAILS,
  IS_ON_BEHALF_OF_EMAIL,
  IS_SITE_ON_CONCESSION,
  IS_SITE_APPLICABLE_FOR_CONCESSION,
  CHILDREN_OVERVIEWS,
  CMT_SELF_SERVE_LINK,
} from 'modules/mac/duck/actions/accountId/types';
import type { MACAccountIdAction, MACAccountIdState } from '../models';

export const initialState = {
  details: Progress.none,
  childrenOverviews: Progress.none,
  isOnBehalfOfEmail: Progress.none,
  isSiteOnConcession: Progress.none,
  CMTSelfServeLink: Progress.none,
  isSiteApplicableForConcession: Progress.none,
};

export default (
  state: MACAccountIdState = initialState,
  action: MACAccountIdAction,
): MACAccountIdState => {
  switch (action.type) {
    case DETAILS:
      return {
        ...state,
        details: { ...state.details, [action.accountId]: action.progress },
      };
    case CHILDREN_OVERVIEWS:
      return {
        ...state,
        childrenOverviews: action.progress,
      };
    case IS_ON_BEHALF_OF_EMAIL:
      return {
        ...state,
        isOnBehalfOfEmail: action.progress,
      };
    case IS_SITE_ON_CONCESSION:
      return {
        ...state,
        isSiteOnConcession: action.progress,
      };
    case CMT_SELF_SERVE_LINK:
      return {
        ...state,
        CMTSelfServeLink: action.progress,
      };
    case IS_SITE_APPLICABLE_FOR_CONCESSION:
      return {
        ...state,
        isSiteApplicableForConcession: action.progress,
      };
    default:
      return state;
  }
};
