import React from 'react';
import Page from '@atlaskit/page';

import { Authenticated } from './Authenticated';
import Recommendations from 'modules/migrate/recommendations/Recommendations';
import { AnalyticsWrapper } from 'modules/migrate/analytics/AnalyticsContext';

export const MigratePage = () => (
  <Authenticated>
    <AnalyticsWrapper>
      <Page>
        <Recommendations />
      </Page>
    </AnalyticsWrapper>
  </Authenticated>
);
