/* eslint-disable react/jsx-no-target-blank */
// @flow
import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import AkSelect from '@atlaskit/select';
import type { RowProps } from 'modules/change/landing/duck/model';
import PriceInfo from 'modules/upgrade/components/PriceInfo';
import t from 'modules/i18n/intl';
import SelectWithExtraInfo from 'components/common/SelectWithExtraInfo';
import AmountWithCurrency from 'components/common/AmountWithCurrency';
import config from 'appconfig';

type OwnProps = {
  showLicenseDisclaimer: boolean,
  showPriceInfo: boolean,
  extraPriceInfo?: React.ReactNode,
};

const Wrapper = styled.div`
  color: ${colors.N200};

  a {
  }
`;

const TextWrapper = styled.div`
  font-size: 12px;
`;

const getOptionExtraInfo = (option) =>
  option.amount > 0 ? <AmountWithCurrency {...option} /> : <b>Free</b>;

const getOptionValue = (option) => option.orderableItemId;
const getOptions = (entry) =>
  entry.options.result === null
    ? []
    : entry.options.result.optionsList.filter(
        (opt) => opt.maintenanceMonths === entry.selectedTerm.result.months,
      );

const ChangeOptions = (props: RowProps & OwnProps) => {
  const options = getOptions(props.entry);
  return (
    <Wrapper>
      {options.length > 0 ? (
        <SelectWithExtraInfo
          items={options}
          getOptionLabel={props.getOptionLabel}
          getOptionExtraInfo={getOptionExtraInfo}
          getOptionValue={getOptionValue}
          onChange={(option) => props.onOptionSelect(props.entry, option)}
          selected={props.entry.selectedOption.result}
          fitToContainer
        />
      ) : (
        props.getNoOptionsMessage(props.entry)
      )}
      {options.length > 0 && props.entry.isSelected && (
        <>
          {props.entry.availableTerms.result.length > 1 ? (
            <AkSelect
              options={props.entry.availableTerms.result}
              onChange={(term) => props.onTermSelect(props.entry, term)}
              value={props.entry.selectedTerm.result}
              getOptionLabel={props.getTermLabel}
              getOptionValue={(term) => term.months}
              shouldFitContainer
            />
          ) : (
            <TextWrapper>{t('upgrade.until-current-date')}</TextWrapper>
          )}
        </>
      )}

      {props.showPriceInfo && <PriceInfo {...props} />}
      {props.extraPriceInfo}
      {props.showLicenseDisclaimer && (
        <TextWrapper>
          <span>{t('upgrade.tiers.match-licenses')}</span>
          &nbsp;
          <a href={`${config.wacUrl}/licensing/marketplace#licensingandpricing-1`} target="_blank">
            {t('common.more-information')}
          </a>
        </TextWrapper>
      )}
    </Wrapper>
  );
};

export default ChangeOptions;
