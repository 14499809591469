import React from 'react';
import styled from 'styled-components';

import SectionMessage from '@atlaskit/section-message';
import OptionsSelect, { Option } from 'modules/cart/summary/OptionsSelect';
import t from 'modules/i18n/intl';
import { PurchaseMode } from 'modules/settings/purchaseMode';
import { Font } from 'components/visuals';

type Props = {
  purchaseMode: PurchaseMode;
  options?: Option[];
};

const Wrapper = styled.div`
  font-family: ${Font.family};
`;

const message = (purchaseMode) =>
  purchaseMode === 'BUY' ? t('cart.no-items-in-cart') : t('cart.no-items-in-quote');

const EmptyCart = ({ purchaseMode, options }: Props) => (
  <Wrapper>
    <SectionMessage>{message(purchaseMode)}</SectionMessage>
    <OptionsSelect options={options} purchaseMode={purchaseMode} />
  </Wrapper>
);

export default EmptyCart;
