import React from 'react';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import { colors } from '@atlaskit/theme';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import t from 'modules/i18n/intl';
import Tooltip from 'components/common/Tooltip';
import { Dimensions, Font } from 'components/visuals';
import { HamsCart } from 'modules/cart/duck/model';

const TaxesIncludedWrapper = styled.div`
  margin: ${Dimensions.units(1)}px 0;
  color: ${colors.N80};
  font-family: ${Font.displayFamily};
  font-size: 12px;
  font-weight: 400;
`;

const paymentLink = (href) => <a href={href}>{t('cart.additional-details')}</a>;

const USTaxTooltip = () => (
  <Tooltip
    direction="right"
    content={
      <span>
        <FormattedMessage
          id="cart.sales-tax-exempted"
          values={{ additionalDetails: paymentLink('/purchase/checkout/payment') }}
        />
      </span>
    }
  >
    <QuestionCircleIcon size="small" primaryColor={colors.N80} label="help" />
  </Tooltip>
);

const TaxesIncluded = ({ cart }: { cart: HamsCart }) => (
  <TaxesIncludedWrapper>
    * {t('cart.item-includes-applicable-taxes')}
    {cart.billingOrganisationDetails.isoCountryCode === 'US' ? <USTaxTooltip /> : null}
  </TaxesIncludedWrapper>
);

export default TaxesIncluded;
