import { redirect } from 'redux-first-router';
import { thunkProgress } from 'redux-progress';
import selectCartPayload from 'modules/cart/duck/selectors/cart';
import mergeContactDetails from 'modules/checkout/contacts/duck/actions/mergeContactDetails';
import { getPageProps } from 'modules/checkout/contacts/duck/selectors';
import { actions as countryActions } from 'modules/data/countries';
import { CartThunk } from 'model/State';
import watchProgress from 'model/watchProgress';
import { route as goToPayment } from 'modules/checkout/payment/duck/actions/route';
import * as steps from 'modules/checkout/steps';
import { HamsEnrichedCart } from 'modules/cart/duck/model';
import { selectInSiteConfiguratorMode } from 'modules/siteconfigurator/duck/selectors';

export const LOAD = 'checkout/contacts/LOAD';
export const load = (): CartThunk => (dispatch) =>
  dispatch(thunkProgress(LOAD, dispatch(mergeContactDetails())));

export const routeThunk = (): CartThunk<Promise<any>> => async (dispatch, getState) => {
  const cart: HamsEnrichedCart = await watchProgress(selectCartPayload);
  const state = getState();
  const inSiteConfiguratorMode = selectInSiteConfiguratorMode(state);

  const currentUserContact = await watchProgress((s) => s.auth.currentContact);

  const shouldSkipContactStep = inSiteConfiguratorMode && !currentUserContact;

  if ((cart.quoteNumber && !cart.inEditMode) || shouldSkipContactStep) {
    dispatch(steps.markComplete('contact'));
    dispatch(redirect(goToPayment()));
  } else {
    await Promise.all([dispatch(countryActions.fetchAll()), dispatch(load())]);
    await watchProgress(getPageProps);
  }
};
