import { HamsCartItem } from '../../cart/duck/model';

export const getProductDescriptionFromErrorMessage = (
  errorDetails: String,
  items: HamsCartItem[],
) => {
  if (
    errorDetails === undefined ||
    errorDetails === '' ||
    items === undefined ||
    items.length === 0
  ) {
    return [];
  }
  const productsDescription: String[] = [];
  const regexp = /(SEN-)([0-9]+)/g;

  Array.from(errorDetails.matchAll(regexp)).forEach((el) => {
    const sen = el[2];
    const senWithProductDescription = items
      .filter((item) => item.crossgradeAccountId === parseInt(sen, 10))
      .map((item) => `${el[0]}: ${item.description}`)
      .pop();
    if (senWithProductDescription !== undefined) {
      productsDescription.push(senWithProductDescription);
    }
  });

  return productsDescription;
};

export default getProductDescriptionFromErrorMessage;
