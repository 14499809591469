import { selectXsrfToken } from 'modules/auth/selectors';
import watchProgress from 'model/watchProgress';
import * as auth from 'modules/auth';

export default async () => {
  const xsrfToken = await watchProgress(selectXsrfToken);
  if (!xsrfToken) {
    auth.actions.goToLogin();
    // return
  }
};
