// @flow
import { ProductGroupKeys, ServerProductKeys } from 'data/products/keys';
import config from 'appconfig';
import t from 'modules/i18n/intl';
import ServerProduct from 'model/editions/ServerProduct';
import SoftwareProductGroup from 'model/SoftwareProductGroup';

export const fisheyeServer = new ServerProduct({
  key: ServerProductKeys.FISHEYE,
  groupKey: ProductGroupKeys.FISHEYE,
  tagLine: t('products.fisheye.tagline'),
  description: t('products.fisheye.description'),
  name: t('products.fisheye.name'),
  recommended: [ServerProductKeys.CRUCIBLE],
  moreLink: `${config.wacUrl}/software/fisheye`,
});

export const fisheyeProductGroup = new SoftwareProductGroup(fisheyeServer);
