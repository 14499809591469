// @flow
import * as React from 'react';
import Link from 'redux-first-router-link';

type Props = {
  to: any,
  children: React.Node,
  style?: any,
  className?: string | null,
};

const defaultStyle = {
  color: 'inherit',
  textDecoration: 'none',
  fontFamily: 'inherit',
};

const LinkWrapper = ({ to, style, className, children }: Props) => {
  if (typeof to === 'string') {
    return (
      <a style={{ ...defaultStyle, ...style }} href={to} className={className}>
        {children}
      </a>
    );
  }
  return (
    <Link to={to} style={style}>
      {children}
    </Link>
  );
};

LinkWrapper.defaultProps = {
  style: {},
  className: null,
};

export default LinkWrapper;
