import React from 'react';
import { Dimensions } from 'components/visuals';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { FormattedMessage } from 'react-intl';
import { sendAnnualBillingMessageToBux } from 'modules/siteconfigurator/utils';

const AdminHubInfoDiv = styled.div`
  padding: ${Dimensions.units(0.5)}px ${Dimensions.units(1)}px;
  margin-bottom: ${Dimensions.units(2)}px;
  width: min-content;
  background: #f6f6f7;
  border-radius: 3px;
  color: ${colors.N500};
  font-size: 12px;
`;

const ManageSubscriptionSpan = styled.span`
  white-space: nowrap;
  cursor: pointer;
  color: ${colors.B400};
`;

const SiteSpan = styled.span`
  white-space: nowrap;
  margin-right: ${Dimensions.units(5)}px;
  color: #42526e;
`;

const AdminHubInfo = ({ accountName }: { accountName: string | undefined }) => (
  <AdminHubInfoDiv>
    {Boolean(accountName) && (
      <SiteSpan>
        <FormattedMessage id="annualbilling.site" />: {accountName}
      </SiteSpan>
    )}
    <ManageSubscriptionSpan
      onClick={() =>
        sendAnnualBillingMessageToBux({
          notify: false,
        })
      }
    >
      <FormattedMessage id="annualbilling.manage-subscription" />
    </ManageSubscriptionSpan>
  </AdminHubInfoDiv>
);

export default AdminHubInfo;
