import Progress from 'redux-progress';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import * as actions from './actions';

export default (state: Progress<LDFlagSet> = Progress.none, action: any) => {
  switch (action.type) {
    case actions.LOAD_FEATURE_FLAGS:
      return action.progress.map((result) => ({
        ...state.result,
        ...result,
        ...window.CART_LAUNCHDARKLY_OVERRIDE,
      }));
    default:
      return state;
  }
};
