import React from 'react';
import { connect } from 'react-redux';
import Progress from 'redux-progress';
import styled from 'styled-components';

import Button from '@atlaskit/button/standard-button';
import CartSummary from 'modules/cart/summary/CartSummary';
import ActionLine from 'modules/cart/ActionLine';
import PromotionMessage from 'modules/cart/PromotionMessage';
import { actions } from 'modules/cart';
import t from 'modules/i18n/intl';
import { goToProductList } from 'actions';
import PageLoading from 'components/common/PageLoading';
import GenericErrorMessage from 'components/common/GenericErrorMessage';
import { Dimensions, Font } from 'components/visuals';
import { HamsCart, HamsEnrichedCart } from 'modules/cart/duck/model';
import { Dispatch, State, GetState } from 'model/State';
import {
  togglePurchaseMode,
  selectPurchaseMode,
  PurchaseMode,
} from 'modules/settings/purchaseMode';
import * as checkout from 'modules/checkout';
import { StoredPromotion } from 'modules/promotion/duck/model';
import { selectors } from 'modules/promotion';
import { HamsProductPricing } from 'model/hams/Pricing';

import ClearCartModal from './ClearCartModal';
import { selectCartPayload, selectCartItemsBeingDeleted, getCartEventData } from './duck/selectors';
import CartPriceSummary from 'modules/cart/CartPriceSummary';
import { isContactStepComplete } from 'modules/checkout/steps';
import { UISource } from 'modules/analytics/model/UIEvent';
import { emitUIEpicEvent, utils } from 'modules/analytics';
import { selectInSiteConfiguratorMode } from 'modules/siteconfigurator/duck/selectors';
import traceUFOPress from '@atlassian/ufo-trace-press';

type StateProps = {
  cartProgress: Progress<HamsCart | null>;
  deletingItems: { [id: number]: true };
  purchaseMode: PurchaseMode;
  promotion: StoredPromotion | null;
  promotionProducts: Progress<HamsProductPricing[]>;
  cartClearDialogVisible: boolean;
  cartClearSubmit: Progress<any>;
  contactStepCompleteProgress: Progress<boolean>;
  inSiteConfiguratorMode: boolean;
};

type DispatchProps = {
  onDelete: (id: number) => void;
  onStartShopping: () => void;
  onSubmit: () => void;
  onTogglePurchaseMode: () => void;
  onClearCart: () => void;
  onClearCartConfirm: () => void;
  onClearCartCancel: () => void;
};

type Props = StateProps & DispatchProps;

const Wrapper = styled.div`
  padding-top: ${Dimensions.units(3)}px;
`;

const HeaderWrapper = styled.div`
  display: flex;
`;

const Header = styled.h2`
  font-family: ${Font.displayFamily};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  margin-bottom: ${Dimensions.units(7)}px;
  flex-grow: 1;
`;

const ClearCartButton = styled(Button)`
  padding: 0 16px;
  font-family: ${Font.family};
  font-size: 16px;
  position: relative;
  top: 4px;
`;

const shouldShowPriceSummary = (cart: HamsEnrichedCart, contactStepComplete: boolean): boolean =>
  Boolean(
    // tax is not zero
    // non zero credit
    cart.totalTax > 0 ||
      // HAMS returns 0 tax even if tax is unknown.
      // To determine if the tax value is actually 0 or unknown we need to chcek
      // if user has finished the contact step. If finshed tax value is calculated and valid.
      contactStepComplete ||
      // non zero discount
      cart.savedAmount > 0 ||
      // non zero credit
      (cart.adjustmentAmounts?.UPGRADE_CREDIT && cart.adjustmentAmounts?.UPGRADE_CREDIT > 0),
  );

export const ReviewPageVisual = (props: Props) => (
  <PageLoading progress={Progress.all(props.cartProgress, props.contactStepCompleteProgress)}>
    {([cart, contactStepComplete]) => {
      if (!cart) {
        return null;
      }
      const showPriceSummary = shouldShowPriceSummary(cart, contactStepComplete);

      return (
        <>
          <Wrapper>
            {props.cartClearSubmit.error && (
              <GenericErrorMessage error={props.cartClearSubmit.error} />
            )}
            <HeaderWrapper>
              <Header>
                {props.purchaseMode === 'BUY' ? t('cart.shopping-cart') : t('cart.price-quote')}
              </Header>
              {cart.items.length > 0 && (
                <ClearCartButton onClick={props.onClearCart}>Remove all items</ClearCartButton>
              )}
            </HeaderWrapper>
            <PromotionMessage
              cart={cart}
              promotion={props.promotion}
              products={props.promotionProducts}
            />
            <CartSummary
              cart={cart}
              deletingItems={props.deletingItems}
              onDelete={props.onDelete}
              purchaseMode={props.purchaseMode}
              hideTotalPrice={showPriceSummary}
            />
            {showPriceSummary && <CartPriceSummary cart={cart} />}
            <ActionLine {...props} cart={cart} />
          </Wrapper>
          <ClearCartModal
            showDialog={props.cartClearDialogVisible}
            onConfirm={props.onClearCartConfirm}
            onCancel={props.onClearCartCancel}
            inProgress={props.cartClearSubmit.inProgress}
          />
        </>
      );
    }}
  </PageLoading>
);

const onSubmitEvent = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const selectedPurchaseMode = selectPurchaseMode(state);
  if (selectedPurchaseMode === 'BUY') {
    dispatch(
      emitUIEpicEvent({
        data: utils.getButtonClickEventData('cartCheckout', UISource.Page),
        selectorsOrObjects: [
          getCartEventData,
          {
            eventContainer: 'checkoutSummary',
            eventComponent: 'checkoutSummaryButton',
            parentLabel: 'checkOutButton',
            label: 'checkOut',
            uuid: '5acbd0ff-87e9-4fd9-9757-5d67ecc100d8',
          },
        ],
      }),
    );
  } else {
    dispatch(
      emitUIEpicEvent({
        data: utils.getButtonClickEventData('cartSaveQuote', UISource.Page),
        selectorsOrObjects: [
          getCartEventData,
          {
            eventContainer: 'checkoutSummary',
            eventComponent: 'checkoutSummaryQuoteButton',
            parentLabel: 'quoteButton',
            label: 'getQuote',
            uuid: '2b373b74-f39a-43cc-8041-b45cbb28f2b6',
          },
        ],
      }),
    );
  }
};

const mapStateToProps = (state: State): StateProps => ({
  cartProgress: selectCartPayload(state),
  contactStepCompleteProgress: isContactStepComplete(state),
  deletingItems: selectCartItemsBeingDeleted(state),
  purchaseMode: selectPurchaseMode(state),
  promotion: state.promotion.storage,
  promotionProducts: selectors.selectPromotionProducts(state),
  cartClearDialogVisible: state.cart.clearCart.dialogOpen,
  cartClearSubmit: state.cart.clearCart.submitProgress,
  inSiteConfiguratorMode: selectInSiteConfiguratorMode(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onDelete: (itemId: number) => {
    traceUFOPress('items-delete');
    dispatch(actions.removeItems(itemId));
  },
  onStartShopping: () => dispatch(goToProductList()),
  onSubmit: () => {
    dispatch(onSubmitEvent());
    dispatch(checkout.actions.goNext());
  },
  onTogglePurchaseMode: () => dispatch(togglePurchaseMode()),
  onClearCart: () => dispatch(actions.openClearCartDialog()),
  onClearCartConfirm: () => dispatch(actions.clearCart()),
  onClearCartCancel: () => dispatch(actions.closeClearCartDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPageVisual);
