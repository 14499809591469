import { thunkProgress } from 'redux-progress';

import type { Dispatch, GetState, State } from 'model/State';
import {
  childrenOverviews,
  isSiteApplicableForConcession,
  detail,
  isSiteOnConcession,
  CMTSelfServeLink,
  isOnBehalfOfEmail,
} from 'modules/mac/duck/api/accountId';
import {
  CHILDREN_OVERVIEWS,
  IS_SITE_APPLICABLE_FOR_CONCESSION,
  DETAILS,
  CMT_SELF_SERVE_LINK,
  IS_ON_BEHALF_OF_EMAIL,
} from './types';

const selectAccountId = (state: State) =>
  state?.location?.query?.accountId ?? state?.location?.query?.partnerAccountId;

export const fetchChildrenOverviews = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const accountId = selectAccountId(state);

  if (!accountId) return;

  const childrenOverviewsData = childrenOverviews(accountId, state);
  dispatch(thunkProgress(CHILDREN_OVERVIEWS, childrenOverviewsData));
};

export const fetchIsSiteApplicableForConcession = () => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const state = getState();
  const accountId = selectAccountId(state);

  if (!accountId) return;

  const isSiteApplicableForConcessionData = isSiteApplicableForConcession(accountId, state);
  dispatch(thunkProgress(IS_SITE_APPLICABLE_FOR_CONCESSION, isSiteApplicableForConcessionData));
};

export const fetchDetails = (accountId) => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();

  if (!accountId) return;

  const detailsData = detail(accountId, state);
  dispatch(thunkProgress(DETAILS, detailsData, { accountId }));
};

export const fetchIsSiteOnConcession = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const accountId = selectAccountId(state);

  if (!accountId) return;

  const isSiteOnConcessionData = isSiteOnConcession(accountId, state);
  dispatch(thunkProgress(IS_SITE_APPLICABLE_FOR_CONCESSION, isSiteOnConcessionData));
};

export const fetchCMTSelfServeLink = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const accountId = selectAccountId(state);

  if (!accountId) return;

  const cmtSelfServeLinkData = CMTSelfServeLink(accountId, state);
  dispatch(thunkProgress(CMT_SELF_SERVE_LINK, cmtSelfServeLinkData));
};

export const fetchIsOnBehalfOfEmail = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const accountId = selectAccountId(state);

  if (!accountId) return;

  const isOnBehalfOfEmailData = isOnBehalfOfEmail(accountId, state);
  dispatch(thunkProgress(IS_ON_BEHALF_OF_EMAIL, isOnBehalfOfEmailData));
};
