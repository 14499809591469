// @flow
import Progress from 'redux-progress';
import { connect } from 'react-redux';
import { actions, selectors } from 'modules/configure';
import AddToCart from 'components/common/AddToCart';
import type { StateProps } from 'components/common/AddToCart';
import type { Dispatch, Selector } from 'model/State';
import { AnalyticsClient } from 'modules/analytics/CartAnalyticsClient';
import { selectors as authSelectors } from 'modules/auth';
import selectCurrency from 'modules/cart/duck/selectors/currency';

const mapStateToProps: Selector<StateProps> = (state) => ({
  currency: selectCurrency(state).result,
  amount: selectors.selectTotalAmount(state),
  purchaseMode: state.settings.purchaseMode,
  submitProgress: state.configure ? state.configure.submitProgress : Progress.none,
  isExpert: authSelectors.getUserChannel(state).ifSuccess((uc) => uc.isExpert) || false,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onAddToCart: (analytics?: AnalyticsClient) => dispatch(actions.addProductToCart(analytics)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
