// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import type { State } from 'model/State';
import ProductsDropdown from 'components/navigation/top/dropdowns/ProductsDropdown';
import TeamsDropdown from 'components/navigation/top/dropdowns/TeamsDropdown';
import SupportDropdown from 'components/navigation/top/dropdowns/SupportDropdown';

const grow = keyframes`
  from {
    max-height: 0;
  }
  
  to {
    max-height: 300px;
  }
`;

const shrink = keyframes`
  from {
    max-height: 300px;
  }
  
  to {
    max-height: 0;
  }
`;

const OpenTransition = styled.div`
  animation: ${grow} 0.3s ease-out;
  overflow: hidden;
`;

const CloseTransition = styled.div`
  animation: ${shrink} 0.3s ease-out;
  overflow: hidden;
  max-height: 0;
`;

type Props = {
  openMenu: string | null,
};
type CompopnentState = {
  closingMenu: string | null,
};

const dropdowns = {
  products: ProductsDropdown,
  teams: TeamsDropdown,
  support: SupportDropdown,
};
const dropdown = (menu: string) => React.createElement(dropdowns[menu]);

export class VisualActiveDropdown extends PureComponent<Props, CompopnentState> {
  constructor(props: Props) {
    super(props);
    this.state = { closingMenu: null };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props: Props) {
    if (this.props.openMenu !== props.openMenu) {
      this.setState({ closingMenu: this.props.openMenu });
    }
  }

  render() {
    if (this.props.openMenu) {
      return <OpenTransition>{dropdown(this.props.openMenu)}</OpenTransition>;
    }
    if (this.state.closingMenu) {
      return <CloseTransition>{dropdown(this.state.closingMenu)}</CloseTransition>;
    }
    return null;
  }
}

const mapStateToProps = (state: State) => ({
  openMenu: state.navigation.topDropdownMenu,
});

export default connect(mapStateToProps)(VisualActiveDropdown);
