import React from 'react';

import { HamsCart, SlaCreditApplication } from 'modules/cart/duck/model';
import TextCell from 'modules/cart/summary/cells/TextCell';
import SLAPriceDeductionCell from 'modules/cart/summary/cells/SLAPriceDeductionCell';
import Cell from 'modules/cart/summary/cells/Cell';

type Props = {
  cart: HamsCart;
  creditApplication: SlaCreditApplication;
};

const SLATableLine = ({ creditApplication, cart }: Props) => (
  <tr>
    <TextCell notes={creditApplication.notes} />
    <Cell />
    <Cell />
    <SLAPriceDeductionCell amountIncTax={creditApplication.amountIncTax} currency={cart.currency} />
  </tr>
);

export default SLATableLine;
