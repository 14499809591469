import Progress from 'redux-progress';
import { AccountState } from './model';
import { FETCH_ACCOUNTS } from './actions';

const initialState = {
  overview: Progress.none,
};

export default (state: AccountState = initialState, action: any) => {
  switch (action.type) {
    case FETCH_ACCOUNTS:
      return {
        ...state,
        overview: action.progress,
      };
    default:
      return state;
  }
};
