import React from 'react';

import Providers from './Providers';
import OriginAppSwitcher from './AppSwitcher';
import UFOSegment from '@atlassian/ufo-segment';

const AppSwitcher = OriginAppSwitcher as React.FC;

const App: React.FC = () => {
  return (
    <UFOSegment name="cart-root">
      <Providers>
        <AppSwitcher />
      </Providers>
    </UFOSegment>
  );
};

export default App;
