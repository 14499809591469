// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';

import t from 'modules/i18n/intl';
import { getSoftwareProduct, getSoftwareProductGroup } from 'data/products/index';
import { Font } from 'components/visuals';
import config from 'appconfig';

const DatacenterUpgradeMessage = ({ productKey }: { productKey: string | null }) => {
  const product = productKey ? getSoftwareProduct(productKey) : null;
  const productGroup = product ? getSoftwareProductGroup(product) : null;

  if (!product || !productGroup || !product.isDataCenter || !productGroup.server) {
    return null;
  }

  return (
    <div style={{ width: '400px', fontFamily: Font.family }}>
      <SectionMessage
        appearance="information"
        // $FlowFixMe Section message does accept a Node as title, even if Flow says it doesn't
        title={
          <FormattedMessage
            id="purchase.upgrading-from-server-product"
            values={{ product: productGroup.server.name }}
          />
        }
        actions={[
          {
            text: t('purchase.upgrade-my-existing-license'),
            href: `${config.wacUrl}/purchase/upgrade/datacenter/`,
            key: product.key,
            // $FlowFixMe Section message does accept a Node as action text
          },
        ].map(({ text, ...restAction }) => (
          <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
        ))}
      >
        <FormattedMessage id="purchase.upgrading-from-server-discount-message" />
      </SectionMessage>
    </div>
  );
};

export default DatacenterUpgradeMessage;
