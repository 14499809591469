import { State } from 'model/State';
import Progress from 'redux-progress';

export interface IAvailablePaymentMethods {
  free: boolean;
  onAccount: boolean;
  onTerms: boolean;
  paypal: boolean;
  quote: boolean;
  tns: boolean;
  stripe: boolean;
}

export const none: IAvailablePaymentMethods = Object.freeze({
  free: false,
  onAccount: false,
  onTerms: false,
  paypal: false,
  quote: false,
  tns: false,
  stripe: false,
});

const getAvailablePaymentMethods = (state: State): Progress<IAvailablePaymentMethods> =>
  Progress.all(state.checkout.payment.checkoutMethodsAvailable).flatMap(
    ([checkoutMethodsAvailable]) => {
      return Progress.success({ ...none, ...checkoutMethodsAvailable });
    },
  ) as any;

export default getAvailablePaymentMethods;
