import { thunkProgress } from 'redux-progress';

import type { Dispatch, GetState } from 'model/State';
import {
  hamsBilledByEmail,
  licenseByEmail,
  archivedAccountIds,
  archivedLicenseIds,
} from 'modules/mac/duck/api/contactEmail';

import {
  HAMS_BILLED_BY_EMAIL,
  LICENSE_BY_EMAIL,
  ARCHIVED_ACCOUNT_IDS,
  ARCHIVED_LICENSE_IDS,
} from './types';

export const fetchHamsBilledByEmail = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const hamsBilledByEmailData = hamsBilledByEmail(state);
  dispatch(thunkProgress(HAMS_BILLED_BY_EMAIL, hamsBilledByEmailData));
};

export const fetchLicenseByEmail = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const licenseByEmailData = licenseByEmail(state);
  dispatch(thunkProgress(LICENSE_BY_EMAIL, licenseByEmailData));
};

export const fetchArchivedAccountIds = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const archivedAccountIdsData = archivedAccountIds(state);
  dispatch(thunkProgress(ARCHIVED_ACCOUNT_IDS, archivedAccountIdsData));
};

export const fetchArchivedLicenseIds = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const archivedLicenseIdsData = archivedLicenseIds(state);
  dispatch(thunkProgress(ARCHIVED_LICENSE_IDS, archivedLicenseIdsData));
};
