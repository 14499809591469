import React from 'react';
import styled from 'styled-components';

import { colors } from '@atlaskit/theme';

import TableLine from 'modules/cart/summary/TableLine';
import SLATableLine from 'modules/cart/summary/SLATableLine';

import { HamsEnrichedCart, HamsCartEnrichedItem } from 'modules/cart/duck/model';

type Props = {
  cart: HamsEnrichedCart;
  deletingItems: { [id: number]: true };
  onDelete: (id: number) => void;
  inSiteConfiguratorMode?: boolean;
};

const ItemLine = (props: Props & { item: HamsCartEnrichedItem }) => (
  <TableLine
    {...props}
    deleting={!!props.deletingItems[props.item.id]}
    onDelete={() => props.onDelete(props.item.id)}
    isChild={!!props.item.parentCartItemId}
  />
);

const ParentItemWrapper = styled.tbody`
  border-bottom: 1px solid ${colors.N40};

  &:nth-last-child(2) {
    border-bottom: 2px solid ${colors.N40};
  }
`;

const TableBody = (props: Props) => (
  <>
    {props.cart.items.map((parent) => (
      <ParentItemWrapper key={parent.id}>
        <ItemLine {...props} item={parent} />
        {parent.childItems.map((child) => (
          <ItemLine {...props} item={child} key={child.id} />
        ))}
        {parent.creditApplications.map((creditApplication) => (
          <SLATableLine
            {...props}
            creditApplication={creditApplication}
            key={creditApplication.id}
          />
        ))}
      </ParentItemWrapper>
    ))}
  </>
);

export default TableBody;
