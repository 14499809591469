export const PRODUCT_LIST = 'PRODUCT_LIST';
export const RENEW = 'RENEW';

export interface ProductListAction {
  type: typeof PRODUCT_LIST;
}

export const goToProductList = (): ProductListAction => ({
  type: PRODUCT_LIST,
});

export const goRenew = (query?: object) => ({
  meta: {
    query,
  },
  type: RENEW,
});
