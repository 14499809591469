import { connect } from 'react-redux';
import { redirect } from 'redux-first-router';

import { Dispatch } from 'model/State';
import { FeatureFlags, checkFeatureGate } from 'util/statsig/index';
import { goToProductList } from 'actions';

type Props = {
  redirectToProductList: () => void;
};

// this component is only to keep MAC functionality in SC hidden behind the FG
const RedirectToProductList = (props: Props) => {
  const cartMACFunctionalityMigration = checkFeatureGate(
    FeatureFlags.CART_MAC_FUNCTIONALITY_MIGRATION,
  );

  if (!cartMACFunctionalityMigration) {
    props.redirectToProductList();
  }
  return null;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  redirectToProductList: () => dispatch(redirect(goToProductList())),
});

export default connect(null, mapDispatchToProps)(RedirectToProductList);
