import { thunkProgress } from 'redux-progress';
import { selectHamsClientConfig } from 'modules/auth/selectors';
import {
  validateCountryPurchaserContactMatchApi,
  checkCountryTechnicalContactMatchApi,
} from 'modules/checkout/contacts/duck/api/validateContactCountry';
import getLockPurchaserEmail from 'modules/checkout/contacts/duck/selectors/getLockPurchaserEmail';
import {
  clearPurchaserEmailValidation,
  clearTechnicalEmailValidation,
} from './clearCountryValidation';
import { PCCartCountryValidationType, TCCartCountryValidationType } from '../model';
import { CartThunk } from 'model/State';

export const FETCH_PURCHASER_EMAIL_COUNTRY_VALIDATION =
  'checkout/contact/PURCHASER_EMAIL_COUNTRY_VALIDATION';
export const FETCH_TECHNICAL_EMAIL_COUNTRY_VALIDATION =
  'checkout/contact/TECHNICAL_EMAIL_COUNTRY_VALIDATION';

export const validateCountryPurchaserContactMatch = (
  isoCountryCode: string,
  emailAddress: string,
): CartThunk<Promise<PCCartCountryValidationType> | null> => (dispatch, getState) => {
  const hamsClientConfig = selectHamsClientConfig(getState());
  if (!getLockPurchaserEmail(getState())) {
    return null;
  }
  dispatch(clearTechnicalEmailValidation());
  return dispatch(
    thunkProgress(
      FETCH_PURCHASER_EMAIL_COUNTRY_VALIDATION,
      validateCountryPurchaserContactMatchApi(isoCountryCode, emailAddress, hamsClientConfig),
      {
        isoCountryCode,
        emailAddress,
      },
    ),
  );
};

export const checkCountryTechnicalContactMatch = (
  isoCountryCode: string,
  emailAddress: string,
): CartThunk<Promise<TCCartCountryValidationType> | null> => (dispatch, getState) => {
  const hamsClientConfig = selectHamsClientConfig(getState());
  if (!emailAddress) {
    return null;
  }
  dispatch(clearPurchaserEmailValidation());
  return dispatch(
    thunkProgress(
      FETCH_TECHNICAL_EMAIL_COUNTRY_VALIDATION,
      checkCountryTechnicalContactMatchApi(isoCountryCode, emailAddress, hamsClientConfig),
      {
        isoCountryCode,
        emailAddress,
      },
    ),
  );
};
