import t from 'modules/i18n/intl';
import { ProductFeature } from './Product';

const eliminateMaintenance: ProductFeature = {
  weight: 0,
  description: t('product.cloud.considers.eliminate-maintenance'),
};

const builtInSecurity: ProductFeature = {
  weight: 5,
  description: t('product.cloud.considers.built-in-security'),
};

const receiveRD: ProductFeature = {
  weight: 10,
  description: t('product.cloud.considers.receive-r&d'),
};

export const cloudConsiderList: ProductFeature[] = [
  eliminateMaintenance,
  builtInSecurity,
  receiveRD,
];
