import React, { useState } from 'react';
import Select from '@atlaskit/select';
import styled from 'styled-components';
import { Dimensions } from 'components/visuals';
import { HamsCartItem } from 'modules/cart/duck/model';
import { OrderableItem } from 'model/hams/Pricing';
import { getEdition, getProductListPrice, is20kCloudProduct } from '../utils';
import {
  StyledTableCell,
  StyledTableProratedPriceCell,
  StyledTableRow,
  ProductDescriptionDiv,
  CartItemDescriptionDiv,
} from './AppInfoRow';
import { formattedDatePeriod } from 'components/common/MaintenancePeriod';
import { colors } from '@atlaskit/theme';
import AmountWithCurrency from 'components/common/AmountWithCurrency';
import { AnalyticsTrigger } from '../analytics';
import { FormattedMessage } from 'react-intl';
import { CloudProductKey } from '../../../data/products/keys';

const HYPHEN = '---';
const unitCountUpperLimit = 10000;

type Props = {
  currency: string;
  cartItem: HamsCartItem;
  editionTierMapping: { [editionName: string]: OrderableItem[] };
  updateItem: (cartItem: HamsCartItem, editionTiers: OrderableItem[], unitCount: string) => void;
  isCartItemOriginalEditionStandard: (cartItemId: number) => boolean;
  getOriginalTierCount: (cartItemId: number) => number;
  setAnalyticsTrigger: (trigger: string) => void;
};

const StyledSelect = styled(Select)`
  margin-right: ${Dimensions.units(3)}px;
  min-width: ${Dimensions.units(12.5)}px;
`;

const Label = styled.span`
  padding: ${Dimensions.units(1)}px ${Dimensions.units(1.5)}px;
  line-height: ${Dimensions.units(2.5)}px;
  background: ${colors.N20A};
  border-radius: 3px;
  color: ${colors.N500};
  text-transform: capitalize;
`;

const ProductInfoRow = ({
  currency,
  cartItem,
  editionTierMapping,
  updateItem,
  isCartItemOriginalEditionStandard,
  getOriginalTierCount,
  setAnalyticsTrigger,
}: Props) => {
  const [selectedEdition, setSelectedEdition] = useState<string>(() => getEdition(cartItem));
  const [selectedTier, setSelectedTier] = useState<number>(cartItem.productDetails.unitCount);
  const editionChoices = Object.keys(editionTierMapping);
  const originalTierCount = getOriginalTierCount(cartItem.id);
  const annualListPrice = getProductListPrice(editionTierMapping, selectedEdition, selectedTier);

  const tierOptions = editionTierMapping[selectedEdition]
    ?.filter(({ unitCount }) => unitCount >= originalTierCount)
    .filter(
      ({ unitCount }) =>
        !(
          is20kCloudProduct(cartItem.productDetails.productKey as CloudProductKey) &&
          unitCount > unitCountUpperLimit
        ),
    )
    .map(({ unitCount }) => ({
      label: `${unitCount} users`,
      value: unitCount,
    }));
  const selectedTierOption = tierOptions?.find(({ value }) => value === selectedTier);
  const editionOptions = editionChoices.map((edition) => ({
    label: edition,
    value: edition,
  }));
  const selectedEditionOption = editionOptions?.find(({ value }) => value === selectedEdition);

  const changeTier = ({ value }) => {
    const newTier = value;
    setSelectedTier(Number.parseInt(newTier, 10));
    updateItem(cartItem, editionTierMapping[selectedEdition], newTier.toString());
    setAnalyticsTrigger(AnalyticsTrigger.Tier);
  };

  const changeEdition = ({ value }) => {
    const newEdition = value;
    setSelectedEdition(newEdition);
    updateItem(cartItem, editionTierMapping[newEdition], selectedTier.toString());
    setAnalyticsTrigger(AnalyticsTrigger.Edition);
  };
  const itemPeriodDates = formattedDatePeriod(cartItem, CartItemDescriptionDiv);

  return (
    <StyledTableRow>
      <StyledTableCell>
        <ProductDescriptionDiv>{cartItem.productDetails.productDescription}</ProductDescriptionDiv>
        <CartItemDescriptionDiv>
          <FormattedMessage id="annualbilling.table.product.description.cloud" />
        </CartItemDescriptionDiv>
      </StyledTableCell>
      <StyledTableCell>
        {editionChoices.length === 1 && <Label data-cy="plan-label">{editionChoices[0]}</Label>}
        {editionChoices.length === 2 && (
          <>
            {isCartItemOriginalEditionStandard(cartItem.id) ? (
              <StyledSelect
                className="plan-select"
                classNamePrefix="plan-select"
                onChange={changeEdition}
                options={editionOptions}
                value={selectedEditionOption}
                styles={{
                  singleValue: (base) => ({
                    ...base,
                    color: colors.N500,
                    textTransform: 'capitalize',
                  }),
                  option: (base) => ({
                    ...base,
                    textTransform: 'capitalize',
                  }),
                }}
              />
            ) : (
              <Label data-cy="plan-label">premium</Label>
            )}
          </>
        )}
      </StyledTableCell>
      <StyledTableCell>
        {Boolean(tierOptions?.length) &&
          (tierOptions.length === 1 ? (
            Boolean(selectedTierOption) && <Label>{selectedTierOption}</Label>
          ) : (
            <StyledSelect
              className="tier-select"
              classNamePrefix="tier-select"
              onChange={changeTier}
              options={tierOptions}
              value={selectedTierOption}
              styles={{
                singleValue: (base) => ({
                  ...base,
                  color: colors.N500,
                }),
              }}
            />
          ))}
      </StyledTableCell>
      <StyledTableCell data-cy="annual-price">
        {annualListPrice === 0 ? (
          HYPHEN
        ) : (
          <AmountWithCurrency
            amount={annualListPrice}
            currency={currency}
            weight="400"
            size="14px"
            dataRole="annualListPrice"
          />
        )}
      </StyledTableCell>
      <StyledTableProratedPriceCell data-cy="prorated-price">
        {cartItem.totalIncTax === 0 ? (
          HYPHEN
        ) : (
          <>
            <AmountWithCurrency
              amount={cartItem.totalIncTax}
              currency={currency}
              weight="400"
              size="14px"
              dataRole="proratedPrice"
            />
            {itemPeriodDates}
          </>
        )}
      </StyledTableProratedPriceCell>
    </StyledTableRow>
  );
};

export { ProductInfoRow };
