// @flow
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { colors } from '@atlaskit/theme';
import { toggleMenu } from 'actions/navigation/topDropdownMenu';
import type { State } from 'model/State';
import { Dimensions, Font } from 'components/visuals';
import Link from 'components/common/Link';

import t from 'modules/i18n/intl';

type OwnProps = {
  text: string,
  menuKey: string,
};

type Props = {
  text: string,
  open: boolean,
  clicked: () => {},
};

const MenuItemLi = styled.li`
  font-size: 16px;
  font-family: ${Font.family};
  font-weight: 400;
  letter-spacing: 1px;
  list-style: none;
  display: inline;
  margin-left: ${Dimensions.units(1)}px;

  border-radius: 3px;
  padding: 4px 14px;
  position: relative;
  top: -4px;

  &:hover {
    color: ${colors.N700};
    background: ${colors.N40};
  }

  &:first-child {
    margin-left: ${Dimensions.units(2)}px;
  }
`;

const DropdownLabel = styled.span`
  cursor: pointer;
`;

const openStyle = {
  color: colors.N700,
  background: colors.N40,
};

const activeLinkStyle = {
  borderBottom: '3px solid #0C66E4',
};

export type MenuItemLinkProps = { href: string, text: string, active?: boolean };

export const MenuItemLink = (props: MenuItemLinkProps) => (
  <MenuItemLi data-cy={props.text} style={props.active ? activeLinkStyle : {}}>
    <Link to={props.href}>{t(props.text)}</Link>
  </MenuItemLi>
);

export const VisualMenuItemDropdown = (props: Props) => (
  <MenuItemLi data-cy={props.text} style={props.open ? openStyle : {}}>
    <DropdownLabel onClick={props.clicked} role="link">
      {t(props.text)}
    </DropdownLabel>
  </MenuItemLi>
);

const mapStateToProps = (state: State, ownProps: OwnProps) => ({
  open: state.navigation.topDropdownMenu === ownProps.menuKey,
});
const mapDispatchToProps = (dispatch: *, ownProps: OwnProps) => ({
  clicked: () => dispatch(toggleMenu(ownProps.menuKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VisualMenuItemDropdown);
