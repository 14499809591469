import Progress, { thunkProgress } from 'redux-progress';
import { selectHamsClientConfig } from 'modules/auth/selectors';
import { id } from 'modules/cart/duck/selectors';
import validateCartCurrencyCountryMatchApi from 'modules/checkout/contacts/duck/api/validateCartCountry';
import { CartThunk } from 'model/State';

export const CHECK_COUNTRY_CART_CURRENCY_MATCH =
  'checkout/contact/CHECK_COUNTRY_CART_CURRENCY_MATCH';

export const validateCartCountryMatch = (
  isoCountryCode: string,
): CartThunk<Promise<Progress<any>>> => (dispatch, getState) => {
  const hamsClientConfig = selectHamsClientConfig(getState());
  return dispatch(
    thunkProgress(
      CHECK_COUNTRY_CART_CURRENCY_MATCH,
      validateCartCurrencyCountryMatchApi(
        isoCountryCode,
        id(getState()) as string,
        hamsClientConfig,
      ),
    ),
  );
};
