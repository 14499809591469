// @flow
import type { Options } from 'redux-first-router';
import queryString from 'query-string';
import validateRoute from 'routes/validateRoute';
import wrapRouteThunks from 'routes/wrapRouteThunks';
import * as addon from 'modules/addon';
import * as addtocart from 'modules/addtocart';
import * as contacts from 'modules/checkout/contacts';
import * as siteconfigurator from 'modules/siteconfigurator';

import * as review from 'modules/checkout/review';
import * as payment from 'modules/checkout/payment';
import * as quote from 'modules/checkout/quote';
import * as complete from 'modules/checkout/complete';
import * as upgrade from 'modules/upgrade';
import * as cart from 'modules/cart';
import * as promotion from 'modules/promotion';
import * as renew from 'modules/renew';
import * as software from 'modules/software';
import * as mac from 'modules/mac';

import { PRODUCT_LIST } from 'actions';
import notificationFlagRouteWrapper from 'modules/meta/notificationFlag/routeWrapper';
import initActionsRouteWrapper from 'actions/init';
import { languageInitWrapper } from 'modules/i18n/duck/actions';
import type { Dispatch, GetState } from 'model/State';
import * as configure from 'modules/configure';
import * as marketplace from 'modules/marketplace';

export type thunk = (dispatch: Dispatch, getState: GetState) => Promise<any>;

export type RouteDef = {
  path: string,
  flag?: string,
  graduated?: boolean,
  thunk?: thunk,
  pageOptions?: any,
  ufoName: string,
};

export type RoutesMap = { [key: string]: RouteDef };

export const routesMap: RoutesMap = {
  [PRODUCT_LIST]: {
    name: 'PRODUCT_LIST', // Name is used for segment events,
    path: '/',
    ufoName: 'product-list-route',
  },
  [addon.actions.ROUTE]: {
    name: 'ADDON_CONFIGURATION',
    path: '/addon/:productKeys',
    thunk: addon.actions.load,
    ufoName: 'addon-route',
  },
  [addtocart.actions.ROUTE]: {
    path: '/addtocart',
    thunk: addtocart.actions.routeThunk(),
    ufoName: 'add-to-cart-route',
  },
  [configure.actions.ROUTE]: {
    name: 'PRODUCT_CONFIGURATION',
    path: '/product/:productKey',
    thunk: configure.actions.load,
    ufoName: 'configure-route',
  },
  [upgrade.landing.actions.ROUTE]: {
    name: 'UPGRADE',
    path: '/upgrade',
    thunk: upgrade.landing.actions.load,
    ufoName: 'upgrade-route',
  },
  [upgrade.tiers.actions.ROUTE]: {
    name: 'UPGRADE_TIERS',
    path: '/upgrade/tiers',
    thunk: upgrade.tiers.actions.load,
    ufoName: 'upgrade-tiers-route',
  },
  [upgrade.datacenter.actions.ROUTE]: {
    name: 'UPGRADE_DATACENTER',
    path: '/upgrade/datacenter',
    thunk: upgrade.datacenter.actions.load,
    ufoName: 'upgrade-datacenter-route',
  },
  [contacts.actions.ROUTE]: {
    name: 'CONTACT',
    path: '/checkout/contact',
    thunk: contacts.actions.routeThunk(),
    ufoName: 'checkout-contacts-route',
  },
  [payment.actions.ROUTE]: {
    name: 'PAYMENT',
    path: '/checkout/payment',
    thunk: payment.actions.routeThunk(),
    ufoName: 'checkout-payment-route',
  },
  [siteconfigurator.actions.ROUTE]: {
    name: 'SITECONFIG',
    path: '/siteconfigurator',
    thunk: siteconfigurator.actions.routeThunk(),
    ufoName: 'purchase/siteconfigurator',
  },
  [review.actions.ROUTE]: {
    name: 'REVIEW',
    path: '/checkout/review',
    thunk: review.actions.load,
    ufoName: 'checkout-review-route',
  },
  [cart.actions.ROUTE]: {
    name: 'CART',
    path: '/cart',
    thunk: cart.actions.load,
    ufoName: 'cart-route',
  },
  [renew.landing.actions.ROUTE]: {
    name: 'RENEW',
    path: '/renew',
    thunk: renew.landing.actions.load,
    ufoName: 'renew-route',
  },
  [renew.server.actions.ROUTE]: {
    name: 'RENEW_SERVER',
    path: '/renew/server',
    thunk: renew.server.actions.load,
    ufoName: 'renew-server-route',
  },
  [complete.actions.ROUTE]: {
    name: 'COMPLETE',
    path: '/checkout/complete',
    pageOptions: { hideSecondaryMenu: true },
    thunk: complete.actions.load,
    ufoName: 'checkout-complete-route',
  },
  [quote.actions.ROUTE]: {
    name: 'QUOTE_DETAILS',
    path: '/checkout/quote',
    thunk: quote.actions.routeThunk(),
    ufoName: 'checkout-quote-route',
  },
  [marketplace.actions.ROUTE]: {
    path: '/marketplace',
    thunk: marketplace.actions.load(),
    ufoName: 'marketplace-route',
  },
  [promotion.actions.ROUTE]: {
    name: 'PROMOTION',
    path: '/promo',
    thunk: promotion.actions.load,
    ufoName: 'promotion-route',
  },
  [software.buy.ROUTE]: {
    name: 'SOFTWARE_BUY',
    path: '/software/1/buy/:productKey',
    thunk: software.buy.load,
    ufoName: 'software-buy-route',
  },
  [software.renewAndUpgrade.RENEW_ROUTE]: {
    name: 'SOFTWARE_RENEW',
    path: '/software/1/renew/:accountId',
    thunk: software.renewAndUpgrade.load,
    ufoName: 'software-renew-route',
  },
  [software.renewAndUpgrade.UPGRADE_ROUTE]: {
    name: 'SOFTWARE_UPGRADE',
    path: '/software/1/upgrade/:accountId',
    thunk: software.renewAndUpgrade.load,
    ufoName: 'software-upgrade-route',
  },
  MIGRATE: {
    name: 'MIGRATE',
    path: '/price-comparison',
    pageOptions: { hideSecondaryMenu: true },
    ufoName: 'migrate-route',
  },
  BUILDER: {
    name: 'BUILDER',
    path: '/price-comparison/builder',
    pageOptions: { hideSecondaryMenu: true },
    ufoName: 'builder-route',
  },
  [mac.landing.ROUTE]: {
    name: 'MAC_LICENSES',
    path: '/my',
    thunk: mac.landing.load,
    pageOptions: { isMACPage: true, hideSecondaryMenu: true },
    ufoName: 'mac-route',
  },
  [mac.orders.ROUTE]: {
    name: 'MAC_ORDERS',
    path: '/my/orders',
    thunk: mac.orders.load,
    pageOptions: { isMACPage: true, hideSecondaryMenu: true },
    ufoName: 'mac-orders-route',
  },
  [mac.configUser.ROUTE]: {
    name: 'MAC_CONFIG_USER',
    path: '/my/config',
    thunk: mac.configUser.load,
    pageOptions: { isMACPage: true, hideSecondaryMenu: true },
    ufoName: 'mac-config-route',
  },
  [mac.editDetails.ROUTE]: {
    name: 'MAC_EDIT_DETAILS',
    path: '/my/edit-details',
    thunk: mac.editDetails.load,
    pageOptions: { isMACPage: true, hideSecondaryMenu: true },
    ufoName: 'mac-edit-details-route',
  },
  [mac.licenseEvaluation.ROUTE]: {
    name: 'MAC_LICENSE_EVALUATION',
    path: '/my/license-evaluation',
    thunk: mac.licenseEvaluation.load,
    pageOptions: { isMACPage: true, hideSecondaryMenu: true },
    ufoName: 'mac-license-evaluation-route',
  },
  [mac.paymentDetails.ROUTE]: {
    name: 'MAC_PAYMENT_DETAILS',
    path: '/my/payment-details/:accountId',
    thunk: mac.paymentDetails.load,
    pageOptions: { isMACPage: true, hideSecondaryMenu: true },
    ufoName: 'mac-payment-details-route',
  },
  [mac.updateCreditCard.ROUTE]: {
    name: 'MAC_UPDATE_CREDIT_CARD',
    path: '/my/update-credit-card/:accountId',
    thunk: mac.updateCreditCard.load,
    pageOptions: { isMACPage: true, hideSecondaryMenu: true },
    ufoName: 'mac-update-credit-card-route',
  },
  [mac.licensesPreselected.ROUTE]: {
    name: 'MAC_LICENSES_PRESELECTED',
    path: '/my/licenses/:senNumber',
    thunk: mac.licensesPreselected.load,
    pageOptions: { isMACPage: true, hideSecondaryMenu: true },
    ufoName: 'mac-licenses-preselected-route',
  },
};

export const basename = '/purchase';

export const routingOptions: Options = {
  basename,
  initialDispatch: false,
  querySerializer: queryString,
  onAfterChange: validateRoute,
};

export default wrapRouteThunks(
  initActionsRouteWrapper,
  languageInitWrapper,
  notificationFlagRouteWrapper,
)(routesMap);
