import { combineReducers } from 'redux';
import * as search from './search/duck';
import * as table from './table/duck';
import * as landing from './landing/duck';

export default combineReducers({
  search: search.default,
  table: table.default,
  landing: landing.default,
});

export { search, table, landing };

export type ChangeState = {
  search: search.model.SearchState;
  table: table.model.TableState;
  landing: landing.model.ChangeLandingState;
};
