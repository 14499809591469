// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Radio } from '@atlaskit/radio';
import { colors } from '@atlaskit/theme';
import { getProduct } from 'data/products';
import { CLOUD, DATACENTER, SERVER, OrderedDeploymentOptions } from 'model/hams/DeploymentOption';
import t from 'modules/i18n/intl';

import type { DeploymentOption } from 'model/hams/DeploymentOption';
import DeploymentIcon from 'components/common/DeploymentIcon';
import { Dimensions, Font } from 'components/visuals';
import Tooltip from 'components/common/Tooltip';
import NavLink from 'components/common/NavLink';
import type { Group } from 'modules/catalog/groups';
import { ServerProductDisabledKeys } from 'data/products/keys';

import StopSellingModalDialog from '../modal/StopSellingModalDialog';
import { selectors } from 'modules/configure/index';
import { makeSelectProductName } from 'modules/catalog/pricing';
import { FormattedMessage } from 'react-intl';
import config from 'appconfig';

type Props = {
  options: Group,
  selected: string | null,
  alwaysShowCloud: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  productName: string,
  // eslint-disable-next-line react/no-unused-prop-types
  onChange: (string) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  action: (string) => Object,
  hasAllowedServerOption?: boolean,
  isAccessRebrandingFFOn?: boolean,
};

const Wrapper = styled.div`
  margin: ${Dimensions.units(1)}px 0;
  cursor: not-allowed;
`;

const LabelWrapper = styled.div`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${(props) => {
    if (props.disabled) {
      return colors.N60;
    }
    return props.selected ? colors.B100 : colors.N800;
  }};
`;

const StyledRadio = styled(Radio)`
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'default')};
`;

const DisabledDeploymentWrapper = styled('div')`
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'default')};
  padding: 2px 4px 2px 28px;
  display: flex;
`;

const IconWrapper = styled.span`
  min-width: ${Dimensions.units(8)}px;
  display: inline-block;
  text-align: center;
  position: relative;
  top: -3px;
`;

const Text = styled.span`
  transform: translateY(-50%);
  display: inline-block;
  font-weight: normal;
  text-transform: capitalize;
  font-family: ${Font.family};
  font-size: 16px;
`;

const labels = {
  [CLOUD]: 'purchase.cloud',
  [DATACENTER]: 'purchase.data-center',
  [SERVER]: 'purchase.server',
};

const getLink = (productKey, action) => {
  const product = getProduct(productKey);
  if (!product) {
    return action(productKey);
  }
  return product.getLink();
};

const isUsingExternalLink = (productKey: string, action: (string) => () => void) => {
  const product = getProduct(productKey);
  return product !== null && product.externalUrl === getLink(productKey, action);
};

const getLabelWithTooltip = (
  Label: React.Node,
  options: Group,
  deployment: DeploymentOption,
  isAccessRebrandingFFOn?: boolean,
) => {
  let content = <FormattedMessage id="configure.product-not-available" values={{ deployment }} />;

  if (deployment === SERVER) {
    content = (
      <FormattedMessage
        id="configure.server-not-available"
        values={{
          link: (
            <a
              href={`${config.wacUrl}/migration/journey-to-cloud`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="configure.server-not-available.link" />
            </a>
          ),
        }}
      />
    );
  }

  if (deployment === CLOUD && options[SERVER] === ServerProductDisabledKeys.CROWD) {
    content = isAccessRebrandingFFOn ? (
      <FormattedMessage
        id="configure.cloud-not-available"
        values={{
          link: (
            <a href={`${config.wacUrl}/software/access`} rel="noopener noreferrer" target="_blank">
              <FormattedMessage id="configure.cloud-not-available.link.guard" />
            </a>
          ),
        }}
      />
    ) : (
      <FormattedMessage
        id="configure.cloud-not-available"
        values={{
          link: (
            <a href={`${config.wacUrl}/software/access`} rel="noopener noreferrer" target="_blank">
              <FormattedMessage id="configure.cloud-not-available.link" />
            </a>
          ),
        }}
      />
    );
  }

  return (
    <Tooltip direction="down" content={content}>
      {Label}
    </Tooltip>
  );
};

const getLabel = ({
  options,
  deployment,
  selected,
  action,
  isDisabled,
  isAccessRebrandingFFOn,
}: {
  options: Group,
  deployment: DeploymentOption,
  selected: boolean,
  action: (string) => () => void,
  isDisabled: boolean,
  isAccessRebrandingFFOn?: boolean,
}) => {
  const Label = (
    <NavLink
      to={options[deployment] ? getLink(options[deployment], action) : null}
      disabled={isDisabled}
      style={{ display: 'inline-block' }}
      outline={false}
    >
      <LabelWrapper selected={selected} disabled={isDisabled} data-testId="label.wrapper">
        <IconWrapper>
          <DeploymentIcon isDisabled={isDisabled} deployment={deployment} />
        </IconWrapper>
        <Text>{t(`${labels[deployment]}`)}</Text>
      </LabelWrapper>
    </NavLink>
  );

  if (isDisabled) {
    return getLabelWithTooltip(Label, options, deployment, isAccessRebrandingFFOn);
  }

  return Label;
};

const isDisableCloud = (name: string, option: string): boolean => {
  const targetAppNameP4J = name === 'com.radiantminds.roadmaps-jira.ondemand';
  return option === 'cloud' && targetAppNameP4J;
};

const isDisabledServer = (name: string, option: string): boolean =>
  option === SERVER && Object.values(ServerProductDisabledKeys).includes(name);

const buildOptionComponent = ({
  options,
  selected,
  onChange,
  action,
  hasAllowedServerOption,
  isAccessRebrandingFFOn,
}: {
  options: Group,
  selected: string | null,
  onChange: (string) => void,
  action: (string) => Object,
  hasAllowedServerOption?: boolean,
  isAccessRebrandingFFOn?: boolean,
}) => (option: DeploymentOption) => {
  // Temporary fix for: https://ops.internal.atlassian.com/jira/browse/HOT-91616
  const product = getProduct(options[option]);
  const usingExternalLink = isUsingExternalLink(options[option], action);
  const productKey = product ? product.key : 'not-allowed';

  const isDisabledServerAddon = option === SERVER && !hasAllowedServerOption;

  const isDisabled =
    !options[option] ||
    isDisableCloud(options[option], option) ||
    isDisabledServer(options[option], option) ||
    isDisabledServerAddon;

  const defaultServerOption = option === SERVER && options[SERVER] === selected;

  return (
    <Wrapper key={option} data-cy={`deployment-radio-button-${productKey}`}>
      {isDisabled && !defaultServerOption ? (
        <DisabledDeploymentWrapper
          isDisabled={isDisabled}
          defaultServerOption={defaultServerOption}
          data-testId="disabled.deployment.wrapper"
        >
          {getLabel({
            options,
            deployment: option,
            selected: false,
            action: () => null,
            isDisabled,
            isAccessRebrandingFFOn,
          })}
        </DisabledDeploymentWrapper>
      ) : (
        <StyledRadio
          isInvalid={false}
          onChange={() => onChange(options[option])}
          isDisabled={isDisabled || usingExternalLink}
          isChecked={options[option] === selected}
          name="product"
          value={options[option] || ''}
          label={getLabel({
            options,
            deployment: option,
            selected: options[option] === selected,
            action,
            isDisabled,
          })}
        />
      )}
    </Wrapper>
  );
};

const modalDialogOptions = (options: Group, props: Props) =>
  Object.fromEntries(
    Object.keys(options).map((option) => [
      option,
      {
        productKey: options[option],
        onChange: () => {
          if (isUsingExternalLink(options[option], props.action)) {
            window.location = getLink(options[option], props.action);
          } else {
            props.onChange(options[option]);
          }
        },
        isDisabledCloud: isDisableCloud(options[option], option),
      },
    ]),
  );

const ChooseDeployment = (props: Props): React.Node[] => {
  const { options }: { options: any } = props;

  if (props.alwaysShowCloud) {
    options[CLOUD] = options[CLOUD] || null;
  }

  return (
    <>
      {isDisabledServer(options[SERVER], SERVER) && (
        <StopSellingModalDialog
          selectedDeployment={props.selected}
          productName={props.productName}
          ssModalDialogOptions={modalDialogOptions(options, props)}
        />
      )}
      {Object.keys(options)
        .sort((a, b) => OrderedDeploymentOptions.indexOf(a) - OrderedDeploymentOptions.indexOf(b))
        .map(buildOptionComponent(props))}
    </>
  );
};

const makeMapStateToProps = () => {
  const selectProductName = makeSelectProductName();

  return (state) => ({
    productName: selectProductName(state, { productKey: selectors.selectMainProductKey(state) }),
  });
};

export default connect(makeMapStateToProps)(ChooseDeployment);
