/* eslint-disable import/prefer-default-export */

import Progress from 'redux-progress';
import { selectChosenOptions } from 'modules/change/table/duck/selectors/chosenOptions';
import { Selector } from 'model/State';
import selectCartPayload from 'modules/cart/duck/selectors/cart';

export const selectChangeCurrency: Selector<Progress<string | null>> = (state) =>
  Progress.all(selectChosenOptions(state), selectCartPayload(state)).map(([options, cart]) =>
    options.reduce(
      (currency, option) => option.currency,
      cart.items.length > 0 ? cart.currency : null,
    ),
  );
