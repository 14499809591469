/* eslint-disable consistent-return,no-throw-literal,import/prefer-default-export */

import Progress, { thunkProgress } from 'redux-progress';
import toLower from 'lodash/toLower';
import * as selectors from 'modules/checkout/contacts/duck/selectors';
import checkLoginExistsInAid from 'api/contacts/checkLoginExists';
import { CartThunk } from 'model/State';

export const VALIDATE_PURCHASER_LOGIN = 'VALIDATE_PURCHASER_LOGIN';

const validateAsync = async (email, loginUser): Promise<any> => {
  if (await checkLoginExistsInAid(email)) {
    if (!loginUser) {
      throw { requireLogin: true };
    } else if (toLower(email).trim() !== toLower(loginUser.username).trim()) {
      throw { text: 'This email address must match the authenticated user' };
    }
  }
};

export const validatePurchaserLogin = (): CartThunk => (dispatch, getState) => {
  const validity = selectors.getValidity(getState()).get('purchaserContactDetails.email');
  if (validity.progress.isNone) {
    Progress.all(selectors.getData(getState()), getState().auth.userDetails).ifSuccess(
      ([data, user]) => {
        const { email } = data.purchaserContactDetails;
        if (email) {
          dispatch(thunkProgress(VALIDATE_PURCHASER_LOGIN, validateAsync(email, user), { email }));
        }
      },
    );
  }
};
