import { CartThunk } from 'model/State';
import * as accounts from 'modules/accounts';

export const search = (): CartThunk => (dispatch, getState) => {
  const state = getState();
  const sen = state.addon && state.addon.search.sen;
  const email = state.addon && state.addon.search.email;

  if (sen && email) {
    dispatch(accounts.actions.searchAccount(sen.replace(/^SEN-/, ''), email));
  }
};
