import React from 'react';
import { connect } from 'react-redux';
import Progress from 'redux-progress';

import PageLoading from 'components/common/PageLoading';
import {
  AccountEntry,
  TableDispatchProps,
  TableOwnProps,
  TableStateProps,
} from 'modules/change/table/duck/model';
import { selectPageProps } from 'modules/change/table/duck/selectors';
import * as actions from 'modules/change/table/duck/actions';
import ChangeOptionsTableVisual from 'modules/change/table/ChangeOptionsTableVisual';
import { AccountChangeOption } from 'modules/accounts/model';
import { selectors as authSelectors } from 'modules/auth';
import { TermOption } from 'model/TermOption';
import { Dispatch, State } from 'model/State';

type Props = TableDispatchProps & TableOwnProps & { progress: Progress<TableStateProps> };

const ChangeOptionsTable = (props: Props) => (
  <PageLoading progress={props.progress}>
    {(stateProps) => <ChangeOptionsTableVisual {...props} {...stateProps} />}
  </PageLoading>
);

const mapStateToProps = (state: State) => ({
  progress: selectPageProps(state),
  isPartner:
    authSelectors.getUserChannel(state).ifSuccess((userChannel) => userChannel.isPartner) || false,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: TableOwnProps,
): Omit<
  TableDispatchProps,
  'onFetchAccountChangeOptions' | 'onFetchPaginatedAccounts' | 'onNotificationDismissed'
> => ({
  onAllEntriesToggle: () => dispatch(actions.toggleAllAccounts({})),
  onEntryToggle: (entry: AccountEntry) => dispatch(actions.toggleAccount(entry)),
  onOptionSelect: (account: AccountEntry, option: AccountChangeOption) =>
    dispatch(actions.pickOptionForAccount({ account, option })),
  onTermSelect: (account: AccountEntry, term: TermOption) =>
    dispatch(actions.pickTermForAccount({ account, term })),
  onAddToCart: ownProps.onAddToCart,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeOptionsTable);
