// @flow
import { progressResult } from 'util/rxOperators';
import validateEmailAddress from 'util/email';
import { distinctUntilChanged, map, filter, debounceTime, flatMap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import type { State$ } from 'model/State';
import * as contacts from 'modules/checkout/contacts';
import { selectors } from 'modules/checkout/contacts/duck';

export default (action$: Observable<any>, state$: State$) => {
  const isoCountryCode$ = state$.pipe(
    progressResult(selectors.getData),
    map((org) =>
      org.resellerOrder
        ? org.technicalOrganisationDetails.isoCountryCode
        : org.billingOrganisationDetails.isoCountryCode,
    ),
    distinctUntilChanged(),
    filter(Boolean),
  );

  const purchaserEmail$ = state$.pipe(
    progressResult(selectors.getData),
    map((o) => o.purchaserContactDetails.email),
    map((email) => (validateEmailAddress(email) ? email : null)),
    distinctUntilChanged(),
  );

  const technicalEmail$ = state$.pipe(
    progressResult(selectors.getData),
    map((o) => o.technicalContactDetails.email),
    map((email) => (validateEmailAddress(email) ? email : null)),
    debounceTime(300),
    distinctUntilChanged(),
  );

  const isDifferentTechnical$ = state$.pipe(
    progressResult(selectors.getData),
    map((o) => o.isDifferentTechnical),
    distinctUntilChanged(),
  );

  return combineLatest(
    isoCountryCode$,
    purchaserEmail$,
    technicalEmail$,
    isDifferentTechnical$,
  ).pipe(
    distinctUntilChanged(),
    flatMap(function* mapper([
      isoCountryCode,
      purchaserEmail,
      technicalEmail,
      isDifferentTechnical,
    ]) {
      if (isDifferentTechnical) {
        yield contacts.actions.checkValidationsForTC(isoCountryCode, technicalEmail);
      } else {
        yield contacts.actions.checkValidationsForPurchaser(isoCountryCode, purchaserEmail);
      }
    }),
  );
};
