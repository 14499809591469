import { createSelector } from 'reselect';
import { State, Selector } from 'model/State';
import { HamsCart } from 'modules/cart/duck/model';
import { CurrentContact } from 'modules/auth/model/CurrentContact';

export default createSelector(
  (s: State) => s.cart.payload.result as any,
  (s: State) => s.auth.currentContact.result as any,
  (cart: HamsCart, user: CurrentContact) => {
    const purchaserEmail = cart && cart.purchaserContactDetails.email;

    if (user) {
      return !purchaserEmail || purchaserEmail === user.contactDetails.email;
    }
    return false;
  },
) as Selector<boolean>;
