import React from 'react';
import styled from 'styled-components';
import Info from '@atlaskit/icon/glyph/info';
import SectionMessage from '@atlaskit/section-message';
import { colors } from '@atlaskit/theme';

import t from 'modules/i18n/intl';
import { Font } from 'components/visuals';

const InfoSectionMessage = styled.div`
  font-family: ${Font.displayFamily};
  margin-bottom: 32px;
  margin-top: 56px;

  & h1 {
    font-weight: 500;
  }
`;

const InfoIcon: React.FC = () => <Info label="Info icon" primaryColor={colors.P400} />;

export const MigrationProcessBanner: React.FC = () => {
  const title = t('open.quotes.warning.message.title');
  const description = t('open.quotes.warning.message.description');

  return (
    <InfoSectionMessage>
      <SectionMessage appearance="discovery" icon={InfoIcon} title={title}>
        {description}
      </SectionMessage>
    </InfoSectionMessage>
  );
};
