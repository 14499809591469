/* eslint-disable react/jsx-no-target-blank */
// @flow
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedDate } from 'react-intl';
import { parseZone } from 'moment';

import type { AccountEntry, TableProvidedEvents } from 'modules/change/table/duck/model';
import ChangeOptionsTable from 'modules/change/table/ChangeOptionsTable';
import SearchBySenBar from 'modules/change/search/SearchBar';
import type { SearchOwnProps } from 'modules/change/search/duck/model';
import { CannotBeRenewed } from 'modules/renew/NoRenewOptionsMessages';
import ChangeOptions from 'modules/renew/ChangeOptions';
import { actions } from 'modules/renew/duck/server';
import type { Dispatch } from 'model/State';
import { Font } from 'components/visuals';
import t from 'modules/i18n/intl';

import DataCenterApprovedAppsMessage from './DataCenterApprovedAppsMessage';

const Header = styled.h2`
  font-family: ${Font.displayFamily};
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-bottom: 0;
`;

const UnitCount = styled.span`
  font-size: 16px;
`;

const getOptionLabel = (option) => (
  <FormattedDate
    value={parseZone(option.expireDate).toDate()}
    day="numeric"
    month="short"
    year="numeric"
  />
);
const getTermLabel = (term) => (
  <span>
    <UnitCount>{term.months}</UnitCount> months
  </span>
);
const getNoOptionsMessage = () => <CannotBeRenewed />;

const RenewPage = (props: TableProvidedEvents & SearchOwnProps) => (
  <>
    <Header>{t('renew.header')}</Header>
    <SearchBySenBar
      onSearchSubmit={props.onSearchSubmit}
      onClearSearch={props.onClearSearch}
      noSearchResultsTitle={t('renew.search.noResults.title')}
      noSearchResultsMessage={t('renew.search.noResults.body')}
      invalidSearchTitle={t('renew.search.invalidSearch.title')}
      invalidSearchMessage={t('renew.search.invalidSearch.body')}
    />
    <DataCenterApprovedAppsMessage />
    <ChangeOptionsTable
      isRenewUpgrade
      onAddToCart={props.onAddToCart}
      onFetchAccountChangeOptions={props.onFetchAccountChangeOptions}
      onFetchPaginatedAccounts={props.onFetchPaginatedAccounts}
      isExpirationVisible
      selectOptionHeader={t('renew.to')}
      userTierHeader={t('renew.user-tier')}
      expirationHeader={t('renew.expires')}
      getOptionLabel={getOptionLabel}
      getTermLabel={getTermLabel}
      getNoOptionsMessage={getNoOptionsMessage}
      getChangeSelector={(rowProps, options) => <ChangeOptions {...rowProps} options={options} />}
    />
  </>
);

const mapDispatchToProps = (dispatch: Dispatch): TableProvidedEvents => ({
  onSearchSubmit: () => dispatch(actions.searchAccount()),
  onClearSearch: () => dispatch(actions.resetAccount()),
  onAddToCart: () => dispatch(actions.addAccountRenewItemsToCart()),
  onFetchAccountChangeOptions: (entry: AccountEntry) =>
    dispatch(actions.fetchAccountChangeOptions(entry.accountId)),
  onFetchPaginatedAccounts: (params) => dispatch(actions.fetchPaginatedAccounts(params)),
});

export default connect(null, mapDispatchToProps)(RenewPage);
