import Progress from 'redux-progress';
import intersection from 'lodash/intersection';

import { selectProductKeys } from 'modules/addon/duck/selectors/productKeys';
import { selectProductParents } from 'modules/catalog/parents';
import { Selector, State } from 'model/State';

const selectValidParents: Selector<Progress<string[]>> = (state: State) =>
  Progress.all(
    ...selectProductKeys(state).map((productKey) => selectProductParents(state, { productKey })),
  ).map((allParents) => intersection(...allParents));

export default selectValidParents;
