/* eslint-disable consistent-return,no-param-reassign */

import Progress from 'redux-progress';
import produce from 'immer';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';

import * as search from 'modules/change/search/duck/actions';
import * as actions from 'modules/change/table/duck/actions';
import { TableState } from 'modules/change/table/duck/model';

const initialState: TableState = {
  list: Progress.none,
  paginated: {},
  options: {},
  selected: {
    allAccounts: false,
    accounts: {},
    orderableItemIds: {},
    months: {},
  },
  progress: Progress.none,
  showFlag: false,
};

export default produce((draft: TableState, action): TableState | void => {
  switch (action.type) {
    case actions.LOAD_CHANGE_LIST:
      draft.list = action.progress;
      action.progress.ifSuccess(
        (overview) =>
          overview &&
          overview.accounts.forEach(({ accountId }) => {
            draft.selected.accounts[accountId] = false;
          }),
      );
      break;
    case actions.LOAD_PAGINATED_CHANGE_LIST:
      draft.list = action.progress;
      action.progress.ifSuccess((overview) => {
        if (overview?.totalItems && !draft.paginated[`${overview?.offset}`]) {
          draft.paginated[`${overview.offset}`] = overview;
          overview.accounts.forEach(({ accountId }) => {
            draft.selected.accounts[accountId] = false;
          });
        }
      });
      break;
    case actions.TOGGLE_ACCOUNT:
      draft.selected.accounts[action.payload.accountId] = !draft.selected.accounts[
        action.payload.accountId
      ];
      draft.selected.allAccounts = !values(draft.selected.accounts).includes(false);
      break;
    case actions.TOGGLE_ALL_ACCOUNTS:
      draft.selected.allAccounts = !draft.selected.allAccounts;
      draft.selected.accounts = mapValues(
        draft.selected.accounts,
        () => draft.selected.allAccounts,
      );
      break;
    case actions.PICK_OPTION:
      draft.selected.orderableItemIds[action.payload.account.accountId] =
        action.payload.option.orderableItemId;
      draft.selected.months[action.payload.account.accountId] =
        action.payload.option.maintenanceMonths;
      break;
    case actions.PICK_TERM:
      draft.selected.months[action.payload.account.accountId] = action.payload.term.months;
      break;
    case actions.SUBMIT_CHANGE_LIST:
      draft.progress = action.progress;
      if (action.progress.isCompleted && action.progress.result?.errorKey) {
        draft.progress = Progress.fail(action.progress.result);
      }
      break;
    case search.SELECT_FORM:
      draft.selected.allAccounts = false;
      Object.keys(draft.selected.accounts).forEach((id) => {
        if (!action.payload.foundAccountsIds.includes(id)) {
          draft.selected.accounts[id] = false;
        }
      });

      draft.options[action.payload.account.accountId] = Progress.success({
        optionsList: action.payload.isSSSDisabled ? [] : action.payload.account.changeOptions,
        ineligibilityReason: action.payload.account.ineligibilityReason,
      });
      break;
    case search.RESET_FORM:
      draft.selected.allAccounts = false;
      Object.keys(draft.selected.accounts).forEach((id) => {
        draft.selected.accounts[id] = false;
      });
      draft.options = {};
      break;
    case actions.RESET_CHANGE_LIST:
      return initialState;
    case actions.FETCH_ACCOUNT_CHANGE_OPTIONS:
      draft.options[action.payload.accountId] = action.progress.map(
        ({ accounts }) =>
          accounts &&
          accounts.length > 0 && {
            optionsList: action.payload.isSSSDisabled ? [] : accounts[0].changeOptions,
            ineligibilityReason: accounts[0].ineligibilityReason,
          },
      );
      break;
    default:
      break;
  }
}, initialState);
