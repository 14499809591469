// @flow
import { combineEpics } from 'redux-observable';

import * as contacts from 'modules/checkout/contacts';

import { timeAction, counter } from './util';

export default combineEpics(
  timeAction(contacts.actions.LOAD, 'checkout.contacts.load'),
  counter(contacts.actions.LOAD, 'checkout.contacts.load'),
);
