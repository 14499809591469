import React, { useEffect } from 'react';
import AkSelect from '@atlaskit/select';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { createFilter } from 'react-select';
import { Country } from 'modules/data/countries/model';
import t from 'modules/i18n/intl';
import Tooltip from 'components/common/Tooltip';

import { Select } from './styles';

const CurrencyChargedIn = styled.div`
  font-size: 10pt;
  color: ${colors.N80};
`;

type Props = typeof AkSelect & {
  country: Country;
  countries: Country[];
  jpy: boolean;
  enableCurrencyTooltip: boolean;
};

type ValidationState = 'default' | 'error';

/* The Validation state of 'success' adds a green border around the country select dropdown.
Could have used classNamePrefix='react-select' but that would affect cypress. Hence having this
workaround */
const getValidationState = (isInvalid): ValidationState => (!isInvalid ? 'default' : 'error');

const aliases = {
  US: 'USA U.S.A.',
  GB: 'UK U.K. Great Britain England Wales Scotland',
};

const stringify = (option) => `${option.value} ${option.label} ${aliases[option.value] || ''}`;

const Wrapper = ({
  countries,
  country,
  field,
  hasReconcilableWarning,
  isInvalid,
  showWarning,
  cartCurrency,
  showCompanyCountryCurrency,
  lockDifferentTechnical,
  isDifferentTechnical,
  ...props
}: Props) => {
  const currencyCode = cartCurrency || country?.primaryCurrency;

  useEffect(() => {
    if (!country) {
      field.edit(null);
    }
  }, [country]);

  let showCountryCurrency = false;
  if (!lockDifferentTechnical && isDifferentTechnical) {
    // company billing country select with tc
    showCountryCurrency = true;
  } else if (lockDifferentTechnical && isDifferentTechnical) {
    // reseller country select
    showCountryCurrency = true;
  } else {
    // company billing country select without tc
    showCountryCurrency = showCompanyCountryCurrency;
  }
  return (
    <div>
      <Select
        {...props}
        classNamePrefix="country"
        hasAutocomplete
        options={countries}
        getOptionLabel={(c) => c.displayName}
        getOptionValue={(c) => c.isoCountryCode}
        value={country || ''}
        onChange={(c) => field.edit(c.isoCountryCode)}
        onBlur={() => field.onBlur()}
        filterOption={createFilter({ stringify })}
        validationState={getValidationState(isInvalid)}
      />
      {currencyCode && showWarning && showCountryCurrency && (
        <CurrencyChargedIn>
          {t('checkout.contact.country-currency', {
            currencyCode,
            reconcilable: hasReconcilableWarning,
          })}
        </CurrencyChargedIn>
      )}
    </div>
  );
};

export default ({ field, country, countries, jpy, enableCurrencyTooltip, ...props }: Props) =>
  field &&
  (enableCurrencyTooltip ? (
    <Tooltip
      direction="right"
      content={t('checkout.contact.country-based-on-currency')}
      eventOn="onFocus"
      eventOff="onBlur"
      useHover={false}
      block
      displayAsFlag
    >
      <Wrapper country={country} countries={countries} field={field} jpy={jpy} {...props} />
    </Tooltip>
  ) : (
    <Wrapper country={country} countries={countries} field={field} jpy={jpy} {...props} />
  ));
