import React from 'react';
import { CollapseRow } from './CollapseRow';

export enum MACTableType {
  licenses = 'licenses',
  orders = 'orders',
  invoices = 'invoices',
}

type MACTableContentProps = {
  items: any[];
  tableType: MACTableType;
  senNumber?: string;
  isCollapsedAll?: boolean;
  isArchived?: boolean;
  isTrial?: boolean;
};

const MACTableContent = ({
  items,
  tableType,
  senNumber,
  isCollapsedAll,
  isArchived,
  isTrial,
}: MACTableContentProps) => {
  return items.length > 0 ? (
    items.map((item) => (
      <CollapseRow
        key={item.id}
        rowItem={item}
        tableType={tableType}
        senNumber={senNumber}
        isCollapsedAll={isCollapsedAll}
        isArchived={isArchived}
        isTrial={isTrial}
      />
    ))
  ) : (
    <> No items</>
  );
};

export default MACTableContent;
