import React, { useState } from 'react';
import t from 'modules/i18n/intl';
import styled from 'styled-components';

import AkButton from '@atlaskit/button/standard-button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Modal, {
  ModalTransition,
  ModalBody,
  ModalTitle,
  ModalHeader,
  ModalFooter,
} from '@atlaskit/modal-dialog';

import { Dimensions } from 'components/visuals';

interface Props {
  onClearProducts: () => void;
}
const ClearItemsWrapper = styled.div`
  margin-bottom: ${Dimensions.units(2) + 3}px;
`;

const ClearItems: React.FC<Props> = ({ onClearProducts }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCloseModal = () => setIsModalOpen(false);
  const onOpenModal = () => setIsModalOpen(true);

  return (
    <ClearItemsWrapper>
      <AkButton
        iconBefore={<CrossIcon label="Cross icon" size="small" />}
        appearance="subtle"
        onClick={onOpenModal}
      >
        {t('migrate.non-billing-user.search-builder.page.clear-items.button')}
      </AkButton>
      <ModalTransition>
        {isModalOpen && (
          <Modal onClose={onCloseModal}>
            <ModalHeader>
              <ModalTitle>{t('migrate.non-billing-user.search-builder.modal.title')}</ModalTitle>
            </ModalHeader>

            <ModalBody>{t('migrate.non-billing-user.search-builder.modal.content')}</ModalBody>
            <ModalFooter>
              {[
                {
                  text: t('migrate.non-billing-user.search-builder.modal.clear.button'),
                  onClick: () => {
                    onCloseModal();
                    onClearProducts();
                  },
                },
                {
                  text: t('migrate.non-billing-user.search-builder.modal.cancel.button'),
                  onClick: onCloseModal,
                },
              ]
                .map((props, index) => (
                  <AkButton
                    {...props}
                    onClick={props.onClick}
                    autoFocus={index === 0}
                    appearance={index === 0 ? 'primary' : 'subtle'}
                  >
                    {props.text}
                  </AkButton>
                ))
                .reverse()}
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </ClearItemsWrapper>
  );
};

export default ClearItems;
