import type { Dispatch, GetState } from 'model/State';
import { Query } from 'redux-first-router';

export const ROUTE = 'mac/licenses-preselected/ROUTE';

export const route = (params?: Query) => ({
  type: ROUTE,
  meta: {
    query: params,
  },
});

export const routeIncludeParams = () => (dispatch: Dispatch, getState: GetState) =>
  dispatch(route(getState().location.query));
