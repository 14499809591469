import React, { useMemo } from 'react';
import styled from 'styled-components';

import { GridRow } from 'modules/migrate/Layout';
import { GridColumn } from '@atlaskit/page';

import { Dimensions, Font } from 'components/visuals';

import { LICENSE_COMMERCIAL } from 'model/hams/LicenseType';
import { ProductFeatureUsageList } from 'modules/migrate/recommendations/api';

import { CTAOption } from './options';
import { TwoColumnCTAOption } from './twoColumnCTAOptions';
import { getCTAOption, getTwoColumnCTAOption, getMaxAgentsCount, getMaxUsersCount } from './utils';
import TwoColumnCTA from './TwoColumnCTA';

const TwoColumnCTAWrapper = styled.div`
  margin-top: ${Dimensions.units(6)}px;
  font-family: ${Font.family};
  display: flex;
`;

type Props = {
  isPartner: boolean;
  productFeatureUsageList: ProductFeatureUsageList[];
};

const DynamicCTA = ({ isPartner, productFeatureUsageList = [] }: Props) => {
  const maxUsersCount = useMemo(() => getMaxUsersCount(productFeatureUsageList), [
    productFeatureUsageList,
  ]);

  const maxAgentsCount = useMemo(() => getMaxAgentsCount(productFeatureUsageList), [
    productFeatureUsageList,
  ]);

  const commercialLicensesLength = useMemo(
    () =>
      productFeatureUsageList.filter((product) => product.licenseType === LICENSE_COMMERCIAL)
        .length,
    [productFeatureUsageList],
  );

  const ctaOption: CTAOption | null = getCTAOption({
    isPartner,
    maxUsersCount,
    maxAgentsCount,
    commercialLicensesLength,
    hasProducts: !!productFeatureUsageList?.length,
  });

  const twoColumnCTAOption: TwoColumnCTAOption | null = getTwoColumnCTAOption({
    isPartner,
    maxUsersCount,
    maxAgentsCount,
    commercialLicensesLength,
    hasProducts: !!productFeatureUsageList?.length,
  });

  if (!ctaOption) {
    return null;
  }

  if (!twoColumnCTAOption) {
    return null;
  }

  return (
    <GridRow>
      <GridColumn medium={2} />
      <GridColumn medium={8}>
        <TwoColumnCTAWrapper>
          <TwoColumnCTA {...twoColumnCTAOption} />
        </TwoColumnCTAWrapper>
      </GridColumn>
      <GridColumn medium={2} />
    </GridRow>
  );
};

export default DynamicCTA;
