// @flow
import { thunkProgress } from 'redux-progress';

import * as selectors from 'modules/marketplace/search/duck/selectors';
import { AMOUNT_PER_PAGE } from 'modules/marketplace/search/duck/contants';
import type { Dispatch, GetState } from 'model/State';
import { fetchSearchFromMarketplace } from 'modules/catalog/addons';

export const FETCH_SEARCH = 'marketplace/search/fetch';

export const fetch = () => (dispatch: Dispatch, getState: GetState) => {
  const query = selectors.query(getState());
  if (!query) {
    return undefined;
  }

  const params = {
    application: selectors.selectedProduct(getState()).key,
    text: query,
    offset: 0,
    limit: AMOUNT_PER_PAGE,
  };

  return dispatch(thunkProgress(FETCH_SEARCH, fetchSearchFromMarketplace(params), { params }));
};
