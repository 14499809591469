// @flow
import { combineReducers } from 'redux';
import academic from './academic';
import purchaseMode from './purchaseMode';
import type { PurchaseMode } from './purchaseMode';

export type Settings = {
  academic: boolean,
  purchaseMode: PurchaseMode,
};

export default combineReducers({
  academic,
  purchaseMode,
});
