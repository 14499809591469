// @flow
import React from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button/standard-button';
import Badge from '@atlaskit/badge';
import t from 'modules/i18n/intl';

type Props = {
  count: number,
  onClick: () => void,
};

const BadgeWrapper = styled.div`
  position: absolute;
  top: -14px;
  right: -14px;
`;

const CartItemButton = (props: Props) => (
  <Button onClick={props.onClick}>
    {t('navigation.header.menu.cart')}
    {props.count > 0 ? (
      <BadgeWrapper>
        <Badge appearance="primary">{props.count}</Badge>
      </BadgeWrapper>
    ) : null}
  </Button>
);

export default CartItemButton;
