import errorReporter from 'util/errorReporter';
import { thunkProgress } from 'redux-progress';
import { PLACE_ORDER } from 'modules/checkout/review/duck/actions/submit';
import { selectHamsClientConfig } from 'modules/auth/selectors';
import * as payment from 'modules/checkout/payment';
import { processOrderWithPaypal } from 'modules/cart/duck/api';
import type { paypalPayload } from 'modules/checkout/review/duck/model';
import type { Dispatch, GetState } from 'model/State';
import { addUpdatedTimeStamp } from 'modules/checkout/review/duck/util';
import type { CheckoutType } from 'modules/checkout/review/duck/actions/CheckoutTypes';

export default (cartId: string, checkoutType: CheckoutType) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const token = payment.api.storage.paypalDetails.load();
  const state = getState();

  if (token === null) {
    errorReporter('No paypal details stored');
    return dispatch(payment.actions.route());
  }

  const paypalData: paypalPayload = {
    nonce: token.nonce,
    paypalAccountEmail: token.details.email,
    termsOfServiceAccepted: getState().checkout.review.eulaAccepted,
  };
  const data = addUpdatedTimeStamp(paypalData, state);

  return dispatch(
    thunkProgress(
      PLACE_ORDER,
      processOrderWithPaypal(selectHamsClientConfig(state), cartId, data),
      { checkoutType },
    ),
  );
};
