/* eslint-disable class-methods-use-this */
import { ProductGroupKey, ProductKey } from 'data/products/keys';
import Product, { Props as BaseProps } from 'model/Product';
import { APP, CORE, ProductType } from 'model/ProductType';
import { route as goAddon } from 'modules/addon/duck/actions/route';

export type Props = BaseProps & {
  addon?: boolean;
  groupKey: ProductGroupKey;
  recommended?: ProductKey[];
  parentKeys?: ProductKey[];
  disabled?: boolean;
};

export default class SoftwareProduct extends Product {
  groupKey: ProductGroupKey;
  addon: boolean;
  recommended: ProductKey[];
  parentKeys: ProductKey[];
  disabled: boolean;

  constructor({
    addon = false,
    groupKey,
    recommended = [],
    parentKeys = [],
    disabled = false,
    ...rest
  }: Props) {
    super(rest);
    this.groupKey = groupKey;
    this.addon = addon;
    this.recommended = recommended;
    this.parentKeys = parentKeys;
    this.disabled = disabled;
  }

  get isDataCenter() {
    return false;
  }

  get type(): ProductType {
    return this.addon ? APP : CORE;
  }

  getRecommendations(): string[] {
    return this.recommended as any;
  }

  getGroupKey(): ProductGroupKey | null {
    return this.groupKey;
  }

  getLink(): Object | string | null {
    return this.addon ? goAddon(this.key) : super.getLink();
  }

  get isDisabled(): boolean {
    return this.disabled;
  }
}
/* eslint-enable class-methods-use-this */
