// @flow
import Progress, { thunkProgress } from 'redux-progress';
import type { Dispatch, GetState } from 'model/State';
import { selectHamsClientConfig, selectXsrfToken } from 'modules/auth/selectors';
import watchProgress from 'model/watchProgress';
import * as api from 'modules/accounts/api';

export const FETCH_CAN_COTERM = 'canCoTerm/FETCH';

export const fetchCanCoTermAction = () => async (dispatch: Dispatch, getState: GetState) => {
  dispatch({ type: FETCH_CAN_COTERM, progress: Progress.inProgress });

  const xsrf = await watchProgress(selectXsrfToken);
  if (xsrf) {
    await dispatch(
      thunkProgress(FETCH_CAN_COTERM, api.fetchCanCoTerm(selectHamsClientConfig(getState()))),
    );
  } else {
    // Co-term checkbox should be enabled by default if user is logged out
    dispatch({ type: FETCH_CAN_COTERM, progress: Progress.success(true) });
  }
};
