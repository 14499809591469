import React from 'react';
import { connect } from 'react-redux';
import Progress from 'redux-progress';

import VisualAddonPage, { Events, Manual } from 'modules/addon/VisualAddonPage';
import {
  selectPageData,
  selectOverviewData,
  isAddToCartEnabled,
} from 'modules/addon/duck/selectors/pageData';
import * as addon from 'modules/addon';
import PageLoading from 'components/common/PageLoading';
import { Dispatch, State } from 'model/State';
import * as marketplace from 'modules/marketplace';
import { toggleAcademic } from 'modules/settings/academic';
import { getCurrentContactEmail } from 'modules/auth/selectors/contactDetails';
import {
  isDisableBambooCrowdServerOptionAddonsFFOn,
  isServerAddonsEndgameFFOn,
} from '../../util/featureFlags';
import { AddonState } from './duck/model/state';

type StateProps = addon.model.AddonState & {
  progress: Progress<addon.model.AddonPageData>;
  overviewProgress: Progress<addon.model.OverviewData>;
  isAddToCartEnabled: boolean;
  isDisableBambooCrowdServerAddonsFFOn: boolean;
  isServerAddonsEOLEndgameFFOn: boolean;
  currentContactEmail: string | null;
};

type DispatchProps = { events: Events };

const AddonPage = (props: StateProps & DispatchProps) => (
  <PageLoading progress={props.progress}>
    {(data) => (
      <VisualAddonPage
        {...props}
        data={data}
        overviewProgress={props.overviewProgress}
        isAddToCartEnabled={props.isAddToCartEnabled}
        events={props.events}
        terms={props.terms as AddonState['terms'] & Manual}
      />
    )}
  </PageLoading>
);

const mapStateToProps = (state: State): StateProps => ({
  ...state.addon,
  progress: selectPageData(state),
  overviewProgress: selectOverviewData(state),
  isAddToCartEnabled: isAddToCartEnabled(state),
  isDisableBambooCrowdServerAddonsFFOn: isDisableBambooCrowdServerOptionAddonsFFOn(state).result,
  currentContactEmail: getCurrentContactEmail(state),
  isServerAddonsEOLEndgameFFOn: isServerAddonsEndgameFFOn(state).result,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  events: {
    onDeploymentChange: (productKey) => dispatch(addon.actions.route(productKey)),
    onModeChange: (mode) => () => dispatch(addon.actions.changeMode(mode)),
    onParentChange: (parent) => dispatch(addon.actions.chooseParent(parent)),
    onTermChange: (key, term) => dispatch(addon.actions.chooseTerm({ key, term })),
    onSearchForAddonsClick: () => dispatch(marketplace.actions.route()),
    onAddToCart: () => dispatch(addon.actions.addToCart()),
    onSenChange: (val) => dispatch(addon.actions.changeSen(val)),
    onEmailChange: (val) => dispatch(addon.actions.changeEmail(val)),
    onSearchSubmit: () => dispatch(addon.actions.search()),
    onTierSelect: (tier) => dispatch(addon.actions.chooseTier(tier)),
    onAcademicToggle: () => dispatch(toggleAcademic()),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddonPage);
