import Progress from 'redux-progress';
import { MultiProductTier } from 'modules/addon/duck/selectors/mergedProductTiers';
import { Account } from 'modules/accounts/model';
import { HamsCart } from 'modules/cart/duck/model';

export type AddonConfigMode = 'choose' | 'search' | 'manual';

export const CHOOSE: AddonConfigMode = 'choose';
export const SEARCH: AddonConfigMode = 'search';
export const MANUAL: AddonConfigMode = 'manual';

export type ChooseModeState = {
  accountId: number | null;
  cartItemId: number | null;
};

export type SearchModeState = {
  sen: string | null;
  email: string | null;
  result: Progress<Account | null>;
};

export type ManualModeState = {
  tier: MultiProductTier | null;
};

export type AddonState = {
  mode: AddonConfigMode;

  choose: ChooseModeState;
  search: SearchModeState;
  manual: ManualModeState;

  terms: { [parentKey: string]: number | null };

  submitProgress: Progress<HamsCart>;
};
