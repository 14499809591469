/* eslint-disable jsx-a11y/label-has-for */
// @flow
import React from 'react';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import Toggle from '@atlaskit/toggle';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import { colors } from '@atlaskit/theme';
import { Font } from 'components/visuals';
import Tooltip from 'components/common/Tooltip';
import config from 'appconfig';
import type { Dispatch, State } from 'model/State';
import { toggleAcademic, isAcademicSwitchOn } from 'modules/settings/academic';
import t from 'modules/i18n/intl';
import { isAcademicPricingToggleFFOn } from 'util/featureFlags';

type StateProps = {
  value: boolean,
};

type DispatchProps = {
  onToggle: () => void,
};

type Props = StateProps & DispatchProps;

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  font-family: ${Font.family};
`;

const HelpTooltip = () => (
  <Tooltip
    direction="down-middle"
    content={
      <span>
        {t('common.academic-pricing-help')}
        &nbsp;
        <a
          href={`${config.wacUrl}/licensing/purchase-licensing#licensing-3`}
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
          rel="noreferrer"
        >
          {t('purchase.learn-more')}
        </a>
      </span>
    }
  >
    <QuestionCircleIcon size="small" primaryColor={colors.N80} />
  </Tooltip>
);

export const AcademicToggleVisual = (props: Props) => {
  const isAcademicPricingToggleFlagOn = useSelector(isAcademicPricingToggleFFOn).result;
  return !isAcademicPricingToggleFlagOn ? (
    <Wrapper data-testid="academic.toggle.wrapper">
      <label>
        {t('purchase.academic-pricing')} <HelpTooltip />
        <Toggle isChecked={props.value} onChange={props.onToggle} name="academic" value="true" />
      </label>
    </Wrapper>
  ) : null;
};

const mapStateToProps = (state: State): StateProps => ({
  value: isAcademicSwitchOn(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onToggle: () => dispatch(toggleAcademic()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcademicToggleVisual);
