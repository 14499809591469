import React from 'react';
import { parseZone } from 'moment';
import { FormattedMessage } from 'react-intl';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import { colors } from '@atlaskit/theme';
import { saleType } from 'model/hams/SaleType';
import Cell from 'modules/cart/summary/cells/Cell';
import CellSubLine from 'modules/cart/summary/cells/CellSubLine';
import { HamsCartItem } from 'modules/cart/duck/model';
import Tooltip from 'components/common/Tooltip';
import t from 'modules/i18n/intl';
import config from 'appconfig';

type Props = {
  item: HamsCartItem;
  isChild: boolean;
};

const shouldShowTerm = ({ isChild }: Props) => !isChild;

const maintenanceMonths = (item) =>
  item.maintenanceMonths ||
  parseZone(item.maintenanceEndDate).diff(parseZone(item.maintenanceStartDate), 'months');

const endDate = (item) => parseZone(item.maintenanceEndDate).format('D MMM YYYY');

const HelpTooltip = () => (
  <Tooltip
    direction="right-middle"
    content={
      <span>
        {t('review.maintenace.coterm-help')}
        &nbsp;
        <a
          href={`${config.wacUrl}/licensing/purchase-licensing#softwaremaintenance-5`}
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
      </span>
    }
  >
    <QuestionCircleIcon size="small" primaryColor={colors.N80} label="help" />
  </Tooltip>
);

const TermCell = (props: Props) => {
  if (!shouldShowTerm(props)) {
    return <Cell />;
  }

  const { item } = props;

  if (item.productDetails.isTraining) {
    return <Cell>N/A</Cell>;
  }

  if (item.productDetails.saleType === saleType.RENEWAL) {
    return (
      <Cell>
        <div>
          {endDate(item)} {item.isCoTermedItem && <HelpTooltip />}
        </div>
        <CellSubLine>{maintenanceMonths(item)} month renewal</CellSubLine>
      </Cell>
    );
  }

  return (
    <Cell>
      <div>
        <FormattedMessage
          id="upgrade.months"
          values={{
            formattedCount: maintenanceMonths(item),
            count: maintenanceMonths(item),
          }}
        />
        {item.productDetails.isSubscription && (
          <CellSubLine>
            <FormattedMessage id="upgrade.subscription-term" />
          </CellSubLine>
        )}{' '}
        {item.isCoTermedItem && <HelpTooltip />}
      </div>
      {item.maintenanceEndDate && (item.productDetails.isSubscription || item.isCoTermedItem) ? (
        <CellSubLine>Until {endDate(item)} </CellSubLine>
      ) : null}
    </Cell>
  );
};

export default TermCell;
