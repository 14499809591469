import React from 'react';
import styled from 'styled-components';
import { saleType } from 'model/hams/SaleType';
import CellSubLine from 'modules/cart/summary/cells/CellSubLine';
import Cell from 'modules/cart/summary/cells/Cell';
import TierLabel from 'components/common/TierLabel';
import { LICENSE_ACADEMIC, LICENSE_STARTER } from 'model/hams/LicenseType';
import { HamsCartEnrichedItem } from 'modules/cart/duck/model';
import { showTierLabel } from 'modules/cart/duck/selectors/cart';
import { getProduct } from 'data/products/index';

type Props = {
  item: HamsCartEnrichedItem;
};

const Upgrade = styled.div`
  max-width: 100px;
`;

const upgradeMessage = (message) => message.replace(/.*?(Upgrade.*)/, '$1');

const TierCell = (props: Props) => (
  <Cell>
    {getProduct(props.item.productDetails.productKey)
      ? getProduct(props.item.productDetails.productKey).additionalTierLabelCell
      : null}
    {showTierLabel(props.item) && <TierLabel {...props.item.productDetails} />}
    {props.item.productDetails.licenseType === LICENSE_STARTER ? (
      <CellSubLine>Starter License</CellSubLine>
    ) : null}
    {props.item.productDetails.licenseType === LICENSE_ACADEMIC ? (
      <CellSubLine>Academic License</CellSubLine>
    ) : null}
    {props.item.productDetails.saleType === saleType.UPGRADE || props.item.crossgradeAccountId ? (
      <Upgrade>
        <CellSubLine>{upgradeMessage(props.item.description)}</CellSubLine>
      </Upgrade>
    ) : null}
    {props.item.productDetails.saleType === saleType.NEW && props.item.parentCartItemId && (
      <CellSubLine>{props.item.description}</CellSubLine>
    )}
  </Cell>
);

export default TierCell;
