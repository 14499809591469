import * as actions from 'modules/marketplace/search/duck/actions';

export default (state = '', action) => {
  switch (action.type) {
    case actions.SET_QUERY:
      return action.payload;
    default:
      return state;
  }
};
