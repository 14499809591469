import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { Font, Dimensions } from 'components/visuals';

export const SearchResults = styled.div`
  margin-top: ${Dimensions.units(4)}px;
  font-family: ${Font.family};
`;

export const SearchForm = styled.form`
  font-family: ${Font.family};
  display: flex;
  flex-direction: row;
  > * {
    margin-right: ${Dimensions.units(2)}px;
  }
  > :first-child {
    width: 340px;
  }
  > :nth-child(2) {
    min-width: 180px;
  }
  > :last-child {
    margin-right: 0;
  }
`;

export const Section = styled.div`
  margin-top: ${Dimensions.units(4)}px;
  > h3 {
    font-family: ${Font.displayFamily};
    font-weight: 500;
    font-size: ${Dimensions.units(3)}px;
    line-height: 32px;
    margin: 0 0 4px;
  }
`;

export const ProductSelectionList = styled.ul`
  margin-top: ${Dimensions.units(2)}px;
  padding: 0;
  list-style: none;
`;

export const ProductSelectionEntry = styled.li`
  height: 50px;
  > a {
    color: ${(props) => (props.selected ? colors.N300 : colors.B400)};
    font-weight: ${(props) => (props.selected ? '400' : '500')};
    font-size: 18px;
    cursor: pointer;
    &:hover {
      color: ${(props) => (props.selected ? colors.N300 : colors.B400)};
    }
  }
`;

export const AddonList = styled.ul`
  margin: 0 0 18px;
  padding: 0;
  list-style: none;
`;

export const AddonItem = styled.li`
  display: flex;
  flex-direction: row;
  margin-top: 0;
  padding: ${Dimensions.units(2)}px;
  border-bottom: 1px solid ${colors.N40};
  &:hover {
    background-color: ${colors.N20};
    cursor: pointer;
  }
  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const AddonImg = styled.div`
  margin-right: ${Dimensions.units(2)}px;
  > img {
    width: 40px;
    height: auto;
  }
`;

export const AddonTitle = styled.h4`
  font-family: ${Font.displayFamily};
  font-weight: 400;
  font-size: ${Dimensions.units(2)}px;
  color: ${colors.N900};
  line-height: ${Dimensions.units(3)}px;
  margin: 0 0 8px;
`;

export const AddonSummary = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 18px;
`;

export const LearnMore = styled.a`
  color: ${colors.B400};
  font-weight: 500;
  text-decoration: none;
  font-size: 13px;
`;
