/* eslint-disable import/prefer-default-export, no-param-reassign */
import produce from 'immer';
import selectCartPayload from 'modules/cart/duck/selectors/cart';

export const addUpdatedTimeStamp = produce(
  (draft, state) =>
    selectCartPayload(state).ifSuccess((cart) => {
      draft.updatedTime = cart.updated;
    }) || draft,
);
