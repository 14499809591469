import Progress from 'redux-progress';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { Selector, State } from 'model/State';

export const getFeatureFlags: Selector<Progress<LDFlagSet>> = (state) => state.meta.launchdarkly;

export const isFeatureFlagOn: Selector<Progress<boolean>, { key: string }> = (
  state: State,
  props: { key: string },
) => state.meta.launchdarkly.map((r) => r[props.key] === true);

export const makeIsFeatureFlagOn: (arg0: string) => Selector<Progress<boolean>> = (key) => (
  state,
) => isFeatureFlagOn(state, { key });
