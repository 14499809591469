// @flow
import React from 'react';
import { connect } from 'react-redux';
import Progress from 'redux-progress';
import Textfield from '@atlaskit/textfield';
import Select from '@atlaskit/select';
import Button from '@atlaskit/button/standard-button';
import LoadingButton from '@atlaskit/button/loading-button';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import AddonEntries from 'modules/marketplace/components/AddonEntries';
import { SearchForm, SearchResults, Section } from 'modules/marketplace/components/styled';
import type { MarketplaceProduct } from 'modules/marketplace/data';
import * as selectors from 'modules/marketplace/search/duck/selectors';
import * as actions from 'modules/marketplace/search/duck/actions';
import ProgressLoader from 'components/common/ProgressLoader';
import type { Dispatch, State } from 'model/State';
import type { MarketplaceAddon } from 'modules/catalog/addons';
import { SearchAddonsProductOptions } from 'modules/marketplace/data';
import { route as goAddon } from 'modules/addon/duck/actions/route';
import t from 'modules/i18n/intl';

type StateProps = {
  entries: Progress<MarketplaceAddon[]>,
  selectedProduct: MarketplaceProduct,
  query: string,
  hasMore: Progress<boolean>,
};

type DispatchProps = {
  fetch: () => void,
  fetchMore: () => void,
  selectProduct: (product: MarketplaceProduct) => void,
  setQuery: (query: string) => void,
  goAddon: (productKey: string) => () => void,
};

type SearchAddonsProps = StateProps & DispatchProps;

type LoadMoreButtonProps = {
  fetchMore: () => void,
};

const LoadMoreButton = (props: LoadMoreButtonProps) => (
  <LoadingButton {...props} appearance="primary" onClick={props.fetchMore}>
    {t('marketplace.search.load-more.button')}
  </LoadingButton>
);

export const SearchAddonsStateless = (props: SearchAddonsProps) => {
  const onChangeQuery = (e) => props.setQuery(e.target.value);
  const onPressEnter = (e) => e.key === 'Enter' && props.fetch();

  return (
    <Section>
      <h3>{t('marketplace.search.title')}</h3>
      <SearchForm onSubmit={props.fetch}>
        <Textfield
          id="marketplace-search-field"
          isCompact
          shouldFitContainer
          onChange={onChangeQuery}
          onKeyDown={onPressEnter}
          value={props.query}
        />
        <Select
          id="marketplace-product-field"
          isSearchable={false}
          spacing="compact"
          options={SearchAddonsProductOptions}
          getOptionValue={(p) => p.key}
          onChange={props.selectProduct}
          value={props.selectedProduct}
        />
        <Button appearance="primary" onClick={props.fetch}>
          {t('marketplace.search.button')}
        </Button>
      </SearchForm>
      <ProgressLoader progress={props.entries} errorMessage={t('marketplace.search.load.fail')}>
        {(results) => (
          <SearchResults>
            {results.length ? (
              <>
                <h3>{t('marketplace.search.subtitle')}</h3>
                <AddonEntries entries={results} goAddon={props.goAddon} />
                {props.hasMore.fold({
                  loading: () => (
                    <LoadMoreButton fetchMore={props.fetchMore} isLoading isDisabled />
                  ),
                  success: (r: boolean) => r && <LoadMoreButton fetchMore={props.fetchMore} />,
                  failed: () => (
                    <SectionMessage
                      appearance="error"
                      actions={[{ text: 'Try again', onClick: props.fetchMore }].map(
                        ({ text, ...restAction }) => (
                          <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
                        ),
                      )}
                    >
                      {t('marketplace.search.load.fail')}
                    </SectionMessage>
                  ),
                })}
              </>
            ) : (
              <SectionMessage appearance="information">
                {t('marketplace.search.empty-results')}
              </SectionMessage>
            )}
          </SearchResults>
        )}
      </ProgressLoader>
    </Section>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  entries: selectors.entries(state),
  selectedProduct: selectors.selectedProduct(state),
  query: selectors.query(state),
  hasMore: selectors.hasMore(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetch: () => dispatch(actions.fetch()),
  fetchMore: () => dispatch(actions.fetchMore()),
  selectProduct: (product: MarketplaceProduct) => dispatch(actions.selectProduct(product)),
  setQuery: (query: string) => dispatch(actions.setQuery(query)),
  goAddon: (productKey: string) => dispatch(goAddon(productKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchAddonsStateless);
