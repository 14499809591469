import type { State } from 'model/State';

export const getCurrentContactDetails = (state: State) => state.auth.currentContact;

export const getCurrentContactEmail = (state: State) =>
  getCurrentContactDetails(state).ifSuccess(
    (currentContact) => currentContact?.contactDetails?.email || null,
  ) || null;

export const getCurrentContactName = (state: State) =>
  getCurrentContactDetails(state).ifSuccess(
    (currentContact) => currentContact?.contactDetails?.firstName || null,
  ) || null;
