import appconfig from 'appconfig';
import { IAuthenticationContext } from 'modules/migrate/Authenticated';
import { LicenseType } from 'model/hams/LicenseType';

export interface ProductFeatureUsageList {
  productKey: string;
  unitCount: number;
  amount: number;
  starter?: boolean;
  licenseType?: LicenseType;
  unitType?: string;
}
export interface EntitlementSummary {
  productFeatureUsageList: ProductFeatureUsageList[];
}

export const fetchRecommendations = async ({
  authenticationContext,
}: {
  authenticationContext: IAuthenticationContext;
}): Promise<EntitlementSummary> => {
  const response = await fetch(
    `${appconfig.hamsUrl}/1.0/public/account/migrate/entitlementSummary`,
    {
      headers: authenticationContext.getRequestHeaders(),
      mode: 'cors',
      credentials: 'include',
    },
  );

  if (response.status === 200) {
    return response.json();
  }
  const error = new Error();
  try {
    Object.assign(error, await response.json());
  } catch (e) {
    error.message = e.message;
  }
  throw error;
};
