import SectionMessage from '@atlaskit/section-message';
import React from 'react';
import t from 'modules/i18n/intl';
import styled from 'styled-components';
import { Font, Dimensions } from 'components/visuals';
import { emitUIEpicEvent, utils } from 'modules/analytics';
import { connect } from 'react-redux';
import { Dispatch } from 'model/State';
import config from 'appconfig';
import { UISource } from 'modules/analytics/model/UIEvent';
import { getAdditionalAnalyticsAttributes } from 'modules/analytics/util';
import Info from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import StopSellingJWMWarningMessage from './StopSellingJWMWarningMessage';

const InfoSectionMessage = styled.div`
  font-family: ${Font.displayFamily};
  margin-bottom: ${Dimensions.units(4)}px;
  margin-top: ${Dimensions.units(6)}px;

  & h1 {
    font-weight: 500;
  }
`;

const WarningSectionMessage = styled.div`
  font-family: ${Font.displayFamily};
  margin: ${Dimensions.units(2.5)}px 0;
`;

const BannerSeparator = styled.div`
  height: ${Dimensions.units(2)}px;
`;

type DispatchProps = {
  onClickEOL: () => void;
};

type Props = DispatchProps & {
  isProductListPage: boolean;
};

const infoIcon = () => <Info label="Info icon" primaryColor={colors.P400} />;

export const ServerAddonsEOLWarningMessage = ({
  isServerAddonsEOLEndgameFFOn,
}: {
  isServerAddonsEOLEndgameFFOn: boolean;
}) => {
  const learnMoreUrl = isServerAddonsEOLEndgameFFOn
    ? `${config.wacUrl}/migration/assess/journey-to-cloud`
    : `${config.wacUrl}/migration/journey-to-cloud`;

  const text = isServerAddonsEOLEndgameFFOn
    ? t('eol.server-addons-endgame.warning.message.text')
    : t('eol.server-addons.warning.message.text');
  const link = isServerAddonsEOLEndgameFFOn
    ? t('eol.server-addons-endgame.warning.message.link')
    : t('eol.server-addons.warning.message.link');

  return (
    <WarningSectionMessage>
      <SectionMessage appearance="warning">
        {text}
        &nbsp;
        <a target="_blank" rel="noopener noreferrer" href={learnMoreUrl}>
          {link}
        </a>
      </SectionMessage>
    </WarningSectionMessage>
  );
};

export const WarningMessage = ({ isProductListPage, onClickEOL }: Props) => {
  const eolTitle = t('eol.warning.message.title');
  const eolDescription = t('eol.warning.message.ss.text');
  const eolLearnMoreText = t('eol.warning.message.link');
  const eolLearnMoreUrl = `${config.wacUrl}/migration/journey-to-cloud`;

  return (
    <InfoSectionMessage>
      {isProductListPage && (
        <>
          <StopSellingJWMWarningMessage />
          <BannerSeparator />
        </>
      )}
      <SectionMessage appearance="discovery" icon={infoIcon} title={eolTitle}>
        {eolDescription}
        &nbsp;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={eolLearnMoreUrl}
          onClick={() => onClickEOL()}
        >
          {eolLearnMoreText}
        </a>
      </SectionMessage>
    </InfoSectionMessage>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickEOL: () => {
    dispatch(
      emitUIEpicEvent({
        data: utils.getLinkClickEventData('eolWarningMessageLink', UISource.Banner),
        selectorsOrObjects: [
          getAdditionalAnalyticsAttributes(
            UISource.Banner,
            'eolWarningMessageLink',
            'Important changes to our server and Data Center products',
            'Learn what this means for you',
          ),
        ],
      }),
    );
  },
});

export default connect(null, mapDispatchToProps)(WarningMessage);
