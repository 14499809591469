// @flow
import { DataCenterProductKeys, ProductGroupKeys, ServerProductKeys } from 'data/products/keys';
import config from 'appconfig';
import t from 'modules/i18n/intl';
import DataCenterProduct from 'model/editions/DataCenterProduct';
import ServerProduct from 'model/editions/ServerProduct';
import SoftwareProductGroup from 'model/SoftwareProductGroup';
import { deploymentOptions } from 'model/features';

export const crowdServer = new ServerProduct({
  key: ServerProductKeys.CROWD,
  groupKey: ProductGroupKeys.CROWD,
  tagLine: t('products.crowd.tagline'),
  description: t('products.crowd.description'),
  name: t('products.crowd.name'),
  moreLink: `${config.wacUrl}/software/crowd`,
});

export const crowdDataCenter = new DataCenterProduct(
  DataCenterProductKeys.CROWD,
  crowdServer,
  [],
  [deploymentOptions],
);

export const crowdProductGroup = new SoftwareProductGroup(crowdServer, crowdDataCenter);
