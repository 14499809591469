import Progress from 'redux-progress';
import {
  MultiProductTier,
  selectMergedAddonsProductTiers,
} from 'modules/addon/duck/selectors/mergedProductTiers';
import { Selector } from 'model/State';

export const selectManualTier: Selector<Progress<MultiProductTier>> = (state) =>
  state.addon && state.addon.manual.tier
    ? Progress.success(state.addon.manual.tier)
    : selectMergedAddonsProductTiers(state).map((tiers) => tiers[0]);
