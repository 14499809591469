import { ProductFeatureUsageList } from '../../api';
import { ctaOptions, CTAOption } from './options';
import { TwoColumnCTAOption, twoColumnCTAOptions } from './twoColumnCTAOptions';

export const getMaxUsersCount = (productFeatureUsageList: ProductFeatureUsageList[]): number =>
  productFeatureUsageList
    .filter((product) => product.unitType === 'USER')
    .reduce(
      (prevProduct, currentProduct) =>
        prevProduct.unitCount > currentProduct.unitCount ? prevProduct : currentProduct,
      { unitCount: 0 },
    ).unitCount;

export const getMaxAgentsCount = (productFeatureUsageList: ProductFeatureUsageList[]): number =>
  productFeatureUsageList
    .filter((product) => product.unitType !== 'USER')
    .reduce(
      (prevProduct, currentProduct) =>
        prevProduct.unitCount > currentProduct.unitCount ? prevProduct : currentProduct,
      { unitCount: 0 },
    ).unitCount;

const COHORT_UNIT_COUNTS = {
  LARGE_MAX: 10000,
  MEDIUM_MAX: 1000,
  SMALL_MAX: 25,
  STARTER_MAX: 10,
};

const COHORT_AGENT_COUNTS = {
  MEDIUM_MAX: 200,
  SMALL_MAX: 25,
  STARTER_MAX: 3,
};

const isLargeCohortUser = (
  maxUsersCount: number,
  maxAgentsCount: number,
  commercialLicensesLength: number,
) =>
  (maxUsersCount > COHORT_UNIT_COUNTS.MEDIUM_MAX &&
    maxUsersCount <= COHORT_UNIT_COUNTS.LARGE_MAX) ||
  maxAgentsCount > COHORT_AGENT_COUNTS.MEDIUM_MAX ||
  commercialLicensesLength > 5;

const isMediumCohortUser = (maxUsersCount: number, maxAgentsCount: number) =>
  (maxUsersCount >= COHORT_UNIT_COUNTS.SMALL_MAX &&
    maxUsersCount <= COHORT_UNIT_COUNTS.MEDIUM_MAX) ||
  (maxAgentsCount >= COHORT_AGENT_COUNTS.SMALL_MAX &&
    maxAgentsCount <= COHORT_AGENT_COUNTS.MEDIUM_MAX);

const isSmallCohortUser = (maxUsersCount: number, maxAgentsCount: number) =>
  (maxUsersCount > COHORT_UNIT_COUNTS.STARTER_MAX &&
    maxUsersCount < COHORT_UNIT_COUNTS.SMALL_MAX) ||
  (maxAgentsCount > COHORT_AGENT_COUNTS.STARTER_MAX &&
    maxAgentsCount < COHORT_AGENT_COUNTS.SMALL_MAX);

const isStarterCohortUser = (maxUsersCount: number, maxAgentsCount: number) =>
  maxUsersCount <= COHORT_UNIT_COUNTS.STARTER_MAX ||
  maxAgentsCount <= COHORT_AGENT_COUNTS.STARTER_MAX;

export const getCTAOption = ({
  isPartner,
  maxUsersCount,
  maxAgentsCount,
  commercialLicensesLength,
  hasProducts,
}): CTAOption | null => {
  if (isPartner) {
    return ctaOptions.partner;
  }

  if (!hasProducts) {
    return ctaOptions.small;
  }

  if (isLargeCohortUser(maxUsersCount, maxAgentsCount, commercialLicensesLength)) {
    return ctaOptions.large;
  }

  if (isMediumCohortUser(maxUsersCount, maxAgentsCount)) {
    return ctaOptions.medium;
  }

  if (isSmallCohortUser(maxUsersCount, maxAgentsCount)) {
    return ctaOptions.small;
  }

  if (isStarterCohortUser(maxUsersCount, maxAgentsCount)) {
    return ctaOptions.starter;
  }

  return null;
};

export const getTwoColumnCTAOption = ({
  isPartner,
  maxUsersCount,
  maxAgentsCount,
  commercialLicensesLength,
  hasProducts,
}): TwoColumnCTAOption | null => {
  if (isPartner) {
    return twoColumnCTAOptions.partner;
  }

  if (!hasProducts) {
    return twoColumnCTAOptions.small;
  }

  if (isLargeCohortUser(maxUsersCount, maxAgentsCount, commercialLicensesLength)) {
    return twoColumnCTAOptions.large;
  }

  if (isMediumCohortUser(maxUsersCount, maxAgentsCount)) {
    return twoColumnCTAOptions.medium;
  }

  if (isSmallCohortUser(maxUsersCount, maxAgentsCount)) {
    return twoColumnCTAOptions.small;
  }

  if (isStarterCohortUser(maxUsersCount, maxAgentsCount)) {
    return twoColumnCTAOptions.starter;
  }

  return null;
};
