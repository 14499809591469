// @flow
/* eslint-disable max-classes-per-file */
import Progress from 'redux-progress';

type Field = 'cardNumber' | 'securityCode' | 'expiry';

export class TnsValidationError {
  fields: { [Field]: Progress<void> };
  constructor(fields: { [Field]: Progress<void> }) {
    this.fields = fields;
  }
}

export class TnsResponseError {
  response: any;
  constructor(response: any) {
    this.response = response;
  }
}
/* eslint-enable max-classes-per-file */
