/* eslint-disable react/no-unused-prop-types */
// @flow
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import PaymentMethodButton from 'modules/checkout/payment/components/PaymentMethodButton';
import type { CashProps } from 'modules/checkout/payment/duck/model';

import { CheckoutModes } from 'modules/checkout/payment/duck/model';

import { CartAnalyticsContext } from 'modules/analytics';
import { analyticsEvents, AnalyticsTrigger } from 'modules/siteconfigurator/analytics';

import {
  MULTIPLE_CASH_OPTIONS_BUTTON_TEST_ID,
  BANK_TRANSFER_OR_CHEQUE_BUTTON_TEST_ID,
  PAY_ON_ACCOUNT_BUTTON_TEST_ID,
  PAY_ON_TERM_BUTTON_TEST_ID,
} from './TabButton.testids';

type Props = {
  cash: CashProps,
  selectMode: (PaymentMethod) => void,
  currency: string,
  inSiteConfiguratorMode: boolean,
};

const getCashTabContentProps = (props: Props) => {
  const { cash } = props;

  if (!cash.isAvailable) {
    return null;
  }

  if (cash.multipleOptionsAvailable) {
    return {
      testId: MULTIPLE_CASH_OPTIONS_BUTTON_TEST_ID,
      // Same as bank transfer for now
      title: <FormattedMessage id="payment.bank-transfer-or-check.button.title" values={props} />,
      description: (
        <FormattedMessage id="payment.bank-transfer-or-check.button.description" values={props} />
      ),
      selectedCheckoutMode: cash.defaultCheckoutMode,
    };
  }

  // If we get here, we can fast track the user to a specific checkout mode
  if (cash.canQuote) {
    return {
      testId: BANK_TRANSFER_OR_CHEQUE_BUTTON_TEST_ID,
      title: <FormattedMessage id="payment.bank-transfer-or-check.button.title" values={props} />,
      description: (
        <FormattedMessage id="payment.bank-transfer-or-check.button.description" values={props} />
      ),
      selectedCheckoutMode: CheckoutModes.BANK_TRANSFER,
    };
  }

  if (cash.canPayOnAccount) {
    return {
      testId: PAY_ON_ACCOUNT_BUTTON_TEST_ID,
      title: <FormattedMessage id="payment.pay-on-account.button.title" values={props} />,
      description: (
        <FormattedMessage id="payment.pay-on-account.button.description" values={props} />
      ),
      selectedCheckoutMode: CheckoutModes.PAY_ON_ACCOUNT,
    };
  }

  if (cash.canPayOnTerm) {
    return {
      testId: PAY_ON_TERM_BUTTON_TEST_ID,
      title: <FormattedMessage id="payment.pay-on-terms.button.title" values={props} />,
      description: <FormattedMessage id="payment.pay-on-terms.button.description" values={props} />,
      selectedCheckoutMode: CheckoutModes.PAY_ON_TERMS,
    };
  }

  return null;
};

const TabButton = (props: Props) => {
  const analytics = useContext(CartAnalyticsContext);
  if (!props.cash.isAvailable) {
    return null;
  }

  const tabContentProps = getCashTabContentProps(props);

  if (tabContentProps === null) {
    return null;
  }

  const { testId, title, description, selectedCheckoutMode } = tabContentProps;

  const onSelect = () => {
    if (props.inSiteConfiguratorMode) {
      analyticsEvents[AnalyticsTrigger.BankTransferCheck](analytics);
    }
    props.selectMode(selectedCheckoutMode);
  };

  return (
    <PaymentMethodButton
      testId={testId}
      title={title}
      isSelected={props.cash.isSelected}
      select={onSelect}
    >
      {description}
    </PaymentMethodButton>
  );
};

export default TabButton;
