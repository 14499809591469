import React from 'react';
import Progress from 'redux-progress';
import { connect } from 'react-redux';
import { CartAnalyticsWrapper } from 'modules/analytics';
import SoftwareProductConfiguration from 'modules/configure/SoftwareProductConfiguration';
import SupportProductConfiguration from 'modules/configure/SupportProductConfiguration';
import { selectConfigurationType, selectMainProductKey } from 'modules/configure/duck/selectors';
import { model } from 'modules/configure';
import PageLoading from 'components/common/PageLoading';
import { State } from 'model/State';
import selectCurrency from 'modules/cart/duck/selectors/currency';
import IntercomConfiguration from './IntercomConfiguration';

type Props = {
  currency: Progress<string>;
  type: string;
  productKey: string;
};

const ProductConfigurationPage = (props: Props) => (
  <>
    <IntercomConfiguration productKey={props.productKey} />
    <CartAnalyticsWrapper>
      <PageLoading progress={Progress.all(props.currency)}>
        {() => {
          if (!props.productKey) {
            return null;
          }
          switch (props.type) {
            case model.SUPPORT:
              return <SupportProductConfiguration productKey={props.productKey} />;
            case model.SOFTWARE:
              return <SoftwareProductConfiguration productKey={props.productKey} />;
            default:
              return null;
          }
        }}
      </PageLoading>
    </CartAnalyticsWrapper>
  </>
);

const mapStateToProps = (state: State) => ({
  currency: selectCurrency(state),
  type: selectConfigurationType(state),
  productKey: selectMainProductKey(state),
});

export default connect(mapStateToProps)(ProductConfigurationPage);
