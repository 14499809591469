import { combineEpics } from 'redux-observable';
import checkoutContacts from './checkoutContacts';
import pages from './pages';
import requiredToLogin from './requiredToLogin';
import viewedCheckoutComplete from './viewedCheckoutComplete';
import viewedProductList from './viewedProductList';
import changeCurrency from './changeCurrency';
import fetchContactDetails from './fetchContactDetails';
import uiEvents from './uiEvents';

export default combineEpics(
  checkoutContacts,
  pages,
  requiredToLogin,
  viewedCheckoutComplete,
  viewedProductList,
  changeCurrency,
  fetchContactDetails,
  uiEvents,
);
