import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import SectionMessage from '@atlaskit/section-message';

import config from 'appconfig';
import { Props } from 'modules/addon/VisualAddonPage';
import t from 'modules/i18n/intl';
import { Dimensions } from 'components/visuals';

const Wrapper = styled.div`
  padding: ${Dimensions.units(2)}px;
`;

const ContactUsLink = styled.a`
  text-transform: lowercase;
`;

const ContactUs = () => (
  <ContactUsLink target="_blank" href={`${config.wacUrl}/company/contact`}>
    {t('common.contact-us')}
  </ContactUsLink>
);

const ParentDisabled = (props: Props) => (
  <Wrapper>
    <SectionMessage appearance="information">
      <FormattedMessage
        id="addon.choose.not-available"
        values={{
          addon: props.data.pricing[0].productDescription,
          contactUs: <ContactUs />,
        }}
      />
    </SectionMessage>
  </Wrapper>
);

export default ParentDisabled;
