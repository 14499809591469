import React from 'react';
import { Props } from 'modules/addon/VisualAddonPage';
import { AcademicToggleVisual } from 'modules/configure/common/AcademicPricingSwitch';

const AcademicToggle = ({ data, events }: Props) =>
  data.isAcademicAvailable ? (
    <AcademicToggleVisual value={data.isAcademicOn} onToggle={events.onAcademicToggle} />
  ) : null;

export default AcademicToggle;
