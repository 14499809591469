import { cartId, editCartId } from 'modules/cart/duck/api/ids';
import { fetchOrCreateCart } from 'modules/cart/duck/actions/fetchOrCreate';
import sanitiseUUID from 'util/uuid';
import { CartThunk } from 'model/State';

export default (): CartThunk => async (dispatch, getState) => {
  const state = getState();
  const queryParamsUuid = (state.location.query && state.location.query.uuid) || null;
  let uuid = queryParamsUuid && sanitiseUUID(queryParamsUuid);
  if (!uuid) {
    uuid = editCartId.load();
  }
  if (!uuid) {
    uuid = cartId.load();
  }
  const cartProgress = await dispatch(fetchOrCreateCart(uuid));
  cartProgress.ifSuccess((cart) => {
    if (cart && cart.uuid === queryParamsUuid && cart.inEditMode) {
      editCartId.store(cart.uuid);
    }
  });

  return cartProgress;
};
