enum TaaSErrorCodes {
  LINE1_LENGTH_BREACH = 'LINE1_LENGTH_BREACH',
  ERR_TAAS_ADDRESSES_INVALID_STREET_NAME_001 = 'ERR_TAAS_ADDRESSES_INVALID_STREET_NAME_001',
  ERR_TAAS_ADDRESSES_INVALID_STREET_NUMBER_002 = 'ERR_TAAS_ADDRESSES_INVALID_STREET_NUMBER_002',
  LINE2_LENGTH_BREACH = 'LINE2_LENGTH_BREACH',
  CITY_LENGTH_BREACH = 'CITY_LENGTH_BREACH',
  ERR_TAAS_ADDRESSES_CITY_NOT_DETERMINED_003 = 'ERR_TAAS_ADDRESSES_CITY_NOT_DETERMINED_003',
  REGION_LENGTH_BREACH = 'REGION_LENGTH_BREACH',
  ERR_TAAS_ADDRESSES_REGION_NOT_SUPPORTED_005 = 'ERR_TAAS_ADDRESSES_REGION_NOT_SUPPORTED_005',
  POSTAL_CODE_LENGTH_BREACH = 'POSTAL_CODE_LENGTH_BREACH',
}

/**
 * returns the i18n error keys for the corresponding error messages for the errors
 * @param errorCode the TaaS returned error codes, like LINE1_LENGTH_BREACH
 * @returns i18n keys for the error messages
 */
export const getTaaSErrorMessage = (errorCode: string): string => {
  const errorMessages = {
    [TaaSErrorCodes.LINE1_LENGTH_BREACH]: 'billing.address.validation.addressLine1.characterLimit',
    [TaaSErrorCodes.ERR_TAAS_ADDRESSES_INVALID_STREET_NAME_001]:
      'billing.address.validation.addressLine1.streetName',
    [TaaSErrorCodes.ERR_TAAS_ADDRESSES_INVALID_STREET_NUMBER_002]:
      'billing.address.validation.addressLine1.streetNumber',
    [TaaSErrorCodes.LINE2_LENGTH_BREACH]: 'billing.address.validation.addressLine2.characterLimit',
    [TaaSErrorCodes.CITY_LENGTH_BREACH]: 'billing.address.validation.city.characterLimit',
    [TaaSErrorCodes.ERR_TAAS_ADDRESSES_CITY_NOT_DETERMINED_003]:
      'billing.address.validation.city.cityName',
    [TaaSErrorCodes.REGION_LENGTH_BREACH]: 'billing.address.validation.state.characterLimit',
    [TaaSErrorCodes.ERR_TAAS_ADDRESSES_REGION_NOT_SUPPORTED_005]:
      'billing.address.validation.state.stateName',
    [TaaSErrorCodes.POSTAL_CODE_LENGTH_BREACH]:
      'billing.address.validation.postcode.characterLimit',
  };

  return errorMessages[errorCode] || 'billing.address.validation.unknown';
};
