// @flow
import React from 'react';
import TierStep from './tier/TierStep';
import { StepList } from './common/Steps';
import DeploymentStep from './deployment/DeploymentStep';
import AddonStep from './addons/AddonStep';
import AcademicToggle from './common/AcademicPricingSwitch';
import TermsStep from './terms/TermsStep';
import PriceLine from './common/PriceLine';
import Header from './common/Header';

const ServerProductConfiguration = () => (
  <StepList>
    <Header showTagline />
    <AcademicToggle />
    <DeploymentStep alwaysShowCloud />
    <TierStep />
    <AddonStep />
    <TermsStep />
    <PriceLine />
  </StepList>
);

export default ServerProductConfiguration;
