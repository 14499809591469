// @flow
import React from 'react';
import { connect } from 'react-redux';
import Progress from 'redux-progress';
import styled from 'styled-components';

import { Font } from 'components/visuals';
import PageLoading from 'components/common/PageLoading';
import type { State } from 'model/State';
import t from 'modules/i18n/intl';

type Props = {
  progress: Progress,
};

const Wrapper = styled.div`
  font-family: ${Font.family};
  font-size: 16px;
`;

const SoftwarePage = ({ progress }: Props) => (
  <PageLoading progress={progress}>
    {() => <Wrapper>{t('software.creating-your-cart')}</Wrapper>}
  </PageLoading>
);

const mapStateToProps = (state: State) => ({
  progress: Progress.all(state.cart.payload, ...Object.values(state.catalog.pricing)),
});

export default connect(mapStateToProps)(SoftwarePage);
