// @flow
import mapFromHams from 'modules/checkout/contacts/duck/actions/mapFromHams';
import type { Dispatch, GetState } from 'model/State';
import watchProgress from 'model/watchProgress';
import selectCartPayload from 'modules/cart/duck/selectors/cart';
import { getBillingContacts } from 'modules/checkout/contacts/duck/selectors/getBillingContacts';
import { selectInSiteConfiguratorMode } from 'modules/siteconfigurator/duck/selectors';

const mergeContactDetails = () => async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const inStateContacts = getBillingContacts(state);
  const cart = await watchProgress(selectCartPayload);
  const currentUserContact = await watchProgress((s) => s.auth.currentContact);
  const mergedCart = { ...cart, ...inStateContacts };
  const inSiteConfiguratorMode = selectInSiteConfiguratorMode(state);
  let currentContact = {};
  if (inStateContacts && currentUserContact) {
    currentContact.contactDetails = {
      ...currentUserContact.contactDetails,
      ...inStateContacts.purchaserContactDetails,
    };
    currentContact.organisationDetails = {
      ...currentUserContact.organisationDetails,
      ...inStateContacts.billingOrganisationDetails,
    };
  } else if (inStateContacts) {
    currentContact.contactDetails = inStateContacts.purchaserContactDetails;
    currentContact.organisationDetails = inStateContacts.billingOrganisationDetails;
  } else if (currentUserContact) {
    currentContact.contactDetails = currentUserContact.contactDetails;
    currentContact.organisationDetails = currentUserContact.organisationDetails;
  } else {
    currentContact = null;
  }
  return mapFromHams(mergedCart, currentContact, inSiteConfiguratorMode);
};

export default mergeContactDetails;
