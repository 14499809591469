import { selectXsrfToken } from 'modules/auth/selectors';
import watchProgress from 'model/watchProgress';
import * as auth from 'modules/auth';
import { fetchOrdersCountByEmail, fetchQuotesPost, fetchSearchForContactPost } from './actions';

export default async (dispatch) => {
  const xsrfToken = await watchProgress(selectXsrfToken);

  if (!xsrfToken) {
    auth.actions.goToLogin();
    return;
  }

  dispatch(fetchOrdersCountByEmail());

  // TODO: update pagination arguments, after creating a pagination for tables, like fetchPaginatedAccounts
  dispatch(fetchQuotesPost(10, 10));
  dispatch(fetchSearchForContactPost(10, 10));
};
