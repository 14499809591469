import { ActionLink } from 'util/ActionLink';
import React from 'react';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { Intl } from 'modules/i18n/intl';

type Props = {
  reason: string;
  link: ActionLink;
};

export default (props: Props) => (
  <Intl>
    {(t) => (
      <SectionMessage
        appearance="error"
        title={t(`promotion.fail.reason.${props.reason}.title`)}
        actions={[
          {
            text: t('promotion.fail.link'),
            href: props.link.href,
            key: props.reason,
            // @ts-ignore CIW5-18, version bump should fix this issue
            onClick: (e) => {
              e.preventDefault();
              props.link.navigate();
            },
          },
        ].map(({ text, ...restAction }) => (
          <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
        ))}
      >
        {t(`promotion.fail.reason.${props.reason}.summary`)}
      </SectionMessage>
    )}
  </Intl>
);
