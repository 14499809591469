/* eslint-disable import/prefer-default-export */

import Progress from 'redux-progress';
import { Selector } from 'model/State';
import { HamsCart$Contacts } from 'modules/cart/duck/model';

export const getSelectedBillingOrganisationCurrency: Selector<Progress<string>> = (state) => {
  const result = state.checkout.contacts.data
    .flatMap(
      (data) =>
        state.data.countries.details[data.billingOrganisationDetails.isoCountryCode] ||
        Progress.none,
    )
    .map((r) => r.primaryCurrency);
  return result;
};

export const getSelectedTechnicalOrganisationCurrency: Selector<Progress<string>> = (state) => {
  const result = state.checkout.contacts.data
    .flatMap(
      (data) =>
        state.data.countries.details[data.technicalOrganisationDetails.isoCountryCode] ||
        Progress.none,
    )
    .map((r) => r.primaryCurrency);
  return result;
};

export const getSelectedBillingCountry: Selector<Progress<string>> = (state) =>
  state.checkout.contacts.data.map((p) => p.billingOrganisationDetails.isoCountryCode);

export const getBillingContacts: Selector<HamsCart$Contacts> = (state) =>
  state.checkout.contacts.data.ifSuccess((c) => c);

export const isResellerOrder: Selector<Progress<boolean>> = (state) =>
  state.checkout.contacts.data.map((p) => p.resellerOrder);
