// @flow
import { thunkProgress } from 'redux-progress';
import compact from 'lodash/compact';

import * as api from 'modules/renew/duck/server/api';
import { fetchPaginatedActiveAccounts, OverviewParams } from 'modules/accounts/api';
import type { Dispatch, GetState } from 'model/State';
import { selectHamsClientConfig } from 'modules/auth/selectors';
import * as change from 'modules/change';
import * as actions from 'modules/change/search/duck/actions';

export const fetchPaginatedAccounts = (props: OverviewParams) => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const state = getState();

  if (state.change.table.paginated[`${props?.offset}`]) {
    return dispatch(
      thunkProgress(
        change.table.actions.LOAD_PAGINATED_CHANGE_LIST,
        state.change.table.paginated[`${props?.offset}`],
      ),
    );
  }

  const productKeys = (props && props.productKeys) || [];

  return dispatch(
    thunkProgress(
      change.table.actions.LOAD_PAGINATED_CHANGE_LIST,
      fetchPaginatedActiveAccounts(selectHamsClientConfig(state), { ...props, productKeys }),
    ),
  );
};

export const fetchAccountChangeOptions = (accountId: string, email: string | null) => (
  dispatch: Dispatch,
  getState: GetState,
) =>
  dispatch(
    thunkProgress(
      change.table.actions.FETCH_ACCOUNT_CHANGE_OPTIONS,
      api
        .fetchRenewOptionsForAccount(accountId, email, selectHamsClientConfig(getState()))
        .then((account) => ({ accounts: compact([account]) })),
      { payload: { accountId } },
    ),
  );

export const fetchAccount = (accountId: string, email: string | null) => (
  dispatch: Dispatch,
  getState: GetState,
) =>
  dispatch(
    thunkProgress(
      change.table.actions.LOAD_CHANGE_LIST,
      api
        .fetchRenewOptionsForAccount(accountId, email, selectHamsClientConfig(getState()))
        .then((account) => ({ accounts: compact([account]) })),
    ),
  );

export const searchAccount = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  return dispatch(
    thunkProgress(
      change.search.actions.SUBMIT_FORM,
      api.fetchRenewOptionsForAccount(
        (state.change.search.sen || '').replace(/SEN-/i, ''),
        state.change.search.email || '',
        selectHamsClientConfig(state),
      ),
    ),
  ).then((accountProgress) =>
    dispatch(
      thunkProgress(actions.SCROLL_FORM, dispatch(actions.scrollForm(accountProgress, false))),
    ),
  );
};

export const resetAccount = () => (dispatch: Dispatch) => {
  dispatch(change.search.actions.resetForm());
};
