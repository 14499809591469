/* eslint-disable consistent-return */
import {
  TYPE_TELEMETRY_COUNTER,
  TYPE_TELEMETRY_TIMER,
  TYPE_TELEMETRY_COUNTER_FAILURE,
} from './util';
import { catalog } from '@atlassiansox/metal-client';
import { reportMetalFailureToSentry } from '../../util/errorReporter';

const createMetalMiddleware = (metalClient) => (store) => (next) => (action) => {
  const currentPage =
    (store.getState().location && store.getState().location.pathname) || 'cart-page-unknown';
  if (action.type === TYPE_TELEMETRY_COUNTER) {
    metalClient.metric.submit({
      name: catalog.userInteraction.TASK_SUCCESS,
      value: 1,
      page: currentPage,
      task: action.aspect,
    });
  } else if (action.type === TYPE_TELEMETRY_COUNTER_FAILURE) {
    metalClient.metric.submit({
      name: catalog.userInteraction.TASK_FAILURE,
      value: 1,
      page: currentPage,
      task: action.aspect,
    });

    /* This is a temporary measure for errors to be logged to Sentry in linkage with Metal Failures,
    in order to be able to better investigate breaching SLI/SLOs */
    reportMetalFailureToSentry({
      currentPage,
      task: action.aspect,
      error: action.error,
    });
  } else if (action.type === TYPE_TELEMETRY_TIMER) {
    metalClient.metric.submit({
      name: catalog.userInteraction.TASK_TIME_TO_COMPLETE,
      page: currentPage,
      task: action.aspect,
      /**
       * This is only required if histograms if success/failure thresholds are being used at /**
       * @see https://bitbucket.org/atlassian/pur-monitoring/src/cc5758d236dd9085f8a3dae1ed8ab5b3269f0f1e/terraform/tome/slos.tf
       *
       * Also, has to be spread because the metal client doesn't accept undefined
       */
      ...(action.histogramBuckets === undefined
        ? {}
        : {
            histogramBuckets: action.histogramBuckets,
          }),
      value: action.time,
    });
  } else {
    return next(action);
  }
};

export default createMetalMiddleware;
