import t from 'modules/i18n/intl';
import { ProductFeature } from './Product';

const longTermOption: ProductFeature = {
  weight: 0,
  description: t('product.dc.benefits.long-term-option'),
};

const migratingFaster: ProductFeature = {
  weight: 5,
  description: t('product.dc.benefits.migrating-faster'),
};

export const dataCenterBenefits: ProductFeature[] = [longTermOption, migratingFaster];
