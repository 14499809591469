import React from 'react';
import SoftwareProduct from 'model/SoftwareProduct';
import { CloudProductKey, ProductKey } from 'data/products/keys';
import ServerProduct from 'model/editions/ServerProduct';
import { FormattedMessage } from 'react-intl';

export default class CloudProduct extends SoftwareProduct {
  constructor(
    key: CloudProductKey,
    serverEdition: ServerProduct,
    parentKeys: ProductKey[] = [],
    externalUrl: string | null = null,
    disabled?: boolean,
  ) {
    super({
      key,
      groupKey: serverEdition.groupKey,
      tagLine: serverEdition.tagLine,
      description: serverEdition.description,
      addon: serverEdition.addon,
      name: serverEdition.name,
      externalUrl,
      parentKeys,
      disabled,
    });
    this.name =
      key === 'jira-core.ondemand' ? (
        <FormattedMessage id="products.jira-work-management.name" />
      ) : (
        serverEdition.name
      );
  }
}
