// @flow
import errorReporter from 'util/errorReporter';
import { redirect } from 'redux-first-router';
import castArray from 'lodash/castArray';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';

import { selectMainProductPricing } from 'modules/configure/duck/selectors';
import { addAddons, goProductConfig, setAddons } from 'modules/configure/duck/actions';
import type { Dispatch, GetState } from 'model/State';
import { LegacyKeysDictionary, mapProductKeyToLegacyProductKey } from 'data/products/keys';
import { getProduct } from 'data/products';
import { goToProductList } from 'actions';
import { fetchPricing } from 'modules/catalog/pricing';
import { fetchParents } from 'modules/catalog/parents';
import watchProgress from 'model/watchProgress';

const extractSelectedAddons = (state) => {
  let urlAddons = [];
  if (state.location.query.addons) {
    urlAddons = flatMap(castArray(state.location.query.addons), (addonKeys) =>
      addonKeys.split(','),
    );
  }
  return urlAddons.map(mapProductKeyToLegacyProductKey);
};

const load = async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const { productKey } = state.location.payload;
  const selectedAddons = uniq(extractSelectedAddons(state));

  if (productKey in LegacyKeysDictionary) {
    dispatch(redirect(goProductConfig(LegacyKeysDictionary[productKey], selectedAddons)));
    return;
  }

  const product = getProduct(productKey);
  if (!product) {
    dispatch(redirect(goToProductList()));
    return;
  }

  const addons = [...selectedAddons];
  product
    .getRecommendations()
    .map(getProduct)
    .filter((p) => p !== null)
    .map((p: any) => p.key)
    .filter((recommendation) => !addons.includes(recommendation))
    .forEach((recommendation) => addons.push(recommendation));

  if (addons.length > 0) {
    dispatch(addAddons(addons, selectedAddons));
  }
  if (selectedAddons.length > 0) {
    dispatch(setAddons(selectedAddons));
  }

  await Promise.all([
    dispatch(fetchPricing(product.key, ...addons)),
    dispatch(fetchParents(...addons)),
  ]);

  if (!(await watchProgress(selectMainProductPricing))) {
    errorReporter(`Product pricing not found: ${product.key}`);
    dispatch(redirect(goToProductList()));
  }
};

export default load;
