import SoftwareProduct, { Props as SoftwareProductProps } from 'model/SoftwareProduct';
import { ServerProductKey } from 'data/products/keys';
import { perpetual, singleServer } from 'model/features';

export type Props = SoftwareProductProps & {
  key: ServerProductKey;
};

export default class ServerProduct extends SoftwareProduct {
  constructor(props: Props) {
    super({
      ...props,
      // Flow madness
      description: props.description,
      groupKey: props.groupKey,
      name: props.name,
      tagLine: props.tagLine,
      // End of Flow madness
      features: [...(props.features || []), singleServer, perpetual],
    });
  }
}
