import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';

import { Dimensions } from 'components/visuals';

import { DeploymentAndBillingCycleContext } from 'modules/migrate/comparator/Switchers';
import { DEPLOY_OPTION, PRICING_PLANS } from 'modules/migrate/comparator/utils';

const InputWrapper = styled.div`
  display: inline-block;
  max-width: ${Dimensions.units(14)}px;
  margin-right: ${Dimensions.units(1)}px;
`;

const UnitLabel = styled.span`
  display: inline-block;
  vertical-align: top;
  line-height: 22px;
  margin-top: ${Dimensions.units(1)}px;
`;

interface Props {
  freeTierLimit: number;
  numberOfUsers: number;
  isEnterprise: boolean;
  recommendedPlan: string;
  setNumberOfUsers: (arg0: number) => void;
  unitLabel: string;
  maxUsersTier: number;
}

const NumberOfUsers = ({
  freeTierLimit,
  numberOfUsers,
  recommendedPlan,
  isEnterprise,
  setNumberOfUsers,
  unitLabel,
  maxUsersTier,
}: Props) => {
  const { deployOption } = useContext(DeploymentAndBillingCycleContext);
  const isUserLabel: boolean = useMemo(() => unitLabel === 'USER', [unitLabel]);
  const maxUnitCount: number =
    recommendedPlan === PRICING_PLANS.FREE ? freeTierLimit : maxUsersTier;

  const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    let targetValue = Number.parseInt(e.currentTarget.value, 10);
    if (Number.isNaN(targetValue)) {
      targetValue = 0;
    }
    const value: number = targetValue > maxUnitCount ? maxUnitCount : targetValue;
    setNumberOfUsers(Math.min(maxUsersTier, value));
  };

  return deployOption === DEPLOY_OPTION.CLOUD ? (
    <>
      <InputWrapper>
        <Textfield
          min={1}
          onChange={onInputChange}
          name="users"
          type="number"
          value={numberOfUsers.toString()}
        />
      </InputWrapper>
      {!isUserLabel && (
        <UnitLabel>
          <FormattedMessage id="migrate.comparator.table.input.unit-label.agents" />
        </UnitLabel>
      )}
    </>
  ) : (
    <UnitLabel>
      <FormattedMessage
        id={`migrate.comparator.table.unit-label.${isUserLabel ? 'users' : 'agents'}`}
        values={{
          unitCount: isEnterprise ? (
            <FormattedMessage id="migrate.table.row.unlimited" />
          ) : (
            numberOfUsers
          ),
        }}
      />
    </UnitLabel>
  );
};

export default NumberOfUsers;
