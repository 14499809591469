import get from 'lodash/get';
import { createSelector } from 'reselect';
import { PCCountryValidation, TCCountryValidation } from 'modules/checkout/contacts/duck/model';
import { State, Selector } from 'model/State';

export const hasErrorCountryPurchaserMismatch = (data: PCCountryValidation) =>
  get(data, 'irreconcilable', []).length > 0;

export const contactValidationSelector = (s: State) =>
  s.checkout.contacts.validateCountryContactMatch;

export const isCountryPurchaserContactMismatch: Selector<boolean> = createSelector(
  contactValidationSelector,
  ({ purchaserContactValidation: { result } }) => hasErrorCountryPurchaserMismatch(result as any),
);

export const isCountryTechnicalContactMismatch: Selector<TCCountryValidation> = createSelector(
  contactValidationSelector,
  (validation) => validation.technicalContactValidation.result || null,
) as any;
