/* eslint-disable consistent-return */
// @flow
import * as dates from 'util/dates';
import { createSelector } from 'reselect';
import Progress from 'redux-progress';
import { TnsValidationError } from 'modules/checkout/payment/duck/model';
import { FormValidity } from 'modules/form/duck/model';
import type { Selector } from 'model/State';

const getMonths = (mm, yy) => yy * 12 + mm;

const isValidExpiry = (monthStr, yearStr) => {
  const month = +(monthStr || 1);
  if (month > 0 && month <= 12) {
    if (!yearStr) {
      return true;
    }

    const year = +yearStr;
    const today = dates.now();
    const todayMonth = today.getMonth() + 1;
    const todayYear = today.getFullYear() - 2000;
    return year < 100 && getMonths(month, year) >= getMonths(todayMonth, todayYear);
  }

  return false;
};

export const validateExpiry: Selector<Progress<void>> = createSelector(
  (state) => state.checkout.payment.creditCard.expiryMonth,
  (state) => state.checkout.payment.creditCard.expiryYear,
  (expiryMonth, expiryYear) =>
    isValidExpiry(expiryMonth, expiryYear) ? Progress.success() : Progress.fail(),
);

const validateCardName: Selector<Progress<void>> = (state) =>
  !state.checkout.payment.creditCard.cardName || // Empty = valid
  state.checkout.payment.creditCard.cardName.trim() // Empty-spaces = not valid
    ? Progress.success()
    : Progress.fail();

const validateTns: Selector<Progress<void>> = createSelector(
  (state) => state.checkout.payment.submission,
  (submission) =>
    submission.fold({
      failed: (error) => {
        if (error instanceof TnsValidationError) {
          return error.fields;
        }
      },
    }),
);

const validateCreditCard: Selector<FormValidity> = createSelector(
  validateExpiry,
  validateCardName,
  validateTns,
  (expiry, cardName, tns) =>
    new FormValidity({
      expiry,
      cardName,
      ...tns,
    }),
);

export default validateCreditCard;
