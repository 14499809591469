import React, { useContext, useEffect, useMemo, useState } from 'react';
import Crypto from 'crypto-js';
import { AuthenticationContext } from 'modules/migrate/Authenticated';
import CartAnalyticsClient, { AnalyticsClient } from 'modules/analytics/CartAnalyticsClient';
import { UIAction, UIActionSubject, UISource } from 'modules/analytics/model/UIEvent';
import { fetchEmail } from './api';

interface Attributes {
  [key: string]: string | string[] | number | boolean;
}
interface Options {
  action: UIAction;
  actionSubject: UIActionSubject;
  actionSubjectId?: string;
  attributes?: Attributes;
}

export interface IAnalyticsContext {
  makeSendUIEvent: (options: Options) => () => void;
  sendUiEvent: (options: Options) => void;
  setAttribute: (key: string, value: string) => void;
  setAttributes: (attributes: Attributes) => void;
}

export const AnalyticsContext = React.createContext<IAnalyticsContext>({
  makeSendUIEvent: () => () => {},
  sendUiEvent: () => {},
  setAttribute: () => {},
  setAttributes: () => {},
});

export const AnalyticsWrapper = ({ children }) => {
  const authenticationContext = useContext(AuthenticationContext);
  const [hashedEmail, setHashedEmail] = useState('');
  const [storedAttributes, setStoredAttributes] = useState({});

  const client: AnalyticsClient = CartAnalyticsClient.getClient();

  const context = useMemo(() => {
    const sendUiEvent = ({ action, actionSubject, actionSubjectId, attributes }: Options) => {
      // since an impersonated user is an unreal customer/user so we are skipping all the
      // events which are to be sent, so as to not create unnecessary event logs
      if (authenticationContext.isImpersonation() && !attributes?.capture) {
        return null;
      }

      // just preventing random properties to be added to the payload
      const targetAttributes = attributes;
      delete targetAttributes?.capture;

      return client.sendUIEvent({
        action,
        actionSubject,
        actionSubjectId,
        source: UISource.Page,
        attributes: {
          schemaVersion: 1,
          eventType: 'ui',
          eventComponent: actionSubject === UIActionSubject.Button ? 'button' : 'linkButton',
          event: action,
          ...storedAttributes,
          ...targetAttributes,
          hashedEmail,
        },
      });
    };

    const setAttribute = (key, value) => setStoredAttributes({ ...storedAttributes, [key]: value });

    const setAttributes = (attributes) =>
      setStoredAttributes({ ...storedAttributes, ...attributes });

    return {
      makeSendUIEvent: (options) => () => {
        sendUiEvent(options);
      },
      sendUiEvent,
      setAttribute,
      setAttributes,
    };
  }, [hashedEmail, storedAttributes]);

  useEffect(() => {
    (async () => {
      const email = await fetchEmail({ authenticationContext });
      setHashedEmail(Crypto.SHA256(email).toString());
    })();
  }, [authenticationContext]);

  return <AnalyticsContext.Provider value={context}>{children}</AnalyticsContext.Provider>;
};
