import Progress, { thunkProgress } from 'redux-progress';
import hamsAdd from 'modules/cart/duck/api/add';
import { cartProperties } from 'modules/cart/duck/api/storage';
import { AddProductsBody, HamsCart } from 'modules/cart/duck/model';
import selectCartPayload from 'modules/cart/duck/selectors/cart';
import watchProgress from 'model/watchProgress';

export const ADD_PRODUCT_TO_CART = 'cart/items/ADD';

const updateCartProperties = (cart: HamsCart) => {
  const storage = cartProperties(cart.uuid);
  const props = storage.load();

  if (props && props.productToAssociateWithServerId) {
    const matchingItem = cart.items.find(
      (item) => item.productDetails.productKey === props.productToAssociateWithServerId,
    );

    if (matchingItem) {
      props.cartItemIdToAssociateWithServerId = matchingItem.id;
      props.productToAssociateWithServerId = null;
      storage.store(props);
    }
  }

  return cart;
};

export const add = (body: AddProductsBody) => async (dispatch) => {
  const cart: HamsCart = await watchProgress(selectCartPayload);
  return dispatch(
    thunkProgress(ADD_PRODUCT_TO_CART, hamsAdd(cart.uuid, body).then(updateCartProperties)),
  ) as Progress<HamsCart>;
};
