import React from 'react';
import styled from 'styled-components';

export type FooterRow = {
  id: string;
  display: React.ReactNode;
};

type Props = {
  footerRows: ReadonlyArray<FooterRow>;
};

const StyledFooter = styled.tfoot`
  border-bottom: 2px solid #1b2b4b;
`;

const TableFooter = ({ footerRows }: Props) => (
  <StyledFooter>
    {footerRows.map((row) => (
      <tr key={`${row.id}-footer-row`} id={row.id}>
        {row.display}
      </tr>
    ))}
  </StyledFooter>
);

export default TableFooter;
