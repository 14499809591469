import { progressResult } from 'util/rxOperators';
import { distinctUntilChanged, map, pairwise, filter } from 'rxjs/operators';
import * as selectors from 'modules/tracking/selectors';
import selectCurrency from 'modules/cart/duck/selectors/currency';
import { CartEpic } from 'model/State';
import { createTrackEvent, utils } from 'modules/analytics';
import {
  TrackAction,
  TrackActionSubject,
  TrackSource,
  TrackEventNames,
} from 'modules/analytics/model/TrackEvent';

export const changeCurrency: CartEpic = (action$, state$) =>
  state$.pipe(
    progressResult(selectCurrency),
    distinctUntilChanged(),
    filter(Boolean),
    pairwise(),
    map(([cartPrevCurrency, cartCurrency]) =>
      createTrackEvent(
        utils.getTrackEventData(
          TrackAction.Changed,
          TrackActionSubject.Currency,
          TrackSource.ContactDetailsScreen,
          TrackEventNames.ChangeCurrency,
        ),
        selectors.props.cart,
        { cartPrevCurrency, cartCurrency },
      ),
    ),
  );

export default changeCurrency;
