import type { Action } from 'redux-first-router';
import { redirect, NOT_FOUND, pathToAction } from 'redux-first-router';
import { routesMap } from 'routes';
import { goToProductList } from 'actions';
import { init as initActions } from 'actions/init';
import type { Dispatch, GetState } from 'model/State';
import { routeIncludeParams as goToMacLicenses } from 'modules/mac/duck/actions/landing/route';

const validateRoute = (dispatch: Dispatch, getState: GetState) => {
  const { location } = getState();
  if (location.type === NOT_FOUND) {
    dispatch(initActions());
    const sanitizedPathName = location.pathname.replace(/\/{2,}/g, '/');
    const recheckedAction = pathToAction(sanitizedPathName, routesMap) as Action;
    const goToAction = recheckedAction.meta?.notFoundPath?.startsWith('/my/')
      ? goToMacLicenses
      : goToProductList;
    dispatch(
      redirect(recheckedAction.type !== NOT_FOUND ? recheckedAction : (goToAction() as Action)),
    );
  }
};

export default validateRoute;
