// @flow
import type { Environment } from 'model/EnvironmentConfig';
import { BIZBOX, DEVELOPMENT, E2E, LOCAL, PRODUCTION, STAGING } from 'model/EnvironmentConfig';

export function getEnvironment(host: string = window.location.hostname): Environment {
  switch (host) {
    case 'cart.e2e.atlassian.com':
      return E2E;
    case 'localhost':
      return LOCAL;
    case 'cart.bizbox.atlassian.com':
      return BIZBOX;
    case 'qa-wac.internal.atlassian.com':
    case 'wac.stg.internal.atlassian.com':
      return STAGING;
    default:
      if (host.endsWith('.dev.atl-paas.net')) {
        return DEVELOPMENT;
      }
      return PRODUCTION;
  }
}

export default getEnvironment();
