/* eslint-disable max-len */
// @flow
import { thunkProgress } from 'redux-progress';
import { getTaxRate } from 'modules/data/taxRates/selectors';
import fetchCountryTaxFromHams from 'api/data/fetchCountryTaxRate';
import type { Dispatch, GetState } from 'model/State';

export const FETCH = 'data/taxRates/FETCH';
export const fetchTaxRate = (isoCountryCode: string, stateKey: string) => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const taxRate = getTaxRate(getState(), { isoCountryCode, stateKey });
  if (taxRate.isNone || taxRate.failed) {
    await dispatch(
      thunkProgress(FETCH, fetchCountryTaxFromHams(isoCountryCode, stateKey), {
        isoCountryCode,
        stateKey,
      }),
    );
  }
};
