import { combineEpics } from 'redux-observable';

import { route } from 'modules/mac/duck/actions/licenseEvaluation';

import { timeAction, counter } from './util';

export default combineEpics(
  counter(route.type, 'mac.license-evaluation'),
  timeAction(route.type, 'mac.license-evaluation'),
);
