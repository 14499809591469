import React, { useEffect, useState } from 'react';
import AkSelect from '@atlaskit/select';
import { Country } from 'modules/data/countries/model';

import { Select } from './styles';

type StateProvinceSelectProps = typeof AkSelect & {
  country?: Country;
  currentStateCode?: string;
};

type SelectOption = {
  name: string;
  code: string;
};

export const StateProvinceSelect: React.FC<StateProvinceSelectProps> = ({
  country,
  currentStateCode,
  field,
  isInvalid,
  ...props
}: StateProvinceSelectProps) => {
  const validationState = isInvalid ? 'error' : 'default';
  const selectedState =
    country?.states?.find((state: SelectOption) => state.code === currentStateCode) || '';

  const [stateValue, setStateValue] = useState<SelectOption | string>(selectedState);

  useEffect(() => {
    setStateValue(selectedState);
  }, [currentStateCode]);

  useEffect(() => {
    if (country?.states) {
      // setting Form field value
      field.edit(selectedState?.code);
      // setting value for Select component
      setStateValue(selectedState);
    }
  }, [country]);

  if (!field) return null;

  return (
    <Select
      {...props}
      classNamePrefix="state"
      hasAutocomplete
      options={country?.states || []}
      getOptionLabel={(s: SelectOption) => s.name}
      getOptionValue={(s: SelectOption) => s.code}
      value={stateValue}
      onChange={(s: SelectOption) => field.edit(s.code)}
      onBlur={() => field.onBlur()}
      validationState={validationState}
    />
  );
};
