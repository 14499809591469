import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import Button from '@atlaskit/button/standard-button';

import { colors } from '@atlaskit/theme';
import { Dimensions, Font } from 'components/visuals';

import { additionalCTALinks, TwoColumnCTAOption } from './twoColumnCTAOptions';

import { AnalyticsContext } from 'modules/migrate/analytics/AnalyticsContext';
import { comparatorCTAEvents } from 'modules/migrate/analytics/analyticsEvents';
import { generateAnalytics } from 'modules/migrate/analytics/utils';

const LeftSection = styled.div`
  width: 60%;
  border-right: 1px solid ${colors.N40};
  padding-right: ${Dimensions.units(4)}px;
`;

const RightSection = styled.div`
  width: 40%;
  padding-left: ${Dimensions.units(4)}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h2`
  font-family: ${Font.displayFamily};
  font-size: 36px;
  line-height: 44px;
  color: ${colors.N700};
`;

const Text = styled.p`
  margin: ${Dimensions.units(4)}px 0;
  font-size: 20px;
  line-height: 28px;
  color: ${colors.N500};
`;

const AdditionalText = styled(Text)`
  margin: ${Dimensions.units(2)}px 0;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: ${colors.N800};
`;

const AdditionalLink = styled.a`
  font-family: ${Font.family};
  font-size: 16px;
  line-height: 14px;
`;

const StyledButton = styled(Button)`
  margin: 0 ${Dimensions.units(0.5)}px;
`;

const TwoColumnCTA = ({ title, text, button, cohort }: TwoColumnCTAOption) => {
  const analytics = useContext(AnalyticsContext);
  const { onCTARenderedAnalytics, ...comparatorCTAAnalytics } = generateAnalytics(
    analytics,
    comparatorCTAEvents,
    { cohort },
  );
  useEffect(() => onCTARenderedAnalytics(), []);

  return (
    <>
      <LeftSection data-cy="left-section">
        <Title>{title}</Title>
        <Text>{text}</Text>
        <StyledButton
          href={button.href}
          appearance={button.appearance}
          onClick={() => comparatorCTAAnalytics[button.analyticsEventName]?.()}
          target="_blank"
          rel="noopener noreferrer"
        >
          {button.text}
        </StyledButton>
      </LeftSection>
      <RightSection data-cy="right-section">
        {additionalCTALinks.map((additionalCTALink) => (
          <React.Fragment key={additionalCTALink.link.analyticsEventName}>
            <AdditionalText>{additionalCTALink.text}</AdditionalText>
            <AdditionalLink
              href={additionalCTALink.link.href}
              onClick={() => comparatorCTAAnalytics[additionalCTALink.link.analyticsEventName]?.()}
              target="_blank"
              rel="noopener noreferrer"
            >
              {additionalCTALink.link.text}
            </AdditionalLink>
          </React.Fragment>
        ))}
      </RightSection>
    </>
  );
};

export default TwoColumnCTA;
