import { AxiosRequestConfig } from 'axios';

import { State } from 'model/State';
import hamsClient from 'modules/hams';
import { selectHamsClientConfig } from 'modules/auth/selectors';

import { baseOrdersContactApiUrl } from '../..';

export const postToHamsClient = async (
  remainingPath: string,
  state: State,
  params?: AxiosRequestConfig['params'],
  data?: unknown,
) => {
  try {
    const config = selectHamsClientConfig(state);

    return (
      await hamsClient.post(`${baseOrdersContactApiUrl}${remainingPath}`, data, {
        ...config,
        params,
      })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
};
