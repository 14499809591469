import code from './code';
import { codeQuality } from './codeQuality';
import enterprise from './enterprise';
import collaborate from './collaborate';
import trackAndSupport, { trackAndSupportWithoutJWM } from './trackAndSupport';
import apps from './apps';
import training from './training';
import securityAndIdentity, { securityAndIdentityWithGuard } from './securityAndIdentity';
import discovery from './discovery';

export default [
  trackAndSupport,
  collaborate,
  code,
  codeQuality,
  enterprise,
  apps,
  training,
  discovery,
];

export const getProductCategory = (isAccessRebrandingFFOn: boolean) => {
  const categories = [
    trackAndSupportWithoutJWM,
    collaborate,
    code,
    codeQuality,
    isAccessRebrandingFFOn ? securityAndIdentityWithGuard : securityAndIdentity,
    enterprise,
    apps,
    training,
    discovery,
  ];

  return categories;
};
