import Progress from 'redux-progress';
import { createSelector } from 'reselect';
import { State } from 'model/State';
import { EXPERT, RESELLER } from 'modules/auth/model/CurrentContact';
import { UserChannel } from 'modules/auth/model/UserChannel';

export const endUser = Object.freeze({
  organisationType: null,
  isExpert: false,
  isReseller: false,
  isPartner: false,
});

type Selector = (state: State) => Progress<UserChannel>;
export const getUserChannel: Selector = createSelector(
  (s: State) => s.auth.currentContact,
  (c) =>
    c.map((contact) => {
      if (contact) {
        const { organisationType } = contact.organisationDetails;
        const isExpert = organisationType === EXPERT;
        const isReseller = organisationType === RESELLER;
        const isPartner = isExpert || isReseller;
        return {
          organisationType,
          isExpert,
          isReseller,
          isPartner,
        };
      }
      return endUser;
    }),
);
