import type { Selector, State as S } from 'model/State';

export const selectArchivedAccountIds: Selector<number[] | null> = (state: S) =>
  state.mac.contactEmail.archivedAccountIds?.ifSuccess((r) => r);

export const selectArchivedLicenseIds: Selector<number[] | null> = (state: S) =>
  state.mac.contactEmail.archivedLicenseIds?.ifSuccess((r) => r);

export const selectHamsBilledByEmail: Selector<unknown> = (state: S) =>
  state.mac.contactEmail.hamsBilledByEmail?.ifSuccess((r) => r);

export const selectLicenseByEmail: Selector<unknown> = (state: S) =>
  state.mac.contactEmail.licenseByEmail?.ifSuccess((r) => r);
