import { combineReducers } from 'redux';
import payload from './payload';
import itemsBeingDeleted from './itemsBeingDeleted';
import itemDeletionQueue from './itemDeletionQueue';
import clearCart from './clearCart';

export default combineReducers({
  itemDeletionQueue,
  itemsBeingDeleted,
  clearCart,
  payload,
});
