/* eslint-disable import/prefer-default-export */
import Progress from 'redux-progress';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';

export type I18nState = {
  language: string;
  translations: Record<string, Progress<Record<string, string>>>;
};

export type LanguageDef = {
  /**
   * the global language code
   */
  code: string;
  /**
   * the country specific language code
   */
  locale: string;
  /**
   * the name of the language
   */
  name: string;
};

export const defaultLanguage: LanguageDef = {
  code: 'en',
  locale: 'en-US',
  name: 'English',
};

export const germanLanguage: LanguageDef = {
  code: 'de',
  locale: 'de-DE',
  name: 'Deutsch',
};

export const frenchLanguage: LanguageDef = {
  code: 'fr',
  locale: 'fr-FR',
  name: 'Français',
};

export const spanishLanguage: LanguageDef = {
  code: 'es',
  locale: 'es',
  name: 'Español',
};

export const russianLanguage: LanguageDef = {
  code: 'ru',
  locale: 'ru-RU',
  name: 'русский',
};

export const portugueseLanguage: LanguageDef = {
  code: 'pt',
  locale: 'pt-BR',
  name: 'Português',
};

export const italianLanguage: LanguageDef = {
  code: 'it',
  locale: 'it-IT',
  name: 'Italiano',
};

export const japaneseLanguage: LanguageDef = {
  code: 'ja',
  locale: 'ja-JP',
  name: '日本語',
};

export const koreanLanguage: LanguageDef = {
  code: 'ko',
  locale: 'ko-KR',
  name: '한국어',
};

export const dutchLanguage: LanguageDef = {
  code: 'nl',
  locale: 'nl-NL',
  name: 'Nederlands',
};

export const polishLanguage: LanguageDef = {
  code: 'pl',
  locale: 'pl-PL',
  name: 'Polski',
};

export const chineseLanguage: LanguageDef = {
  code: 'zh',
  locale: 'zh-CN',
  name: '中文',
};

export const supportedLanguages: Array<LanguageDef> = [
  defaultLanguage,
  germanLanguage,
  frenchLanguage,
  spanishLanguage,
  portugueseLanguage,
  russianLanguage,
  italianLanguage,
  japaneseLanguage,
  koreanLanguage,
  dutchLanguage,
  polishLanguage,
  chineseLanguage,
];

/**
 * before upgrading react-intl from v2 to v3 "addLocaleData" was required to use
 * in v3 it's replaced with native Intl API
 * https://formatjs.io/docs/react-intl/upgrade-guide-3x/#breaking-api-changes
 * "intl pluralrules" and "intl relativetimeformat" polyfills are used
 * to support some browsers
 * https://formatjs.io/docs/react-intl/upgrade-guide-3x/#migrate-to-using-native-intl-apis
 */

let pluralRulesPolyfillForceImported = false;
let relativeTimeFormatPolyfillForceImported = false;

const polyfillPluralRules = async (locale: string) => {
  const unsupportedLocale = shouldPolyfillPluralRules(locale);
  // This locale is supported
  if (!unsupportedLocale) {
    return;
  }

  if (!pluralRulesPolyfillForceImported) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-pluralrules/polyfill-force');
    pluralRulesPolyfillForceImported = true;
  }

  await import(`@formatjs/intl-pluralrules/locale-data/${unsupportedLocale}`);
};

const polyfillRelativeTimeFormat = async (locale: string) => {
  const unsupportedLocale = shouldPolyfillRelativeTimeFormat(locale);
  // This locale is supported
  if (!unsupportedLocale) {
    return;
  }

  if (!relativeTimeFormatPolyfillForceImported) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-relativetimeformat/polyfill-force');
    relativeTimeFormatPolyfillForceImported = true;
  }

  await import(`@formatjs/intl-relativetimeformat/locale-data/${unsupportedLocale}`);
};

supportedLanguages.forEach(async (lang) => {
  await polyfillPluralRules(lang.code);
  await polyfillRelativeTimeFormat(lang.code);
});
