import React from 'react';
import styled from 'styled-components';
import { ProductFeature } from 'model/Product';
import FeatureListItem from './FeatureListItem';
import { Dimensions } from 'components/visuals';

type Props = {
  features: ProductFeature[];
  children?: React.ReactNode;
};

const List = styled.ul`
  list-style: none;
  text-align: left;
  padding-left: 0;
  line-height: ${Dimensions.units(3)}px;
  margin: ${Dimensions.units(3)}px 0;
  max-width: ${Dimensions.units(45)}px;
  font-size: 13px;
  margin: 0;
`;

export default ({ features, children }: Props) => (
  <List>
    {features.map(({ description }) => (
      <FeatureListItem key={`${description}`} description={description} />
    ))}
    {children}
  </List>
);
