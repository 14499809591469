import Progress from 'redux-progress';
import * as actions from 'modules/marketplace/recommended/duck/actions';

export default (state = Progress.none, action) => {
  switch (action.type) {
    case actions.FETCH_RECOMMENDED:
      return action.progress;
    default:
      return state;
  }
};
