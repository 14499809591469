import type { Selector, State as S } from 'model/State';
import { OrderResult, RefundLatest } from '../models/orders';

export const selectOrdersCountByEmail: Selector<number | null> = (state: S) =>
  state.mac.orders.ordersCountByEmail.ifSuccess((r) => r);

export const selectQuotes: Selector<OrderResult | null> = (state: S) =>
  state.mac.orders.quotes.ifSuccess((r) => r);

export const selectInvoices: Selector<OrderResult | null> = (state: S) =>
  state.mac.orders.invoices.ifSuccess((r) => r);

export const selectRefundLatest: Selector<RefundLatest | null> = (state: S) =>
  state.mac.orders.refundLatest.ifSuccess((r) => r);
