import SectionMessage from '@atlaskit/section-message';
import React from 'react';
import t from 'modules/i18n/intl';
import { emitUIEpicEvent, utils } from 'modules/analytics';
import { connect } from 'react-redux';
import { Dispatch } from 'model/State';
import config from 'appconfig';
import { UISource } from 'modules/analytics/model/UIEvent';
import { getAdditionalAnalyticsAttributes } from 'modules/analytics/util';
import Info from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';

type DispatchProps = {
  onClickStopSellingJWM: () => void;
};

type Props = DispatchProps;

const infoIcon = () => <Info label="Info icon" primaryColor={colors.P400} />;

const StopSellingJWMWarningMessage = ({ onClickStopSellingJWM }: Props) => {
  const stopSellingJWMTitle = t('stop-selling.jwm.banner.title');
  const stopSellingJWMDescription = t('stop-selling.jwm.banner.description');
  const stopSellingJWMLearnMoreText = t('stop-selling.jwm.banner.link');
  const stopSellingJWMLearnMoreUrl = `${config.wacUrl}/licensing/jira-core#jira-work-management`;

  return (
    <SectionMessage appearance="discovery" icon={infoIcon} title={stopSellingJWMTitle}>
      {stopSellingJWMDescription}
      &nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={stopSellingJWMLearnMoreUrl}
        onClick={() => onClickStopSellingJWM()}
      >
        {stopSellingJWMLearnMoreText}
      </a>
    </SectionMessage>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickStopSellingJWM: () => {
    dispatch(
      emitUIEpicEvent({
        data: utils.getLinkClickEventData('stopSellingJWMWarningMessageLink', UISource.Banner),
        selectorsOrObjects: [
          getAdditionalAnalyticsAttributes(
            UISource.Banner,
            'stopSellingJWMWarningMessageLink',
            'Important changes to our list of Jira products',
            'Learn more about changes',
          ),
        ],
      }),
    );
  },
});

export default connect(null, mapDispatchToProps)(StopSellingJWMWarningMessage);
