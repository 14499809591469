/* eslint-disable import/prefer-default-export */
// @flow
import { ExternalProductKeys } from 'data/products/keys';
import config from 'appconfig';
import t from 'modules/i18n/intl';
import ExternalProduct from 'model/ExternalProduct';

const technicalAccountManagement = new ExternalProduct({
  key: ExternalProductKeys.TECHNICAL_ACCOUNT_MANAGEMENT,
  tagLine: t('products.tam.tagline'),
  description: t('products.tam.description'),
  name: t('products.tam.name'),
  externalUrl: `${config.wacUrl}/enterprise/tam`,
});

export default technicalAccountManagement;
