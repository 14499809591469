// @flow
import type { MapToProps } from 'util/react-redux/connectStateAndDispatch';
import type { PaypalProps } from 'modules/checkout/payment/duck/model';
import { CheckoutModes } from 'modules/checkout/payment/duck/model';
import * as actions from 'modules/checkout/payment/duck/actions';
import validateCreditCard from 'modules/checkout/payment/duck/selectors/validateCreditCard';
import getAvailablePaymentMethods from './getAvailablePaymentMethods';

export const isTNSCheckoutModeSelected = (state) =>
  state.checkout.payment.checkoutMode === CheckoutModes.CREDIT_CARD;

const getCreditCardProps: MapToProps<PaypalProps> = (state, dispatch) =>
  getAvailablePaymentMethods(state).map((availablePaymentMethods) => ({
    ...state.checkout.payment.creditCard,
    isSelected: isTNSCheckoutModeSelected(state),
    isAvailable: availablePaymentMethods.tns && !availablePaymentMethods.stripe,
    validity: validateCreditCard(state),
    configureTnsForm: (fields) => dispatch(actions.configureTnsForm(fields)),
    editTnsForm: (payload) => dispatch(actions.editTnsForm(payload)),
  }));
export default getCreditCardProps;
