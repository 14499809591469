import React from 'react';
import styled from 'styled-components';
import queryString from 'query-string';

import Button from '@atlaskit/button/standard-button';

import t from 'modules/i18n/intl';
import config from 'appconfig';

const NotLoggedInWrapper = styled.div`
  padding: 18px 22px;
`;

const Login = ({ children }: any) => {
  const loginParams = queryString.stringify({ continue: window.location.href });
  const loginUrl = `${config.idLoginUrl}?${loginParams}`;

  return (
    <NotLoggedInWrapper>
      <Button href={loginUrl} appearance="primary">
        {t('addon.login')}
      </Button>
      &nbsp;
      {children}
    </NotLoggedInWrapper>
  );
};

export default Login;
