import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { GridColumn } from '@atlaskit/page';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';

import { ReloadAction, ContactUsAction } from 'components/common/GenericErrorMessage';
import { Dimensions } from 'components/visuals';
import { GridRow } from 'modules/migrate/Layout';

interface Props {
  error?: Error;
}

const Wrapper = styled.div`
  margin-top: ${Dimensions.units(14)}px;
`;

const ErrorBanner: React.FC<Props> = () => (
  <Wrapper>
    <GridRow>
      <GridColumn medium={3} />
      <GridColumn medium={6}>
        <SectionMessage
          appearance="error"
          actions={[ReloadAction, ContactUsAction].map(({ text, ...restAction }) => (
            <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
          ))}
        >
          <FormattedMessage id="migrate.table.error" />
        </SectionMessage>
      </GridColumn>
      <GridColumn medium={3} />
    </GridRow>
  </Wrapper>
);

export default ErrorBanner;
