import Progress, { thunkProgress } from 'redux-progress';
import { initialize as prodLaunchdarklyClient } from 'launchdarkly-js-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import devLaunchdarklyClient from 'launchdarkly-client-wrapper/lib/index';

import config from 'appconfig';

import context from './context';
import { Dispatch } from 'model/State';

export const LOAD_FEATURE_FLAGS = 'LOAD_FEATURE_FLAGS';

const initLaunchdarkly = (dispatch: Dispatch) => {
  const options: any = {
    offline: config.launchdarklyId === null,
  };
  let client;

  if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_FFS_DEV_TOOLS_ACTIVE === 'true'
  ) {
    const changeFeatureFlags = (flags: LDFlagSet) => ({
      type: LOAD_FEATURE_FLAGS,
      progress: Progress.success(flags),
    });

    client = devLaunchdarklyClient(config.launchdarklyId, context(), options);
    client.onChange((flags: LDFlagSet) => dispatch(changeFeatureFlags(flags)));
  } else {
    client = prodLaunchdarklyClient(config.launchdarklyId, context(), options);
  }

  return new Promise((resolve) => {
    client.on('ready', () => {
      resolve(client.allFlags());
    });
  });
};

declare global {
  interface Window {
    CART_LAUNCHDARKLY_OVERRIDE?: object;
  }
}

export const loadFeatureFlags = () => (dispatch: Dispatch) => {
  dispatch(thunkProgress(LOAD_FEATURE_FLAGS, initLaunchdarkly(dispatch)));
};
