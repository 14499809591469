/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import AkSectionMessage, { SectionMessageAction } from '@atlaskit/section-message';

import { Font } from 'components/visuals';
import config from 'appconfig';
import type { Dispatch } from 'model/State';
import * as cart from 'modules/cart';
import t from 'modules/i18n/intl';
import { reportErrorOnly } from 'util/errorReporter';

import { selectInSiteConfiguratorMode } from 'modules/siteconfigurator/duck/selectors';
import { sendAnnualBillingMessageToBux } from 'modules/siteconfigurator/utils';

export type Action = {
  text: React.Node,
  href?: string,
  onClick?: (Event) => void,
};

type StateProps = {
  inSiteConfiguratorMode: boolean,
};

type Props = {
  actions?: (Action | ((Dispatch) => Action))[] | null,
  error: any,
} & StateProps;

type UuidErrorMessageProps = {
  uuid?: string,
} & Props;

export const ReloadAction: Action = {
  text: t('common.reload'),
  onClick: () => window.location.reload(true),
};

export const ContactUsAction: Action = {
  text: t('common.contact-us'),
  href: `${config.wacUrl}/company/contact/purchasing-licensing`,
};

export const getStartNewCartAction = (dispatch: Dispatch): Action => ({
  text: t('common.start-new-cart'),
  onClick: () => {
    try {
      dispatch(cart.actions.resetCart());
    } finally {
      window.location.reload(true);
    }
  },
});

export const CloseSiteConfigurator: Action = {
  text: t('annualbilling.error.back.link'),
  onClick: () => sendAnnualBillingMessageToBux({ notify: true, isError: true }),
};

export const SectionMessage = styled(AkSectionMessage)`
  font-family: ${Font.family};
`;

export class UuidErrorMessageVisual extends React.PureComponent<UuidErrorMessageProps> {
  static defaultProps = {
    actions: null,
    uuid: null,
  };

  constructor(props: Props) {
    super(props);

    this.errorMessage = t('common.generic-error');
    this.errorActions = this.props.actions;
    if (this.props.inSiteConfiguratorMode) {
      this.errorMessage = t('annualbilling.error.message');
      this.errorActions = [CloseSiteConfigurator];
    }
    reportErrorOnly(props.error);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.error !== prevProps.error) {
      reportErrorOnly(this.props.error);
    }
  }

  render() {
    return (
      <SectionMessage
        appearance="error"
        title={t('common.generic-error.title')}
        actions={this.errorActions.map(({ text, ...restAction }) => (
          <SectionMessageAction {...restAction}>{text}</SectionMessageAction>
        ))}
      >
        {this.errorMessage}
        {this.props.uuid != null ? <p>Ref: {this.props.uuid}</p> : null}
      </SectionMessage>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: [getStartNewCartAction(dispatch), ReloadAction, ContactUsAction],
});

const mapStateToProps = (state: State): StateProps => ({
  inSiteConfiguratorMode: selectInSiteConfiguratorMode(state),
});

export const GenericErrorMessageVisual = ({ actions, error, inSiteConfiguratorMode }: Props) => {
  const uuid = error != null ? error.uuid : undefined;
  return (
    <UuidErrorMessageVisual
      inSiteConfiguratorMode={inSiteConfiguratorMode}
      actions={actions}
      error={error}
      uuid={uuid}
    />
  );
};
GenericErrorMessageVisual.defaultProps = {
  actions: undefined,
};

export const ConnectedUuidErrorMessageVisual = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UuidErrorMessageVisual);

const ConnectedGenericErrorMessageVisual = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericErrorMessageVisual);

export default ConnectedGenericErrorMessageVisual;
/* eslint-enable react/static-property-placement */
